import { store } from "./config/store";

export function checkSecurity(theAction) {
  const state = store.getState();
  const val =
    state.user.permissions &&
    state.user.permissions.find((action) => action === theAction);

  if (val) return true;
  return false;
}

export function checkView(actName) {
  const state = store.getState();
  const updateAction = "Update" + actName;
  const editVal =
    state.user.permissions &&
    state.user.permissions.find((action) => action === updateAction);

  if (editVal) return true;

  const viewAction = "View" + actName;
  const viewVal =
    state.user.permissions &&
    state.user.permissions.find((action) => action === viewAction);
  if (viewVal) return true;
  return false;
}

export function checkUpdate(actName) {
  const updateAction = "Update" + actName;

  return checkSecurity(updateAction);
}
