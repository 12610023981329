import React from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../form.css";

class RankingInfoDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rankingInfo: this.props.rankingInfo,
      showDialog: false,
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.showInformation = this.showInformation.bind(this);
    this.hiddenActionButton = this.hiddenActionButton.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rankingInfo !== prevProps.rankingInfo) {
      this.setState({ rankingInfo: this.props.rankingInfo });
    }
  }
  showInformation() {
    const id = this.props.actBtnId ? this.props.actBtnId : "riDialogBtn";
    document.getElementById(id).click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
  }
  getMessage() {
    const info = this.state.rankingInfo;
    return (
      <React.Fragment>
        Base Score: {info.baseScore}
        <br />
        {info.onSaleAdj !== 0 && (
          <span>
            OnSale Adjustment: {info.onSaleAdj}
            <br />
          </span>
        )}
        {info.soldOutAdj !== 0 && (
          <span>
            SoldOut Adjustment: {info.soldOutAdj}
            <br />
          </span>
        )}
        {info.pvAdj !== 0 && (
          <span>
            Variant SoldOut Adjustment: {info.pvAdj}
            <br />
          </span>
        )}
        Total View Adjustment: {info.overallViewAdj}
        <br />
        M2D View Adjustment: {info.m2dViewAdj}
        <br />
        Views/User Adjustment: {info.overallV2UAdj}
        <br />
        M2D Views/User Adjustment: {info.m2dV2UAdj}
        <br />
        Order Adjustment: {info.orderAdj}
        <br />
        M2D Order Adjustment: {info.m2dOrderAdj}
        <br />
        <b>Final Ranking Score: {info.finalRankingScore}</b>
        <br />
        &nbsp;
        <br />
        Month 2 Date Views: {info.m2dViewCnt}
        <br />
        Total Views: {info.overallViewCnt}
        <br />
        Month 2 Date Users: {info.m2dUserCnt}
        <br />
        Total Users: {info.overallUserCnt}
        <br />
        Month 2 Date Orders: {info.m2dOrderCnt}
        <br />
        Total Orders: {info.orderCnt}
      </React.Fragment>
    );
  }
  hiddenActionButton() {
    const id = this.props.actBtnId ? this.props.actBtnId : "riDialogBtn";
    return (
      <button
        id={id}
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }

  informationDialog() {
    if (!this.state.rankingInfo) return "";

    const title =
      "Product Ranking Score for " + this.state.rankingInfo.productId;
    return (
      <div>
        {this.hiddenActionButton()}
        <Dialog isOpen={this.state.showDialog} onDismiss={this.closeDialog}>
          <b>{title}</b>
          <p>{this.getMessage()}</p>
          <p />
          <button onClick={this.closeDialog} className="btn-style">
            Close
          </button>
        </Dialog>
      </div>
    );
  }

  render() {
    return (
      <div>
        <button
          id={this.props.btnId}
          onClick={this.showInformation}
          style={{ display: "none" }}
        >
          Hidden
        </button>
        {this.informationDialog()}
      </div>
    );
  }
}
export default RankingInfoDialog;
