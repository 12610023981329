import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditFtpSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderFtp: null,
      quoteFtp: null,
      settings: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      qPwFldType: "password",
      oPwFldType: "password",
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.passworFieldClicked = this.passworFieldClicked.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetFtpSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({
      orderFtp: response.data.orderFtp,
      quoteFtp: response.data.quoteFtp,
      isLoading: false,
    });
  }
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    const isQuote = name.startsWith("Quote_");
    let ftpInfo = isQuote ? this.state.quoteFtp : this.state.orderFtp;

    name = name.substring(6);
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
    }

    ftpInfo = {
      ...ftpInfo,
      [name]: val,
    };
    if (isQuote) {
      this.setState({ quoteFtp: ftpInfo, updateBtnDisabled: false });
    } else {
      this.setState({ orderFtp: ftpInfo, updateBtnDisabled: false });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateFtpSetting";
    const data = {
      orderFtp: this.state.orderFtp,
      quoteFtp: this.state.quoteFtp,
    };
    console.log(data);
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
          return;
        }
        this.props.history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  passworFieldClicked(name, visible) {
    const type = visible ? "text" : "password";
    if (name === "Quote") {
      this.setState({ qPwFldType: type });
    } else {
      this.setState({ oPwFldType: type });
    }
  }
  FTPForm(ftpInfo, name) {
    const title = name + " FTP Setting";
    const enName = name + "_CB_enabled";
    const ipName = name + "_ipAddress";
    const uName = name + "_user";
    const pName = name + "_password";
    const dName = name + "_directory";
    const iName = name + "_invoiceDir";
    const pType =
      name === "Quote" ? this.state.qPwFldType : this.state.oPwFldType;
    return (
      <div>
        <font size="4">{title}</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">Enabled: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name={enName}
              defaultChecked={ftpInfo.enabled}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Enabled
          </div>
          <div className="form-item-label">IP Address: </div>
          <div className="form-item-value">
            <input
              type="text"
              name={ipName}
              value={ftpInfo.ipAddress}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">User: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name={uName}
              value={ftpInfo.user}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Password: </div>
          <div className="form-item-value">
            <input
              type={pType}
              size="15"
              name={pName}
              value={ftpInfo.password}
              onChange={this.handleChange}
              onClick={() => this.passworFieldClicked(name, true)}
              onBlur={() => this.passworFieldClicked(name, false)}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">DAT Dir: </div>
          <div className="form-item-value">
            <input
              type="text"
              name={dName}
              value={ftpInfo.directory}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Invoice Dir: </div>
          <div className="form-item-value">
            <input
              type="text"
              name={iName}
              value={ftpInfo.invoiceDir}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
        </div>
      </div>
    );
  }
  theForm() {
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";

    return (
      <div className="generic-wrapper">
        <font size="5">FTP Settings</font>
        <p />
        {this.FTPForm(this.state.orderFtp, "Order")}
        <hr className={hrClsName} align="left" />
        {this.FTPForm(this.state.quoteFtp, "Quote")}
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditFtpSettings);
