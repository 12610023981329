import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import BuyTogether from "./BuyTogether";
import Working from "../Working";
import "../../App.css";
import "../../form.css";
import "../../generic.css";

class ManageBT extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      btList: null,
      isLoading: true,
      mobileMode: false,
      editAllowed: checkUpdate("Product"),
    };

    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.updateBT = this.updateBT.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.editAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchBTList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const btList = response.objList;
      this.setState({ btList, isLoading: false });
    }
  }

  fetchBTList() {
    const url = apiBaseUrl() + "GetAllBTList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updateBT(uniqueKey) {
    const url = "/editBT/" + uniqueKey;
    this.props.history.push(url);
  }
  showAll() {
    if (this.state.btList && this.state.btList.length > 0) {
      return (
        <div>
          {this.state.btList.map((bt) => {
            const bti = this.enableItems(bt);
            const pv = bt.item.data.pvList[bt.item.id];
            let title = pv.fullName ? pv.fullNameme : pv.variantValue;
            if (!title) title = bt.item.data.name;
            title += " $" + pv.finalPrice;
            return (
              <div className="top-10">
                <BuyTogether bti={bti} title={title} />
              </div>
            );
          })}
        </div>
      );
    }
  }

  enableItems(bt) {
    const item = {
      ...bt.item,
      checked: true,
    };
    let otherItems = [],
      i;
    for (i = 0; i < bt.otherItems.length; i++) {
      const aItem = {
        ...bt.otherItems[i],
        checked: true,
      };
      otherItems.push(aItem);
    }
    const bti = {
      ...bt,
      item,
      otherItems,
    };
    return bti;
  }

  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="form-container">
          <div>
            <font size="5">Buy Together Items</font>
          </div>
          {this.showAll()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageBT);
