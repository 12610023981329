import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { searchProducts } from "./actions/searchActions";
import { setTopContainerWrapperSettings, isMobileMode } from "./Util";
import { checkView } from "./SecManager";
import HamburgerMenu from "./HamburgerMenu";
import "../navBar.css";

class MenuBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMode: false,
      isLoading: true,
      locations: null,
      viewAllowed: checkView("Setting"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
  }
  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  searchProducts(event) {
    if (event.key === "Enter") {
      const query = event.target.value;
      if (query && query.trim().length > 0) {
        this.props.searchProducts(query);
        event.target.value = "";
        this.props.history.push("/search");
      }
    }
  }

  regularLayout() {
    return (
      <div className="menu-wrapper">
        <div className="menu-left-side">
          <Link to="/">Home</Link>
        </div>
        <div className="menu-middle">&nbsp;</div>
        <div className="menu-right-side">
          <div>
            <input
              type="search"
              placeholder="Search"
              results="0"
              height="25"
              size="35"
              onKeyDown={this.searchProducts}
            />
          </div>
          {this.state.viewAllowed && (
            <React.Fragment>
              <div>
                <Link to="/editPromotions">Promotions</Link>
              </div>
              <div>
                <Link to="/editSpecials">Specials</Link>
              </div>
            </React.Fragment>
          )}
          <div>
            <Link to={this.props.menuUrl}>{this.props.loginMenu}</Link>
          </div>
        </div>
      </div>
    );
  }

  mobileLayout() {
    const homeIcon = "/icons/home.svg";

    return (
      <div className="menu-wrapper">
        <div className="menu-left-side">
          <HamburgerMenu />
        </div>
        <div className="menu-mobile-home-img">
          <Link to="/">
            <div className="menu-logo-wrapper">
              <img src={homeIcon} alt="Home" height="29"></img>
            </div>
          </Link>
        </div>
        <div className="menu-middle">
          <input
            type="search"
            placeholder="Search"
            results="0"
            height="25"
            size="35"
            onKeyDown={this.searchProducts}
          />
        </div>
      </div>
    );
  }
  render() {
    return <div className="top-wrapper">{this.mobileLayout()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    userState: state.user,
    loginMenu: state.user.loginMenu,
    menuUrl: state.user.menuUrl,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchProducts: (query) => {
      dispatch(searchProducts(query));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuBar));
