export const initUserState = {
  userId: "",
  sessionKey: "",
  isLoggedIn: false,
  permissions: null,
  passwordReq: "",
  resetMsg: null,
  userInfo: null,
  loginTime: null,
  loginMenu: "Login",
  menuUrl: "/login",
  version: "",
};

export const initSearchState = {
  categoryId: null,
  query: "",
  searchCategory: "",
  result: null,
  orderQuery: null,
  orderMostMatches: false,
  searchType: 1,
  orderResult: null,
  exactOrderList: null,
  exactCrit: "",
};

export const initWebOrderState = {
  userId: null,
  storeId: 0,
  storeName: null,
  kiosk: false,
};
