import React from "react";
import { withRouter } from "react-router-dom";
import { getIconBaseUrl } from "./Util";
import { checkView, checkSecurity } from "./SecManager";
import "../App.css";
import "../navBar.css";
import "../hammenu.css";

class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.documentElement.style.setProperty(
      "--hammenu-display-mode",
      "none"
    );
  }
  handleClick(url) {
    document.getElementById("hiddenCB").click();
    if (url !== null) {
      this.props.history.push(url);
    }
  }
  handleChange(event) {
    const mode = event.target.checked ? "normal" : "none";
    document.documentElement.style.setProperty("--hammenu-display-mode", mode);
  }
  getMenuItems(legacy) {
    let items = [];
    items.push({ name: "Account", url: "/myAccount", image: "User.jpg" });
    items.push({
      name: "Invisible",
      url: "/invisible",
      image: "Invisible.jpg",
    });
    items.push({
      name: "Buy Together",
      url: "/btItems",
      image: "buyTogether2.jpg",
    });
    if (checkView("Statistics")) {
      items.push({
        name: "Order Statistics",
        url: "/orderStats",
        image: "statistics.jpg",
      });
    }
    if (legacy) {
      items.push({
        name: "Specials",
        url: "/editSpecials",
        image: "specials.jpg",
      });
      items.push({
        name: "Promotions",
        url: "/editPromotions",
        image: "Promotion.jpg",
      });
    }
    items.push({
      name: "Schedule Setting",
      url: "/schedule",
      image: "scheduled.png",
    });
    if (checkView("Setting")) {
      items.push({
        name: "Product Settings",
        url: "/editProdSetting",
        image: "prod-setting.jpg",
      });
      items.push({
        name: "DAT Settings",
        url: "/datSetting",
        image: "dat2.jpg",
      });
      items.push({
        name: "PDF Settings",
        url: "/pdfSetting",
        image: "pdf.jpg",
      });
      items.push({
        name: "HPage Settings",
        url: "/hpSetting",
        image: "homepage.jpg",
      });
      items.push({
        name: "Order Settings",
        url: "/orderSetting",
        image: "order-setting.jpg",
      });
      items.push({
        name: "Other Settings",
        url: "/editSettings",
        image: "Setting.jpg",
      });
    }
    if (legacy && checkView("Setting")) {
      items.push({
        name: "Categiories",
        url: "/editCategory",
        image: "Category.jpg",
      });
      items.push({
        name: "Suppliers",
        url: "/editSupplier",
        image: "Supplier.jpg",
      });
      items.push({ name: "States", url: "/editStates", image: "States.jpg" });
      items.push({
        name: "Special Menu",
        url: "/specialMenu",
        image: "SpMenu2.jpg",
      });
      items.push({ name: "Logos", url: "/editLogos", image: "logo.jpg" });
      items.push({ name: "FAQ", url: "/editFaq", image: "Faq.jpg" });
    }
    if (checkView("PaymentProcessor")) {
      items.push({
        name: "Payment Settings",
        url: "/editPmtSetting",
        image: "payment.jpg",
      });
    }
    if (checkView("UserSession")) {
      items.push({
        name: "User Sessions",
        url: "/manageSess",
        image: "Sessions.jpg",
      });
    }
    if (checkSecurity("RootFeatures")) {
      items.push({
        name: "Return Payment",
        url: "/returnPayment",
        image: "returnPayment.jpg",
      });
    }
    if (checkView("Setting")) {
      items.push({ name: "SEO Setting", url: "/seo", image: "seo.png" });
    }
    if (checkView("Batch")) {
      items.push({
        name: "Download/Upload",
        url: "/batch",
        image: "updown.jpg",
      });
    }
    if (checkSecurity("RootFeatures")) {
      items.push({
        name: "Browser Ident",
        url: "/browserIdent",
        image: "browser.jpg",
      });
    }
    return items;
  }
  render() {
    const menuItems = this.getMenuItems(false);

    return (
      <div id="menuToggle">
        <input type="checkbox" id="hiddenCB" onChange={this.handleChange} />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu" align="left">
          {menuItems.map((item, idx) => {
            const icon = getIconBaseUrl() + item.image;
            return (
              <li key={idx}>
                <div className="flex-wrapper-pr5">
                  <div>
                    <img src={icon} height="20" alt={item.name}></img>
                  </div>
                  <div className="left-4">
                    <a onClick={() => this.handleClick(item.url)} href="#">
                      {item.name}
                    </a>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(HamburgerMenu);
