import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { checkUpdate, checkView } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
  FormatCurrency,
  getIconBaseUrl,
} from "../Util";
import ActionConfirmation from "../ActionConfirmation";
import Working from "../Working";
import "../../App.css";
import "../../form.css";
import "../../generic.css";
import "../../bt.css";
import "../../combo.css";
import "../../prodPreview.css";

class EditCombo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      comboId: this.props.match.params.comboId,
      comboName: null,
      productId: this.props.match.params.prodId,
      description: null,
      salePrice: 0,
      showSoldOut: true,
      enabled: false,
      groups: [null, null, null, null],
      expanded: [false, false, false, false],
      addNew: true,
      allPvList: null,
      isLoading: true,
      isLoadingPv: true,
      mobileMode: false,
      updBtnDisabled: true,
      errorMessage: null,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };

    this.processResponse = this.processResponse.bind(this);
    this.processPvResponse = this.processPvResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchCombo = this.fetchCombo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processNewPVResponse = this.processNewPVResponse.bind(this);
    this.doDelete = this.doDelete.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed || !this.props.match.params.comboId) {
      this.props.history.push("/");
      return;
    }

    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchCombo();
    this.fetchPvList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  toIdGroup(group) {
    if (group) {
      let itemList = [],
        i;
      for (i = 0; i < group.itemList.length; i++) {
        itemList.push(group.itemList[i].id);
      }
      const idGroup = {
        groupId: group.groupId,
        name: group.name,
        itemList,
      };
      return idGroup;
    }
    return null;
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.data) {
        const d = response.data;
        let expanded = [],
          groups = [];
        if (d.group1) expanded.push(true);
        else expanded.push(false);
        if (d.group2) expanded.push(true);
        else expanded.push(false);
        if (d.group3) expanded.push(true);
        else expanded.push(false);
        if (d.group4) expanded.push(true);
        else expanded.push(false);
        groups.push(d.group1);
        groups.push(d.group2);
        groups.push(d.group3);
        groups.push(d.group4);

        this.setState({
          id: d.id,
          comboName: d.comboName,
          productId: d.productId,
          description: d.description,
          salePrice: d.salePrice,
          showSoldOut: d.showSoldOut,
          enabled: d.enabled,
          groups,
          expanded,
          addNew: false,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          addNew: true,
        });
      }
    }
  }
  fetchCombo() {
    const url = apiBaseUrl() + "GetCombo";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.props.match.params.comboId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Error fetching combo info",
          isLoading: false,
        });
        console.log(error);
      });
  }
  doDelete(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "DeleteCombo";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        const url = "/product/" + this.state.productId;
        this.props.history.push(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Error fetching combo info",
        });
        console.log(error);
      });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        const url = "/combo/" + this.state.comboId;
        this.props.history.push(url);
      } else {
        this.setState({ errorMessage: response.errorMessage });
      }
    }
  }
  handleUpdate(event) {
    event.preventDefault();
    const url = apiBaseUrl() + "UpdateCombo";
    const data = {
      ...this.state,
      group1: this.toIdGroup(this.state.groups[0]),
      group2: this.toIdGroup(this.state.groups[1]),
      group3: this.toIdGroup(this.state.groups[2]),
      group4: this.toIdGroup(this.state.groups[3]),
    };
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Error updating",
        });
        console.log(error);
      });
  }
  processPvResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({ allPvList: response.objList, isLoadingPv: false });
    }
  }
  fetchPvList() {
    const url = apiBaseUrl() + "GetAllPvList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processPvResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processNewPVResponse(response, grpIdx) {
    if (response.pv) {
      let group = this.state.groups[grpIdx];
      let itemList = [];

      if (group && group.itemList) {
        itemList = group.itemList;
        itemList.push(response.pv);
      } else {
        itemList = [response.pv];
      }
      group = {
        ...this.state.groups[grpIdx],
        itemList,
      };
      let i,
        groups = [];
      for (i = 0; i < 4; i++) {
        if (i === grpIdx) groups.push(group);
        else groups.push(this.state.groups[i]);
      }
      this.setState({ groups, updBtnDisabled: false });
    } else {
      this.setState({ errorMessage: "Failed to get PV" });
    }
  }
  fetchNewPV(grpIdx, prodVarId) {
    const url = apiBaseUrl() + "GetVariant";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: prodVarId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processNewPVResponse(res.data, grpIdx);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      this.setState({ [name]: event.target.checked, updBtnDisabled: false });
    } else if (name.startsWith("NewItem_")) {
      if (event.target.value && event.target.value.length > 0) {
        const grpIdx = parseInt(name.substring(8));
        const prodVarId = event.target.value;
        this.fetchNewPV(grpIdx, prodVarId);
      }
    } else if (name.startsWith("GrpName_")) {
      const grpIdx = parseInt(name.substring(8));
      let grp = {
        ...this.state.groups[grpIdx],
        name: event.target.value,
      };
      if (
        event.target.value.trim().length === 0 &&
        (!grp.itemList || grp.itemList.length === 0)
      ) {
        grp = null;
      }

      let groups = [],
        i;
      for (i = 0; i < 4; i++) {
        if (i === grpIdx) {
          groups.push(grp);
        } else {
          groups.push(this.state.groups[i]);
        }
      }
      this.setState({ groups, updBtnDisabled: false });
    } else {
      this.setState({ [name]: event.target.value, updBtnDisabled: false });
    }
  }
  handleClick(idx) {
    let expanded = [];
    let i;
    for (i = 0; i < this.state.expanded.length; i++) {
      if (i === idx) expanded.push(!this.state.expanded[i]);
      else expanded.push(this.state.expanded[i]);
    }
    this.setState({ expanded });
  }
  handleItemDelete(grpIdx, itemIdx) {
    let groups = [],
      i;
    for (i = 0; i < 4; i++) {
      const grp = this.state.groups[i];
      if (i === grpIdx) {
        let itemList = [],
          k;
        for (k = 0; k < grp.itemList.length; k++) {
          if (k !== itemIdx) {
            itemList.push(grp.itemList[k]);
          }
        }
        let newGrp = {
          ...grp,
          itemList,
        };
        if ((!grp.name || grp.name.length === 0) && itemList.length === 0) {
          newGrp = null;
        }
        groups.push(newGrp);
      } else {
        groups.push(grp);
      }
    }
    this.setState({ groups, updBtnDisabled: false });
  }

  showGroupItems(grpIdx) {
    const group = this.state.groups[grpIdx];
    if (group && group.itemList) {
      const clsName = this.state.mobileMode
        ? "threecol-wrapper"
        : "generic-flex-top10";
      return (
        <div className={clsName}>
          {group.itemList.map((item, itemIdx) => {
            const imgUrl = item.imageUrl ? item.imageUrl : item.prodImageUrl;
            const imgId = item.forSale ? "img" : "soldOutImage";
            const crossIcon = getIconBaseUrl() + "redCross.jpg";
            return (
              <div className="right-10" key={itemIdx}>
                <div className="generic-flex-top">
                  <div>
                    <img
                      id={imgId}
                      src={fullProductImageUrl(imgUrl)}
                      width="100"
                      height="75"
                      alt={item.sku}
                    />
                  </div>
                  <div>
                    <img
                      src={crossIcon}
                      width="12"
                      onClick={() => this.handleItemDelete(grpIdx, itemIdx)}
                      alt="Cross"
                    />
                  </div>
                </div>
                <div>
                  <FormatCurrency amount={item.salePrice} />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
  showAddNew(grpIdx) {
    if (
      !this.state.isLoadingPv &&
      this.state.allPvList &&
      this.state.expanded[grpIdx]
    ) {
      const name = "NewItem_" + grpIdx;
      return (
        <div className="left-20">
          Add New:&nbsp;
          <select name={name} onChange={this.handleChange}>
            <option value="">[SELECT ONE]</option>
            {this.state.allPvList.map((pv) => {
              return (
                <option value={pv.id}>
                  {pv.sku} ${pv.salePrice}
                </option>
              );
            })}
          </select>
        </div>
      );
    }
  }
  showOneGroup(grpIdx) {
    const group = this.state.groups[grpIdx];
    const itemCount = group && group.itemList ? group.itemList.length : 0;
    const name = group
      ? group.name + " (" + itemCount + ")"
      : "Group " + (grpIdx + 1) + " (Empty)";
    const icon = this.state.expanded[grpIdx] ? "minus.jpg" : "plus.jpg";
    const grpName = "GrpName_" + grpIdx;
    const grpVal = group ? group.name : null;

    return (
      <div className="top-10">
        <div className="generic-flex">
          <div>
            <img
              src={getIconBaseUrl() + icon}
              width="20"
              onClick={() => this.handleClick(grpIdx)}
              alt="Img"
            ></img>
          </div>
          {this.state.expanded[grpIdx] ? (
            <div>
              <div className="generic-flex">
                <div>Group Name: </div>
                <div className="left-10">
                  <input
                    type="text"
                    name={grpName}
                    value={grpVal}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              {this.showGroupItems(grpIdx)}
            </div>
          ) : (
            <div>{name}</div>
          )}
        </div>
        {this.showAddNew(grpIdx)}
      </div>
    );
  }
  showComboMain() {
    const rows = this.state.mobileMode ? 10 : 8;
    const cols = this.state.mobileMode ? 42 : 80;
    return (
      <div className="form-wrapper">
        <div className="form-item-label">Combo Name:</div>
        <div className="form-item-value">
          <input
            type="text"
            size="25"
            name="comboName"
            value={this.state.comboName}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-item-label">Combo Price:</div>
        <div className="form-item-value">
          <input
            type="text"
            size="6"
            name="salePrice"
            value={this.state.salePrice}
            onChange={this.handleChange}
          />{" "}
        </div>
        <div className="form-item-label">Setting:</div>
        <div className="form-item-value">
          <input
            type="checkbox"
            name="CB_showSoldOut"
            defaultChecked={this.state.showSoldOut}
            onChange={this.handleChange}
          />{" "}
          Show Sold Out Items
          <br />
          <input
            type="checkbox"
            name="CB_enabled"
            defaultChecked={this.state.enabled}
            onChange={this.handleChange}
          />{" "}
          Enabled
        </div>
        <div className="form-item-label">Description:</div>
        <div className="form-item-value">
          <textarea
            name="description"
            rows={rows}
            cols={cols}
            value={this.state.description}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
  showDelButton() {
    //Only show these buttons for single-variant product update
    if (!this.state.addNew) {
      return (
        <ActionConfirmation
          title="Delete Confirmation"
          message="Are you sure you want to delete this combo?"
          buttonTitle="Delete"
          buttonClsName="red-btn-style"
          actionHandler={this.doDelete}
        />
      );
    }
  }
  theForm() {
    if (this.state.isLoading)
      return (
        <div>
          <img src={getIconBaseUrl() + "working.gif"} width={80} />
        </div>
      );

    const btnTitle = this.state.addNew ? "Add" : "Update";
    const btnStyle = this.state.updBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";

    return (
      <div>
        {this.showComboMain()}
        {this.showOneGroup(0)}
        {this.showOneGroup(1)}
        {this.showOneGroup(2)}
        {this.showOneGroup(3)}
        <div className="generic-flex-top10">
          <div>
            <button
              name="update"
              className={btnStyle}
              onClick={this.handleUpdate}
              disabled={this.state.updBtnDisabled}
            >
              {btnTitle}
            </button>
          </div>
          <div className="left-10">&nbsp;</div>
          {this.showDelButton()}
        </div>
      </div>
    );
  }
  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    let title = this.state.addNew ? "Add Combo " : "Update Combo ";
    title += this.props.match.params.comboId;
    return (
      <div className="top-wrapper">
        <div className="form-container">
          <div className="bottom-10">
            <font size="5">{title}</font>
          </div>
          {this.state.errorMessage && (
            <div>
              <font color="red">{this.state.errorMessage}</font>
            </div>
          )}
          {this.theForm()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCombo);
