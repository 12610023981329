import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
  toAmount,
  getMainSiteUrl,
} from "../Util";
import { checkView } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../waitlistStats.css";

class WaitlistStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waitlistStats: null,
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      viewAllowed: checkView("Product"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchStats = this.fetchStats.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchStats();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }
  fetchStats() {
    const url = apiBaseUrl() + "GetWaitlistStats";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ waitlistStats: res.data.objList, isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get waitlist stats",
          isLoading: false,
        });
      });
  }
  oneStatsRow(stats, rowIdx) {
    const clsName = rowIdx % 2 === 0 ? "wl-grid-item-ev" : "wl-grid-item-od";
    const previewUrl = "/product/" + stats.productId;
    const prodUrl =
      getMainSiteUrl() + "/product/" + stats.productId + "/" + stats.sku;
    return (
      <React.Fragment>
        <div className={clsName}>
          <a href={previewUrl}>
            <img
              src={fullProductImageUrl(stats.imageInfoList[0].url)}
              width="64"
              height="48"
            ></img>
          </a>
        </div>
        <div className={clsName}>
          <a href={prodUrl} target="MainSite">
            {stats.sku}
          </a>
          <br />
          Price: {toAmount(stats.price)}
        </div>
        <div className={clsName}>{stats.count}</div>
      </React.Fragment>
    );
  }

  statsHeader() {
    const clsName = "wl-grid-item-hd";
    const cntTitle = this.state.mobileMode ? "Count" : "Customer Count";
    return (
      <React.Fragment>
        <div className={clsName}>
          <b>Image</b>
        </div>
        <div className={clsName}>
          <b>Product</b>
        </div>
        <div className={clsName}>
          <b>{cntTitle}</b>
        </div>
      </React.Fragment>
    );
  }
  showStats() {
    const clsName = "wl-threecol-table";
    return (
      <div>
        <div className={clsName}>
          {this.statsHeader()}
          {this.state.waitlistStats.map((stats, rowIdx) => {
            return this.oneStatsRow(stats, rowIdx);
          })}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    if (!this.state.waitlistStats || this.state.waitlistStats.length === 0) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">Product Waiting List</font>
            <p />
            <font color="red">There is no waiting list right now.</font>
          </div>
        </div>
      );
    }

    const title = "Sold Out Product Waiting List";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          The following products have customers registered to get email
          notification.
          <p />
          {this.showStats()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WaitlistStats);
