import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toAmount,
  setTopContainerWrapperSettings,
  isMobileMode,
  getIconBaseUrl,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import { setLoggedOut } from "../actions/userActions";
import "../../App.css";
import "../../search.css";
import "../../generic.css";

class OrderSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMode: false,
      goodList: null,
      possibleList: null,
      layawayList: null,
      isLoading: true,
      errorMessage: null,
      viewAllowed: checkView("Order"),
    };
    this.processResponse = this.processResponse.bind(this);
    this.fetchOrders = this.fetchOrders.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.adjustMode();
      window.addEventListener("resize", this.adjustMode);
      this.fetchOrders();
    }
  }
  componentDidUpdate(prevProps) {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (
      this.props.query !== prevProps.query ||
      this.props.mostMatches != prevProps.mostMatches
    ) {
      this.fetchOrders();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }

  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      let goodList = [];
      let possibleList = [];
      if (response.orderList) {
        for (const [idx, order] of response.orderList.entries()) {
          if (order.score > 0.0) possibleList.push(order);
          else goodList.push(order);
        }
      }
      let cnt = goodList.length + possibleList.length;
      if (cnt <= 1) {
        cnt += response.layawayList ? response.layawayList.length : 0;
      }
      if (cnt === 1) {
        let url = null;
        if (goodList.length > 0 || possibleList.length > 0) {
          const theOrder =
            goodList.length > 0 ? goodList[0].summary : possibleList[0].summary;
          if (theOrder && theOrder.orderNumber) {
            url = "/orderDetail/" + theOrder.orderNumber;
          }
        } else {
          const theOrder = response.layawayList[0];
          if (theOrder && theOrder.orderNumber) {
            url = "/layawayDetail/" + theOrder.orderNumber;
          }
        }
        if (url) {
          this.props.history.replace(url);
          return;
        }
      }

      this.setState({
        goodList,
        possibleList,
        layawayList: response.layawayList,
        isLoading: false,
      });
    }
  }
  fetchOrders() {
    const url = apiBaseUrl() + "SearchOrder";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      query: this.props.query,
      searchType: this.props.searchType,
      mostMatches: this.props.mostMatches,
      category: this.props.searchCategory,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        let errorMessage = "An error has occurred";
        if (checkUpdate("Setting")) {
          errorMessage += ": " + error;
        }
        console.log(error);
        this.setState({
          isLoading: false,
          errorMessage,
        });
      });
  }
  getCustomerName(addr) {
    let name = "";

    if (addr) {
      if (addr.firstName && addr.firstName.length > 0) {
        if (this.state.mobileMode) name += addr.firstName.substring(0, 1);
        else name += addr.firstName;
        name += " ";
      }
      if (addr.lastName && addr.lastName.length > 0) {
        if (this.state.mobileMode) name += addr.lastName.substring(0, 1);
        else name += addr.lastName;
      }
    }
    return name;
  }
  showOneList(orderList, showScore) {
    const title = showScore ? "Possible Matches" : "Likely Matches";
    const clsName = this.state.mobileMode
      ? "res-fivecol-wrapper"
      : "res-sixcol-wrapper";

    return (
      <div className="order-result-wrapper">
        {this.props.searchType != 3 && (
          <label>
            {" "}
            <b>{title}</b>
            <br />
          </label>
        )}
        <div className={clsName}>
          {orderList.map((ord, idx) => {
            const name = this.getCustomerName(ord.contactAddress);
            const url = "/orderDetail/" + ord.summary.orderNumber;
            let img = null;
            const phone = ord.contactAddress ? ord.contactAddress.phone : "";

            if (ord.orderStatus) {
              img = getIconBaseUrl() + ord.orderStatus.icon;
            } else {
              img =
                ord.payments && ord.payments.length > 0
                  ? getIconBaseUrl() + "checkmark.png"
                  : getIconBaseUrl() + "cross.png";
            }
            const dateStr = this.state.mobileMode
              ? ord.summary.mobileDateStr
              : ord.summary.dateStr;
            return (
              <React.Fragment key={idx}>
                <div>
                  <img src={img} width="12" alt="Order"></img>
                </div>
                <div>
                  <Link to={url}>{ord.summary.orderNumber}</Link>
                </div>
                <div>{dateStr}</div>
                <div>{name}</div>
                {!this.state.mobileMode && <div>{phone}</div>}
                <div align="right">{toAmount(ord.summary.totalPrice)}</div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  showLayaways(showSubTitle) {
    if (this.state.layawayList && this.state.layawayList.length > 0) {
      const clsName = this.state.mobileMode
        ? "res-fivecol-wrapper"
        : "res-sixcol-wrapper";

      return (
        <div>
          {showSubTitle && <div className="subtitle-container">Layaways</div>}
          <div className={clsName}>
            {this.state.layawayList.map((layaway, idx) => {
              const name = this.getCustomerName(layaway.customer);
              const img = layaway.status ? layaway.status.icon : "layaway.jpg";
              const url = "/layawayDetail/" + layaway.orderNumber;

              return (
                <React.Fragment key={idx}>
                  <div>
                    <img
                      src={getIconBaseUrl() + img}
                      width="12"
                      alt="Layaway"
                    ></img>
                  </div>
                  <div>
                    <Link to={url}>{layaway.orderNumber}</Link>
                  </div>
                  <div>{layaway.dateOrderedStr}</div>
                  <div>{name}</div>
                  {!this.state.mobileMode && (
                    <div>{layaway.customer.phone}</div>
                  )}
                  <div align="right">{toAmount(layaway.totalPrice)}</div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    }
  }
  showOrdersAndLayaways() {
    const hasGood = this.state.goodList && this.state.goodList.length > 0;
    const hasPossible =
      this.state.possibleList && this.state.possibleList.length > 0;
    const hasLayaway =
      this.state.layawayList && this.state.layawayList.length > 0;
    const showSubTitle = this.props.searchType == 3;
    if (hasGood || hasPossible || hasLayaway) {
      return (
        <div>
          {(hasGood || hasPossible) && (
            <div>
              {showSubTitle && <div className="subtitle-container">Orders</div>}
              {hasGood && this.showOneList(this.state.goodList, false)}
              {hasPossible && this.showOneList(this.state.possibleList, true)}
            </div>
          )}
          {this.showLayaways(showSubTitle)}
        </div>
      );
    } else {
      let message = "No order was found.";
      if (this.props.searchType == 2) message = "No layaway was found.";
      else if (this.props.searchType == 3)
        message = "No order/layaway was found.";
      return <div>{message}</div>;
    }
  }

  showMessage() {
    const pl = this.state.possibleList;
    const gl = this.state.goodList;
    const skuMatchOnly = pl && pl.length > 0 && pl[pl.length - 1].skuMatch;
    const goodMatchOnly = (!pl || pl.length === 0) && gl && gl.length > 0;

    if (
      !this.state.errorMessage &&
      !this.props.mostMatches &&
      (skuMatchOnly || goodMatchOnly)
    ) {
      return (
        <div>
          <p />
          Did not find what you are looking for? Try to go back, check the
          "Search all" checkbox and search again.
        </div>
      );
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Working />;
    }

    let title = "Order Search Result";
    if (this.props.searchType == 2) title = "Layaway Search Result";
    else if (this.props.searchType == 3)
      title = "Order & Layaway Search Result";
    let subtitle =
      this.props.searchCategory && this.props.searchCategory !== "All"
        ? "By " + this.props.searchCategory + " " + this.props.query
        : null;
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <br />
          {subtitle && (
            <div>
              &nbsp;&nbsp;&nbsp;
              <i>
                <font size="4">{subtitle}</font>
              </i>
            </div>
          )}
          <br />
          {this.state.errorMessage ? (
            <font color="red">{this.state.errorMessage}</font>
          ) : (
            this.showOrdersAndLayaways()
          )}
          {this.showMessage()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    query: state.search.orderQuery,
    mostMatches: state.search.orderMostMatches,
    searchType: state.search.searchType,
    searchCategory: state.search.searchCategory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderSearch);
