import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import {
  apiBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
  FormatCurrency,
} from "../Util";
import "../../App.css";
import "../../form.css";
import "../../generic.css";
import "../../prodPreview.css";
import "../../bt.css";

class BuyTogether extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMode: false,
      uniqueKey: this.props.uniqueKey,
      bti: this.props.bti,
      exists: true,
      editAllowed: checkUpdate("Product"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.addOrUpdateBT = this.addOrUpdateBT.bind(this);
    this.fetchBT = this.fetchBT.bind(this);
    this.markBT = this.markBT.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.processBTResponse = this.processBTResponse.bind(this);
  }

  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    if (this.props.bti) {
      const uniqueKey = this.props.bti.item.data.pvList[this.props.bti.item.id]
        .uniqueKey;
      this.setState({ bti: this.props.bti, uniqueKey });
    } else {
      this.fetchBT(this.props.uniqueKey);
    }
  }
  componentDidUpdate(preProps) {
    if (preProps.uniqueKey !== this.props.uniqueKey) {
      this.setState({
        uniqueKey: this.props.uniqueKey,
      });
      this.fetchBT(this.props.uniqueKey);
    } else if (this.props.bti && !preProps.bti) {
      const uniqueKey = this.props.bti.item.data.pvList[this.props.bti.item.id]
        .uniqueKey;
      this.setState({ bti: this.props.bti, uniqueKey });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  addOrUpdateBT() {
    const url = "/editBT/" + this.state.uniqueKey;
    this.props.history.push(url);
  }
  processMarkResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      window.location.reload();
    }
  }
  processBTResponse(response) {
    if (response.data && response.data.exists) {
      const item = {
        ...response.data.item,
        checked: true,
      };
      let otherItems = [],
        i;
      if (response.data.otherItems) {
        for (i = 0; i < response.data.otherItems.length; i++) {
          const aItem = {
            ...response.data.otherItems[i],
            checked: true,
          };
          otherItems.push(aItem);
        }
      }
      const bti = {
        ...response.data,
        item,
        otherItems,
      };
      this.setState({ bti });
    } else {
      this.setState({ bti: null });
    }
  }

  fetchBT(uniqueKey) {
    const url = apiBaseUrl() + "GetBTInfo";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: uniqueKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processBTResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Error fetching data",
          isLoading: false,
        });
        console.log(error);
      });
  }
  markBT(event) {
    event.preventDefault();
    const url = apiBaseUrl() + "MarkBT";
    const data = {
      id: this.state.bti.id,
      enabled: !this.state.bti.enabled,
    };
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processMarkResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Error updating",
          isLoading: false,
        });
        console.log(error);
      });
  }

  getImageUrl(item) {
    const pv = item.data.pvList[item.id];
    const img = pv.imageUrl ? pv.imageUrl : item.data.imageUrl;
    return img;
  }
  handleClick(event) {
    const name = event.target.name;
    if (name.startsWith("item_")) {
      const idx = parseInt(name.substring(5));
      let otherItems = [],
        i;
      const oldOI = this.state.bti.otherItems;
      for (i = 0; i < oldOI.length; i++) {
        if (i === idx) {
          const item = {
            ...oldOI[i],
            checked: event.target.checked,
          };
          otherItems.push(item);
        } else {
          otherItems.push(oldOI[i]);
        }
      }
      const bti = {
        ...this.state.bti,
        otherItems,
      };
      this.setState({ bti });
    } else {
      const item = {
        ...this.state.bti.item,
        checked: event.target.checked,
      };
      const bti = {
        ...this.state.bti,
        item,
      };
      this.setState({ bti });
    }
  }

  showBTI() {
    const bti = this.state.bti;
    const checked = this.state.bti.item.checked;
    let totalPrice = checked
      ? this.state.bti.item.data.pvList[this.state.bti.item.id].finalPrice
      : 0;
    let i,
      itemCount = totalPrice > 0 ? 1 : 0;
    for (i = 0; i < bti.otherItems.length; i++) {
      if (bti.otherItems[i].checked) {
        totalPrice +=
          bti.otherItems[i].data.pvList[bti.otherItems[i].id].finalPrice;
        itemCount++;
      }
    }
    let btnTitle = null;
    if (itemCount > 2 || itemCount === 1)
      btnTitle = "Add " + itemCount + " Items";
    else if (itemCount === 2) btnTitle = "Add Both Items";
    const pv2 = bti.item.data.pvList[bti.item.id];
    const url2 = "/product/" + pv2.productId + "/" + pv2.sku;
    const imgId2 = pv2.forSale ? "img" : "soldOutImage";
    return (
      <div>
        <div className="generic-flex2">
          {checked &&
            (this.props.title ? (
              <div>
                <a href={url2}>
                  <img
                    id={imgId2}
                    src={fullProductImageUrl(this.getImageUrl(bti.item))}
                    width="60"
                    height="45"
                    alt="BT"
                  />
                </a>
              </div>
            ) : (
              <div>
                <img
                  id={imgId2}
                  src={fullProductImageUrl(this.getImageUrl(bti.item))}
                  width="60"
                  height="45"
                  alt="BT"
                />
              </div>
            ))}

          {bti.otherItems.map((item, idx) => {
            if (item.checked) {
              const pv = item.data.pvList[item.id];
              const imgId = pv.forSale ? "img" : "soldOutImage";
              const url = "/product/" + pv.productId + "/" + pv.sku;
              return (
                <React.Fragment key={idx}>
                  {(checked || (itemCount > 1 && idx > 0)) && <div>+</div>}
                  <div>
                    <a href={url}>
                      <img
                        id={imgId}
                        src={fullProductImageUrl(this.getImageUrl(item))}
                        width="60"
                        height="45"
                        alt="BT"
                      />
                    </a>
                  </div>
                </React.Fragment>
              );
            }
          })}
          {itemCount > 0 && (
            <React.Fragment>
              <div className="left-10">&nbsp;</div>
              <div className="bti-div2">
                Total Price:{" "}
                <b>
                  <font color="red">
                    <FormatCurrency amount={totalPrice} />
                  </font>
                </b>
                <br />
                {btnTitle && (
                  <button className="atc-btn-style" name="add" disabled={true}>
                    {btnTitle}
                  </button>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="bti-div-b10">
          <input
            type="checkbox"
            className="bti-cb"
            name="item"
            defaultChecked={checked}
            onClick={this.handleClick}
          />
          This item
          <br />
          {bti.otherItems.map((item, idx) => {
            const name = "item_" + idx;
            const pv = item.data.pvList[item.id];
            const title = pv.fullName ? pv.fullName : pv.variantValue;
            return (
              <React.Fragment key={idx}>
                <input
                  type="checkbox"
                  className="bti-cb"
                  name={name}
                  defaultChecked={item.checked}
                  onClick={this.handleClick}
                />
                {title} ({pv.sku})
                <br />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
  showAll() {
    if (this.state.bti) {
      const edBtnTitle = this.state.bti.enabled ? "Disable" : "Enable";
      const mbtnStyle = this.state.bti.enabled
        ? "bt-red-btn-style"
        : "bt-btn-style";
      const clsName1 = this.state.mobileMode ? "none" : "generic-flex";
      const clsName2 = this.state.mobileMode ? "generic-flex" : "left-20";
      const clsName3 = this.state.mobileMode ? "none" : "top-5";
      return (
        <div>
          {this.props.title ? (
            <div className="bti-div-small">
              <b>{this.props.title}</b>
            </div>
          ) : (
            <div className="bti-div-small">
              {this.state.bti.enabled ? (
                <b>Buy Together (Enabled)</b>
              ) : (
                <b>
                  Buy Together (<font color="red">Disabled</font>)
                </b>
              )}
            </div>
          )}

          <div className={clsName1}>
            {this.showBTI()}
            {this.state.editAllowed && (
              <div className={clsName2}>
                <div>
                  <button
                    name="update"
                    onClick={this.addOrUpdateBT}
                    className="bt-btn-style"
                  >
                    Update
                  </button>
                </div>

                <div className={clsName3}>
                  <button
                    name="update"
                    onClick={this.markBT}
                    className={mbtnStyle}
                  >
                    {edBtnTitle}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      if (this.state.editAllowed) {
        return (
          <div>
            <button
              name="add"
              onClick={this.addOrUpdateBT}
              className="btn-style"
            >
              Add Buy Together
            </button>
          </div>
        );
      }
    }
  }

  render() {
    return this.showAll();
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuyTogether));
