import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import TextAreaInput from "../validation/TextAreaInput";
import FileInput from "../validation/FileInput";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import {
  apiBaseUrl,
  fullProductImageUrl,
  previewImgBaseUrl,
  isUserLoggedIn,
  toTimeStr,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
  manualBaseUrl,
} from "../Util";
import { uploadFiles } from "../FileUploader";
import InvDelButtons from "./InvDelButtons";
import BuyTogether from "./BuyTogether";
import RankingInfoDialog from "./RankingInfoDialog";
import "../../App.css";
import "../../form.css";
import ImageWindow from "./ImageWindow";
import Working from "../Working";

const UPLD_PRODIMG = 1;
const UPLD_PVIMG = 3;
const UPLD_MANUAL = 5;

class EditProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      origComboId: null,
      forSaleColor: "green",
      duplicateSku: false,
      categories: null,
      finalPrice: 0,
      suppliers: [{ id: 1, name: "InStock" }],
      isLoadingCat: true,
      isLoading: true,
      isLoadingSup: true,
      addProduct: false,
      pgDisabled: false,
      errorMessage: null,
      mobileMode: false,
      updBtnDisabled: true,
      delayRefresh: false,
      isUpdating: false,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
      deleteAllowed: checkSecurity("PurgeProduct"),
      advAllowed: checkSecurity("AdvancedProduct"),
    };

    this.processAddedRsp = this.processAddedRsp.bind(this);
    this.processDeleteProdRsp = this.processDeleteProdRsp.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.doMakeInvisible = this.doMakeInvisible.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.setActionTypes = this.setActionTypes.bind(this);
    this.manageImageSetting = this.manageImageSetting.bind(this);
    this.processDelSymLinkRsp = this.processDelSymLinkRsp.bind(this);
    this.addSymLink = this.addSymLink.bind(this);
    this.addVariant = this.addVariant.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.initProduct = this.initProduct.bind(this);
    this.editCombo = this.editCombo.bind(this);
    this.manageImgMan = this.manageImgMan.bind(this);
    this.showThumbnail = this.showThumbnail.bind(this);
    this.processDataResponse = this.processDataResponse.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    if (!this.props.match.params.id) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.setActionTypes();
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  componentDidUpdate(preProps) {
    if (this.props.match.params.id !== preProps.match.params.id) {
      this.setActionTypes();
      this.fetchData();
    }
  }

  processDataResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const product = response.product;
      if (product) {
        const pv = product.pvList.length > 1 ? null : product.pvList[0];
        let forSaleColor = this.state.forSaleColor;
        if (pv) {
          forSaleColor = pv.forSale ? "green" : "red";
        }
        this.setState({
          product,
          origComboId: product.comboId,
          forSaleColor,
          isLoading: false,
        });
      }

      //Categories
      const categories = response.categories;
      if (categories) {
        this.setState({
          categories,
          isLoadingCat: false,
        });
      } else {
        this.setState({
          categories: [{ id: 1, name: "InStock" }],
          isLoadingCat: false,
        });
      }

      if (categories && this.props.match.params.id === "+") {
        let categoryId = this.props.categoryId;
        if (!categoryId) categoryId = categories[0].category.id;
        this.setState({ categoryId });
      }

      //Suppliers
      const suppliers = response.suppliers;
      this.setState({
        suppliers,
        isLoadingSup: false,
      });

      if (
        suppliers &&
        (this.props.match.params.id === "+" ||
          this.props.match.params.sku === "+")
      ) {
        this.setState({ supplierId: suppliers[0].id });
      }
    }
  }
  setActionTypes() {
    const addProduct = this.props.match.params.id === "+" ? true : false;

    this.setState({ addProduct });
    if (addProduct === true) {
      this.setState({ isLoading: false, delayRefresh: true });
    }
  }
  fetchData() {
    let id = this.props.match.params.id;
    if (id === "+") {
      this.initProduct();
      id = 0;
    }

    const url = apiBaseUrl() + "GetProdVarForEdit";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id,
      editProduct: true,
    };
    this.setState({ isLoading: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processDataResponse(res.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }
  initProduct() {
    const categoryId = this.props.categoryId ? this.props.categoryId : 1;
    const pv = {
      productId: 0,
      categoryId,
      unitsInStock: 100,
      assemblyFee: -1,
      inStockTime: 0,
      sequence: 1,
      retailPrice: 0,
      forSale: true,
      supplierId: 1,
      assemblyRequired: true,
      visible: true,
    };
    const pvList = [pv];
    const product = {
      categoryId,
      pvList,
      showInCatPage: true,
      rankingScore: 10,
    };

    this.setState({ product, isLoading: false });
  }
  processUpdatedRsp(response) {
    if (response.invalidSession === true) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        if (response.value > 0) {
          const url = "/product/" + response.value;
          this.props.history.push(url);
        } else {
          const url = "/product-category/" + this.props.categoryId;
          this.props.history.push(url);
        }
      } else {
        let errorMessage = "Error: " + response.errorMessage;
        let duplicateSku = false;
        if (response.value > 0) {
          errorMessage +=
            ". The SKU has been used by <a href='/product/" +
            response.value +
            "'>" +
            response.value +
            "</a>";
          duplicateSku = true;
        }
        this.setState({ errorMessage, duplicateSku });
      }
    }
  }
  processAddedRsp(response) {
    if (response.invalidSession === true) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.value > 0) {
      const url = "/product/" + response.value;

      this.setState({ errorMessage: null });
      this.props.history.push(url);
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  processDeleteProdRsp(response) {
    if (response.invalidSession === true) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const url = "/product-category/" + this.props.categoryId;
      this.props.history.push(url);
    }
  }

  processUploadResponse(response, fileName, type) {
    if (response.status) {
      let product;
      if (type === UPLD_PRODIMG) {
        let imageInfoList = this.state.product.imageInfoList;

        if (!imageInfoList) imageInfoList = [];
        imageInfoList.push({ id: 0, url: fileName });

        product = {
          ...this.state.product,
          imageInfoList,
        };
      } else if (type == UPLD_PVIMG) {
        let imageInfoList = this.state.product.pvList[0].imageInfoList;

        if (!imageInfoList) imageInfoList = [];
        imageInfoList.push({ id: 0, url: fileName });

        const pv = {
          ...this.state.product.pvList[0],
          imageInfoList,
        };

        product = {
          ...this.state.product,
          pvList: [pv],
        };
      } else {
        //Maunal
        const pos = fileName.lastIndexOf(".");
        const pdf = pos > 0 && fileName.substring(pos) == ".pdf" ? true : false;
        let installManualList = this.state.product.installManualList;

        if (!installManualList) installManualList = [];
        installManualList.push({ id: 0, url: fileName, pdf });
        product = {
          ...this.state.product,
          installManualList,
        };
      }
      this.setState({ product, updBtnDisabled: false });
    } else {
      console.log("Upload failed");
    }
  }
  doMakeInvisible() {
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.product.id,
    };

    const url = apiBaseUrl() + "HideProduct";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdatedRsp(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  doDelete() {
    const url = apiBaseUrl() + "DeleteProd";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.product.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processDeleteProdRsp(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  addProduct() {
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.product,
      delayMainRefresh: this.state.delayRefresh,
    };
    const url = apiBaseUrl() + "AddProduct";

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processAddedRsp(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleUpdate(event) {
    if (this.state.addProduct === true) {
      return this.addProduct();
    }

    const url = apiBaseUrl() + "UpdateProd";
    let product = this.state.product;

    if (product && product.pvList && product.pvList.length > 1) {
      //Multi-variant product, only update the "Product" part
      product = {
        ...this.state.product,
        pvList: null,
      };
    }
    this.setState({ updBtnDisabled: true, isUpdating: true });
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: product,
      delayMainRefresh: this.state.delayRefresh,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ isUpdating: false });
        this.processUpdatedRsp(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isUpdating: false });
      });
  }

  handleChange(event) {
    let name = event.target.name;
    if (name === "delayRefresh") {
      this.setState({ delayRefresh: event.target.checked });
    } else if (name === "pgDisabled") {
      this.setState({ pgDisabled: event.target.checked });
    } else if (name.startsWith("Upload_")) {
      const type = parseInt(name.substring(7));
      if (event.target.files && event.target.files.length > 0) {
        uploadFiles(event.target.files, this.processUploadResponse, type);
      }
    } else if (name.startsWith("P_")) {
      let value = event.target.value;

      name = name.substring(2);
      if (name.startsWith("CB_")) {
        name = name.substring(3);
        value = event.target.checked;
      }
      const product = {
        ...this.state.product,
        [name]: value,
      };
      this.setState({ product, updBtnDisabled: false });
    } else if (name.startsWith("V_")) {
      const pv = this.state.product.pvList[0];
      let value = event.target.value;
      let forSaleColor = this.state.forSaleColor;
      let duplicateSku = this.state.duplicateSku;

      name = name.substring(2);
      let inStockTime = name === "inStockTime" ? value : pv.inStockTime;

      if (name.startsWith("CB_")) {
        name = name.substring(3);
        value = event.target.checked;
        if (name === "forSale") {
          forSaleColor = event.target.checked ? "green" : "red";
        }
      } else {
        if (name === "sku") duplicateSku = false;
        else if (name === "supplierId") {
          const supId = parseInt(value);
          const found = this.state.suppliers.find((sup) => sup.id === supId);
          if (found) {
            inStockTime = found.inStockTime;
          }
        }
      }
      let finalPrice = this.state.finalPrice;
      if (name === "salePrice") {
        finalPrice = 0;
        if (value && value.trim().length > 0) {
          try {
            finalPrice = parseFloat(value);
            finalPrice = Math.round(finalPrice);
          } catch (error) {}
        }
      }
      const newPv = {
        ...pv,
        [name]: value,
        inStockTime,
      };
      const product = {
        ...this.state.product,
        pvList: [newPv],
      };
      this.setState({
        product,
        forSaleColor,
        duplicateSku,
        finalPrice,
        updBtnDisabled: false,
      });
    } else {
      console.log("ERROR! Unknown name=" + event.target.name);
    }
  }
  manageImgMan(type, isPv) {
    let url = "/manageImgMan/" + type + this.state.product.id;
    if (isPv) url += "/" + this.state.product.pvList[0].uniqueKey;
    this.props.history.push(url);
  }
  showVariantImage() {
    const pv = this.state.product.pvList[0];
    const showVI = this.state.addProduct || !pv.imageUrl ? false : true;

    if (showVI) {
      const iiList = pv.imageInfoList;
      const allowAdd =
        this.state.editAllowed && this.state.addProduct ? true : false;
      const allowManage = this.state.editAllowed && !allowAdd;
      const vTitle = this.state.mobileMode ? "Var Image" : "Variant Image";
      const name = "Upload_" + UPLD_PVIMG;

      return (
        <React.Fragment>
          <div className="form-item-label">{vTitle}</div>
          <div className="generic-flex">
            {iiList && iiList.length > 0 && (
              <div className="generic-flex">
                {iiList.map((info, idx) => {
                  const imgUrl = this.state.addProduct
                    ? previewImgBaseUrl() + info.url
                    : fullProductImageUrl(info.url);
                  return (
                    <div key={idx}>
                      <a href={imgUrl} target="Image">
                        <img src={imgUrl} width="36" height="24"></img>
                      </a>
                    </div>
                  );
                })}
                <div>&nbsp;</div>
              </div>
            )}

            {allowAdd && (
              <div>
                <input
                  type="file"
                  name={name}
                  multiple="true"
                  onChange={this.handleChange}
                ></input>
              </div>
            )}

            {allowManage && (
              <div>
                <button
                  name="managePvImg"
                  onClick={() => this.manageImgMan("P_", true)}
                >
                  Manage Pictures
                </button>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }
  }

  variantForm() {
    if (this.state.product.pvList && this.state.product.pvList.length === 1) {
      const pv = this.state.product.pvList[0];
      const uTitle = this.state.mobileMode ? "Units" : "Units In Stock";
      const aTitle = this.state.mobileMode ? "Assembly" : "Assembly Fee";
      const dTitle = this.state.mobileMode ? "In Stock" : "In Stock Days";
      const rTitle = this.state.mobileMode ? "Retail" : "Retail Price";
      const txtLen = this.state.mobileMode ? 40 : 45;
      const finalPriceLabel =
        this.state.finalPrice && this.state.finalPrice > 0
          ? "(Final: $" + this.state.finalPrice.toFixed(0) + ")"
          : null;
      return (
        <React.Fragment>
          <div className="form-item-label">
            <hr></hr>
          </div>
          <div className="form-item-value">&nbsp;</div>
          <div className="form-item-label">
            {this.state.duplicateSku ? (
              <font color="red">
                <b>SKU:</b>
              </font>
            ) : (
              <font>SKU:</font>
            )}
          </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="V_sku"
              size="30"
              value={pv.sku}
              disabled={!this.state.editAllowed}
              validators={["required"]}
              errorMessages={["SKU is required"]}
            />
          </div>
          <div className="form-item-label">Variant:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="40"
              name="V_variantValue"
              disabled={!this.state.editAllowed}
              placeholder="Required for multiple variants"
              value={pv.variantValue || ""}
              onChange={this.handleChange}
            ></input>
          </div>
          {this.state.product.variantName &&
            pv.variantValue &&
            pv.variantValue.length > 0 && (
              <React.Fragment>
                <div className="form-item-label">Short Name:</div>
                <div className="form-item-value">
                  <input
                    type="text"
                    size="12"
                    name="V_shortName"
                    disabled={!this.state.editAllowed}
                    value={pv.shortName || ""}
                    onChange={this.handleChange}
                  ></input>{" "}
                  (for Name in Box)
                </div>
                <div className="form-item-label">Color:</div>
                <div className="form-item-value">
                  <input
                    type="text"
                    size="12"
                    name="V_color"
                    disabled={!this.state.editAllowed}
                    value={pv.color || ""}
                    onChange={this.handleChange}
                  ></input>{" "}
                  (for Name Color in Box)
                </div>
              </React.Fragment>
            )}
          <div className="form-item-label">Sequence:</div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="V_sequence"
              disabled={!this.state.editAllowed}
              value={pv.sequence}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="form-item-label">Settings:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="V_CB_forSale"
              disabled={!this.state.editAllowed}
              defaultChecked={pv.forSale}
              onChange={this.handleChange}
            ></input>{" "}
            <font color={this.state.forSaleColor}>For Sale</font> <br />
            <input
              type="checkbox"
              name="V_CB_assemblyRequired"
              disabled={!this.state.editAllowed}
              defaultChecked={pv.assemblyRequired}
              onChange={this.handleChange}
            ></input>{" "}
            Assembly Required
            <br />
            <input
              type="checkbox"
              name="V_CB_newArrival"
              disabled={!this.state.editAllowed}
              defaultChecked={pv.newArrival}
              onChange={this.handleChange}
            ></input>{" "}
            New Arrival <br />
            <input
              type="checkbox"
              name="V_CB_onSale"
              disabled={!this.state.editAllowed}
              defaultChecked={pv.onSale}
              onChange={this.handleChange}
            ></input>{" "}
            On Sale
          </div>
          <div className="form-item-label">Supplier:</div>
          <div className="form-item-value">
            <select
              id="supplierId"
              name="V_supplierId"
              disabled={!this.state.editAllowed}
              value={pv.supplierId}
              onChange={this.handleChange}
            >
              {this.state.suppliers.map((sup) => {
                return (
                  <option value={sup.id} key={sup.id}>
                    {sup.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-item-label">{uTitle}:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="V_unitsInStock"
              size="6"
              type="text"
              inputmode="decimal"
              value={pv.unitsInStock}
              disabled={!this.state.editAllowed}
              validators={["required"]}
              errorMessages={["Units In Stock is required"]}
            />
          </div>
          <div className="form-item-label">Sale Price:</div>
          <div className="generic-flex">
            <div>
              {this.state.pgDisabled ? (
                <TextInput
                  onChange={this.handleChange}
                  name="V_salePrice"
                  type="text"
                  inputmode="decimal"
                  size="8"
                  value={pv.salePrice}
                  disabled={!this.state.editAllowed}
                  validators={["isNumber"]}
                  errorMessages={["Invalid Sale Price"]}
                />
              ) : (
                <TextInput
                  onChange={this.handleChange}
                  name="V_salePrice"
                  type="text"
                  inputmode="decimal"
                  size="8"
                  value={pv.salePrice}
                  disabled={!this.state.editAllowed}
                  validators={["isNumber", "minNumber:40"]}
                  errorMessages={[
                    "Invalid Sale Price",
                    "Sale Price is too low",
                  ]}
                />
              )}
            </div>
            {this.state.advAllowed ? (
              <div>
                <input
                  type="checkbox"
                  name="pgDisabled"
                  defaultChecked={this.state.pgDisabled}
                  onChange={this.handleChange}
                  disabled={!this.state.advAllowed}
                />{" "}
                Disable price guard
              </div>
            ) : (
              <div>&nbsp;{finalPriceLabel}</div>
            )}
          </div>
          <div className="form-item-label">{aTitle}:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="V_assemblyFee"
              inputmode="decimal"
              size="8"
              value={pv.assemblyFee}
              disabled={!this.state.editAllowed || !pv.assemblyRequired}
            />
          </div>
          <div className="form-item-label">{rTitle}:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="V_retailPrice"
              inputmode="decimal"
              size="8"
              disabled={!this.state.editAllowed}
              value={pv.retailPrice}
            />
          </div>
          <div className="form-item-label">{dTitle}:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="V_inStockTime"
              inputmode="decimal"
              size="8"
              value={pv.inStockTime}
              disabled={!this.state.editAllowed}
              validators={["required"]}
              errorMessages={["In Stock Time is required"]}
            />
          </div>
          {this.showVariantImage()}
          <div className="form-item-label">Dimensions:</div>
          <div className="form-item-value">
            <div className="small-form-wrapper">
              <div className="form-item-lavel">L: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  inputmode="decimal"
                  size="6"
                  name="V_length"
                  value={pv.length}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>{" "}
                (inches)
              </div>
              <div className="form-item-lavel">D: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  inputmode="decimal"
                  size="6"
                  name="V_width"
                  value={pv.width}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>{" "}
                (inches)
              </div>
              <div className="form-item-lavel">H: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  inputmode="decimal"
                  size="6"
                  name="V_height"
                  value={pv.height}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>{" "}
                (inches)
              </div>
            </div>
          </div>
          <div className="form-item-label">Components:</div>
          <div className="form-item-value">
            <input
              type="text"
              size={txtLen}
              name="V_components"
              disabled={!this.state.editAllowed}
              placeholder="[For searching related products]"
              value={pv.components || ""}
              onChange={this.handleChange}
            ></input>
          </div>
        </React.Fragment>
      );
    }
  }
  getCategoryName(catId) {
    try {
      for (var i = 0; i < this.state.categories.length; i++) {
        if (this.state.categories[i].category.id === catId) {
          return this.state.categories[i].category.name;
        }
      }
    } catch (error) {
      console.log(error);
    }
    return "" + catId;
  }
  manageImageSetting() {
    const url = "/imageSetting/" + this.state.product.id;
    this.props.history.push(url);
  }
  showImages() {
    if (this.state.mobileMode) {
      return (
        <a
          href={fullProductImageUrl(this.state.product.imageUrl)}
          target="Image"
        >
          <img
            src={fullProductImageUrl(this.state.product.imageUrl)}
            width="50"
            height="30"
            alt="Tiny"
          ></img>{" "}
          &nbsp;&nbsp;&nbsp;
        </a>
      );
    } else {
      let iiList = this.state.product.imageInfoList;
      if (!iiList) {
        iiList = [{ url: this.state.product.imageUrl }];
      }
      if (iiList) {
        return (
          <React.Fragment>
            {iiList.map((info, idx) => {
              return (
                <a
                  href={fullProductImageUrl(info.url)}
                  target="Image"
                  key={"img" + idx}
                >
                  <img
                    src={fullProductImageUrl(info.url)}
                    width="50"
                    height="30"
                    alt="Tiny"
                  ></img>{" "}
                  &nbsp;&nbsp;&nbsp;
                </a>
              );
            })}
          </React.Fragment>
        );
      }
    }
  }
  showThumbnail(event) {
    event.preventDefault();
    document.getElementById("hiddenImgWindowBtn").click();
  }
  hiddenStuff() {
    const tnUrl = this.state.product ? this.state.product.thumbnailImage : null;
    if (tnUrl) {
      return (
        <ImageWindow
          imageUrl={tnUrl}
          width={400}
          height={300}
          btnId="hiddenImgWindowBtn"
        />
      );
    }
  }
  showImagesAndButtons() {
    const btnTitle = this.state.mobileMode ? "Manage Pic" : "Manage Picture";
    const tnUrl =
      this.state.product && !this.state.mobileMode
        ? this.state.product.thumbnailImage
        : null;

    return (
      <div className="flex-wrapper">
        {this.showImages()}
        {this.state.editAllowed && (
          <button
            name="managePic"
            onClick={() => this.manageImgMan("P_", false)}
            className="left-10"
          >
            {btnTitle}
          </button>
        )}
        <button
          name="manageImageSetting"
          onClick={this.manageImageSetting}
          className="left-10"
        >
          Image Setting
        </button>
        {tnUrl && (
          <span>
            &nbsp;&nbsp;
            <img
              className="hand"
              src={getIconBaseUrl() + "thumbnail.jpg"}
              height={20}
              onClick={this.showThumbnail}
            ></img>
            <ImageWindow
              imageUrl={tnUrl}
              width={400}
              height={300}
              btnId="hiddenImgWindowBtn"
            />
          </span>
        )}
      </div>
    );
  }
  showComboId() {
    if (
      !this.state.product ||
      !this.state.product.pvList ||
      this.state.product.pvList.length === 1
    ) {
      return true;
    }
    return false;
  }
  editCombo() {
    const url =
      "/editCombo/" + this.state.product.comboId + "/" + this.state.product.id;
    this.props.history.push(url);
  }

  showInstallManual() {
    let list = this.state.product.installManualList;
    let manuals = null;
    if (list && list.length > 0) {
      const baseUrl = this.state.addProduct
        ? previewImgBaseUrl()
        : manualBaseUrl();

      manuals = list.map((man) => {
        const manualUrl = baseUrl + man.url;
        const icon = man.pdf ? getIconBaseUrl() + "pdf.jpg" : manualUrl;
        return (
          <div>
            <a href={manualUrl} target="UserManual">
              <img src={icon} width="32"></img>&nbsp;
            </a>
          </div>
        );
      });
    }
    const name = "Upload_" + UPLD_MANUAL;
    return (
      <div className="form-item-value">
        <div className="flex-center">
          {manuals}
          <div>
            {this.state.addProduct ? (
              <input
                type="file"
                multiple={true}
                name={name}
                disabled={!this.state.editAllowed}
                onChange={this.handleChange}
              ></input>
            ) : (
              <button
                name="updateMan"
                onClick={() => this.manageImgMan("M_", false)}
                disabled={!this.state.editAllowed}
              >
                Update Manual
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  showRankingDetail(event) {
    event.preventDefault();
    document.getElementById("hiddenDialogBtn").click();
  }
  productForm() {
    const product = this.state.product;
    const dt = product.id > 0 ? product.dateAdded : null;
    const dtStr = toTimeStr(dt);
    const updDt = product.id > 0 ? product.lastUpdated : null;
    const updDtStr = toTimeStr(updDt);
    const txtLen = this.state.mobileMode ? 40 : 60;
    const colCnt = this.state.mobileMode ? 44 : 80;
    const rowCnt = this.state.mobileMode ? 10 : 12;
    const pTitle = this.state.mobileMode ? "Name" : "Product Name";
    const iTitle = this.state.mobileMode ? "Images" : "Product Images";
    const rTitle = this.state.mobileMode ? "Ranking" : "Ranking Score";
    const vTitle = this.state.mobileMode ? "Var Name" : "Variant Name";
    const mTitle = this.state.mobileMode ? "Manual" : "Assembly Manual";
    const shortName = product.shortName ? product.shortName : "";
    const variantName = product.variantName ? product.variantName : "";
    let comboBtnTitle = null;
    if (
      this.state.origComboId &&
      this.state.product.comboId === this.state.origComboId
    ) {
      if (this.state.product.comboExists) comboBtnTitle = "Update Combo";
      else comboBtnTitle = "Configure Combo";
    }
    const comboImg = getIconBaseUrl() + "combo.jpg";
    const updName = "Upload_" + UPLD_PRODIMG;
    let vdName = null;
    let vdIcon = null;
    if (product.variantName && product.vdList) {
      const vd = product.vdList.find(
        (item) => item.id === product.variantDisplay
      );
      if (vd) {
        vdName = vd.name;
        if (product.variantDisplay === 2) {
          //thumbnail
          vdIcon = fullProductImageUrl(product.imageUrl);
        } else {
          vdIcon = getIconBaseUrl() + vd.icon;
        }
      } else vdName = "" + product.variantDisplay;
    }

    return (
      <React.Fragment>
        {product.id > 0 && (
          <React.Fragment>
            <div className="form-item-label">Product ID:</div>
            <div className="form-item-value">{product.id}</div>
            <div className="form-item-label">Date Added:</div>
            <div className="form-item-value">{dtStr}</div>
            {updDtStr && (
              <React.Fragment>
                {" "}
                <div className="form-item-label">Updated:</div>
                <div className="form-item-value">{updDtStr}</div>
              </React.Fragment>
            )}
            <div className="form-item-label">{iTitle}:</div>
            <div className="form-item-value">{this.showImagesAndButtons()}</div>
          </React.Fragment>
        )}
        {this.state.addProduct === true && (
          <React.Fragment>
            <div className="form-item-label">{iTitle}:</div>
            <div className="form-item-value">
              {this.state.editAllowed && (
                <FileInput
                  name={updName}
                  multiple="true"
                  onChange={this.handleChange}
                ></FileInput>
              )}
              {this.state.product.imageInfoList &&
                this.state.product.imageInfoList.map((info, idx) => {
                  const url = previewImgBaseUrl() + info.url;
                  return (
                    <a href={url} target="NewImage" key={idx}>
                      <img src={url} width="50" height="30" alt="Tiny"></img>
                      &nbsp;&nbsp;&nbsp;
                    </a>
                  );
                })}
            </div>
          </React.Fragment>
        )}
        <div className="form-item-label">Category:</div>
        <div className="form-item-value">
          <div className="generic-flex">
            <div>
              <select
                id="categoryId"
                name="P_categoryId"
                value={product.categoryId}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              >
                {this.state.categories.map((cat) => {
                  return (
                    <option value={cat.category.id} key={cat.category.id}>
                      {cat.category.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              &nbsp;
              <select
                id="categoryId2"
                name="P_categoryId2"
                value={product.categoryId2}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              >
                <option value={0}></option>
                {this.state.categories.map((cat) => {
                  return (
                    <option value={cat.category.id} key={cat.category.id}>
                      {cat.category.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              &nbsp;
              <select
                id="categoryId3"
                name="P_categoryId3"
                value={product.categoryId3}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              >
                <option value={0}></option>
                {this.state.categories.map((cat) => {
                  return (
                    <option value={cat.category.id} key={cat.category.id}>
                      {cat.category.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {!this.state.mobileMode && (
              <div>
                &nbsp;
                <select
                  id="categoryId4"
                  name="P_categoryId4"
                  value={product.categoryId4}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                >
                  <option value={0}></option>
                  {this.state.categories.map((cat) => {
                    return (
                      <option value={cat.category.id} key={cat.category.id}>
                        {cat.category.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
        </div>
        <div className="form-item-label">{pTitle}:</div>
        <div className="form-item-value">
          <TextInput
            onChange={this.handleChange}
            name="P_name"
            size={txtLen}
            value={product.name}
            disabled={!this.state.editAllowed}
            validators={["required"]}
            errorMessages={["Name is required"]}
          />
        </div>
        <div className="form-item-label">Short Name:</div>
        <div className="form-item-value">
          <TextInput
            onChange={this.handleChange}
            name="P_shortName"
            size="25"
            placeholder="For mobile devices"
            value={shortName}
            disabled={!this.state.editAllowed}
          />
        </div>
        <div className="form-item-label">Show:</div>
        <div className="form-item-value">
          <input
            type="checkbox"
            name="P_CB_showInCatPage"
            disabled={!this.state.editAllowed}
            defaultChecked={product.showInCatPage}
            onChange={this.handleChange}
          ></input>{" "}
          Show In Category Page
        </div>
        <div className="form-item-label">Description:</div>
        <div className="form-item-value">
          <TextAreaInput
            onChange={this.handleChange}
            name="P_description"
            rows={rowCnt}
            cols={colCnt}
            value={product.description}
            disabled={!this.state.editAllowed}
            validators={["required"]}
            errorMessages={["Description is required"]}
          />
        </div>
        {this.state.advAllowed && (
          <React.Fragment>
            <div className="form-item-label">Keywords:</div>
            <div className="form-item-value">
              <textarea
                onChange={this.handleChange}
                name="P_note"
                rows={6}
                cols={colCnt}
                disabled={!this.state.editAllowed}
                placeholder="Enter important keywords here, they can greatly improve search results."
                value={product.note || ""}
              />
            </div>
          </React.Fragment>
        )}
        <div className="form-item-label">{vTitle}:</div>
        <div className="form-item-value">
          <input
            type="text"
            name="P_variantName"
            size="30"
            disabled={!this.state.editAllowed}
            placeholder="Required for multiple variants"
            value={variantName}
            onChange={this.handleChange}
          />{" "}
          (*)
        </div>
        {this.state.advAllowed && (
          <React.Fragment>
            <div className="form-item-label">{rTitle}:</div>
            <div className="form-item-value">
              <input
                type="text"
                inputmode="decimal"
                name="P_rankingScore"
                maxlength={5}
                size="5"
                value={product.rankingScore}
                disabled={!this.state.editAllowed}
                onChange={this.handleChange}
              />
              &nbsp;
              {product.rankingInfo && (
                <span>
                  (Final Score:{" "}
                  <a href="#" onClick={this.showRankingDetail}>
                    <b>{product.rankingInfo.finalRankingScore}</b>
                  </a>
                  &nbsp;)
                </span>
              )}
            </div>
            {this.showComboId() && (
              <React.Fragment>
                <div className="form-item-label">Combo ID:</div>
                <div className="form-item-value">
                  <input
                    type="text"
                    name="P_comboId"
                    size="12"
                    value={product.comboId || ""}
                    disabled={!this.state.editAllowed}
                    onChange={this.handleChange}
                  />
                  &nbsp;
                  {comboBtnTitle ? (
                    <a href="#None" onClick={this.editCombo}>
                      <img src={comboImg} height="16"></img>&nbsp;
                      {comboBtnTitle}
                    </a>
                  ) : (
                    <i>(for combo products)</i>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <div className="form-item-label">{mTitle}:</div>
        {this.showInstallManual()}
        {vdName && (
          <React.Fragment>
            <div className="form-item-label">Variant Style:</div>
            <div className="form-item-value-flex">
              {vdIcon && (
                <div>
                  <img src={vdIcon} height="22" width="30" alt="VD" />
                  &nbsp;&nbsp;
                </div>
              )}
              <div>
                <a href={"/editVD/" + product.id}>
                  <b>{vdName}</b>
                </a>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  showAddUpdateButton() {
    if (this.state.editAllowed) {
      const btnStyle = this.state.updBtnDisabled
        ? "disabled-btn-style"
        : "btn-style";
      const btnTitle =
        this.state.addProduct === true ? "Add Product" : "Update";
      return (
        <div className="top-10">
          <button
            className={btnStyle}
            type="submit"
            disabled={this.state.updBtnDisabled}
          >
            {btnTitle}
          </button>
          &nbsp;
          <input
            type="checkbox"
            name="delayRefresh"
            defaultChecked={this.state.delayRefresh}
            disabled={this.state.updBtnDisabled}
            onChange={this.handleChange}
          ></input>{" "}
          &nbsp;Delay Refresh
        </div>
      );
    }
  }
  showInvDelButtons() {
    //Only show these buttons for single-variant product update
    if (this.state.addProduct === false) {
      return (
        <InvDelButtons
          uName="Product"
          name="product"
          delAction={this.doDelete}
          invAction={this.doMakeInvisible}
        />
      );
    }
  }

  showInvDelButtons_Orig() {
    //Only show these buttons for single-variant product update
    if (
      this.state.addProduct === false &&
      (!this.state.product.pvList || this.state.product.pvList.length <= 1)
    ) {
      return (
        <InvDelButtons
          uName="Product"
          name="product"
          delAction={this.doDelete}
          invAction={this.doMakeInvisible}
        />
      );
    }
  }
  processDelSymLinkRsp(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        const url = "/product/" + this.props.match.params.id;
        this.props.history.push(url);
      } else {
        this.setState({ errorMessage: response.errorMessage });
      }
    }
  }

  deleteSymLink(pvId) {
    const url = apiBaseUrl() + "DeleteSymLink";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: pvId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processDelSymLinkRsp(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  addVariant() {
    const url = "/editVariant/+/" + this.state.product.id;
    this.props.history.push(url);
  }

  addSymLink() {
    const url = "/addSymLink/" + this.state.product.id;
    this.props.history.push(url);
  }
  showVariantLinks() {
    const pvList = this.state.product.pvList;
    const url = "/editPvSeq/" + this.state.product.id;
    if (pvList && pvList.length > 1) {
      return (
        <div>
          <p />
          Click the links below to update individual variants:
          <p />
          {pvList.map((pv) => {
            let url = "/editVariant/" + pv.id;
            if (pv.origPvId > 0) {
              if (pv.origMvProduct) {
                url = "/editVariant/" + pv.origPvId;
              } else {
                url = "/editProduct/" + pv.origProductId;
              }
            }
            return (
              <div key={pv.id}>
                <b>{pv.sequence}</b>&nbsp;
                <Link to={url}>
                  <label>
                    {pv.sku} ({pv.variantValue})
                  </label>
                </Link>
                &nbsp;
                {pv.origProductId > 0 && (
                  <button
                    className="red-btn-style"
                    name="delPV"
                    onClick={() => this.deleteSymLink(pv.id)}
                  >
                    Delete SymLink
                  </button>
                )}
                <p />
              </div>
            );
          })}
          <p />
          The <b>bold</b> numbers above are variant sequences, which control the
          display order of variants.
          <br />
          To change the sequence, click the link below:
          <p />
          <a href={url}>Update Variant Sequence</a>
          <p />
          <div className="generic-flex">
            <div>
              <button
                name="addVariant"
                className="btn-style"
                onClick={this.addVariant}
              >
                Add New Variant
              </button>
            </div>
            <div className="width-20"></div>
            <div>
              <button
                name="addSymLink"
                className="btn-style"
                onClick={this.addSymLink}
              >
                Add SymLink
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
  showProgress() {
    if (this.state.isUpdating) {
      return <div>Updating ...</div>;
    }
  }
  theForm() {
    const pvList = this.state.product.pvList;
    return (
      <React.Fragment>
        {this.hiddenStuff()}
        <ValidatorForm ref="form" onSubmit={this.handleUpdate}>
          <div className="form-wrapper">
            {this.productForm()}
            {this.variantForm()}
          </div>
          {this.showAddUpdateButton()}
        </ValidatorForm>
        {this.showProgress()}
        {this.showInvDelButtons()}
        <div>&nbsp;</div>
        {pvList && pvList.length > 1 && this.showVariantLinks()}
      </React.Fragment>
    );
  }
  render() {
    const addProduct = this.props.match.params.id === "+" ? true : false;

    if (
      (!addProduct && this.state.isLoading === true) ||
      this.state.isLoadingCat === true ||
      this.state.isLoadingSup === true
    ) {
      return <Working size={80} />;
    }

    if (!this.state.product) {
      return (
        <div className="top-wrapper">
          <div>
            <font color="red">Product not found!</font>
          </div>
        </div>
      );
    }

    const title =
      this.state.addProduct === true ? "Add Product" : "Update Product";
    const pvList = this.state.product.pvList;
    return (
      <div className="top-wrapper">
        <div>
          <font size="5">{title}</font>
        </div>
        {this.state.errorMessage && (
          <font color="red">
            <div
              dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}
            ></div>
          </font>
        )}
        <div className="form-container">{this.theForm()}</div>
        <RankingInfoDialog
          btnId="hiddenDialogBtn"
          rankingInfo={this.state.product.rankingInfo}
        />
        <p />
        {pvList && pvList.length === 1 && !this.state.product.comboExists && (
          <React.Fragment>
            <BuyTogether uniqueKey={pvList[0].uniqueKey} />
            <p />
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    categoryId: state.search.categoryId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
