import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import TextAreaInput from "../validation/TextAreaInput";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import Working from "../Working";
import {
  apiBaseUrl,
  fullProductImageUrl,
  previewImgBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  getIconBaseUrl,
  manualBaseUrl,
  setTopContainerWrapperSettings,
  prodImgBaseUrl,
} from "../Util";
import { uploadFiles } from "../FileUploader";
import InvDelButtons from "./InvDelButtons";
import BuyTogether from "./BuyTogether";
import ImageWindow from "./ImageWindow";
import "../../App.css";
import "../../form.css";

class EditVariant extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      prodVar: null,
      singlePV: false,
      suppliers: [],
      supplierId: 0,
      forSaleColor: "green",
      finalPrice: 0,
      mobileMode: false,
      isAddNew: this.props.match.params.id === "+",
      isLoading: true,
      errorMessage: null,
      cvtErrorMessage: null,
      submitBtnDisabled: true,
      delayRefresh: this.props.match.params.id === "+",
      cvtDelayRefresh: true,
      cvtBtnDisabled: false,
      pgDisabled: false,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
      deleteAllowed: checkSecurity("PurgeProduct"),
      advAllowed: checkSecurity("AdvancedProduct"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.processDataResponse = this.processDataResponse.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.processHideDelRsp = this.processHideDelRsp.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.copyFromProd = this.copyFromProd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.doMarkInvisible = this.doMarkInvisible.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.manageImgMan = this.manageImgMan.bind(this);
    this.convert2Prod = this.convert2Prod.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processDataResponse(response) {
    if (response.invalidSession === true) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      console.log(response);
      if (response.status) {
        const product = response.product;
        let prodVar = response.pv;
        if (!prodVar) {
          prodVar = {
            productId: product.id,
            categoryId: product.categoryId,
            imageInfoList: [],
            unitsInStock: 100,
            assemblyFee: -1,
            inStockTime: 0,
            sequence: 1,
            retailPrice: 0,
            forSale: true,
            supplierId: 1,
            assemblyRequired: true,
            visible: true,
          };
        }
        this.setState({
          product,
          prodVar,
          singlePV: response.singlePV,
          isLoading: false,
        });

        //Suppliers
        const suppliers = response.suppliers;
        this.setState({
          suppliers,
        });

        if (suppliers && this.state.isAddNew) {
          this.setState({ supplierId: suppliers[0].id });
        }
      } else {
        this.setState({
          isLoading: false,
          errorMessage: response.errorMessage,
        });
      }
    }
  }
  fetchData() {
    let id = this.props.match.params.id;
    const editProduct = id === "+" ? true : false;
    const url = apiBaseUrl() + "GetProdVarForEdit";

    if (id === "+") id = this.props.match.params.prodId;

    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id,
      editProduct,
    };
    this.setState({ isLoading: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processDataResponse(res.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }
  processUpdateResponse(response) {
    if (response.status) {
      const url = "/product/" + this.state.product.id;
      this.props.history.push(url);
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  handleUpdate(event) {
    event.preventDefault();
    const url = apiBaseUrl() + "AddUpdateVariant";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.prodVar,
      delayMainRefresh: this.state.delayRefresh,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  convert2Prod(event) {
    event.preventDefault();
    this.setState({ cvtBtnDisabled: true });

    const url = apiBaseUrl() + "ConvertPvToProd";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.state.prodVar.uniqueKey,
      delayMainRefresh: this.state.cvtDelayRefresh,
    };
    console.log(req);
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession === true) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            const url = "/product/" + res.data.value;
            this.props.history.push(url);
          } else {
            let cvtErrorMessage = "Failed to convert this variant to product";
            if (res.data.errorMessages) {
              cvtErrorMessage += ": " + res.data.errorMessages;
            }
            this.setState({
              cvtErrorMessage,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  manageImgMan(type) {
    const url =
      "/manageImgMan/" +
      type +
      this.state.product.id +
      "/" +
      this.state.prodVar.uniqueKey;
    this.props.history.push(url);
  }
  showThumbnail(event) {
    event.preventDefault();
    document.getElementById("hiddenImgWindowBtn").click();
  }
  hiddenStuff() {
    const tnUrl = this.state.prodVar ? this.state.prodVar.thumbnailImage : null;
    if (tnUrl) {
      return (
        <ImageWindow
          imageUrl={tnUrl}
          width={400}
          height={300}
          btnId="hiddenImgWindowBtn"
        />
      );
    }
  }
  showVariantImage() {
    const iiList = this.state.prodVar.imageInfoList;
    const tnUrl = this.state.prodVar.thumbnailImage;
    return (
      <div className="form-item-value">
        <div className="flex-center">
          {iiList &&
            iiList.length > 0 &&
            iiList.map((info) => {
              const base = info.uploaded
                ? previewImgBaseUrl()
                : prodImgBaseUrl();
              const url = base + info.url;
              return (
                <div>
                  <img src={url} width="60" height="40"></img>&nbsp;
                </div>
              );
            })}

          {this.state.editAllowed && this.state.isAddNew && (
            <div>
              <input
                type="file"
                multiple="true"
                name="newImage"
                onChange={this.handleChange}
              ></input>
            </div>
          )}
          {this.state.editAllowed && !this.state.isAddNew && (
            <div className="flex-center">
              <div>
                <button name="update" onClick={() => this.manageImgMan("P_")}>
                  Manage Pictures
                </button>
              </div>
              {tnUrl && (
                <div>
                  &nbsp;&nbsp;
                  <img
                    className="hand"
                    src={getIconBaseUrl() + "thumbnail.jpg"}
                    height={20}
                    onClick={this.showThumbnail}
                  ></img>
                  <ImageWindow
                    imageUrl={tnUrl}
                    width={400}
                    height={300}
                    btnId="hiddenImgWindowBtn"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  showInstallManual() {
    let list = this.state.prodVar.installManualList;
    let manuals = null;
    if (list && list.length > 0) {
      const baseUrl = this.state.isAddNew
        ? previewImgBaseUrl()
        : manualBaseUrl();

      manuals = list.map((man) => {
        const manualUrl = baseUrl + man.url;
        const icon = man.pdf ? getIconBaseUrl() + "pdf.jpg" : manualUrl;
        return (
          <div>
            <a href={manualUrl} target="UserManual">
              <img src={icon} width="32"></img>&nbsp;
            </a>
          </div>
        );
      });
    }

    return (
      <div className="form-item-value">
        <div className="flex-center">
          {manuals}
          <div>
            {this.state.isAddNew ? (
              <input
                type="file"
                multiple={true}
                name="newManual"
                disabled={!this.state.editAllowed}
                onChange={this.handleChange}
              ></input>
            ) : (
              <button
                name="updateMan"
                onClick={() => this.manageImgMan("M_")}
                disabled={!this.state.editAllowed}
              >
                Update Manual
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  handleChange(event) {
    let name = event.target.name;

    if (name === "delayRefresh" || name === "cvtDelayRefresh") {
      this.setState({ [name]: event.target.checked });
    } else if (name === "newImage") {
      uploadFiles(event.target.files, this.processUploadResponse, false);
    } else if (name === "newManual") {
      uploadFiles(event.target.files, this.processUploadResponse, true);
    } else if (name === "pgDisabled") {
      this.setState({ pgDisabled: event.target.checked });
    } else {
      let value = event.target.value;
      let forSaleColor = this.state.forSale;

      if (name.startsWith("CB_")) {
        name = name.substring(3);
        value = event.target.checked;
        if (name === "forSale") {
          forSaleColor = event.target.checked ? "green" : "red";
        }
      }
      const prodVar = {
        ...this.state.prodVar,
        [name]: value,
      };
      let finalPrice = this.state.finalPrice;
      if (name === "salePrice") {
        finalPrice = 0;
        if (value && value.trim().length > 0) {
          try {
            finalPrice = parseFloat(value);
            finalPrice = Math.round(finalPrice);
          } catch (error) {}
        }
      }
      this.setState({
        prodVar,
        forSaleColor,
        finalPrice,
        submitBtnDisabled: false,
      });
    }
  }
  processUploadResponse(response, fileName, isManual) {
    if (response.status) {
      if (isManual) {
        let installManualList = this.state.prodVar.installManualList;
        const pos = fileName.lastIndexOf(".");
        const pdf = pos > 0 && fileName.substring(pos) == ".pdf" ? true : false;
        if (!installManualList) installManualList = [];
        installManualList.push({ url: fileName, pdf });
        const prodVar = {
          ...this.state.prodVar,
          installManualList,
        };
        this.setState({
          prodVar,
          submitBtnDisabled: false,
        });
      } else {
        let imageInfoList = this.state.prodVar.imageInfoList;
        if (!imageInfoList) imageInfoList = [];

        imageInfoList.push({ id: 0, objId: 0, uploaded: true, url: fileName });
        const prodVar = {
          ...this.state.prodVar,
          imageInfoList,
        };
        this.setState({
          prodVar,
          submitBtnDisabled: false,
        });
      }
    } else {
      console.log("Upload failed");
    }
  }
  processHideDelRsp(response, isDel) {
    if (response.status) {
      const url = "/product/" + this.state.product.id;
      this.props.history.push(url);
    } else {
      const action = isDel ? "delete" : "mark invisible";
      this.setState({ errorMessage: "Failed to " + action + " variant!" });
    }
  }

  doMarkInvisible() {
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.prodVar.id,
    };

    const url = apiBaseUrl() + "HideVariant";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processHideDelRsp(res.data, false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  doDelete() {
    const url = apiBaseUrl() + "DeleteVariant";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.prodVar.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processHideDelRsp(res.data, true);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  copyFromProd() {
    const prodVar = {
      ...this.state.prodVar,
      description: this.state.product.description,
    };
    this.setState({ prodVar });
  }
  productVariantForm() {
    const pv = this.state.prodVar;
    const colCnt = this.state.mobileMode ? 40 : 80;
    const rowCnt = this.state.mobileMode ? 12 : 15;
    const txtLen = this.state.mobileMode ? 40 : 45;
    const btnStyle = this.state.submitBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const btnTitle = this.state.isAddNew ? "Add Variant" : "Update";
    const mTitle = this.state.mobileMode ? "Manual" : "Assembly Manual";
    let fullName = pv ? pv.fullName : "";
    if (!fullName) fullName = "";
    const finalPriceLabel =
      this.state.finalPrice && this.state.finalPrice > 0
        ? "(Final: $" + this.state.finalPrice.toFixed(0) + ")"
        : null;
    return (
      <ValidatorForm ref="form" onSubmit={this.handleUpdate}>
        <div className="form-wrapper">
          <div className="form-item-label">SKU:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="sku"
              size="30"
              value={pv ? pv.sku : ""}
              disabled={!this.state.editAllowed}
              validators={["required"]}
              errorMessages={["SKU is required"]}
            />
          </div>
          <div className="form-item-label">Variant:</div>
          <div className="form-item-value">
            <TextInput
              size={txtLen}
              name="variantValue"
              disabled={!this.state.editAllowed}
              value={pv ? pv.variantValue : ""}
              onChange={this.handleChange}
              validators={["required"]}
              errorMessages={["Variant is required"]}
            ></TextInput>
          </div>
          <div className="form-item-label">Short Name:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="12"
              name="shortName"
              disabled={!this.state.editAllowed}
              value={(pv && pv.shortName) || ""}
              onChange={this.handleChange}
            ></input>{" "}
            (for Name in Box)
          </div>
          <div className="form-item-label">Color:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="12"
              name="color"
              disabled={!this.state.editAllowed}
              value={(pv && pv.color) || ""}
              onChange={this.handleChange}
            ></input>{" "}
            (for Name Color in Box)
          </div>
          <div className="form-item-label">Full Name:</div>
          <div className="form-item-value">
            <input
              type="text"
              size={txtLen}
              name="fullName"
              disabled={!this.state.editAllowed}
              placeholder="[Used as the product name for this variant]"
              value={fullName}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="form-item-label">Sequence:</div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="sequence"
              disabled={!this.state.editAllowed}
              value={pv ? pv.sequence : 1}
              onChange={this.handleChange}
            ></input>
          </div>

          <div className="form-item-label">Settings:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_forSale"
              disabled={!this.state.editAllowed}
              defaultChecked={pv ? pv.forSale : true}
              onChange={this.handleChange}
            ></input>{" "}
            <font color={this.state.forSaleColor}>For Sale</font> <br />
            <input
              type="checkbox"
              name="CB_assemblyRequired"
              disabled={!this.state.editAllowed}
              defaultChecked={pv ? pv.assemblyRequired : true}
              onChange={this.handleChange}
            ></input>{" "}
            Assembly Required
            <br />
            <input
              type="checkbox"
              name="CB_newArrival"
              disabled={!this.state.editAllowed}
              defaultChecked={pv ? pv.newArrival : false}
              onChange={this.handleChange}
            ></input>{" "}
            New Arrival <br />
            <input
              type="checkbox"
              name="CB_onSale"
              disabled={!this.state.editAllowed}
              defaultChecked={pv ? pv.onSale : false}
              onChange={this.handleChange}
            ></input>{" "}
            On Sale
          </div>
          <div className="form-item-label">Supplier:</div>
          <div className="form-item-value">
            <select
              id="supplierId"
              name="supplierId"
              disabled={!this.state.editAllowed}
              value={pv ? pv.supplierId : 1}
              onChange={this.handleChange}
            >
              {this.state.suppliers.map((sup) => {
                return (
                  <option value={sup.id} key={sup.id}>
                    {sup.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-item-label">Units In Stock:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="unitsInStock"
              inputmode="decimal"
              size="6"
              value={pv ? pv.unitsInStock : 100}
              disabled={!this.state.editAllowed}
              validators={["required"]}
              errorMessages={["Units In Stock is required"]}
            />
          </div>
          <div className="form-item-label">Sale Price:</div>
          <div className="generic-flex">
            <div>
              {this.state.pgDisabled ? (
                <TextInput
                  onChange={this.handleChange}
                  name="salePrice"
                  inputmode="decimal"
                  size="10"
                  value={pv ? pv.salePrice : 0}
                  disabled={!this.state.editAllowed}
                  validators={["required", "isNumber"]}
                  errorMessages={[
                    "Sale Price is required",
                    "Invalid Sale Price",
                  ]}
                />
              ) : (
                <TextInput
                  onChange={this.handleChange}
                  name="salePrice"
                  inputmode="decimal"
                  size="10"
                  value={pv ? pv.salePrice : 0}
                  disabled={!this.state.editAllowed}
                  validators={["required", "isNumber", "minNumber:40"]}
                  errorMessages={[
                    "Sale Price is required",
                    "Invalid Sale Price",
                    "Sale Price is too low",
                  ]}
                />
              )}
            </div>
            {this.state.advAllowed ? (
              <div>
                <input
                  type="checkbox"
                  name="pgDisabled"
                  defaultChecked={this.state.pgDisabled}
                  onChange={this.handleChange}
                  disabled={!this.state.advAllowed}
                />{" "}
                Disable price guard
              </div>
            ) : (
              <div>&nbsp;{finalPriceLabel}</div>
            )}
          </div>
          <div className="form-item-label">Assembly Fee:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              inputmode="decimal"
              name="assemblyFee"
              size="10"
              value={pv ? pv.assemblyFee : -1}
              disabled={!this.state.editAllowed || (pv && !pv.assemblyRequired)}
            />
          </div>
          <div className="form-item-label">Retail Price:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              inputmode="decimal"
              name="retailPrice"
              size="10"
              disabled={!this.state.editAllowed}
              value={pv ? pv.retailPrice : 0}
            />
          </div>
          <div className="form-item-label">In Stock Days:</div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              inputmode="decimal"
              name="inStockTime"
              size="10"
              value={pv ? pv.inStockTime : 0}
              disabled={!this.state.editAllowed}
              validators={["required"]}
              errorMessages={["In Stock Time is required"]}
            />
          </div>
          <div className="form-item-label">Variant Image:</div>
          {this.showVariantImage()}
          <div className="form-item-label">{mTitle}:</div>
          {this.showInstallManual()}
          <div className="form-item-label">Dimensions:</div>
          <div className="form-item-value">
            <div className="small-form-wrapper">
              <div className="form-item-lavel">L: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  inputmode="decimal"
                  size="6"
                  name="length"
                  value={pv.length}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>{" "}
                (inches)
              </div>
              <div className="form-item-lavel">D: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  inputmode="decimal"
                  size="6"
                  name="width"
                  value={pv.width}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>{" "}
                (inches)
              </div>
              <div className="form-item-lavel">H: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  inputmode="decimal"
                  size="6"
                  name="height"
                  value={pv.height}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>{" "}
                (inches)
              </div>
            </div>
          </div>
          <div className="form-item-label">Use Desc:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_useDesc"
              disabled={!this.state.editAllowed}
              defaultChecked={pv ? pv.useDesc : true}
              onChange={this.handleChange}
            ></input>{" "}
            Use description below for this variant
          </div>
          <div className="form-item-label">
            Description:
            <p />
            <a href="#None" onClick={this.copyFromProd}>
              <img src={getIconBaseUrl() + "copy.jpg"} height="45"></img>
              <br />
              Copy From
              <br />
              Product
            </a>
          </div>
          <div className="form-item-value">
            <TextAreaInput
              onChange={this.handleChange}
              name="description"
              rows={rowCnt}
              cols={colCnt}
              value={pv ? pv.description : ""}
              disabled={!this.state.editAllowed}
              validators={["required"]}
              errorMessages={["Description is required"]}
            />
          </div>
          <div className="form-item-label">Components:</div>
          <div className="form-item-value">
            <input
              type="text"
              size={txtLen}
              name="components"
              disabled={!this.state.editAllowed}
              placeholder="[For searching related products]"
              value={pv ? pv.components : ""}
              onChange={this.handleChange}
            ></input>
          </div>
        </div>
        <div>&nbsp;</div>
        <div>
          <button
            type="submit"
            className={btnStyle}
            name="update"
            disabled={this.state.submitBtnDisabled}
          >
            {btnTitle}
          </button>
          &nbsp;
          <input
            type="checkbox"
            name="delayRefresh"
            defaultChecked={this.state.delayRefresh}
            disabled={this.state.submitBtnDisabled}
            onChange={this.handleChange}
          ></input>{" "}
          Delay Refresh
        </div>
      </ValidatorForm>
    );
  }
  showInvDelButtons() {
    if (!this.state.isAddNew && !this.state.singlePV) {
      return (
        <InvDelButtons
          uName="Variant"
          name="variant"
          delAction={this.doDelete}
          invAction={this.doMarkInvisible}
        />
      );
    }
  }
  productInfo() {
    const product = this.state.product;
    if (product) {
      const imgUrl = fullProductImageUrl(product.imageUrl);
      let name = product.name;
      if (this.state.mobileMode && name.length > 30) {
        name = name.substring(0, 30) + "...";
      }
      return (
        <div className="form-wrapper">
          <div className="form-item-label">Product ID:</div>
          <div className="form-item-value">
            {product.id}&nbsp;&nbsp;
            {this.state.advAllowed &&
              this.state.prodVar &&
              this.state.prodVar.convertibleValue > 1 && (
                <React.Fragment>
                  <button
                    className="small-btn"
                    name="convert"
                    onClick={this.convert2Prod}
                  >
                    Convert to Product
                  </button>{" "}
                  &nbsp;&nbsp;
                  <input
                    type="checkbox"
                    name="cvtDelayRefresh"
                    onChange={this.handleChange}
                    defaultChecked={this.state.cvtDelayRefresh}
                    disabled={this.state.cvtBtnDisabled}
                  ></input>
                  Delay Refresh
                </React.Fragment>
              )}
          </div>
          <div className="form-item-label">Product Image:</div>
          <div className="form-item-value">
            <a href={imgUrl} target="ProductImage">
              <img src={imgUrl} width="50" alt="Small"></img>
            </a>
          </div>
          <div className="form-item-label">Product Name:</div>
          <div className="form-item-value">{name}</div>
          <div className="form-item-label">Variant Name:</div>
          <div className="form-item-value">{product.variantName}</div>
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    if (!this.state.prodVar) {
      return (
        <div className="top-wrapper">
          <div>
            <font color="red">Product variant not found!</font>
          </div>
        </div>
      );
    }

    const title = this.state.isAddNew ? "Add Variant" : "Update Variant";
    return (
      <div className="top-container">
        <div className="top-wrapper">
          <font size="5">{title}</font>
          {this.hiddenStuff()}
          <p />
          {this.state.cvtErrorMessage && (
            <div>
              <font color="red">{this.state.cvtErrorMessage}</font>
              <p />
            </div>
          )}
          {!this.state.mobileMode && this.productInfo()}
          {this.productVariantForm()}
          <div className="generic-flex">
            {this.showInvDelButtons()}
            {this.state.advAllowed &&
              this.state.prodVar &&
              this.state.prodVar.convertibleValue == 1 && (
                <div className="top-10">
                  &nbsp;&nbsp;
                  <button
                    className="btn-style"
                    name="convert"
                    onClick={this.convert2Prod}
                    disabled={this.state.cvtBtnDisabled}
                  >
                    Convert to Product
                  </button>
                </div>
              )}
          </div>
          <div>&nbsp;</div>
          {this.state.mobileMode && this.productInfo()}
          <p />
          {!this.state.isAddNew && this.state.prodVar && (
            <BuyTogether uniqueKey={this.state.prodVar.uniqueKey} />
          )}
          {this.state.errorMessage && (
            <font color="red">{this.state.errorMessage}</font>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    categoryId: state.search.categoryId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditVariant);
