import {
  SET_USERINFO,
  SET_LOGGEDIN,
  SET_LOGGEDOUT,
  SET_USERID,
  SET_RESETMSG,
  SET_VERSION,
} from "../actions/actionTypes";
import { initUserState } from "../State";

const userReducer = (state = initUserState, action) => {
  if (action.type === SET_USERINFO) {
    return {
      ...state,
      userInfo: action.payload,
    };
  } else if (action.type === SET_LOGGEDIN) {
    return {
      ...state,
      isLoggedIn: true,
      userId: action.payload.userId,
      sessionKey: action.payload.sessionKey,
      permissions: action.payload.permissions,
      passwordReq: action.payload.passwordReq,
      loginTime: new Date().getTime(),
      loginMenu: "Account",
      menuUrl: "/myAccount",
    };
  } else if (action.type === SET_LOGGEDOUT) {
    return initUserState;
  } else if (action.type === SET_USERID) {
    return {
      ...state,
      userId: action.payload.userId,
      passwordReq: action.payload.passwordReq,
    };
  } else if (action.type === SET_RESETMSG) {
    return {
      ...state,
      resetMsg: action.resetMsg,
    };
  } else if (action.type === SET_VERSION) {
    return {
      ...state,
      version: action.payload,
    };
  }

  return state;
};

export default userReducer;
