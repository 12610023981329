import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { checkUpdate, checkView } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
  FormatCurrency,
} from "../Util";
import "../../App.css";
import "../../form.css";
import "../../generic.css";
import "../../bt.css";
import "../../prodPreview.css";

class EditBT extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pvList: null,
      allPvList: null,
      bti: null,
      uniqueKey: this.props.match.params.key,
      thePv: null,
      enabled: false,
      otherKeys: [null, null, null, null],
      enabled: false,
      position: 1,
      title1: "Buy Together for This Item",
      isLoading: true,
      isLoadingPv: true,
      mobileMode: false,
      updBtnDisabled: true,
      errorMessage: null,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };

    this.processPvResponse = this.processPvResponse.bind(this);
    this.processBTResponse = this.processBTResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchBT = this.fetchBT.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed || !this.props.match.params.key) {
      this.props.history.push("/");
      return;
    }

    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchPvList();
    this.fetchBT();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processPvResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const allPvList = response.objList;
      const pv = allPvList.find(
        (aPv) => aPv.uniqueKey === this.props.match.params.key
      );
      if (pv) {
        this.setState({ allPvList, thePv: pv, isLoadingPv: false });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: "Invalid KEY: " + this.props.match.params.key,
        });
        this.props.history.push("/");
      }
    }
  }
  handleCancel(event) {
    let url = "/";
    if (this.state.thePv) {
      url = "/product/" + this.state.thePv.productId;
    }
    this.props.history.push(url);
  }
  fetchPvList() {
    const url = apiBaseUrl() + "GetAllPvList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processPvResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processBTResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        if (response.data && response.data.exists) {
          let otherKeys = [];
          const otherItems = response.data.otherItems;
          let i;
          for (i = 0; i < otherItems.length; i++) {
            otherKeys.push(
              otherItems[i].data.pvList[otherItems[i].id].uniqueKey
            );
          }
          const delta = 4 - otherItems.length;
          for (i = 0; i < delta; i++) otherKeys.push(null);
          this.setState({
            bti: response.data,
            enabled: response.data.enabled,
            otherKeys,
            enabled: response.data.enabled,
            position: response.data.position,
            isLoading: false,
          });
        } else {
          let pvList = null;
          if (response.data && response.data.item) {
            const item = response.data.item;
            if (item.data.pvList) {
              let i;
              pvList = [];
              for (i = 0; i < item.data.pvList.length; i++) {
                const checked =
                  this.props.match.params.key === item.data.pvList[i].uniqueKey;
                const pv = {
                  ...item.data.pvList[i],
                  checked,
                  visible: true,
                };
                pvList.push(pv);
              }
            }
          }
          let title1 = this.state.title1;
          if (pvList && pvList.length > 1) {
            title1 = "Check One or More Main Items:";
          }
          this.setState({
            pvList,
            title1,
            isLoading: false,
            uniqueKey: this.props.match.params.key,
          });
        }
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          isLoading: false,
        });
      }
    }
  }
  fetchBT() {
    const url = apiBaseUrl() + "GetBTInfo";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.props.match.params.key,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processBTResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Error fetching data",
          isLoading: false,
        });
        console.log(error);
      });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        const pv = this.state.allPvList.find(
          (aPv) => aPv.uniqueKey === this.state.uniqueKey
        );
        if (pv) {
          const url = "/product/" + pv.productId;
          this.props.history.push(url);
        }
      } else {
        this.setState({ errorMessage: response.errorMessage });
      }
    }
  }
  handleUpdate(event) {
    event.preventDefault();
    const url = apiBaseUrl() + "UpdateBT";
    const id = this.state.bti ? this.state.bti.id : 0;
    let data;
    if (!this.state.pvList || this.state.pvList.length < 2) {
      data = {
        id,
        uniqueKey: this.state.uniqueKey,
        uniqueKey1: this.state.otherKeys[0],
        uniqueKey2: this.state.otherKeys[1],
        uniqueKey3: this.state.otherKeys[2],
        uniqueKey4: this.state.otherKeys[3],
        enabled: this.state.enabled,
        position: this.state.position,
      };
    } else {
      let ukList = [],
        i;
      for (i = 0; i < this.state.pvList.length; i++) {
        const pv = this.state.pvList[i];
        if (pv.checked && pv.visible) {
          ukList.push(pv.uniqueKey);
        }
      }
      data = {
        id,
        ukList,
        uniqueKey1: this.state.otherKeys[0],
        uniqueKey2: this.state.otherKeys[1],
        uniqueKey3: this.state.otherKeys[2],
        uniqueKey4: this.state.otherKeys[3],
        enabled: this.state.enabled,
        position: this.state.position,
      };
    }

    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Error updating",
          isLoading: false,
        });
        console.log(error);
      });
  }
  handleDelete(event) {
    event.preventDefault();
    const url = apiBaseUrl() + "DeleteBT";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.bti.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Error delete",
          isLoading: false,
        });
        console.log(error);
      });
  }
  handleChange(event) {
    if (event.target.name.startsWith("KEY_")) {
      const idx = parseInt(event.target.name.substring(4));
      let otherKeys = [];
      let i;
      let empty = true;
      for (i = 0; i < 4; i++) {
        if (i === idx) {
          if (event.target.value.length > 0) empty = false;
          otherKeys.push(event.target.value);
        } else {
          if (this.state.otherKeys[i] && this.state.otherKeys[i].length > 0) {
            empty = false;
          }
          otherKeys.push(this.state.otherKeys[i]);
        }
      }
      this.setState({ otherKeys, updBtnDisabled: empty });

      if (this.state.pvList && this.state.pvList.length > 1) {
        let pvList = [];
        for (i = 0; i < this.state.pvList.length; i++) {
          const pv = this.state.pvList[i];
          const kk = otherKeys.find((key) => key === pv.uniqueKey);
          const mpv = {
            ...pv,
            visible: !kk,
          };
          pvList.push(mpv);
        }
        this.setState({ pvList });
      }
    } else if (event.target.name.startsWith("PVCB_")) {
      const idx = parseInt(event.target.name.substring(5));
      let hasChecked = false;
      let pvList = [],
        i;
      for (i = 0; i < this.state.pvList.length; i++) {
        if (i === idx) {
          const pv = {
            ...this.state.pvList[i],
            checked: event.target.checked,
          };
          pvList.push(pv);
          if (event.target.checked) hasChecked = true;
        } else {
          pvList.push(this.state.pvList[i]);
          if (this.state.pvList[i].checked && this.state.pvList[i].visible)
            hasChecked = true;
        }
      }
      const updBtnDisabled = hasChecked ? this.isEmpty() : true;
      this.setState({ pvList, updBtnDisabled });
    } else {
      if (event.target.name === "position") {
        this.setState({
          position: event.target.value,
          updBtnDisabled: this.isEmpty(),
        });
      } else {
        this.setState({
          enabled: event.target.checked,
          updBtnDisabled: this.isEmpty(),
        });
      }
    }
  }
  isEmpty() {
    let empty = true,
      i;
    for (i = 0; i < 4; i++) {
      if (this.state.otherKeys[i] && this.state.otherKeys[i].length > 0) {
        empty = false;
        break;
      }
    }
    return empty;
  }
  showOneItem(idx) {
    const name = "KEY_" + idx;
    let value = this.state.otherKeys[idx];
    let price = null;
    let img = null,
      imgId = "img";
    if (value) {
      const pv = this.state.allPvList.find((aPv) => aPv.uniqueKey === value);
      if (pv) {
        img = pv.imageUrl ? pv.imageUrl : pv.prodImageUrl;
        price = pv.finalPrice;
        if (!pv.forSale) imgId = "soldOutImage";
      }
    } else value = "";
    return (
      <div>
        <select name={name} value={value} onChange={this.handleChange}>
          <option value="">[Select One]</option>
          {this.state.allPvList.map((pv) => {
            return (
              <option value={pv.uniqueKey} key={pv.uniqueKey}>
                {pv.sku}
              </option>
            );
          })}
        </select>
        {img && (
          <React.Fragment>
            <br />
            <img
              id={imgId}
              src={fullProductImageUrl(img)}
              width="75"
              height="60"
            />
          </React.Fragment>
        )}
        {price && (
          <React.Fragment>
            <br />
            <FormatCurrency amount={price} />
          </React.Fragment>
        )}
      </div>
    );
  }
  showMainItems() {
    if (this.state.bti || !this.state.pvList || this.state.pvList.length < 2) {
      //Update or Add for single variant
      const pv = this.state.thePv;
      const img = pv.imageUrl ? pv.imageUrl : pv.prodImageUrl;
      const price = pv.finalPrice;
      const name = pv.fullName ? pv.fullName : pv.variantValue;
      const imgId = pv.forSale ? "img_" + pv.uniqueKey : "soldOutImage";
      return (
        <div className="generic-flex">
          <div>
            <img
              id={imgId}
              src={fullProductImageUrl(img)}
              width="160"
              height="120"
            />
          </div>
          <div className="left-10">
            SKU: {pv.sku}
            <br />
            {name}
            <br />
            <FormatCurrency amount={price} />
          </div>
        </div>
      );
    } else {
      //Add new Buy Together for multi-variant
      const clsName1 = this.state.mobileMode
        ? "generic-2col"
        : "generic-flex-b15";
      const width = this.state.mobileMode ? 120 : 160;
      const height = this.state.mobileMode ? 90 : 120;
      return (
        <div className={clsName1}>
          {this.state.pvList.map((pv, idx) => {
            if (pv.visible) {
              const name = "PVCB_" + idx;
              const img = pv.imageUrl ? pv.imageUrl : pv.prodImageUrl;
              const clsName = idx > 0 ? "left-10" : "none";
              let sku = pv.sku;
              if (this.state.mobileMode && sku.length > 15) {
                sku = sku.substring(0, 15);
              }

              const imgId2 = pv.forSale
                ? "img_" + pv.uniqueKey
                : "soldOutImage";

              return (
                <div key={idx}>
                  <div className="generic-flex">
                    <div className={clsName}>
                      <input
                        type="checkbox"
                        name={name}
                        defaultChecked={pv.checked}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div>
                      <img
                        id={imgId2}
                        src={fullProductImageUrl(img)}
                        width={width}
                        height={height}
                      />
                    </div>
                  </div>
                  <div className="left-20">
                    SKU: {sku}
                    <br />
                    Price: <FormatCurrency amount={pv.finalPrice} />
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    }
  }
  theForm() {
    if (this.state.isLoadingPv || this.state.isLoading)
      return <div>Loading PV ...</div>;

    const btnTitle = this.state.bti ? "Update" : "Add";
    const btnStyle = this.state.updBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const clsName = this.state.mobileMode ? "none" : "generic-flex";
    return (
      <div>
        {this.showMainItems()}
        <div className="top-10">
          <b>Select At Least One From Following:</b>
        </div>
        <div className={clsName}>
          {this.showOneItem(0)}
          {this.showOneItem(1)}
          {this.showOneItem(2)}
          {this.showOneItem(3)}
        </div>
        <div className="generic-flex-top10">
          <div>Desktop Position:&nbsp; </div>
          <div className={clsName}>
            <div>
              <input
                type="radio"
                name="position"
                value={1}
                defaultChecked={this.state.position === 1}
                onChange={this.handleChange}
              />
              Below Image
            </div>
            <div>
              <input
                type="radio"
                name="position"
                value={2}
                defaultChecked={this.state.position === 2}
                onChange={this.handleChange}
              />
              Below Description
            </div>
            <div>
              <input
                type="radio"
                name="position"
                value={3}
                defaultChecked={this.state.position === 3}
                onChange={this.handleChange}
              />
              Above Related Prod
            </div>
          </div>
        </div>
        <div>
          <input
            type="checkbox"
            name="enabled"
            defaultChecked={this.state.enabled}
            onChange={this.handleChange}
          ></input>
          &nbsp;Enabled
        </div>
        <div className="top-10">
          <button
            name="update"
            className={btnStyle}
            onClick={this.handleUpdate}
            disabled={this.state.updBtnDisabled}
          >
            {btnTitle}
          </button>{" "}
          &nbsp;&nbsp;
          {this.state.bti && (
            <button
              name="delete"
              onClick={this.handleDelete}
              className="red-btn-style"
            >
              Delete
            </button>
          )}
          &nbsp;&nbsp;
          <button
            name="cancel"
            onClick={this.handleCancel}
            className="btn-style"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    const title = this.state.bti ? "Update Buy Together" : "Add Buy Together";
    return (
      <div className="top-wrapper">
        <div className="form-container">
          <div>
            <font size="5">{title}</font>
          </div>
          {this.state.errorMessage && (
            <div>
              <font color="red">{this.state.errorMessage}</font>
            </div>
          )}
          <div>&nbsp;</div>
          <div>
            <b>{this.state.title1}</b>
          </div>
          {this.theForm()}
          <div className="top-10">
            The greyed out images indicate sold out items. They will not show in
            main website until they become available again.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditBT);
