import React from "react";
import "../../qtybox.css";
import { getIconBaseUrl } from "../Util";

class ItemQtyBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: this.props.qty,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(minus) {
    const qty = minus ? this.state.qty - 1 : this.state.qty + 1;
    this.setState({ qty });

    if (this.props.actionHandler) {
      this.props.actionHandler(this.props.itemIdx, qty);
    }
  }

  charOnly() {
    const minus = this.state.qty <= 1 ? "x" : "-";
    const mClsName = this.state.qty <= 1 ? "qty-minus-x" : "qty-minus";
    return (
      <React.Fragment>
        <div className={mClsName}>
          <a
            onClick={() => {
              this.handleChange(true);
            }}
          >
            {minus}
          </a>
        </div>
        <div>{this.state.qty}</div>
        <div className="qty-plus">
          <a
            onClick={() => {
              this.handleChange(false);
            }}
          >
            +
          </a>
        </div>
      </React.Fragment>
    );
  }
  useImages() {
    const iconHeight = 13;
    const minusImg = this.state.qty <= 1 ? "qty-cross.jpg" : "qty-minus.jpg";
    return (
      <React.Fragment>
        <div className="qty-minus-img">
          <a
            onClick={() => {
              this.handleChange(true);
            }}
          >
            <img src={getIconBaseUrl() + minusImg} height={iconHeight}></img>
          </a>
        </div>
        <div className="qty-img">{this.state.qty}</div>
        <div className="qty-plus-img">
          <a
            onClick={() => {
              this.handleChange(false);
            }}
          >
            <img
              src={getIconBaseUrl() + "qty-plus.jpg"}
              height={iconHeight}
            ></img>
          </a>
        </div>
      </React.Fragment>
    );
  }
  render() {
    return (
      <div className="qty-box">
        {this.props.useChar ? this.charOnly() : this.useImages()}
      </div>
    );
  }
}
export default ItemQtyBox;
