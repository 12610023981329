import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { setLoggedIn, setUserId } from "../actions/userActions";
import { isUserLoggedIn, apiBaseUrl } from "../Util";
import { AXIOS_HEADER } from "../config/constants";

import "../../App.css";
import "../../form.css";

class LoginUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      password: "",
      errorMessage: null,
    };
    this.userRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.processLoginResponse = this.processLoginResponse.bind(this);
  }

  componentDidMount() {
    const userState = {
      isLoggedIn: this.props.isLoggedIn,
      userId: this.props.userId,
      loginTime: this.props.loginTime,
    };
    if (isUserLoggedIn(userState) === true) {
      if (this.props.returnUrl) {
        this.props.history.push(this.props.returnUrl);
      } else {
        this.props.history.push("/");
      }
    }
    this.userRef.current.input.focus();
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  processLoginResponse(response) {
    if (response.success) {
      if (response.changePasswordRequired === true) {
        this.props.setUserId(response);
        this.props.history.push("/myAccount/1");
      } else {
        this.props.setLoggedIn(response);
        this.setState({ errorMessage: null });

        if (this.props.returnUrl) {
          this.props.history.push(this.props.returnUrl);
        } else {
          window.location.reload();
        }
      }
    } else {
      this.setState({ errorMessage: "Invalid user ID or password" });
    }
  }

  handleLogin(event) {
    event.preventDefault();

    if (!this.state.userId) {
      this.setState({ errorMessage: "User ID is required" });
      return;
    }

    if (!this.state.password) {
      this.setState({ errorMessage: "Password is required" });
      return;
    }

    const url = apiBaseUrl() + "Login";
    const req = {
      userId: this.state.userId,
      password: this.state.password,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processLoginResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMessage: "Unable to login" });
      });
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Login</font>
          <p />
          <ValidatorForm ref="form" onSubmit={this.handleLogin}>
            <div className="form-wrapper">
              <div className="form-item-label">User ID: </div>
              <div className="form-item-value">
                <TextInput
                  name="userId"
                  value={this.state.userId}
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["User ID is required"]}
                  ref={this.userRef}
                ></TextInput>
              </div>
              <div className="form-item-label">Password: </div>
              <div className="form-item-value">
                <TextInput
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["Password is required"]}
                ></TextInput>
              </div>
            </div>
            <div>
              <button name="login" type="submit" className="btn-style">
                Login
              </button>
            </div>
          </ValidatorForm>
          <p />
          <font color="red">{this.state.errorMessage}</font>
          <p />

          <div>
            Forgot your password? Click <Link to="/resetPass">Reset</Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.userId,
    loginTime: state.user.loginTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (payload) => {
      dispatch(setLoggedIn(payload));
    },
    setUserId: (payload) => {
      dispatch(setUserId(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginUser));
