import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import Thumbnail from "./Thumbnail";
import { apiBaseUrl, isUserLoggedIn } from "../Util";
import { setSearchResult } from "../actions/searchActions";
import { setLoggedOut } from "../actions/userActions";
import "../../generic.css";
import "../../prodByCat.css";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      message: "",
      isLoading: true,
    };
    this.searchProducts = this.searchProducts.bind(this);
    this.processResponseData = this.processResponseData.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (this.props.searchResult !== null) {
      this.setState({ items: this.props.searchResult, isLoading: false });
    } else {
      this.searchProducts();
    }
  }
  componentDidUpdate(prevProps) {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (this.props.query !== prevProps.query) {
      this.searchProducts();
    }
  }
  processResponseData(result) {
    if (result.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const objList = result.objList;
      this.props.setSearchResult(objList);

      const message =
        result.status === true ? "" : "No results for " + this.props.query;
      this.setState({
        items: objList,
        message: message,
        isLoading: false,
      });

      if (objList && objList.length === 1) {
        this.props.history.push("/product/" + objList[0].id);
      }
    }
  }

  searchProducts() {
    if (!this.props.query) {
      this.props.history.push("/");
    }

    const url = apiBaseUrl() + "Search";
    const searchCrit = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      query: this.props.query,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, searchCrit, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponseData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (this.state.isLoading) return <div>Searching ...</div>;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Search Result</font>
          <p />
          {this.state.items && this.state.items.length > 0 ? (
            <div className="prodbycat-wrapper">
              {this.state.items.map((item, idx) => {
                return <Thumbnail prod={item} key={idx} />;
              })}
            </div>
          ) : (
            <div>
              <font color="red">No product was found: {this.props.query}</font>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    query: state.search.query,
    searchResult: state.search.result,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
    setSearchResult: (result) => {
      dispatch(setSearchResult(result));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
