import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  fullProductImageUrl,
  toAmountEx,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
} from "../Util";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../layaway.css";
import ItemQtyBox from "./ItemQtyBox";
import PvSelector from "../product/PvSelector";

class OrderUpdator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.adjustTaxAndTotals(this.props.order, true),
      allowStoreProd: this.props.allowStoreProd,
      pvList: null,
      storeProdList: null,
      updOrderBtnDisabled: true,
      skuIdx: -1,
      isSP: false,
      storeProdName: null,
      storeProdPrice: null,
      addStoreProdBtnEnabled: false,
      couponName: null,
      couponAmount: null,
      addCouponBtnEnabled: false,
    };
    this.fetchPvList = this.fetchPvList.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.doAddNewItem = this.doAddNewItem.bind(this);
    this.itemQtyChanged = this.itemQtyChanged.bind(this);
    this.storeItemQtyChanged = this.storeItemQtyChanged.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.removeDiscount = this.removeDiscount.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
    this.adjustTaxAndTotals = this.adjustTaxAndTotals.bind(this);
    this.addTypedInProd = this.addTypedInProd.bind(this);
    this.addCoupon = this.addCoupon.bind(this);
  }
  componentDidMount() {
    this.fetchPvList();
  }
  componentDidUpdate(prepProps) {
    if (prepProps.order != this.props.order) {
      this.setState({ order: this.props.order });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  handleChange(event) {
    event.preventDefault();

    let name = event.target.name;
    if (name === "skuIdx") {
      let skuIdx = -1;
      let isSP = false;
      const val = event.target.value;
      if (val.startsWith("SP_")) {
        isSP = true;
        skuIdx = parseInt(val.substring(3));
      } else {
        skuIdx = parseInt(val);
      }

      const updOrderBtnDisabled =
        skuIdx < 0 ? this.state.updOrderBtnDisabled : true;
      this.setState({ skuIdx, updOrderBtnDisabled, isSP });
    } else {
      const val = event.target.value;
      let addCouponBtnEnabled = this.state.addCouponBtnEnabled;
      let addStoreProdBtnEnabled = this.state.addStoreProdBtnEnabled;
      if (name.startsWith("P_")) {
        name = name.substring(2);
        if (!val || val.trim().length === 0) {
          addStoreProdBtnEnabled = false;
        } else {
          if (
            name === "storeProdName" &&
            this.state.storeProdPrice &&
            this.state.storeProdPrice.length > 0
          ) {
            addStoreProdBtnEnabled = true;
          } else if (
            name === "storeProdPrice" &&
            this.state.storeProdName &&
            this.state.storeProdName.length > 0
          ) {
            addStoreProdBtnEnabled = true;
          }
        }
      } else if (name.startsWith("C_")) {
        name = name.substring(2);
        if (!val || val.trim().length === 0) {
          addCouponBtnEnabled = false;
        } else {
          if (
            name === "couponName" &&
            this.state.couponAmount &&
            this.state.couponAmount.length > 0
          ) {
            addCouponBtnEnabled = true;
          } else if (
            name === "couponAmount" &&
            this.state.couponName &&
            this.state.couponName.length > 0
          ) {
            addCouponBtnEnabled = true;
          }
        }
      }
      this.setState({
        [name]: val,
        addCouponBtnEnabled,
        addStoreProdBtnEnabled,
      });
    }
  }
  alreadyExists(sku, isSP) {
    if (this.state.order) {
      const order = this.state.order;
      if (isSP) {
        if (order.storeItems) {
          const found = order.storeItems.filter((si) => si.sku === sku);
          if (found && found.length > 0) return true;
        }
      } else {
        if (order.orderItems) {
          const found = order.orderItems.filter(
            (oItem) => oItem.item.sku === sku
          );
          if (found && found.length > 0) return true;
        }
      }
    }

    return false;
  }
  inCartSkuList() {
    let list = [];
    const order = this.state.order;

    list.push("3-ROOM-COMBO");
    if (order) {
      if (order.orderItems) {
        for (let i = 0; i < order.orderItems.length; i++) {
          list.push(order.orderItems[i].item.sku);
        }
      }
      if (order.storeItems) {
        for (let i = 0; i < order.storeItems.length; i++) {
          list.push(order.storeItems[i].sku);
        }
      }
    }
    return list;
  }

  adjustTaxAndTotals(order, balanceOnly) {
    if (this.props.showSummary) {
      let summary = this.props.layaway ? order : order.summary;
      let totalPrice = summary.totalPrice;

      if (!balanceOnly) {
        let subtotal = 0;

        if (order.orderItems) {
          for (let i = 0; i < order.orderItems.length; i++) {
            const item = order.orderItems[i].item;
            subtotal += item.quantity * item.unitPrice;
          }
        }
        if (order.storeItems) {
          for (let i = 0; i < order.storeItems.length; i++) {
            const item = order.storeItems[i];
            if (item.typeId < 1) {
              subtotal += item.quantity * item.price;
            }
          }
        }
        if (summary.discount > 0) subtotal -= summary.discount;

        const tax = summary.taxRate * subtotal;

        totalPrice = subtotal + tax;
        if (summary.delivery) totalPrice += summary.deliveryFee;

        if (order.storeCoupons) {
          for (let i = 0; i < order.storeCoupons.length; i++) {
            if (order.storeCoupons[i].quantity > 0) {
              totalPrice += order.storeCoupons[i].price;
            }
          }
        }
        summary = {
          ...summary,
          subtotal,
          tax,
          totalPrice,
        };
      }

      let balance = null;
      const payments = this.props.layaway ? order.paymentList : order.payments;
      if (payments && payments.length > 0) {
        balance = totalPrice;
        for (let i = 0; i < payments.length; i++) {
          balance -= payments[i].amount;
        }
      }
      if (this.props.layaway) {
        summary = {
          ...summary,
          balance,
        };
        return summary;
      } else {
        const adjOrder = {
          ...order,
          summary,
          balance,
        };
        return adjOrder;
      }
    } else return order;
  }
  addNewItem(event) {
    if (this.state.skuIdx >= 0) {
      this.doAddNewItem(this.state.skuIdx, this.state.isSP);
    }
  }

  doAddNewItem(skuIdx, isSP) {
    if (isSP) {
      const sp = this.state.storeProdList[skuIdx];
      const storeProd = {
        ...sp,
        quantity: 1,
      };
      let storeItems = this.state.order.storeItems
        ? this.state.order.storeItems
        : [];
      storeItems.push(storeProd);
      let order = {
        ...this.state.order,
        storeItems,
      };
      order = this.adjustTaxAndTotals(order, false);
      this.setState({ order, updOrderBtnDisabled: false, skuIdx: -1 });
    } else {
      const pv = this.state.pvList[skuIdx];
      const imageUrl = pv.imageUrl ? pv.imageUrl : pv.prodImageUrl;
      let productName = pv.fullName;

      if (!productName) productName = pv.variantValue;
      if (!productName) productName = pv.sku;
      const item = {
        orderNumber: this.state.order.orderNumber,
        productId: pv.productId,
        prodVarId: pv.id,
        sku: pv.sku,
        quantity: 1,
        unitPrice: pv.finalPrice,
      };
      const oItem = {
        productId: pv.productId,
        productName,
        imageUrl,
        item,
      };

      let orderItems = this.state.order.orderItems
        ? this.state.order.orderItems
        : [];
      orderItems.push(oItem);
      let order = {
        ...this.state.order,
        orderItems,
      };
      order = this.adjustTaxAndTotals(order, false);
      this.setState({ order, updOrderBtnDisabled: false, skuIdx: -1 });
    }
  }

  fetchPvList() {
    const url = apiBaseUrl() + "GetForSaleProdList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({
          pvList: res.data.pvList,
          storeProdList: res.data.storeProdList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  removeDiscount(event) {
    const order = {
      ...this.state.order,
      discount: 0,
    };
    this.setState({ order, updOrderBtnDisabled: false });
  }

  removeCoupon(idx) {
    let storeCoupons = [];
    let order = this.state.order;
    let totalPrice = this.props.layaway
      ? order.totalPrice
      : order.summary.totalPrice;

    for (let i = 0; i < order.storeCoupons.length; i++) {
      if (i !== idx) storeCoupons.push(order.storeCoupons[i]);
      else {
        totalPrice -= order.storeCoupons[i].price;
        if (order.storeCoupons[i].orderItem) {
          const newCp = {
            ...order.storeCoupons[i],
            quantity: 0,
          };
          storeCoupons.push(newCp);
        } else {
        }
      }
    }

    order = {
      ...this.state.order,
      storeCoupons,
    };
    order = this.adjustTaxAndTotals(order, false);
    this.setState({ order, updOrderBtnDisabled: false });
  }
  addNewItemForm_Old() {
    const pvList = this.state.pvList;
    const storeProdList = this.props.allowStoreProd
      ? this.state.storeProdList
      : null;
    if (pvList && pvList.length > 0) {
      const disabled = this.state.skuIdx < 0;
      const btnStyle = disabled ? "disabled-small-btn" : "small-btn";
      const pv = disabled || this.state.isSP ? null : pvList[this.state.skuIdx];
      const sp =
        disabled || !this.state.isSP
          ? null
          : this.state.storeProdList[this.state.skuIdx];
      const val = this.state.isSP
        ? "SP_" + this.state.skuIdx
        : this.state.skuIdx;
      let imageUrl = null;
      if (pv) {
        imageUrl = pv.imageUrl ? pv.imageUrl : pv.prodImageUrl;
      }
      if (pvList && pvList.length > 0) {
        return (
          <div className="top-10">
            <div className="flex-wrapper">
              <div>Add New:</div>
              <div className="left-10">
                <select name="skuIdx" value={val} onChange={this.handleChange}>
                  <option value={-1}>[Select SKU]</option>
                  {pvList.map((pv, idx) => {
                    if (!this.alreadyExists(pv.sku, false)) {
                      const display = pv.sku + " ($" + pv.finalPrice + ")";
                      return (
                        <option value={idx} key={idx}>
                          {display}
                        </option>
                      );
                    }
                  })}
                  {storeProdList &&
                    storeProdList.map((sp, idx) => {
                      if (sp.typeId < 1 && !this.alreadyExists(sp.sku, true)) {
                        const display = sp.name + " ($" + sp.price + ")";
                        const val = "SP_" + idx;
                        return (
                          <option value={val} key={val}>
                            {display}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <div className="left-10">
                <button
                  className={btnStyle}
                  name="addNew"
                  disabled={disabled}
                  onClick={this.addNewItem}
                >
                  Add
                </button>
              </div>
            </div>
            {pv && (
              <div className="flex-wrapper-t10">
                <div>
                  <img
                    src={fullProductImageUrl(imageUrl)}
                    width="80"
                    height="60"
                  ></img>
                </div>
                <div className="left-10">
                  SKU: {pv.sku} <br />
                  Price: {toAmountEx(pv.finalPrice)}
                </div>
              </div>
            )}
            {sp && (
              <div className="flex-wrapper-t10">
                <div>
                  <img
                    src={fullProductImageUrl(sp.imageUrl)}
                    width="80"
                    height="60"
                  ></img>
                </div>
                <div className="left-10">
                  SKU: {sp.sku} <br />
                  Name: {sp.name} <br />
                  Price: {toAmountEx(sp.price)}
                </div>
              </div>
            )}
          </div>
        );
      }
    }
  }
  addNewItemForm() {
    const pvList = this.state.pvList;
    const storeProdList = this.props.allowStoreProd
      ? this.state.storeProdList
      : null;
    if (pvList && pvList.length > 0) {
      return (
        <div className="top-10">
          <PvSelector
            storePvList={storeProdList}
            pvList={pvList}
            exSkuList={this.inCartSkuList()}
            prompt="Add New: "
            showPreview={true}
            btnTitle="Add"
            actionHandler={this.doAddNewItem}
          />
        </div>
      );
    }
  }
  addTypedInProd(event) {
    event.preventDefault();
    const price = parseFloat(this.state.storeProdPrice);
    const item = {
      id: 0,
      typeId: 0,
      name: this.state.storeProdName,
      sku: "TYPED_" + this.state.storeProdName,
      price,
      finalPrice: price,
      quantity: 1,
      imageUrl: "store-items.jpg",
    };
    let storeItems = this.state.order.storeItems;
    if (!storeItems) storeItems = [];
    storeItems.push(item);

    let order = {
      ...this.state.order,
      storeItems,
    };
    order = this.adjustTaxAndTotals(order, false);
    this.setState({
      order,
      storeProdName: null,
      storeProdPrice: null,
      addStoreProdBtnEnabled: false,
      updOrderBtnDisabled: false,
    });
  }
  addCoupon() {
    let amount = parseFloat(this.state.couponAmount);

    if (amount > 0) amount = 0 - amount;
    const item = {
      id: 0,
      typeId: 0,
      name: this.state.couponName,
      sku: "COUPON_" + this.state.couponName,
      price: amount,
      finalPrice: amount,
      quantity: 1,
      coupon: true,
    };
    let storeCoupons = this.state.order.storeCoupons;
    if (!storeCoupons) storeCoupons = [];
    storeCoupons.push(item);

    let order = {
      ...this.state.order,
      storeCoupons,
    };
    order = this.adjustTaxAndTotals(order, false);
    this.setState({
      order,
      couponName: null,
      couponPrice: null,
      addCouponBtnEnabled: false,
      updOrderBtnDisabled: false,
    });
  }
  storeProdForm() {
    const btnStyle = this.state.addStoreProdBtnEnabled
      ? "small-btn"
      : "small-btn-disabled";
    return (
      <div>
        <div>&nbsp;</div>
        <div className="generic-flex">
          <div>
            <img
              src={getIconBaseUrl() + "storeprod-logo.jpg"}
              alt="Logo"
              height="23"
            ></img>
          </div>
          <div className="left-10">
            <input
              type="text"
              size="20"
              name="P_storeProdName"
              value={this.state.storeProdName || ""}
              placeholder="Product Name"
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="left-10">
            <input
              type="text"
              size="5"
              name="P_storeProdPrice"
              inputmode="numeric"
              value={this.state.storeProdPrice || ""}
              placeholder="Price"
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="left-10">
            <button
              className={btnStyle}
              name="add"
              disabled={!this.state.addStoreProdBtnEnabled}
              onClick={this.addTypedInProd}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    );
  }
  couponForm() {
    const btnStyle = this.state.addCouponBtnEnabled
      ? "small-btn"
      : "small-btn-disabled";
    return (
      <div className="generic-flex">
        <div>
          <img
            src={getIconBaseUrl() + "coupon.jpg"}
            alt="Logo"
            height="23"
          ></img>
        </div>
        <div className="left-10">
          <input
            type="text"
            size="20"
            name="C_couponName"
            value={this.state.couponName}
            placeholder="Coupon Name/Code"
            onChange={this.handleChange}
          ></input>
        </div>
        <div className="left-10">
          <input
            type="text"
            size="5"
            name="C_couponAmount"
            inputmode="numeric"
            value={this.state.couponAmount}
            placeholder="Amount"
            onChange={this.handleChange}
          ></input>
        </div>
        <div className="left-10">
          <button
            className={btnStyle}
            name="addCoupon"
            disabled={!this.state.addCouponBtnEnabled}
            onClick={this.addCoupon}
          >
            Add Coupon
          </button>
        </div>
      </div>
    );
  }
  itemQtyChanged(key, newQty) {
    const idx = parseInt(key);
    this.state.order.orderItems[idx].item.quantity = newQty;
    const order = this.adjustTaxAndTotals(this.state.order, false);
    this.setState({ order, updOrderBtnDisabled: false });
  }
  storeItemQtyChanged(key, newQty) {
    const idx = parseInt(key);
    this.state.order.storeItems[idx].quantity = newQty;
    const order = this.adjustTaxAndTotals(this.state.order, false);
    this.setState({ order, updOrderBtnDisabled: false });
  }
  updateOrder(event) {
    event.preventDefault();

    this.setState({ updOrderBtnDisabled: true });
    if (this.props.callback) {
      this.props.callback(this.state.order);
    }
  }
  promoInfo(afterTax) {
    const clsName = "la-summary";
    const ordEx = this.state.order.summary;
    if (ordEx && ordEx.promoDiscount > 0 && !ordEx.promoDeliveryFee) {
      if (
        (afterTax && ordEx.promoAfterTax) ||
        (!afterTax && !ordEx.promoAfterTax)
      ) {
        return (
          <React.Fragment>
            <div></div>
            <div className={clsName}>{ordEx.promoName}:</div>
            <div className={clsName}></div>
            <div className="la-summary-val">
              <font color="red">{toAmountEx(0 - ordEx.promoDiscount)}</font>
            </div>
          </React.Fragment>
        );
      }
    }
    return "";
  }
  orderSummary() {
    if (this.props.showSummary) {
      const sum = this.props.layaway
        ? this.state.order
        : this.state.order.summary;
      const clsName = "la-summary";
      const pcnt = sum.taxRate * 100;
      const storeCoupons = this.state.order.storeCoupons;
      const payments = this.props.layaway
        ? this.state.order.paymentList
        : this.state.order.payments;
      const taxName =
        !this.props.layaway && sum.leaseId > 0 && sum.tax > 0.1
          ? "Overlimit Tax"
          : "Tax";

      return (
        <React.Fragment>
          {this.promoInfo(false)}
          <div></div>
          <div className={clsName}>Subtotal:</div>
          <div className={clsName}></div>
          <div className="la-summary-val">{toAmountEx(sum.subtotal)}</div>
          <div></div>
          <div className={clsName}>
            {taxName} ({pcnt}%):
          </div>
          <div className={clsName}></div>
          <div className="la-summary-val">{toAmountEx(sum.tax)}</div>
          {sum.delivery && (
            <React.Fragment>
              <div></div>
              <div className={clsName}>Delivery Fee:</div>
              <div className={clsName}></div>
              <div className="la-summary-val">
                {toAmountEx(sum.deliveryFee)}
              </div>
            </React.Fragment>
          )}
          {storeCoupons &&
            storeCoupons.map((item, idx) => {
              if (item.quantity > 0) {
                let name = item.name;
                if (name.startsWith("COUPON_")) name = name.substring(7);
                return (
                  <React.Fragment key={idx}>
                    <div></div>
                    <div className={clsName}>{name}:</div>
                    <div className="la-summary-mid">
                      <img
                        src={fullProductImageUrl("remove-item.jpg")}
                        height="12"
                        onClick={() => this.removeCoupon(idx)}
                      ></img>
                    </div>
                    <div className="la-summary-val">
                      <font color="red">{toAmountEx(item.price)}</font>
                    </div>
                  </React.Fragment>
                );
              }
            })}
          {this.promoInfo(true)}
          <div></div>
          <div className={clsName}>
            <b>Total:</b>
          </div>
          <div className={clsName}></div>
          <div className="la-summary-val">
            <b>{toAmountEx(sum.totalPrice)}</b>
          </div>
          {payments &&
            payments.length > 0 &&
            payments.map((pmt, idx) => {
              let pn = pmt.paymentName;
              if (!pn) {
                if (this.props.layaway && pmt.downPayment) pn = "Down payment";
                else pn = "Payment";
              } else {
                if (pmt.amount < 0) pn += " Refund";
              }
              const color = pmt.amount > 0 ? "red" : "blue";
              return (
                <React.Fragment key={idx}>
                  <div></div>
                  <div className={clsName}>{pn}:</div>
                  <div className={clsName}></div>
                  <div className="la-summary-val">
                    <font color={color}>{toAmountEx(0 - pmt.amount)}</font>
                  </div>
                </React.Fragment>
              );
            })}
          {this.state.order.balance && (
            <React.Fragment>
              <div></div>
              <div className={clsName}>
                <i>Balance:</i>
              </div>
              <div className={clsName}></div>
              <div className="la-summary-val">
                <i>{toAmountEx(this.state.order.balance)}</i>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  }
  updateOrderForm() {
    const order = this.state.order;
    if (order) {
      const orderItems = order.orderItems;
      const btnStyle = this.state.updOrderBtnDisabled
        ? "disabled-small-btn"
        : "small-btn";
      const storeItems = this.props.allowStoreProd ? order.storeItems : null;
      return (
        <div>
          <div className="bottom-10">
            <i>Order Items</i>
          </div>
          <div className="la-table-c4">
            <div className="la-ord-hd">Product</div>
            <div className="la-ord-hd">Name</div>
            <div className="la-ord-hd">Qty</div>
            <div className="la-ord-hd">Price</div>
            {orderItems.map((item, idx) => {
              if (item.item.quantity > 0) {
                const imgUrl = fullProductImageUrl(item.imageUrl);
                const clsName = idx % 2 === 0 ? "la-item-ev" : "la-item-od";
                const clsName2 =
                  idx % 2 === 0 ? "la-item-ev-250" : "la-item-od-250";
                return (
                  <React.Fragment key={idx}>
                    <div className={clsName}>
                      <img src={imgUrl} width="45" height="30"></img>
                      &nbsp;{item.item.sku}
                    </div>
                    <div className={clsName2}>{item.productName}</div>
                    <div className={clsName}>
                      <ItemQtyBox
                        qty={item.item.quantity}
                        itemIdx={idx}
                        actionHandler={this.itemQtyChanged}
                      />
                    </div>
                    <div className={clsName}>
                      {toAmountEx(item.item.unitPrice)}
                    </div>
                  </React.Fragment>
                );
              }
            })}
            {order.discountName && order.discount > 0 && (
              <React.Fragment>
                <div className="la-discount">Discount</div>
                <div className="la-discount">{order.discountName}</div>
                <div className="la-discount">
                  <img
                    src={fullProductImageUrl("remove-item.jpg")}
                    height="12"
                    onClick={this.removeDiscount}
                  ></img>
                </div>
                <div className="la-discount">{toAmountEx(order.discount)}</div>
              </React.Fragment>
            )}
            {storeItems &&
              storeItems.map((item, idx) => {
                if (item.quantity > 0 && item.typeId < 1) {
                  const imgUrl = fullProductImageUrl(item.imageUrl);
                  const clsName = idx % 2 === 0 ? "la-item-ev" : "la-item-od";
                  const clsName2 =
                    idx % 2 === 0 ? "la-item-ev-250" : "la-item-od-250";
                  let sku = item.sku;
                  if (sku.startsWith("TYPED_")) sku = sku.substring(6);
                  return (
                    <React.Fragment key={idx}>
                      <div className={clsName}>
                        <img src={imgUrl} width="45" height="30"></img>
                        &nbsp;{sku}
                      </div>
                      <div className={clsName2}>{item.name}</div>
                      <div className={clsName}>
                        <ItemQtyBox
                          qty={item.quantity}
                          itemIdx={idx}
                          actionHandler={this.storeItemQtyChanged}
                        />
                      </div>
                      <div className={clsName}>{toAmountEx(item.price)}</div>
                    </React.Fragment>
                  );
                }
              })}
            {this.orderSummary()}
          </div>
          {this.addNewItemForm()}
          {this.state.allowStoreProd && this.storeProdForm()}
          {this.state.allowStoreProd && this.couponForm()}
          <div className="top-10">
            <button
              className={btnStyle}
              name="updateOrder"
              disabled={this.state.updOrderBtnDisabled}
              onClick={this.updateOrder}
            >
              Update Order
            </button>
          </div>
        </div>
      );
    } else return "";
  }

  render() {
    return this.updateOrderForm();
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
export default connect(mapStateToProps)(OrderUpdator);
