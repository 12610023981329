import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import {
  apiBaseUrl,
  fullProductImageUrl,
  isUserLoggedIn,
  setTopContainerWrapperSettings,
} from "../Util";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../form.css";

class EditPVSequence extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      pvIdSeqList: [],
      isLoading: true,
      delayRefresh: false,
      errorMessage: null,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };
    this.processProdRsp = this.processProdRsp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.processUpdateRsp = this.processUpdateRsp.bind(this);
    this.updateSequence = this.updateSequence.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.editAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchProduct();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    setTopContainerWrapperSettings();
  }
  fetchProduct() {
    const url = apiBaseUrl() + "GetProduct";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processProdRsp(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updateSequence(event) {
    const url = apiBaseUrl() + "UpdatePVSequence";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: this.state.pvIdSeqList,
      delayMainRefresh: this.state.delayRefresh,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateRsp(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  processProdRsp(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const prod = response.data;
      if (!prod || !prod.pvList || prod.pvList.length < 2) {
        this.props.history.push("/");
      } else {
        let pvIdSeqList = [];
        let i;
        for (i = 0; i < prod.pvList.length; i++) {
          pvIdSeqList.push({
            id: prod.pvList[i].id,
            sequence: prod.pvList[i].sequence,
          });
        }
        this.setState({
          product: prod,
          pvIdSeqList,
          updBtnEnabled: false,
          isLoading: false,
        });
      }
    }
  }
  processUpdateRsp(response) {
    const url = "/product/" + this.state.product.id;
    this.props.history.push(url);
  }

  productInfo() {
    const prod = this.state.product;
    const imgUrl = fullProductImageUrl(prod.imageUrl);

    return (
      <div className="form-wrapper">
        <div className="form-item-label">Product ID:</div>
        <div className="form-item-value">{prod.id}</div>
        <div className="form-item-label">Product Image:</div>
        <div className="form-item-value">
          <a href={imgUrl} target="ProductImage">
            <img src={imgUrl} width="80" alt="Small"></img>
          </a>
        </div>
        <div className="form-item-label">Category:</div>
        <div className="form-item-value">{prod.categoryId}</div>
        <div className="form-item-label">Product Name:</div>
        <div className="form-item-value">{prod.name}</div>
        {prod.shortName && (
          <React.Fragment>
            <div className="form-item-label">Short Name:</div>
            <div className="form-item-value">{prod.shortName}</div>
          </React.Fragment>
        )}
        <div className="form-item-label">Variant Name:</div>
        <div className="form-item-value">{prod.variantName}</div>
      </div>
    );
  }
  handleChange(event) {
    if (event.target.name === "delayRefresh") {
      this.setState({ delayRefresh: event.target.checked });
    } else {
      const idx = parseInt(event.target.name.substring(1));
      let pvIdSeqList = [];
      let i;

      for (i = 0; i < this.state.pvIdSeqList.length; i++) {
        const seq =
          idx === i ? event.target.value : this.state.pvIdSeqList[i].sequence;
        pvIdSeqList.push({ id: this.state.pvIdSeqList[i].id, sequence: seq });
      }
      this.setState({ pvIdSeqList, updBtnEnabled: true });
    }
  }

  theForm() {
    const btnStyle = this.state.updBtnEnabled
      ? "btn-style"
      : "disabled-btn-style";
    return (
      <div className="form-container">
        <p />
        Change the sequence numbers then click the "Update Sequence" button.
        <p />
        <div className="form-wrapper">
          {this.state.pvIdSeqList.map((pvSeq, idx) => {
            const name = "S" + idx;
            const title =
              this.state.product.pvList[idx].sku +
              " (" +
              this.state.product.pvList[idx].variantValue +
              ")";
            return (
              <React.Fragment key={idx}>
                <div className="form-item-label">
                  <input
                    type="text"
                    inputmode="decimal"
                    size="4"
                    name={name}
                    value={pvSeq.sequence}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="form-item-value">{title}</div>
              </React.Fragment>
            );
          })}
        </div>
        <div className="generic-flex-top10">
          <div>
            <button
              name="Update"
              className={btnStyle}
              disabled={!this.state.updBtnEnabled}
              onClick={this.updateSequence}
            >
              Update Sequence
            </button>
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="delayRefresh"
              defaultChecked={this.state.delayRefresh}
              disabled={!this.state.updBtnEnabled}
              onChange={this.handleChange}
            ></input>{" "}
            Delay Refresh
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-container">
        <div className="top-wrapper">
          <font size="5">Update Variant Sequence</font>
          <p />
          <div className="form-container">{this.productInfo()}</div>
          {this.theForm()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    categoryId: state.search.categoryId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditPVSequence);
