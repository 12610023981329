import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getMainSiteUrl,
} from "../Util";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import { registerWebOrder } from "../actions/webOrderActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditStoreSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storeSetting: null,
      ftpPassword: null,
      isLoading: true,
      regStoreName: this.props.storeName,
      useDefaultFtp: true,
      enableNotification: false,
      updateBtnDisabled: true,
      mobileMode: false,
      valErrMsg: null,
      errorMessage: null,
      viewProdAllowed: checkView("Product"),
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
      advAllowed: checkSecurity("RootFeatures"),
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.processRegisterResponse = this.processRegisterResponse.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewProdAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      let useDefaultFtp = true;
      let enableNotification = false;
      let storeSetting = response.setting
        ? response.setting
        : { id: 0, storeId: this.props.match.params.id };
      if (storeSetting.id <= 0) {
        storeSetting = {
          ...storeSetting,
          authToken: this.generateToken(),
        };
      } else {
        if (storeSetting.ftpServer && storeSetting.ftpUser) {
          useDefaultFtp = false;
        }
        if (
          storeSetting.emailCustomer ||
          storeSetting.emailKioskCustomer ||
          storeSetting.storeEmail
        ) {
          enableNotification = true;
        }
      }
      this.setState({
        storeSetting,
        regStoreName: response.regStoreName,
        ftpPassword: storeSetting.ftpPassword,
        useDefaultFtp,
        enableNotification,
        isLoading: false,
      });
    }
  }
  fetchData() {
    const url = apiBaseUrl() + "GetStoreSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get store setting",
        });
        console.log(error);
      });
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let topLevel = false;
    let ftpOrderDat = this.state.storeSetting.ftpOrderDat;
    let ftpInvoiceDat = this.state.storeSetting.ftpInvoiceDat;

    if (name.startsWith("TOP_")) {
      name = name.substring(4);
      topLevel = true;
    }
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      value = event.target.checked;
      if (!event.target.checked) {
        if (name === "genOrderDat") ftpOrderDat = false;
        else if (name === "genInvoiceDat") {
          ftpInvoiceDat = false;
        }
      }
    }
    if (topLevel) {
      this.setState({
        [name]: value,
        updateBtnDisabled: false,
        valErrMsg: null,
      });
      if (name === "useDefaultFtp" && value === true) {
        const storeSetting = {
          ...this.state.storeSetting,
          ftpServer: null,
          ftpUser: null,
          ftpPassword: null,
        };
        this.setState({ storeSetting });
      }
    } else {
      const storeSetting = {
        ...this.state.storeSetting,
        [name]: value,
        ftpOrderDat,
        ftpInvoiceDat,
      };
      this.setState({
        storeSetting,
        updateBtnDisabled: false,
        valErrMsg: null,
      });
    }
  }
  generateToken() {
    let S4 = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + "-" + S4() + S4();
  }

  handleUpdate(event) {
    event.preventDefault();

    if (
      !this.state.useDefaultFtp &&
      this.state.storeSetting.ftpPassword !== this.state.ftpPassword
    ) {
      this.setState({ valErrMsg: "Two passwords do not match!" });
      return;
    }

    let data = this.state.storeSetting;
    if (!this.state.enableNotification) {
      data = {
        ...this.state.storeSetting,
        storeEmail: null,
        emailCustomer: false,
        emailKioskCustomer: false,
      };
    }
    const url = apiBaseUrl() + "UpdateStoreSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processUpdateResponse(response) {
    if (response.status) {
      const url = "/editLocations/" + this.state.storeSetting.storeId;
      this.props.history.push(url);
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  processRegisterResponse(response, kiosk) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        const payload = {
          userId: this.props.payload,
          storeId: this.state.storeSetting.storeId,
          storeName: this.state.storeSetting.storeName,
          kiosk,
        };
        this.props.registerWebOrder(payload);
        let url =
          getMainSiteUrl() +
          "/__register/" +
          this.state.storeSetting.storeId +
          "/" +
          this.state.storeSetting.authToken;
        if (kiosk) url += "/kiosk";
        window.location.replace(url);
      } else {
        this.setState({ errorMessage: "Failed to register" });
      }
    }
  }
  handleRegister(kiosk) {
    const apiName = this.state.regStoreName
      ? "DeregisterStore"
      : "RegisterStore";
    const url = apiBaseUrl() + apiName;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.storeSetting,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processRegisterResponse(res.data, kiosk);
      })
      .catch((error) => {
        console.log(error);
      });

    if (this.state.regStoreName) {
      const payload = {
        userId: this.props.payload,
        storeId: 0,
        storeName: null,
        kiosk: false,
      };
      this.props.registerWebOrder(payload);
      const url =
        getMainSiteUrl() +
        "/__register/" +
        this.state.storeSetting.storeId +
        "/remove";
      window.location.replace(url);
    }
  }
  notificationInfo() {
    const ss = this.state.storeSetting;
    const enStore = ss.storeEmail && ss.storeEmail.length > 0 ? true : false;
    const enCust = ss.emailCustomer || ss.emailKioskCustomer ? true : false;
    const disBoth = !enStore && !enCust ? true : false;
    const emailType = this.state.mobileMode ? "email" : "text";

    return (
      <React.Fragment>
        <div className="form-item-label">
          <b>Notification:</b>{" "}
        </div>
        <div className="form-item-value">
          <input
            type="checkbox"
            name="TOP_CB_enableNotification"
            defaultChecked={this.state.enableNotification}
            disabled={!this.state.editAllowed}
            onChange={this.handleChange}
          ></input>
          Enable Email Notification
        </div>
        {this.state.enableNotification && (
          <React.Fragment>
            <div className="form-item-label">Customer: </div>
            <div className="form-item-value">
              <input
                type="checkbox"
                name="CB_emailCustomer"
                defaultChecked={ss.emailCustomer}
                disabled={!this.state.editAllowed}
                onChange={this.handleChange}
              ></input>
              Send Order Emails to Customers
              <br />
              <input
                type="checkbox"
                name="CB_emailKioskCustomer"
                defaultChecked={ss.emailKioskCustomer}
                disabled={!this.state.editAllowed}
                onChange={this.handleChange}
              ></input>
              Email Kiosk Customer
            </div>
            <div className="form-item-label">Store Email: </div>
            <div className="form-item-value">
              <input
                type={emailType}
                size="30"
                name="storeEmail"
                value={ss.storeEmail || ""}
                disabled={!this.state.editAllowed}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="form-item-label">From Email: </div>
            <div className="form-item-value">
              <input
                type={emailType}
                size="30"
                name="fromEmail"
                value={ss.fromEmail || ""}
                disabled={!this.state.editAllowed || disBoth}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="form-item-label">From Name: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="30"
                name="fromEmailName"
                value={ss.fromEmailName || ""}
                disabled={!this.state.editAllowed || disBoth}
                onChange={this.handleChange}
              ></input>
            </div>
            {this.state.advAllowed && (
              <React.Fragment>
                <div className="form-item-label">CustDelvTmpl: </div>
                <div className="form-item-value">
                  <input
                    type="text"
                    size="30"
                    name="custDeliveryTmpl"
                    value={ss.custDeliveryTmpl || ""}
                    disabled={!this.state.editAllowed || !enCust}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="form-item-label">CustPickupTmpl: </div>
                <div className="form-item-value">
                  <input
                    type="text"
                    size="30"
                    name="custPickupTmpl"
                    value={ss.custPickupTmpl || ""}
                    disabled={!this.state.editAllowed || !enCust}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="form-item-label">StoreDelvTmpl: </div>
                <div className="form-item-value">
                  <input
                    type="text"
                    size="30"
                    name="storeDeliveryTmpl"
                    value={ss.storeDeliveryTmpl || ""}
                    disabled={!this.state.editAllowed || !enStore}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="form-item-label">StorePickupTmpl: </div>
                <div className="form-item-value">
                  <input
                    type="text"
                    size="30"
                    name="storePickupTmpl"
                    value={ss.storePickupTmpl || ""}
                    disabled={!this.state.editAllowed || !enStore}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  datSetting() {
    const editAllowed = this.state.editAllowed;
    const ss = this.state.storeSetting;
    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";
    const pTitle = this.state.mobileMode ? "Password" : "FTP Password";
    const pTitle2 = this.state.mobileMode ? "Confirm" : "Confirm Password";
    const disablePrefix =
      !this.state.editAllowed ||
      ss.useOrderNumForInvoice ||
      (!ss.genInvoiceDat && !ss.genLeaseInvoiceDat);
    return (
      <React.Fragment>
        <div className="form-item-label">
          <b>DAT Setting</b>
        </div>
        <div className="form-item-value">
          <hr className={hrClsName} />
        </div>
        <div className="form-item-label">DAT: </div>
        <div className="form-item-value">
          <fieldset className="fs-verify-container">
            <legend>Order DAT</legend>
            <div>
              <div>
                <input
                  type="checkbox"
                  name="CB_genOrderDat"
                  defaultChecked={ss.genOrderDat}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>
                Generate &nbsp;&nbsp;
                <input
                  type="checkbox"
                  name="CB_ftpOrderDat"
                  defaultChecked={ss.ftpOrderDat}
                  disabled={!this.state.editAllowed || !ss.genOrderDat}
                  onChange={this.handleChange}
                ></input>
                FTP
              </div>
              <div>
                <input
                  type="checkbox"
                  name="CB_genLeaseOrderDat"
                  defaultChecked={ss.genLeaseOrderDat}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>
                Generate for Lease &nbsp;
                <input
                  type="checkbox"
                  name="CB_ftpLeaseOrderDat"
                  defaultChecked={ss.ftpLeaseOrderDat}
                  disabled={!this.state.editAllowed || !ss.genLeaseOrderDat}
                  onChange={this.handleChange}
                ></input>
                FTP for Lease
              </div>
            </div>
          </fieldset>
          <div>&nbsp;</div>
          <fieldset className="fs-verify-container">
            <legend>Invoice DAT</legend>
            <div>
              <div>
                <input
                  type="checkbox"
                  name="CB_genInvoiceDat"
                  defaultChecked={ss.genInvoiceDat}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>
                Generate&nbsp;&nbsp;
                <input
                  type="checkbox"
                  name="CB_ftpInvoiceDat"
                  defaultChecked={ss.ftpInvoiceDat}
                  disabled={!this.state.editAllowed || !ss.genInvoiceDat}
                  onChange={this.handleChange}
                ></input>
                FTP
              </div>
              <div>
                <input
                  type="checkbox"
                  name="CB_genLeaseInvoiceDat"
                  defaultChecked={ss.genLeaseInvoiceDat}
                  disabled={!this.state.editAllowed}
                  onChange={this.handleChange}
                ></input>
                Generate for Lease &nbsp;&nbsp;
                <input
                  type="checkbox"
                  name="CB_ftpLeaseInvoiceDat"
                  defaultChecked={ss.ftpLeaseInvoiceDat}
                  disabled={!this.state.editAllowed || !ss.genLeaseInvoiceDat}
                  onChange={this.handleChange}
                ></input>
                FTP for Lease
              </div>
              <div>
                <input
                  type="checkbox"
                  name="CB_useOrderNumForInvoice"
                  defaultChecked={ss.useOrderNumForInvoice}
                  disabled={
                    !this.state.editAllowed ||
                    (!ss.genInvoiceDat && !ss.genLeaseInvoiceDat)
                  }
                  onChange={this.handleChange}
                ></input>{" "}
                Use Order # as Invoice Number
                {!disablePrefix && (
                  <React.Fragment>
                    <br />
                    Prefix:{" "}
                    <input
                      type="text"
                      size="8"
                      name="invoicePrefix"
                      value={ss.invoicePrefix}
                      onChange={this.handleChange}
                      disabled={disablePrefix}
                    ></input>
                    <br />
                    Start:{" "}
                    <input
                      type="text"
                      size="8"
                      name="invoiceStartNum"
                      value={ss.invoiceStartNum}
                      onChange={this.handleChange}
                      disabled={disablePrefix}
                    ></input>
                  </React.Fragment>
                )}
              </div>
            </div>
          </fieldset>
        </div>
        <div className="form-item-label">FTP: </div>
        <div className="form-item-value">
          <input
            type="checkbox"
            name="TOP_CB_useDefaultFtp"
            defaultChecked={this.state.useDefaultFtp}
            onChange={this.handleChange}
            disabled={!editAllowed}
          ></input>
          Use Default FTP
        </div>
        {!this.state.useDefaultFtp && (
          <React.Fragment>
            <div className="form-item-label">
              FTP Server<font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                size="40"
                name="ftpServer"
                value={ss.ftpServer}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required"]}
                errorMessages={["FTP Server is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">
              FTP User<font color="red">*</font>:
            </div>
            <div className="form-item-value">
              <TextInput
                size="30"
                name="ftpUser"
                value={ss.ftpUser}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required"]}
                errorMessages={["FTP User is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">
              {pTitle}
              <font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                type="password"
                size="20"
                name="ftpPassword"
                value={ss.ftpPassword}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required"]}
                errorMessages={["Password is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">
              {pTitle2}
              <font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                type="password"
                size="20"
                name="TOP_ftpPassword"
                value={this.state.ftpPassword}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required"]}
                errorMessages={["Confirm Password is required"]}
              ></TextInput>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  theForm() {
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const ss = this.state.storeSetting;
    const btnTitle = ss.id > 0 ? "Update" : "Setup";
    const oTitle = this.state.mobileMode ? "Order Start" : "Order Start Number";
    const lTitle = this.state.mobileMode
      ? "Layaway Start"
      : "Layaway Start Number";
    const colCnt = this.state.mobileMode ? 40 : 50;
    const rowCnt = this.state.mobileMode ? 6 : 5;
    const regBtnTitle = this.state.regStoreName
      ? "Deregister"
      : "Register This Computer as " + ss.storeName;
    const name = this.props.kiosk ? "Kiosk" : this.state.regStoreName;
    const sdiAllowed = editAllowed && ss.autoCompleteAddr;
    return (
      <div>
        {ss.id > 0 && (
          <React.Fragment>
            <div>
              {this.state.regStoreName && (
                <label>
                  This computer is registered as{" "}
                  <b>
                    <font color="red">{name}&nbsp;</font>
                  </b>
                </label>
              )}
              <button
                className="btn-style"
                onClick={() => this.handleRegister(false)}
              >
                {regBtnTitle}
              </button>
              {!this.state.regStoreName && this.state.viewAllowed && (
                <div>
                  <p />
                  <button
                    className="green-btn-style"
                    onClick={() => this.handleRegister(true)}
                  >
                    Register This Computer As Kiosk
                  </button>
                </div>
              )}
            </div>
            <div>&nbsp;</div>
          </React.Fragment>
        )}
        <ValidatorForm ref="form" onSubmit={this.handleUpdate}>
          <div className="form-wrapper">
            <div className="form-item-label">Store ID: </div>
            <div className="form-item-value">{ss.storeId}</div>
            <div className="form-item-label">
              Store Name<font color="red">*</font>:
            </div>
            <div className="form-item-value">
              <TextInput
                size="30"
                name="storeName"
                value={ss.storeName}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required"]}
                errorMessages={["Store Name is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">
              Order Prefix<font color="red">*</font>:
            </div>
            <div className="form-item-value">
              <TextInput
                size="5"
                name="orderPrefix"
                value={ss.orderPrefix}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required"]}
                errorMessages={["Order Prefix is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">
              {oTitle}
              <font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                size="10"
                inputmode="decimal"
                name="orderStartNum"
                value={ss.orderStartNum}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["isNumber", "minNumber:1000"]}
                errorMessages={[
                  "Invalid Start Number",
                  "Must be at least 1000",
                ]}
              ></TextInput>
            </div>
            <div className="form-item-label">Layaway Prefix:</div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="layawayPrefix"
                value={ss.layawayPrefix}
                onChange={this.handleChange}
                disabled={!editAllowed}
              ></input>
            </div>
            <div className="form-item-label">{lTitle}:</div>
            <div className="form-item-value">
              <input
                type="text"
                size="10"
                inputmode="decimal"
                name="layawayStartNum"
                value={ss.layawayStartNum}
                onChange={this.handleChange}
                disabled={!editAllowed}
              ></input>
            </div>
            <div className="form-item-label">&nbsp;</div>
            <div className="form-item-value">
              Order complete message for KIOSK
            </div>
            <div className="form-item-label">Message:</div>
            <div className="form-item-value">
              <textarea
                name="kioskOrderCompleteMsg"
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
                cols={colCnt}
                rows={rowCnt}
                value={ss.kioskOrderCompleteMsg || ""}
              ></textarea>
            </div>
            <div className="form-item-label">Geo Location: </div>
            <div className="form-item-value">
              <input
                type="checkbox"
                name="CB_autoCompleteAddr"
                defaultChecked={ss.autoCompleteAddr}
                onChange={this.handleChange}
                disabled={!editAllowed}
              ></input>
              Auto Complete Address
              <br />
              <input
                type="checkbox"
                name="CB_showDistanceInfo"
                defaultChecked={ss.showDistanceInfo}
                onChange={this.handleChange}
                disabled={!sdiAllowed}
              ></input>
              Show Driving Distance Info
            </div>
            {this.datSetting()}
            {this.notificationInfo()}
            <div className="form-item-label">Note: </div>
            <div className="form-item-value">
              <textarea
                name="note"
                cols={colCnt}
                rows={rowCnt}
                value={ss.note || ""}
                onChange={this.handleChange}
                disabled={!editAllowed}
              ></textarea>
            </div>
          </div>
          {this.state.valErrMsg && (
            <div>
              <font color="red">{this.state.valErrMsg}</font>
            </div>
          )}
          <p />
          <button
            name="update"
            className={btnStyle}
            disabled={this.state.updateBtnDisabled || !editAllowed}
          >
            {btnTitle}
          </button>
        </ValidatorForm>
      </div>
    );
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    let title = "Setup Web Order";
    if (this.state.storeSetting.id > 0) {
      if (this.state.editAllowed) {
        title = "Update Web Order Setting";
      } else {
        title = "Register for Web Order";
      }
    }
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          {this.theForm()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    kiosk: state.webOrder.kiosk,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
    registerWebOrder: (payload) => {
      dispatch(registerWebOrder(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditStoreSetting);
