import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  fullProductImageUrl,
  toAmount,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
  toDateStr,
  getItemCount,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../layaway.css";
import InvoiceLinkWidget from "./InvoiceLinkWidget";
import OrderUpdator from "./OrderUpdator";
import ShippingUpdator from "./ShippingUpdator";
import OrderNotes from "./OrderNotes";
import OrderActivityLog from "./OrderActivityLog";
import PrintOrder from "./PrintOrder";
import MarkPaid from "./MarkPaid";
import ReturnedInfo from "../store/ReturnedInfo";
import Working from "../Working";

class LayawayDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layaway: null,
      isLoading: true,
      errorMessage: null,
      note: null,
      statusNote: null,
      paymentMethod: 1,
      addPmtExpanded: false,
      layawayStatus: 0,
      updStatusBtnDisabled: true,
      addPmtBtnDisabled: true,
      amount: null,
      nameOnCard: null,
      last4: null,
      approvalCode: null,
      mobileMode: false,
      deliveryInfo: null,
      updBtnNextPmtDateDisabled: true,
      updOrderBtnDisabled: true,
      updateOrderMode: false,
      minDate: null,
      generatingInvoice: false,
      statusDate: null,
      maxStatusDate: null,
      editAllowed: checkUpdate("Order"),
      viewAllowed: checkView("Order"),
    };
    this.fetchLayaway = this.fetchLayaway.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.addPayment = this.addPayment.bind(this);
    this.updateNextPaymentDate = this.updateNextPaymentDate.bind(this);
    this.updateLayawayOrder = this.updateLayawayOrder.bind(this);
    this.updateLayaway = this.updateLayaway.bind(this);
    this.ackOrder = this.ackOrder.bind(this);
    this.setInitState = this.setInitState.bind(this);
    this.generateInvoice = this.generateInvoice.bind(this);
    this.updateDatePaid = this.updateDatePaid.bind(this);
    this.gotoReturnItems = this.gotoReturnItems.bind(this);
  }
  setInitState() {
    this.setState({
      addPmtExpanded: false,
      updStatusBtnDisabled: true,
      addPmtBtnDisabled: true,
      amount: null,
      nameOnCard: null,
      last4: null,
      approvalCode: null,
      updBtnNextPmtDateDisabled: true,
      updOrderBtnDisabled: true,
      updateOrderMode: false,
      generatingInvoice: false,
    });
  }
  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    let newDate = new Date();
    newDate.setDate(newDate.getDate() + 1);

    let dateStr = "" + newDate.getFullYear() + "-";
    if (newDate.getMonth < 9) dateStr += "0";
    dateStr += "" + (newDate.getMonth() + 1) + "-";
    if (newDate.getDate() < 10) dateStr += "0";
    dateStr += "" + newDate.getDate();

    const today = toDateStr(new Date());
    this.setState({
      minDate: dateStr,
      statusDate: today,
      maxStatusDate: today,
    });
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchLayaway();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    let updStatusBtnDisabled = this.state.updStatusBtnDisabled;

    if (name === "layawayStatus" && this.state.layaway) {
      if (this.state.layaway.statusId == event.target.value) {
        updStatusBtnDisabled = true;
      } else updStatusBtnDisabled = false;
    } else if (name === "paymentMethod") {
      val = parseInt(event.target.value);
    } else if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
      //TODO updateBtnDisabled = this.state.updateBtnDisabled;
    }
    let addPmtBtnDisabled = this.state.addPmtBtnDisabled;
    if (name === "amount") {
      if (!val || val.trim().length === 0) {
        addPmtBtnDisabled = true;
      } else {
        let amount = 0;
        try {
          amount = parseFloat(val);
        } catch (error) {}
        if (amount > 0) addPmtBtnDisabled = false;
      }
    }

    const updBtnNextPmtDateDisabled =
      name === "nextPaymentDate" ? false : this.state.updBtnNextPmtDateDisabled;

    this.setState({
      [name]: val,
      updStatusBtnDisabled,
      addPmtBtnDisabled,
      updBtnNextPmtDateDisabled,
    });
  }
  processResponse(response, updated) {
    if (response && response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const layaway = response.data;
      if (layaway) {
        let deliveryInfo = layaway.deliveryAddress
          ? layaway.deliveryAddress
          : layaway.customer;
        if (deliveryInfo && !layaway.deliveryAddress) {
          if (
            deliveryInfo.state &&
            deliveryInfo.state != "GA" &&
            deliveryInfo.state != "Georgia"
          ) {
            //non-Georgia address
            deliveryInfo = {
              ...deliveryInfo,
              addressLine1: null,
              addressLine2: null,
              city: null,
              state: null,
              zipCode: null,
            };
          }
        }

        this.setState({
          layaway,
          deliveryInfo,
          layawayStatus: layaway.statusId,
          nextPaymentDate: layaway.nextPmtDateStr,
          isLoading: false,
        });
        if (updated) {
          this.setInitState();
        }
      } else {
        this.setState({
          errorMessage: "An error has occurred",
          isLoading: false,
        });
      }
    }
  }
  fetchLayaway() {
    const url = apiBaseUrl() + "GetLayawayDetail";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.props.match.params.orderNo,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateNextPaymentDate(event) {
    const url = apiBaseUrl() + "UpdateLayawayPmtDate";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      orderNumber: this.state.layaway.orderNumber,
      deliveryDate: this.state.nextPaymentDate,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            window.location.reload();
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              message: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updateLayaway(order) {
    this.setState({ layaway: order });
    this.updateLayawayOrder(order);
  }
  updateLayawayOrder(layaway) {
    const url = apiBaseUrl() + "UpdateLayawayOrder";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: layaway,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data, true);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  ackOrder() {
    const url = apiBaseUrl() + "AckOrder";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.state.layaway.orderNumber,
      flag: true,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            this.props.history.push("/");
          } else {
            this.setState({
              errorMessage: "Failed to mark acknowledged",
              message: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  layawayDetailHtml() {
    const layaway = this.state.layaway;
    const orderItems = layaway.orderItems;
    const storeItems = layaway.storeItems;
    const storeCoupons = layaway.storeCoupons;
    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html +=
      '<table><tr><th style="background-color: #D0D0D0">Product</th><th style="background-color: #D0D0D0">Name</th><th style="background-color: #D0D0D0">Qty.</th><th style="background-color: #D0D0D0">Price</th></tr>';

    for (let idx = 0; idx < orderItems.length; idx++) {
      const item = orderItems[idx];
      const url = "/product/" + item.productId;
      const imgUrl = fullProductImageUrl(item.imageUrl);
      const description = item.productName;
      html +=
        '<tr><td><a href="' +
        url +
        '"><img src="' +
        imgUrl +
        '" width="45" height="30"></img></a>&nbsp;&nbsp;';
      if (!item.pvExists) html += '<font color="red">';
      html += item.item.sku;
      if (!item.pvExists) html += "</font>";
      html += "</td>";
      html += "<td width='300'>" + description + "</td>";
      html += '<td align="center">' + item.item.quantity + "</td>";
      html +=
        '<td align="right">' + toAmount(item.item.unitPrice) + "</td></tr>";
    }

    if (storeItems && storeItems.length > 0) {
      for (let idx = 0; idx < storeItems.length; idx++) {
        const item = storeItems[idx];
        if (item.typeId <= 0) {
          const imgUrl = fullProductImageUrl(item.imageUrl);

          html +=
            '<tr><td><img src="' +
            imgUrl +
            '" width="45" height="30"></img>&nbsp;&nbsp;Store Item</td>';
          html += "<td>" + item.name + "</td>";
          html += '<td align="center">' + item.quantity + "</td>";
          html += '<td align="right">' + toAmount(item.price) + "</td></tr>";
        }
      }
    }
    if (layaway.discountName && layaway.discount > 0) {
      html +=
        '<tr><td colspan="3">' +
        layaway.discountName +
        ':</td><td align="right">' +
        toAmount(0 - layaway.discount) +
        "</td></tr>";
    }
    html +=
      '<tr><td colspan="3">Subtotal:</td><td align="right">' +
      toAmount(layaway.subtotal) +
      "</td></tr>";
    html +=
      '<tr><td colspan="3">GA Tax: </td><td align="right">' +
      toAmount(layaway.tax) +
      "</td></tr>";
    if (layaway.deliveryFee && layaway.deliveryFee > 0) {
      html +=
        '<tr><td colspan="3">Delivery Fee: </td><td align="right">' +
        toAmount(layaway.deliveryFee) +
        "</td></tr>";
    }
    if (storeCoupons && storeCoupons.length > 0) {
      for (let idx = 0; idx < storeCoupons.length; idx++) {
        html +=
          '<tr><td colspan="3">' +
          storeCoupons[idx].name +
          ':</td><td align="right"><font color="red">' +
          toAmount(storeCoupons[idx].price) +
          "</font></td></tr>";
      }
    }
    html +=
      '<tr><td colspan="3"><b>Total:</b> </td><td align="right"><b>' +
      toAmount(layaway.totalPrice) +
      "</b></td></tr>";
    let totalDeposit = 0;
    if (storeItems && storeItems.length > 0) {
      for (let idx = 0; idx < storeItems.length; idx++) {
        const item = storeItems[idx];
        if (item.typeId > 0) {
          const amount = item.price > 0 ? item.price : 0 - item.price;
          const isReturn = item.price < 0;
          const img =
            item.depositType && item.depositType.imageUrl
              ? item.depositType.imageUrl
              : item.imageUrl;
          const imgUrl = fullProductImageUrl(img);
          let name = item.name;
          let properties = "";

          if (isReturn) {
            name += ' (<font color="red">Return</font>)';
          }
          if (item.properties && item.properties.length > 0) {
            for (let k = 0; k < item.properties.length; k++) {
              properties +=
                "<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
                item.properties[k].key +
                ": " +
                item.properties[k].value;
            }
          }

          html +=
            '<tr><td colspan="3"><img src="' +
            imgUrl +
            '" height="20"></img>&nbsp;&nbsp;' +
            name +
            properties +
            "</td>";
          if (isReturn) {
            html += '<td align="right">' + toAmount(amount) + "</td></tr>";
          } else {
            html +=
              '<td align="right"><font color="red">-' +
              toAmount(amount) +
              "</font></td></tr>";
          }

          totalDeposit += item.price;
        }
      }
    }
    if (totalDeposit > 0) {
      let balance = layaway.totalPrice - totalDeposit;

      if (Math.abs(balance) < 1) balance = 0;

      html += '<tr><td colspan="3"><i>Balance:</i></td>';
      html += '<td align="right">$' + balance.toFixed(2) + "</td></tr>";
    }
    html += "</table>";
    return html;
  }
  updateStatus() {
    const url = apiBaseUrl() + "UpdateLayawayStatus";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objectId: this.state.layaway.id,
      statusId: this.state.layawayStatus,
      statusDate: this.state.statusDate,
      note: this.state.statusNote,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            window.location.reload();
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              message: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  generateInvoice(event) {
    const url = apiBaseUrl() + "GenerateLayawayInvoice";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.state.layaway.orderNumber,
    };
    this.setState({ generatingInvoice: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data, true);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  addPayment(event) {
    let data;
    if (this.state.paymentMethod === 1) {
      data = {
        orderNumber: this.state.layaway.orderNumber,
        amount: this.state.amount,
        typeId: 1,
        note: this.state.note,
      };
    } else {
      data = {
        orderNumber: this.state.layaway.orderNumber,
        amount: this.state.amount,
        typeId: 2,
        nameOnCard: this.state.nameOnCard,
        last4: this.state.last4,
        approvalCode: this.state.approvalCode,
        note: this.state.note,
      };
    }

    this.setState({ addPmtBtnDisabled: true });

    const url = apiBaseUrl() + "AddLayawayPayment";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data, true);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  shippingUpdator() {
    const layaway = this.state.layaway;
    if (layaway.statusId < 20) {
      let isContactAddr = layaway.delivery ? false : true;
      let address = layaway.delivery
        ? layaway.deliveryAddress
        : layaway.customer;
      let pickupLoc = null;
      const scheduledDate = layaway.scheduledDate
        ? toDateStr(new Date(layaway.scheduledDate))
        : null;
      const info = {
        orderNumber: layaway.orderNumber,
        delivery: layaway.delivery,
        deliveryInstr: layaway.deliveryInstr,
        orderSubtotal: layaway.subtotal,
        scheduledDate,
        address,
        isContactAddr,
        pickupLoc,
        layaway: true,
      };
      return <ShippingUpdator info={info} callback={this.processResponse} />;
    }
  }
  addPaymentSection() {
    if (this.state.addPmtExpanded && this.addPaymentAllowed()) {
      const addBtnStyle = this.state.addPmtBtnDisabled
        ? "disabled-btn-style"
        : "btn-style";
      const layaway = this.state.layaway;
      const p20 = layaway.totalPrice * 0.2;
      const p10 = layaway.totalPrice * 0.1;
      const poff = layaway.totalPrice - layaway.depositAmount;
      let message = null;
      if (p10 >= poff) {
        message = "(Pay off: $" + poff.toFixed(2) + ")";
      } else if (p20 >= poff) {
        message =
          "(10%: $" + p10.toFixed(2) + ", Pay off: $" + poff.toFixed(2) + ")";
      } else {
        message =
          "(10%: $" +
          p10.toFixed(2) +
          ", 20%: $" +
          p20.toFixed(2) +
          ", Pay off: $" +
          poff.toFixed(2) +
          ")";
      }
      return (
        <div className="left-20">
          <div className="twocol-form">
            <div>
              Amount<font color="red">*</font>:
            </div>
            <div>
              <input
                type="text"
                size="6"
                name="amount"
                inputMode="decimal"
                value={this.state.amount || ""}
                onChange={this.handleChange}
              ></input>
              &nbsp;{message}
            </div>
            {this.state.paymentMethod === 2 && (
              <React.Fragment>
                <div>Name on Card:</div>
                <div>
                  <input
                    type="text"
                    size="20"
                    name="nameOnCard"
                    value={this.state.nameOnCard || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div>Last4:</div>
                <div>
                  <input
                    type="text"
                    size="6"
                    name="last4"
                    value={this.state.last4 || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div>Approval Code:</div>
                <div>
                  <input
                    type="text"
                    size="20"
                    name="approvalCode"
                    value={this.state.approvalCode || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </React.Fragment>
            )}
            <div>Note:</div>
            <div>
              <input
                type="text"
                name="note"
                value={this.state.note || ""}
                onChange={this.handleChange}
              ></input>
            </div>
          </div>
          <div className="top10b15">
            <button
              className={addBtnStyle}
              name="addPmt"
              onClick={this.addPayment}
              disabled={this.state.addPmtBtnDisabled}
            >
              Add Payment
            </button>
          </div>
        </div>
      );
    }
  }
  addPaymentAllowed() {
    const layaway = this.state.layaway;
    if (layaway && layaway.statusId < 5) {
      return layaway.depositAmount < layaway.totalPrice;
    }
    return false;
  }
  nextPaymentDate() {
    const btnStyle = this.state.updBtnNextPmtDateDisabled
      ? "disabled-small-btn"
      : "small-btn";
    return (
      <div className="top10b15">
        Next Payment Date:&nbsp;
        <input
          type="date"
          name="nextPaymentDate"
          value={this.state.nextPaymentDate}
          min={this.state.minDate}
          onChange={this.handleChange}
        ></input>{" "}
        &nbsp;
        <button
          name="updatePmtDate"
          className={btnStyle}
          disabled={this.state.updBtnNextPmtDateDisabled}
          onClick={this.updateNextPaymentDate}
        >
          Update
        </button>
      </div>
    );
  }
  paymentHistory() {
    const layaway = this.state.layaway;

    if (layaway && layaway.paymentList && layaway.paymentList.length > 0) {
      const dateLabel = this.state.mobileMode ? "Date" : "Payment Date";
      const amountLabel = this.state.mobileMode ? "Amount" : "Payment Amount";
      return (
        <div>
          <div className="la-sect-header">
            <div>
              <img
                src={getIconBaseUrl() + "payment-hist.jpg"}
                height="22"
              ></img>
            </div>
            <div className="la-sect-title">
              <font size="4">Payment History</font>
            </div>
          </div>
          <div className="la-table-c5">
            <div className="la-item-hd">&nbsp;</div>
            <div className="la-item-hd">{dateLabel}</div>
            <div className="la-item-hd">{amountLabel}</div>
            <div className="la-item-hd">Info</div>
            <div className="la-item-hd">Note</div>
            {layaway.paymentList.map((pmt, idx) => {
              const clsName = idx % 2 === 0 ? "la-item-ev" : "la-item-od";
              const img = pmt.icon ? pmt.icon : "layaway-deposit.jpg";
              let info = "";
              if (pmt.userId)
                info = "Clerk: " + this.getUserId(pmt.userId) + "<br/>";
              if (pmt.nameOnCard)
                info += "Name on Card: " + pmt.nameOnCard + "<br/>";
              if (pmt.last4) info += "Number: " + pmt.last4 + "<br/>";
              if (pmt.approvalCode)
                info += "Approval Code: " + pmt.approvalCode + "<br/>";
              const amtStr = "$" + pmt.amount.toFixed(2);
              return (
                <React.Fragment key={idx}>
                  <div className={clsName}>
                    <img src={fullProductImageUrl(img)} width="25"></img>
                  </div>
                  <div className={clsName}>{pmt.pmtDateStr}</div>
                  <div className={clsName}>
                    {pmt.ccPmtId && pmt.ccPmtId > 0 ? (
                      <Link to={"/paymentDetail/" + pmt.ccPmtId}>{amtStr}</Link>
                    ) : (
                      <label>{amtStr}</label>
                    )}
                  </div>
                  <div
                    className={clsName}
                    dangerouslySetInnerHTML={{ __html: info }}
                  ></div>
                  <div className={clsName}>{pmt.note}</div>
                </React.Fragment>
              );
            })}
          </div>
          {this.addPaymentAllowed() && (
            <div>
              {this.nextPaymentDate()}
              <div className="flex-wrapper-t10">
                <div>
                  <input
                    type="checkbox"
                    name="CB_addPmtExpanded"
                    defaultChecked={this.state.addPmtExpanded}
                    checked={this.state.addPmtExpanded}
                    onChange={this.handleChange}
                  ></input>{" "}
                </div>
                <div>
                  <b>
                    <font color="darkgreen">Add Payment</font>
                  </b>
                </div>
                {this.state.addPmtExpanded && (
                  <React.Fragment>
                    <div className="left-10">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value={1}
                        checked={this.state.paymentMethod === 1}
                        onChange={this.handleChange}
                      ></input>{" "}
                      Cash/Check
                    </div>
                    <div className="left-10">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value={2}
                        checked={this.state.paymentMethod === 2}
                        onChange={this.handleChange}
                      ></input>
                      Debit/Credit Card
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
          <p />
        </div>
      );
    }
  }
  getStatusSetting() {
    let placeholder = "Reason for status change";
    let showStatusDate = false;
    const layaway = this.state.layaway;
    if (layaway && layaway.allowedList && this.state.layawayStatus > 0) {
      const allowedList = layaway.allowedList;
      const theStatus = allowedList.find(
        (status) => status.id == this.state.layawayStatus
      );
      if (theStatus) {
        if (layaway.storeId <= 0) {
          //Internet layway
          if (theStatus.status === "Cancelled") {
            placeholder = "Reason, refund amount";
          }
        }

        if (
          theStatus.status == "Delivered" ||
          theStatus.status == "Picked Up"
        ) {
          placeholder = "Note";
          showStatusDate = true;
        }
      }
    }
    return { placeholder, showStatusDate };
  }

  showLayawayStatus() {
    const la = this.state.layaway;
    const layawayStatus = la.status;
    console.log("Layaway status");
    console.log(layawayStatus);
    if (!layawayStatus) return "";

    if (layawayStatus.code > 15) {
      return (
        <div className="flex-wrapper">
          <div>Layaway Status: </div>
          <div className="left-10">
            <b>{layawayStatus.status}</b>
          </div>
        </div>
      );
    } else {
      const allowedList = la.allowedList;
      if (this.state.editAllowed) {
        const btnStyle = this.state.updStatusBtnDisabled
          ? "disabled-small-btn"
          : "small-btn";
        const setting = this.getStatusSetting();
        return (
          <div>
            <div className="la-sect-header">
              <div>
                <img src={getIconBaseUrl() + "status.jpg"} height="22"></img>
              </div>
              <div className="la-sect-title">
                <font size="4">Layaway Status</font>
              </div>
            </div>
            <div className="flex-wrapper">
              <div>
                <select
                  name="layawayStatus"
                  value={this.state.layawayStatus}
                  onChange={this.handleChange}
                >
                  {this.state.layaway.status && (
                    <option value={this.state.layaway.status.code}>
                      {this.state.layaway.status.status}
                    </option>
                  )}
                  {allowedList &&
                    allowedList.map((status) => {
                      return (
                        <option value={status.code} key={status.id}>
                          {status.status}
                        </option>
                      );
                    })}
                </select>
              </div>
              {setting.showStatusDate && (
                <div className="left-10">
                  <input
                    type="date"
                    name="statusDate"
                    value={this.state.statusDate}
                    max={this.state.maxStatusDate}
                    onChange={this.handleChange}
                  />
                </div>
              )}
              {this.state.updStatusBtnDisabled === false &&
                !this.state.mobileMode && (
                  <div className="left-10">
                    <input
                      name="statusNote"
                      size="20"
                      value={this.state.statusNote || ""}
                      placeholder={setting.placeholder}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                )}
              <div className="left-10">
                <button
                  name="updateStatus"
                  className={btnStyle}
                  onClick={this.updateStatus}
                  disabled={this.state.updStatusBtnDisabled}
                >
                  Update Status
                </button>
              </div>
            </div>
            {this.state.updStatusBtnDisabled === false &&
              this.state.mobileMode && (
                <div>
                  <input
                    name="note"
                    size="20"
                    value={this.state.note}
                    placeholder={setting.placeholder}
                    onChange={this.handleChange}
                  ></input>
                </div>
              )}
          </div>
        );
      } else {
        const statusStr = layawayStatus ? layawayStatus.status : "Unknown";
        return <div>Layaway Status: {statusStr}</div>;
      }
    }
  }
  updateDatePaid(datePaid) {
    try {
      const layaway = {
        ...this.state.layaway,
        datePaid,
        paidDateStr: toDateStr(new Date(datePaid)),
      };
      this.setState({ layaway });
    } catch (error) {}
  }
  summary() {
    const layaway = this.state.layaway;
    let salesperson = null;
    if (layaway.storeId > 0 && layaway.storeName) {
      if (layaway.kiosk) salesperson = "KIOSK";
      else salesperson = layaway.salesperson;
    }
    const balance = layaway.totalPrice - layaway.depositAmount;
    let statusStr = <label>{layaway.statusId}</label>;
    if (layaway.statusId < 7 && layaway.invoiceNumber) {
      const label = this.state.mobileMode
        ? layaway.invoiceNumber
        : "Invoice " + layaway.invoiceNumber;
      statusStr = <font color="blue">{label}</font>;
    } else {
      if (layaway.status) {
        if (layaway.status.color)
          statusStr = (
            <font color={layaway.status.color}>{layaway.status.status}</font>
          );
        else statusStr = <label>{layaway.status.status}</label>;
      }
    }

    let shipping = <label>Pickup</label>;
    if (layaway.delivery) {
      if (layaway.deliveryDateStr)
        shipping = <label>Delivery ({layaway.deliveryDateStr})</label>;
      else
        shipping = (
          <label>
            Delivery (<font color="red">Will call</font>)
          </label>
        );
    }

    let pmtStatus = layaway.pmtStatus;
    if (!layaway.pmtStatus) {
      if (layaway.balance < 1)
        pmtStatus = {
          status: "Paid in Full",
          color: "green",
          icon: "paid.jpg",
        };
    }

    return (
      <div>
        <div className="twocol-form">
          <div>Date:</div>
          <div>{layaway.dateOrderedStr}</div>
          {salesperson && (
            <React.Fragment>
              <div>Salesperson:</div>
              <div>
                <b>{salesperson}</b>
              </div>
            </React.Fragment>
          )}
          <div>Total Amount:</div>
          <div>${layaway.totalPrice.toFixed(2)}</div>
          <div>Deposit Amount:</div>
          <div>
            ${layaway.depositAmount.toFixed(2)} (
            {layaway.percentPaid.toFixed(2)}%)
          </div>
          <div>Balance:</div>
          <div>${balance.toFixed(2)}</div>
          <div>Next Payment Due:</div>
          <div>{layaway.nextPmtDateStr}</div>
          <div>Shipping:</div>
          <div>{shipping}</div>
          <div>Status:</div>
          <div>{statusStr}</div>
          {pmtStatus && (
            <React.Fragment>
              <div>Payment Status:</div>
              <div>
                <font color={pmtStatus.color}>{pmtStatus.status}</font>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
  customerInfo() {
    const customer = this.state.layaway.customer;
    if (customer) {
      let info = [];
      let idx = 0;
      info.push(
        <span key={idx++}>
          {customer.firstName + " " + customer.lastName}
          <br />
        </span>
      );
      if (customer.addressLine1) {
        info.push(
          <span key={idx++}>
            {customer.addressLine1}
            <br />
          </span>
        );
      }
      if (customer.addressLine2) {
        info.push(
          <span key={idx++}>
            {customer.addressLine2}
            <br />
          </span>
        );
      }
      if (customer.city && customer.city != "Unknown") {
        info.push(
          <span key={idx++}>
            {customer.city}, {customer.state} {customer.zipCode}
            <br />
          </span>
        );
      }
      if (customer.phone) {
        info.push(
          <span key={idx++}>
            Phone: {customer.phone}
            <br />
          </span>
        );
      }
      if (customer.email) {
        info.push(
          <span key={idx++}>
            Email: {customer.email}
            <br />
          </span>
        );
      }
      return (
        <div>
          <font size="4">
            <i>Customer Info</i>
          </font>
          <p />
          {info}
          <br />
        </div>
      );
    }
  }
  showActivities() {
    const layaway = this.state.layaway;
    if (layaway) {
      return (
        <OrderActivityLog
          activityList={layaway.activityList}
          isLayaway={true}
        />
      );
    }
  }
  getUserId(userId) {
    if (userId.length > 15 && userId.indexOf("-") > 0) return "Internet";
    else return userId;
  }
  showInvoiceLinks() {
    const layaway = this.state.layaway;
    if (
      layaway &&
      layaway.showInvoiceLinks &&
      layaway.commonOrderInfo.showLegacyLinks
    ) {
      if (layaway.pdfDatUrl || layaway.printDatUrl || layaway.editDatUrl) {
        const editUrl = layaway.statusId < 20 ? layaway.editDatUrl : null;
        return (
          <div className="top-10">
            <hr className="hr-300" align="left" />
            <div className="la-sect-header">
              <div>
                <img
                  src={getIconBaseUrl() + "invoice-info.jpg"}
                  height="22"
                ></img>
              </div>
              <div className="la-sect-title">
                <font size="4">Legacy ExpressInvoice</font>
              </div>
            </div>
            <InvoiceLinkWidget
              invoicePdfUrl={layaway.pdfDatUrl}
              invoicePrintUrl={layaway.printDatUrl}
              invoiceEditUrl={editUrl}
              showTitle={false}
            />
            {this.showGenInvSection()}
          </div>
        );
      }
    }
  }
  showNotifications() {
    if (
      this.state.layaway &&
      this.state.layaway.notifications &&
      this.state.layaway.notifications.length > 0
    ) {
      const clsName = this.state.mobileMode
        ? "threecol-wrapper"
        : "fourcol-wrapper";
      return (
        <div>
          <p />
          <hr className="hr-300" align="left" />
          <div className="la-sect-header">
            <div>
              <img src={getIconBaseUrl() + "email.jpg"} height="22"></img>
            </div>
            <div className="la-sect-title">
              <font size="4">Notifications</font>
            </div>
          </div>
          <div className={clsName}>
            {this.state.layaway.notifications.map((noti, idx) => {
              const recType = noti.recipientType === 1 ? "Customer" : "Store";
              const img = noti.sent
                ? getIconBaseUrl() + "checkmark.png"
                : getIconBaseUrl() + "cross.png";
              const url = "/notiDetail/" + noti.id;
              return (
                <React.Fragment key={idx}>
                  <div>
                    <img src={img} alt="Status" width="12"></img>
                  </div>
                  <div className="left-10">
                    {noti.typeName} ({recType})
                  </div>
                  {!this.state.mobileMode && (
                    <div className="left-10">{noti.dateStr}</div>
                  )}
                  <div className="left-10">
                    <Link to={url}>{noti.toEmail}</Link>
                  </div>
                </React.Fragment>
              );
            })}
            <div>&nbsp;</div>
          </div>
        </div>
      );
    }
  }
  showGenInvSection() {
    const layaway = this.state.layaway;
    if (layaway && layaway.statusId < 20 && !layaway.invoiceNumber) {
      if (this.state.generatingInvoice) {
        return (
          <div className="top-10">
            <img src={getIconBaseUrl() + "working.gif"} alt="Generating" />
          </div>
        );
      } else {
        return (
          <div className="top-10">
            <button
              name="genInv"
              className="btn-style"
              onClick={this.generateInvoice}
            >
              Generate Invoice
            </button>
          </div>
        );
      }
    }
  }
  showReturnButton() {
    if (
      !this.state.updateOrderMode &&
      this.state.layaway.commonOrderInfo.returnAllowed
    )
      return true;
    else return false;
  }
  gotoReturnItems() {
    const url = "/returnItems/" + this.state.layaway.orderNumber + "/2";
    this.props.history.push(url);
  }
  detailSect() {
    const itemCnt = getItemCount(this.state.layaway.commonOrderInfo);

    if (itemCnt > 0) {
      const showReturn = this.showReturnButton();
      const layaway = this.state.layaway;
      return (
        <React.Fragment>
          <div className="la-sect-header">
            <div>
              <img
                src={getIconBaseUrl() + "order-detail.jpg"}
                height="22"
              ></img>
            </div>
            <div className="la-sect-title">
              <font size="4">Layaway Order Detail</font>
            </div>
            <div className="left-20"></div>
            {layaway.statusId < 20 && (
              <div className="la-sect-title">
                <input
                  type="checkbox"
                  name="CB_updateOrderMode"
                  defaultChecked={this.state.updateOrderMode}
                  checked={this.state.updateOrderMode}
                  onChange={this.handleChange}
                ></input>{" "}
                Update mode
              </div>
            )}
            {showReturn && (
              <div className="left-10">
                <button
                  className="red-small-btn"
                  name="returnBtn"
                  onClick={this.gotoReturnItems}
                >
                  Return Items
                </button>
              </div>
            )}
          </div>
          <p />
          {this.state.updateOrderMode ? (
            <OrderUpdator
              order={this.state.layaway}
              callback={this.updateLayaway}
              allowStoreProd={false}
              layaway={true}
              showSummary={true}
            />
          ) : (
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: this.layawayDetailHtml() }}
              ></div>
              <p />
              <ReturnedInfo
                info={this.state.layaway.returnedInfo}
                coi={this.state.layaway.commonOrderInfo}
                mobileMode={this.state.mobileMode}
              />
            </div>
          )}
        </React.Fragment>
      );
    }
    return (
      <ReturnedInfo
        info={this.state.layaway.returnedInfo}
        mobileMode={this.state.mobileMode}
      />
    );
  }
  render() {
    if (this.state.isLoading) {
      return <Working />;
    }

    if (!this.state.layaway)
      return (
        <div>
          <font color="red">Layaway not found</font>
        </div>
      );

    const layaway = this.state.layaway;
    let title = "Layaway: " + layaway.orderNumber;
    if (layaway.storeId > 0 && layaway.storeName) {
      title = layaway.storeName + " " + title;
    }
    const pending = layaway.acknowledged || !layaway.pendingAck ? false : true;
    const closed = layaway.statusId >= 20 ? true : false;
    const showMarkPaid = !closed && !layaway.paidDateStr ? true : false;
    const sendPdfEnabled = checkSecurity("RootFeatures");
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          {pending && layaway.storeId <= 0 && (
            <button name="ack" className="btn-style" onClick={this.ackOrder}>
              Acknowledge
            </button>
          )}
          <div>
            <div className="topalign-flex">
              {this.summary()}
              <div className="left-40"></div>
              {this.customerInfo()}
            </div>
            <hr className="hr-300" align="left" />
            {showMarkPaid && (
              <MarkPaid
                order={layaway}
                isLayaway={true}
                callback={this.updateDatePaid}
              />
            )}
            <PrintOrder
              order={this.state.layaway.commonOrderInfo}
              isLayaway={true}
              sendPdfEnabled={sendPdfEnabled}
            />
            {this.paymentHistory()}
            {this.addPaymentSection()}
            <hr className="hr-300" align="left" />
            {this.shippingUpdator()}
            <font color="red">{this.state.errorMessage}</font>
          </div>
          {this.detailSect()}
          <p />
          {this.showActivities()}
          <p />
          {this.showInvoiceLinks()}
          {this.showNotifications()}
          <OrderNotes
            orderNumber={layaway.orderNumber}
            noteList={layaway.noteList}
            isLayaway={true}
            editDisabled={closed}
          />
          <hr className="hr-300" align="left" />
          <p />
          {this.showLayawayStatus()}
          <p />
          <div>&nbsp;</div>
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LayawayDetail);
