import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
  getIconBaseUrl,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditDeliveryFee extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryFeeSetting: null,
      isLoading: true,
      updateBtnDisabled: true,
      newCity: null,
      newFee: 0,
      newMinAmount: 0,
      otherCities: null,
      isLoadingOC: true,
      mobileMode: false,
      hrWidth: 400,
      newMin: null,
      newMax: null,
      newMinAmount: null,
      newDistanceFee: null,
      newStrategy: 1,
      distanceErrMsg: null,
      regionExpanded: [false, true, false],
      errorMessage: null,
      editAllowed: checkUpdate("Fee"),
      viewAllowed: checkView("Fee"),
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.addNewDeliveryFee = this.addNewDeliveryFee.bind(this);
    this.updateDeliveryFees = this.updateDeliveryFees.bind(this);
    this.processDataResponse = this.processDataResponse.bind(this);
    this.cityClicked = this.cityClicked.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.addNewDistanceFee = this.addNewDistanceFee.bind(this);
    this.deleteDistanceFee = this.deleteDistanceFee.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData("DeliveryFees");
    this.fetchData("OtherCities");
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    let hrWidth = getGroupWrapperWidth() - 20;
    if (hrWidth > 500) hrWidth = 500;

    this.setState({ mobileMode: isMobileMode(), hrWidth });
    setTopContainerWrapperSettings();
  }
  processDataResponse(response, type) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (type === "DeliveryFees") {
        this.setState({
          deliveryFeeSetting: response.data,
          isLoading: false,
        });
      } else {
        const objList = response.objList;
        this.setState({
          otherCities: objList,
          isLoadingOC: false,
        });
      }
    }
  }

  fetchData(type) {
    const url = apiBaseUrl() + "Get" + type;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processDataResponse(res.data, type);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateDeliveryFees() {
    const url = apiBaseUrl() + "UpdateDeliveryFees";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.deliveryFeeSetting,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleDelete(event) {
    const city = event.target.name;
    const cityFeeList = this.state.deliveryFeeSetting.cityFeeList.filter(
      (df) => df.city !== city
    );
    const deliveryFeeSetting = {
      ...this.state.deliveryFeeSetting,
      cityFeeList,
      cityFeeUpdated: true,
    };
    this.setState({ deliveryFeeSetting, updateBtnDisabled: false });
  }

  handleChange(event) {
    let name = event.target.name;

    if (name.startsWith("SETTING_")) {
      name = name.substring(8);
      const deliveryFeeSetting = {
        ...this.state.deliveryFeeSetting,
        [name]: event.target.value,
      };
      this.setState({ deliveryFeeSetting, updateBtnDisabled: false });
    } else {
      const found = this.state.deliveryFeeSetting.cityFeeList.find(
        (df) => df.city === name
      );
      if (found) {
        found.fee = event.target.value;

        const deliveryFeeSetting = {
          ...this.state.deliveryFeeSetting,
          cityFeeUpdated: true,
        };
        this.setState({ deliveryFeeSetting, updateBtnDisabled: false });
      }
    }
  }
  handleClick(region) {
    const regionExpanded = this.state.regionExpanded.map((flag, idx) => {
      return idx === region ? !flag : flag;
    });
    this.setState({ regionExpanded });
  }
  handleAddChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      distanceErrMsg: null,
    });
  }
  addNewDistanceFee() {
    let distanceErrMsg = null;
    if (this.state.newMin && this.state.newMax && this.state.newDistanceFee) {
      let min = -1;
      let max = 0;
      let fee = 0;
      let strategy = 1;
      try {
        min = parseInt(this.state.newMin);
        max = parseInt(this.state.newMax);
        fee = parseInt(this.state.newDistanceFee);
        strategy = parseInt(this.state.newStrategy);
      } catch (error) {}

      if (min >= 0 && max > 0 && fee > 0) {
        let distanceFeeList = this.state.deliveryFeeSetting.distanceFeeList;
        let overlap = false;

        if (distanceFeeList && distanceFeeList.length > 0) {
          const found = distanceFeeList.find(
            (df) =>
              (df.minDistance <= min && df.maxDistance > min) ||
              (df.minDistance <= max && df.maxDistance > max)
          );
          if (found) overlap = true;
        } else distanceFeeList = [];

        if (overlap) {
          distanceErrMsg = "Overlapped";
        } else {
          distanceFeeList.push({
            id: 0,
            minDistance: min,
            maxDistance: max,
            fee,
            strategy,
            minAmount: this.state.newMinAmount
              ? parseInt(this.state.newMinAmount)
              : 0,
          });

          const deliveryFeeSetting = {
            ...this.state.deliveryFeeSetting,
            distanceFeeList,
            distanceFeeUpdated: true,
          };
          this.setState({
            deliveryFeeSetting,
            updateBtnDisabled: false,
            newMin: "",
            newMax: "",
            newMinAmount: "",
            newDistanceFee: "",
            newStrategy: 1,
            distanceErrMsg: null,
          });
          return;
        }
      } else {
        if (min < 0) distanceErrMsg = "Invalid minimum distance";
        else if (max <= 0) distanceErrMsg = "Invalid maximum distance";
        else distanceErrMsg = "Invalid delivery fee";
      }
    } else {
      if (!this.state.newMin) distanceErrMsg = "Minimum distance is required";
      else if (!this.state.newMax)
        distanceErrMsg = "Maximum distance is required";
      else distanceErrMsg = "Delivery fee is required";
    }
    this.setState({ distanceErrMsg });
  }
  deleteDistanceFee(tbdIdx) {
    let distanceFeeList = this.state.deliveryFeeSetting.distanceFeeList.filter(
      (df, idx) => idx !== tbdIdx
    );
    if (distanceFeeList.length === 0) distanceFeeList = null;

    const deliveryFeeSetting = {
      ...this.state.deliveryFeeSetting,
      distanceFeeList,
      distanceFeeUpdated: true,
    };
    this.setState({ deliveryFeeSetting, updateBtnDisabled: false });
  }
  getStrategyStr(strategy) {
    let str = "C";
    if (
      this.state.deliveryFeeSetting &&
      this.state.deliveryFeeSetting.strategyList
    ) {
      const found = this.state.deliveryFeeSetting.strategyList.find(
        (st) => st.value === strategy
      );
      if (found) str = found.name.substring(0, 1);
    }
    return str;
  }
  showDistanceFeeList() {
    const list = this.state.deliveryFeeSetting.distanceFeeList;
    const miles = this.state.mobileMode ? "" : "miles";
    const icon = this.state.regionExpanded[0] ? "minus.jpg" : "plus.jpg";
    return (
      <div>
        <img
          src={getIconBaseUrl() + icon}
          width="16"
          onClick={() => this.handleClick(0)}
          alt="PM"
        ></img>
        &nbsp;
        <b>Distance-based Delivery Fees</b>
        <br />
        {this.state.regionExpanded[0] && (
          <React.Fragment>
            <div className="top-10">
              <div className="fourcol-wrapper2">
                {list &&
                  list.map((df, idx) => {
                    const strategy = this.getStrategyStr(df.strategy);
                    const minStr =
                      df.minAmount > 0 ? " (Min $" + df.minAmount + ")" : "";

                    return (
                      <React.Fragment>
                        <div className="left-10">
                          <b>{idx + 1}.</b>
                        </div>
                        <div className="left-10">
                          ({strategy}) {df.minDistance} {miles} -{" "}
                          {df.maxDistance} {miles}
                          {minStr}:
                        </div>
                        <div className="left-10">Fee ${df.fee}</div>
                        <div className="left-10">
                          <button
                            className="small-btn"
                            name="deleteDf"
                            onClick={() => this.deleteDistanceFee(idx)}
                          >
                            Delete
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
            <div className="left-10">
              <p />
              <input
                type="text"
                size="6"
                name="newMin"
                placeholder="min"
                value={this.state.newMin}
                onChange={this.handleAddChange}
              ></input>
              -
              <input
                type="text"
                size="6"
                name="newMax"
                placeholder="max"
                value={this.state.newMax}
                onChange={this.handleAddChange}
              ></input>
              &nbsp;$
              <input
                type="text"
                size="6"
                name="newMinAmount"
                placeholder="min amount"
                value={this.state.newMinAmount}
                onChange={this.handleAddChange}
              ></input>
              &nbsp;Fee:{" $"}
              <input
                type="text"
                size="6"
                name="newDistanceFee"
                placeholder="fee"
                value={this.state.newDistanceFee}
                onChange={this.handleAddChange}
              ></input>
              &nbsp;
              <select
                name="newStrategy"
                value={this.state.newStrategy}
                onChange={this.handleAddChange}
              >
                {this.state.deliveryFeeSetting.strategyList.map((st, idx) => {
                  return (
                    <option value={st.value} key={idx}>
                      {st.name}
                    </option>
                  );
                })}
              </select>
              &nbsp;
              <button
                className="small-btn"
                name="AddDF"
                onClick={this.addNewDistanceFee}
              >
                Add
              </button>
            </div>
            {this.state.distanceErrMsg && (
              <div>
                <font color="red">{this.state.distanceErrMsg}</font>
              </div>
            )}
          </React.Fragment>
        )}
        <p />
      </div>
    );
  }
  showFeeList() {
    if (this.state.isLoading === false) {
      const editAllowed = this.state.editAllowed;
      const clsName = this.state.mobileMode
        ? "twocol-wrapper"
        : "fourcol-wrapper-g10";
      const icon = this.state.regionExpanded[1] ? "minus.jpg" : "plus.jpg";
      return (
        <div className="generic-wrapper">
          <div>
            <img
              src={getIconBaseUrl() + icon}
              width="16"
              onClick={() => this.handleClick(1)}
              alt="PM"
            ></img>
            &nbsp; <b>Delivery Cities</b>
          </div>
          {this.state.regionExpanded[1] && (
            <div className={clsName}>
              {this.state.deliveryFeeSetting.cityFeeList.map((df, idx) => {
                const minStr =
                  df.minAmount && df.minAmount > 0
                    ? " ($" + df.minAmount + ")"
                    : " ";
                const clsName2 =
                  !this.state.mobileMode && idx % 2 === 1 ? "left-20" : "none";
                return (
                  <React.Fragment key={df.city}>
                    <div className={clsName2}>
                      {df.city}
                      <font color="red">{minStr}</font>
                    </div>
                    <div className="flex-wrapper">
                      <div>
                        <input
                          type="text"
                          inputmode="decimal"
                          size="6"
                          name={df.city}
                          value={df.fee}
                          onChange={this.handleChange}
                          disabled={!editAllowed}
                        ></input>
                      </div>
                      {editAllowed && (
                        <div className="left-5">
                          <button name={df.city} onClick={this.handleDelete}>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      );
    }
  }

  addNewDeliveryFee(event) {
    event.preventDefault();

    if (!this.state.newCity) {
      this.setState({
        errorMessage: "City is required!",
      });
      return;
    }

    const fee = parseInt(this.state.newFee);
    const minAmount = this.state.newMinAmount
      ? parseInt(this.state.newMinAmount)
      : 0;
    const city = this.state.newCity.toUpperCase();
    const found = this.state.deliveryFeeSetting.cityFeeList.find(
      (df) => df.city === city
    );

    if (found) {
      this.setState({
        errorMessage: "City " + this.state.newCity + " already exists!",
      });
    } else if (fee < 40) {
      this.setState({
        errorMessage: "Delivery fee is too low!",
      });
    } else {
      const newDf = { city, fee, minAmount };
      let cityFeeList = this.state.deliveryFeeSetting.cityFeeList;

      cityFeeList.push(newDf);

      const deliveryFeeSetting = {
        ...this.state.deliveryFeeSetting,
        cityFeeList,
        cityFeeUpdated: true,
      };
      this.setState({
        deliveryFeeSetting,
        newCity: "",
        newFee: 0,
        newMinAmount: 0,
        errorMessage: null,
        updateBtnDisabled: false,
      });
    }
  }

  showAddSection() {
    if (this.state.regionExpanded[1]) {
      const editAllowed = this.state.editAllowed;
      const newCity = this.state.newCity ? this.state.newCity : "";
      if (editAllowed) {
        const label = this.state.mobileMode ? "Fee" : "Delivery Fee";
        const clsName = this.state.mobileMode ? "none" : "flex-wrapper";
        return (
          <div className={clsName}>
            <div className="generic-flex">
              <div className="right-10">City: </div>
              <div className="right-20">
                <input
                  type="text"
                  name="newCity"
                  value={newCity}
                  onChange={this.handleAddChange}
                ></input>
              </div>
              <div className="right-20">
                $
                <input
                  type="text"
                  inputmode="decimal"
                  size="6"
                  name="newMinAmount"
                  placeholder="min amount"
                  value={this.state.newMinAmount}
                  onChange={this.handleAddChange}
                ></input>
              </div>
            </div>
            <div className="generic-flex">
              <div className="right-10">{label}:</div>
              <div className="right-20">
                <input
                  type="text"
                  inputmode="decimal"
                  size="6"
                  name="newFee"
                  value={this.state.newFee}
                  onChange={this.handleAddChange}
                ></input>
              </div>
              <div>
                <button
                  name="add"
                  className="btn-style"
                  onClick={this.addNewDeliveryFee}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  cityClicked(city) {
    this.setState({ newCity: city });
  }

  showOtherCities() {
    if (this.state.otherCities && this.state.otherCities.length > 0) {
      const editAllowed = this.state.editAllowed;
      const icon = this.state.regionExpanded[2] ? "minus.jpg" : "plus.jpg";
      return (
        <div className="generic-wrapper">
          <p />
          <hr width={this.state.hrWidth} align="left"></hr>
          <div>
            <img
              src={getIconBaseUrl() + icon}
              width="16"
              onClick={() => this.handleClick(2)}
              alt="PM"
            ></img>
            &nbsp; <b>Other Cities in Metro Atlanta Area</b>
          </div>
          <p />
          {this.state.regionExpanded[2] &&
            this.state.otherCities.map((cbc, idx) => {
              const clsName = this.state.mobileMode
                ? "twocol-wrapper"
                : "tencol-wrapper";
              return (
                <React.Fragment key={idx}>
                  <font color="red">{cbc.county}</font>
                  <br />
                  <div className={clsName}>
                    {cbc.cityList.map((cf) => {
                      if (editAllowed) {
                        return (
                          <div key={cf.city}>
                            <a
                              href="#NONE"
                              onClick={() => this.cityClicked(cf.city)}
                            >
                              {cf.city}
                            </a>
                          </div>
                        );
                      } else {
                        return <div>{cf.city}</div>;
                      }
                    })}
                  </div>
                  <p />
                </React.Fragment>
              );
            })}
        </div>
      );
    }
  }
  deliveryFeeSetting() {
    return (
      <div>
        <div>
          Default Delivery Fee: &nbsp;
          <input
            type="text"
            size="6"
            name="SETTING_defaultDeliveryFee"
            value={this.state.deliveryFeeSetting.defaultDeliveryFee}
            onChange={this.handleChange}
          ></input>
        </div>
        <div>
          Max to Correct Address: &nbsp;
          <input
            type="text"
            size="6"
            name="SETTING_maxDistanceToCorrectAddress"
            value={this.state.deliveryFeeSetting.maxDistanceToCorrectAddress}
            onChange={this.handleChange}
          ></input>{" "}
          miles
        </div>
        <p />
      </div>
    );
  }
  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    const btnClsName = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Delivery Fees</font>
          <p />
          You can change fees, delete cities, or add new cities. You must click
          the "Update" button in order to save the changes to the server.
          <p />
          <button
            className={btnClsName}
            name="update"
            onClick={this.updateDeliveryFees}
            disabled={this.state.updateBtnDisabled}
          >
            Update
          </button>
        </div>
        {this.deliveryFeeSetting()}
        {this.showDistanceFeeList()}
        {this.showFeeList()}
        <p />
        {this.showAddSection()}
        <p />
        {this.showOtherCities()}
        <p />
        <font color="red">{this.state.errorMessage}</font>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDeliveryFee);
