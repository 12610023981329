import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditDatSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datSetting: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetDatSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({ datSetting: response.data, isLoading: false });
  }
  processUpdateResponse(response) {
    console.log(response);
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    console.log("Statue=" + response.status);
    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("CB_")) {
      name = name.substring(3);

      let datSetting = {
        ...this.state.datSetting,
        [name]: event.target.checked,
      };

      if (
        (name === "generateOrder" || name === "generateInvoice") &&
        !event.target.checked
      ) {
        if (name === "generateOrder") {
          datSetting = {
            ...datSetting,
            ftpOrder: false,
          };
        } else {
          datSetting = {
            ...datSetting,
            ftpInvoice: false,
          };
        }
      }

      this.setState({
        datSetting,
        updateBtnDisabled: false,
      });
    } else if (name.startsWith("FLD_")) {
      const theIdx = parseInt(name.substring(4));
      const fieldList = this.state.datSetting.fieldList.map((fld, idx) => {
        if (idx === theIdx) {
          const nf = {
            ...fld,
            fieldName: event.target.value,
          };
          return nf;
        } else return fld;
      });
      const datSetting = {
        ...this.state.datSetting,
        fieldList,
      };
      this.setState({ datSetting, updateBtnDisabled: false });
    } else {
      const datSetting = {
        ...this.state.datSetting,
        [name]: event.target.value,
      };
      this.setState({
        datSetting,
        updateBtnDisabled: false,
      });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateDatSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.datSetting,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  theForm() {
    const settings = this.state.datSetting;
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";
    const timeType = this.state.mobileMode ? "time" : "text";
    const spVal = this.state.mobileMode
      ? "Replace Special Char in DAT"
      : "Replace Special Characters in DAT";
    const fldLen = this.state.mobileMode ? 35 : 40;
    const colCnt = this.state.mobileMode ? 42 : 80;
    const rowCnt = this.state.mobileMode ? 12 : 7;
    const cNotes = this.state.mobileMode ? "C Notes" : "Common Notes";
    const intOrder = this.state.mobileMode ? "Web Orders" : "Internet Orders";
    const fldName = this.state.mobileMode ? "Fld Names" : "Field Names";
    const tmpl = this.state.mobileMode ? "Template" : "Log Template";
    return (
      <div className="generic-wrapper">
        <font size="5">DAT Settings</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">FTP Retry:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_retryFtpFailed"
              defaultChecked={settings.retryFtpFailed}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Retry Failed DAT
          </div>
          <div className="form-item-label">Assembly Fee:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_addAssemblyFeeToDat"
              defaultChecked={settings.addAssemblyFeeToDat}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show Assembly Fee in DAT
          </div>
          <div className="form-item-label">Delivery Info:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_addDeliveryInstrToDat"
              defaultChecked={settings.addDeliveryInstrToDat}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show Delivery Instru in DAT
            <br />
            <input
              type="checkbox"
              name="CB_showDeliveryInfoInNotes"
              defaultChecked={settings.showDeliveryInfoInNotes}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Delivery Info in Notes Field
          </div>
          <div className="form-item-label">Character:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_replaceSpecialCharInDat"
              defaultChecked={settings.replaceSpecialCharInDat}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
            {" " + spVal}
          </div>
          {checkSecurity("RootFeatures") && (
            <React.Fragment>
              <div className="form-item-label">Ship To:</div>
              <div className="form-item-value">
                <input
                  type="checkbox"
                  name="CB_dupOrdAddress"
                  defaultChecked={settings.dupOrdAddress}
                  onChange={this.handleChange}
                  disabled={!editAllowed}
                ></input>
                Duplicate Address for Order DAT
                <br />
                <input
                  type="checkbox"
                  name="CB_dupInvAddress"
                  defaultChecked={settings.dupInvAddress}
                  onChange={this.handleChange}
                  disabled={!editAllowed}
                ></input>
                Duplicate Address for Invoice DAT
                <br />
              </div>
            </React.Fragment>
          )}
          <div className="form-item-label">
            <b>{fldName}</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          {settings.fieldList.map((fld, idx) => {
            const name = "FLD_" + idx;
            let displayName = fld.displayName;

            if (this.state.mobileMode) {
              if (displayName === "Invoice Deposit")
                displayName = "Inv Deposit";
              else if (displayName === "Layaway Deposit")
                displayName = "LA Deposit";
              else if (displayName === "Internet Layaway Deposit")
                displayName = "IntLa Deposit";
              else if (displayName.length > 15)
                displayName = displayName.substring(0, 15);
            }
            return (
              <React.Fragment key={idx}>
                <div className="form-item-label">{displayName}:</div>
                <div className="form-item-value">
                  <input
                    type="text"
                    size={fldLen}
                    name={name}
                    value={fld.fieldName}
                    onChange={this.handleChange}
                    disabled={!editAllowed}
                  ></input>
                </div>
              </React.Fragment>
            );
          })}
          <div className="form-item-label">
            <b>Invoices</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Settings:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_markInvPrinted"
              defaultChecked={settings.markInvPrinted}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Mark Invoices As Printed
            <br />
            <input
              type="checkbox"
              name="CB_addInvNotes"
              defaultChecked={settings.addInvNotes}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Add Foot Notes
            <br />
          </div>
          <div className="form-item-label">{cNotes}:</div>
          <div className="form-item-value">
            <textarea
              cols={colCnt}
              rows={rowCnt}
              name="invCommonNotes"
              value={settings.invCommonNotes}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.addInvNotes}
            ></textarea>
          </div>
          <div className="form-item-label">Foot Notes:</div>
          <div className="form-item-value">
            <textarea
              cols={colCnt}
              rows={5}
              name="invCommonFootNotes"
              value={settings.invCommonFootNotes}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.addInvNotes}
            ></textarea>
          </div>
          <div className="form-item-label">{tmpl}:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="40"
              name="invLogTmpl"
              value={settings.invLogTmpl}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">
            <b>{intOrder}</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Cutoff Time:</div>
          <div className="form-item-value">
            <input
              type={timeType}
              size="8"
              name="samedayFtpCutoff"
              value={settings.samedayFtpCutoff}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Order:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_generateOrder"
              defaultChecked={settings.generateOrder}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Generate Order DAT
            <br />
            <input
              type="checkbox"
              name="CB_ftpOrder"
              defaultChecked={settings.ftpOrder}
              checked={settings.ftpOrder}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.generateOrder}
            ></input>{" "}
            FTP Order DAT
            <br />
            Prefix:{" "}
            <input
              type="text"
              size="8"
              name="orderPrefix"
              value={settings.orderPrefix}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.generateOrder}
            ></input>
            <br />
            Start:{" "}
            <input
              type="text"
              size="8"
              name="orderStartNum"
              value={settings.orderStartNum}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.generateOrder}
            ></input>
          </div>
          <div className="form-item-label">Invoice:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_generateInvoice"
              defaultChecked={settings.generateInvoice}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Generate Invoice DAT
            <br />
            <input
              type="checkbox"
              name="CB_ftpInvoice"
              defaultChecked={settings.ftpInvoice}
              checked={settings.ftpInvoice}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.generateInvoice}
            ></input>{" "}
            FTP Invoice DAT
            <br />
            <input
              type="checkbox"
              name="CB_useOrderNumForInvoice"
              defaultChecked={settings.useOrderNumForInvoice}
              checked={settings.useOrderNumForInvoice}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.generateInvoice}
            ></input>{" "}
            Use Order Number
            <br />
            Prefix:{" "}
            <input
              type="text"
              size="8"
              name="invoicePrefix"
              value={settings.invoicePrefix}
              onChange={this.handleChange}
              disabled={
                !editAllowed ||
                settings.useOrderNumForInvoice ||
                !settings.generateInvoice
              }
            ></input>
            <br />
            Start:{" "}
            <input
              type="text"
              size="8"
              name="invoiceStartNum"
              value={settings.invoiceStartNum}
              onChange={this.handleChange}
              disabled={
                !editAllowed ||
                settings.useOrderNumForInvoice ||
                !settings.generateInvoice
              }
            ></input>
          </div>
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
        <hr className="hr-300" align="left" />
        <div className="form-wrapper">
          <div>Current Time:</div>
          <div>{settings.currentTime}</div>
          <div>Adjusted Time: &nbsp;</div>
          <div>{settings.adjustedTime}</div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDatSetting);
