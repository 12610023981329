import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import {
  FormatCurrency,
  apiBaseUrl,
  setTopContainerWrapperSettings,
  isUserLoggedIn,
  isMobileMode,
} from "../Util";
import { checkUpdate, checkView } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import VariantDisplay from "./VariantDisplay";
import Working from "../Working";
import "../../App.css";
import "../../form.css";
import "../../generic.css";
import "../../product.css";
import "../../prodPreview.css";

class EditVariantDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      vdMode: 0,
      skuIdx: 0,
      mobileMode: false,
      isLoading: true,
      updBtnDisabled: true,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };

    this.fetchData = this.fetchData.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.selectVariant = this.selectVariant.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.adjustScreenSize = this.adjustScreenSize.bind(this);
    this.updateSetting = this.updateSetting.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({
        product: response.data,
        vdMode: response.data.variantDisplay,
        isLoading: false,
      });
    }
  }
  fetchData() {
    const url = apiBaseUrl() + "GetProduct";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }

    this.adjustScreenSize();
    window.addEventListener("resize", this.adjustScreenSize);
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchData();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustScreenSize);
  }

  adjustScreenSize() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const url = "/product/" + this.state.product.id;
      this.props.history.push(url);
    }
  }
  updateSetting() {
    const url = apiBaseUrl() + "UpdateVD";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      productId: this.state.product.id,
      vdMode: this.state.vdMode,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showProdInfo() {
    const prod = this.state.product;
    return (
      <div>
        {prod.name}
        <br />
        <ul>
          {prod.pvList.map((pv) => {
            return (
              <li>
                {pv.sku} <FormatCurrency amount={pv.finalPrice} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  selectVariant(idx) {
    this.setState({ skuIdx: idx });
  }
  handleChange(event) {
    const vdMode = parseInt(event.target.value);
    const updBtnDisabled = vdMode === this.state.product.variantDisplay;
    this.setState({ vdMode, updBtnDisabled });
  }
  cancel() {
    const url = "/editProduct/" + this.state.product.id;
    this.props.history.push(url);
  }
  showVariants() {
    const prod = this.state.product;
    const btnStyle = this.state.updBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const pv = prod.pvList[this.state.skuIdx];
    return (
      <div>
        <hr className="hr-style" align="left" />
        Select a "Display Style" from the dropdown list and then click the
        "Update" button to save the change.
        <p />
        Display Style:&nbsp;&nbsp;
        <select
          name="variantDisplay"
          value={this.state.vdMode}
          onChange={this.handleChange}
        >
          {prod.vdList.map((vd) => {
            return <option value={vd.id}>{vd.name}</option>;
          })}
        </select>
        <p />
        <div>
          <button
            className={btnStyle}
            name="update"
            onClick={this.updateSetting}
            disabled={!this.state.editAllowed || this.state.updBtnDisabled}
          >
            Update
          </button>
          &nbsp;&nbsp;&nbsp;
          <button className="btn-style" name="cancel" onClick={this.cancel}>
            Cancel
          </button>
        </div>
        <br />
        <hr className="hr-style" align="left" />
        <b>
          <font color="darkgreen">Preview:</font>
        </b>
        <br />
        {this.state.vdMode > 1 && (
          <React.Fragment>
            <p />
            {pv.sku} ({pv.variantValue})
            <br />
            <b>Price: </b>
            <font color="red">
              <FormatCurrency amount={pv.finalPrice} />
            </font>
            &nbsp;&nbsp;
            {!pv.forSale && (
              <label>
                (<font color="red">Sold Out</font>)
              </label>
            )}
          </React.Fragment>
        )}
        <p />
        <VariantDisplay
          product={prod}
          vdMode={this.state.vdMode}
          callbackFunc={this.selectVariant}
        />
      </div>
    );
  }
  render() {
    if (this.state.product === null) {
      return <Working size={80} />;
    }

    return (
      <div className="top-container">
        <div className="top-wrapper">
          <div>
            <font size="5" color="darkgreen">
              Variant Display Style
            </font>
          </div>
          <p />
          {this.showProdInfo()}
          {this.showVariants()}
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditVariantDisplay);
