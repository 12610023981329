import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toAmount,
  getIconBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class ManagePayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyList: [],
      expanded: [],
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      editAllowed: checkUpdate("Payment"),
      viewAllowed: checkView("Payment"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchPayments();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    let expanded = [];
    if (response.objList && response.objList.length > 0) {
      let i;
      for (i = 0; i < response.objList.length; i++) {
        if (i === 0) expanded.push(true);
        else expanded.push(false);
      }
    }
    this.setState({
      monthlyList: response.objList,
      expanded,
      isLoading: false,
    });
  }
  fetchPayments() {
    const url = apiBaseUrl() + "GetPayments";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getPaymentIcon(status) {
    let url = getIconBaseUrl();
    if (status == "Processed") {
      url += "pmtAccepted.png";
    } else if (status === "Rejected") url += "pmtRejected.jpg";
    else if (status === "Cancelled") url += "cancelled.jpg";
    else url += "creditCard.png";
    return url;
  }
  handleClick(idx) {
    let expanded = [];
    let i;
    for (i = 0; i < this.state.expanded.length; i++) {
      if (i === idx) expanded.push(!this.state.expanded[i]);
      else expanded.push(this.state.expanded[i]);
    }
    this.setState({ expanded });
  }
  oneHtmlRow(payment) {
    const pUrl = "/paymentDetail/" + payment.id;
    const oUrl = "/orderDetail/" + payment.orderNumber;
    const timestamp = this.state.mobileMode
      ? payment.mobileDateStr
      : payment.dateStr;
    const c1 = payment.amount < 0 ? '<font color="red">' : "";
    const c2 = payment.amount < 0 ? "</font>" : "";
    let imgUrl = getIconBaseUrl();
    if (payment.amount < 0) imgUrl += "refunded.jpg";
    else {
      if (payment.paymentName === "Delivery Fee") imgUrl += "deliveryFee.jpg";
      else imgUrl += "securityDeposit.jpg";
    }
    let name = payment.name;
    if (this.state.mobileMode && name.length > 12) {
      const pos = name.indexOf(" ");
      if (pos > 0) name = name.substring(pos + 1);
      else name = name.substring(0, 12);
    }
    let html =
      '<tr><td><img src="' + imgUrl + '" alt="Type" width="24"></img></td>';
    if (this.state.mobileMode) {
      html += '<td><a href="' + pUrl + '">' + timestamp + "</a></td>";
    } else {
      html += "<td>" + timestamp + "</td>";
    }
    if (!this.state.mobileMode) {
      html += '<td><a href="' + pUrl + '">' + payment.paymentName + "</a></td>";
      html += '<td><a href="' + oUrl + '">' + payment.orderNumber + "</a></td>";
    }
    html += "<td>" + name + "</td>";
    html += "<td>" + c1 + toAmount(payment.amount) + c2 + "</td>";
    html += "</tr>";
    return html;
  }
  showTable(monthPmts) {
    let i;
    const pmtList = monthPmts.list;

    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html += '<table><tr><th style="background-color: #D0D0D0">&nbsp;</th>';
    html += '<th style="background-color: #D0D0D0" align="left">Time</th>';
    if (!this.state.mobileMode) {
      html += '<th style="background-color: #D0D0D0" align="left">Type</th>';
      html += '<th style="background-color: #D0D0D0" align="left">Order #</th>';
    }
    html += '<th style="background-color: #D0D0D0" align="left">Name</th>';
    html += '<th style="background-color: #D0D0D0">Amount</th>';
    html += "</tr>";

    for (i = 0; i < pmtList.length; i++) {
      html += this.oneHtmlRow(pmtList[i]);
    }
    html += "</table>";

    return (
      <div
        className="left-8"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    );
  }
  showHeader(monthPmts) {
    return (
      <div>
        <b>
          &nbsp;{monthPmts.name} {monthPmts.list.length}
        </b>
      </div>
    );
  }
  showPayments() {
    if (!this.state.monthlyList || this.state.monthlyList.length === 0)
      return <div>No payments found</div>;

    return (
      <React.Fragment>
        {this.state.monthlyList.map((monthPmts, idx) => {
          const icon = this.state.expanded[idx] ? "minus.jpg" : "plus.jpg";
          return (
            <div className="bottom-15" key={idx}>
              <div className="generic-flex">
                <div>
                  <img
                    src={getIconBaseUrl() + icon}
                    width="20"
                    onClick={() => this.handleClick(idx)}
                  ></img>
                </div>
                <div>
                  {this.state.expanded[idx]
                    ? this.showTable(monthPmts)
                    : this.showHeader(monthPmts)}
                </div>
                <p />
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Payments</font>
          <p />
          {this.showPayments()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagePayments);
