import React from "react";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import "../../generic.css";

class AddrUpdateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: this.props.address,
      emailRequired: this.props.emailRequired,
      addrRequired: this.props.addrRequired,
      updBtnDisabled: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.address !== prevProps.address) {
      this.setState({ address: this.props.address });
    }
  }
  handleChange(event) {
    const address = {
      ...this.state.address,
      [event.target.name]: event.target.value,
    };
    this.setState({
      address,
      updBtnDisabled: false,
    });
  }
  updateAddress(doUpdate) {
    this.props.callback(this.state.address, doUpdate);
  }
  addressForm() {
    const txtLen = 30;
    const addr = this.state.address;
    const clsName = this.state.updBtnDisabled
      ? "disabled-small-btn"
      : "small-btn";
    const city = addr.city == "Unknown" ? "" : addr.city;

    return (
      <ValidatorForm ref="form">
        <div>
          <div className="generic-flex">
            <div>
              <TextInput
                onChange={this.handleChange}
                name="firstName"
                size="15"
                value={addr.firstName}
                placeholder="First name"
                validators={["required"]}
                errorMessages={["First Name is required"]}
              />
            </div>
            <div className="left-10">
              <TextInput
                onChange={this.handleChange}
                name="lastName"
                size="18"
                value={addr.lastName}
                placeholder="Last name"
                validators={["required"]}
                errorMessages={["Last Name is required"]}
              />
            </div>
          </div>
          {this.state.addrRequired ? (
            <div className="generic-flex">
              <div>
                <TextInput
                  onChange={this.handleChange}
                  name="addressLine1"
                  size={txtLen}
                  value={addr.addressLine1}
                  placeholder="Address line 1"
                  validators={["required"]}
                  errorMessages={["Address line 1 is required"]}
                />
              </div>
              <div>
                <font color="red">*</font>
              </div>
            </div>
          ) : (
            <div>
              <input
                type="text"
                onChange={this.handleChange}
                name="addressLine1"
                size={txtLen}
                value={addr.addressLine1}
                placeholder="Address line 1"
              />
            </div>
          )}

          <div>
            <input
              type="text"
              onChange={this.handleChange}
              name="addressLine2"
              size="30"
              value={addr.addressLine2}
              placeholder="Address line 2"
            />
          </div>
          {this.state.addrRequired ? (
            <div className="generic-flex">
              <div>
                <TextInput
                  onChange={this.handleChange}
                  name="city"
                  size="20"
                  value={city}
                  placeholder="City"
                  validators={["required"]}
                  errorMessages={["City is required"]}
                />
              </div>
              <div>
                <font color="red">*</font>
              </div>
              <div className="left-5">
                <TextInput
                  onChange={this.handleChange}
                  name="state"
                  size="2"
                  value={addr.state}
                  placeholder="State"
                  validators={["required"]}
                  errorMessages={["State is required"]}
                />
              </div>
              <div>
                <font color="red">*</font>
              </div>
              <div className="left-5">
                <TextInput
                  onChange={this.handleChange}
                  name="zipCode"
                  size="9"
                  value={addr.zipCode}
                  placeholder="Zip Code"
                  validators={["required"]}
                  errorMessages={["Zip Code is required"]}
                />
              </div>
              <div>
                <font color="red">*</font>
              </div>
            </div>
          ) : (
            <div className="generic-flex">
              <div>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="city"
                  size="20"
                  value={city}
                  placeholder="City"
                />
              </div>
              <div className="left-5">
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="state"
                  size="2"
                  value={addr.state}
                  placeholder="State"
                />
              </div>
              <div className="left-5">
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="zipCode"
                  size="5"
                  value={addr.zipCode}
                  placeholder="Zip Code"
                />
              </div>
            </div>
          )}
          <div className="generic-flex">
            <div>
              <TextInput
                onChange={this.handleChange}
                name="phone"
                size="15"
                value={addr.phone}
                placeholder="Phone"
                validators={["required"]}
                errorMessages={["Phone is required"]}
              />
            </div>
            <div>
              <font color="red">*</font>
            </div>
          </div>
          {this.state.emailRequired ? (
            <div className="generic-flex">
              <div>
                <TextInput
                  onChange={this.handleChange}
                  name="email"
                  size={txtLen}
                  value={addr.email}
                  placeholder="Email"
                  validators={["required"]}
                  errorMessages={["Email is required"]}
                />
              </div>
              <div>
                <font color="red">*</font>
              </div>
            </div>
          ) : (
            <div>
              <input
                type="text"
                onChange={this.handleChange}
                name="email"
                size={txtLen}
                value={addr.email}
                placeholder="Email"
              />
            </div>
          )}
          <div className="generic-flex">
            <div>
              <button
                name="update"
                className={clsName}
                disabled={this.state.updBtnDisabled}
                onClick={() => this.updateAddress(true)}
              >
                Update
              </button>
            </div>
            <div className="left-20">
              <button
                name="cancel"
                className="small-btn"
                onClick={() => this.updateAddress(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ValidatorForm>
    );
  }
  render() {
    return this.addressForm();
  }
}
export default AddrUpdateForm;
