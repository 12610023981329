import React from "react";
import { getIconBaseUrl } from "../Util";
import "../../App.css";
import "../../generic.css";
import "../../layaway.css";

class OrderActivityLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activityList: this.props.activityList,
      isLayaway: this.props.isLayaway,
      mobileMode: this.props.mobileMode,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.activityList !== prevProps.activityList) {
      this.setState({
        activityList: this.props.activityList,
        isLayaway: this.props.isLayaway,
        mobileMode: this.props.mobileMode,
      });
    }
  }
  getUserId(userId) {
    let uid = userId;
    if (!userId || (userId.length > 15 && userId.indexOf("-")) > 0) {
      uid = "Internet";
    }
    if (this.state.mobileMode && uid.length > 7) uid = uid.substring(0, 7);
    return uid;
  }
  showActivities() {
    const activityList = this.state.activityList;
    if (activityList && activityList.length > 0) {
      const title = this.state.isLayaway
        ? "Layaway Activities"
        : "Order Activities";
      return (
        <div>
          <hr className="hr-300" align="left" />
          <div className="la-sect-header">
            <div>
              <img src={getIconBaseUrl() + "actlog.jpg"} height="22"></img>
            </div>
            <div className="la-sect-title">
              <font size="4">{title}</font>
            </div>
          </div>
          <div className="la-table-c4">
            <div className="la-act-hd">Date</div>
            <div className="la-act-hd">Activity</div>
            <div className="la-act-hd">Note</div>
            <div className="la-act-hd">Clerk</div>
            {activityList.map((act, idx) => {
              const clsName = idx % 2 === 0 ? "la-item-ev" : "la-item-od";
              let note;
              if (act.note && act.note.length > 30) {
                note = (
                  <textarea
                    disabled={true}
                    name="note"
                    cols={30}
                    rows={5}
                    value={act.note}
                  ></textarea>
                );
              } else {
                note = <label>{act.note}</label>;
              }
              const dtStr = this.state.mobileMode ? (
                <span>
                  {act.dateStr.substring(0, 10)}
                  <br />
                  {act.dateStr.substring(11)}
                </span>
              ) : (
                act.dateStr
              );
              return (
                <React.Fragment key={idx}>
                  <div className={clsName}>{dtStr}</div>
                  <div className={clsName}>{act.activity}</div>
                  <div className={clsName}>{note}</div>
                  <div className={clsName}>{this.getUserId(act.userId)}</div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    } else return "";
  }

  render() {
    return this.showActivities();
  }
}
export default OrderActivityLog;
