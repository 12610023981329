import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import {
  apiBaseUrl,
  fullProductImageUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
} from "../Util";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../form.css";
import "../../generic.css";

class AddSymLinkVariant extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      skuInfoList: null,
      sku: null,
      product: null,
      prodVar: null,
      isLoading: true,
      isLoadingProd: true,
      errorMessage: null,
      mobileMode: false,
      imageWidth: 400,
      addBtnDisabled: true,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };
    this.processResponse = this.processResponse.bind(this);
    this.processProdRsp = this.processProdRsp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchSkuInfoList = this.fetchSkuInfoList.bind(this);
    this.processAddResponse = this.processAddResponse.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.editAllowed) {
      this.props.history.push("/");
      return;
    }

    if (!this.props.match.params.id) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchSkuInfoList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    let imageWidth = getGroupWrapperWidth();
    if (imageWidth > 400) imageWidth = 400;

    this.setState({ mobileMode: isMobileMode(), imageWidth });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({ skuInfoList: response.objList, isLoading: false });
    }
  }

  processProdRsp(response, sku) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const product = response.data;
      let prodVar = null;

      if (product.pvList && product.pvList.length > 0) {
        prodVar = product.pvList.find((pv) => pv.sku === sku);
        if (!prodVar) prodVar = product.pvList[0];
      }

      this.setState({ product, prodVar, isLoadingProd: false });
    }
  }
  processAddResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        const url = "/product/" + this.props.match.params.id;
        this.props.history.push(url);
      } else {
        this.setState({ errorMessage: response.errorMessage });
      }
    }
  }
  fetchSkuInfoList() {
    const url = apiBaseUrl() + "GetSymLinkSkus";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get SKU list",
        });
      });
  }
  fetchProduct(sku) {
    const url = apiBaseUrl() + "GetProductBySKU";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: sku,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processProdRsp(res.data, sku);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get SKU list",
        });
      });
  }
  handleChange(event) {
    event.preventDefault();

    const sku = event.target.value;
    if (sku && sku.length > 0 && sku !== "0") {
      this.setState({ sku, addBtnDisabled: false });
      this.fetchProduct(sku);
    } else {
      this.setState({
        sku: null,
        addBtnDisabled: true,
        item: null,
        prodVar: null,
      });
    }
  }
  handleAdd(event) {
    const url = apiBaseUrl() + "AddSymLink";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      productId: this.props.match.params.id,
      sku: this.state.sku,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processAddResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to add Symbolic SKU",
        });
      });
  }
  theForm() {
    const btnStyle = this.state.addBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    return (
      <div className="generic-flex">
        <div>Select SKU: </div>
        <div className="left-10">
          <select name="sku" onChange={this.handleChange}>
            <option value="0"></option>
            {this.state.skuInfoList.map((info) => {
              const style = {
                backgroundImage:
                  "url('" + fullProductImageUrl(info.imageUrl) + "')",
                backgroundSize: "40px 30px",
              };
              return (
                <option value={info.sku} style={style} key={info.sku}>
                  {info.sku} ${info.price}
                </option>
              );
            })}
          </select>
        </div>
        <div className="left-10">
          <button className={btnStyle} name="add" onClick={this.handleAdd}>
            Add
          </button>
        </div>
      </div>
    );
  }

  productInfo() {
    if (!this.state.item) return "";

    const imgUrl =
      this.state.prodVar && this.state.prodVar.imageUrl
        ? this.state.prodVar.imageUrl
        : this.state.product.imageUrl;
    let title = this.state.product.name;
    if (this.state.product.pvList.length > 1) {
      title = title + " - " + this.state.prodVar.variantValue;
    }
    const clsName = this.state.mobileMode ? "none" : "generic-flex";
    return (
      <div className={clsName}>
        <div>
          <img
            src={fullProductImageUrl(imgUrl)}
            width={this.state.imageWidth}
            alt="Prod"
          ></img>
        </div>
        <div className="left-10">
          <b>{title}</b>
          <p />
          SKU: {this.state.sku}
          <br />
          Price: ${this.state.prodVar.salePrice}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const url = "/product/" + this.props.match.params.id;
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Add Existing SKU As Variant</font>
          <p />
          <div>
            Please select a SKU from the list below to add it as a variant for
            product:{" "}
            <a href={url} target="ProdPreview">
              <b>{this.props.match.params.id}</b>
            </a>
            <p />
          </div>
        </div>
        {this.state.errorMessage && (
          <div>
            <font color="red">{this.state.errorMessage}</font>
            <p />
          </div>
        )}
        {this.theForm()}
        <p />
        {this.productInfo()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    categoryId: state.search.categoryId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSymLinkVariant);
