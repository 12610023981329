import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import {
  apiBaseUrl,
  fullProductImageUrl,
  isUserLoggedIn,
  isMobileMode,
  getGroupWrapperWidth,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
} from "../Util";
import "../../App.css";
import "../../form.css";

class EditImageSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productId: 0,
      imageSetting: -1,
      thumbnailHWRatio: 0,
      useThumbnails: true,
      thumbnailsAvailable: false,
      product: null,
      mobileMode: false,
      isLoading: true,
      isLoadingProd: true,
      errorMessage: null,
      updateBtnDisabled: true,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };

    this.processResponse = this.processResponse.bind(this);
    this.processProdRsp = this.processProdRsp.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateSetting = this.updateSetting.bind(this);
    this.previewProduct = this.previewProduct.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchImageSetting();
    this.fetchProduct();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }
  fetchImageSetting() {
    const url = apiBaseUrl() + "GetImageSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage:
            "Failed to get image setting for product=" +
            this.props.match.params.id,
          isLoading: false,
        });
      });
  }

  fetchProduct() {
    if (this.props.match.params.id === "+") {
      this.setState({ isLoading: false });
    } else {
      const url = apiBaseUrl() + "GetProduct";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        id: this.props.match.params.id,
      };
      this.setState({ isLoading: true });
      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.processProdRsp(res.data);
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoadingProd: false,
            errorMessage: "Failed to get product info",
          });
        });
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      updateBtnDisabled: false,
    });
  }
  processProdRsp(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const product = response.data;
      this.setState({ product, isLoadingProd: false });
    }
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const setting = response.data;
      if (response.status && setting) {
        this.setState({
          productId: setting.productId,
          imageSetting: setting.imageSetting,
          thumbnailHWRatio: setting.thumbnailHWRatio,
          useThumbnails: setting.useThumbnails,
          thumbnailsAvailable: setting.thumbnailsAvailable,
          isLoading: false,
        });
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          isLoading: false,
        });
      }
    }
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        const url = "/product/" + this.state.productId;
        this.props.history.push(url);
      } else {
        this.setState({ errorMessage: "Failed to update image setting" });
      }
    }
  }

  updateSetting(event) {
    const url = apiBaseUrl() + "UpdateImageSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage:
            "Failed to update image setting for product=" +
            this.props.match.params.id,
        });
      });
  }

  previewProduct(event) {
    const url = "/product/" + this.props.match.params.id;
    this.props.history.push(url);
  }
  productInfo() {
    if (this.state.isLoadingProd)
      return (
        <div>
          <img src={getIconBaseUrl() + "working.gif"} width={80} />
        </div>
      );

    const prod = this.state.product;
    const pvList = prod.pvList;
    const iiList = this.state.product.imageInfoList;
    const info =
      iiList && iiList.length > 0
        ? "" + iiList[0].width + "x" + iiList[0].height
        : "";
    let width = this.state.mobileMode ? getGroupWrapperWidth() : 400;
    if (iiList && iiList.length > 0 && iiList[0].width < width) {
      width = iiList[0].width;
    }
    const clsName = this.state.mobileMode ? "none" : "generic-flex";
    const left10 = this.state.mobileMode ? "none" : "left-10";
    if (pvList.length < 2) {
      return (
        <div>
          <div className={clsName}>
            <div>
              <img
                src={fullProductImageUrl(prod.imageUrl)}
                width={width}
                alt="Home"
              ></img>
              <br />
              {info}
            </div>
            <div className={left10}>
              <b>{prod.name}</b>
              <p />
              SKU: {pvList[0].sku}
              <br />
              Price: ${pvList[0].salePrice}
              <br />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className={clsName}>
            <div>
              <img
                src={fullProductImageUrl(prod.imageUrl)}
                width={width}
                alt="IS"
              ></img>
              <br />
              {info}
            </div>
            <div className={left10}>
              <b>{prod.name}</b>
              <p />
              <div className="threecol-wrapper">
                {pvList.map((pv, idx) => {
                  const imgUrl = pv.imageUrl
                    ? fullProductImageUrl(pv.imageUrl)
                    : "/icons/NoPic.jpg";
                  let info2 = "";
                  if (pv.imageUrl && this.state.product.pvList) {
                    if (pv.imageInfoList && pv.imageInfoList[0].width > 0) {
                      info2 =
                        "(" +
                        pv.imageInfoList[0].width +
                        "x" +
                        pv.imageInfoList[0].height +
                        ")";
                    }
                  }
                  const soldOut = pv.forSale ? "" : "Sold Out";
                  return (
                    <React.Fragment key={idx}>
                      <div>
                        <img src={imgUrl} width="60"></img>
                      </div>
                      <div>{info2}</div>
                      <div className="left-10">
                        {pv.variantValue}
                        <br />
                        {pv.sku}&nbsp;&nbsp;${pv.salePrice}
                        <br />
                        <font color="red">{soldOut}</font>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  theForm() {
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const title = this.state.mobileMode
      ? "Image Size:"
      : "Image Size in Product Page: ";
    return (
      <div>
        <div className="form-wrapper">
          <div className="form-item-label">{title}</div>
          <div className="form-item-value">
            <select
              name="imageSetting"
              value={this.state.imageSetting}
              disabled={!this.state.editAllowed}
              onChange={this.handleChange}
            >
              <option value="-1">Use System Default</option>
              <option value="0">Use Actual Size</option>
              <option value="1">Use Fixed Size</option>
            </select>
          </div>
          <div className="form-item-label">Thumbnail HW Ratio: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="6"
              name="thumbnailHWRatio"
              value={this.state.thumbnailHWRatio}
              onChange={this.handleChange}
              disabled={
                !this.state.editAllowed || !this.state.thumbnailsAvailable
              }
            ></input>{" "}
            (Default: 0.75)
          </div>
        </div>
        <div>
          <button
            className={btnStyle}
            name="submit"
            disabled={this.state.updateBtnDisabled}
            onClick={this.updateSetting}
          >
            Update
          </button>
          &nbsp;
          <button
            className="btn-style"
            name="preview"
            onClick={this.previewProduct}
          >
            Preview
          </button>
        </div>
      </div>
    );
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Image Setting</font>
          <p />
          {this.productInfo()}
          <p />
          {this.theForm()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditImageSetting);
