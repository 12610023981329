import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import ActionConfirmation from "../ActionConfirmation";
import Working from "../Working";
import { previewImgBaseUrl, apiBaseUrl } from "../Util";
import { uploadFlyerPics } from "../FileUploader";
import "../../App.css";
import "../../generic.css";

class ImageMapWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      map: this.props.map,
      mobileMode: this.props.mobileMode,
      editAllowed: this.props.editAllowed,
      btnTitle: this.props.map.id <= 0 ? "Add New Flyer" : "Update",
      updBtnDisabled: true,
      repBtnDisabled: false,
      hdnDelMapBtnId: "delMapId_" + this.props.map.mapID,
    };
    this.handleChange = this.handleChange.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.processUpdateMapResponse = this.processUpdateMapResponse.bind(this);
    this.processReplaceResponse = this.processReplaceResponse.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.deleteMap = this.deleteMap.bind(this);
    this.processDeleteResponse = this.processDeleteResponse.bind(this);
    this.replaceImage = this.replaceImage.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  handleChange(event) {
    let name = event.target.name;

    if (name == "newImage") {
      uploadFlyerPics(event.target.files, this.processUploadResponse);
    } else {
      let value;

      if (name.startsWith("CB_")) {
        name = name.substring(3);
        value = event.target.checked;
      } else value = event.target.value;

      const map = {
        ...this.state.map,
        [name]: value,
      };
      this.setState({ map, updBtnDisabled: false });
    }
  }
  processUploadResponse(response) {
    if (response.status) {
      const info = response.data;
      const mapID = this.state.map.id > 0 ? this.state.map.mapID : info.mapID;
      const updBtnDisabled =
        this.state.map.id > 0 ? this.state.updBtnDisabled : false;
      const map = {
        ...this.state.map,
        uploadedImage: info.uploadedImage,
        imageWidth: info.imageWidth,
        imageHeight: info.imageHeight,
        mapID,
      };
      this.setState({ map, updBtnDisabled });
    }
  }
  processUpdateMapResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.data) {
        this.setState({ updBtnDisabled: true });

        const map = {
          ...response.data,
          expanded: this.state.map.expanded,
        };
        this.props.updateCallback(map, this.props.passBack);
      } else {
        const dialogTitle = "Update Map Failed";
        const dialogMessage = response.errorMessage;

        this.props.errorCallback(dialogTitle, dialogMessage);
      }
    }
  }
  processDeleteResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        this.props.deleteCallback(this.state.map, this.props.passBack);
      } else {
        const dialogTitle = "Delete Map Failed";
        const dialogMessage = response.errorMessage;

        this.props.errorCallback(dialogTitle, dialogMessage);
      }
    }
  }
  processReplaceResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.data) {
        const map = {
          ...response.data,
          expanded: this.state.map.expanded,
        };
        this.setState({ map, repBtnDisabled: false });
        this.props.updateCallback(map, this.props.passBack);
      }
    }
  }
  updateMap() {
    const url =
      this.state.map.id > 0
        ? apiBaseUrl() + "UpdateMap"
        : apiBaseUrl() + "AddNewMap";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.map,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateMapResponse(res.data);
      })
      .catch((error) => {
        const dialogTitle = "Add/Update " + error.name;
        const dialogMessage = "Add/Updated Map failed :" + error.message;

        this.props.errorCallback(dialogTitle, dialogMessage);
        console.log(error);
      });
  }
  deleteMap() {
    const url = apiBaseUrl() + "DeleteMap";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.map,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processDeleteResponse(res.data);
      })
      .catch((error) => {
        const dialogTitle = "Delete " + error.name;
        const dialogMessage = "Delete map failed :" + error.message;

        this.props.errorCallback(dialogTitle, dialogMessage);
        console.log(error);
      });
  }
  replaceImage() {
    const url = apiBaseUrl() + "ReplaceMapImage";
    this.setState({ repBtnDisabled: true });

    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.map,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processReplaceResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          repBtnDisabled: false,
          errorMessage: "Failed to replace map image",
        });
        console.log(error);
      });
  }
  editMapForm() {
    const map = this.state.map;
    const isAdd = map.id <= 0 ? true : false;
    const btnStyle = this.state.updBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const mark = isAdd ? <font color="red">*</font> : "";
    return (
      <div>
        {isAdd && this.newImageSection(true)}
        {isAdd && <div className="top-10"></div>}
        <div className="twocol-table">
          <div>Flyer ID{mark}:</div>
          {isAdd ? (
            <div className="left-10">
              <input
                type="text"
                size="20"
                name="mapID"
                value={map.mapID}
                disabled={!this.state.editAllowed}
                onChange={this.handleChange}
              />
            </div>
          ) : (
            <div className="left-10">
              <b>{map.mapID}</b>
            </div>
          )}
          {!isAdd && (
            <React.Fragment>
              <div>Image Size:</div>
              <div className="left-10">
                {map.imageWidth}x{map.imageHeight}
              </div>
            </React.Fragment>
          )}

          <div>Flyer Name:</div>
          <div className="left-10">
            <input
              type="text"
              name="mapName"
              size="25"
              value={map.mapName || ""}
              disabled={!this.state.editAllowed}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div>
          <input
            type="checkbox"
            name="CB_enabled"
            checked={map.enabled}
            disabled={!this.state.editAllowed}
            onChange={this.handleChange}
          />
          Enabled
        </div>
        <div>
          <input
            type="checkbox"
            name="CB_useInHomeScreen"
            checked={map.useInHomeScreen}
            disabled={!this.state.editAllowed}
            onChange={this.handleChange}
          />
          Show in Home Page
        </div>
        <div>
          <input
            type="checkbox"
            name="CB_showMapName"
            checked={map.showMapName}
            disabled={!this.state.editAllowed}
            onChange={this.handleChange}
          />
          Show Flyer Name
        </div>
        <div>
          <input
            type="checkbox"
            name="CB_defaultMap"
            checked={map.defaultMap}
            disabled={!this.state.editAllowed}
            onChange={this.handleChange}
          />
          Default Map
        </div>

        <div className="top-10">
          {this.state.editAllowed && (
            <div className="generic-flex">
              <div>
                <button
                  name="update"
                  className={btnStyle}
                  disabled={this.state.updBtnDisabled}
                  onClick={this.updateMap}
                >
                  {this.state.btnTitle}
                </button>
              </div>
              {!isAdd && (
                <div className="left-20">
                  <button
                    name="delete"
                    className="red-btn-style"
                    onClick={this.confirmDelete}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <ActionConfirmation
          title="Delete Confirmation"
          message={
            "Are you sure you want to delete this image map?  ID: " + map.mapID
          }
          buttonTitle="Delete"
          buttonClsName="red-btn-style"
          actionHandler={this.deleteMap}
          hidden={true}
          btnId={this.state.hdnDelMapBtnId}
        />
      </div>
    );
  }
  newImageSection() {
    const map = this.state.map;
    const btnStyle = this.state.repBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const legend = map.id > 0 ? "Replace Image" : "Upload Image";
    const clsName = map.id > 0 ? "top30w400" : "top-10";
    return (
      <div className={clsName}>
        <fieldset className="fs-container">
          <legend>
            <b>{legend}</b>
          </legend>
          {map.id > 0 ? (
            <label>
              You can replace the above image with a new one by "Choose File"
              then click "Replace" button.
            </label>
          ) : (
            <label>Please select a JPEG image file.</label>
          )}

          <p />
          <div className="left-20">
            <input
              type="file"
              name="newImage"
              accept="image/jpeg"
              disabled={!this.state.editAllowed}
              onChange={this.handleChange}
            ></input>
          </div>
          {map.uploadedImage && (
            <div>
              <img src={previewImgBaseUrl() + map.uploadedImage} width="350" />
              <br />
              <label>
                Image Size: {map.imageWidth}x{map.imageHeight}
              </label>
            </div>
          )}
          {map.uploadedImage && map.id > 0 && (
            <div className="top-20">
              <div className="twocol-table">
                <button
                  name="replace"
                  className={btnStyle}
                  onClick={this.replaceImage}
                >
                  Replace
                </button>
                {this.state.repBtnDisabled && (
                  <div className="left-20">
                    <Working size={15} />
                  </div>
                )}
              </div>
            </div>
          )}
        </fieldset>
      </div>
    );
  }

  confirmDelete() {
    document.getElementById(this.state.hdnDelMapBtnId).click();
  }

  render() {
    return (
      <div>
        {this.editMapForm()}
        {this.state.editAllowed &&
          this.state.map.id > 0 &&
          this.newImageSection()}
      </div>
    );
  }
}
export default ImageMapWidget;
