import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import { apiBaseUrl, isUserLoggedIn } from "../Util";
import InvisibleThumbnail from "./InvisibleThumbnail";
import Working from "../Working";
import "../../generic.css";
import "../../prodByCat.css";

class InvisibleProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prodList: null,
      isLoading: true,
      errorMessage: null,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };

    this.processResponse = this.processResponse.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }

    const url = apiBaseUrl() + "GetInvisibleProducts";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Failed to get invisible list",
          isLoading: false,
        });
        console.log(error);
      });
  }
  processResponse(response) {
    if (response.invalidSession === true) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      let prodList = response.objList;
      if (prodList && prodList.length === 1 && !prodList[0]) {
        prodList = null;
      }
      this.setState({ prodList, isLoading: false });
    }
  }
  showBody() {
    if (!this.state.prodList || this.state.prodList.length === 0) {
      return <div>No invisible products found.</div>;
    } else {
      return (
        <div className="prodbycat-wrapper">
          {this.state.prodList.map((item, idx) => {
            return <InvisibleThumbnail prod={item} key={idx} />;
          })}
        </div>
      );
    }
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Invisible Products</font>
          <p />
          {this.showBody()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InvisibleProducts);
