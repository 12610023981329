import React from "react";
import { getIconBaseUrl } from "../Util";
import "../../layaway.css";

class InvoiceLinkWidget extends React.Component {
  render() {
    const invoicePrintUrl = this.props.invoicePrintUrl;
    const invoicePdfUrl = this.props.invoicePdfUrl;
    const invoiceEditUrl = this.props.invoiceEditUrl;

    if (invoicePrintUrl || invoicePdfUrl || invoiceEditUrl) {
      const iconSize = 20;
      const clsName = this.props.showTitle ? "top-10" : "none";
      return (
        <div className={clsName}>
          {this.props.showTitle && (
            <div className="bottom-10">
              <b>Legacy ExpressInvoice</b>
            </div>
          )}
          <div className="invoice-links-wrapper">
            {invoicePdfUrl && (
              <React.Fragment>
                <div>
                  <img
                    src={getIconBaseUrl() + "inv_pdf.gif"}
                    width={iconSize}
                    height={iconSize}
                  ></img>
                </div>
                <div className="invoice-name">
                  &nbsp;
                  <a href={invoicePdfUrl} target="__Invoice">
                    View PDF
                  </a>
                </div>
              </React.Fragment>
            )}
            {invoicePrintUrl && (
              <React.Fragment>
                <div className="left-20">
                  <img
                    src={getIconBaseUrl() + "inv_print.gif"}
                    width={iconSize}
                    height={iconSize}
                  ></img>
                </div>
                <div className="invoice-name">
                  &nbsp;
                  <a href={invoicePrintUrl} target="__Invoice">
                    Print
                  </a>
                </div>
              </React.Fragment>
            )}
            {invoiceEditUrl && (
              <React.Fragment>
                <div className="left-20">
                  <img
                    src={getIconBaseUrl() + "inv_edit.gif"}
                    width={iconSize}
                    height={iconSize}
                  ></img>
                </div>
                <div className="invoice-name">
                  &nbsp;
                  <a href={invoiceEditUrl} target="__Invoice">
                    Edit
                  </a>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}
export default InvoiceLinkWidget;
