import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditProductSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetProductSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({ settings: response.data, isLoading: false });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("CB_")) {
      name = name.substring(3);

      const settings = {
        ...this.state.settings,
        [name]: event.target.checked,
      };
      this.setState({
        settings,
        updateBtnDisabled: false,
      });
    } else {
      const settings = {
        ...this.state.settings,
        [name]: event.target.value,
      };
      this.setState({
        settings,
        updateBtnDisabled: false,
      });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateProductSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.settings,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  p2pSettings() {
    const settings = this.state.settings;

    if (settings) {
      const editAllowed = this.state.editAllowed;
      const catLabel = this.state.mobileMode ? "Category" : "New Prod Category";
      return (
        <React.Fragment>
          <div className="form-item-label-t10">
            <b>P2P Conversion</b>
          </div>
          <div className="form-item-value-t10">&nbsp;</div>
          <div className="form-item-label">P2P Settings: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_p2pEnabled"
              defaultChecked={settings.p2pEnabled}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Enable PV 2 Prod Conversion
            <br />
            <input
              type="checkbox"
              name="CB_p2pAllowFirst"
              defaultChecked={settings.p2pAllowFirst}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.p2pEnabled}
            />{" "}
            Allow First PV
            <br />
            <input
              type="checkbox"
              name="CB_p2pShowInCat"
              defaultChecked={settings.p2pShowInCat}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.p2pEnabled}
            />{" "}
            Show New Prod in Category
            <br />
            <input
              type="checkbox"
              name="CB_p2pPriorityOnly"
              defaultChecked={settings.p2pPriorityOnly}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.p2pEnabled}
            />{" "}
            Recommended Only
          </div>
          <div className="form-item-label">Recommended: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="35"
              name="p2pPriorities"
              value={settings.p2pPriorities}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.p2pEnabled}
            />
          </div>
          <div className="form-item-label">{catLabel}: </div>
          <div className="form-item-value">
            <select
              name="p2pCategoryId"
              value={settings.p2pCategoryId}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.p2pEnabled}
            >
              <option value="0" key="0">
                USE CURRENT
              </option>
              {settings.categories.map((cat, idx) => {
                return (
                  <option value={cat.id} key={idx}>
                    {cat.name}
                  </option>
                );
              })}
            </select>
          </div>
        </React.Fragment>
      );
    }
  }
  productRankingSettings() {
    const settings = this.state.settings;
    if (settings) {
      const editAllowed = this.state.editAllowed;
      const rankLabel = this.state.mobileMode ? "Ranking" : "Product Ranking";
      const maxM2DLabel = this.state.mobileMode
        ? "Max M2D"
        : "Max M2D Adjustment";
      const maxOverallLabel = this.state.mobileMode
        ? "Max Overall"
        : "Max Overall Adjustment";
      const osAdjLabel = this.state.mobileMode
        ? "OnSale Adj"
        : "OnSale Adjustment";
      const soAdjLabel = this.state.mobileMode
        ? "SoldOut Adj"
        : "SoldOut Adjustment";
      const pvAdjLabel = this.state.mobileMode ? "PV Adj" : "PV Adjustments";
      const v2uLabel = this.state.mobileMode
        ? "Max V/U Adj"
        : "Max View/User Adj";
      const m2dV2ULabel = this.state.mobileMode
        ? "M2D V/U Adj"
        : "Max M2D View/User Adj";
      const lifespanLabel = this.state.mobileMode
        ? "Score Lifespan"
        : "Ranking Score Lifespan";
      const oLabel = this.state.mobileMode
        ? "Max Order Adj"
        : "Max Order Adjustment";
      const m2dOLabel = this.state.mobileMode
        ? "M2D Order Adj"
        : "Max M2D Order Adjustment";
      const numType = this.state.mobileMode ? "number" : "text";
      return (
        <React.Fragment>
          <div className="form-item-label-t10">
            <b>{rankLabel}</b>
          </div>
          <div className="form-item-value-t10">&nbsp;</div>
          <div className="form-item-label">{osAdjLabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="onSaleAdjustment"
              value={settings.onSaleAdjustment}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />
          </div>
          <div className="form-item-label">{soAdjLabel}: </div>
          <div className="form-item-value">
            <input
              type={numType}
              size="5"
              name="soldOutAdjustment"
              value={settings.soldOutAdjustment}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />
          </div>
          <div className="form-item-label">{pvAdjLabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name="pvAdjustments"
              value={settings.pvAdjustments}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />
          </div>
          <div className="form-item-label">{maxOverallLabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="maxOverallViewsAdjustment"
              value={settings.maxOverallViewsAdjustment}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            (Overall product views)
          </div>
          <div className="form-item-label">{maxM2DLabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="maxM2DViewsAdjustment"
              value={settings.maxM2DViewsAdjustment}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            (Month 2 date views)
          </div>
          <div className="form-item-label">{v2uLabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="maxOverallV2UAdjustment"
              value={settings.maxOverallV2UAdjustment}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            (Overall views/user)
          </div>
          <div className="form-item-label">{m2dV2ULabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="maxM2DV2UAdjustment"
              value={settings.maxM2DV2UAdjustment}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            (Month 2 date views/user)
          </div>
          <div className="form-item-label">{oLabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="maxOrderAdjustment"
              value={settings.maxOrderAdjustment}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />
          </div>
          <div className="form-item-label">{m2dOLabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="maxM2DOrderAdjustment"
              value={settings.maxM2DOrderAdjustment}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />
          </div>
          <div className="form-item-label">{lifespanLabel}: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="rankingScoreLifespan"
              value={settings.rankingScoreLifespan}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            (Minutes)
          </div>
        </React.Fragment>
      );
    }
  }
  productImageSettings() {
    const settings = this.state.settings;
    if (settings) {
      const editAllowed = this.state.editAllowed;
      const imgLabel = this.state.mobileMode ? "Image" : "Product Image";
      const imgSecLabel = this.state.mobileMode ? "Image" : "Product Image";
      return (
        <React.Fragment>
          <div className="form-item-label-t10">
            <b>{imgSecLabel}</b>
          </div>
          <div className="form-item-value-t10">&nbsp;</div>
          <div className="form-item-label">Image Setting: </div>
          <div className="form-item-value">
            <select
              name="imageSetting"
              value={settings.imageSetting}
              disabled={!editAllowed}
              onChange={this.handleChange}
            >
              <option value="0">Actual Resolution</option>
              <option value="1">Fixed Size</option>
              <option value="2">Actual Resoltion Inside Fixed Size</option>
            </select>
          </div>
          <div className="form-item-label">{imgLabel}: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_showModalImage"
              defaultChecked={settings.showModalImage}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Show modal image
            <br />
            <input
              type="checkbox"
              name="CB_useFirstForSalePvImage"
              defaultChecked={settings.useFirstForSalePvImage}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Use first forSale variant image
          </div>
          <div className="form-item-label">Thumbnail: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_autoGenerateThumbnail"
              defaultChecked={settings.autoGenerateThumbnail}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Auto generate thumbnails
          </div>
          <div className="form-item-label">Thumbnail Size: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="thumbnailWidth"
              value={settings.thumbnailWidth}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.autoGenerateThumbnail}
            />{" "}
            x&nbsp;
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="thumbnailHeight"
              value={settings.thumbnailHeight}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.autoGenerateThumbnail}
            />
          </div>
          <div className="form-item-label">Thumbnail Quality: </div>
          <div className="form-item-value">
            <input
              type="range"
              size="5"
              name="thumbnailQuality"
              min={75}
              max={100}
              value={settings.thumbnailQuality}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.autoGenerateThumbnail}
            />
            &nbsp;{settings.thumbnailQuality}
          </div>
          <div className="form-item-label">Thumbnail Type: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_keepOrigImageType"
              defaultChecked={settings.keepOrigImageType}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.autoGenerateThumbnail}
            />{" "}
            Keep Original Image Type
          </div>
        </React.Fragment>
      );
    }
  }
  theForm() {
    const settings = this.state.settings;
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const quantLabel = this.state.mobileMode
      ? "Use product quantity"
      : "Use product quantity (for inventory)";

    return (
      <div className="generic-wrapper">
        <font size="5">Product Settings</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">
            Dimension/
            <br />
            Template:{" "}
          </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_showDimension"
              defaultChecked={settings.showDimension}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show Product Dimension
            <br />
            <input
              type="text"
              size="35"
              name="dimensionTemplate"
              value={settings.dimensionTemplate}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Add Item:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_showCartAfterAdd"
              defaultChecked={settings.showCartAfterAdd}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show shopping cart
          </div>
          <div className="form-item-label">Quantity:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_useProductQuantity"
              defaultChecked={settings.useProductQuantity}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            {quantLabel}
          </div>
          <div className="form-item-label">Symbolic Link: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_singleVariantSymLinkOnly"
              defaultChecked={settings.singleVariantSymLinkOnly}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Single variant products only
          </div>
          <div className="form-item-label">Keywords: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_showKeywords"
              defaultChecked={settings.showKeywords}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show keywords in meta data
          </div>
          <div className="form-item-label">PV Sequence: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_discriminateSoldoutPv"
              defaultChecked={settings.discriminateSoldoutPv}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Discriminate Soldout PVs
          </div>
          {this.productImageSettings()}
          {this.p2pSettings()}
          {this.productRankingSettings()}
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProductSetting);
