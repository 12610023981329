import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { fullProductImageUrl } from "../Util";
import "../../App.css";
import "../../generic.css";

class ThumbnailView extends React.Component {
  render() {
    const imgUrl = fullProductImageUrl(this.props.prod.imageUrl);
    const pvUrl = "/product/" + this.props.prod.id;
    let name = this.props.prod.name;

    if (name.length > 20) name = name.substring(0, 20);
    return (
      <div className="generic-flex" key={name}>
        <div className="generic-item">
          <Link to={pvUrl}>
            <img src={imgUrl} width="85" height="57" alt="ProdImage"></img>
          </Link>
        </div>
        <div className="generic-item" key={this.props.prod.id}>
          <div>{name}</div>

          {this.props.prod.pvList.map((pv, idx) => {
            if (idx < 2) {
              let sku = pv.sku;
              if (sku.length > 10) sku = sku.substring(0, 10);
              const key = sku + "_" + pv.id + "_" + idx;
              return (
                <div className="left-10" key={key}>
                  {sku}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(ThumbnailView);
