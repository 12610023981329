import {
  SET_USERINFO,
  SET_LOGGEDIN,
  SET_LOGGEDOUT,
  SET_USERID,
  SET_RESETMSG,
  SET_VERSION,
} from "./actionTypes";

export const setUserInfo = (userInfo) => {
  return {
    type: SET_USERINFO,
    payload: userInfo,
  };
};
export const setLoggedIn = (payload) => {
  return {
    type: SET_LOGGEDIN,
    payload: payload,
  };
};
export const setUserId = (payload) => {
  return {
    type: SET_USERID,
    payload: payload,
  };
};
export const setResetMsg = (msg) => {
  return {
    type: SET_RESETMSG,
    resetMsg: msg,
  };
};
export const setLoggedOut = () => {
  return {
    type: SET_LOGGEDOUT,
  };
};
export const setVersion = (version) => {
  return {
    type: SET_VERSION,
    payload: version,
  };
};
