import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  previewImgBaseUrl,
  fullProductImageUrl,
  getIconBaseUrl,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import { uploadFiles } from "../FileUploader";
import ActionConfirmation from "../ActionConfirmation";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../inventory.css";

class BatchAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      zip_disabled: false,
      zip_includePdf: false,
      zip_includeAllImages: false,
      zip_ftp: false,
      zip_download: true,
      zip_encrypt: false,
      zip_password: null,
      zip_timer: -1,
      zipFileUrl: null,
      csv_disabled: false,
      csv_ftp: false,
      csv_download: true,
      csv_timer: -1,
      csvFileUrl: null,
      reviewMode: false,
      commit_disabled: false,
      com_timer: -1,
      mobileMode: false,
      updatedList: null,
      errorMessage: null,
      updMessage: null,
      updErrorMessage: null,
      uploadedCsvFile: null,
      viewAllowed: checkView("Batch"),
      updateAllowed: checkUpdate("Batch"),
    };

    this.adjustMode = this.adjustMode.bind(this);
    this.pullImages = this.pullImages.bind(this);
    this.pullInventory = this.pullInventory.bind(this);
    this.fetchUpdatedList = this.fetchUpdatedList.bind(this);
    this.commitChanges = this.commitChanges.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.processUpdListResponse = this.processUpdListResponse.bind(this);
    this.processCommitResponse = this.processCommitResponse.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.zipTimerHandler = this.zipTimerHandler.bind(this);
    this.comTimerHandler = this.comTimerHandler.bind(this);
    this.csvTimerHandler = this.csvTimerHandler.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.clearFile = this.clearFile.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  pullImages() {
    this.setState({ zip_disabled: true });

    const url = apiBaseUrl() + "PullAllImages";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      includePdf: this.state.zip_includePdf,
      includeAllImages: this.state.zip_includeAllImages,
      ftp: this.state.zip_ftp,
      download: this.state.zip_download,
      encrypt: this.state.zip_encrypt,
      password: this.state.zip_password,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data, true);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to pull product images",
        });
        console.log(error);
      });
  }
  pullInventory() {
    this.setState({ csv_disabled: true });

    const url = apiBaseUrl() + "PullInventory";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      ftp: this.state.csv_ftp,
      download: this.state.csv_download,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data, false);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to pull inventory",
        });
        console.log(error);
      });
  }
  processCommitResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        this.props.history.push("/");
      } else {
        this.setState({ errorMessage: response.errorMessage });
      }
    }
  }
  commitChanges() {
    this.setState({ commit_disabled: true });

    const url = apiBaseUrl() + "CommitProductUpdates";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: this.state.updatedList,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processCommitResponse(res.data, false);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to commit changes",
        });
        console.log(error);
      });
  }
  cancelChanges() {
    window.location.reload();
  }
  zipTimerHandler() {
    if (this.state.zip_timer > 1) {
      this.setState({ zip_timer: this.state.zip_timer - 1 });
      setTimeout(this.zipTimerHandler, 60000);
    } else {
      this.setState({ zip_timer: -1, zipFileUrl: null, zip_disabled: false });
    }
  }
  csvTimerHandler() {
    if (this.state.csv_timer > 1) {
      this.setState({ csv_timer: this.state.csv_timer - 1 });
      setTimeout(this.csvTimerHandler, 60000);
    } else {
      this.setState({ csv_timer: -1, csvFileUrl: null, csv_disabled: false });
    }
  }
  comTimerHandler() {
    if (this.state.com_timer > 1) {
      this.setState({ com_timer: this.state.com_timer - 1 });
      setTimeout(this.comTimerHandler, 60000);
    } else {
      window.location.reload();
    }
  }
  processResponse(response, isZip) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.data) {
        if (isZip) {
          this.setState({
            zipFileUrl: response.data,
            zip_timer: 10,
            isLoading: false,
          });
          setTimeout(this.zipTimerHandler, 60000);
        } else {
          this.setState({
            csvFileUrl: response.data,
            csv_timer: 10,
            isLoading: false,
          });
          setTimeout(this.csvTimerHandler, 60000);
        }
      }
    }
  }

  processUploadResponse(response, fileName) {
    if (response.status) {
      this.setState({
        uploadedCsvFile: fileName,
        updMessage: null,
        updErrorMessage: null,
      });
      this.fetchUpdatedList(fileName);
    } else {
      this.setState({ updErrorMessage: "Failed to upload" });
    }
  }
  processUpdListResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        const updatedList = response.objList;
        if (!updatedList || updatedList.length === 0) {
          this.setState({
            updMessage: "There is no change in the uploaded CSV file.",
            updErrorMessage: null,
          });
        } else {
          this.setState({ updatedList, reviewMode: true, com_timer: 10 });
          setTimeout(this.comTimerHandler, 60000);
        }
      } else {
        this.setState({ updErrorMessage: response.errorMessage });
      }
    }
  }
  fetchUpdatedList(fileName) {
    const url = apiBaseUrl() + "FetchUpdatedList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: fileName,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdListResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get updated list",
        });
        console.log(error);
      });
  }

  handleChange(event) {
    if (event.target.name === "uploadCsv") {
      uploadFiles(event.target.files, this.processUploadResponse);
    } else if (event.target.name === "zip_password") {
      this.setState({ zip_password: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.checked });
    }
  }
  showLink(isZip) {
    const file = isZip ? this.state.zipFileUrl : this.state.csvFileUrl;
    const disabled = isZip ? this.state.zip_disabled : this.state.csv_disabled;
    const name = isZip ? "ZIP" : "CSV";

    if (file) {
      const url = previewImgBaseUrl() + file;
      const timer = isZip ? this.state.zip_timer : this.state.csv_timer;

      return (
        <div>
          <p />
          Please click the link below to download the {name} file. Please note,
          the
          {name} file will be deleted in{" "}
          <b>
            <font color="red" size="4">
              {timer}
            </font>
          </b>{" "}
          minutes.
          <p />
          <a href={url}>
            <b>{file}</b>
          </a>
          <p />
        </div>
      );
    } else if (disabled) {
      return (
        <div>
          <p />
          <font color="orange">Generating {name} file ....</font>
          <p />
        </div>
      );
    }
  }

  productImages() {
    const btnStyle = this.state.zip_disabled
      ? "disabled-btn-style"
      : "btn-style";
    return (
      <div>
        <b>
          <font size="4">Product Images</font>
        </b>
        <br />
        Click the button below to generate a ZIP file that contains all the
        latest product images.
        <p />
        <i>Options:</i>
        <br />
        <div>
          <div className="left-10">
            <input
              type="checkbox"
              name="zip_includeAllImages"
              defaultChecked={this.state.zip_includeAllImages}
              onChange={this.handleChange}
              disabled={this.state.zip_disabled}
            ></input>{" "}
            Include All Images (not just the first one)
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="zip_includePdf"
              defaultChecked={this.state.zip_includePdf}
              onChange={this.handleChange}
              disabled={this.state.zip_disabled}
            ></input>{" "}
            Include Assembly Manuals
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="zip_ftp"
              defaultChecked={this.state.zip_ftp}
              onChange={this.handleChange}
              disabled={this.state.zip_disabled}
            ></input>{" "}
            FTP ZIP file to invoice server
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="zip_download"
              defaultChecked={this.state.zip_download}
              onChange={this.handleChange}
              disabled={this.state.zip_disabled}
            ></input>{" "}
            Download ZIP file
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="zip_encrypt"
              defaultChecked={this.state.zip_encrypt}
              onChange={this.handleChange}
              disabled={this.state.zip_disabled}
            ></input>{" "}
            Encrypt ZIP file
          </div>
          {this.state.zip_encrypt && (
            <div className="left-10">
              Password:&nbsp;
              <input
                type="text"
                name="zip_password"
                size="20"
                value={this.state.zip_password}
                placeholder="Password"
                onChange={this.handleChange}
                disabled={this.state.zip_disabled || !this.state.zip_encrypt}
              ></input>
            </div>
          )}
          <div>
            <p />
            <button
              className={btnStyle}
              name="pull"
              onClick={this.pullImages}
              disabled={this.state.zip_disabled}
            >
              Pull Product Images
            </button>
          </div>
          {this.showLink(true)}
        </div>
      </div>
    );
  }

  productInventory() {
    const btnStyle = this.state.csv_disabled
      ? "disabled-btn-style"
      : "btn-style";
    return (
      <div>
        <b>
          <font size="4">Product Inventory</font>
        </b>
        <br />
        Click the button below to generate a CSV file that contains all the
        products.
        <p />
        <i>Options:</i>
        <br />
        <div>
          <div className="left-10">
            <input
              type="checkbox"
              name="csv_ftp"
              defaultChecked={this.state.csv_ftp}
              onChange={this.handleChange}
              disabled={this.state.csv_disabled}
            ></input>{" "}
            FTP CSV file to invoice server
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="csv_download"
              defaultChecked={this.state.csv_download}
              onChange={this.handleChange}
              disabled={this.state.csv_disabled}
            ></input>{" "}
            Download CSV file
          </div>
          <div>
            <p />
            <button
              className={btnStyle}
              name="pullInv"
              onClick={this.pullInventory}
              disabled={this.state.csv_disabled}
            >
              Pull Product Inventory
            </button>
          </div>
          {this.showLink(false)}
        </div>
      </div>
    );
  }
  clearFile(event) {
    event.target.value = "";
    this.setState({ uploadedCsvFile: null });
  }
  showUploadCsv() {
    if (this.state.updateAllowed) {
      return (
        <div>
          <b>
            <font size="4">Update Products with CSV</font>
          </b>
          <p />
          You can batch update product quantities, prices, and availabilities by
          uploading an updated CSV file. Once uploaded, you have a chance to
          review in the next page before committing your changes.
          <p />
          <label for="file-upload" class="custom-file-upload">
            <img src={getIconBaseUrl() + "upload.png"} height="11"></img>Upload
            CSV
          </label>
          <input
            type="file"
            id="file-upload"
            name="uploadCsv"
            accept=".csv"
            onClick={this.clearFile}
            onChange={this.handleChange}
            style={{ display: "none" }}
          ></input>{" "}
          &nbsp;{this.state.uploadedCsvFile}
          <p />
          {this.state.updMessage && (
            <div>
              <font color="blue">{this.state.updMessage}</font>
              <p />
            </div>
          )}
          {this.state.updErrorMessage && (
            <div>
              <font color="red">{this.state.updErrorMessage}</font>
              <p />
            </div>
          )}
          &nbsp;
          <p />
        </div>
      );
    }
  }
  oneRow(info, idx) {
    const clsName = idx % 2 === 0 ? "inv-item-ev" : "inv-item-od";
    const imgUrl = fullProductImageUrl(info.imageUrl);
    return (
      <React.Fragment>
        <div className={clsName}>
          <img src={imgUrl} alt={info.sku} width="60" height="40"></img>
        </div>
        <div className={clsName}>{info.sku}</div>
        {info.quantity !== info.origQuantity ? (
          <div className={clsName}>
            <font color="red">{info.quantity}</font>
            <br />
            {info.origQuantity}
          </div>
        ) : (
          <div className={clsName}>{info.quantity}</div>
        )}
        {info.price !== info.origPrice ? (
          <div className={clsName}>
            <font color="red">${info.price}</font>
            <br />${info.origPrice}
          </div>
        ) : (
          <div className={clsName}>${info.price}</div>
        )}
        {info.forSale !== info.origForSale ? (
          <div className={clsName}>
            <font color="red">{info.forSale ? "Yes" : "No"}</font>
          </div>
        ) : (
          <div className={clsName}>{info.forSale ? "Yes" : "No"}</div>
        )}
      </React.Fragment>
    );
  }

  showChanges() {
    return (
      <div className="inv-table-c5">
        <div className="inv-item-hd">&nbsp;</div>
        <div className="inv-item-hd">SKU</div>
        <div className="inv-item-hd">Quantity</div>
        <div className="inv-item-hd">Price</div>
        <div className="inv-item-hd">For Sale</div>
        {this.state.updatedList.map((info, idx) => {
          return this.oneRow(info, idx);
        })}
      </div>
    );
  }
  showConfirmation() {
    document.getElementById("hiddenConfirmDelBtn").click();
  }
  reviewChanges() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Review Changes</font>
          <p />
          Please review the following {this.state.updatedList.length} changes in
          the uploaded CSV file carefully then click on the "Commit Changes" if
          everything looks OK. This page will expire in{" "}
          <font color="red" size="4">
            {this.state.com_timer}
          </font>{" "}
          minutes.
          <p />
          {this.showChanges()}
          <p />
          <div>
            <button
              name="commit"
              className="btn-style"
              onClick={this.showConfirmation}
              disabled={this.state.commit_disabled}
            >
              Commit Changes
            </button>
            &nbsp;&nbsp;
            <button
              name="cancel"
              className="btn-style"
              onClick={this.cancelChanges}
            >
              Cancel
            </button>
          </div>
          <ActionConfirmation
            hidden={true}
            btnId="hiddenConfirmDelBtn"
            actionHandler={this.commitChanges}
            buttonTitle="Hidden Delete"
            buttonClsName="red-btn-style"
            title="Change Confirmation"
            message='Are you sure you want to update these products? If you click "Yes", all the products in the list will be updated.'
          />
        </div>
      </div>
    );
  }
  render() {
    if (
      this.state.reviewMode &&
      this.state.updatedList &&
      this.state.updatedList.length > 0
    ) {
      return this.reviewChanges();
    } else {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">Download/Upload</font>
            <p />
            {this.productImages()}
            <hr width="300" align="left"></hr>
            <p />
            {this.productInventory()}
            <hr width="300" align="left"></hr>
            <p />
            {this.showUploadCsv()}
            <p />
            {this.state.errorMessage && (
              <div>
                <font color="red">{this.state.errorMessage}</font>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BatchAdmin);
