import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditOtherSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
      isRoot: checkSecurity("RootFeatures"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetOtherSettings";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({ settings: response.data, isLoading: false });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("SC_")) {
      const scName = name.substring(3);
      let shippingChoices = this.state.settings.shippingChoices;
      let sc = shippingChoices.find((choice) => choice.name === scName);
      if (sc) {
        sc.enabled = event.target.checked;
      }
      const settings = {
        ...this.state.settings,
        shippingChoices,
      };
      this.setState({ settings, updateBtnDisabled: false });
    } else if (name.startsWith("CB_")) {
      name = name.substring(3);

      const settings = {
        ...this.state.settings,
        [name]: event.target.checked,
      };
      this.setState({
        settings,
        updateBtnDisabled: false,
      });
    } else {
      const settings = {
        ...this.state.settings,
        [name]: event.target.value,
      };
      this.setState({
        settings,
        updateBtnDisabled: false,
      });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateOtherSettings";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      otherSettings: this.state.settings,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  theForm() {
    const settings = this.state.settings;
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const sc = settings.shippingChoices.find(
      (choice) => choice.name === "Delivery"
    );
    const ooaMsgVisible = sc && sc.enabled;
    const colCnt = this.state.mobileMode ? 37 : 60;
    const rowCnt = this.state.mobileMode ? 8 : 5;
    const sLabel = this.state.mobileMode ? "Ship Choices" : "Shipping Choices";
    const ooaMsg = settings.outOfAreaMessage ? settings.outOfAreaMessage : "";
    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";
    const telType = this.state.mobileMode ? "tel" : "text";
    const urlName = this.state.mobileMode ? "URL Prefix" : "Invoice URL Prefix";
    return (
      <div className="generic-wrapper">
        <font size="5">Other Settings</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">Tax Rate: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputMode="decimal"
              size="8"
              name="taxRate"
              value={settings.taxRate}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            %
          </div>
          <div className="form-item-label">Main Phone: </div>
          <div className="form-item-value">
            <input
              type={telType}
              size="15"
              name="mainPhone"
              value={settings.mainPhone}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Assembly Fee:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_addAssemblyFeeToStoreEmail"
              defaultChecked={settings.addAssemblyFeeToStoreEmail}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show Fee in Store Email
            <br />
            <input
              type="checkbox"
              name="CB_addAssemblyFeeToCustomerEmail"
              defaultChecked={settings.addAssemblyFeeToCustomerEmail}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show Fee in Customer Email
          </div>
          <div className="form-item-label">
            <b>Shipping</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">{sLabel}: </div>
          <div className="form-item-value">
            {settings.shippingChoices.map((choice) => {
              const name = "SC_" + choice.name;
              return (
                <React.Fragment key={choice.name}>
                  <input
                    type="checkbox"
                    name={name}
                    checked={choice.enabled}
                    onChange={this.handleChange}
                    disabled={!editAllowed}
                  ></input>{" "}
                  {choice.name}
                  <br />
                </React.Fragment>
              );
            })}
          </div>
          {ooaMsgVisible && (
            <React.Fragment>
              <div className="form-item-label">
                Out Of Delivery
                <br />
                Area Message:{" "}
              </div>
              <div className="form-item-value">
                <textarea
                  name="outOfAreaMessage"
                  value={ooaMsg}
                  rows={rowCnt}
                  cols={colCnt}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                ></textarea>
              </div>
            </React.Fragment>
          )}
          <div className="form-item-label">
            <b>Appearance</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Search Box: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_autoHideSearchBox"
              defaultChecked={settings.autoHideSearchBox}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Auto Hide
          </div>
          <div className="form-item-label">Mobile Input: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_supportHTML5"
              defaultChecked={settings.supportHTML5}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Supports HTML5
          </div>
          <div className="form-item-label">Delivery Date: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_useHTML5Date"
              defaultChecked={settings.useHTML5Date}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Use HTML5 Date Widget
            <br />
            <input
              type="checkbox"
              name="CB_useHTML5DateForIOS"
              defaultChecked={settings.useHTML5DateForIOS}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.useHTML5Date}
            ></input>{" "}
            Use HTML5 Date Widget for iOS
            <br />
          </div>
          <div className="form-item-label">Category: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_useCatImageForMobile"
              defaultChecked={settings.useCatImageForMobile}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Use Image for Mobile
          </div>
          <div className="form-item-label">Mobile Device: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_useScreenWidth"
              defaultChecked={settings.useScreenWidth}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Use Screen.Width (Not ClientWidth)
          </div>
          <div className="form-item-label">Address: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_autoCompleteAddress"
              defaultChecked={settings.autoCompleteAddress}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Auto-Complete Address
            <br />
            <input
              type="checkbox"
              name="CB_autoCompleteCDF"
              defaultChecked={settings.autoCompleteCDF}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Auto-Complete Calculate Delivery Fee
          </div>
          <div className="form-item-label">Sign In: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_showSignInAtCheckout"
              defaultChecked={settings.showSignInAtCheckout}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show Sign-in Box at Checkout
          </div>
          <div className="form-item-label">
            <b>Wording</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Deposit: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="20"
              name="pickupDepositTerm"
              value={settings.pickupDepositTerm}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Special: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name="specialPageTitle"
              value={settings.specialPageTitle}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">
            <b>Best Seller</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Admin Max: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="8"
              name="bestSellerMax"
              value={settings.bestSellerMax}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Main Max: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="8"
              name="mainSiteMaxRanking"
              value={settings.mainSiteMaxRanking}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            (0: turn off, -1: show all)
          </div>
          <div className="form-item-label">Top Count: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="8"
              name="topProdCount"
              value={settings.topProdCount}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            (0: turn off)
          </div>
          <div className="form-item-label">
            <b>Versions</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Main Site: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="8"
              name="customerSiteVersion"
              value={settings.customerSiteVersion}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Admin Site: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="8"
              name="adminSiteVersion"
              value={settings.adminSiteVersion}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">
            <b>Miscellaneous</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">{urlName}: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name="invoiceUrlPrefix"
              value={settings.invoiceUrlPrefix}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          {this.state.isRoot && (
            <React.Fragment>
              <div className="form-item-label">Product Images: </div>
              <div className="form-item-value">
                <input
                  type="checkbox"
                  name="CB_convertImageToWebp"
                  defaultChecked={settings.convertImageToWebp}
                  onChange={this.handleChange}
                  disabled={!editAllowed}
                ></input>
                Auto Convert To WEBP Format
              </div>
            </React.Fragment>
          )}
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditOtherSettings);
