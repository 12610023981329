import React, { Component } from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Link } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { AXIOS_HEADER } from "../config/constants";
import { getIconBaseUrl, apiBaseUrl, toAmountEx } from "../Util";
import DialogBox from "../DialogBox";

import "../../generic.css";
import "../../App.css";

class LeaseLookupWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaseInfo: null,
      orderNumberList: null,
      leaseStatus: null,
      lookupType: 3,
      leaseId: "",
      phoneNumber: "",
      last4SSN: "",
      lastName: "",
      birthMonth: "01",
      birthYear: null,
      lookupBtnDisabled: true,
      waitingForLeaseInfo: false,
      waitingForLeaseInfo: false,
      errorMessage: null,
      hideSSNForIDLookup: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.setLookupType = this.setLookupType.bind(this);
    this.lookupLease = this.lookupLease.bind(this);
    this.processLookupResponse = this.processLookupResponse.bind(this);
    this.cancelContracts = this.cancelContracts.bind(this);
  }
  processLookupResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (response.status) {
      this.setState({
        leaseInfo: response.leaseInformation,
        orderNumberList: response.orderNumberList,
        leaseStatus: response.leaseStatus,
        waitingForLeaseInfo: false,
      });
    } else {
      this.setState({
        errorMessage: response.errorMessage,
        waitingForLeaseInfo: false,
      });
    }
  }
  lookupLease(event) {
    const url = apiBaseUrl() + "LookupLease";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      ...this.state,
      storeId: 0,
    };

    axiosRetry(axios, { retries: 3 });
    this.setState({
      waitingForLeaseInfo: true,
      leaseInfo: null,
      orderNumberList: null,
      leaseStatus: null,
      errorMessage: null,
      lookupBtnDisabled: true,
    });

    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        console.log(res.data);
        this.processLookupResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          waitingForLeaseInfo: false,
          lookupBtnDisabled: false,
          dialogTitle: this.formatDialogTitle("Lease Approval Not Found", true),
          dialogMessage: "An error has occurred, please try again later",
        });
        document.getElementById("hdnDialogBoxBtn").click();
        console.log(error);
      });
  }
  cancelContracts() {
    const url = apiBaseUrl() + "CancelContracts";
    const info = this.state.leaseInfo;
    const orderNumber =
      this.state.orderNumberList && this.state.orderNumberList.length > 0
        ? this.state.orderNumberList[0]
        : null;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      leaseId: info.accountNumber,
      orderNumber,
    };

    this.setState({ waitingForCancelling: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            this.setState({
              errorMessage: null,
              waitingForCancelling: false,
            });
            window.location.reload();
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              waitingForCancelling: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to cancel lease contracts",
          waitingForCancelling: false,
        });
      });
  }
  formatDialogTitle(title, error) {
    const img = error ? "error.jpg" : "prog-web-05-Prog-Mark-Icon-Bl02.jpg";
    return (
      <div>
        <img src={getIconBaseUrl() + img} height="18" />
        &nbsp;&nbsp;{title}
      </div>
    );
  }
  handleChange(event) {
    const name = event.target.name;
    let lookupType;

    if (name === "lookupType") {
      lookupType = parseInt(event.target.value);
      this.setState({ lookupType });
    } else {
      const state = {
        ...this.state,
        [name]: event.target.value,
      };
      lookupType = this.state.lookupType;
      let lookupBtnDisabled = true;
      const last4 = state.last4SSN;
      if (lookupType == 3 || (last4 && last4.length >= 4)) {
        const type = state.lookupType;
        if (type == 1) {
          if (state.phoneNumber && state.phoneNumber.length >= 10) {
            lookupBtnDisabled = false;
          }
        } else if (type == 2) {
          if (state.lastName && state.lastName.length > 0) {
            if (state.birthYear && state.birthYear.length == 4) {
              lookupBtnDisabled = false;
            }
          }
        } else {
          if (state.leaseId && state.leaseId.length > 3) {
            lookupBtnDisabled = false;
          }
        }
      }
      this.setState({
        [name]: event.target.value,
        lookupBtnDisabled,
      });
    }
  }
  setLookupType(type) {
    this.setState({ lookupType: type });
  }
  lookupTypes() {
    return (
      <div className="generic-flex">
        <div>
          <input
            type="radio"
            name="lookupType"
            value={3}
            checked={this.state.lookupType == 3}
            onChange={this.handleChange}
          />
          <label className="choice-label" onClick={() => this.setLookupType(3)}>
            By Lease ID
          </label>
        </div>
        <div className="left-10">
          <input
            type="radio"
            name="lookupType"
            value={1}
            checked={this.state.lookupType == 1}
            onChange={this.handleChange}
          />
          <label className="choice-label" onClick={() => this.setLookupType(1)}>
            By Phone
          </label>
        </div>
        <div className="left-10">
          <input
            type="radio"
            name="lookupType"
            value={2}
            checked={this.state.lookupType == 2}
            onChange={this.handleChange}
          />
          <label className="choice-label" onClick={() => this.setLookupType(2)}>
            By Name
          </label>
        </div>
      </div>
    );
  }
  showLeaseInfo() {
    if (this.state.leaseInfo) {
      const info = this.state.leaseInfo;
      console.log(info);
      const addr = info.customerAddress;
      const pos = info.createDate ? info.createDate.indexOf("T") : -1;
      const dateStr =
        pos > 0 ? info.createDate.substring(0, pos) : info.createDate;
      const okdLabel = info.okayToDeliver ? (
        <font color="green">Yes</font>
      ) : (
        <font color="red">No</font>
      );
      const showOtherInfo =
        info.accountStatus == "Approved" || info.initialPaymentTotal == "$0.00"
          ? false
          : true;
      let reason = info.accountStatusReason;
      if (info.accountStatusReason && info.accountStatusReason.length > 13) {
        let cols = parseInt(info.accountStatusReason.length / 13);
        if (cols > 5) cols = 5;
        reason = (
          <textarea name="status" cols={15} rows={4} disabled={true}>
            {info.accountStatusReason}
          </textarea>
        );
      }

      return (
        <div className="top-10">
          <b>Lease Found:</b>
          <div className="generic-flex">
            <div className="otv-2col-form">
              <div>Lease ID:</div>
              <div>{info.accountNumber}</div>
              <div>Date Applied:</div>
              <div>{dateStr}</div>
              <div>Approval Limit:</div>
              <div>{toAmountEx(info.approvalLimit)}</div>
              <div>Account Status: </div>
              <div>{info.accountStatus}</div>
              {info.accountStatusReason && (
                <React.Fragment>
                  <div>Status Reason: </div>
                  <div>{reason}</div>
                </React.Fragment>
              )}
              {showOtherInfo && (
                <React.Fragment>
                  <div>OkToDeliver: </div>
                  <div>{okdLabel}</div>
                  <div>Initial Payment:</div>
                  <div>{info.initialPaymentTotal}</div>
                  <div>Periodic Payment:</div>
                  <div>{info.periodicPaymentTotal}</div>
                  <div>Frequency:</div>
                  <div>{info.frequency}</div>
                  {!info.okayToDeliver && (
                    <React.Fragment>
                      <div>E-sign URL:</div>
                      <div>
                        <a href={info.documentUrl} target="__Esign">
                          Go to E-sign
                        </a>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="left-10">
              {info.lastName}, {info.firstName}
              <br />
              {addr && (
                <div>
                  {addr.streetAddress1}
                  <br />
                  {addr.city}, {addr.state} {addr.zip}
                </div>
              )}
              {info.cellPhone}
              <br />
              {info.emailAddress}
            </div>
          </div>
        </div>
      );
    }
  }
  showCancelButton() {
    const ps = this.state.leaseStatus;
    if (ps && ps.canCancel) {
      const clsName = this.state.waitingForCancelling
        ? "disabled-lightgreen-btn"
        : "lightgreen-btn";
      return (
        <fieldset className="fs-container">
          <legend>Progressive Leasing</legend>
          <div>
            You may cancel the Pogressive leasing contracts by clicking the
            button below. After successful cancellation, the customer will be
            able to re-order from our website.
            <div className="generic-flex">
              <button
                name="cancelContracts"
                className={clsName}
                onClick={this.cancelContracts}
              >
                Cancel Lease Contracts
              </button>
              {this.state.waitingForCancelling && (
                <div className="left-10">
                  <img
                    src={getIconBaseUrl() + "working.gif"}
                    height="25"
                    alt="Waiting"
                  />
                </div>
              )}
            </div>
          </div>
        </fieldset>
      );
    }
  }
  relatedOrders() {
    const orderNumberList = this.state.orderNumberList;
    if (orderNumberList && orderNumberList.length > 0) {
      return (
        <div className="top-10">
          <b>Related Orders:</b>
          <ul>
            {orderNumberList.map((ordNum, idx) => {
              const url = "/orderDetail/" + ordNum;
              return (
                <li key={idx}>
                  <Link to={url}>{ordNum}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }
  lookupForm() {
    const clsName = this.state.lookupBtnDisabled
      ? "disabled-lightgreen-btn"
      : "lightgreen-btn";
    const reqLabel = (
      <label>
        (<font color="red">*</font>)
      </label>
    );
    const lookupType = this.state.lookupType;
    const ssnReq = lookupType == 3 ? "" : reqLabel;

    return (
      <fieldset className="fs-container">
        <legend>Lease Lookup</legend>
        {this.lookupTypes()}
        <ValidatorForm ref="form" onSubmit={this.lookupLease}>
          <div className="tbl-container3">
            {(lookupType != 3 || !this.state.hideSSNForIDLookup) && (
              <React.Fragment>
                <div>Last 4 SSN{ssnReq}: </div>
                <div>
                  {lookupType == 3 ? (
                    <input
                      type="text"
                      className="password-mask"
                      type="tel"
                      size="5"
                      name="last4SSN"
                      value={this.state.last4SSN}
                      onChange={this.handleChange}
                    />
                  ) : (
                    <TextInput
                      className="password-mask"
                      type="tel"
                      size="5"
                      name="last4SSN"
                      value={this.state.last4SSN}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["SSN is required"]}
                    />
                  )}
                </div>
              </React.Fragment>
            )}

            {lookupType == 1 && (
              <React.Fragment>
                <div>Phone Number{reqLabel}: </div>
                <div>
                  <TextInput
                    type="tel"
                    size="15"
                    name="phoneNumber"
                    placeholder="Numbers only"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Phone Number is required"]}
                  />
                </div>
              </React.Fragment>
            )}
            {lookupType == 2 && (
              <React.Fragment>
                <div>Last Name{reqLabel}: </div>
                <div>
                  <TextInput
                    type="text"
                    size="20"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Last Name is required"]}
                  />
                </div>
                <div>Brith Date{reqLabel}: </div>
                <div className="generic-flex">
                  <div>
                    <select
                      name="birthMonth"
                      onChange={this.handleChange}
                      value={this.state.birthMonth}
                    >
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    &nbsp;&nbsp;
                  </div>

                  <TextInput
                    type="text"
                    size="5"
                    name="birthYear"
                    value={this.state.birthYear || ""}
                    placeholder="Year"
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Year is required"]}
                  />
                </div>
              </React.Fragment>
            )}
            {lookupType == 3 && (
              <React.Fragment>
                <div>Lease ID{reqLabel}:</div>
                <div>
                  <TextInput
                    size="15"
                    name="leaseId"
                    value={this.state.leaseId}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Lease ID is required"]}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="generic-flex">
            <button
              className={clsName}
              disabled={this.state.lookupBtnDisabled}
              type="submit"
              name="submit"
            >
              Lookup Lease Approval
            </button>
            {this.state.waitingForLeaseInfo && (
              <div className="left-10">
                <img src={getIconBaseUrl() + "working.gif"} height="30" />
              </div>
            )}
          </div>
        </ValidatorForm>
      </fieldset>
    );
  }
  render() {
    return (
      <div>
        {this.lookupForm()}
        {this.showLeaseInfo()}
        {this.showCancelButton()}
        {this.relatedOrders()}
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <DialogBox
          hidden={true}
          btnId="hdnDialogBoxBtn"
          buttonTitle="Hidden"
          buttonClsName="btn-style"
          title={this.state.dialogTitle}
          message={this.state.dialogMessage}
          hideCancel={true}
        />
      </div>
    );
  }
}
export default LeaseLookupWidget;
