import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import {
  apiBaseUrl,
  fullProductImageUrl,
  isUserLoggedIn,
  setTopContainerWrapperSettings,
  FormatCurrency,
  getIconBaseUrl,
  isMobileMode,
} from "../Util";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditProdAsmFee extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theList: null,
      grpList: null,
      expanded: [],
      updBtnEnabled: false,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };
    this.processResponse = this.processResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.updateOne = this.updateOne.bind(this);
    this.updateChanged = this.updateChanged.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    setTopContainerWrapperSettings();
    this.setState({ mobileMode: isMobileMode() });
  }
  fetchList() {
    const url = this.props.match.params.type
      ? apiBaseUrl() + "GetAsmFeeList"
      : apiBaseUrl() + "GetUnAsmFeeList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const theList = response.objList;
      let grpList = [];
      if (theList && theList.length > 0) {
        let i;
        let categoryName = theList[0].categoryName;
        let list = [];
        for (i = 0; i < theList.length; i++) {
          if (theList[i].categoryName !== categoryName) {
            const grp = {
              category: categoryName,
              list,
            };
            grpList.push(grp);
            list = [];
            categoryName = theList[i].categoryName;
          }
          list.push(theList[i]);
        }
        const grp = {
          category: categoryName,
          list,
        };
        grpList.push(grp);
      }
      let expanded = this.state.expanded;
      if (expanded.length !== grpList.length) {
        let i;
        expanded = [];
        if (grpList.length > 0) {
          for (i = 0; i < grpList.length; i++) {
            const flag = i === 0 ? true : false;
            expanded.push(flag);
          }
        }
      }
      this.setState({ theList, grpList, expanded, isLoading: false });
    }
  }

  updateOne(grpIdx, itemIdx) {
    const item = this.state.grpList[grpIdx].list[itemIdx];
    const url = apiBaseUrl() + "UpdateAsmFee";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: item,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updateChanged() {
    let changedList = [];
    let i, k;
    for (i = 0; i < this.state.grpList.length; i++) {
      for (k = 0; k < this.state.grpList[i].list.length; k++) {
        const item = this.state.grpList[i].list[k];
        if (item.changed) changedList.push(item);
      }
    }
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: changedList,
    };

    const url = apiBaseUrl() + "UpdateAsmFeeList";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleClick(idx) {
    let expanded = [];
    let i;
    for (i = 0; i < this.state.expanded.length; i++) {
      if (i === idx) expanded.push(!this.state.expanded[i]);
      else expanded.push(this.state.expanded[i]);
    }
    this.setState({ expanded });
  }
  showOneGroup(grpIdx) {
    const category = this.state.grpList[grpIdx].category;
    const list = this.state.grpList[grpIdx].list;
    const icon = this.state.expanded[grpIdx] ? "minus.jpg" : "plus.jpg";
    const assigned = this.props.match.params.type ? true : false;

    return (
      <div className="top-10" key={grpIdx}>
        <div className="generic-flex">
          <div>
            <img
              src={getIconBaseUrl() + icon}
              width="20"
              onClick={() => this.handleClick(grpIdx)}
              alt="PM"
            ></img>
          </div>
          <div className="left-10">
            <font size="4">
              {category} ({list.length})
            </font>
          </div>
        </div>

        {this.state.expanded[grpIdx] && (
          <div className="threecol-wrapper">
            {list.map((info, idx) => {
              const url = "/product/" + info.productId + "/" + info.sku;
              const imgUrl = fullProductImageUrl(info.imageUrl);
              const name = "" + grpIdx + "_" + idx;
              const btnName = "button" + name;
              const disabled = !this.state.editAllowed || !info.changed;
              let desc = info.name;
              if (desc && desc.length > 12)
                desc = desc.substring(0, 12) + "...";
              const label = this.state.mobileMode ? "" : "Price: ";
              const label2 = assigned ? "" : "Fee: ";
              return (
                <React.Fragment key={idx}>
                  <div>
                    <a href={url}>
                      <img src={imgUrl} width="80" height="60" alt="Img"></img>
                    </a>
                  </div>
                  <div className="left-10">
                    {info.sku}
                    <br />
                    {desc}
                  </div>
                  <div className="left-10">
                    {label}
                    <FormatCurrency amount={info.finalPrice} />
                    <br />
                    {label2}
                    <input
                      type="text"
                      size="4"
                      name={name}
                      value={info.assemblyFee}
                      onChange={this.handleChange}
                      disabled={!this.state.editAllowed}
                    ></input>
                    {assigned && (
                      <button
                        name={btnName}
                        disabled={disabled}
                        onClick={() => this.updateOne(grpIdx, idx)}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
    );
  }
  handleChange(event) {
    const name = event.target.name;
    const grpIdx = parseInt(name.substring(0, 1));
    const itemIdx = parseInt(name.substring(2));
    let i, k;
    let grpList = [];

    for (i = 0; i < this.state.grpList.length; i++) {
      if (i === grpIdx) {
        let list = [];
        for (k = 0; k < this.state.grpList[i].list.length; k++) {
          if (k === itemIdx) {
            const item = {
              ...this.state.grpList[i].list[k],
              assemblyFee: event.target.value,
              changed: true,
            };
            list.push(item);
          } else {
            list.push(this.state.grpList[i].list[k]);
          }
        }
        const grp = {
          ...this.state.grpList[i],
          list,
        };
        grpList.push(grp);
      } else {
        grpList.push(this.state.grpList[i]);
      }
    }
    this.setState({ grpList, updBtnEnabled: true });
  }

  theForm() {
    const assigned = this.props.match.params.type ? true : false;

    if (!this.state.theList || this.state.theList.length == 0) {
      const type = assigned ? "assigned" : "unassigned";
      return <div>No product with {type} assembly fee found.</div>;
    }

    const btnStyle = this.state.updBtnEnabled
      ? "btn-style"
      : "disabled-btn-style";
    return (
      <div className="form-container">
        {assigned ? (
          <div>
            <a href="/editAsmFee">View/Update Unassigned List</a>
          </div>
        ) : (
          <div>
            <button
              name="Update"
              className={btnStyle}
              disabled={!this.state.updBtnEnabled}
              onClick={this.updateChanged}
            >
              Update Assembly Fees
            </button>
            &nbsp;&nbsp;
            <a href="/editAsmFee/assigned">View/Update Assigned List</a>
          </div>
        )}
        <div>
          {this.state.grpList.map((grp, idx) => {
            return this.showOneGroup(idx);
          })}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const title = this.props.match.params.type
      ? "Update Product Assembly Fees"
      : "Add Product Assembly Fees";
    return (
      <div className="top-container">
        <div className="top-wrapper">
          <font size="5">{title}</font>
          <p />
          {this.theForm()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    categoryId: state.search.categoryId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProdAsmFee);
