import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "./config/constants";
import OrderSearchWidget from "./order/OrderSearchWidget";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toTimeStr,
  toAmount,
  fullProductImageUrl,
  setTopContainerWrapperSettings,
  isMobileMode,
  getIconBaseUrl,
  toDateStr,
} from "./Util";
import { checkView, checkSecurity } from "./SecManager";
import { setLoggedOut, setVersion } from "./actions/userActions";
import "../App.css";
import "../home.css";
import "../generic.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMode: false,
      newOrderBriefList: null,
      newPayments: null,
      newProducts: null,
      newNotifications: null,
      changeList: null,
      eventList: null,
      changeLogExpanded: false,
      isLoading: true,
      errorMessage: null,
      luExpanded: false,
      timer: null,
    };
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchWhatsNew = this.fetchWhatsNew.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.ackOrder = this.ackOrder.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.adjustMode();
      window.addEventListener("resize", this.adjustMode);
      this.fetchWhatsNew();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  processResponse(response, name) {
    if (name === "OrdersEx") {
      this.setState({ newOrderBriefList: response.objList, isLoading: false });
    } else if (name === "Payments") {
      this.setState({ newPayments: response.objList, isLoading: false });
    } else if (name === "Products") {
      this.setState({ newProducts: response.objList, isLoading: false });
    } else if (name === "Notifications") {
      this.setState({ newNotifications: response.objList, isLoading: false });
    } else if (name === "Changes") {
      this.setState({ changeList: response.objList, isLoading: false });
    } else if (name === "Events") {
      this.setState({ eventList: response.objList, isLoading: false });
    }
  }
  fetchWhatsNew() {
    this.fetchNew("OrdersEx");
    this.fetchNew("Events");
    this.fetchNew("Payments");
    this.fetchNew("Products");
    this.fetchNew("Notifications");
    this.fetchNew("Changes");
    const timer = setTimeout(this.fetchWhatsNew, 180000);
    this.setState({ timer });
  }
  fetchNew(name) {
    const url = apiBaseUrl() + "GetNew" + name;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.status) {
          this.checkAndSetVersion(res.data.version);
        }
        this.processResponse(res.data, name);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  checkAndSetVersion(version) {
    if (version != this.props.version) {
      this.props.setVersion(version);
      window.location.reload();
      console.log("Version set to " + version);
    }
  }
  getHomeItems() {
    const today = toDateStr(new Date());
    let items = [];

    if (checkView("Setting")) {
      items.push({
        url: "/editHomeImages",
        name: "Home<br/>Images",
        image: "homeImage.jpg",
      });
    }
    items.push({
      url: "/editLocations",
      name: "Store<br/>Locations",
      image: "storeLoc.jpg",
    });
    if (checkView("User")) {
      items.push({ url: "/manageUsers", name: "Users", image: "users.jpg" });
    }
    if (checkView("Setting")) {
      items.push({
        url: "/manageSales",
        name: "Sales<br/>Team",
        image: "salesTeam.jpg",
      });
    }
    if (checkView("Statistics")) {
      items.push({
        url: "/statistics",
        name: "Statistics",
        image: "statistics.jpg",
      });
    }
    if (checkView("Order")) {
      items.push({
        url: "/leasingOrders",
        name: "Leasing",
        image: "leasing2.jpg",
      });
      items.push({ url: "/listOrders", name: "Orders", image: "orders.jpg" });
    }
    items.push({
      url: "/listOrders/2",
      name: "Layaway",
      image: "layaway.jpg",
    });

    items.push({
      url: "/loaded",
      name: "Today's<br/>Delivery",
      image: "loaded3.jpg",
    });
    items.push({
      url: "/markComplete",
      name: "Mark<br/>Complete",
      image: "complete.jpg",
    });
    items.push({
      url: "/waitlistStats",
      name: "Waiting<br/>List",
      image: "waitlist2.jpg",
    });
    if (!this.state.mobileMode) {
      if (checkView("Payment")) {
        items.push({
          url: "/managePayments",
          name: "Payments",
          image: "payment2.jpg",
        });
      }
    }
    items.push({
      url: "/scheduled/" + today,
      name: "Delivery<br/>Map",
      image: "truck_map.jpg",
    });
    if (this.state.mobileMode) {
      if (checkView("Payment")) {
        items.push({
          url: "/managePayments",
          name: "Payments",
          image: "payment2.jpg",
        });
      }
    }
    if (checkView("Fee")) {
      items.push({
        url: "/editDeliveryFee",
        name: "Delivery<br/>Fee",
        image: "deliveryFee2.jpg",
      });
    }
    if (checkView("Setting")) {
      items.push({
        url: "/editAds",
        name: "Advertise",
        image: "advertisement.jpg",
      });
      items.push({
        url: "/layawaySetting",
        name: "Layaway<br/>Setting",
        image: "layaway-setting2.jpg",
      });
    }
    items.push({
      url: "/manageInventory",
      name: "Inventory",
      image: "inventory.jpg",
    });

    if (checkView("Fee")) {
      items.push({
        url: "/editAssembly",
        name: "Assembly<br/>Fee",
        image: "assemblyFee2.jpg",
      });
    }
    if (checkView("Setting")) {
      items.push({
        url: "/editSpecials",
        name: "Specials",
        image: "specials.jpg",
      });
      items.push({
        url: "/editHomepageProd",
        name: "Homepage<br/>Products",
        image: "homepage_prod.jpg",
      });
      items.push({
        url: "/editCategory",
        name: "Categories",
        image: "Category.jpg",
      });
      items.push({
        url: "/editSupplier",
        name: "Suppliers",
        image: "Supplier.jpg",
      });
      items.push({ url: "/editLogos", name: "Logos", image: "logo.jpg" });
      items.push({
        url: "/specialMenu",
        name: "Special<br/>Menu",
        image: "SpMenu2.jpg",
      });
      items.push({ url: "/editStates", name: "States", image: "States.jpg" });
      items.push({ url: "/editFaq", name: "FAQ", image: "Faq.jpg" });
    }
    if (checkView("Product")) {
      const name = this.state.mobileMode ? "Store Prod" : "Store Product";
      items.push({ url: "/manageStoreProd", name, image: "storeProd.jpg" });
    }
    if (checkView("Setting")) {
      items.push({
        url: "/editFtpSetting",
        name: "FTP<br/>Setting",
        image: "ftp2.jpg",
      });
      items.push({
        url: "/progressiveSetting",
        name: "Progressive<br/>Setting",
        image: "prog-web-05-Prog-Mark-Icon-Bl02.jpg",
      });
    }
    if (checkSecurity("RootFeatures")) {
      items.push({
        url: "/makePayment",
        name: "Make<br/>Payment",
        image: "ccpayment.jpg",
      });
    }
    items.push({
      url: "/salesStats",
      name: "Best<br/>Seller",
      image: "bestSeller.jpg",
    });
    items.push({
      url: "/priceHistory",
      name: "Price<br/>History",
      image: "priceHistory.jpg",
    });
    if (checkView("Setting")) {
      items.push({
        url: "/manageFlyers",
        name: "Manage<br/>Flyers",
        image: "flyer.jpg",
      });
    }
    items.push({
      url: "/managePromo",
      name: "Manage<br/>Promotion",
      image: "Promotion.jpg",
    });
    return items;
  }
  ackOrder(theIdx) {
    const order = this.state.newOrderBriefList[theIdx];
    const url = apiBaseUrl() + "AckOrder";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: order.orderNumber,
      flag: order.layaway,
    };

    const newOrderBriefList = this.state.newOrderBriefList.filter(
      (ord, idx) => idx != theIdx
    );
    this.setState({ newOrderBriefList });

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        //ignore
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showOrdersEx() {
    const list = this.state.newOrderBriefList;
    if (list && list.length > 0) {
      const clsName = this.state.mobileMode
        ? "home-sixcol-wrapper"
        : "home-7col-wrapper";
      return (
        <div>
          <p />
          <b>Orders & Layaways:</b>
          <br />
          <div className={clsName}>
            {list.map((ord, idx) => {
              let name = null;

              if (this.state.mobileMode) {
                if (ord.firstName) {
                  name =
                    ord.firstName.substring(0, 1) +
                    ord.lastName.substring(0, 1);
                }
              } else {
                name = ord.firstName + " " + ord.lastName;
                if (name.length > 16) name = name.substring(0, 16);
              }
              let storeName = ord.storeName;
              const url = ord.layaway
                ? "/layawayDetail/" + ord.orderNumber
                : "/orderDetail/" + ord.orderNumber;
              let img = null;
              let amtStr = "$" + ord.totalPrice;
              try {
                amtStr = toAmount(ord.totalPrice);
              } catch (error) {}
              if (this.state.mobileMode && storeName)
                storeName = storeName.substring(0, 1);

              if (ord.statusIcon) {
                img = getIconBaseUrl() + ord.statusIcon;
              } else {
                img = getIconBaseUrl() + "checkmark.png";
              }
              const dateStr = this.state.mobileMode
                ? ord.shortDateStr
                : ord.dateStr;
              return (
                <React.Fragment key={idx}>
                  <div>
                    <img src={img} width="13" alt="Order"></img>
                  </div>
                  <div>
                    <Link to={url}>{ord.orderNumber}</Link>&nbsp;
                    <button
                      className="tiny-btn"
                      name="ack"
                      onClick={() => this.ackOrder(idx)}
                    >
                      Ack
                    </button>
                    &nbsp;
                  </div>
                  <div>{dateStr}&nbsp;&nbsp;</div>
                  <div>{storeName}&nbsp;</div>
                  <div>{name} &nbsp;</div>
                  {!this.state.mobileMode && <div>{ord.phone} &nbsp;</div>}
                  <div align="right">{amtStr}</div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    }
  }
  showPayments() {
    if (
      !this.state.mobileMode &&
      this.state.newPayments &&
      this.state.newPayments.length > 0
    ) {
      return (
        <div>
          <p />
          <b>Payments:</b>
          <br />
          <div className="fivecol-wrapper">
            {this.state.newPayments.map((pmt, idx) => {
              const url = "/paymentDetail/" + pmt.info.id;
              const img = getIconBaseUrl() + "checkmark.png";
              const amount = toAmount(pmt.info.amount);
              return (
                <React.Fragment key={idx}>
                  <div>
                    <img src={img} width="12" alt="IMG"></img>
                  </div>
                  <div>
                    <Link to={url}>{pmt.info.paymentName}</Link>&nbsp;&nbsp;
                  </div>
                  <div>{pmt.info.dateStr}&nbsp;&nbsp;</div>
                  <div>{pmt.info.orderNumber}&nbsp;&nbsp;</div>
                  {pmt.info.amount < 0 ? (
                    <div>
                      <font color="red">{amount}</font>
                    </div>
                  ) : (
                    <div> {amount}</div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    }
  }
  showEvents() {
    if (this.state.eventList && this.state.eventList.length > 0) {
      return (
        <div>
          <p />
          <b>New Events:</b>
          <br />
          <div className="col4-wrapper">
            {this.state.eventList.map((evt, idx) => {
              let title;
              if (this.state.mobileMode) {
                title =
                  evt.title && evt.title.length > 20
                    ? evt.title.substring(0, 20)
                    : evt.title;
              } else {
                const byName = evt.byCustomer
                  ? "by Customer"
                  : "by " + evt.userId;
                title = (
                  <span>
                    {evt.title}&nbsp;<i>{byName}</i>
                  </span>
                );
              }

              let url = null;
              if (evt.orderNumber) {
                if (evt.typeId == 2) url = "/layawayDetail/" + evt.orderNumber;
                else url = "/orderDetail/" + evt.orderNumber;
              }

              const img = evt.eventType
                ? getIconBaseUrl() + evt.eventType.icon
                : null;
              return (
                <React.Fragment key={idx}>
                  <div>{img && <img src={img} width="12" alt="IMG"></img>}</div>
                  <div>
                    {url && <Link to={url}>{evt.orderNumber}</Link>}&nbsp;&nbsp;
                  </div>
                  <div>{evt.tsStr}&nbsp;&nbsp;</div>
                  <div>{title}</div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    }
  }
  showNotifications() {
    if (this.state.newNotifications && this.state.newNotifications.length > 0) {
      return (
        <div>
          <p />
          <b>Notifications:</b>
          <br />
          <ul>
            {this.state.newNotifications.map((noti, idx) => {
              const url = "/notiDetail/" + noti.id;
              return (
                <li key={idx}>
                  {noti.typeName}&nbsp;&nbsp;
                  {toTimeStr(noti.timestamp)}&nbsp;&nbsp;
                  <Link to={url}>{noti.toEmail}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }
  showProducts() {
    const newProducts = this.state.newProducts;
    if (newProducts && newProducts.length > 0) {
      return (
        <div>
          <p />
          <b>Newly Added Products:</b>
          <br />
          <ul>
            {newProducts.map((prod) => {
              const url = "/product/" + prod.id;
              const imgUrl = fullProductImageUrl(prod.imageUrl);
              return (
                <li>
                  <Link to={url}>
                    <img
                      src={imgUrl}
                      alt={prod.id}
                      width="75"
                      height="55"
                    ></img>
                  </Link>
                  &nbsp;&nbsp;
                  {prod.pvList[0].sku} {prod.name}&nbsp;&nbsp;
                  {toAmount(prod.pvList[0].salePrice)}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }
  showWhatsNew() {
    if (!this.state.isLoading) {
      const oc = this.state.newOrderBriefList
        ? this.state.newOrderBriefList.length
        : 0;
      const pmtc = this.state.newPayments ? this.state.newPayments.length : 0;
      const nc = this.state.newNotifications
        ? this.state.newNotifications.length
        : 0;
      const prdc = this.state.newProducts ? this.state.newProducts.length : 0;
      const totalCnt = oc + pmtc + nc + prdc;

      if (totalCnt > 0) {
        return (
          <div className="generic-wrapper">
            <font size="5">What's New</font>
            <br />
            {this.showOrdersEx()}
            {this.showEvents()}
            {this.showPayments()}
            {this.showNotifications()}
            {this.showProducts()}
          </div>
        );
      }
    }
  }
  showChangeLogs() {
    if (checkView("Setting")) {
      const changeList = this.state.changeList;
      if (changeList && changeList.length > 0) {
        const startIdx = changeList[0].toUpperCase().startsWith("TITLE=")
          ? 1
          : 0;
        const title = startIdx > 0 ? changeList[0].substring(6) : "CHANGE LOGS";
        const icon = this.state.changeLogExpanded ? "minus.jpg" : "plus.jpg";

        return (
          <div>
            <div className="home-log-title">
              <img
                src={getIconBaseUrl() + icon}
                width="16"
                onClick={this.handleClick2}
              ></img>{" "}
              {title}
            </div>
            {this.state.changeLogExpanded && (
              <div className="home-log-item">
                <ul>
                  {changeList.map((item, idx) => {
                    if (startIdx === 0 || idx > 0) {
                      return <li key={idx}>{item}</li>;
                    }
                  })}
                </ul>
              </div>
            )}
          </div>
        );
      }
    }
  }
  showHomeItems(homeItems) {
    const size = this.state.mobileMode ? 40 : 60;
    return (
      <div className="homeitems-wrapper">
        {homeItems.map((item, idx) => {
          const img = getIconBaseUrl() + item.image;
          return (
            <div className="generic-flex" key={idx}>
              <div>
                <Link to={item.url}>
                  <img src={img} width={size} height={size}></img>
                </Link>
              </div>
              <div className="home-item">
                <Link to={item.url}>
                  <div dangerouslySetInnerHTML={{ __html: item.name }}></div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  handleClick(event) {
    this.setState({ luExpanded: !this.state.luExpanded });
  }
  handleClick2(event) {
    this.setState({ changeLogExpanded: !this.state.changeLogExpanded });
  }
  showAllHomeItems() {
    const homeItems = this.getHomeItems();

    if (homeItems.length <= 10) {
      return this.showHomeItems(homeItems);
    } else {
      const icon = this.state.luExpanded ? "minus.jpg" : "plus.jpg";
      const title = this.state.luExpanded ? "Less .." : "More .....";
      let clsName = "none";
      if (!this.state.luExpanded) {
        if (!this.state.mobileMode) clsName = "left20-b25";
        else clsName = "bottom25";
      } else if (!this.state.mobileMode) clsName = "left-20";

      let mainItems = [];
      let luItems = [];
      const mainMax = this.state.mobileMode ? 9 : 10;
      for (let i = 0; i < homeItems.length; i++) {
        if (i < mainMax) mainItems.push(homeItems[i]);
        else luItems.push(homeItems[i]);
      }
      return (
        <React.Fragment>
          {this.showHomeItems(mainItems)}
          <div className={clsName}>
            <img
              src={getIconBaseUrl() + icon}
              width="16"
              onClick={this.handleClick}
            ></img>{" "}
            {title}
          </div>
          {this.state.luExpanded && this.showHomeItems(luItems)}
        </React.Fragment>
      );
    }
  }
  showQRCodes() {
    return (
      <div className="flex-wrapper-top20">
        <div>
          <img
            src={getIconBaseUrl() + "qrcode_admin.png"}
            alt="QRCode"
            width="120"
          />
        </div>
        <div className="left-20">
          <img
            src={getIconBaseUrl() + "qrcode_furniturewayless.png"}
            alt="QRCode"
            width="120"
          />
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="top-wrapper">
        {this.showAllHomeItems()}
        <OrderSearchWidget searchType={3} />
        {this.showWhatsNew()}
        {this.showChangeLogs()}
        {this.showQRCodes()}
        <div>
          <p />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    version: state.user.version,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
    setVersion: (version) => {
      dispatch(setVersion(version));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
