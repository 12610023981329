import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toAmount,
  getIconBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import OrderSearchWidget from "../order/OrderSearchWidget";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class ManageLayaways extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allLayaways: [],
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      layawayType: 0,
      layawayFldsLists: [null, null, null],
      sortOrder: [-1, -1, -1],
      listTitles: ["Open Layaways", "Closed Layaways", "All Layaways"],
      editAllowed: checkUpdate("Order"),
      viewAllowed: checkView("Order"),
      viewSettingAllowed: checkView("Setting"),
    };
    this.fetchLayaways = this.fetchLayaways.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchLayaways();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  getCustomerName(layaway) {
    if (layaway.customer) {
      return layaway.customer.firstName + " " + layaway.customer.lastName;
    }
    return "";
  }
  toFieldsArray(layaway) {
    const name = layaway.customer
      ? layaway.customer.firstName + " " + layaway.customer.lastName
      : "";
    const phone = layaway.customer ? layaway.customer.phone : "";
    const fld8 =
      layaway.status && layaway.status.code > 4
        ? "N/A"
        : layaway.nextPmtDateStr;
    const fields = [
      layaway.statusId,
      layaway.orderNumber,
      layaway.dateOrderedStr,
      name,
      layaway.totalPrice,
      layaway.depositAmount,
      layaway.percentPaid,
      fld8,
      phone,
      layaway.status,
    ];
    return fields;
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    const allLayaways = response.objList;
    let layawayFldsLists = [[], [], []];
    if (allLayaways && allLayaways.length > 0) {
      for (let i = 0; i < allLayaways.length; i++) {
        const fa = this.toFieldsArray(allLayaways[i]);
        if (allLayaways[i].statusId < 7) {
          layawayFldsLists[0].push(fa);
        } else {
          layawayFldsLists[1].push(fa);
        }
        layawayFldsLists[2].push(fa);
      }
      this.sortList(6, layawayFldsLists[0], 1); //Sort open layaways by percent in descending order
      this.sortList(1, layawayFldsLists[1], 0); //Sort closed layaways by order number in ascending order
    }
    const sortOrder = [1, 0, 0];
    this.setState({
      allLayaways,
      layawayFldsLists,
      sortOrder,
      isLoading: false,
    });
  }
  fetchLayaways() {
    const url = apiBaseUrl() + "GetAllLayaways";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  oneRow(fields, idx) {
    const clsName = idx % 2 === 0 ? "la-item-ev" : "la-item-od";
    const rClsName = idx % 2 === 0 ? "la-item-evr" : "la-item-odr";
    const imgUrl = fields[9] ? fields[9].icon : null;
    const color = fields[9] ? fields[9].color : null;
    const detailUrl = "/layawayDetail/" + fields[1];

    return (
      <React.Fragment key={idx}>
        {imgUrl ? (
          <div className={clsName}>
            <img src={getIconBaseUrl() + imgUrl} width={30} alt="Status"></img>
          </div>
        ) : (
          <div className={clsName}>{fields[0]}</div>
        )}
        <div className={clsName}>
          <a href={detailUrl}>{fields[1]}</a>
        </div>
        {color ? (
          <React.Fragment>
            <div className={clsName}>
              <font color={color}>{fields[2]}</font>
            </div>
            <div className={clsName}>
              <font color={color}>{fields[3]}</font>
            </div>
            <div className={rClsName}>
              <font color={color}>{toAmount(fields[4])}</font>
            </div>
            <div className={rClsName}>
              <font color={color}>{toAmount(fields[5])}</font>
            </div>
            <div className={rClsName}>
              <font color={color}>{fields[6].toFixed(2)}%</font>
            </div>
            <div className={clsName}>
              <font color={color}>{fields[7]}</font>
            </div>
            <div className={clsName}>
              <font color={color}>{fields[8]}</font>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={clsName}>{fields[2]}</div>
            <div className={clsName}>{fields[3]}</div>
            <div className={rClsName}>{toAmount(fields[4])}</div>
            <div className={rClsName}>{toAmount(fields[5])}</div>
            <div className={rClsName}>{fields[6].toFixed(2)}%</div>
            <div className={clsName}>{fields[7]}</div>
            <div className={clsName}>{fields[8]}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  handleClick(colIdx) {
    let fieldsList = this.state.layawayFldsLists[this.state.layawayType];

    if (fieldsList && fieldsList.length > 1) {
      const order = this.state.sortOrder[this.state.layawayType] === 0 ? 1 : 0;

      fieldsList = this.sortList(colIdx, fieldsList, order);

      this.state.layawayFldsLists[this.state.layawayType] = fieldsList;
      this.state.sortOrder[this.state.layawayType] = order;
    }
  }
  sortList(colIdx, fieldsList, order) {
    if (fieldsList && fieldsList.length > 1) {
      fieldsList = fieldsList.sort((a, b) => {
        if (colIdx >= 4 && colIdx <= 6) {
          if (order === 0) {
            return a[colIdx] - b[colIdx];
          } else {
            return b[colIdx] - a[colIdx];
          }
        } else {
          let val = 0;
          if (a[colIdx] > b[colIdx]) val = 1;
          else if (a[colIdx] < b[colIdx]) val = -1;

          if (order === 1) val = 0 - val;
          return val;
        }
      });
    }
    return fieldsList;
  }
  layawayList() {
    const fieldsList = this.state.layawayFldsLists[this.state.layawayType];
    const title = this.state.listTitles[this.state.layawayType];
    const nLabel = this.state.mobileMode ? "NM" : "Name";
    return (
      <div>
        <div className="left-8b8">
          <font size="4">{title}</font>
        </div>
        {fieldsList && fieldsList.length > 0 ? (
          <div className="la-table-c9">
            <div className="la-list-hds">
              <a href="#" onClick={() => this.handleClick(0)}>
                <b>S</b>
              </a>
            </div>
            <div className="la-list-hd">
              <a href="#" onClick={() => this.handleClick(1)}>
                Layaway#
              </a>
            </div>
            <div className="la-list-hd">
              <a href="#" onClick={() => this.handleClick(2)}>
                Time
              </a>
            </div>
            <div className="la-list-hd">
              <a href="#" onClick={() => this.handleClick(3)}>
                {nLabel}
              </a>
            </div>
            <div className="la-list-hdr">
              <a href="#" onClick={() => this.handleClick(4)}>
                Total
              </a>
            </div>
            <div className="la-list-hdr">
              <a href="#" onClick={() => this.handleClick(5)}>
                Deposit
              </a>
            </div>
            <div className="la-list-hdr">
              <a href="#" onClick={() => this.handleClick(6)}>
                Percent
              </a>
            </div>
            <div className="la-list-hd">
              <a href="#" onClick={() => this.handleClick(7)}>
                Next Due
              </a>
            </div>
            <div className="la-list-hd">
              <a href="#" onClick={() => this.handleClick(8)}>
                Phone
              </a>
            </div>
            {fieldsList.map((fields, idx) => {
              return this.oneRow(fields, idx);
            })}
          </div>
        ) : (
          <div>No layaway found</div>
        )}
      </div>
    );
  }
  handleChange(event) {
    if (event.target.name === "layawayType") {
      this.setState({ layawayType: parseInt(event.target.value) });
    }
  }
  layawayTypes() {
    return (
      <div className="flex-wrapper">
        <div>
          <input
            type="radio"
            name="layawayType"
            value={0}
            checked={this.state.layawayType === 0}
            onChange={this.handleChange}
          ></input>{" "}
          {this.state.listTitles[0]}
        </div>
        <div className="left-10">
          <input
            type="radio"
            name="layawayType"
            value={1}
            checked={this.state.layawayType === 1}
            onChange={this.handleChange}
          ></input>{" "}
          {this.state.listTitles[1]}
        </div>
        <div className="left-10">
          <input
            type="radio"
            name="layawayType"
            value={2}
            checked={this.state.layawayType === 2}
            onChange={this.handleChange}
          ></input>{" "}
          {this.state.listTitles[2]}
        </div>
      </div>
    );
  }
  render() {
    if (this.state.isLoading)
      return <div className="left-20">Please wait ...</div>;

    const hasLayaways =
      this.state.allLayaways && this.state.allLayaways.length > 0
        ? true
        : false;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div className="title-flex">
            <div>
              <font size="5">Layaways</font>
            </div>
            {this.state.viewSettingAllowed && (
              <div>
                <img src={getIconBaseUrl() + "Setting.jpg"} height="14"></img>
                &nbsp;
                <Link to="/layawaySetting">Settings</Link>
              </div>
            )}
          </div>
          {hasLayaways ? (
            <div>
              <OrderSearchWidget searchType={2} />
              <p />
              {this.layawayTypes()}
              <br />
              {this.layawayList()}
            </div>
          ) : (
            <div>No layaways were found.</div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageLayaways);
