import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  getIconBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../setting.css";

class EditPaymentSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      addNewErr: false,
      errName: null,
      errFieldName: null,
      errFieldValue: null,
      errMessage: null,
      viewAllowed: checkView("Setting"),
      editPmtAllowed: checkUpdate("PaymentProcessor"),
      viewPmtAllowed: checkView("PaymentProcessor"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.addErrMsg = this.addErrMsg.bind(this);
    this.deleteErrMsg = this.deleteErrMsg.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetPaymentSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    let errFieldName = "";
    if (
      response.data &&
      response.data.fieldNames &&
      response.data.fieldNames.length > 0
    ) {
      errFieldName = response.data.fieldNames[0];
    }
    this.setState({ settings: response.data, errFieldName, isLoading: false });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let useBillingAddress = this.state.settings.useBillingAddress;

    if (name.startsWith("err")) {
      this.setState({ [event.target.name]: event.target.value });
      return;
    } else if (name.startsWith("CB_")) {
      name = name.substring(3);
      value = event.target.checked;
      if (name === "billingAddrRequired" && event.target.checked) {
        document.getElementById("useBillingAddress").click();
        useBillingAddress = true;
      }
    }

    if (name.startsWith("PAC_")) {
      name = name.substring(4);
      const pos = name.indexOf("_");
      const theIdx = parseInt(name.substring(0, pos));
      name = name.substring(pos + 1);

      const amountChoiceList = this.state.settings.amountChoiceList.map(
        (choice, idx) => {
          if (idx === theIdx) {
            const newChoice = {
              ...choice,
              [name]: value,
            };
            return newChoice;
          } else return choice;
        }
      );
      const settings = {
        ...this.state.settings,
        amountChoiceList,
      };
      this.setState({ settings, updateBtnDisabled: false });
    } else if (name.startsWith("PMTERR_")) {
      name = name.substring(7);
      const pos = name.indexOf("_");
      const idx = parseInt(name.substring(0, pos));
      let pmtErrList = [];
      let settings = this.state.settings;

      name = name.substring(pos + 1);
      for (let i = 0; i < settings.pmtErrList.length; i++) {
        if (i === idx) {
          const pe = {
            ...settings.pmtErrList[i],
            [name]: value,
          };
          pmtErrList.push(pe);
        } else {
          pmtErrList.push(settings.pmtErrList[i]);
        }
      }
      settings = {
        ...this.state.settings,
        pmtErrList,
        pmtSettingUpdated: true,
      };
      this.setState({
        settings,
        updateBtnDisabled: false,
      });
    } else {
      const settings = {
        ...this.state.settings,
        [name]: value,
        useBillingAddress,
      };
      this.setState({
        settings,
        updateBtnDisabled: false,
      });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdatePaymentSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.settings,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleClick(type, idx) {
    if (type === 1) {
      const choices = this.state.settings.amountChoiceList;
      const amountChoiceList = [];

      for (let i = 0; i < choices.length; i++) {
        if (i === idx) {
          const nc = {
            ...choices[i],
            expanded: !choices[i].expanded,
          };
          amountChoiceList.push(nc);
        } else amountChoiceList.push(choices[i]);
      }
      const settings = {
        ...this.state.settings,
        amountChoiceList,
      };
      this.setState({ settings });
    } else {
      if (idx < 0) {
        this.setState({ addNewErr: true });
      } else {
        const list = this.state.settings.pmtErrList;
        const pmtErrList = [];

        for (let i = 0; i < list.length; i++) {
          if (i === idx) {
            const ne = {
              ...list[i],
              expanded: !list[i].expanded,
            };
            pmtErrList.push(ne);
          } else pmtErrList.push(list[i]);
        }
        const settings = {
          ...this.state.settings,
          pmtErrList,
        };
        this.setState({ settings });
      }
    }
  }
  displayOneChoice(choice, idx) {
    const icon = choice.expanded ? "minus.jpg" : "plus.jpg";

    if (!choice.expanded) {
      return (
        <React.Fragment>
          <div className="form-item-label">
            <img
              src={getIconBaseUrl() + icon}
              width="16"
              onClick={() => this.handleClick(1, idx)}
              alt="PM"
            ></img>
            <i>{choice.name}</i>
          </div>
          <div className="form-item-value">{choice.displayName}</div>
        </React.Fragment>
      );
    } else {
      const colCnt = this.state.mobileMode ? 38 : 60;
      const rowCnt = this.state.mobileMode ? 10 : 8;
      const displayName = "PAC_" + idx + "_displayName";
      const delivery = "CB_PAC_" + idx + "_delivery";
      const pickup = "CB_PAC_" + idx + "_pickup";
      const message = "PAC_" + idx + "_paymentMessage";
      const afterPmtMessage = "PAC_" + idx + "_afterPmtMessage";
      const afterPmtTitle = "PAC_" + idx + "_afterPmtTitle";
      const sequence = "PAC_" + idx + "_sequence";
      const display = this.state.mobileMode ? "Display" : "Display Name";
      return (
        <React.Fragment>
          <div className="form-item-label">
            <img
              src={getIconBaseUrl() + icon}
              width="16"
              onClick={() => this.handleClick(1, idx)}
              alt="PM"
            ></img>
            <i>{choice.name}</i>
          </div>
          <div className="form-item-value">
            <div className="pmt-twocol-table">
              <div className="form-item-label">{display}: </div>
              <div className="form-item-value">
                <input
                  name={displayName}
                  type="text"
                  size="20"
                  onChange={this.handleChange}
                  value={choice.displayName}
                  disabled={!this.state.editPmtAllowed}
                ></input>
              </div>
              <div className="form-item-label">Enabled: </div>
              <div className="form-item-value">
                <input
                  name={delivery}
                  type="checkbox"
                  onChange={this.handleChange}
                  defaultChecked={choice.delivery}
                  disabled={!this.state.editPmtAllowed}
                ></input>
                Delivery
                {this.state.mobileMode ? (
                  <br></br>
                ) : (
                  <label>&nbsp;&nbsp;&nbsp;</label>
                )}
                <input
                  name={pickup}
                  type="checkbox"
                  onChange={this.handleChange}
                  defaultChecked={choice.pickup}
                  disabled={!this.state.editPmtAllowed}
                ></input>
                Pickup
              </div>
              <div className="form-item-label">Sequence: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  size="4"
                  name={sequence}
                  value={choice.sequence}
                  disabled={!this.state.editPmtAllowed}
                  onChange={this.handleChange}
                ></input>
              </div>
              <div className="form-item-label">After Title: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  size="20"
                  name={afterPmtTitle}
                  value={choice.afterPmtTitle}
                  disabled={!this.state.editPmtAllowed}
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <b>Payment Message:</b>
            <br />
            <textarea
              name={message}
              rows={rowCnt}
              cols={colCnt}
              disabled={!this.state.editPmtAllowed}
              value={choice.paymentMessage}
              onChange={this.handleChange}
            ></textarea>
            <br />
            <b>After Payment Message:</b>
            <br />
            <textarea
              name={afterPmtMessage}
              rows={rowCnt}
              cols={colCnt}
              disabled={!this.state.editPmtAllowed}
              value={choice.afterPmtMessage}
              onChange={this.handleChange}
            ></textarea>
          </div>
        </React.Fragment>
      );
    }
  }
  pmtAmountChoices() {
    const choices = this.state.settings.amountChoiceList;
    if (!choices || choices.length === 0) return null;

    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";
    return (
      <React.Fragment>
        <div className="form-item-label">
          <b>Amount Choices</b>
        </div>
        <div className="form-item-value">
          <hr className={hrClsName} />
        </div>
        {choices.map((choice, idx) => {
          return this.displayOneChoice(choice, idx);
        })}
      </React.Fragment>
    );
  }
  deleteErrMsg(idx) {
    const list = this.state.settings.pmtErrList;
    const pmtErrList = [];

    for (let i = 0; i < list.length; i++) {
      if (i === idx) {
        if (list[i].id > 0) {
          list[i].deleted = true;
          pmtErrList.push(list[i]);
        }
      } else pmtErrList.push(list[i]);
    }
    const settings = {
      ...this.state.settings,
      pmtErrList,
    };
    this.setState({ settings, updateBtnDisabled: false });
  }
  showOnePmtErr(pmtErr, idx) {
    if (!pmtErr.deleted) {
      const icon = pmtErr.expanded ? "minus.jpg" : "plus.jpg";
      const colCnt = this.state.mobileMode ? 38 : 60;
      const rowCnt = this.state.mobileMode ? 8 : 5;
      const brief =
        pmtErr.message.length > 20
          ? pmtErr.message.substring(0, 20) + "..."
          : pmtErr.message;
      const taName = "PMTERR_" + idx + "_message";
      const fn = "PMTERR_" + idx + "_fieldName";
      const fv = "PMTERR_" + idx + "_fieldValue";
      const delBtnStyle = this.state.editPmtAllowed
        ? "bt-red-btn-style"
        : "disabled-btn-style";
      return (
        <React.Fragment key={idx}>
          <div className="form-item-label">
            <img
              src={getIconBaseUrl() + icon}
              width="16"
              onClick={() => this.handleClick(2, idx)}
              alt="PM"
            ></img>
            <i>{pmtErr.name}</i>
          </div>
          <div className="form-item-value">
            {pmtErr.expanded ? (
              <React.Fragment>
                <textarea
                  name={taName}
                  value={pmtErr.message}
                  rows={rowCnt}
                  cols={colCnt}
                  onChange={this.handleChange}
                  disabled={!this.state.editPmtAllowed}
                ></textarea>
                {pmtErr.custom && (
                  <React.Fragment>
                    <br />
                    <select
                      name={fn}
                      value={pmtErr.fieldName}
                      onChange={this.handleChange}
                    >
                      {this.state.settings.fieldNames.map((name) => {
                        return <option value={name}>{name}</option>;
                      })}
                    </select>
                    &nbsp;
                    <input
                      name={fv}
                      value={pmtErr.fieldValue}
                      onChange={this.handleChange}
                    ></input>
                    <br />
                    <button
                      className={delBtnStyle}
                      name="delete"
                      onClick={() => this.deleteErrMsg(idx)}
                      disabled={!this.state.editPmtAllowed}
                    >
                      Delete
                    </button>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <label>{brief}</label>
            )}
          </div>
        </React.Fragment>
      );
    }
  }
  addErrMsg(event) {
    event.preventDefault();

    const pmtErr = {
      id: 0,
      name: this.state.errName,
      custom: true,
      fieldName: this.state.errFieldName,
      fieldValue: this.state.errFieldValue,
      message: this.state.errMessage,
    };
    const pmtErrList = this.state.settings.pmtErrList;
    pmtErrList.push(pmtErr);
    const settings = {
      ...this.state.settings,
      pmtErrList,
    };
    this.setState({
      settings,
      addNewErr: false,
      errName: null,
      errFieldValue: null,
      errMessage: null,
      updateBtnDisabled: false,
    });
  }

  addNewErrForm() {
    if (this.state.addNewErr) {
      const colCnt = this.state.mobileMode ? 38 : 60;
      const rowCnt = this.state.mobileMode ? 8 : 5;
      return (
        <div>
          Name:{" "}
          <input
            name="errName"
            value={this.state.errName || ""}
            onChange={this.handleChange}
          ></input>
          <br />
          Field Name:&nbsp;
          <select
            name="errFieldName"
            value={this.state.errFieldName}
            onChange={this.handleChange}
          >
            {this.state.settings.fieldNames.map((name) => {
              return <option value={name}>{name}</option>;
            })}
          </select>
          <br />
          Field Value:&nbsp;
          <input
            name="errFieldValue"
            value={this.state.errFieldValue || ""}
            onChange={this.handleChange}
          ></input>
          <br />
          Error Message:
          <br />
          <textarea
            name="errMessage"
            value={this.state.errMessage || ""}
            rows={rowCnt}
            cols={colCnt}
            onChange={this.handleChange}
            disabled={!this.state.editPmtAllowed}
          ></textarea>
          <br />
          <button name="addErr" onClick={this.addErrMsg}>
            Add
          </button>
        </div>
      );
    }
  }
  paymentErrorMessages() {
    const settings = this.state.settings;
    const visible = settings.selectedProcessor !== "Manual";
    const visible2 =
      visible && settings.pmtErrList && settings.pmtErrList.length > 0;

    if (visible2) {
      const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";

      return (
        <React.Fragment>
          <div className="form-item-label">
            <b>Error Messages:</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          {settings.pmtErrList.map((pe, idx) => {
            return this.showOnePmtErr(pe, idx);
          })}
          {this.state.editPmtAllowed && (
            <div className="form-item-label">
              <img
                src={getIconBaseUrl() + "plus.jpg"}
                width="16"
                onClick={() => this.handleClick(2, -1)}
                alt="PM"
              ></img>
              <font color="blue">Add Custom</font>
            </div>
          )}
          <div className="form-item-value">{this.addNewErrForm()}</div>
        </React.Fragment>
      );
    }
  }
  theForm() {
    const settings = this.state.settings;
    const updDisabled =
      this.state.updateBtnDisabled || !this.state.editPmtAllowed;
    const btnStyle = updDisabled ? "disabled-btn-style" : "btn-style";
    const visible = settings.selectedProcessor !== "Manual";
    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";
    return (
      <div className="generic-wrapper">
        <font size="5">Payment Settings</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">
            <b>Credit Card</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Payment: </div>
          <div className="form-item-value">
            <input
              id="useBillingAddress"
              type="checkbox"
              name="CB_useBillingAddress"
              defaultChecked={settings.useBillingAddress}
              onChange={this.handleChange}
              disabled={!this.state.editPmtAllowed}
            ></input>{" "}
            Use Billing Address
            <br />
            <input
              type="checkbox"
              name="CB_billingAddrRequired"
              defaultChecked={settings.billingAddrRequired}
              onChange={this.handleChange}
              disabled={!this.state.editPmtAllowed}
            ></input>{" "}
            Billing Address Required
            <br />
            <input
              type="checkbox"
              name="CB_validateCCNumber"
              defaultChecked={settings.validateCCNumber}
              onChange={this.handleChange}
              disabled={!this.state.editPmtAllowed}
            ></input>{" "}
            Validate Card Number
          </div>
          {this.pmtAmountChoices()}
          <div className="form-item-label">
            <b>Processor</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">
            Payment
            <br />
            Processor:{" "}
          </div>
          <div className="form-item-value">
            {settings.processorNames.map((name) => {
              return (
                <div key={name}>
                  <input
                    type="radio"
                    name="selectedProcessor"
                    value={name}
                    checked={name === settings.selectedProcessor}
                    disabled={!this.state.editPmtAllowed}
                    onChange={this.handleChange}
                  ></input>{" "}
                  &nbsp; {name}
                </div>
              );
            })}
          </div>
          {visible && (
            <React.Fragment>
              <div className="form-item-label">Processor Switch: </div>
              <div className="form-item-value">
                <input
                  type="checkbox"
                  name="CB_allowSwitchToManual"
                  disabled={!this.state.editPmtAllowed}
                  defaultChecked={settings.allowSwitchToManual}
                  onChange={this.handleChange}
                ></input>{" "}
                Switch To Manual if Auto Failed
              </div>
            </React.Fragment>
          )}
          {this.paymentErrorMessages()}
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={updDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentSetting);
