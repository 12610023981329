import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
  toDateStrEx,
  toTimeStr,
} from "../Util";
import Working from "../Working";
import { checkSecurity, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import ActionConfirmation from "../ActionConfirmation";

import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../setting.css";

class ManagePromotion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      promotions: [],
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      idxTBD: -1,
      editAllowed: checkUpdate("Setting"),
      isRoot: checkSecurity("RootFeatures"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchPromotions = this.fetchPromotions.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.deletePromotion = this.deletePromotion.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    this.fetchPromotions();
    window.addEventListener("resize", this.adjustMode);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  fetchPromotions() {
    const newPromo = { id: 0, displayName: "Add New Promotion" };
    const url = apiBaseUrl() + "GetAllPromotions";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        let promotions = null;
        if (res.data && res.data.status) {
          promotions = res.data.objList;
        }

        if (!promotions || promotions.length === 0) {
          promotions = [newPromo];
        } else {
          promotions.push(newPromo);
        }
        this.setState({ promotions, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ promotions: [newPromo], isLoading: false });
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      const newPromo = { id: 0, displayName: "Add New Promotion" };
      let promotions = response.objList;

      if (!promotions || promotions.length === 0) {
        promotions = [newPromo];
      } else {
        promotions.push(newPromo);
      }
      this.setState({ promotions });
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    const pos = name.indexOf("_");
    const idx = parseInt(name.substring(0, pos));

    name = name.substring(pos + 1);
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
    }
    let promo = {
      ...this.state.promotions[idx],
      [name]: val,
    };

    let actionable = false;
    if (promo.code && promo.code.length > 1) {
      if (promo.discountAmount > 0 || promo.discountPercent > 0) {
        actionable = true;
      }
    }
    promo = {
      ...promo,
      actionable,
    };
    let promotions = [];
    for (let i = 0; i < this.state.promotions.length; i++) {
      if (i === idx) promotions.push(promo);
      else promotions.push(this.state.promotions[i]);
    }
    this.setState({
      promotions,
    });
  }
  handleClick(idx) {
    let promotions = this.state.promotions;

    promotions[idx].expanded = !promotions[idx].expanded;
    this.setState({ promotions });
  }
  handleDelete() {
    const idx = this.state.idxTBD;

    if (idx >= 0) {
      this.handleUpdate("Delete", idx);
      this.setState({ idxTBD: -1 });
    }
  }
  handleUpdate(action, idx) {
    const url = apiBaseUrl() + action + "Promotion";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.promotions[idx],
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  deletePromotion(idx) {
    this.setState({ idxTBD: idx });
    document.getElementById("hdnDelPromoConfirmBtn").click();
  }
  deleteConfirmDialog() {
    let msg = (
      <div>
        <p />
        Are you sure you want to delete/disable this promotion?
      </div>
    );

    return (
      <ActionConfirmation
        hidden={true}
        btnId="hdnDelPromoConfirmBtn"
        actionHandler={this.handleDelete}
        buttonTitle="Hidden Delete"
        buttonClsName="red-btn-style"
        title="Delete/Disable Confirmation"
        message={msg}
      />
    );
  }
  showOnePromotion(promo, idx) {
    const prefix = "" + idx + "_";
    const btnStyle =
      this.state.editAllowed && promo.actionable
        ? "btn-style"
        : "disabled-btn-style";
    const redBtnStyle = this.state.editAllowed
      ? "red-btn-style"
      : "disabled-btn-style";
    const required = <font color="red">*</font>;
    const action = promo.id > 0 ? "Update" : "Add";
    const startDate = toDateStrEx(promo.startDate);
    const endDate = toDateStrEx(promo.endDate);
    const amtDisabled = promo.discountPercent > 0 ? true : false;
    const pctDisabled = promo.discountAmount > 0 ? true : false;
    const delBtnLabel = promo.usedCount > 0 ? "Disable" : "Delete";

    return (
      <div>
        <div className="form-wrapper">
          <div className="form-item-label">Promo Code {required}: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name={prefix + "code"}
              value={promo.code || ""}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || promo.id > 0}
            ></input>
          </div>
          <div className="form-item-label">Promo Name: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="40"
              name={prefix + "name"}
              value={promo.name || ""}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Description: </div>
          <div className="form-item-value">
            <textarea
              name={prefix + "description"}
              value={promo.description || ""}
              rows="3"
              cols="40"
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></textarea>
          </div>
          <div className="form-item-label">Discount {required}: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="8"
              inputMode="numeric"
              name={prefix + "discountAmount"}
              value={promo.discountAmount || "0"}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || amtDisabled}
            ></input>
            $ or&nbsp;&nbsp;
            <input
              type="text"
              size="8"
              inputMode="numeric"
              name={prefix + "discountPercent"}
              value={promo.discountPercent || "0"}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || pctDisabled}
            ></input>
            %
          </div>
          <div className="form-item-label">Min Purchase: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="8"
              inputMode="numeric"
              name={prefix + "minPurchase"}
              value={promo.minPurchase || "0"}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
            $
          </div>
          <div className="form-item-label">Start Date: </div>
          <div className="form-item-value">
            <input
              type="date"
              name={prefix + "startDate"}
              value={startDate}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">End Date: </div>
          <div className="form-item-value">
            <input
              type="date"
              name={prefix + "endDate"}
              value={endDate}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Settings: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name={prefix + "CB_deliveryFee"}
              checked={promo.deliveryFee}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
            Is delivery fee discount
            {this.state.isRoot && (
              <React.Fragment>
                <br />
                <input
                  type="checkbox"
                  name={prefix + "CB_excludeOnline"}
                  checked={promo.excludeOnline}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                ></input>
                Exclude online
                <br />
                <input
                  type="checkbox"
                  name={prefix + "CB_excludeInStore"}
                  checked={promo.excludeInStore}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                ></input>
                Exclude in-store
              </React.Fragment>
            )}
            <br />
            <input
              type="checkbox"
              name={prefix + "CB_includeLayaway"}
              checked={promo.includeLayaway}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !this.state.isRoot}
            ></input>
            Include layaway
            <br />
          </div>
          <div className="form-item-label">Max Count: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="8"
              inputMode="numeric"
              name={prefix + "maxCount"}
              value={promo.maxCount || "0"}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          {promo.id > 0 && (
            <React.Fragment>
              <div className="form-item-label0">Used Count: </div>
              <div className="form-item-value0">{promo.usedCount}</div>
              <div className="form-item-label0">Added: </div>
              <div className="form-item-value0">
                {toTimeStr(promo.dateAdded)} by {promo.addedBy}
              </div>
              <div className="form-item-label0">Last Updated: </div>
              <div className="form-item-value0">
                {toTimeStr(promo.lastUpdated)} by {promo.lastUpdatedBy}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="generic-flex">
          <div className="top-10">
            <button
              className={btnStyle}
              name="addUpdate"
              disabled={!this.state.editAllowed || !promo.actionable}
              onClick={() => this.handleUpdate(action, idx)}
            >
              {action}
            </button>
          </div>
          <div className="left-10"></div>
          {promo.id > 0 && (
            <div className="top-10">
              <button
                className={redBtnStyle}
                disabled={!this.state.editAllowed}
                name="delete"
                onClick={() => this.deletePromotion(idx)}
              >
                {delBtnLabel}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  theForm() {
    const promotions = this.state.promotions;

    if (promotions && promotions.length > 0) {
      return (
        <div className="generic-wrapper">
          <font size="5">Promotions</font>
          <p />
          {promotions.map((promo, idx) => {
            const icon = promo.expanded ? "minus.jpg" : "plus.jpg";
            let title = promo.displayName ? (
              <font color="blue">{promo.displayName}</font>
            ) : (
              promo.name
            );

            if (!title) title = promo.code;
            return (
              <div className="bottom-15" key={idx}>
                <div className="generic-flex">
                  <div>
                    <img
                      src={getIconBaseUrl() + icon}
                      width="20"
                      onClick={() => this.handleClick(idx)}
                      alt="PM"
                    ></img>
                  </div>
                  <div>
                    {promo.expanded ? this.showOnePromotion(promo, idx) : title}
                  </div>
                  <p />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) return <Working />;

    return (
      <div className="top-wrapper">
        {this.theForm()}
        {this.deleteConfirmDialog()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagePromotion);
