import React, { isValidElement } from "react";
import { withRouter } from "react-router-dom";
import { FormatCurrency, fullProductImageUrl } from "../Util";
import "../../App.css";
import "../../generic.css";

class SPThumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { locList: this.props.locList };
  }
  componentDidUpdate(prevProps) {
    if (this.props.locList !== prevProps.locList) {
      this.setState({ locList: this.props.locList });
    }
  }
  getStoreName(locId) {
    const locList = this.state.locList;
    let name = "Location: " + locId;

    if (locList) {
      const loc = locList.find((lr) => lr.locationInfo.id === locId);
      if (loc) {
        name = loc.locationInfo.shortName;
      }
    }
    return name;
  }
  showQuantity(sp) {
    const showStores = this.props.locId <= 0;
    const invList = sp.inventoryList;
    let quantity = null;

    if (this.props.locId > 0 && sp.inventoryList) {
      const found = sp.inventoryList.find(
        (inv) => inv.storeId === this.props.locId
      );
      if (found) quantity = "Quantity: " + found.quantity;
    }

    return (
      <React.Fragment>
        Price:{" "}
        <font color="red">
          <FormatCurrency amount={sp.price} />
        </font>
        <br />
        {quantity && (
          <span>
            {quantity}
            <br />
          </span>
        )}
        {showStores && invList && invList.length > 0 && (
          <div className="sp-small-font-div">
            {invList.map((inv, idx) => {
              return (
                <span key={idx}>
                  * {this.getStoreName(inv.storeId)}&nbsp;({inv.quantity})
                  <br />
                </span>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
  showDeposit(sp) {
    const invList = sp.inventoryList;
    return (
      <div className="top-10">
        {invList && invList.length > 0 && (
          <div className="sp-small-font-div">
            {invList.map((inv, idx) => {
              return (
                <span key={idx}>
                  * {this.getStoreName(inv.storeId)}
                  <br />
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  }
  render() {
    const sp = this.props.storeProd;
    const editUrl = "/editStoreProd/" + sp.id;
    let imageUrl = sp.imageUrl ? sp.imageUrl : "no-image.jpg";

    if (
      sp.typeId > 0 &&
      sp.depositType &&
      (!imageUrl || imageUrl === "no-image.jpg")
    ) {
      imageUrl = sp.depositType.imageUrl;
    }

    return (
      <div className="generic-flex" key={sp.id}>
        <div>
          <img src={fullProductImageUrl(imageUrl)} alt="Image" width="75"></img>
        </div>
        <div className="sp-left-10">
          <br />
          <a href={editUrl}>{sp.name}</a>&nbsp;({sp.sku})
          <br />
          {sp.typeId > 0 ? this.showDeposit(sp) : this.showQuantity(sp)}
        </div>
      </div>
    );
  }
}

export default withRouter(SPThumbnail);
