import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView } from "../SecManager";
import PHWidget from "./PHWidget";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";

class PriceHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phList: null,
      mobileMode: false,
      isLoading: true,
      viewAllowed: checkView("Product"),
    };
    this.fetchPH = this.fetchPH.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchPH();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  fetchPH() {
    const url = apiBaseUrl() + "GetPriceHistory";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ phList: res.data.objList, isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error has occurred",
          isLoading: false,
        });
        console.log(error);
      });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <Working />
          </div>
        </div>
      );
    }

    if (this.state.phList) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <PHWidget
              phList={this.state.phList}
              showSku={true}
              mobileMode={this.state.mobileMode}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font color="red">{this.state.errorMessage}</font>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PriceHistory);
