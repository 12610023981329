import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";

import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditOrderSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      setting: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetOrderSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({ setting: response.data, isLoading: false });
  }
  processUpdateResponse(response) {
    console.log(response);
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    let setting;

    if (name.startsWith("Ord_")) {
      const theIdx = parseInt(name.substring(4));
      let ordStatusList = this.state.setting.ordStatusList.map((ss, idx) => {
        if (idx === theIdx) {
          const newSS = {
            ...ss,
            allowedStatus: val,
          };
          return newSS;
        } else return ss;
      });
      setting = {
        ...this.state.setting,
        ordStatusList,
      };
    } else if (name.startsWith("La_")) {
      const theIdx = parseInt(name.substring(3));
      let laStatusList = this.state.setting.laStatusList.map((ss, idx) => {
        if (idx === theIdx) {
          const newSS = {
            ...ss,
            allowedStatus: val,
          };
          return newSS;
        } else return ss;
      });
      setting = {
        ...this.state.setting,
        laStatusList,
      };
    } else {
      if (name.startsWith("CB_")) {
        name = name.substring(3);
        val = event.target.checked;
      }
      setting = {
        ...this.state.setting,
        [name]: val,
      };
    }
    this.setState({ setting, updateBtnDisabled: false });
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateOrderSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.setting,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  statusSection(statusList, layaway) {
    if (statusList && statusList.length > 0) {
      const editAllowed = this.state.editAllowed;
      const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";
      const name = layaway ? "La" : "Ord";

      return (
        <React.Fragment>
          <div className="form-item-label">
            <b>{name} Allowed</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          {statusList.map((ss, idx) => {
            const sid = layaway ? ss.code : ss.id;
            const fName = name + "_" + idx;
            return (
              <React.Fragment key={idx}>
                <div className="form-item-label">
                  {ss.status}({sid}):
                </div>
                <div className="form-item-value">
                  <input
                    type="text"
                    size="20"
                    name={fName}
                    value={ss.allowedStatus || ""}
                    onChange={this.handleChange}
                    disabled={!editAllowed}
                  ></input>
                </div>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    }
  }
  theForm() {
    const setting = this.state.setting;
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";

    return (
      <div className="generic-wrapper">
        <font size="5">Order Settings</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">Legacy:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_showLegacyLinks"
              defaultChecked={setting.showLegacyLinks}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show ExpressInvoice Links
          </div>
          <div className="form-item-label">Single:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_singleReturnAllowed"
              defaultChecked={setting.singleReturnAllowed}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Single Item Order Return Allowed
          </div>
          <div className="form-item-label">Order Status:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_autoUpdateLoadedOrders"
              defaultChecked={setting.autoUpdateLoadedOrders}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Auto Update Loaded Orders
          </div>
          <div className="form-item-label">Restocking Fees:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name="margintop"
              inputmode="numeric"
              value={setting.restockingFeeChoices}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">
            <b>Return Allowed</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Order:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name="ordRtnAllowedStatuses"
              value={setting.ordRtnAllowedStatuses}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Layaway:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name="laRtnAllowedStatuses"
              value={setting.laRtnAllowedStatuses}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">
            <b>Non-Editable</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Order:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name="ordNonEditableStatuses"
              value={setting.ordNonEditableStatuses}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Layaway:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name="laNonEditableStatuses"
              value={setting.laNonEditableStatuses}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">
            <b>Return</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">DAT:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_genDatAfterReturn"
              defaultChecked={setting.genDatAfterReturn}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Generate DAT After Return
          </div>
          {this.statusSection(setting.ordStatusList, false)}
          {this.statusSection(setting.laStatusList, true)}
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditOrderSettings);
