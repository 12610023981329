import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "./config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  getIconBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "./Util";
import { checkView, checkUpdate } from "./SecManager";
import { setLoggedOut } from "./actions/userActions";
import Working from "./Working";
import "../App.css";
import "../generic.css";
import "../form.css";

class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      monthlyStatsList: null,
      totalBSList: null,
      m2dBSList: null,
      isLoading: true,
      isLoadingBS: true,
      errorMessage: null,
      expanded: [],
      mobileMode: false,
      editAllowed: checkUpdate("Statistics"),
      viewAllowed: checkView("Statistics"),
    };
    this.processResponse = this.processResponse.bind(this);
    this.processBSResponse = this.processBSResponse.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchStatsList();
    this.fetchBrowserStats();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }
  fetchStatsList() {
    const url = apiBaseUrl() + "GetStatsList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.processResponse(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get statistics",
          isLoading: false,
        });
      });
  }

  fetchBrowserStats() {
    const url = apiBaseUrl() + "GetBrowserStats";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.processBSResponse(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get statistics",
          isLoading: false,
        });
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    let expanded = [];
    if (response.objList && response.objList.length > 0) {
      let i;
      for (i = 0; i < response.objList.length; i++) {
        if (i === 0) expanded.push(true);
        else expanded.push(false);
      }
    }
    this.setState({
      monthlyStatsList: response.objList,
      expanded,
      isLoading: false,
    });
  }
  processBSResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({
      totalBSList: response.totalList,
      m2dBSList: response.m2dList,
      isLoadingBS: false,
    });
  }

  oneHtmlRow(stats, bold) {
    const b1 = bold ? "<b>" : "";
    const b2 = bold ? "</b>" : "";
    const dateStr = bold
      ? stats.statsDate.substring(0, 4) + "/" + stats.statsDate.substring(4)
      : stats.statsDate;
    let html =
      '<tr><td><a href="/statsDetail/' +
      stats.statsDate +
      '">' +
      b1 +
      dateStr +
      b2 +
      "</a></td>";

    if (stats.orderByStoreList && stats.orderByStoreList.length > 0) {
      html += "<td>" + b1 + stats.orderCount + b2 + "</td>";
    } else {
      html += "<td>" + b1 + stats.orderCount + b2 + "</td>";
    }
    if (stats.layawayByStoreList && stats.layawayByStoreList.length > 0) {
      html += "<td>" + b1 + stats.layawayCount + b2 + "</td>";
    } else {
      html += "<td>" + b1 + stats.layawayCount + b2 + "</td>";
    }

    html += "<td>" + b1 + stats.paymentCount + b2 + "</td>";
    html += "<td>" + b1 + stats.returnCount + b2 + "</td>";
    html += "<td>" + b1 + stats.userCount + b2 + "</td>";
    if (!this.state.mobileMode) {
      html += "<td>" + b1 + stats.hpUserCount + b2 + "</td>";
      html += "<td>" + b1 + stats.abandonedOrders + b2 + "</td>";
    }
    html += "</tr>";
    return html;
  }

  showStatsTable(monthStats) {
    let i;
    const stats = monthStats.statsList;
    const pLabel = this.state.mobileMode ? "Pmts" : "Payments";
    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html +=
      '<table><tr><th style="background-color: #D0D0D0">Date</th><th style="background-color: #D0D0D0">Orders</th><th style="background-color: #D0D0D0">Layaways</th>';
    html +=
      '<th style="background-color: #D0D0D0">' +
      pLabel +
      '</th><th style="background-color: #D0D0D0">Refunds</th><th style="background-color: #D0D0D0">Users</th>';
    if (!this.state.mobileMode) {
      html +=
        '<th style="background-color: #D0D0D0">Home Page Only</th><th style="background-color: #D0D0D0">Abandoned Orders</th>';
    }
    html += "</tr>";

    if (monthStats.m2dStats) {
      html += this.oneHtmlRow(monthStats.m2dStats, true);
    }
    for (i = 0; i < stats.length; i++) {
      html += this.oneHtmlRow(stats[i], false);
    }
    html += "</table>";

    return (
      <div
        className="left-8"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    );
  }

  oneBSHtmlRow(bs) {
    let browser = bs.browser;
    if (this.state.mobileMode && browser && browser.length > 10) {
      browser = browser.substring(0, 10);
    }
    let html = "<tr>";
    html += "<td>" + browser + "</td>";
    html +=
      "<td align='right'>" + bs.userCount + "(" + bs.userPercent + "%)</td>";
    html += "<td align='right'>" + bs.viewCount + "</td>";
    if (!this.state.mobileMode) {
      html += "<td align='right'>" + bs.homePageOnlyCount + "</td>";
    }
    html += "<td align='right'>" + bs.orderCount + "</td>";
    html += "</tr>";
    return html;
  }

  showBrowserStats(bsList) {
    if (this.state.isLoadingBS) {
      return (
        <div>
          <img src={getIconBaseUrl() + "working.gif"} width={80} />
        </div>
      );
    } else if (!bsList || bsList.length == 0) return "";

    const bLabel = this.state.mobileMode ? "Browser" : "Browser/Device";
    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html +=
      '<table><tr><th style="background-color: #D0D0D0">' +
      bLabel +
      '</th><th style="background-color: #D0D0D0">Users</th>';
    html += '<th style="background-color: #D0D0D0">Views</th>';
    if (!this.state.mobileMode) {
      html += '<th style="background-color: #D0D0D0">Home Page Only</th>';
    }
    html += '<th style="background-color: #D0D0D0">Orders</th></tr>';

    const stats = bsList;
    let i;
    for (i = 0; i < stats.length; i++) {
      html += this.oneBSHtmlRow(stats[i]);
    }
    html += "</table>";

    return (
      <div
        className="left-8"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    );
  }

  handleClick(idx) {
    let expanded = [];
    let i;
    for (i = 0; i < this.state.expanded.length; i++) {
      if (i === idx) expanded.push(!this.state.expanded[i]);
      else expanded.push(this.state.expanded[i]);
    }
    this.setState({ expanded });
  }

  statsHeader(monthStats) {
    const stats = monthStats.m2dStats;
    if (stats) {
      const url = "/statsDetail/" + stats.statsDate;
      return (
        <div>
          <b>
            &nbsp;<a href={url}>{stats.statsDate}</a>&nbsp;&nbsp;
            {stats.orderCount}&nbsp;&nbsp;
            {stats.paymentCount}&nbsp;&nbsp;
            {stats.userCount}&nbsp;&nbsp;
            {stats.hpUserCount}&nbsp;&nbsp;
            {stats.abandonedOrders}
          </b>
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    if (
      !this.state.monthlyStatsList ||
      this.state.monthlyStatsList.length === 0
    ) {
      return (
        <div>
          <font color="red">No statistics available</font>
        </div>
      );
    }

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Statistics</font>
          <p />
          {this.state.monthlyStatsList.map((monthStats, idx) => {
            const icon = this.state.expanded[idx] ? "minus.jpg" : "plus.jpg";
            return (
              <div className="bottom-15" key={idx}>
                <div className="generic-flex">
                  <div>
                    <img
                      src={getIconBaseUrl() + icon}
                      width="20"
                      onClick={() => this.handleClick(idx)}
                      alt="PM"
                    ></img>
                  </div>
                  <div>
                    {this.state.expanded[idx]
                      ? this.showStatsTable(monthStats)
                      : this.statsHeader(monthStats)}
                  </div>
                  <p />
                </div>
              </div>
            );
          })}
          <p />
          <font size="4">Browser/Device Statistics</font>
          <br />
          <div>{this.showBrowserStats(this.state.totalBSList)}</div>
          {this.state.m2dBSList && (
            <React.Fragment>
              <p />
              <font size="4">M2D Browser Statistics</font>
              <br />
              <div>{this.showBrowserStats(this.state.m2dBSList)}</div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
