import React from "react";
import { connect } from "react-redux";
import {
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import Working from "../Working";
import { checkView } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import LeaseLookupWidget from "./LeaseLookupWidget";
import "../../App.css";
import "../../generic.css";

class LeaseLookup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMode: false,
      viewAllowed: checkView("Order"),
    };
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }

  render() {
    if (this.state.isLoading) return <Working />;

    return (
      <div className="top-wrapper">
        <div className="left-10">
          <font size="5">Progressive Lease Lookup</font>
          <p />
          You can search for Progressive lease applications by phone, name, or
          lease ID.
          <p />
          <LeaseLookupWidget
            userId={this.props.userId}
            sessionKey={this.props.sessionKey}
            mobileMode={this.state.mobileMode}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaseLookup);
