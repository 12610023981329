import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
} from "../Util";
import { checkView, checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import { setExactOrderResult } from "../actions/searchActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../orderStats.css";
import StoreDatesSelector from "../StoreDatesSelector";

const Names = ["City", "Zip Code", "City/Zip"];
const OtherNames = ["Zip Codes", "Cities", null];

class OrderStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderStats: null,
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      amountFlags: [false, false, false],
      statsLists: null,
      amountLists: null,
      viewAllowed: checkView("Statistics"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchOrderStats = this.fetchOrderStats.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
    this.processSearchResponse = this.processSearchResponse.bind(this);
    this.setDistances = this.setDistances.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchOrderStats({ storeId: 0, includeLayaway: true });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }
  fetchOrderStats(storeDates) {
    const url = apiBaseUrl() + "GetOrderStats";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      startDate: storeDates.startDate,
      endDate: storeDates.endDate,
      storeId: storeDates.storeId,
      flag: storeDates.includeLayaway,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get order stats",
          isLoading: false,
        });
      });
  }
  processSearchResponse(response, listIdx, name) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const crit = Names[listIdx] + "=" + this.toTitleCase(name);
      this.props.setExactOrderResult(response.objList, crit);
      this.props.history.push("/orderListView");
    }
  }
  setDistances() {
    const url = apiBaseUrl() + "SetOrderDistances";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  searchOrderExact(listIdx, name) {
    const url = apiBaseUrl() + "SearchOrderExact";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      type: listIdx,
      name: name,
      startDate: this.state.orderStats.startDateStr,
      endDate: this.state.orderStats.endDateStr,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processSearchResponse(res.data, listIdx, name);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get order stats",
          isLoading: false,
        });
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      let statsLists = [];
      let amountLists = [];

      if (response.data.totalSales > 0) {
        statsLists.push(response.data.topSalesByCity);
        statsLists.push(response.data.topSalesByZipCode);
        statsLists.push(response.data.topSalesByCityZip);
        amountLists.push(response.data.topAmountByCity);
        amountLists.push(response.data.topAmountByZipCode);
        amountLists.push(response.data.topAmountByCityZip);
      }
      this.setState({
        orderStats: response.data,
        statsLists,
        amountLists,
        isLoading: false,
      });
    }
  }
  handleExpand(listIdx, rowIdx, flag) {
    if (this.state.amountFlags[listIdx]) {
      const amountLists = this.state.amountLists.map((list, idx) => {
        if (idx === listIdx) {
          const newList = list.map((stats, rIdx) => {
            if (rIdx === rowIdx) {
              const newStats = {
                ...stats,
                expanded: flag,
              };
              return newStats;
            } else return stats;
          });
          return newList;
        } else return list;
      });
      this.setState({ amountLists });
    } else {
      const statsLists = this.state.statsLists.map((list, idx) => {
        if (idx === listIdx) {
          const newList = list.map((stats, rIdx) => {
            if (rIdx === rowIdx) {
              const newStats = {
                ...stats,
                expanded: flag,
              };
              return newStats;
            } else return stats;
          });
          return newList;
        } else return list;
      });
      this.setState({ statsLists });
    }
  }
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  otherNames(stats, listIdx, rowIdx) {
    const list = stats.otherNameList;
    const expanded = stats.expanded;
    const displayOther = OtherNames[listIdx] ? true : false;
    const clsName = rowIdx % 2 === 0 ? "grid-item-ev" : "grid-item-od";

    if (displayOther) {
      if (!list || list.length === 0) {
        return <div className={clsName}>&nbsp;</div>;
      } else if (list.length === 1) {
        return <div className={clsName}>{this.toTitleCase(list[0])}</div>;
      }

      const icon = stats.expanded ? "minus.jpg" : "plus.jpg";
      if (expanded) {
        return (
          <div className={clsName}>
            {list.map((name, idx) => {
              if (idx === 0) {
                return (
                  <a
                    href="#NONE"
                    onClick={() => this.handleExpand(listIdx, rowIdx, false)}
                  >
                    <img src={getIconBaseUrl() + icon} height="13"></img>
                    {this.toTitleCase(name)}
                    <br />
                  </a>
                );
              } else {
                return (
                  <label>
                    {this.toTitleCase(name)}
                    <br />
                  </label>
                );
              }
            })}
          </div>
        );
      } else {
        const val = this.toTitleCase(list[0]) + "...";
        return (
          <div className={clsName}>
            <a
              href="#NONE"
              onClick={() => this.handleExpand(listIdx, rowIdx, true)}
            >
              <img src={getIconBaseUrl() + icon} height="13"></img>
              {val}
            </a>
          </div>
        );
      }
    }
  }
  oneStatsRow(listIdx, rowIdx) {
    const statsList = this.state.amountFlags[listIdx]
      ? this.state.amountLists[listIdx]
      : this.state.statsLists[listIdx];
    const stats = statsList[rowIdx];
    const cp = " (" + stats.countPercent.toFixed(2) + "%)";
    const ap = " (" + stats.amountPercent.toFixed(2) + "%)";
    const clsName = rowIdx % 2 === 0 ? "grid-item-ev" : "grid-item-od";
    const ref = "#" + listIdx + "_" + rowIdx;
    const average = stats.amount / stats.count;
    return (
      <React.Fragment>
        <div className={clsName}>
          <a
            href={ref}
            onClick={() => this.searchOrderExact(listIdx, stats.name)}
          >
            {this.toTitleCase(stats.name)}
          </a>
        </div>
        <div className={clsName}>
          {"" + stats.count}
          {this.state.mobileMode && <br />}
          {cp}
        </div>
        <div className={clsName}>
          {"$" + stats.amount.toFixed(2)}
          {this.state.mobileMode && <br />}
          {ap}
        </div>
        {!this.state.mobileMode && (
          <div className={clsName}>{"$" + average.toFixed(2)}</div>
        )}
        {this.otherNames(stats, listIdx, rowIdx)}
      </React.Fragment>
    );
  }
  handleClick(index, flag) {
    const amountFlags = this.state.amountFlags.map((f, idx) => {
      if (index === idx) return flag;
      else return f;
    });
    this.setState({ amountFlags });
  }
  statsHeader(index) {
    const isAmount = this.state.amountFlags[index];
    const displayOther = OtherNames[index] ? true : false;
    const clsName = "grid-item-hd";
    const name = "Stats" + index;
    const refName = "#" + name;
    return (
      <React.Fragment>
        <div className={clsName}>
          <i>{Names[index]}</i>
        </div>
        <div className={clsName}>
          {isAmount ? (
            <div>
              <a href={refName} onClick={() => this.handleClick(index, false)}>
                <i>
                  <b>Count</b>
                </i>
              </a>
            </div>
          ) : (
            <div>
              <i>Count</i>
            </div>
          )}
        </div>
        <div className={clsName}>
          {isAmount ? (
            <div>
              <i>Amount</i>
            </div>
          ) : (
            <a href={refName} onClick={() => this.handleClick(index, true)}>
              <i>
                <b>Amount</b>
              </i>
            </a>
          )}
        </div>
        {!this.state.mobileMode && (
          <div className={clsName}>
            <i>Average</i>
          </div>
        )}
        {displayOther && (
          <div className={clsName}>
            <i>{OtherNames[index]}</i>
          </div>
        )}
      </React.Fragment>
    );
  }
  showStats(index) {
    const isAmount = this.state.amountFlags[index];
    const statsList = isAmount
      ? this.state.amountLists[index]
      : this.state.statsLists[index];

    const displayOther = OtherNames[index] ? true : false;
    let title = "Top " + statsList.length + " Sales ";
    let colCnt = 3;

    if (displayOther) colCnt++;
    if (!this.state.mobileMode) colCnt++;

    const clsName = "os-table-c" + colCnt;
    if (isAmount) title += "Amount ";
    else title += "Count ";
    title += "by " + Names[index];

    return (
      <div>
        <b>{title}</b>
        <br />
        <div className={clsName}>
          {this.statsHeader(index)}
          {statsList.map((stats, rowIdx) => {
            return this.oneStatsRow(index, rowIdx);
          })}
        </div>
      </div>
    );
  }
  showSetDistanceButton() {
    if (checkSecurity("RootFeatures")) {
      return (
        <div>
          <button
            className="small-btn"
            name="setDistances"
            onClick={this.setDistances}
          >
            Set Distances
          </button>
        </div>
      );
    }
  }
  searchBox() {
    const storeList = this.state.orderStats
      ? this.state.orderStats.ssList
      : null;
    return (
      <StoreDatesSelector
        userId={this.props.userId}
        sessionKey={this.props.sessionKey}
        storeList={storeList}
        defaultStoreId={0}
        showLayawayCB={true}
        btnTitle="Search"
        actionHandler={this.fetchOrderStats}
      />
    );
  }
  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    if (
      !this.state.orderStats ||
      !this.state.orderStats.topSalesByCity ||
      this.state.orderStats.topSalesByCity.length === 0
    ) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">Orders</font>
            <p />
            {this.searchBox()}
            <p />
            <font color="red">No order is found.</font>
          </div>
        </div>
      );
    }

    const title =
      this.state.orderStats.storeName +
      " " +
      "Order Statistics " +
      this.state.orderStats.startDateStr +
      " - " +
      this.state.orderStats.endDateStr;
    const stats = this.state.orderStats;
    const avPick =
      stats.averagePickupDistance > 0
        ? "" + stats.averagePickupDistance.toFixed(2) + " miles"
        : "N/A";
    const avDelv =
      stats.averageDeliveryDistance > 0
        ? "" + stats.averageDeliveryDistance.toFixed(2) + " miles"
        : "N/A";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          {this.searchBox()}
          <p />
          {this.showSetDistanceButton()}
          <p />
          <b>Total Sales: {stats.totalSales}</b>
          <br />
          <b>Total Amount: ${stats.totalAmount.toFixed(2)}</b>
          <br />
          <b>Total Amount (with Tax): ${stats.totalAmountWithTax.toFixed(2)}</b>
          <br />
          <div className="twocol-wrapper">
            <div>Delivery:</div>
            <div className="left-10">
              Total={stats.deliveryCount}
              <br />
              Avg. Distance={avDelv}
            </div>
            <div>Pickup:</div>
            <div className="left-10">
              Total={stats.pickupCount}&nbsp;&nbsp;Avg. Distance={avPick}
              <br />
              Georgia={stats.pickupGaCount}&nbsp;&nbsp;Avg. Distance=
              {stats.averageGaPickupDistance.toFixed(2)} miles
              <br />
              Out of State={stats.pickupOosCount}
            </div>
          </div>
          <p />
          {this.showStats(0)}
          <p />
          {this.showStats(1)}
          <p />
          {this.showStats(2)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
    setExactOrderResult: (list, crit) => {
      dispatch(setExactOrderResult(list, crit));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderStats);
