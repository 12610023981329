import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import ThumbnailView from "./ThumbnailView";
import {
  apiBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import "../../App.css";
import "../../generic.css";
import "../../specials.css";

class EditHSProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productList: null,
      isLoading: true,
      allProducts: null,
      isLoadingAll: true,
      newProductIds: [],
      mobileMode: false,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };
    this.fetchAllProducts = this.fetchAllProducts.bind(this);
    this.processProdListResponse = this.processProdListResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateProductList = this.updateProductList.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  processProdListResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const objList = response.objList;
      this.setState({ productList: objList, isLoading: false });

      if (objList) {
        let newProductIds = objList.map((prod) => {
          return prod.id;
        });
        this.setState({ newProductIds });
      }
    }
  }
  componentDidMount() {
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "Get" + this.props.name;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processProdListResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    this.fetchAllProducts();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  fetchAllProducts() {
    if (this.state.editAllowed) {
      const url = apiBaseUrl() + "GetAllProducts";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
      };
      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.setState({ allProducts: res.data, isLoadingAll: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleChange(event) {
    let prodId = parseInt(event.target.name);

    let idList;
    if (event.target.checked) {
      idList = this.state.newProductIds;
      idList.push(prodId);
    } else {
      idList = this.state.newProductIds.filter((id) => prodId !== id);
    }
    this.setState({ newProductIds: idList, updateBtnDisabled: false });
  }

  showProductList() {
    if (this.state.productList) {
      return (
        <React.Fragment>
          {this.state.productList.map((prod, idx) => {
            return (
              <div key={idx}>
                <input
                  type="checkbox"
                  name={prod.id}
                  defaultChecked="true"
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                ></input>
                <ThumbnailView prod={prod} />
              </div>
            );
          })}
        </React.Fragment>
      );
    }
  }

  isInProductList(prodId) {
    const found = this.state.productList.find((prod) => prod.id === prodId);

    if (found) return true;
    else return false;
  }

  showOthers() {
    if (this.state.allProducts && this.state.productList) {
      return (
        <React.Fragment>
          {this.state.allProducts.map((prod, idx) => {
            if (this.isInProductList(prod.id) === false) {
              return (
                <div key={idx}>
                  <input
                    type="checkbox"
                    name={prod.id}
                    onChange={this.handleChange}
                  ></input>
                  <ThumbnailView prod={prod} />
                </div>
              );
            } else return "";
          })}
        </React.Fragment>
      );
    }
  }

  updateProductList() {
    const url = apiBaseUrl() + "Update" + this.props.name;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      idList: this.state.newProductIds,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const btnClsName = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const clsName = this.state.mobileMode ? "generic-wrapper" : "div-width800";
    const name = this.props.displayName
      ? this.props.displayName
      : this.props.name;
    return (
      <div className="top-wrapper">
        <font size="5">{name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</font>
        <button
          className={btnClsName}
          name="update"
          onClick={this.updateProductList}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <br />
        {this.props.name === "Specials" ? (
          <div className="generic-wrapper">
            The checked products in this page will be shown in the "
            <font color="red">Web Specials</font>".
          </div>
        ) : (
          <div className={clsName}>
            The checked products in this page will be shown in the Home Page: 4
            in the first row below the big sliding pictures and 2 on the right
            side of these big pictures. Please select <b>at least 6 products</b>
            , the system will randomly choose 6 from the selected products.
          </div>
        )}
        <br />
        <div className="spprod-wrapper">
          {this.showProductList()}
          {this.showOthers()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditHSProductList));
