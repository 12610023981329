import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import ActionConfirmation from "../ActionConfirmation";
import { apiBaseUrl, getIconBaseUrl } from "../Util";
import { setLoggedOut } from "../actions/userActions";
import "../../generic.css";

class DatWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datInfoList: null,
      uploadBtnEnabled: false,
      uploading: false,
      errorMessage: null,
      message: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.doUploadFiles = this.doUploadFiles.bind(this);
    this.uploadDatFiles = this.uploadDatFiles.bind(this);
    this.processCheckResponse = this.processCheckResponse.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
  }
  componentDidMount() {
    this.setInitState();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.layawayDatFile != prevProps.layawayDatFile ||
      this.props.orderDatFile != prevProps.orderDatFile ||
      this.props.invoiceDatFile != prevProps.invoiceDatFile
    ) {
      this.setInitState();
    }
  }
  setInitState() {
    let datInfoList = [];
    if (this.props.orderDatFile) {
      datInfoList.push(this.toDatInfo(this.props.orderDatFile, "Order"));
    }
    if (this.props.invoiceDatFile) {
      datInfoList.push(this.toDatInfo(this.props.invoiceDatFile, "Invoice"));
    }
    if (this.props.layawayDatFile) {
      datInfoList.push(this.toDatInfo(this.props.layawayDatFile, "Layaway"));
    }

    this.setState({
      datInfoList,
      uploadBtnEnabled: false,
    });
    this.checkDat(datInfoList);
  }
  toDatInfo(datFile, name) {
    if (datFile && datFile.length > 0) {
      return {
        orderNumber: this.props.orderNumber,
        name,
        datFile: datFile,
        exists: false,
        upload: false,
      };
    }
    return null;
  }
  processCheckResponse(response) {
    if (response && response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      let datInfoList = [];
      let uploadBtnEnabled = false;
      if (response.objList) {
        for (let i = 0; i < response.objList.length; i++) {
          const info = {
            ...response.objList[i],
            upload: !response.objList[i].exists,
          };
          if (!response.objList[i].exists) {
            uploadBtnEnabled = true;
          }
          datInfoList.push(info);
        }
      }
      this.setState({ datInfoList, uploadBtnEnabled });
    }
  }
  checkDat(datInfoList) {
    const url = apiBaseUrl() + "CheckDat";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: datInfoList,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processCheckResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange(event) {
    let uploadBtnEnabled = event.target.checked;
    let datInfoList = [];

    for (let i = 0; i < this.state.datInfoList.length; i++) {
      let info = this.state.datInfoList[i];

      if (info.name === event.target.name) {
        info = {
          ...info,
          upload: event.target.checked,
        };
      } else {
        if (info.upload) uploadBtnEnabled = true;
      }
      datInfoList.push(info);
    }
    this.setState({
      datInfoList,
      uploadBtnEnabled,
      errorMessage: null,
      message: null,
    });
  }
  overwriteConfirmation() {
    if (this.willOverwrite()) {
      let msg = (
        <div>
          The DAT file(s) you are trying to upload already exist on the
          ExpressInvoice server.
          <p />
          Are you sure you want to overwrite?
        </div>
      );
      return (
        <ActionConfirmation
          hidden={true}
          btnId="hiddenDatOWCfmBtn"
          actionHandler={this.doUploadFiles}
          buttonTitle="Hidden Delete"
          buttonClsName="red-btn-style"
          title="Overwrite Confirmation"
          message={msg}
        />
      );
    }
  }
  willOverwrite() {
    let exists = false;

    if (this.state.datInfoList) {
      for (let i = 0; i < this.state.datInfoList.length; i++) {
        const info = this.state.datInfoList[i];
        if (info.upload && info.exists) {
          exists = true;
        }
      }
    }
    return exists;
  }
  uploadDatFiles(event) {
    if (this.willOverwrite()) {
      document.getElementById("hiddenDatOWCfmBtn").click();
      return;
    }
    this.doUploadFiles();
  }
  processUploadResponse(response) {
    if (response && response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({
        datInfoList: response.objList,
        uploading: false,
        errorMessage: null,
        message: "DAT file successfully uploaded",
      });
    }
  }
  doUploadFiles() {
    this.setState({ uploadBtnEnabled: false, uploading: true });

    const url = apiBaseUrl() + "UploadDat";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: this.state.datInfoList,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUploadResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          uploadBtnEnabled: true,
          uploading: false,
          errorMessage: "Failed to upload DAT file",
        });
        console.log(error);
      });
  }
  datExistInfo(datInfo) {
    if (datInfo.exists && datInfo.pdfUrl) {
      return (
        <div className="left-5">
          (<font color="green">Exists</font>&nbsp;
          <a href={datInfo.pdfUrl} target="__PDF">
            <img src={getIconBaseUrl() + "pdf.jpg"} height="16" alt="PDF"></img>
          </a>
          )
        </div>
      );
    } else if (datInfo.exists) {
      return (
        <div className="left-5">
          (<font color="green">Exists</font>)
        </div>
      );
    }
  }
  oneDat(datInfo, idx) {
    if (datInfo) {
      let datFile = datInfo.datFile;
      const pos = datFile.lastIndexOf("/");

      datFile = datFile.substring(pos + 1);

      return (
        <div className="generic-flex" key={idx}>
          <div>
            <input
              name={datInfo.name}
              type="checkbox"
              checked={datInfo.upload}
              onChange={this.handleChange}
            />
          </div>
          <div className="left-10">
            {datInfo.name} DAT File {datFile}
          </div>
          {this.datExistInfo(datInfo)}
        </div>
      );
    }
  }
  showDats() {
    if (this.state.datInfoList) {
      return (
        <div className="top-5">
          {this.state.datInfoList.map((datInfo, idx) => {
            return this.oneDat(datInfo, idx);
          })}
          <p />
        </div>
      );
    }
  }
  showMessage() {
    if (this.state.errorMessage) {
      return (
        <div className="top-5">
          <font color="red">{this.state.errorMessage}</font>
          <p />
        </div>
      );
    } else if (this.state.message) {
      return (
        <div className="top-5">
          <font color="blue">{this.state.message}</font>
          <p />
        </div>
      );
    }
  }
  render() {
    if (this.state.datInfoList && this.state.datInfoList.length > 0) {
      const btnStyle = this.state.uploadBtnEnabled
        ? "btn-style"
        : "disabled-btn-style";
      return (
        <div className="top-10">
          <fieldset className="fs-container">
            <legend>
              <font color="green">DAT on ExpressInvoice</font>
            </legend>
            {this.showDats()}
            {this.showMessage()}
            <div className="generic-flex">
              <div>
                <button
                  className={btnStyle}
                  name="upload"
                  onClick={this.uploadDatFiles}
                  disabled={!this.state.uploadBtnEnabled}
                >
                  Upload DAT File(s)
                </button>
              </div>
              {this.state.uploading && (
                <div className="left-10">
                  <img
                    src={getIconBaseUrl() + "working.gif"}
                    height="25"
                    alt="Uploading"
                  />
                </div>
              )}
            </div>
          </fieldset>
          {this.overwriteConfirmation()}
        </div>
      );
    } else return "";
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DatWidget);
