import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class ManageSalesperson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salespersonList: null,
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };
    this.fetchData = this.fetchData.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.addSales = this.addSales.bind(this);
  }

  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({
      salespersonList: response.objList,
      isLoading: false,
    });
  }
  fetchData() {
    const url = apiBaseUrl() + "GetAllSalespersons";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addSales() {
    this.props.history.push("/editSales");
  }
  oneHtmlRow(person) {
    const url = "/editSales/" + person.id;
    let html = '<tr><td><a href="' + url + '">' + person.nickname + "</a></td>";
    html += "<td>" + person.primaryStoreName + "</td>";
    html += "</tr>";
    return html;
  }
  showTable() {
    const list = this.state.salespersonList;
    if (!list || list.length === 0) return "";

    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html += '<table><tr><th style="background-color: #D0D0D0">Salesperson</th>';
    html +=
      '<th style="background-color: #D0D0D0" align="left">Primary Store</th>';
    html += "</tr>";

    for (let i = 0; i < list.length; i++) {
      html += this.oneHtmlRow(list[i]);
    }
    html += "</table>";

    return (
      <div
        className="left-8"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    );
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Sales Team</font>
          <p />
          <button className="btn-style" onClick={this.addSales}>
            Add Salesperson
          </button>
          <p />
          {this.showTable()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageSalesperson);
