import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { apiBaseUrl, toDateStr, getIconBaseUrl } from "../Util";
import ActionConfirmation from "../ActionConfirmation";

import "../../App.css";
import "../../generic.css";
import "../../form.css";

class LeaseActionWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coi: this.props.coi,
      mobileMode: this.props.mobileMode,
      leaseDeliveryDate: null,
      updateLeaseOrderStatus: false,
      errorMessage: null,
      waitingForConfirmation: false,
      waitingForCancelling: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.cancelContracts = this.cancelContracts.bind(this);
    this.confirmCancel = this.confirmCancel.bind(this);
    this.submitDeliveryConfirmation =
      this.submitDeliveryConfirmation.bind(this);
  }
  componentDidMount() {
    const today = toDateStr(new Date());
    this.setState({
      leaseDeliveryDate: today,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.coi != this.props.coi ||
      prevProps.mobileMode != this.props.mobileMode
    ) {
      this.setState({
        coi: this.props.coi,
        mobileMode: this.props.mobileMode,
      });
    }
  }
  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({ [name]: event.target.value });
    }
  }
  submitDeliveryConfirmation() {
    const url = apiBaseUrl() + "SubmitDeliveryConfirmation";
    const coi = this.state.coi;
    const info = coi.progressiveStatus;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      leaseId: info.leaseId,
      orderNumber: coi.order.orderNumber,
      deliveryDate: this.state.leaseDeliveryDate,
      updateOrderStatus: this.state.updateLeaseOrderStatus,
    };

    this.setState({ waitingForConfirmation: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            const coi = {
              ...this.state.coi,
              progressiveStatus: res.data.data,
            };
            this.setState({ coi, waitingForConfirmation: false });
            if (this.props.deliveryConfirmedHandler) {
              this.props.deliveryConfirmedHandler(res.data.data);
            }
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              message: null,
              waitingForConfirmation: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to submit delivery confirmation",
          message: null,
          waitingForConfirmation: false,
        });
      });
  }
  cancelContracts() {
    const url = apiBaseUrl() + "CancelContracts";
    const info = this.state.coi.progressiveStatus;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      leaseId: info.leaseId,
      orderNumber: this.state.coi.order.orderNumber,
    };

    this.setState({ waitingForCancelling: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            this.setState({
              leaseCancelled: true,
              errorMessage: null,
              message: null,
              waitingForCancelling: false,
            });
            if (this.props.cancelledHandler) {
              this.props.cancelledHandler();
            }
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              message: null,
              waitingForCancelling: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to cancel lease contracts",
          message: null,
          waitingForCancelling: false,
        });
      });
  }
  cancelForm(leaseInfo, statusInfo) {
    const clsName = this.state.waitingForCancelling
      ? "disabled-lightgreen-btn"
      : "lightgreen-btn";
    let reason = "";

    if (statusInfo.status == "Contracts Received" && !statusInfo.okToDeliver) {
      reason = (
        <label>
          The customer has <b>NOT</b> made the initial payment to Progressive
          Leasing.&nbsp;{" "}
        </label>
      );
    } else if (statusInfo.status == "Contracts") {
      reason = (
        <label>
          The customer has <b>NOT</b> signed the lease agreement.&nbsp;{" "}
        </label>
      );
    }
    return (
      <fieldset className="fs-container">
        <legend>
          <font color="green">Progressive Leasing</font>
        </legend>
        <div className="bottom-10">
          {reason}You can cancel the lease contracts by clicking the button
          below. The order will also be cancelled. After successful
          cancellation, the lease application will change to Approved state, and
          the customer can use the lease application to order again.
          <p />
          <div className="generic-flex">
            <button
              name="cancelContracts"
              className={clsName}
              onClick={this.cancelContracts}
            >
              Cancel Lease Contracts
            </button>
            {this.state.waitingForCancelling && (
              <div className="left-10">
                <img
                  src={getIconBaseUrl() + "working.gif"}
                  height="25"
                  alt="Waiting"
                />
              </div>
            )}
          </div>
        </div>
      </fieldset>
    );
  }
  confirmCancel() {
    document.getElementById("hiddenCancelConfirmBtn").click();
  }
  cancelDialog() {
    let msg = (
      <div>
        The lease has been approved, the order is OK to deliver.
        <p />
        Are you sure you want to cancel the lease contracts and the order?
      </div>
    );

    return (
      <ActionConfirmation
        hidden={true}
        btnId="hiddenCancelConfirmBtn"
        actionHandler={this.cancelContracts}
        buttonTitle="Hidden Delete"
        buttonClsName="red-btn-style"
        title="Cancel Confirmation"
        message={msg}
      />
    );
  }
  deliveryConfirmForm(statusInfo, delivery) {
    const action = delivery ? "delivered" : "picked up";
    const now = new Date();
    const maxDate = now.toISOString().split("T")[0];
    const btnStyle = this.state.waitingForConfirmation
      ? "disabled-small-btn"
      : "small-btn";
    const clsName = this.state.waitingForCancelling ? "none" : "left-10";
    const btnClsName = this.state.waitingForCancelling
      ? "disabled-lightgreen-btn"
      : "lightgreen-btn";
    const showWorking =
      this.state.waitingForCancelling || this.state.waitingForConfirmation
        ? true
        : false;
    const addInfo = statusInfo.canCancel
      ? ' You may also cancel the lease contracts by clicking the "Cancel" button.'
      : "";
    return (
      <fieldset className="fs-container">
        <legend>
          <font color="green">Delivery Confirmatio</font>n
        </legend>
        <div className="bottom-10">
          If the order has been {action}, please select the date and then click
          the "Submit Confirmation" button. {addInfo}
        </div>
        <div className="generic-flex">
          {!this.state.waitingForCancelling && (
            <React.Fragment>
              <div>
                <input
                  type="date"
                  name="leaseDeliveryDate"
                  max={maxDate}
                  value={this.state.leaseDeliveryDate || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div className="left-10">
                <button
                  name="submitConfirmation"
                  className={btnStyle}
                  onClick={this.submitDeliveryConfirmation}
                  disabled={this.state.waitingForConfirmation}
                >
                  Submit Confirmation
                </button>
              </div>
            </React.Fragment>
          )}
          {statusInfo.canCancel && !this.state.waitingForConfirmation && (
            <div className={clsName}>
              <button
                name="cancelContracts"
                className={btnClsName}
                onClick={this.confirmCancel}
              >
                Cancel Lease Contracts
              </button>
              {this.cancelDialog()}
            </div>
          )}
          {showWorking && (
            <div className="left-10">
              <img src={getIconBaseUrl() + "working.gif"} height="25" />
            </div>
          )}
        </div>
        <div className="top-10">
          <input
            type="checkbox"
            name="CB_updateLeaseOrderStatus"
            checked={this.state.updateLeaseOrderStatus}
            onChange={this.handleChange}
          />{" "}
          Update order status
        </div>
      </fieldset>
    );
  }
  leaseActionForm() {
    const coi = this.state.coi;
    if (coi && coi.progressiveStatus && !this.state.leaseCancelled) {
      const statusInfo = coi.progressiveStatus;
      let csc = true;

      if (!statusInfo.funded && statusInfo.okToDeliver) {
        const delivery = coi.order.delivery;
        csc = false;
        return this.deliveryConfirmForm(statusInfo, delivery);
      }

      if (csc && statusInfo.canCancel) {
        return this.cancelForm(coi.leaseInfo, statusInfo);
      }
    }
    return "";
  }

  render() {
    return this.leaseActionForm();
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaseActionWidget);
