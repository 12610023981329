import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { isUserLoggedIn, apiBaseUrl, isMobileMode } from "../Util";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
      isLoading: true,
      userGroups: null,
      isLoadingUG: true,
      userId: null,
      updateBtnDisabled: true,
      resetBtnDisabled: true,
      message: null,
      errorMessage: null,
      editAllowed: checkUpdate("User"),
      viewAllowed: checkView("User"),
      resetAllowed: checkSecurity("ResetPassword"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.addUserClicked = this.addUserClicked.bind(this);
    this.updateUserClicked = this.updateUserClicked.bind(this);
    this.processDataResponse = this.processDataResponse.bind(this);
    this.processResetResponse = this.processResetResponse.bind(this);
    this.resetPasswordClicked = this.resetPasswordClicked.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    if (!isUserLoggedIn(this.props.userState)) {
      this.props.history.push("/login");
      return;
    }

    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.fetchData("Users");
    this.fetchData("Groups");
  }

  processDataResponse(response, type) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const objList = response.objList;
      if (type === "Users") {
        this.setState({ users: objList, isLoading: false });
      } else {
        this.setState({ userGroups: objList, isLoadingUG: false });
      }
    }
  }

  fetchData(type) {
    const url = apiBaseUrl() + "GetAll" + type;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processDataResponse(res.data, type);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    if (event.target.value != -1) {
      this.setState({
        [event.target.name]: event.target.value,
        updateBtnDisabled: false,
        resetBtnDisabled: false,
      });
    }
  }

  addUserClicked(event) {
    this.props.history.push("/user");
  }

  updateUserClicked(event) {
    this.props.history.push("/user/" + this.state.userId);
  }

  processResetResponse(response) {
    if (response.success) {
      this.setState({
        message: "User's password was successfully reset. " + response.message,
        errorMessage: null,
        resetBtnDisabled: true,
      });
    } else {
      this.setState({ errorMessage: response.errorMessage, message: null });
    }
  }
  resetPasswordClicked(event) {
    const url = apiBaseUrl() + "ResetPassByRoot";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      tbmUser: { userId: this.state.userId },
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResetResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  deleteUser(event) {
    const url = apiBaseUrl() + "DeleteUser";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      tbmUser: { userId: this.state.userId },
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getGroupName(user) {
    if (!this.state.isLoadingUG && this.state.userGroups) {
      const grp = this.state.userGroups.find(
        (group) => group.id === user.groupId
      );
      if (grp) return grp.name;
    }
    return user.groupId;
  }

  showUserInfo() {
    if (this.state.userId) {
      const theUser = this.state.users.find(
        (user) => user.userId === this.state.userId
      );
      if (theUser) {
        return (
          <div>
            <b>User Info:</b> <br />
            <div className="form-wrapper-with-border">
              <div className="form-item-label">User ID: </div>
              <div className="form-item-value">{theUser.userId}</div>
              <div className="form-item-label">Group: </div>
              <div className="form-item-value">
                {this.getGroupName(theUser)}
              </div>
              <div className="form-item-label">Name: </div>
              <div className="form-item-value">
                {theUser.firstName} {theUser.lastName}
              </div>
              <div className="form-item-label">Email Address: </div>
              <div className="form-item-value">{theUser.email}</div>
              <div className="form-item-label">Phone Number: </div>
              <div className="form-item-value">{theUser.mobilePhone}</div>
            </div>
          </div>
        );
      }
    }
    return "";
  }

  showButtons() {
    const updClsName = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const resetClsName = this.state.resetBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    return (
      <div className="left-10" valign="top">
        {this.state.editAllowed && (
          <React.Fragment>
            <button
              name="addUser"
              className="btn-style"
              onClick={this.addUserClicked}
            >
              Add User
            </button>
            <p />
            <button
              name="updateUser"
              className={updClsName}
              disabled={this.state.updateBtnDisabled}
              onClick={this.updateUserClicked}
            >
              Update User
            </button>
          </React.Fragment>
        )}
        <p />
        {this.state.resetAllowed && (
          <button
            name="resetPass"
            className={resetClsName}
            disabled={this.state.resetBtnDisabled}
            onClick={this.resetPasswordClicked}
          >
            Reset Password
          </button>
        )}
        <p />
        {this.state.editAllowed && (
          <button
            name="deleteUser"
            className={updClsName}
            disabled={this.state.updateBtnDisabled}
            onClick={this.deleteUser}
          >
            Delete User
          </button>
        )}
        <p />
      </div>
    );
  }

  showUserList() {
    const userId = this.state.userId ? this.state.userId : "";
    return (
      <div>
        <select
          name="userId"
          size="10"
          value={userId}
          onChange={this.handleChange}
        >
          {this.state.users.map((user) => {
            return (
              <option value={user.userId} key={user.userId}>
                &nbsp;&nbsp;{user.firstName} {user.lastName} &nbsp;&nbsp;
              </option>
            );
          })}
          {this.state.users.length === 0 && (
            <option value="-1"> &nbsp;&nbsp;[No User] &nbsp;&nbsp;</option>
          )}
        </select>
      </div>
    );
  }

  showAll() {
    if (isMobileMode()) {
      return (
        <React.Fragment>
          <div className="flex-wrapper-top">
            {this.showUserList()}
            {this.showButtons()}
            <div className="width-20"></div>
          </div>
          {this.showUserInfo()}
        </React.Fragment>
      );
    } else {
      return (
        <div className="flex-wrapper-top">
          {this.showUserList()}
          {this.showButtons()}
          <div className="width-20"></div>
          {this.showUserInfo()}
        </div>
      );
    }
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Manage Users</font>
          <p />
          {this.showAll()}
          <p />
          <font color="blue">{this.state.message}</font>
          <br />
          <font color="red">{this.state.errorMessage}</font>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userState: state.user,
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
