import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, toAmountEx, getIconBaseUrl } from "../Util";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../pdf.css";

class PrintOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commonOrderInfo: null,
      isLoading: true,
      email: null,
      phone: null,
      recipientEditable: true,
      sendSMS: false,
      sendEmail: false,
      sendBtnEnabled: false,
      message: null,
      errorMessage: null,
    };
    this.fetchOrder = this.fetchOrder.bind(this);
    this.adjustPdfSetting = this.adjustPdfSetting.bind(this);
    this.savePDF = this.savePDF.bind(this);
    this.printPDF = this.printPDF.bind(this);
    this.sendPDF = this.sendPDF.bind(this);
    this.doSendOrderPdf = this.doSendOrderPdf.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.fetchOrder();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.order != prevProps.order ||
      this.props.orderNumber != prevProps.orderNumber
    ) {
      this.fetchOrder();
    }
  }
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    let sendBtnEnabled = this.state.sendBtnEnabled;

    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
      if (event.target.checked) sendBtnEnabled = true;
      else {
        if (name === "sendEmail") {
          sendBtnEnabled = this.state.sendSMS;
        } else {
          sendBtnEnabled = this.state.sendEmail;
        }
      }
    }
    this.setState({
      [name]: val,
      sendBtnEnabled,
      message: null,
      errorMessage: null,
    });
  }
  fetchOrder() {
    if (this.props.order) {
      const addr = this.props.order.contactAddress;
      const email = addr ? addr.email : null;
      const phone = addr ? addr.phone : null;
      this.setState({
        commonOrderInfo: this.props.order,
        email,
        phone,
        isLoading: false,
      });
      this.adjustPdfSetting(this.props.order);
    } else {
      const url = apiBaseUrl() + "GetOrderForPrint";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        value: this.props.orderNumber,
        flag: this.props.isLayaway,
      };
      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          if (res.data.data) {
            const addr = res.data.data.contactAddress;
            const email = addr ? addr.email : null;
            const phone = addr ? addr.phone : null;
            this.adjustPdfSetting(res.data.data);
            this.setState({
              commonOrderInfo: res.data.data,
              email,
              phone,
              isLoading: false,
            });
          } else {
            this.setState({
              errorMessage: "Order not found",
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  doSendOrderPdf(base64) {
    const url = apiBaseUrl() + "SendOrderPdf";
    const email = this.state.sendEmail ? this.state.email : null;
    const phone = this.state.sendSMS ? this.state.phone : null;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      base64,
      orderNumber: this.state.commonOrderInfo.order.orderNumber,
      email,
      phone,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.status) {
          this.setState({
            message: "PDF successfully sent",
            errorMessage: null,
            sendBtnEnabled: false,
            sendEmail: false,
            sendSMS: false,
          });
        } else {
          this.setState({ errorMessage: "Failed to send PDF", message: null });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  adjustPdfSetting(coi) {
    if (coi && coi.pdfSetting) {
      const s = coi.pdfSetting;

      document.documentElement.style.setProperty(
        "--pdf-page-width",
        "" + s.pageWidth + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-page-height",
        "" + s.pageHeight + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-margin-left",
        "" + s.marginLeft + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-margin-bottom",
        "" + s.marginBottom + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-margin-top",
        "" + s.marginTop + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-head-gap",
        "" + s.headGap + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-shipto-left",
        "" + s.shipToLeft + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-table-percent",
        "" + s.tablePercent + "%"
      );

      document.documentElement.style.setProperty(
        "--pdf-fs-reg-table",
        s.fsRegTable
      );
      document.documentElement.style.setProperty(
        "--pdf-fs-large-table",
        s.fsLargeTable
      );
      document.documentElement.style.setProperty(
        "--pdf-fs-left-header",
        s.fsLeftHeader
      );
      document.documentElement.style.setProperty(
        "--pdf-fs-right-header",
        s.fsRightHeader
      );
      document.documentElement.style.setProperty("--pdf-fs-footer", s.fsFooter);
      document.documentElement.style.setProperty("--pdf-fs-note", s.fsNote);
      document.documentElement.style.setProperty(
        "--pdf-fs-header2",
        s.fsHeader2
      );
    }
  }
  headerLeft() {
    const coi = this.state.commonOrderInfo;
    return (
      <div className="pdf-hdr-left">
        <img src={getIconBaseUrl() + "invoicelogo.jpg"} height="40" alt="FWL" />
        {coi && coi.pdfSetting ? (
          <div dangerouslySetInnerHTML={{ __html: coi.pdfSetting.headLeft }} />
        ) : (
          <React.Fragment>
            <br />
            https://www.furniturewayless.com
            <br />
            Marietta: (678)819-7591 (11am-7pm)
            <br />
            Jonesboro: (678)819-8777 (11am-7pm)
            <br />
            Decatur: (404)284-1688 (11am-7pm, Closed Tue)
            <br />
            Warehouse: (404)800-5805 (10am-7pm)
            <br />
            Repair/Parts: fwlcod@gmail.com
          </React.Fragment>
        )}
      </div>
    );
  }
  headerRight() {
    const order = this.state.commonOrderInfo.order;
    const title = this.state.commonOrderInfo.layaway
      ? "Layaway"
      : "Internet Order";
    return (
      <div className="pdf-hdr-right">
        <font size="4">{title}</font>
        <p />
        <div className="col2-table">
          <div>Date:</div>
          <div className="left-10">{order.ordDateStr}</div>
          <div>Order No.:</div>
          <div className="left-10">{order.orderNumber}</div>
          <div>Salesperson:</div>
          <div className="left-10">{order.salesperson}</div>
        </div>
      </div>
    );
  }
  billingAndShipping() {
    const coi = this.state.commonOrderInfo;
    const order = coi.order;
    const contact = coi.contactAddress;
    let shippingInfo = "Ship To:<br/>";

    if (order.delivery) {
      let addr = coi.deliveryAddress;
      if (!addr) addr = contact;

      shippingInfo += addr.addressLine1 + "&nbsp;&nbsp;";
      if (addr.addressLine2) shippingInfo += addr.addressLine2;
      shippingInfo += "<br/>";
      shippingInfo +=
        addr.city + ", " + addr.state + " " + addr.zipCode + "<br/>";
    } else {
      if (coi.pickupLoc) {
        shippingInfo +=
          "Pickup at " + coi.pickupLoc.locationInfo.name + "<br/>";
      } else {
        shippingInfo += "Pickup at Warehouse<br/>";
      }
    }
    return (
      <div className="pdf-header2">
        <div>
          Bill To:
          <br />
          {contact.phone}
          <br />
          {contact.firstName} {contact.lastName}
        </div>
        <div
          className="pdf-shipping"
          dangerouslySetInnerHTML={{ __html: shippingInfo }}
        ></div>
      </div>
    );
  }
  orderNote() {
    const order = this.state.commonOrderInfo.order;
    let note = order.deliveryInstr;
    const nl = this.state.commonOrderInfo.noteList;

    if (nl && nl.length > 0) {
      for (let i = 0; i < nl.length; i++) {
        if (nl[i].publicNote) {
          if (!note) note = "";
          else note += "<br/>";
          note += nl[i].note;
        }
      }
    }
    if (order.delivery) {
      const ddStr =
        "Delivery Date: " + (order.schDateStr ? order.schDateStr : "Will call");
      if (note) {
        note = ddStr + "<br/>" + note;
      } else note = ddStr;
    }
    if (this.state.commonOrderInfo.layaway) {
      if (order.nextPmtDateStr) {
        const ndStr = "Next Payment Due: " + order.nextPmtDateStr;
        if (!note) note = ndStr;
        else note += "<br/>" + ndStr;
      }
    }
    if (note) {
      return (
        <div
          className="pdf-note"
          dangerouslySetInnerHTML={{ __html: note }}
        ></div>
      );
    }
  }
  footer() {
    const coi = this.state.commonOrderInfo;
    if (coi && coi.pdfSetting) {
      return (
        <div
          className="pdf-footer"
          dangerouslySetInnerHTML={{ __html: coi.pdfSetting.footer }}
        />
      );
    } else {
      return (
        <div className="pdf-footer">
          1. Pickup location 4795 Fulton Industrial Blvd Atlanta GA 30336 Suit B
          (404) 800 - 5805 &nbsp;&nbsp;&nbsp; 2. There is 15% restocking fee for
          returned items, no used returns accepted. &nbsp;&nbsp; 3. All claims
          must be made within 3 days after delivery/pickup. &nbsp;&nbsp; 4.
          Assembly is required for most furniture, delivery fee doesn't include
          assembly, you can pay drivers to assemble it.&nbsp;&nbsp; Do not open
          boxes with sharp objects, such as knife. Damage by cut is not eligible
          for exchange or return.
          <p />
          Driver will call 30min - 1hour prior to delivery, we are unable to
          offer specific time window.
          <p />
          &nbsp;
          <p />
          <font size="2">Signature _______________________</font>
        </div>
      );
    }
  }
  showPayments(coi) {
    if (!coi.layaway) {
      if (coi.paymentList && coi.paymentList.length > 0) {
        return true;
      }
    }
    return false;
  }
  isStoreItemPayment(storeItem, layaway) {
    if (layaway) {
      if (storeItem.typeId > 0) return true;
    } else {
      if (storeItem.typeId > 0 && storeItem.typeId != 9) return true;
    }
    return false;
  }
  getLeaseApplFee() {
    const coi = this.state.commonOrderInfo;
    if (!coi.layaway && coi.order.leaseToOwn && coi.order.leaseId > 0) {
      return coi.order.leaseApplFee;
    }
    return 0;
  }
  progressiveInfo(colspan) {
    const coi = this.state.commonOrderInfo;
    if (!coi.layaway && coi.order.leaseToOwn && coi.order.leaseId > 0) {
      const info = coi.leaseInfo;
      if (info) {
        const ps = coi.progressiveStatus;
        const status = ps && ps.funded ? "Funded" : "Pending";
        const color = ps && ps.funded ? "red" : "orange";
        return (
          <tr>
            <td colspan={colspan} align="right" style={{ border: "none" }}>
              Progressive Leasing:
              <br />
              Lease ID: {info.leaseId}
            </td>
            <td align="right" style={{ border: "none" }}>
              {status}
              <br />
              <font color={color}>-{toAmountEx(info.cashPrice)}</font>
            </td>
          </tr>
        );
      }
    }
  }
  getProgressivePayment() {
    const coi = this.state.commonOrderInfo;
    if (!coi.layaway && coi.order.leaseToOwn && coi.order.leaseId > 0) {
      const info = coi.leaseInfo;
      if (info) {
        return info.cashPrice;
      }
    }
    return 0;
  }
  orderDetail() {
    const coi = this.state.commonOrderInfo;
    const order = coi.order;
    const orderItems = coi.orderItemList;
    const storeItems = coi.storeItemList;
    const storeCoupons = coi.storeCouponList;
    const payments = coi.paymentList;
    let balance = null;
    let paidInFull = false;
    let itemCount = 0;
    const showPmts = this.showPayments(coi);
    const applFee = this.getLeaseApplFee();

    if (showPmts) {
      //For store layaway, the payments are included in storeItems
      itemCount += payments.length;
      balance = order.totalPrice;
      for (let i = 0; i < payments.length; i++) {
        balance -= payments[i].amount;
        if (balance < 1.0) paidInFull = true;
      }
    }
    if (storeItems != null) {
      for (let i = 0; i < storeItems.length; i++) {
        if (this.isStoreItemPayment(storeItems[i], coi.layaway)) {
          if (!balance) balance = order.totalPrice;
          balance -= storeItems[i].price;
          if (balance < 1.0) paidInFull = true;
        }
      }
    }
    const pp = this.getProgressivePayment();
    if (pp > 0) {
      if (!balance) balance = order.totalPrice;
      balance -= this.getProgressivePayment();
    }

    if (orderItems) itemCount += orderItems.length;
    if (storeItems) itemCount += storeItems.length;
    if (storeCoupons) itemCount += storeCoupons.length;
    if (order.discount > 0) itemCount++;
    const clsName = itemCount > 8 ? "pdf-table-xs" : "pdf-table";

    const hdr = (
      <tr>
        <th className="pdf-th">Qty</th>
        <th className="pdf-th">Item</th>
        <th className="pdf-th">Description</th>
        <th className="pdf-th">Unit Price</th>
        <th className="pdf-th">Total</th>
      </tr>
    );
    return (
      <table className={clsName} cellSpacing="0" cellPadding="0">
        <tbody>
          {hdr}
          {orderItems &&
            orderItems.map((item, idx) => {
              const total = item.item.unitPrice * item.item.quantity;
              return (
                <tr key={idx}>
                  <td align="center">{item.item.quantity}</td>
                  <td width="20%" style={{ wordWrap: "break-word" }}>
                    {item.item.sku}
                  </td>
                  <td>{item.productName}</td>
                  <td align="right">{toAmountEx(item.item.unitPrice)}</td>
                  <td align="right">{toAmountEx(total)}</td>
                </tr>
              );
            })}
          {storeItems &&
            storeItems.map((item, idx) => {
              if (item.typeId <= 0) {
                const total = item.price * item.quantity;
                let name = item.name;

                if (name.startsWith("TYPED_")) name = name.substring(6);
                return (
                  <tr key={idx}>
                    <td align="center">{item.quantity}</td>
                    <td>{name}</td>
                    <td>Store Item</td>
                    <td align="right">{toAmountEx(item.price)}</td>
                    <td align="right">{toAmountEx(total)}</td>
                  </tr>
                );
              }
            })}
          {order.discountName && order.discount > 0 && (
            <tr>
              <td align="center">1</td>
              <td>{order.discountName}</td>
              <td>Discount</td>
              <td align="right">
                <font color="red">{toAmountEx(0 - order.discount)}</font>
              </td>
              <td align="right">
                <font color="red">{toAmountEx(0 - order.discount)}</font>
              </td>
            </tr>
          )}
          <tr>
            <td colspan="4" align="right" style={{ border: "none" }}>
              Subtotal:
            </td>
            <td align="right" style={{ border: "none" }}>
              {toAmountEx(order.subtotal)}
            </td>
          </tr>
          <tr>
            <td colspan="4" align="right" style={{ border: "none" }}>
              Tax:
            </td>
            <td align="right" style={{ border: "none" }}>
              {toAmountEx(order.tax)}
            </td>
          </tr>
          {order.delivery && order.deliveryFee > 0 && (
            <tr>
              <td colspan="4" align="right" style={{ border: "none" }}>
                Delivery Fee:
              </td>
              <td align="right" style={{ border: "none" }}>
                {toAmountEx(order.deliveryFee)}
              </td>
            </tr>
          )}
          {applFee > 0 && (
            <tr>
              <td colspan={4} align="right" style={{ border: "none" }}>
                Application fee:
              </td>
              <td align="right" style={{ border: "none" }}>
                {toAmountEx(applFee)}
              </td>
            </tr>
          )}
          {storeCoupons &&
            storeCoupons.length > 0 &&
            storeCoupons.map((coupon, idx) => {
              let name = storeCoupons[idx].name;
              if (name.startsWith("COUPON_")) name = name.substring(7);
              return (
                <tr key={idx}>
                  <td colspan="4" align="right" style={{ border: "none" }}>
                    {name}:
                  </td>
                  <td align="right" style={{ border: "none" }}>
                    <font color="red">{toAmountEx(coupon.price)}</font>
                  </td>
                </tr>
              );
            })}
          <tr>
            <td colspan="4" align="right" style={{ border: "none" }}>
              Order Total:
            </td>
            <td align="right" style={{ border: "none" }}>
              {toAmountEx(order.totalPrice)}
            </td>
          </tr>
          {this.progressiveInfo(4)}
          {showPmts &&
            payments.map((pmt, idx) => {
              let name = pmt.paymentName;

              if (name && !name.endsWith("Payment") && !name.endsWith("Paid")) {
                name += " Paid";
              }

              if (pmt.dateStr) name += " (" + pmt.dateStr + ")";
              return (
                <tr key={idx}>
                  <td colspan="4" align="right" style={{ border: "none" }}>
                    {name}:
                  </td>
                  <td align="right" style={{ border: "none" }}>
                    <font color="red">{toAmountEx(0 - pmt.amount)}</font>
                  </td>
                </tr>
              );
            })}
          {storeItems &&
            storeItems.map((item, idx) => {
              if (this.isStoreItemPayment(item, coi.layaway)) {
                //typeId=9 is online payment which already included in the payments
                const amount = item.price > 0 ? item.price : 0 - item.price;
                const isReturn = item.price < 0;
                let name = item.name;

                if (name.startsWith("TYPED_")) name = name.substring(6);
                if (isReturn) name += " (Return)";
                if (item.item && item.item.dateStr) {
                  name += " (" + item.item.dateStr + ")";
                }
                return (
                  <tr key={idx}>
                    <td colspan="4" align="right" style={{ border: "none" }}>
                      {name}
                    </td>
                    <td align="right" style={{ border: "none" }}>
                      <font color="red">{toAmountEx(0 - amount)}</font>
                    </td>
                  </tr>
                );
              }
            })}
          {paidInFull && (
            <tr>
              <td colspan="5" align="right" style={{ border: "none" }}>
                <i>
                  <font color="green">Paid in full</font>
                </i>
              </td>
            </tr>
          )}
          {balance != null && !paidInFull && (
            <tr>
              <td colspan="4" align="right" style={{ border: "none" }}>
                <i>Balance</i>:
              </td>
              <td align="right" style={{ border: "none" }}>
                {toAmountEx(balance)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  printableInfo() {
    return (
      <div className="pdf-container">
        <div className="generic-flex">
          {this.headerLeft()}
          {this.headerRight()}
        </div>
        {this.billingAndShipping()}
        {this.orderDetail()}
        {this.orderNote()}
        {this.footer()}
      </div>
    );
  }

  savePDF() {
    const order = this.state.commonOrderInfo.order;
    const fileName = order.storeName + "-" + order.orderNumber + ".pdf";
    const html = ReactDOMServer.renderToStaticMarkup(this.printableInfo());
    let doc = new jsPDF("p", "pt", "a4");

    doc.html(html, {
      callback: function (pdf) {
        const pageCount = pdf.internal.getNumberOfPages();
        if (pageCount > 1) pdf.deletePage(pageCount);
        pdf.save(fileName);
      },
    });
  }
  printPDF() {
    const html = ReactDOMServer.renderToStaticMarkup(this.printableInfo());
    let doc = new jsPDF("p", "pt", "a4");

    doc.html(html, {
      callback: function (pdf) {
        const pageCount = pdf.internal.getNumberOfPages();
        if (pageCount > 1) pdf.deletePage(pageCount);
        pdf.autoPrint();
        pdf.output("dataurlnewwindow");
      },
    });
  }
  sendPDF(sender) {
    if (this.state.sendEmail) {
      let email = this.state.email;
      if (
        !email ||
        email.trim().length === 0 ||
        email.indexOf("@") <= 0 ||
        email.indexOf(".") <= 0
      ) {
        this.setState({ errorMessage: "Missing/Invalid email address" });
        return;
      }
    }
    if (this.state.sendSMS) {
      let phone = this.state.phone;
      if (!phone || phone.trim().length === 0) {
        this.setState({ errorMessage: "Phone number is required" });
        return;
      }
    }

    const html = ReactDOMServer.renderToStaticMarkup(this.printableInfo());
    let doc = new jsPDF("p", "pt", "a4");

    doc.html(html, {
      callback: function (pdf) {
        const pageCount = pdf.internal.getNumberOfPages();
        if (pageCount > 1) pdf.deletePage(pageCount);

        let base64 = btoa(pdf.output());
        sender(base64);
      },
    });
  }
  sendPdfSect() {
    if (this.props.sendPdfEnabled) {
      const editable = this.state.recipientEditable;
      const showPhone =
        editable || (this.state.phone && this.state.phone.trim().length > 0)
          ? true
          : false;
      const showEmail =
        editable || (this.state.email && this.state.email.trim().length > 0)
          ? true
          : false;
      const clsName = this.state.sendBtnEnabled
        ? "pdf-btn"
        : "disabled-pdf-btn";
      const smsIcon = (
        <img src={getIconBaseUrl() + "sms.jpg"} height="12" alt="SMS" />
      );
      const emailIcon = (
        <img src={getIconBaseUrl() + "email.jpg"} height="12" alt="Email" />
      );
      let btnIcons = null;
      if (this.state.sendSMS && this.state.sendEmail) {
        btnIcons = (
          <React.Fragment>
            {smsIcon}&nbsp;{emailIcon}
          </React.Fragment>
        );
      } else if (this.state.sendSMS) {
        btnIcons = smsIcon;
      } else if (this.state.sendEmail) {
        btnIcons = emailIcon;
      }

      return (
        <div className="pdf-send-wrapper">
          <div className="col2-table">
            {showPhone && (
              <React.Fragment>
                <div className="left-10">
                  <input
                    type="checkbox"
                    name="CB_sendSMS"
                    onChange={this.handleChange}
                  ></input>{" "}
                  SMS
                </div>
                <div className="pdf-left5">
                  <input
                    type="text"
                    size="12"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    disabled={!editable || !this.state.sendSMS}
                  />
                </div>
              </React.Fragment>
            )}
            {showEmail && (
              <React.Fragment>
                <div className="left-10">
                  <input
                    type="checkbox"
                    name="CB_sendEmail"
                    onChange={this.handleChange}
                  ></input>{" "}
                  Email
                </div>
                <div className="pdf-left5">
                  <input
                    type="text"
                    size="20"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    disabled={!editable || !this.state.sendEmail}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="pdf-l10t5">
            <button
              className={clsName}
              onClick={() => this.sendPDF(this.doSendOrderPdf)}
              disabled={!this.state.sendBtnEnabled}
            >
              {btnIcons}&nbsp;Send Invoice to Customer
            </button>
          </div>

          {this.state.message && <div>{this.state.message}</div>}
          {this.state.errorMessage && (
            <div>
              <font color="red">{this.state.errorMessage}</font>
            </div>
          )}
        </div>
      );
    }
  }
  invoiceSection() {
    const coi = this.state.commonOrderInfo;
    const enabled =
      coi && coi.pdfSetting && coi.pdfSetting.sendEnabled ? true : false;

    if (enabled || this.props.sendPdfEnabled) {
      return (
        <fieldset className="fs-container">
          <legend>
            <font color="green">Invoice</font>
          </legend>
          <div className="pdf-flex">
            <div>
              <div>
                <button className="pdf-btn" onClick={this.printPDF}>
                  <img
                    src={getIconBaseUrl() + "print.jpg"}
                    height="12"
                    alt="PDF"
                  />
                  &nbsp; Print Invoice
                </button>
              </div>
              <div className="top-10">
                <button className="pdf-btn" onClick={this.savePDF}>
                  <img
                    src={getIconBaseUrl() + "pdf.jpg"}
                    height="12"
                    alt="PDF"
                  />
                  &nbsp;Download PDF
                </button>
              </div>
            </div>
            <div className="left-20">{this.sendPdfSect()}</div>
          </div>
        </fieldset>
      );
    } else {
      return (
        <div className="pdf-flex">
          <div>
            <button className="pdf-btn" onClick={this.printPDF}>
              <img src={getIconBaseUrl() + "print.jpg"} height="12" alt="PDF" />
              &nbsp; Print Invoice
            </button>
          </div>
          <div className="left-10">
            <button className="pdf-btn" onClick={this.savePDF}>
              <img src={getIconBaseUrl() + "pdf.jpg"} height="12" alt="PDF" />
              &nbsp;Download PDF
            </button>
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="pdf-top-div">
          <img src={getIconBaseUrl() + "working.gif"} height="50" />
        </div>
      );
    }

    if (!this.state.commonOrderInfo)
      return (
        <div className="pdf-top-div">
          <font color="red">Order not found</font>
        </div>
      );

    return this.invoiceSection();
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
export default connect(mapStateToProps)(PrintOrder);
