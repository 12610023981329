import React from "react";
import { connect } from "react-redux";
import { setResetMsg } from "../actions/userActions";

import "../../App.css";
import "../../generic.css";

class ResetMessage extends React.Component {
  constructor(props) {
    super(props);

    this.processOkResponse = this.processOkResponse.bind(this);
  }
  componentDidMount() {
    if (!this.props.resetMsg) {
      this.props.history.push("/login");
    }
  }
  processOkResponse() {
    this.props.setResetMsg(null);
    this.props.history.push("/login");
  }
  render() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Password Reset Success</font>
          <p />
          You password has been successfully reset. &nbsp;
          {this.props.resetMsg}
          <p />
          Click the button below to login using the tempory password.
          <p />
          <button
            name="ok"
            onClick={this.processOkResponse}
            className="btn-style"
          >
            Okay
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userState: state.user,
    resetMsg: state.user.resetMsg
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setResetMsg: msg => {
      dispatch(setResetMsg(msg));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetMessage);
