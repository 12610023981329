import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import Working from "../Working";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";

import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../setting.css";

class EditProgressiveSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      disabledVisible: false,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      viewAllowed: checkView("Setting"),
      editAllowed: checkUpdate("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetProgressiveSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    const disabledVisible =
      response.data.disabled || checkSecurity("RootFeatures") ? true : false;
    this.setState({
      settings: response.data,
      disabledVisible,
      isLoading: false,
    });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    let enableStoreLookup = this.state.settings.enableStoreLookup;

    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
      if (name == "enableStoreOrder") {
        if (event.target.checked) {
          enableStoreLookup = true;
        }
      } else if (name == "enableStoreLookup") {
        enableStoreLookup = event.target.checked;
      }
    }
    const settings = {
      ...this.state.settings,
      [name]: val,
      enableStoreLookup,
    };

    this.setState({
      settings,
      updateBtnDisabled: false,
    });
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateProgressiveSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.settings,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  storeSettings(settings) {
    return (
      <fieldset className="fs-container">
        <legend>Store Settings</legend>
        <div className="generic-form-container">
          <div className="form-item-label">Store Appl Fee: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="storeApplFee"
              value={settings.storeApplFee}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Redirect Delay: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="storeRedirectDelay"
              value={settings.storeRedirectDelay}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">UIPoll Interval: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="uiStorePollInterval"
              value={settings.uiStorePollInterval}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Settings: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_enableStoreLookup"
              checked={settings.enableStoreLookup}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || settings.enableStoreOrder}
            ></input>{" "}
            Enable lookup for store orders
            <br />
            <input
              type="checkbox"
              name="CB_enableStoreOrder"
              checked={settings.enableStoreOrder}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Enable store orders
          </div>
          <div className="form-item-label">Verification: </div>
          {this.storeVerification()}
        </div>
      </fieldset>
    );
  }
  storeVerification() {
    const settings = this.state.settings;
    return (
      <div className="form-item-value">
        <fieldset className="fs-verify-container">
          <legend>Delivery</legend>
          <div>
            <input
              type="checkbox"
              name="CB_verifyNameForStore"
              defaultChecked={settings.verifyNameForStore}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Verify customer name
            <br />
            <input
              type="checkbox"
              name="CB_verifyAddressForStore"
              defaultChecked={settings.verifyAddressForStore}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Verify customer address
            <br />
            {settings.verifyAddressForStore && (
              <React.Fragment>
                <input
                  type="checkbox"
                  name="CB_verifyCityForStore"
                  checked={settings.verifyCityForStore}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                ></input>{" "}
                Verify city
              </React.Fragment>
            )}
          </div>
        </fieldset>
        <fieldset className="fs-verify-container">
          <legend>Pickup</legend>
          <div>
            <input
              type="checkbox"
              name="CB_verifyPickNameForStore"
              defaultChecked={settings.verifyPickNameForStore}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Verify customer name
            <br />
            <input
              type="checkbox"
              name="CB_verifyPickAddressForStore"
              defaultChecked={settings.verifyPickAddressForStore}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Verify customer address
            <br />
            {settings.verifyPickAddressForStore && (
              <React.Fragment>
                <input
                  type="checkbox"
                  name="CB_verifyPickCityForStore"
                  checked={settings.verifyPickCityForStore}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                ></input>{" "}
                Verify city
              </React.Fragment>
            )}
          </div>
        </fieldset>
      </div>
    );
  }
  internetVerification() {
    const settings = this.state.settings;
    return (
      <div className="form-item-value">
        <fieldset className="fs-verify-container">
          <legend>Delivery</legend>
          <div>
            <input
              type="checkbox"
              name="CB_verifyName"
              defaultChecked={settings.verifyName}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Verify customer name
            <br />
            <input
              type="checkbox"
              name="CB_verifyAddress"
              defaultChecked={settings.verifyAddress}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Verify customer address
            <br />
            {settings.verifyAddress && (
              <React.Fragment>
                <input
                  type="checkbox"
                  name="CB_verifyCity"
                  checked={settings.verifyCity}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                ></input>{" "}
                Verify city
              </React.Fragment>
            )}
          </div>
        </fieldset>
        <fieldset className="fs-verify-container">
          <legend>Pickup</legend>
          <div>
            <input
              type="checkbox"
              name="CB_verifyPickName"
              defaultChecked={settings.verifyPickName}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Verify customer name
            <br />
            <input
              type="checkbox"
              name="CB_verifyPickAddress"
              defaultChecked={settings.verifyPickAddress}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Verify customer address
            <br />
            {settings.verifyPickAddress && (
              <React.Fragment>
                <input
                  type="checkbox"
                  name="CB_verifyPickCity"
                  checked={settings.verifyPickCity}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                ></input>{" "}
                Verify city
              </React.Fragment>
            )}
          </div>
        </fieldset>
      </div>
    );
  }
  theForm() {
    const settings = this.state.settings;
    const updDisabled = this.state.updateBtnDisabled || !this.state.editAllowed;
    const btnStyle = updDisabled ? "disabled-btn-style" : "btn-style";
    const cols = this.state.mobileMode ? 35 : 70;
    const rows = this.state.mobileMode ? 9 : 5;
    const maxDisabled =
      settings.allowCod || settings.allowCreditCard ? false : true;
    const cancelLabel = this.state.mobileMode
      ? "Cancel Status"
      : "Cancellable Status";
    const remindLabel = this.state.mobileMode
      ? "Esign Remind"
      : "E-Sign Reminder";
    const limitLabel = this.state.mobileMode
      ? "Esign Limit"
      : "E-Sign Wait Limit";
    const snippetLen = this.state.mobileMode ? 35 : 70;
    const applyUrlLen = this.state.mobileMode ? 35 : 50;
    return (
      <div>
        <div className="generic-wrapper">
          <font size="5">Progressive Settings</font>
          <p />
          <div className="form-wrapper">
            <div className="form-item-label">Lease Minimum: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="leaseMinimum"
                value={settings.leaseMinimum}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>
            </div>
            <div className="form-item-label">Application Fee: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="applicationFee"
                value={settings.applicationFee}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>
            </div>
            <div className="form-item-label">ApplFee Label: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="25"
                name="applFeeLabel"
                value={settings.applFeeLabel}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>
            </div>
            <div className="form-item-label">Settings: </div>
            <div className="form-item-value">
              <input
                type="checkbox"
                name="CB_submitItemsForOrder"
                defaultChecked={settings.submitItemsForOrder}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Submit items for order
              <br />
              <input
                type="checkbox"
                name="CB_submitItemsForPrice"
                defaultChecked={settings.submitItemsForPrice}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Submit items for price
              <br />
              <input
                type="checkbox"
                name="CB_allowNameMismatchForStore"
                defaultChecked={settings.allowNameMismatchForStore}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Allow Name Mismatch for Store
              <br />
              <input
                type="checkbox"
                name="CB_show90Day"
                defaultChecked={settings.show90Day}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Show 90-day buyout info
              <br />
              <input
                type="checkbox"
                name="CB_useEstimatorSnippet"
                defaultChecked={settings.useEstimatorSnippet}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Use estimator snippet
              <br />
              <input
                type="checkbox"
                name="CB_useMobileSnippet"
                defaultChecked={settings.useMobileSnippet}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Use snippet for mobile
              <br />
              <input
                type="checkbox"
                name="CB_modifyOrderAllowed"
                defaultChecked={settings.modifyOrderAllowed}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Allow order modification
              {this.state.disabledVisible && (
                <React.Fragment>
                  <br />
                  <input
                    type="checkbox"
                    name="CB_disabled"
                    defaultChecked={settings.disabled}
                    onChange={this.handleChange}
                    disabled={!this.state.editAllowed}
                  ></input>{" "}
                  Disabled!
                </React.Fragment>
              )}
            </div>
            <div className="form-item-label">Verification: </div>
            {this.internetVerification()}
            <div className="form-item-label">Apply URL: </div>
            <div className="form-item-value">
              <input
                type="text"
                size={applyUrlLen}
                name="applyUrl"
                value={settings.applyUrl}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-item-label">Snippet URL: </div>
            <div className="form-item-value">
              <input
                type="text"
                size={snippetLen}
                name="snippetUrl"
                value={settings.snippetUrl}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-item-label">
              <b>Processing</b>
            </div>
            <div className="form-item-value">
              <hr className="hr-240" align="left" />
            </div>
            <div className="form-item-label">Monitor Interval: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="monitorInterval"
                value={settings.monitorInterval}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              (minutes)
            </div>
            <div className="form-item-label">Init Delay: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="initDelay"
                value={settings.initDelay}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              (seconds)
            </div>
            <div className="form-item-label">UIPoll Interval: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="uiPollInterval"
                value={settings.uiPollInterval}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              (seconds)
            </div>
            <div className="form-item-label">BGPoll Interval: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="bgMainPollInterval"
                value={settings.bgMainPollInterval}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              (seconds)
            </div>
            <div className="form-item-label">E-Sign Cutover: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="esignTimeout"
                value={settings.esignTimeout}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              (seconds)
            </div>
            <div className="form-item-label">{remindLabel}: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="esignReminderTime"
                value={settings.esignReminderTime}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              (minutes)
            </div>
            <div className="form-item-label">{limitLabel}: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="esignWaitLimit"
                value={settings.esignWaitLimit}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              (minutes)
            </div>
            <div className="form-item-label">Pending Status: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="35"
                name="esignPendingStatus"
                value={settings.esignPendingStatus}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>
            </div>
            <div className="form-item-label">E-Signed Status: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="35"
                name="esignedStatus"
                value={settings.esignedStatus}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>
            </div>
            <div className="form-item-label">{cancelLabel}: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="35"
                name="canCancelStatus"
                value={settings.canCancelStatus}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>
            </div>
            <div className="form-item-label">Pmt Frequency: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="25"
                name="paymentFrequency"
                value={settings.paymentFrequency}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>
            </div>
            <div className="form-item-label">
              <b>Over Limit</b>
            </div>
            <div className="form-item-value">
              <hr className="hr-240" align="left" />
            </div>
            <div className="form-item-label">Settings: </div>
            <div className="form-item-value">
              <input
                type="checkbox"
                name="CB_allowCod"
                defaultChecked={settings.allowCod}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Allow COD
              <br />
              <input
                type="checkbox"
                name="CB_allowCreditCard"
                defaultChecked={settings.allowCreditCard}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></input>{" "}
              Allow credit card
            </div>
            <div className="form-item-label">OverLimit Max: </div>
            <div className="form-item-value">
              <input
                type="text"
                size="5"
                name="overLimitMax"
                value={settings.overLimitMax}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed || maxDisabled}
              ></input>
              $
            </div>
            <div className="form-item-label">Message: </div>
            <div className="form-item-value">
              <textarea
                cols={cols}
                rows={rows}
                name="exceedLimitMsg"
                value={settings.exceedLimitMsg}
                onChange={this.handleChange}
                disabled={!this.state.editAllowed}
              ></textarea>
            </div>
          </div>
          <div className="top-10">{this.storeSettings(settings)}</div>
          <p />
          <button
            name="update"
            onClick={this.handleUpdate}
            className={btnStyle}
            disabled={updDisabled}
          >
            Update
          </button>
          <p />
          <font color="red">{this.state.errorMessage}</font>
          <br />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProgressiveSetting);
