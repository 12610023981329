import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  getIconBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import Working from "../Working";
import { checkView } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import LeaseOrderTableView from "./LeaseOrderTableView";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../setting.css";

class LeasingOrders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingList: null,
      orderList: null,
      isLoading: true,
      errorMessage: null,
      viewAllowed: checkView("Order"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.gotoLookup = this.gotoLookup.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetLeaseOrders";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
          return;
        }
        this.setState({
          orderList: res.data.leaseOrderList,
          pendingList: res.data.pendingOrderList,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get lease orders",
        });
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  gotoLookup() {
    this.props.history.push("/leaseLookup");
  }

  render() {
    if (this.state.isLoading) return <Working />;
    const clsName = this.state.mobileMode ? "none" : "lease-group-container";
    const clsName2 = this.state.mobileMode ? "top-10" : "none";
    return (
      <div className="top-wrapper">
        <div className="left-10">
          <font size="5">Progressive Leasing Orders</font>
          <p />
          <div className={clsName}>
            {!this.state.mobileMode && (
              <div>
                <img
                  src={getIconBaseUrl() + "pending.jpg"}
                  height="16"
                  alt="Pending"
                />
              </div>
            )}
            <div className={clsName2}>
              <b>Pending Orders</b> -- Lease agreements not signed or initial
              payments not made.
            </div>
            {!this.state.mobileMode && (
              <div>
                <img
                  src={getIconBaseUrl() + "prog-trans.png"}
                  height="16"
                  alt="Pending"
                />
              </div>
            )}
            <div className={clsName2}>
              <b>Lease Orders</b> -- Pending delivery/pickup orders, you can
              also include funded, cancelled, or leaseID=0 orders.
            </div>
          </div>
          <p />
          <label>
            You can also lookup a lease by clicking &nbsp;&nbsp;
            {this.state.mobileMode && <p />}
          </label>
          <label className="green-link-label" onClick={this.gotoLookup}>
            Lookup Lease
          </label>
          <p />
          <LeaseOrderTableView
            orderList={this.state.pendingList}
            title="Pending Orders"
            showFilters={false}
          />
          <p />
          &nbsp;
          <br />
          <LeaseOrderTableView
            orderList={this.state.orderList}
            title="Lease Orders"
            showFilters={true}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeasingOrders);
