import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Lightbox } from "react-modal-image";
import { AXIOS_HEADER } from "../config/constants";
import { checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import ImageWindow from "./ImageWindow";
import Working from "../Working";
import {
  apiBaseUrl,
  prodImgBaseUrl,
  previewImgBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
  fullProductImageUrl,
  manualBaseUrl,
  getIconBaseUrl,
} from "../Util";
import { uploadFiles } from "../FileUploader";
import "../../App.css";
import "../../form.css";
import "../../generic.css";

class ManageImgMan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prodImageData: null,
      allManList: null,
      selIndex: -1,
      isLoading: true,
      imageWidth: 150,
      imageHeight: 120,
      updateDisabled: true,
      mobileMode: false,
      hrWidth: 400,
      modalImageUrl: null,
      editAllowed: checkUpdate("Product"),
      isManual:
        this.props.match.params.id &&
        this.props.match.params.id.substring(0, 2) === "M_"
          ? true
          : false,
    };

    this.processProdRsp = this.processProdRsp.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.addPicFile = this.addPicFile.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.updateImages = this.updateImages.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.copyProdImages = this.copyProdImages.bind(this);
    this.fetchAllInstallManuals = this.fetchAllInstallManuals.bind(this);
    this.copyExisting = this.copyExisting.bind(this);
    this.generateThumbnail = this.generateThumbnail.bind(this);
    this.showModalImage = this.showModalImage.bind(this);
    this.closeModalImage = this.closeModalImage.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.editAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchImageData();
    if (this.state.isManual) {
      this.fetchAllInstallManuals();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    let hrWidth = getGroupWrapperWidth();
    if (hrWidth > 400) hrWidth = 400;

    this.setState({ mobileMode: isMobileMode(), hrWidth });
    setTopContainerWrapperSettings();
  }
  processProdRsp(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({ prodImageData: response.data, isLoading: false });
    }
  }

  fetchImageData() {
    const productId = parseInt(this.props.match.params.id.substring(2));
    const manual = this.props.match.params.id.substring(0, 2) === "M_";
    const url = apiBaseUrl() + "GetImgManuals";
    const data = {
      productId,
      uniqueKey: this.props.match.params.key,
      manual,
    };
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };

    this.setState({ isLoading: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processProdRsp(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  fetchAllInstallManuals() {
    const url = apiBaseUrl() + "GetAllInstallManuals";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    this.setState({ isLoading: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ allManList: res.data.objList, isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  generateThumbnail() {
    const url = apiBaseUrl() + "GenerateThumbnail";
    const productId = parseInt(this.props.match.params.id.substring(2));
    const uniqueKey = this.props.match.params.key;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: productId,
      strValue: uniqueKey,
    };

    this.setState({ isLoading: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  productInfo() {
    const data = this.state.prodImageData;
    let name = data.productName;
    const showProdWarn =
      !this.state.isManual && !data.uniqueKey && data.allPvHaveImages
        ? true
        : false;

    if (name.length > 30 && this.state.mobileMode)
      name = name.substring(0, 30) + "...";
    return (
      <div className="generic-flex">
        {this.state.isManual && (
          <React.Fragment>
            <div>
              <img
                src={fullProductImageUrl(data.imageUrl)}
                width="80"
                height="60"
              ></img>
            </div>
            <div className="left-10"></div>
          </React.Fragment>
        )}
        <div className="form-wrapper">
          <div className="form-item-label">Product ID:</div>
          <div className="form-item-value">{data.productId}</div>
          <div className="form-item-label">Product Name:</div>
          <div className="form-item-value">{name}</div>
          {data.sku && (
            <React.Fragment>
              <div className="form-item-label">SKU:</div>
              <div className="form-item-value">{data.sku}</div>
            </React.Fragment>
          )}
          {showProdWarn && (
            <React.Fragment>
              <div className="form-item-label">
                <b>Note:</b>
              </div>
              <div className="form-item-value">All variants have images.</div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  deleteImage(tbdIdx) {
    const imageInfoList = this.state.prodImageData.imageInfoList.map(
      (info, idx) => {
        if (idx !== tbdIdx) return info;
        else {
          const newInfo = {
            ...info,
            deleted: true,
          };
          return newInfo;
        }
      }
    );

    const prodImageData = {
      ...this.state.prodImageData,
      imageInfoList,
    };
    this.setState({
      prodImageData,
      updateDisabled: false,
    });
  }
  handleChange(event) {
    if (event.target.name === "manualList") {
      const selIndex = parseInt(event.target.value);
      this.setState({ selIndex });
    } else {
      const theIdx = parseInt(event.target.name.substring(4)); //Seq_IDX
      const imageInfoList = this.state.prodImageData.imageInfoList.map(
        (info, idx) => {
          if (idx !== theIdx) return info;
          else {
            const newInfo = {
              ...info,
              sequence: event.target.value,
              primary: false,
            };
            return newInfo;
          }
        }
      );
      const prodImageData = {
        ...this.state.prodImageData,
        imageInfoList,
      };
      this.setState({ prodImageData, updateDisabled: false });
    }
  }

  addPicFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      uploadFiles(event.target.files, this.processUploadResponse);
    }
  }
  processUploadResponse(response, fileName) {
    if (response.status) {
      const pos = fileName.lastIndexOf(".");
      const pdf = pos > 0 && fileName.substring(pos) == ".pdf" ? true : false;
      let imageInfoList = this.state.prodImageData.imageInfoList;

      if (!imageInfoList) imageInfoList = [];
      imageInfoList.push({ id: 0, url: fileName, uploaded: true, pdf });
      const prodImageData = {
        ...this.state.prodImageData,
        imageInfoList,
      };
      this.setState({ prodImageData, updateDisabled: false });
    } else {
      console.log("Upload failed");
    }
  }
  copyProdImages(event) {
    event.preventDefault();

    const piList = this.state.prodImageData.prodImageInfoList;
    if (piList && piList.length > 0) {
      let imageInfoList = this.state.prodImageData.imageInfoList;
      if (!imageInfoList) imageInfoList = [];

      for (const [idx, info] of piList.entries()) {
        const newInfo = {
          ...info,
          id: 0,
          objId: 0,
        };
        imageInfoList.push(newInfo);
      }
      const prodImageData = {
        ...this.state.prodImageData,
        imageInfoList,
      };
      this.setState({ prodImageData, updateDisabled: false });
    }
  }
  copyExisting(event) {
    if (this.state.selIndex >= 0) {
      const urlList =
        this.state.allManList[this.state.selIndex].installManualList;
      const imageInfoList = urlList.map((url) => {
        const pdf = url.toUpperCase().endsWith(".PDF") ? true : false;
        return { id: 0, url, pdf };
      });
      const prodImageData = {
        ...this.state.prodImageData,
        imageInfoList,
      };
      this.setState({ prodImageData, updateDisabled: false });
    }
  }
  showAllManList() {
    if (!this.state.allManList || this.state.allManList.length === 0) return;

    const list = this.state.prodImageData.imageInfoList;
    let cnt = 0;

    if (list && list.length > 0) {
      for (const [idx, info] of list.entries()) {
        if (!info.deleted) cnt++;
      }
    }
    if (cnt > 0) return;
    const manUrlList =
      this.state.selIndex >= 0
        ? this.state.allManList[this.state.selIndex].installManualList
        : null;
    const btnStyle =
      this.state.selIndex >= 0 ? "small-btn" : "disabled-small-btn";
    return (
      <div>
        <p />
        You can copy assembly manual from an existing product below:
        <br />
        <div className="generic-flex">
          <div>
            <select name="manualList" onChange={this.handleChange}>
              <option value={this.state.selIndex}>[SELECT A PRODUCT]</option>
              {this.state.allManList.map((info, idx) => {
                const name = info.sku
                  ? "" + info.productId + " " + info.sku
                  : "" + info.productId;
                return <option value={idx}>{name}</option>;
              })}
            </select>
          </div>
          <div className="left-10">
            <button
              className={btnStyle}
              disabled={this.state.selIndex < 0}
              name="copy"
              onClick={this.copyExisting}
            >
              Copy
            </button>
          </div>
        </div>
        {manUrlList && (
          <div className="generic-flex">
            {manUrlList.map((url) => {
              const pos = url.lastIndexOf(".");
              const pdf =
                pos > 0 && url.substring(pos).toUpperCase() === ".PDF"
                  ? true
                  : false;
              const manUrl = pdf
                ? getIconBaseUrl() + "pdf.jpg"
                : manualBaseUrl() + url;
              return (
                <div>
                  <a href={manualBaseUrl() + url} target="AssemblyManual">
                    <img src={manUrl} alt="Manual" width="60"></img>
                  </a>
                  &nbsp;&nbsp;
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
  showThumbnail(event) {
    event.preventDefault();
    document.getElementById("hiddenImgWindowBtn").click();
  }
  hiddenStuff() {
    const tnInfo = this.state.prodImageData.thumbnailInfo;
    if (tnInfo && tnInfo.url && this.state.updateDisabled) {
      return (
        <ImageWindow
          imageUrl={tnInfo.url}
          width={400}
          height={300}
          btnId="hiddenImgWindowBtn"
        />
      );
    }
  }
  showThumbnailInfo(firstImgInfo) {
    if (!this.state.isManual && this.state.updateDisabled) {
      const tnInfo = this.state.prodImageData.thumbnailInfo;

      if (tnInfo || firstImgInfo.width > 500) {
        let btnLabel = "Generate TN";

        if (tnInfo) {
          if (tnInfo.lmt < firstImgInfo.lmt) btnLabel = "Update TN";
          else btnLabel = "ReGen TN";
        }
        const imageWidth = this.state.mobileMode ? 80 : this.state.imageWidth;
        const clsName = this.state.mobileMode ? "none" : "flex-wrapper";
        return (
          <div className={clsName}>
            {tnInfo && (
              <div className="left-10">
                <b>Thumbnail:</b>
                <br />
                <img
                  className="hand"
                  src={fullProductImageUrl(tnInfo.url)}
                  width={imageWidth}
                  onClick={this.showThumbnail}
                ></img>
              </div>
            )}
            <div className="left-10">
              <button
                name="genTN"
                className="small-btn"
                onClick={this.generateThumbnail}
              >
                {btnLabel}
              </button>
            </div>
          </div>
        );
      }
    }
  }
  showModalImage(imgUrl) {
    this.setState({ modalImageUrl: imgUrl });
  }
  closeModalImage() {
    this.setState({ modalImageUrl: null });
  }
  showImages() {
    const list = this.state.prodImageData.imageInfoList;
    let cnt = 0;

    if (list && list.length > 0) {
      for (const [idx, info] of list.entries()) {
        if (!info.deleted) cnt++;
      }
    }
    if (cnt > 0) {
      const canDelete =
        this.state.isManual || cnt > 1 || this.props.match.params.key;
      const clsName1 = this.state.mobileMode ? "none" : "generic-flex";
      const clsName2 = this.state.mobileMode ? "none" : "left-10";
      return (
        <React.Fragment>
          {list.map((info, idx) => {
            if (!info.deleted) {
              let baseUrl = this.state.isManual
                ? manualBaseUrl()
                : prodImgBaseUrl();

              if (info.uploaded) baseUrl = previewImgBaseUrl();
              const url = baseUrl + info.url;
              const icon = info.pdf ? getIconBaseUrl() + "pdf.jpg" : url;
              const width = info.pdf ? 60 : this.state.imageWidth;
              const height = info.pdf ? 45 : this.state.imageHeight;
              const name = "Seq_" + idx;
              let val = info.sequence ? info.sequence : "0";
              const placeholder = info.primary ? "Primary" : "";
              const clsName = info.pdf ? "none" : "hand";
              if (info.primary) val = "";

              return (
                <div className="flex-wrapper" key={idx}>
                  <div>
                    <img
                      className={clsName}
                      src={icon}
                      width={width}
                      height={height}
                      alt="ProdImg"
                      onClick={() => this.showModalImage(info.url)}
                    ></img>
                  </div>
                  {canDelete && (
                    <div className={clsName1}>
                      <div className="left-10">
                        <input
                          type="text"
                          size="4"
                          name={name}
                          value={val}
                          placeholder={placeholder}
                          onChange={this.handleChange}
                        ></input>
                      </div>
                      <div className={clsName2}>
                        <button
                          name="delImage"
                          onClick={() => this.deleteImage(idx)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                  {idx === 0 && this.showThumbnailInfo(info)}
                </div>
              );
            }
          })}
        </React.Fragment>
      );
    } else {
      if (this.props.match.params.key) {
        const list = this.state.prodImageData.prodImageInfoList;
        const name = this.state.isManual ? "manuals" : "images";
        const uName = this.state.isManual ? "Manuals" : "Images";
        if (list && list.length > 0) {
          return (
            <div>
              <div>
                No {name} are configured for this variant, it will use
                product&nbsp;
                {name}. The following are product-level {name}, you can copy
                them to this variant (and make changes afterwards if desired).
              </div>
              <div>&nbsp;</div>
              <div className="generic-flex">
                <div className="generic-flex">
                  {list.map((info) => {
                    let url;
                    if (info.pdf) url = getIconBaseUrl() + "pdf.jpg";
                    else {
                      if (this.state.isManual) url = manualBaseUrl() + info.url;
                      else url = fullProductImageUrl(info.url);
                    }
                    return (
                      <div>
                        <img src={url} width="40" height="30"></img>
                      </div>
                    );
                  })}
                </div>
                <div className="left-10">
                  <button name="copy" onClick={this.copyProdImages}>
                    Copy Product {uName}
                  </button>
                </div>
              </div>
            </div>
          );
        }
      }
    }
  }

  cancelUpdate() {
    const url = "/editProduct/" + this.state.prodImageData.productId;
    this.props.history.push(url);
  }

  updateImages() {
    const url = apiBaseUrl() + "UpdateImgManuals";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.prodImageData,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          const url = "/product/" + this.state.prodImageData.productId;
          this.props.history.push(url);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (this.state.isLoading === true) return <Working size={80} />;
    const btnStyle =
      this.state.updateDisabled === true ? "disabled-btn-style" : "btn-style";
    const name = this.state.isManual ? "manual" : "picture";
    const uName = this.state.isManual ? "Manual" : "Picture";
    const title = this.props.match.params.key
      ? "Update Variant " + uName + "s"
      : "Update Product " + uName + "s";
    const addMsg = this.state.isManual
      ? ", you can upload PDF or image files:"
      : ":";
    const alt = this.state.mobileMode
      ? "Double tap to see larger picture"
      : "Double click to see larger picture";
    return (
      <div className="top-wrapper">
        <div className="form-container">
          <div>
            <font size="5">{title}</font>
          </div>
          {this.productInfo()}
          <hr width={this.state.hrWidth} align="left"></hr>
          {this.showImages()}
          {this.showAllManList()}
          {this.hiddenStuff()}
          {this.state.modalImageUrl && (
            <Lightbox
              large={fullProductImageUrl(this.state.modalImageUrl)}
              alt={alt}
              hideDownload={true}
              hideZoom={true}
              onClose={this.closeModalImage}
              imageBackgroundColor="white"
            />
          )}

          <hr width={this.state.hrWidth} align="left"></hr>
          <p />
          <div>
            <div>
              To add {name}s, click the button below{addMsg}
              <p />
              <b>Add {uName}: </b>
              <input
                type="file"
                id="addPicFile"
                multiple={true}
                onChange={this.addPicFile}
              ></input>
            </div>
            <div>&nbsp;</div>
            <div>
              <button
                className={btnStyle}
                name="update"
                onClick={this.updateImages}
                disabled={this.state.updateDisabled}
              >
                Update
              </button>
              &nbsp;&nbsp;
              <button
                className="btn-style"
                name="cancel"
                onClick={this.cancelUpdate}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageImgMan);
