import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
} from "../Util";
import { checkView } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../waitlistStats.css";

class LoadedOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderList: null,
      loadedSetting: null,
      orderNumber: null,
      dateStr: null,
      allSent: false,
      hasAddedItem: false,
      isLoading: true,
      errorMessage: null,
      message: null,
      checkAll: false,
      mobileMode: false,
      updBtnEnabled: false,
      addBtnEnabled: false,
      viewAllowed: checkView("Product"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.getOrderList = this.getOrderList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addOrder = this.addOrder.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.getOrderList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }
  getOrderList() {
    const url = apiBaseUrl() + "GetLoadedList";
    const value = this.props.match.params.date
      ? this.props.match.params.date
      : null;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({
            orderList: res.data.orderList,
            loadedSetting: res.data.setting,
            dateStr: res.data.dateStr,
            allSent: res.data.allSent,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get waitlist stats",
          isLoading: false,
        });
      });
  }
  addOrder() {
    const orderNumber = this.state.orderNumber;
    const found = this.state.orderList.filter(
      (order) => order.orderNumber == orderNumber
    );

    this.setState({ orderNumber: null, addBtnEnabled: false });
    if (found && found.length > 0) {
      this.setState({ errorMessage: "Order already exists: " + orderNumber });
    } else {
      const url = apiBaseUrl() + "GetLoadedOrder";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        value: orderNumber,
      };

      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          if (res.data.invalidSession) {
            this.props.setLoggedOut();
            this.props.history.push("/login");
          } else {
            if (res.data.data) {
              const orderList = this.state.orderList;
              const order = {
                ...res.data.data,
                toSend: true,
              };
              orderList.push(order);
              this.setState({
                orderList,
                errorMessage: null,
                hasAddedItem: true,
                message: null,
                updBtnEnabled: true,
              });
            } else {
              this.setState({
                errorMessage: "Order not found: " + orderNumber,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorMessage: "Failed to get order",
          });
        });
    }
  }
  sendNotification(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "NotifyCustomersForDelivery";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: this.state.orderList,
    };

    this.setState({ updBtnEnabled: false });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.orderList && res.data.orderList.length > 0) {
            this.setState({
              orderList: res.data.orderList,
              allSent: res.data.allSent,
              hasAddedItem: false,
              updBtnEnabled: false,
              message: "Notification successfully sent: " + res.data.sentCount,
            });
          } else {
            this.props.history.push("/");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to send notification",
          isLoading: false,
          updBtnEnabled: true,
        });
      });
  }
  handleChange(event) {
    const name = event.target.name;
    if (name.startsWith("CB_")) {
      const rowIdx = parseInt(name.substring(3));
      let updBtnEnabled = true;

      if (!event.target.checked) {
        let chkCnt = 0;
        for (let i = 0; i < this.state.orderList.length; i++) {
          if (this.state.orderList[i].toSend) chkCnt++;
        }
        if (chkCnt < 2) updBtnEnabled = false;
      }

      const orderList = this.state.orderList.map((r, idx) => {
        if (idx === rowIdx) {
          const nr = {
            ...r,
            toSend: event.target.checked,
          };
          return nr;
        } else return r;
      });

      this.setState({ orderList, updBtnEnabled });
    } else {
      if (name === "checkAll") {
        const checked = event.target.checked;
        const orderList = this.state.orderList.map((ord) => {
          if (ord.notificationSent) return ord;
          else {
            const newOrd = {
              ...ord,
              toSend: checked,
            };
            return newOrd;
          }
        });
        this.setState({
          orderList,
          checkAll: checked,
          updBtnEnabled: checked,
        });
      } else {
        const val = event.target.value;
        const addBtnEnabled = val && val.trim().length > 0 ? true : false;
        this.setState({ [name]: val, addBtnEnabled });
      }
    }
  }
  handleKeyDown(event) {
    if (event.keyCode === 13) {
      if (this.state.orderNumber && this.state.orderNumber.trim().length > 0) {
        this.addOrder();
      }
    }
  }
  showInfo(order, clsName) {
    if (order.leaseStatus) {
      const status = order.leaseStatus.funded ? (
        <font color="green">Funded</font>
      ) : (
        order.leaseStatus.status
      );
      const okLabel = order.leaseStatus.okToDeliver ? (
        <font color="green">OK</font>
      ) : (
        <font color="red">Don't Deliver!</font>
      );

      return (
        <div className={clsName}>
          {order.email}
          <div className="twocol-table">
            <div>Lease ID:</div>
            <div>{order.leaseStatus.leaseId}</div>
            <div>Status:</div>
            <div>{status}</div>
            {!order.leaseStatus.funded && (
              <React.Fragment>
                <div>Delivery:</div>
                <div>{okLabel}</div>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    } else {
      return <div className={clsName}>{order.email}</div>;
    }
  }
  oneRow(order, rowIdx, showEmail) {
    const disableAll = this.props.match.params.date ? true : false;
    const clsName = rowIdx % 2 === 0 ? "wl-grid-item-ev" : "wl-grid-item-od";
    const url = order.layaway
      ? "/layawayDetail/" + order.orderNumber
      : "/orderDetail/" + order.orderNumber;
    const cbName = "CB_" + rowIdx;
    let peTitle = order.phone;
    let name = this.state.mobileMode ? order.shortName : order.name;

    if (name && name.length > 16) name = name.substring(0, 16);
    if (
      showEmail &&
      this.state.mobileMode &&
      order.email &&
      order.email.trim().length > 0
    ) {
      peTitle = peTitle + "<br/>" + order.shortEmail;
    }
    const showInfo = showEmail || order.leaseStatus ? true : false;
    const disableThis =
      order.leaseStatus && !order.leaseStatus.okToDeliver ? true : false;

    return (
      <React.Fragment>
        <div className={clsName}>
          {!order.notificationSent && (
            <input
              type="checkbox"
              name={cbName}
              checked={order.toSend}
              onChange={this.handleChange}
              disabled={disableAll || disableThis}
            />
          )}
        </div>
        <div className={clsName}>
          <a href={url}>{order.orderNumber}</a>
        </div>
        <div className={clsName}>{name}</div>
        <div
          className={clsName}
          dangerouslySetInnerHTML={{ __html: peTitle }}
        ></div>
        {showInfo && !this.state.mobileMode && this.showInfo(order, clsName)}
        <div className={clsName}>
          {order.notificationSent && (
            <img
              src={getIconBaseUrl() + "checkmark.png"}
              height="12"
              alt="Sent"
            />
          )}
        </div>
      </React.Fragment>
    );
  }

  header(showEmail) {
    const clsName = "wl-grid-item-hd";
    const sTitle = this.state.mobileMode ? "S" : "Status";

    return (
      <React.Fragment>
        <div className={clsName}>
          <img src={getIconBaseUrl() + "sms.jpg"} height="15" alt="sms" />
        </div>
        <div className={clsName}>
          <b>Order</b>
        </div>
        <div className={clsName}>
          <b>Name</b>
        </div>
        <div className={clsName}>
          {this.state.mobileMode && showEmail ? (
            <b>
              Phone
              <br />
              Email
            </b>
          ) : (
            <b>Phone</b>
          )}
        </div>
        {showEmail && !this.state.mobileMode && (
          <div className={clsName}>
            <b>Info</b>
          </div>
        )}
        <div className={clsName}>
          <b>{sTitle}</b>
        </div>
      </React.Fragment>
    );
  }
  checkAllBox() {
    if (!this.state.loadedSetting || this.state.loadedSetting.checkAllEnabled) {
      let disableAll = this.props.match.params.date ? true : false;
      if (this.state.allSent && !this.state.hasAddedItem) {
        disableAll = true;
      }
      return (
        <div>
          <input
            type="checkbox"
            name="checkAll"
            defaultChecked={this.state.checkAll}
            onChange={this.handleChange}
            disabled={disableAll}
          ></input>
          ALL
        </div>
      );
    } else {
      return "";
    }
  }
  showOrders() {
    if (this.state.orderList && this.state.orderList.length > 0) {
      const showEmail =
        this.state.loadedSetting && this.state.loadedSetting.sendEmail
          ? true
          : false;
      const clsName =
        !this.state.mobileMode && showEmail ? "wl-6col-table" : "wl-5col-table";
      return (
        <div>
          {this.checkAllBox()}
          <div className={clsName}>
            {this.header(showEmail)}
            {this.state.orderList.map((order, rowIdx) => {
              return this.oneRow(order, rowIdx, showEmail);
            })}
          </div>
        </div>
      );
    }
  }
  addSection() {
    const disableAll = this.props.match.params.date ? true : false;
    const clsName = this.state.addBtnEnabled
      ? "small-btn"
      : "disabled-small-btn";

    return (
      <div className="generic-flex">
        <div>
          <input
            type="text"
            size="12"
            name="orderNumber"
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            value={this.state.orderNumber || ""}
            placeholder="Order Number"
            disabled={disableAll}
          />
        </div>
        <div className="left-10">
          <button
            name="Add"
            onClick={this.addOrder}
            className={clsName}
            disabled={disableAll}
          >
            Add
          </button>
        </div>
      </div>
    );
  }
  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    if (!this.state.orderList || this.state.orderList.length === 0) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">Scheduled Orders</font>
            <p />
            <font color="red">There is no orders.</font>
          </div>
        </div>
      );
    }
    const clsName = this.state.updBtnEnabled
      ? "narrow-btn"
      : "disabled-narrow-btn";
    const title = this.props.match.params.date
      ? "Delivery for " + this.props.match.params.date
      : "Today's Delivery";
    let dmUrl = "/deliveryMap";

    if (this.props.match.params.date) {
      dmUrl += "/" + this.props.match.params.date;
    } else if (this.state.dateStr) {
      dmUrl += "/" + this.state.dateStr;
    }

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div className="title-flex">
            <div>
              <font size="5">{title}</font> &nbsp;&nbsp;&nbsp;
              <Link to={dmUrl}>
                <img src={getIconBaseUrl() + "google-map.jpg"} height="20" />
              </Link>
            </div>
            {checkView("Setting") && (
              <div className="left-10">
                <Link to="/loadedSetting">Settings</Link>
              </div>
            )}
          </div>
          <p />
          {this.props.match.params.date ? (
            <label>
              The following orders are scheduled to deliver{" "}
              {this.props.match.params.date}.
            </label>
          ) : (
            <label>
              The following orders are scheduled to deliver today. Click the
              check box next to each order if they are loaded for delivery. You
              can also type-in order number if a loaded order is not in the
              list. Click "Send Notification" button to send text messages to
              these customers.
            </label>
          )}

          <p />
          {this.state.message && (
            <div>
              <font color="blue">{this.state.message}</font>
              <p />
            </div>
          )}
          {this.showOrders()}
          <br />
          {this.addSection()}
          <p />
          <button
            name="Update"
            className={clsName}
            onClick={this.sendNotification}
            disabled={!this.state.updBtnEnabled}
          >
            <div className="generic-flex">
              <div>
                <img src={getIconBaseUrl() + "sms.jpg"} height="16" alt="SMS" />
              </div>
              <div>
                &nbsp;<font size="3">Send Notification</font>
              </div>
            </div>
          </button>
          <p />
          <font color="red">{this.state.errorMessage}</font>
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoadedOrder);
