import React from "react";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import { fullProductImageUrl, toAmountEx, getIconBaseUrl } from "../Util";
import "../../App.css";
import "../../return.css";
import "../../pdf.css";

class ReturnedInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      returnedInfo: this.props.info,
      coi: this.props.coi,
      mobileMode: this.props.mobileMode,
      hideUser: this.props.hideUser,
    };
    this.printPDF = this.printPDF.bind(this);
    this.savePDF = this.savePDF.bind(this);
    this.adjustPdfSetting = this.adjustPdfSetting.bind(this);
  }
  componentDidUpdate(prevProp) {
    if (
      prevProp.info !== this.props.info ||
      prevProp.hideUser !== this.props.hideUser
    ) {
      this.setState({
        returnedInfo: this.props.info,
        mobileMode: this.props.mobileMode,
        hideUser: this.props.hideUser,
        coi: this.props.coi,
      });
    }
  }
  savePDF() {
    this.adjustPdfSetting();

    const order = this.state.coi.order;
    const fileName = order.orderNumber + "-Return.pdf";
    const html = ReactDOMServer.renderToStaticMarkup(this.printableInfo());
    let doc = new jsPDF("p", "pt", "a4");

    doc.html(html, {
      callback: function (pdf) {
        const pageCount = pdf.internal.getNumberOfPages();
        if (pageCount > 1) pdf.deletePage(pageCount);
        pdf.save(fileName);
      },
    });
  }
  printPDF() {
    this.adjustPdfSetting();

    const html = ReactDOMServer.renderToStaticMarkup(this.printableInfo());
    let doc = new jsPDF("p", "pt", "a4");

    doc.html(html, {
      callback: function (pdf) {
        const pageCount = pdf.internal.getNumberOfPages();
        if (pageCount > 1) pdf.deletePage(pageCount);
        pdf.autoPrint();
        pdf.output("dataurlnewwindow");
      },
    });
  }
  printableInfo() {
    return (
      <div className="pdf-container">
        <div className="generic-flex">
          {this.headerLeft()}
          {this.headerRight()}
        </div>
        <p />
        {this.returnedInfo(true)}
        {this.footer()}
      </div>
    );
  }
  headerLeft() {
    const info = this.state.returnedInfo;
    return (
      <div className="pdf-hdr-left">
        <img src={getIconBaseUrl() + "invoicelogo.jpg"} height="40" alt="FWL" />
        {info && info.pdfSetting ? (
          <div dangerouslySetInnerHTML={{ __html: info.pdfSetting.headLeft }} />
        ) : (
          <React.Fragment>
            <br />
            https://www.furniturewayless.com
            <br />
            Marietta: (678)819-7591 (11am-7pm)
            <br />
            Jonesboro: (678)819-8777 (11am-7pm)
            <br />
            Decatur: (404)284-1688 (11am-7pm, Closed Tue)
            <br />
            Warehouse: (404)800-5805 (10am-7pm)
            <br />
            Repair/Parts: fwlcod@gmail.com
          </React.Fragment>
        )}
      </div>
    );
  }
  headerRight() {
    if (this.state.coi) {
      const order = this.state.coi.order;
      const addr = this.state.coi.contactAddress;
      return (
        <div className="pdf-hdr-right">
          &nbsp;
          <p />
          <div>
            Order Number: <b>{order.orderNumber}</b>
          </div>
          {addr && (
            <div>
              Customer: {addr.firstName} {addr.lastName}
            </div>
          )}
          {addr && addr.phone && <div>Phone: {addr.phone}</div>}
        </div>
      );
    }
  }
  footer() {
    const info = this.state.returnedInfo;
    if (info && info.pdfSetting) {
      const footer = info.pdfSetting.returnFooter;
      if (footer && footer.length > 0) {
        return (
          <div
            className="pdf-footer"
            dangerouslySetInnerHTML={{ __html: footer }}
          />
        );
      }
    }
  }

  adjustPdfSetting() {
    const info = this.state.returnedInfo;
    if (info && info.pdfSetting) {
      const s = info.pdfSetting;

      document.documentElement.style.setProperty(
        "--pdf-page-width",
        "" + s.pageWidth + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-page-height",
        "" + s.pageHeight + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-margin-left",
        "" + s.marginLeft + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-margin-bottom",
        "" + s.marginBottom + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-margin-top",
        "" + s.marginTop + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-head-gap",
        "" + s.headGap + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-shipto-left",
        "" + s.shipToLeft + "px"
      );
      document.documentElement.style.setProperty(
        "--pdf-table-percent",
        "" + s.tablePercent + "%"
      );

      document.documentElement.style.setProperty(
        "--pdf-fs-reg-table",
        s.fsRegTable
      );
      document.documentElement.style.setProperty(
        "--pdf-fs-large-table",
        s.fsLargeTable
      );
      document.documentElement.style.setProperty(
        "--pdf-fs-left-header",
        s.fsLeftHeader
      );
      document.documentElement.style.setProperty(
        "--pdf-fs-right-header",
        s.fsRightHeader
      );
      document.documentElement.style.setProperty("--pdf-fs-footer", s.fsFooter);
      document.documentElement.style.setProperty("--pdf-fs-note", s.fsNote);
      document.documentElement.style.setProperty(
        "--pdf-fs-header2",
        s.fsHeader2
      );
    }
  }
  returnedInfo(forPrint) {
    const returnedInfo = this.state.returnedInfo;
    if (
      !returnedInfo ||
      !returnedInfo.itemList ||
      returnedInfo.itemList.length === 0
    ) {
      return "";
    }
    const clsName = forPrint ? "pdf-left-header" : "rtn-header";
    return (
      <div>
        <div className={clsName}>Returned Items</div>
        {this.returnDetail(returnedInfo, forPrint)}
        {!forPrint && (
          <div className="flex-wrapper-tb10">
            <div>
              <button
                name="print"
                className="small-btn"
                onClick={this.printPDF}
              >
                <img
                  src={getIconBaseUrl() + "print.jpg"}
                  height="12"
                  alt="PDF"
                />
                &nbsp; Print
              </button>
            </div>
            <div className="left-20">
              <button name="pdf" className="small-btn" onClick={this.savePDF}>
                <img src={getIconBaseUrl() + "pdf.jpg"} height="12" alt="PDF" />
                &nbsp;PDF
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  returnDetail(returnedInfo, forPrint) {
    const mobileMode = forPrint ? false : this.state.mobileMode;
    const hideUser = forPrint ? true : this.state.hideUser;
    const tblCls = forPrint ? "pdf-table" : "rtn-table";
    let colspan = mobileMode ? 3 : 6;
    const taxPercent = (returnedInfo.refundTaxRate * 100.0).toFixed(2);
    const hdr = (
      <tr>
        <th>Date</th>
        <th>Product</th>
        {!mobileMode && <th>UnitPrice</th>}
        <th>Qty</th>
        {!mobileMode && <th>Fee</th>}
        {!mobileMode && !hideUser && <th>User</th>}
        <th>Refund</th>
      </tr>
    );

    if (!mobileMode && hideUser) colspan -= 1;
    return (
      <table className={tblCls} cellSpacing="0" cellPadding="0">
        <tbody>
          {hdr}
          {returnedInfo.itemList.map((item, idx) => {
            const imgUrl = fullProductImageUrl(item.imageUrl);
            const ts = mobileMode
              ? item.dtStr.substring(1, 10) + <br /> + item.dtStr.substring(11)
              : item.dtStr;
            const skuInfo = mobileMode ? (
              <span>
                {item.sku}
                <br />
                {toAmountEx(item.unitPrice)}
              </span>
            ) : (
              item.sku
            );

            return (
              <tr key={idx}>
                <td>{ts}</td>
                <td>
                  <div className="flex-wrapper">
                    {!item.coupon && (
                      <div>
                        <img
                          src={imgUrl}
                          width="40"
                          height="30"
                          alt={item.sku}
                        />
                      </div>
                    )}
                    <div className="left5">{skuInfo}</div>
                  </div>
                </td>
                {!mobileMode && <td>{toAmountEx(item.unitPrice)}</td>}
                <td>{item.quantity}</td>
                {!mobileMode && <td>{item.restockingPercent}%</td>}
                {!mobileMode && !hideUser && <td>{item.userId}</td>}
                <td align="right">{toAmountEx(item.refund)}</td>
              </tr>
            );
          })}
          <tr>
            <td colspan={colspan} align="right" style={{ border: "none" }}>
              Subtotal:{" "}
            </td>
            <td align="right" style={{ border: "none" }}>
              {toAmountEx(returnedInfo.refundSubtotal)}
            </td>
          </tr>
          <tr>
            <td colspan={colspan} align="right" style={{ border: "none" }}>
              Tax ({taxPercent}%):{" "}
            </td>
            <td align="right" style={{ border: "none" }}>
              {toAmountEx(returnedInfo.refundTax)}
            </td>
          </tr>
          <tr>
            <td colspan={colspan} align="right" style={{ border: "none" }}>
              <b>Refund Total</b>:{" "}
            </td>
            <td align="right" style={{ border: "none" }}>
              <b>
                <font color="red">{toAmountEx(returnedInfo.refundTotal)}</font>
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    return this.returnedInfo(false);
  }
}

export default ReturnedInfo;
