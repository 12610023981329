import React from "react";
import { Dialog, DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../form.css";
import { fullProductImageUrl } from "../Util";

class ImageWindow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  showWindow() {
    document.getElementById("showWindowBtn").click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
  }

  hiddenButton() {
    return (
      <button
        id="showWindowBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }

  imageWindow() {
    return (
      <div>
        {this.hiddenButton()}
        <DialogOverlay
          className="light-modal"
          aria-label="Image"
          isOpen={this.state.showDialog}
          onDismiss={this.closeDialog}
        >
          <DialogContent
            style={{
              width: this.props.width,
              height: this.props.height,
              border: "solid 5px hsla(0, 0%, 0%, 0.5)",
              borderRadius: "10px",
            }}
          >
            <img
              src={fullProductImageUrl(this.props.imageUrl)}
              width={this.props.width}
              height={this.props.height}
            ></img>
          </DialogContent>
        </DialogOverlay>
      </div>
    );
  }

  render() {
    return (
      <div>
        <button
          id={this.props.btnId}
          onClick={this.showWindow}
          style={{ display: "none" }}
        >
          NotUsed
        </button>
        {this.imageWindow()}
      </div>
    );
  }
}
export default ImageWindow;
