import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { Lightbox } from "react-modal-image";
import {
  apiBaseUrl,
  prodImgBaseUrl,
  previewImgBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
} from "../Util";
import { uploadFiles } from "../FileUploader";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

const TYPE_ORIGINAL = 1;
const TYPE_UPLOADED = 2;
const TYPE_COPIED = 3;

class EditHomeImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeImages: [],
      isLoading: true,
      deletedIdList: [],
      uploadedList: [],
      copiedList: [],
      productList: null,
      tbcIndex: -1,
      updateBtnDisabled: true,
      imageWidth: 180,
      imageHeight: 99,
      mobileMode: false,
      modalImageUrl: null,
      errorMessage: null,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.deleteImage = this.deleteImage.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.addPicFile = this.addPicFile.bind(this);
    this.handleProdIdChange = this.handleProdIdChange.bind(this);
    this.updateHomeImages = this.updateHomeImages.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchProdList = this.fetchProdList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.copyProdImage = this.copyProdImage.bind(this);
    this.showModalImage = this.showModalImage.bind(this);
    this.closeModalImage = this.closeModalImage.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchProdList();

    const url = apiBaseUrl() + "GetHomeImages";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  fetchProdList() {
    const url = apiBaseUrl() + "GetForSaleProducts";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ productList: res.data.objList });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const objList = response.objList;
      this.setState({ homeImages: objList, isLoading: false });
    }
  }

  processUpdateResponse(response) {
    if (response.status) {
      this.props.history.push("/homeImgPreview");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  updateHomeImages(event) {
    const url = apiBaseUrl() + "UpdateHomeImages";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      uploadedList: this.state.uploadedList,
      copiedList: this.state.copiedList,
      deletedIdList: this.state.deletedIdList,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteImage(fileName, type) {
    if (type === TYPE_UPLOADED) {
      const newList = this.state.uploadedList.filter(
        (item) => item.imageUrl !== fileName
      );
      this.setState({ uploadedList: newList });
    } else if (type === TYPE_COPIED) {
      const newList = this.state.copiedList.filter(
        (item) => item.imageUrl !== fileName
      );
      this.setState({ copiedList: newList });
    } else {
      const found = this.state.homeImages.find(
        (item) => item.imageUrl === fileName
      );

      if (found) {
        let idList = this.state.deletedIdList;
        const newList = this.state.homeImages.filter(
          (item) => item.imageUrl !== fileName
        );

        idList.push(found.id);
        this.setState({ homeImages: newList, deletedIdList: idList });
      }
    }

    let disabled = false;
    if (
      this.state.uploadedList.length === 0 &&
      this.state.deletedIdList === 0
    ) {
      disabled = true;
    }
    this.setState({ updateBtnDisabled: disabled });
  }

  processUploadResponse(response, fileName) {
    if (response.status) {
      let list = this.state.uploadedList;
      list.push({ imageUrl: fileName });
      this.setState({
        uploadedList: list,
        productId: null,
        updateBtnDisabled: false,
      });
    } else {
      console.log("Upload failed");
    }
  }
  addPicFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      uploadFiles(event.target.files, this.processUploadResponse);
    }
  }

  handleProdIdChange(event) {
    const name = event.target.name;
    const type = parseInt(name.substring(1, 2));
    const fileName = name.substring(3);

    if (type === TYPE_UPLOADED) {
      const idx = this.state.uploadedList.findIndex(
        (item) => item.imageUrl === fileName
      );

      if (idx >= 0) {
        let newList = this.state.uploadedList;

        newList[idx].productId = event.target.value;
        this.setState({ uploadedList: newList });
      }
    } else {
      const idx = this.state.copiedList.findIndex(
        (item) => item.imageUrl === fileName
      );

      if (idx >= 0) {
        let newList = this.state.copiedList;

        newList[idx].productId = event.target.value;
        this.setState({ copiedList: newList });
      }
    }
  }

  showImageList(list, baseUrl, canDelete, type) {
    return (
      <React.Fragment>
        {list.map((homeImg, idx) => {
          const url = baseUrl + homeImg.imageUrl;
          const label = this.state.mobileMode ? "Product" : "Product ID";
          const idStr = "T" + type + "_" + homeImg.imageUrl;
          return (
            <div className="flex-wrapper" key={idx}>
              <div>
                <img
                  src={url}
                  width={this.state.imageWidth}
                  height={this.state.imageHeight}
                  alt="ProdImg"
                  onClick={() => this.showModalImage(homeImg.imageUrl)}
                ></img>
                &nbsp;&nbsp;
              </div>
              {canDelete === true && (
                <div>
                  <button
                    name="delImage"
                    onClick={() => this.deleteImage(homeImg.imageUrl, type)}
                  >
                    Delete
                  </button>
                </div>
              )}
              <div className="width-10"></div>
              <div>
                <div className="flex-wrapper">
                  <div>{label}:&nbsp;</div>
                  {type === TYPE_UPLOADED ? (
                    <div>
                      <input
                        type="text"
                        onChange={this.handleProdIdChange}
                        name={idStr}
                        size="6"
                        value={homeImg.productId}
                      />
                    </div>
                  ) : type === TYPE_COPIED ? (
                    <div>
                      <font color="blue">{homeImg.productId}</font>
                    </div>
                  ) : (
                    <div>{homeImg.productId}</div>
                  )}
                  <div className="width-30"></div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
  showModalImage(imgUrl) {
    this.setState({ modalImageUrl: imgUrl });
  }
  closeModalImage() {
    this.setState({ modalImageUrl: null });
  }
  showImages() {
    const imgCnt =
      this.state.homeImages.length + this.state.uploadedList.length;
    const canDelete = this.state.editAllowed && imgCnt > 1;
    const alt = this.state.mobileMode
      ? "Double tap to see larger picture"
      : "Double click to see larger picture";

    return (
      <React.Fragment>
        {this.showImageList(
          this.state.homeImages,
          prodImgBaseUrl(),
          canDelete,
          TYPE_ORIGINAL
        )}
        {this.showImageList(
          this.state.uploadedList,
          previewImgBaseUrl(),
          canDelete,
          TYPE_UPLOADED
        )}
        {this.showImageList(
          this.state.copiedList,
          prodImgBaseUrl(),
          canDelete,
          TYPE_COPIED
        )}
        {this.state.modalImageUrl && (
          <Lightbox
            large={fullProductImageUrl(this.state.modalImageUrl)}
            alt={alt}
            hideDownload={true}
            hideZoom={true}
            onClose={this.closeModalImage}
            imageBackgroundColor="white"
          />
        )}
      </React.Fragment>
    );
  }

  selectable(prodId) {
    const found = this.state.homeImages.find((hi) => hi.productId === prodId);
    if (found) return false;

    const found2 = this.state.uploadedList.find(
      (hi) => hi.productId === prodId
    );
    if (found2) return false;

    const found3 = this.state.copiedList.find((hi) => hi.productId === prodId);
    if (found3) return false;
    return true;
  }
  handleChange(event) {
    const tbcIndex = parseInt(event.target.value);
    this.setState({ tbcIndex });
  }
  copyProdImage() {
    if (this.state.tbcIndex >= 0) {
      const prod = this.state.productList[this.state.tbcIndex];
      const hi = { productId: prod.id, imageUrl: prod.imageUrl };
      let copiedList = this.state.copiedList;

      copiedList.push(hi);
      this.setState({ copiedList, tbcIndex: -1, updateBtnDisabled: false });
    }
  }

  showProducts() {
    if (!this.state.productList || this.state.productList.length === 0) return;

    const prod =
      this.state.tbcIndex >= 0
        ? this.state.productList[this.state.tbcIndex]
        : null;
    const imageInfo =
      prod && prod.imageInfoList && prod.imageInfoList.length > 0
        ? prod.imageInfoList[0]
        : null;
    const btnStyle =
      this.state.tbcIndex >= 0 ? "btn-style" : "disabled-btn-style";
    return (
      <div>
        <div>
          You can also select an existing product image from the following list:
        </div>
        <div className="generic-flex">
          <div>
            <select
              name="product"
              value={this.state.tbcIndex}
              onChange={this.handleChange}
            >
              <option value={-1}>[SELECT A PRODUCT]</option>
              {this.state.productList.map((prod, idx) => {
                if (this.selectable(prod.id)) {
                  let name = prod.name;
                  if (this.state.mobileMode && name.length > 32)
                    name = name.substring(0, 30) + "...";
                  return (
                    <option value={idx}>
                      {prod.id} {name}
                    </option>
                  );
                }
              })}
            </select>
          </div>
          <div className="left-10"></div>
          <div>
            <button
              className={btnStyle}
              name="copy"
              onClick={this.copyProdImage}
              disabled={this.state.tbcIndex < 0}
            >
              Copy
            </button>
          </div>
        </div>
        {prod && (
          <div className="generic-flex">
            <div>
              <img src={fullProductImageUrl(prod.imageUrl)} width="180"></img>
            </div>
            <div className="left-10"></div>
            {imageInfo && (
              <div>
                Width: {imageInfo.width}
                <br />
                Height: {imageInfo.height}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    const editAllowed = this.state.editAllowed;
    const btnClsName = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const clsName = this.state.mobileMode ? "none" : "twocol-wrapper";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Home Images</font>
          <br />
          Images below are used in the slider show on the home page.
          {editAllowed && (
            <React.Fragment>
              &nbsp;&nbsp;You can delete existiing images or upload new images.{" "}
              <br />
              When you upload a image, make sure you assign a product ID to it
              so users can go to that product when clicking the image. <br />
              For best result, the width/height ratio should be 1200/660.
            </React.Fragment>
          )}
          <p />
          <button
            className={btnClsName}
            name="update"
            disabled={this.state.updateBtnDisabled}
            onClick={this.updateHomeImages}
          >
            Update Home Images
          </button>
          <p />
          <div className={clsName}>{this.showImages()}</div>
          <p />
          {editAllowed && (
            <div>
              To add more pictures, click the button below:
              <p />
              <b>Add Home Image: </b>
              <input
                type="file"
                id="addPicFile"
                onChange={this.addPicFile}
              ></input>
              <p />
              <font color="red">{this.state.errorMessage}</font>
            </div>
          )}
          <p />
          {this.showProducts()}
          <p />
          <div>&nbsp;</div>
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditHomeImages);
