import React from "react";
import { toAmountEx, fullProductImageUrl } from "../Util";
import "../../App.css";
import "../../generic.css";

class PvSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pvList: this.props.pvList,
      storePvList: this.props.storePvList,
      exSkuList: this.props.exSkuList, //Excluded list
      prompt: this.props.prompt,
      showPreview: this.props.showPreview,
      btnTitle: this.props.btnTitle,
      sku: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.buttonClicked = this.buttonClicked.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.pvList !== prevProps.pvList ||
      this.props.storePvList !== prevProps.storePvList ||
      this.props.exSkuList !== prevProps.exSkuList
    ) {
      this.setState({
        pvList: this.props.pvList,
        storePvList: this.props.storePvList,
        exSkuList: this.props.exSkuList, //Excluded list
        prompt: this.props.prompt,
        showPreview: this.props.showPreview,
        btnTitle: this.props.btnTitle,
      });
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  buttonClicked(event) {
    const info = this.getSelectedPvInfo();

    if (info) {
      this.props.actionHandler(info.index, info.isSP);
      this.setState({ sku: "" });
    }
  }
  isInExList(sku) {
    const exSkuList = this.state.exSkuList;
    if (exSkuList) {
      for (let i = 0; i < exSkuList.length; i++) {
        if (sku == exSkuList[i]) {
          return true;
        }
      }
    }
    return false;
  }
  getSelectedPvInfo() {
    const sku = this.state.sku;
    if (sku && sku.length > 0) {
      const storePvList = this.state.storePvList;
      if (sku.startsWith("StoreItem: ") && storePvList) {
        const name = sku.substring(11);
        for (let i = 0; i < storePvList.length; i++) {
          if (storePvList[i].name == name) {
            const info = { index: i, isSP: true };
            return info;
          }
        }
      } else {
        const pvList = this.state.pvList;
        if (pvList) {
          for (let i = 0; i < pvList.length; i++) {
            if (pvList[i].sku == sku) {
              const info = { index: i, isSP: false };
              return info;
            }
          }
        }
      }
    }
    return null;
  }

  isValidPv() {
    const info = this.getSelectedPvInfo();

    if (info) return true;
    return false;
  }

  preview() {
    if (this.state.showPreview) {
      const info = this.getSelectedPvInfo();
      if (info) {
        const pv = info.isSP ? null : this.state.pvList[info.index];
        const sp = info.isSP ? this.state.storePvList[info.index] : null;
        let imageUrl = null;

        if (pv) {
          imageUrl = pv.imageUrl ? pv.imageUrl : pv.prodImageUrl;
        } else imageUrl = sp.imageUrl;

        return (
          <div className="flex-wrapper-t10">
            <div>
              <img
                src={fullProductImageUrl(imageUrl)}
                width="80"
                height="60"
              ></img>
            </div>
            {pv ? (
              <div className="left-10">
                SKU: {pv.sku} <br />
                Price: {toAmountEx(pv.finalPrice)}
              </div>
            ) : (
              <div className="left-10">
                SKU: {sp.sku} <br />
                Name: {sp.name} <br />
                Price: {toAmountEx(sp.price)}
              </div>
            )}
          </div>
        );
      }
    }
  }
  onSelect(item) {
    console.log(item);
  }
  showSelector() {
    const pvList = this.state.pvList;
    const storePvList = this.state.storePvList;
    if (
      (pvList != null && pvList.length > 0) ||
      (storePvList != null && storePvList.length > 0)
    ) {
      const clsName = this.state.prompt ? "left-10" : "none";
      const disabled = !this.isValidPv();
      const btnStyle = disabled ? "disabled-small-btn" : "small-btn";
      return (
        <div>
          <div className="flex-wrapper">
            {this.state.prompt && <div>{this.state.prompt}</div>}
            <div className={clsName}>
              <input
                list="pvlist"
                name="sku"
                type="text"
                size="30"
                onChange={this.handleChange}
                value={this.state.sku}
                placeholder="[Select/Type SKU]"
              />
              <datalist id="pvlist">
                {pvList &&
                  pvList.map((pv, idx) => {
                    if (!this.isInExList(pv.sku)) {
                      return <option value={pv.sku} key={idx} />;
                    }
                  })}
                {storePvList &&
                  storePvList.map((pv, idx) => {
                    if (pv.typeId < 1 && !this.isInExList(pv.sku)) {
                      const val = "StoreItem: " + pv.name;
                      return <option value={val} key={idx} />;
                    }
                  })}
              </datalist>
            </div>
            <div className="left-10">
              <button
                className={btnStyle}
                name="addNew"
                disabled={disabled}
                onClick={this.buttonClicked}
              >
                {this.state.btnTitle}
              </button>
            </div>
          </div>
          {this.preview()}
        </div>
      );
    }
    return "";
  }

  render() {
    return this.showSelector();
  }
}
export default PvSelector;
