import React from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { toAmount, fullProductImageUrl, formatCurrency } from "../Util";
import "../../App.css";
import "../../form.css";
import "../../generic.css";
import "../../mydialog.css";

class OrderInfoDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryInfo: this.props.deliveryInfo,
      showDialog: false,
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.showInformation = this.showInformation.bind(this);
    this.hiddenActionButton = this.hiddenActionButton.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.deliveryInfo !== prevProps.deliveryInfo) {
      this.setState({ deliveryInfo: this.props.deliveryInfo });
    }
  }
  showInformation() {
    const id = this.props.actBtnId ? this.props.actBtnId : "diDialogBtn";
    document.getElementById(id).click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
  }

  hiddenActionButton() {
    const id = this.props.actBtnId ? this.props.actBtnId : "diDialogBtn";
    return (
      <button
        id={id}
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  orderDetailHtml() {
    const info = this.state.deliveryInfo;
    if (!info) return "";

    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html +=
      '<table><tr><th style="background-color: #D0D0D0">Product</th><th style="background-color: #D0D0D0">Name</th><th style="background-color: #D0D0D0">Qty.</th><th style="background-color: #D0D0D0">Price</th></tr>';

    for (let idx = 0; idx < info.orderItems.length; idx++) {
      const item = info.orderItems[idx];
      const url = "/product/" + item.productId;
      const imgUrl = fullProductImageUrl(item.imageUrl);
      const description = item.productName;
      html +=
        '<tr><td><a href="' +
        url +
        '"><img src="' +
        imgUrl +
        '" width="45" height="30"></img></a>&nbsp;&nbsp;';
      if (!item.pvExists) html += '<font color="red">';
      html += item.item.sku;
      if (!item.pvExists) html += "</font>";
      html += "</td>";
      html += "<td>" + description + "</td>";
      html += '<td align="center">' + item.item.quantity + "</td>";
      html +=
        '<td align="right">' + toAmount(item.item.unitPrice) + "</td></tr>";
    }

    if (info.storeItems && info.storeItems.length > 0) {
      for (let idx = 0; idx < info.storeItems.length; idx++) {
        const item = info.storeItems[idx];
        if (item.typeId <= 0) {
          const imgUrl = fullProductImageUrl(item.imageUrl);

          html +=
            '<tr><td><img src="' +
            imgUrl +
            '" width="45" height="30"></img>&nbsp;&nbsp;Store Item</td>';
          html += "<td>" + item.name + "</td>";
          html += '<td align="center">' + item.quantity + "</td>";
          html += '<td align="right">' + toAmount(item.price) + "</td></tr>";
        }
      }
    }
    if (info.order.discountName && info.order.discount > 0) {
      html +=
        '<tr><td colspan="3">' +
        info.order.discountName +
        ':</td><td align="right">' +
        toAmount(0 - info.order.discount) +
        "</td></tr>";
    }
    html +=
      '<tr><td colspan="3">Subtotal:</td><td align="right">' +
      toAmount(info.order.subtotal) +
      "</td></tr>";
    html +=
      '<tr><td colspan="3">GA Tax: </td><td align="right">' +
      toAmount(info.order.tax) +
      "</td></tr>";
    if (info.order.deliveryFee && info.order.deliveryFee > 0) {
      html +=
        '<tr><td colspan="3">Delivery Fee: </td><td align="right">' +
        toAmount(info.order.deliveryFee) +
        "</td></tr>";
    }
    if (info.storeCoupons && info.storeCoupons.length > 0) {
      for (let idx = 0; idx < info.storeCoupons.length; idx++) {
        html +=
          '<tr><td colspan="3">' +
          info.storeCoupons[idx].name +
          ':</td><td align="right"><font color="red">' +
          toAmount(info.storeCoupons[idx].price) +
          "</font></td></tr>";
      }
    }
    html +=
      '<tr><td colspan="3"><b>Total:</b> </td><td align="right"><b>' +
      toAmount(info.order.totalPrice) +
      "</b></td></tr>";
    let totalDeposit = 0;
    if (info.storeItems && info.storeItems.length > 0) {
      for (let idx = 0; idx < info.storeItems.length; idx++) {
        const item = info.storeItems[idx];
        if (item.typeId > 0) {
          const amount = item.price > 0 ? item.price : 0 - item.price;
          const isReturn = item.price < 0;
          const img =
            item.depositType && item.depositType.imageUrl
              ? item.depositType.imageUrl
              : item.imageUrl;
          const imgUrl = fullProductImageUrl(img);
          let name = item.name;
          let properties = "";

          if (isReturn) {
            name += ' (<font color="red">Return</font>)';
          }
          if (item.properties && item.properties.length > 0) {
            for (let k = 0; k < item.properties.length; k++) {
              properties +=
                "<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
                item.properties[k].key +
                ": " +
                item.properties[k].value;
            }
          }

          html +=
            '<tr><td colspan="3"><img src="' +
            imgUrl +
            '" height="20"></img>&nbsp;&nbsp;' +
            name +
            properties +
            "</td>";
          if (isReturn) {
            html +=
              '<td align="right">' + formatCurrency(amount) + "</td></tr>";
          } else {
            html +=
              '<td align="right"><font color="red">-' +
              toAmount(amount) +
              "</font></td></tr>";
          }

          totalDeposit += item.price;
        }
      }
    }
    if (totalDeposit > 0) {
      let balance = info.order.totalPrice - totalDeposit;
      if (Math.abs(balance) < 1) balance = 0;
      html += '<tr><td colspan="3"><i>Balance:</i></td>';
      html += '<td align="right">' + formatCurrency(balance) + "</td></tr>";
    }
    html += "</table>";
    return html;
  }
  addressInfo() {
    const info = this.state.deliveryInfo;
    if (info) {
      const addr = info.address;
      let lines = [];
      lines.push(addr.firstName + " " + addr.lastName);
      lines.push(addr.addressLine1);
      if (addr.addressLine2) lines.push(addr.addressLine2);
      lines.push(addr.city + ", " + addr.state + " " + addr.zipCode);
      if (addr.phone) lines.push(addr.phone);
      if (addr.email) lines.push(addr.email);

      return (
        <div>
          {lines.map((t) => (
            <div>{t}</div>
          ))}
        </div>
      );
    }
  }
  otherInfo() {
    const info = this.state.deliveryInfo;
    if (info) {
      return (
        <div className="left-20">
          Delivery Date: {info.order.schDateStr}
          <br />
          Date Ordered: {info.order.ordDateStr}
          <br />
          Distance: {info.order.distance} miles
          <br />
        </div>
      );
    }
  }
  deliveryInstruction() {
    const info = this.state.deliveryInfo;
    if (info && info.order.deliveryInstr) {
      return (
        <div>
          <p />
          <i>Delivery Instruction:</i>
          <br />
          {info.order.deliveryInstr}
        </div>
      );
    }
  }
  deliveryInfoDialog() {
    if (!this.state.deliveryInfo) return "";

    const title =
      "Order #" +
      this.state.deliveryInfo.index +
      ": " +
      this.state.deliveryInfo.order.orderNumber;
    return (
      <div>
        {this.hiddenActionButton()}
        <Dialog isOpen={this.state.showDialog} onDismiss={this.closeDialog}>
          <b>{title}</b>
          <p />
          <div className="generic-flex">
            {this.addressInfo()}
            {this.otherInfo()}
          </div>
          <br />
          <div
            dangerouslySetInnerHTML={{ __html: this.orderDetailHtml() }}
          ></div>
          {this.deliveryInstruction()}
          <p />
          <button onClick={this.closeDialog} className="btn-style">
            Close
          </button>
        </Dialog>
      </div>
    );
  }

  render() {
    return (
      <div>
        <button
          id={this.props.btnId}
          onClick={this.showInformation}
          style={{ display: "none" }}
        >
          Hidden
        </button>
        {this.deliveryInfoDialog()}
      </div>
    );
  }
}
export default OrderInfoDialog;
