import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "./config/constants";
import { apiBaseUrl, getCompressSetting, getFileSizeThreshold } from "./Util";

const Compress = require("compress.js");

function uploadOneFile(file, fileName, callbackFunc, param, param2) {
  const data = new FormData();
  data.append("file", file);

  const url = apiBaseUrl() + "UploadProdPic";
  axiosRetry(axios, { retries: 3 });
  axios
    .post(url, data, {
      headers: AXIOS_HEADER,
    })
    .then((res) => {
      console.log(res);
      callbackFunc(res.data, fileName, param, param2);
    })
    .catch((error) => {
      console.log(error);
    });
}
function uploadOneFlyerPic(file, fileName, callbackFunc, param) {
  const data = new FormData();
  data.append("file", file);

  const url = apiBaseUrl() + "UploadFlyerPic";
  axiosRetry(axios, { retries: 3 });
  axios
    .post(url, data, {
      headers: AXIOS_HEADER,
    })
    .then((res) => {
      console.log(res);
      callbackFunc(res.data, fileName, param);
    })
    .catch((error) => {
      console.log(error);
    });
}
function compressFile(file, callbackFunc, param, param2) {
  const compress = new Compress();
  const setting = getCompressSetting();

  compress
    .compress([file], {
      size: setting.maxSize,
      quality: setting.quality,
      maxWidth: setting.maxWidth,
      maxHeight: setting.maxWidth,
      resize: true,
    })
    .then((results) => {
      const base64str = results[0].data;
      const imgExt = results[0].ext;
      const blob = Compress.convertBase64ToFile(base64str, imgExt);
      const newFile = new File([blob], file.name, { type: imgExt });

      uploadOneFile(newFile, file.name, callbackFunc, param, param2);
    })
    .catch((error) => {
      console.log("Failed to upload file: " + file.name);
      console.log(error);
    });
}

export function uploadFiles(files, callbackFunc, param, param2) {
  if (files && files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > getFileSizeThreshold()) {
        compressFile(files[i], callbackFunc, param, param2);
      } else {
        uploadOneFile(files[i], files[i].name, callbackFunc, param, param2);
      }
    }
  }
}

export function uploadFlyerPics(files, callbackFunc, param) {
  if (files && files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      uploadOneFlyerPic(files[i], files[i].name, callbackFunc, param);
    }
  }
}
