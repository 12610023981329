import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Lightbox } from "react-modal-image";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import ActionConfirmation from "../ActionConfirmation";
import Working from "../Working";
import {
  apiBaseUrl,
  fullProductImageUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
  FormatCurrency,
  getIconBaseUrl,
} from "../Util";
import "../../App.css";
import "../../product.css";
import "../../generic.css";
import "../../combo.css";

class ComboPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      comboId: null,
      productId: 0,
      comboName: null,
      finalPrice: 0,
      showSoldOut: true,
      enabled: true,
      description: null,
      groupList: null,
      comboItem: null,
      comboImgWidth: 100,
      imageWidth: 200,
      soldOut: false,
      discount: 0,
      modalImageUrl: null,
      isLoading: true,
      errorMessage: null,
    };

    this.adjustWidth = this.adjustWidth.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.setDiscount = this.setDiscount.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectGroupItem = this.selectGroupItem.bind(this);
    this.setSoldOutStatus = this.setSoldOutStatus.bind(this);
    this.editCombo = this.editCombo.bind(this);
    this.markCombo = this.markCombo.bind(this);
    this.showModalImage = this.showModalImage.bind(this);
    this.closeModalImage = this.closeModalImage.bind(this);
  }

  componentDidMount() {
    this.adjustWidth();
    window.addEventListener("resize", this.adjustWidth);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustWidth);
  }

  adjustWidth() {
    const imageWidth = (getGroupWrapperWidth() * 35) / 100;
    const comboImgWidth = getGroupWrapperWidth() / 4;
    this.setState({ imageWidth, comboImgWidth });
    setTopContainerWrapperSettings();
  }
  showModalImage(imgUrl) {
    this.setState({ modalImageUrl: imgUrl });
  }
  closeModalImage() {
    this.setState({ modalImageUrl: null });
  }
  setSoldOutStatus(group) {
    if (group) {
      let soldOut = true;
      let i;
      for (i = 0; i < group.itemList.length; i++) {
        if (group.itemList[i].forSale) {
          soldOut = false;
          break;
        }
      }
      if (soldOut) {
        const ng = {
          ...group,
          soldOut,
        };
        return ng;
      }
    }
    return group;
  }

  processResponse(response) {
    if (response && response.data) {
      console.log(response.data);
      const c = response.data;
      let soldOut = false;
      let groupList = [];
      const group1 = this.setSoldOutStatus(c.group1);
      const group2 = this.setSoldOutStatus(c.group2);
      const group3 = this.setSoldOutStatus(c.group3);
      const group4 = this.setSoldOutStatus(c.group4);

      if (group1) {
        groupList.push(group1);
        if (group1.soldOut) soldOut = true;
      }
      if (group2) {
        groupList.push(group2);
        if (group2.soldOut) soldOut = true;
      }
      if (group3) {
        groupList.push(group3);
        if (group3.soldOut) soldOut = true;
      }
      if (group4) {
        groupList.push(group4);
        if (group4.soldOut) soldOut = true;
      }
      if (!soldOut) soldOut = c.comboSoldOut;
      this.setState({
        id: c.id,
        productId: c.productId,
        comboId: c.comboId,
        comboName: c.comboName,
        finalPrice: c.finalPrice,
        description: c.description,
        showSoldOut: c.showSoldOut,
        enabled: c.enabled,
        comboItem: c.comboItem,
        soldOut,
        groupList,
        isLoading: false,
      });
      this.setDiscount(c.finalPrice, groupList);
    } else {
      this.props.history.push("/");
    }
  }
  setDiscount(finalPrice, groupList) {
    let totalPrice = 0;
    let i;
    for (i = 0; i < groupList.length; i++) {
      const grp = groupList[i];
      const itemIdx = grp.itemIndex ? grp.itemIndex : 0;
      const pv = grp.itemList[itemIdx];
      totalPrice += pv.finalPrice;
    }
    const discount = finalPrice - totalPrice;
    this.setState({ discount });
  }
  fetchData() {
    const url = apiBaseUrl() + "GetCombo";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.props.match.params.comboId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get Combo",
        });
      });
  }
  handleChange(event) {
    const len = "Group_".length;
    const grpIdx = parseInt(event.target.name.substring(len));
    const itemIndex = parseInt(event.target.value);
    this.selectGroupItem(grpIdx, itemIndex);
  }

  selectGroupItem(grpIdx, itemIndex) {
    let groupList = [];
    let i;

    for (i = 0; i < this.state.groupList.length; i++) {
      const grp = this.state.groupList[i];
      if (grpIdx === i) {
        const mGrp = {
          ...grp,
          itemIndex,
        };
        groupList.push(mGrp);
      } else {
        groupList.push(grp);
      }
    }
    this.setState({ groupList });
    this.setDiscount(this.state.finalPrice, groupList);
  }

  showGroup(group, grpIdx) {
    if (group && group.itemList && group.itemList.length > 0) {
      const itemIdx =
        group.itemIndex && group.itemIndex >= 0 ? group.itemIndex : 0;
      const pv = group.itemList[itemIdx];
      const imgUrl = pv.imageUrl ? pv.imageUrl : pv.prodImageUrl;
      const desc = pv.description;
      let name = pv.fullName ? pv.fullName : pv.variantValue;
      const height = (this.state.imageWidth * 3) / 4;
      const sku = pv.sku;
      const bigImgId = group.soldOut ? "soldOutImage" : "regular";

      return (
        <div key={grpIdx}>
          {isMobileMode() && <b>{group.name}</b>}
          <div className="generic-flex">
            <div>
              <img
                id={bigImgId}
                src={fullProductImageUrl(imgUrl)}
                width={this.state.imageWidth}
                height={height}
                alt="BigImg"
                onClick={() => this.showModalImage(imgUrl)}
              ></img>
            </div>
            <div className="left-10">
              {!isMobileMode() && (
                <div>
                  <b>{group.name} (Click an image below to select)</b>
                </div>
              )}
              <div className="combo-image-wrapper">
                {group.itemList.map((pv, idx) => {
                  const img = pv.imageUrl ? pv.imageUrl : pv.prodImageUrl;
                  const selectable = idx !== itemIdx;
                  const imgId = pv.forSale ? "regular" : "soldOutImage";

                  if (selectable) {
                    return (
                      <div className="brightness" key={idx}>
                        <img
                          id={imgId}
                          src={fullProductImageUrl(img)}
                          width="60"
                          height="45"
                          onClick={() => this.selectGroupItem(grpIdx, idx)}
                        ></img>
                      </div>
                    );
                  } else {
                    return (
                      <div className="right-5">
                        <img
                          id={imgId}
                          src={fullProductImageUrl(img)}
                          width="60"
                          height="45"
                        ></img>
                      </div>
                    );
                  }
                })}
              </div>
              <div>
                <font color="green">Selected SKU:</font>&nbsp;{sku} &nbsp;&nbsp;
                {!pv.forSale && <font color="red">SOLD OUT</font>}
              </div>
              {!isMobileMode() && (
                <React.Fragment>
                  <p />
                  <i>{name}</i>
                </React.Fragment>
              )}
              <p />
              <div dangerouslySetInnerHTML={{ __html: desc }}></div>
            </div>
          </div>
        </div>
      );
    }
  }
  editCombo() {
    const url = "/editCombo/" + this.state.comboId;
    this.props.history.push(url);
  }
  markCombo(event) {
    //event.preventDefault();
    const url = apiBaseUrl() + "MarkCombo";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.id,
      enabled: !this.state.enabled,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get Combo",
        });
      });
  }
  showCombo() {
    if (this.state.comboName) {
      const imgUrl = this.state.comboItem.imageUrl;
      const soIcon = getIconBaseUrl() + "soldout2.jpg";
      const clsName = isMobileMode() ? "none" : "generic-flex";
      const clsName2 = isMobileMode() ? "none" : "left-15";
      const alt = this.state.isMobileMode
        ? "Double tap to see larger picture"
        : "Double click to see larger picture";
      return (
        <div className="prod-wrapper">
          <div className="generic-flex">
            <div>
              <img
                src={fullProductImageUrl(imgUrl)}
                width={this.state.comboImgWidth}
              ></img>
            </div>
            <div className="left-15">
              <b>
                <font size="5">{this.state.comboName}</font>&nbsp;&nbsp;
                {this.state.enabled ? (
                  <font size="4" color="green">
                    (Enabled)
                  </font>
                ) : (
                  <font size="4" color="red">
                    (Disabled)
                  </font>
                )}
              </b>
              <div
                dangerouslySetInnerHTML={{ __html: this.state.description }}
              ></div>
              <div className={clsName}>
                <div>
                  <div>
                    <b>
                      Sale Price:{" "}
                      <font color="red">
                        <FormatCurrency
                          amount={this.state.finalPrice}
                        ></FormatCurrency>
                      </font>
                    </b>
                  </div>
                  <div>
                    <b>
                      Discount:{" "}
                      <font color="red">
                        <FormatCurrency
                          amount={this.state.discount}
                        ></FormatCurrency>
                      </font>
                    </b>
                  </div>
                </div>
                <div className="generic-flex">
                  <div className={clsName2}>
                    <button
                      name="update"
                      onClick={this.editCombo}
                      className="btn-style"
                    >
                      Update
                    </button>
                  </div>
                  <div className="left-15">
                    {this.state.enabled ? (
                      <ActionConfirmation
                        actionHandler={this.markCombo}
                        buttonTitle="Disable"
                        buttonClsName="red-btn-style"
                        title="Disable Combo Confirmation"
                        message="Are you sure you want to disable the combo? Customers will not be able to purchase after disabling."
                      />
                    ) : (
                      <button
                        name="enable"
                        onClick={this.markCombo}
                        className="btn-style"
                      >
                        Enable
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {this.state.soldOut && (
                <div>
                  <img src={soIcon} height="70"></img>
                  <br />
                  This product is temporarily sold out, please check back later.
                </div>
              )}
            </div>
          </div>
          <br />
          <div>
            {this.state.groupList.map((group, grpIdx) => {
              return this.showGroup(group, grpIdx);
            })}
          </div>
          {this.state.modalImageUrl && (
            <Lightbox
              large={fullProductImageUrl(this.state.modalImageUrl)}
              alt={alt}
              hideDownload={true}
              hideZoom={true}
              onClose={this.closeModalImage}
              imageBackgroundColor="white"
            />
          )}
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div>
          <font size="5">Combo Preview</font>
        </div>
        {this.showCombo()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComboPreview);
