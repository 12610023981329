import {
  SEARCH_PRODUCTS,
  SEARCH_ORDERS,
  SET_CATEGORY,
  SET_SEARCH_RESULT,
  CLEAR_SEARCH,
  SET_EXACT_ORDER_RESULT,
} from "../actions/actionTypes";
import { initSearchState } from "../State";

const searchReducer = (state = initSearchState, action) => {
  if (action.type === SEARCH_PRODUCTS) {
    return {
      ...state,
      query: action.query,
      result: null,
    };
  } else if (action.type === SET_CATEGORY) {
    return {
      ...state,
      categoryId: action.categoryId,
      query: "",
      result: null,
    };
  } else if (action.type === SET_SEARCH_RESULT) {
    return {
      ...state,
      result: action.result,
    };
  } else if (action.type === CLEAR_SEARCH) {
    return initSearchState;
  } else if (action.type === SEARCH_ORDERS) {
    return {
      ...state,
      orderQuery: action.payload.query,
      orderMostMatches: action.payload.mostMatches,
      searchType: action.payload.searchType,
      searchCategory: action.payload.category,
      orderResult: null,
    };
  } else if (action.type === SET_EXACT_ORDER_RESULT) {
    return {
      ...state,
      exactOrderList: action.list,
      exactCrit: action.crit,
    };
  }

  return state;
};

export default searchReducer;
