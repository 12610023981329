import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkSecurity } from "../SecManager";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class ReturnPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNumber: null,
      paymentOrderId: null,
      layawayPayment: false,
      userId: this.props.userId,
      password: null,
      amount: 0,
      note: null,
      rtnPmtBtnDisabled: true,
      returnMessage: null,
      errorMessage: null,
      mobileMode: false,
      isProcessing: false,
      returnAllowed: checkSecurity("RootFeatures"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.returnPayment = this.returnPayment.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.returnAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
    }

    this.setState({
      [name]: val,
      rtnPmtBtnDisabled: false,
      returnMessage: "",
      errorMessage: "",
    });
  }
  returnPayment(event) {
    event.preventDefault();

    this.setState({ rtnPmtBtnDisabled: true });
    const url = apiBaseUrl() + "ReturnPaymentEx";
    const req = {
      userId: this.state.userId,
      password: this.state.password,
      note: this.state.note,
      paymentOrderId: this.state.paymentOrderId,
      orderNumber: this.state.orderNumber,
      layawayPayment: this.state.layawayPayment,
      amount: this.state.amount,
      sessionKey: this.props.sessionKey,
    };
    this.setState({ isProcessing: true, returnMessage: "", errorMessage: "" });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            console.log(res.data);
            const pmt = res.data.data;
            let returnMessage = "Payment successfully returned.";
            if (pmt) returnMessage += " Approval Code: " + pmt.approvalCode;

            this.setState({
              isProcessing: false,
              returnMessage,
              errorMessage: null,
              password: "",
              amount: 0,
              note: "",
              layawayPayment: false,
              paymentOrderId: "",
              orderNumber: "",
            });
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              returnMessage: null,
              isProcessing: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "An error has occurred",
          returnMessage: null,
          isProcessing: false,
        });
      });
  }
  showReturnPayment() {
    const btnStyle = this.state.rtnPmtBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const btnTitle = "Return Payment";
    return (
      <div>
        <ValidatorForm ref="form" onSubmit={this.returnPayment}>
          <div className="form-wrapper">
            <div className="form-item-label">User ID:</div>
            <div className="form-item-value">
              <TextInput
                onChange={this.handleChange}
                name="userId"
                size="20"
                value={this.state.userId}
                disabled={true}
                validators={["required"]}
                errorMessages={["User ID is required"]}
              />
            </div>
            <div className="form-item-label">Password:</div>
            <div className="form-item-value">
              <TextInput
                onChange={this.handleChange}
                type="password"
                name="password"
                size="20"
                value={this.state.password}
                validators={["required"]}
                errorMessages={["Password is required"]}
              />
            </div>
            <div className="form-item-label">Order Number:</div>
            <div className="form-item-value">
              <input
                type="text"
                onChange={this.handleChange}
                name="orderNumber"
                size="12"
                value={this.state.orderNumber || ""}
              />
            </div>
            <div className="form-item-label">Payment Order ID:</div>
            <div className="form-item-value">
              <input
                type="text"
                onChange={this.handleChange}
                name="paymentOrderId"
                size="12"
                value={this.state.paymentOrderId || ""}
              />
            </div>
            <div className="form-item-label">Amount:</div>
            <div className="form-item-value">
              <input
                type="text"
                onChange={this.handleChange}
                name="amount"
                size="5"
                value={this.state.amount}
              />
            </div>
            <div className="form-item-label">Reason:</div>
            <div className="form-item-value">
              <input
                type="text"
                onChange={this.handleChange}
                name="note"
                size="35"
                value={this.state.note}
                placeholder="Reason for refund"
              />
            </div>
          </div>
          <p />
          <div>
            <button
              type="submit"
              className={btnStyle}
              disabled={this.state.rtnPmtBtnDisabled}
            >
              {btnTitle}
            </button>
          </div>
        </ValidatorForm>
      </div>
    );
  }

  showPaymentProcInfo() {
    if (this.state.viewPPAllowed) {
      if (this.state.payment && this.state.payment.info.response) {
        const info = this.state.payment.info;
        const answer = info.approved ? "Yes" : "No";
        const colCnt = this.state.mobileMode ? 55 : 90;
        const rowCnt = this.state.mobileMode ? 15 : 10;
        return (
          <div>
            <p />
            <b>Payment Processing Info:</b>
            <br />
            Approved: {answer} <br />
            {info.approved === true && (
              <React.Fragment>
                Approval Code: {info.approvalCode} <br />
                Authorized Amount: ${info.authorizedAmount}
                <br />
              </React.Fragment>
            )}
            Response:
            <br />
            <textarea
              rows={rowCnt}
              cols={colCnt}
              disabled={true}
              value={info.response}
            ></textarea>
          </div>
        );
      }
    }
  }
  progressBar() {
    if (this.state.isProcessing) {
      return (
        <div>
          <p />
          <img
            src={getIconBaseUrl() + "working.gif"}
            alt="Processing"
            width="100"
          />
        </div>
      );
    }
  }
  render() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div>
            <font size="5">Return Payment</font>
            <p />
          </div>
          {this.state.errorMessage && (
            <React.Fragment>
              <font color="red">{this.state.errorMessage}</font>
              <p />
            </React.Fragment>
          )}
          {this.state.returnMessage && (
            <React.Fragment>
              <font color="green">
                <b>{this.state.returnMessage}</b>
              </font>
              <p />
            </React.Fragment>
          )}
          {this.showReturnPayment()}
          {this.progressBar()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReturnPayment);
