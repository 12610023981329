import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditOrderPdfSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      setting: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetOrderPdfSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({ setting: response.data, isLoading: false });
  }
  processUpdateResponse(response) {
    console.log(response);
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;

    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
    }
    const setting = {
      ...this.state.setting,
      [name]: val,
    };
    this.setState({ setting, updateBtnDisabled: false });
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateOrderPdfSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.setting,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  theForm() {
    const setting = this.state.setting;
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";
    const colCnt = this.state.mobileMode ? 42 : 80;
    const rowCnt = this.state.mobileMode ? 12 : 7;
    const rowCnt2 = this.state.mobileMode ? 18 : 12;
    const hfLabel = this.state.mobileMode ? "H&F" : "Header & Footer";
    const bmLabel = this.state.mobileMode ? "Btm Margin" : "Bottom Margin";
    const pcntLabel = this.state.mobileMode ? "Table Pcnt" : "Table Percent";
    return (
      <div className="generic-wrapper">
        <font size="5">Order PDF Settings</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">
            <b>PDF Setting</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>

          <div className="form-item-label">Page Width:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="pageWidth"
              inputmode="numeric"
              value={setting.pageWidth}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Page Height:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="pageHeight"
              inputmode="numeric"
              value={setting.pageHeight}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Top Margin:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="margintop"
              inputmode="numeric"
              value={setting.marginTop}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">{bmLabel}:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="marginBottom"
              inputmode="numeric"
              value={setting.marginBottom}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Left Margin:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="marginLeft"
              inputmode="numeric"
              value={setting.marginLeft}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Header Gap:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="headGap"
              inputmode="numeric"
              value={setting.headGap}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">ShipTo Left:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="shipToLeft"
              inputmode="numeric"
              value={setting.shipToLeft}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">{pcntLabel}:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="tablePercent"
              inputmode="numeric"
              value={setting.tablePercent}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Send PDF:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_sendEnabled"
              defaultChecked={setting.sendEnabled}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Enable Send PDF to Customer
          </div>
          <div className="form-item-label">
            <b>Store Order</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Print:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_autoPrint"
              defaultChecked={setting.autoPrint}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Auto Print Invoice
          </div>
          <div className="form-item-label">Send:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_autoSend"
              defaultChecked={setting.autoSend}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Auto Send Invoice PDF to Customer
          </div>
          <div className="form-item-label">
            <b>{hfLabel}</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Header:</div>
          <div className="form-item-value">
            <textarea
              cols={colCnt}
              rows={rowCnt}
              name="headLeft"
              value={setting.headLeft}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></textarea>
          </div>
          <div className="form-item-label">Header2:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="10"
              name="fsHeader2"
              value={setting.fsHeader2}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Footer:</div>
          <div className="form-item-value">
            <textarea
              cols={colCnt}
              rows={rowCnt2}
              name="footer"
              value={setting.footer}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></textarea>
          </div>
          <div className="form-item-label">Return Footer:</div>
          <div className="form-item-value">
            <textarea
              cols={colCnt}
              rows={3}
              name="returnFooter"
              value={setting.returnFooter || ""}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></textarea>
          </div>
          <div className="form-item-label">
            <b>Font Sizes</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Left Hdr:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="10"
              name="fsLeftHeader"
              value={setting.fsLeftHeader}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Right Hdr:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="10"
              name="fsRightHeader"
              value={setting.fsRightHeader}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Header2:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="10"
              name="fsHeader2"
              value={setting.fsHeader2}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Reg Tbl:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="10"
              name="fsRegTable"
              value={setting.fsRegTable}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Large Tbl:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="10"
              name="fsLargeTable"
              value={setting.fsLargeTable}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Note:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="10"
              name="fsNote"
              value={setting.fsNote}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Footer:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="10"
              name="fsFooter"
              value={setting.fsFooter}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrderPdfSetting);
