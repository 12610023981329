import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { isUserLoggedIn, apiBaseUrl, isMobileMode } from "../Util";
import { checkUpdate, checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import { AXIOS_HEADER } from "../config/constants";
import Working from "../Working";
import "../../App.css";
import "../../form.css";
import "../../generic.css";

class UpdateUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      userId: null,
      firstName: null,
      lastName: null,
      email: null,
      mobilePhone: null,
      groupId: 0,
      enabled: true,
      isLoading: true,
      userGroups: null,
      password: null,
      confirmPassword: null,
      isLoadingUG: true,
      updateBtnDisabled: true,
      errorMessage: null,
      editAllowed: checkUpdate("User"),
      isRoot: checkSecurity("RootFeatures"),
    };
    this.processListResponse = this.processListResponse.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.processUserResponse = this.processUserResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.editAllowed) {
      this.props.history.push("/");
      return;
    }
    this.fetchUser();
    this.fetchList("Groups");
  }
  componentDidUpdate(preProps) {
    if (this.props.match.params.userId !== preProps.match.params.userId) {
      this.fetchUser();
    }
  }
  processListResponse(response, type) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({
        userGroups: response.objList,
        isLoadingUG: false,
      });

      if (!this.props.match.params.userId) {
        const groups = response.objList;
        if (groups && groups.length > 0) {
          this.setState({ groupId: groups[0].id });
        }
      }
    }
  }

  fetchList(type) {
    const url = apiBaseUrl() + "GetAll" + type;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processListResponse(res.data, type);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  processUserResponse(response) {
    if (response) {
      const user = response;
      this.setState({
        id: user.id,
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobilePhone: user.mobilePhone,
        enabled: user.enabled,
        groupId: user.groupId,
        isLoading: false,
      });
    }
  }
  fetchUser() {
    if (this.props.match.params.userId) {
      const url = apiBaseUrl() + "GetUser";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        tbmUser: { userId: this.props.match.params.userId },
      };

      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.processUserResponse(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ updateBtnDisabled: false });
    }
  }
  handleChange(event) {
    if (event.target.name === "enabled") {
      this.setState({
        enabled: event.target.checked,
        updateBtnDisabled: false,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        updateBtnDisabled: false,
      });
    }
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        this.props.history.push("/manageUsers");
      } else {
        const msg = this.props.match.params.userId
          ? "Failed to update user"
          : "Failed to add user";
        this.setState({ errorMessage: msg });
      }
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    if (this.state.groupId >= 3) {
      if (this.state.password && this.state.password.length > 0) {
        if (!this.state.confirmPassword) {
          this.setState({ errorMessage: "Two passwords don't match" });
          return;
        } else {
          const p1 = this.state.password.trim();
          const p2 = this.state.confirmPassword.trim();
          if (p1 !== p2) {
            this.setState({ errorMessage: "Two passwords don't match" });
            return;
          } else if (p1.length < 8) {
            this.setState({ errorMessage: "Password is too short" });
            return;
          }
        }
      }
    }

    const url = this.props.match.params.userId
      ? apiBaseUrl() + "UpdateUser"
      : apiBaseUrl() + "AddUser";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      tbmUser: this.state,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  theForm() {
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const btnTitle = this.props.match.params.userId
      ? "Update User"
      : "Add User";
    const allowPassword = this.state.groupId >= 3 || this.state.isRoot;
    const pwdTitle = this.props.match.params.userId
      ? "New Password"
      : "Password";
    const emailLen = isMobileMode() ? 35 : 50;
    const emailType = isMobileMode() ? "email" : "text";
    const telType = isMobileMode() ? "tel" : "text";
    return (
      <ValidatorForm ref="form" onSubmit={this.handleUpdate}>
        <div className="form-wrapper">
          <div className="form-item-label">
            User ID<font color="red">*</font>:{" "}
          </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="userId"
              size="30"
              value={this.state.userId}
              validators={["required"]}
              errorMessages={["User ID is required"]}
            />
          </div>
          <div className="form-item-label">
            First Name<font color="red">*</font>:{" "}
          </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="firstName"
              size="30"
              value={this.state.firstName}
              validators={["required"]}
              errorMessages={["First Name is required"]}
            />
          </div>
          <div className="form-item-label">
            Last Name<font color="red">*</font>:{" "}
          </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="lastName"
              size="30"
              value={this.state.lastName}
              validators={["required"]}
              errorMessages={["Last Name is required"]}
            />
          </div>
          <div className="form-item-label">
            Email Address<font color="red">*</font>:{" "}
          </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              type={emailType}
              name="email"
              size={emailLen}
              value={this.state.email}
              validators={["required", "isEmail"]}
              errorMessages={["Email is required", "Invalid email address"]}
            />
          </div>
          <div className="form-item-label">
            Phone Number<font color="red">*</font>:{" "}
          </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="mobilePhone"
              type={telType}
              size="15"
              value={this.state.mobilePhone}
              validators={["required"]}
              errorMessages={["Phone Number is required"]}
            />
          </div>
          <div className="form-item-label">
            Group<font color="red">*</font>:{" "}
          </div>
          <div className="form-item-value">
            <select
              name="groupId"
              value={this.state.groupId}
              onChange={this.handleChange}
            >
              {this.state.userGroups.map((grp) => {
                return (
                  <option value={grp.id} key={grp.id}>
                    {grp.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-item-label">Enabled: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              defaultChecked={this.state.enabled}
              name="enabled"
              onChange={this.handleChange}
            ></input>{" "}
            (Uncheck to suspend the user)
          </div>
          {allowPassword && (
            <React.Fragment>
              <div className="form-item-label">{pwdTitle}: </div>
              <div className="form-item-value">
                <input
                  type="password"
                  onChange={this.handleChange}
                  name="password"
                  size="15"
                  value={this.state.password}
                />
              </div>
              <div className="form-item-label">Confirm Password: </div>
              <div className="form-item-value">
                <input
                  type="password"
                  onChange={this.handleChange}
                  name="confirmPassword"
                  size="15"
                  value={this.state.confirmPassword}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <div>
          <button
            type="submit"
            className={btnStyle}
            disabled={this.state.updateBtnDisabled}
          >
            {btnTitle}
          </button>
        </div>
      </ValidatorForm>
    );
  }

  render() {
    if (this.state.isLoadingUG || this.state.isLoadingUG) {
      return <Working size={80} />;
    }
    const title = this.props.match.params.userId
      ? "Update User"
      : "Add New User";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          {this.state.errorMessage && (
            <font color="red">{this.state.errorMessage}</font>
          )}
          {this.theForm()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userState: state.user,
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
