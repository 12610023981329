import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditSalesperson extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salesperson: { id: 0 },
      storeLocations: null,
      isLoading: true,
      updateBtnDisabled: true,
      mobileMode: false,
      errorMessage: null,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchStoreLocations = this.fetchStoreLocations.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchStoreLocations();
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({ salesperson: response.data, isLoading: false });
    }
  }
  fetchData() {
    if (this.props.match.params.id) {
      const url = apiBaseUrl() + "GetSalesperson";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        id: this.props.match.params.id,
      };
      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.processResponse(res.data);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            errorMessage: "Failed to get sales person",
          });
          console.log(error);
        });
    }
  }
  fetchStoreLocations() {
    const url = apiBaseUrl() + "StoreLocations";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ storeLocations: res.data.objList, isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Failed to get store locations",
        });
        console.log(error);
      });
  }
  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let topLevel = false;

    if (name.startsWith("TOP_")) {
      name = name.substring(4);
      topLevel = true;
    }
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      value = event.target.checked;
    }
    if (topLevel) {
      this.setState({ [name]: value });
    } else {
      const salesperson = {
        ...this.state.salesperson,
        [name]: value,
      };
      this.setState({
        salesperson,
        errorMessage: null,
        updateBtnDisabled: false,
      });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "AddUpdateSalesperson";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.salesperson,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleDelete(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "DeleteSalesperson";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.salesperson.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.props.history.push("/manageSales");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processUpdateResponse(response) {
    if (response.status) {
      const url = "/manageSales";
      this.props.history.push(url);
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  theForm() {
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const person = this.state.salesperson;
    const btnTitle = person.id > 0 ? "Update" : "Add";
    const storeId = person.primaryStoreId ? person.primaryStoreId : 0;
    const storeList = this.state.storeLocations
      ? this.state.storeLocations
      : [];
    const emailType = this.state.mobileMode ? "email" : "text";
    const telType = this.state.mobileMode ? "tel" : "text";
    return (
      <div>
        <div className="form-wrapper">
          <div className="form-item-label">
            Nickname<font color="red">*</font>:
          </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name="nickname"
              value={person.nickname || ""}
              onChange={this.handleChange}
              disabled={!editAllowed}
              validators={["required"]}
              errorMessages={["Nickname is required"]}
            ></input>
          </div>
          <div className="form-item-label">Primary Store:</div>
          <div className="form-item-value">
            <select
              name="primaryStoreId"
              onChange={this.handleChange}
              value={storeId}
            >
              <option value={0}>[SELECT STORE]</option>
              {storeList.map((loc) => {
                const info = loc.locationInfo;
                return <option value={info.id}>{info.shortName}</option>;
              })}
            </select>
          </div>
          <div className="form-item-label">First Name: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name="firstName"
              value={person.firstName || ""}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Last Name: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name="lastName"
              value={person.lastName || ""}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Phone: </div>
          <div className="form-item-value">
            <input
              type={telType}
              size="15"
              name="phone"
              value={person.phone || ""}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Email: </div>
          <div className="form-item-value">
            <input
              type={emailType}
              size="40"
              name="email"
              value={person.email || ""}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
        </div>
        <p />
        {this.state.errorMessage && (
          <div>
            <font color="red">{this.state.errorMessage}</font>
            <p />
          </div>
        )}
        <div className="generic-flex">
          <div>
            <button
              name="update"
              className={btnStyle}
              onClick={this.handleUpdate}
              disabled={this.state.updateBtnDisabled || !editAllowed}
            >
              {btnTitle}
            </button>
          </div>
          {this.state.salesperson.id > 0 && (
            <div className="left-10">
              <button
                name="delete"
                className="red-btn-style"
                onClick={this.handleDelete}
                disabled={!editAllowed}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const title =
      this.state.salesperson.id > 0 ? "Update Salesperson" : "Add Salesperson";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          {this.theForm()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSalesperson);
