import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../inventory.css";

class ManageInventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inventoryList: null,
      filteredList: null,
      isLoading: true,
      errorMessage: null,
      message: null,
      batchMode: false,
      enableQuantity: false,
      mobileMode: false,
      updBtnDisabled: true,
      allBtnDisabled: false,
      isUpdating: false,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
      batchAllowed: checkUpdate("Setting"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchInventory = this.fetchInventory.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchInventory = this.searchInventory.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.handleBatchUpdate = this.handleBatchUpdate.bind(this);
    this.processBatchResponse = this.processBatchResponse.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchInventory();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }
  fetchInventory() {
    const url = apiBaseUrl() + "GetInventory";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get inventory",
          isLoading: false,
        });
      });
  }
  processUpdateResponse(response, pvId) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        const filteredList = this.state.filteredList.map((info) => {
          if (info && info.pvId === pvId) {
            const newInfo = {
              ...info,
              origUnitsInStock: info.unitsInStock,
              origPrice: info.unitPrice,
            };
            return newInfo;
          } else return info;
        });
        const theOne = this.state.filteredList.find(
          (info) => info && info.pvId === pvId
        );
        const inventoryList = this.state.inventoryList.map((info) => {
          if (info && info.pvId === pvId) {
            const newInfo = {
              ...info,
              unitsInStock: theOne.unitsInStock,
              origUnitsInStock: theOne.unitsInStock,
              unitPrice: theOne.unitPrice,
              origPrice: theOne.unitPrice,
            };
            return newInfo;
          } else return info;
        });
        this.setState({
          inventoryList,
          filteredList,
          message: response.message,
          isUpdating: false,
          allBtnDisabled: false,
        });
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          isUpdating: false,
          allBtnDisabled: false,
        });
      }
    }
  }
  handleUpdate(pvId) {
    const url = apiBaseUrl() + "UpdateQtyPrice";
    const quantInfo = this.state.filteredList.find(
      (info) => info && info.pvId === pvId
    );
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: quantInfo,
    };
    this.setState({ allBtnDisabled: true, isUpdating: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data, pvId);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to update inventory",
          isLoading: false,
        });
      });
  }
  handleBatchUpdate(event) {
    const url = apiBaseUrl() + "BatchUpdateQtyPrice";
    let updatedList = [];

    this.setState({ updBtnDisabled: true, isUpdating: true });

    for (let i = 0; i < this.state.filteredList.length; i++) {
      const info = this.state.filteredList[i];
      if (
        info &&
        (info.unitsInStock !== info.origUnitsInStock ||
          info.unitPrice !== info.origPrice)
      ) {
        updatedList.push(info);
      }
    }
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: updatedList,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processBatchResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to batch update inventory",
        });
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({
        inventoryList: response.objList,
        filteredList: response.objList,
        isLoading: false,
      });
    }
  }
  processBatchResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        this.setState({
          inventoryList: response.objList,
          filteredList: response.objList,
          batchMode: false,
          updBtnDisabled: true,
          message: response.message,
          isUpdating: false,
        });
        document.getElementById("searchBox").value = "";
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          updBtnDisabled: false,
          isUpdating: false,
        });
      }
    }
  }
  getSkuHtml(quantInfo) {
    let sku = quantInfo.sku;
    if (this.state.mobileMode) {
      let pos = sku.toLowerCase().indexOf("only");
      if (pos > 0) {
        sku = sku.substring(0, pos - 1);
      }
      const max = 15;
      if (sku.length > max) {
        const pre = sku.substring(0, max);
        let suf = sku.substring(max);
        if (suf.length > max) suf = suf.substring(0, max);
        sku = pre + "<br/>" + suf;
      }
    }

    let color = null;
    if (this.state.batchMode) {
      if (quantInfo.origUnitsInStock !== quantInfo.unitsInStock) {
        color = quantInfo.unitsInStock > 0 ? "blue" : "red";
      } else if (quantInfo.origPrice !== quantInfo.unitPrice) {
        color = quantInfo.unitPrice > quantInfo.origPrice ? "green" : "red";
      }
    }
    const html = color ? "<font color='" + color + "'>" + sku + "</font>" : sku;
    return html;
  }
  oneRow(quantInfo, rowIdx) {
    if (quantInfo) {
      const clsName = rowIdx % 2 === 0 ? "inv-item-ev" : "inv-item-od";
      const imgUrl = fullProductImageUrl(quantInfo.imageUrl);
      const updName = "UPD_" + quantInfo.uniqueKey;
      let disabled = false;

      if (
        !this.state.editAllowed ||
        this.state.allBtnDisabled ||
        ((quantInfo.unitsInStock === -999 ||
          quantInfo.unitsInStock === quantInfo.origUnitsInStock) &&
          quantInfo.unitPrice === quantInfo.origPrice)
      ) {
        disabled = true;
      }

      const qv = quantInfo.unitsInStock < 0 ? "" : quantInfo.unitsInStock;
      let btnStyle;
      if (disabled) btnStyle = "inv-disabled-btn";
      else {
        if (quantInfo.unitsInStock > 0) btnStyle = "inv-btn";
        else btnStyle = "inv-red-btn";
      }
      const skuHtml = this.getSkuHtml(quantInfo);
      const pName = "P_" + rowIdx;
      const qName = "Q_" + rowIdx;
      const price = quantInfo.unitPrice > 0 ? quantInfo.unitPrice : "";

      return (
        <React.Fragment key={rowIdx}>
          <div className={clsName}>
            <img
              src={imgUrl}
              alt={quantInfo.uniqueKey}
              width="60"
              height="40"
            ></img>
          </div>
          <div
            className={clsName}
            dangerouslySetInnerHTML={{ __html: skuHtml }}
          ></div>
          <div className={clsName}>
            $
            <input
              type="text"
              inputMode="decimal"
              size="5"
              name={pName}
              value={price}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className={clsName}>
            <div className="generic-flex">
              {this.state.enableQuantity ||
                (!this.state.mobileMode && (
                  <div>
                    <input
                      type="text"
                      inputMode="decimal"
                      size="4"
                      name={qName}
                      value={qv}
                      onChange={this.handleChange}
                      disabled={!this.state.enableQuantity}
                    ></input>
                  </div>
                ))}
              {!this.state.batchMode && (
                <div>
                  &nbsp;
                  <button
                    className={btnStyle}
                    name={updName}
                    disabled={disabled}
                    onClick={() => this.handleUpdate(quantInfo.pvId)}
                  >
                    Update
                  </button>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
  showInventory() {
    const list = this.state.filteredList;

    if (list && list.length > 0) {
      const label =
        this.state.enableQuantity || !this.state.mobileMode ? "Qty." : "";
      return (
        <div>
          <div className="inv-table-c4">
            <div className="inv-item-hd">&nbsp;</div>
            <div className="inv-item-hd">SKU</div>
            <div className="inv-item-hd">Price</div>
            <div className="inv-item-hd">{label}</div>
            {list.map((quantInfo, idx) => {
              return this.oneRow(quantInfo, idx);
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <font color="red">No match</font>
        </div>
      );
    }
  }
  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "batchMode") {
      this.setState({ batchMode: event.target.checked });
      return;
    } else if (name === "enableQuantity") {
      this.setState({ enableQuantity: event.target.checked });
      return;
    }

    let idx = -1;
    let isQty = false;
    if (name.startsWith("P_")) {
      idx = parseInt(name.substring(2));
    } else if (name.startsWith("Q_")) {
      idx = parseInt(name.substring(2));
      isQty = true;
    }

    if (idx >= 0) {
      let iVal = -1;
      if (value && value.length > 0) {
        iVal = parseInt(value);
      }
      let filteredList = this.state.filteredList;

      let qtyPriceInfo = filteredList[idx];
      if (isQty) {
        qtyPriceInfo.unitsInStock = iVal;
      } else {
        qtyPriceInfo.unitPrice = iVal;
      }

      let updBtnDisabled = true;
      for (let i = 0; i < filteredList.length; i++) {
        if (this.isChanged(filteredList[i])) {
          updBtnDisabled = false;
          break;
        }
      }
      this.setState({
        filteredList,
        updBtnDisabled,
        message: "",
        errorMessage: "",
      });
    }
  }
  isChanged(item) {
    if (
      item.unitsInStock != item.origUnitsInStock ||
      item.unitPrice != item.origPrice
    ) {
      return true;
    }
    return false;
  }
  searchInventory(event) {
    let query = event.target.value;
    if (!query || query.length === 0) {
      this.setState({
        filteredList: this.state.inventoryList,
        batchMode: false,
      });
    } else {
      const all = this.state.inventoryList;
      let filteredList = [];

      query = query.toUpperCase();
      for (let i = 0; i < all.length; i++) {
        const uSku = all[i].sku.toUpperCase();
        if (uSku.search(query) >= 0) filteredList.push(all[i]);
      }
      this.setState({
        filteredList,
        errorMessage: "",
        message: "",
        batchMode: true,
      });
    }
  }
  searchBox() {
    const total = this.state.filteredList ? this.state.filteredList.length : 0;
    return (
      <div className="generic-flex">
        <div>
          <b>Total: {total}&nbsp;&nbsp;</b>
        </div>
        <div>
          <input
            id="searchBox"
            type="search"
            size="25"
            name="searchCrit"
            placeholder="Search"
            onKeyUp={this.searchInventory}
          ></input>
        </div>
      </div>
    );
  }
  messageBox() {
    if (this.state.isUpdating) {
      return <Working />;
    }
    if (this.state.errorMessage) {
      return (
        <div className="top-10">
          <font color="red">{this.state.errorMessage}</font>
        </div>
      );
    } else if (this.state.message) {
      return (
        <div className="top-10">
          <font color="blue">{this.state.message}</font>
        </div>
      );
    }
  }
  batchUpdateBox() {
    const list = this.state.filteredList;
    if (this.state.batchAllowed && list && list.length > 0) {
      const btnStyle = this.state.updBtnDisabled
        ? "inv-disabled-btn"
        : "inv-btn";
      return (
        <div>
          <div className="generic-flex">
            <div>
              <input
                type="checkbox"
                name="batchMode"
                checked={this.state.batchMode}
                onChange={this.handleChange}
              ></input>
              Batch Mode&nbsp;&nbsp;
            </div>
            <div className="left-10">
              <input
                type="checkbox"
                name="enableQuantity"
                checked={this.state.enableQuantity}
                onChange={this.handleChange}
              ></input>
              Change Quantity&nbsp;&nbsp;
            </div>
            {this.state.batchMode && (
              <div>
                <button
                  className={btnStyle}
                  name="batchUpdate"
                  onClick={this.handleBatchUpdate}
                  disabled={
                    !this.state.editAllowed || this.state.updBtnDisabled
                  }
                >
                  Batch Update
                </button>
              </div>
            )}
          </div>
          {this.state.batchMode && <div>&nbsp;</div>}
        </div>
      );
    }
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Product Inventory</font>
          <p />
          {this.searchBox()}
          <p />
          {this.batchUpdateBox()}
          {this.messageBox()}
          {this.showInventory()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageInventory);
