import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import ActionConfirmation from "../ActionConfirmation";
import {
  isUserLoggedIn,
  apiBaseUrl,
  isMobileMode,
  getIconBaseUrl,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";

import "../../App.css";
import "../../generic.css";
import "../../session.css";
import "../../faq.css";

class ManageSessions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sessions: null,
      mobileMode: false,
      isLoading: true,
      selectedIdx: -1,
      logEntries: null,
      isGettingLogFile: false,
      logFileInfo: null,
      errorMessage: null,
      editAllowed: checkUpdate("UserSession"),
      viewAllowed: checkView("UserSession"),
    };

    this.processResponse = this.processResponse.bind(this);
    this.deleteSession = this.deleteSession.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchLogEntries = this.fetchLogEntries.bind(this);
    this.getLogFile = this.getLogFile.bind(this);
  }

  componentDidMount() {
    if (!isUserLoggedIn(this.props.userState)) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchSessions();
    this.fetchLogEntries();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({ sessions: response.objList, isLoading: false });
    }
  }

  fetchSessions() {
    const url = apiBaseUrl() + "GetSessions";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchLogEntries() {
    const url = apiBaseUrl() + "GetLogEntries";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ logEntries: res.data.objList });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getLogFile(name) {
    const url = apiBaseUrl() + "FetchLog";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: name,
    };

    this.setState({ isGettingLogFile: true, logFileInfo: null });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        let logFileInfo = null;
        if (res.data.data) {
          logFileInfo = res.data.data;
        }
        this.setState({ isGettingLogFile: false, logFileInfo });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isGettingLogFile: false, logFileInfo: null });
      });
  }
  deleteSession(idx) {
    this.setState({ selectedIdx: idx });
    document.getElementById("hiddenConfirmBtn").click();
  }

  doDelete() {
    if (this.state.selectedIdx >= 0) {
      const url = apiBaseUrl() + "DeleteSession";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        data: this.state.sessions[this.state.selectedIdx],
      };

      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.processResponse(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  header() {
    const clsName = "grid-item";
    const label = this.state.mobileMode ? "Session Info" : "Login Time";
    return (
      <React.Fragment>
        <div className={clsName}>
          <b>User ID</b>
        </div>
        <div className={clsName}>
          <b>{label}</b>
        </div>
        {!this.state.mobileMode && (
          <React.Fragment>
            <div className={clsName}>
              <b>Last Active</b>
            </div>
            <div className={clsName}>
              <b>IP Address</b>
            </div>
          </React.Fragment>
        )}
        <div>&nbsp;</div>
      </React.Fragment>
    );
  }
  showOneSess(idx) {
    const mod = idx % 2;
    const sess = this.state.sessions[idx];
    let clsName = "grid-item" + mod;
    const img = sess.sessionKey === this.props.sessionKey ? "me.jpg" : null;

    if (sess.expired) clsName += "-ex";

    return (
      <React.Fragment key={idx}>
        <div className={clsName}>
          {img && <img src={getIconBaseUrl() + img} height="15" alt="Me"></img>}
          {sess.userId}
        </div>
        {this.state.mobileMode ? (
          <div className={clsName}>
            <div className="twocol-table">
              <div>Login:</div>
              <div> {sess.loginTimeStr}</div>
              <div>Last:</div>
              <div>{sess.lastActTimeStr}</div>
              {sess.ipAddress && (
                <React.Fragment>
                  <div>IP Addr:</div>
                  <div>{sess.ipAddress}</div>
                </React.Fragment>
              )}
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className={clsName}>{sess.loginTimeStr}</div>
            <div className={clsName}>{sess.lastActTimeStr}</div>
            <div className={clsName}>{sess.ipAddress}</div>
          </React.Fragment>
        )}

        <div className="grid-item2">
          <img
            src={getIconBaseUrl() + "cross.png"}
            height="14"
            onClick={() => this.deleteSession(idx)}
            alt="Delete"
          ></img>
        </div>
      </React.Fragment>
    );
  }
  showSessions() {
    const clsName = this.state.mobileMode ? "threecol-table" : "fivecol-table";
    return (
      <div className={clsName}>
        {this.header()}
        {this.state.sessions.map((sess, idx) => this.showOneSess(idx))}
      </div>
    );
  }
  showLogEntries() {
    const list = this.state.logEntries;
    if (list && list.length > 0) {
      return (
        <div>
          <div>
            <font size="4">Logs</font>
          </div>
          <ul>
            {list.map((info, idx) => {
              return (
                <li key={idx}>
                  <a href="#" onClick={() => this.getLogFile(info.name)}>
                    {info.name}
                  </a>
                </li>
              );
            })}
          </ul>
          <p />
          {this.state.isGettingLogFile && (
            <div>
              <img
                src={getIconBaseUrl() + "working.gif"}
                alt="Generating"
                width="100"
              />
            </div>
          )}
          {this.state.logFileInfo && (
            <div>
              <a href={this.state.logFileInfo.url} target="__log">
                {this.state.logFileInfo.url}
              </a>
            </div>
          )}
        </div>
      );
    }
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">User Sessions</font>
          <p />
          {this.showSessions()}
          <p />
          {this.showLogEntries()}
          <p />
          <font color="red">{this.state.errorMessage}</font>
        </div>
        <ActionConfirmation
          hidden={true}
          btnId="hiddenConfirmBtn"
          actionHandler={this.doDelete}
          buttonTitle="Hidden Delete"
          buttonClsName="red-btn-style"
          title="Delete Confirmation"
          message="Are you sure you want to delete this session? The user will be logged out."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userState: state.user,
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageSessions);
