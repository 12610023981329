import React from "react";
import { Link } from "react-router-dom";
import {
  toAmountEx,
  fullProductImageUrl,
  getMainSiteUrl,
  getIconBaseUrl,
} from "../Util";
import "../../App.css";
import "../../waitlistStats.css";

class PHWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      phList: this.props.phList,
      mobileMode: this.props.mobileMode,
      showSku: this.props.showSku,
      showExpCol: this.props.showExpCol,
      maxPerPage: this.props.maxPerPage ? this.props.maxPerPage : 300,
      pageIdx: 0,
    };
    this.handleExpand = this.handleExpand.bind(this);
    this.pageNumClicked = this.pageNumClicked.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.phList !== prevProps.phList ||
      this.props.showSku !== prevProps.showSku
    ) {
      this.setState({
        phList: this.props.phList,
        mobileMode: this.props.mobileMode,
        showSku: this.props.showSku,
        showExpCol: this.props.showExpCol,
        expanded: false,
      });
    }
  }
  header(hasPages, showChange) {
    const clsName = "wl-grid-item-hd";
    return (
      <React.Fragment>
        {hasPages && !this.state.mobileMode && <div className={clsName}></div>}
        <div className={clsName}>
          <b>Date</b>
        </div>
        {this.state.showSku && (
          <div className={clsName}>
            <b>SKU</b>
          </div>
        )}
        <div className={clsName}>
          <b>Old Price</b>
        </div>
        <div className={clsName}>
          <b>New Price</b>
        </div>
        {showChange && (
          <div className={clsName}>
            <b>Change</b>
          </div>
        )}
        {!this.state.mobileMode && (
          <div className={clsName}>
            <b>User</b>
          </div>
        )}
      </React.Fragment>
    );
  }
  showPHTable(phList) {
    if (!this.state.showExpCol || this.state.expanded) {
      const hasPages = this.state.maxPerPage < phList.length ? true : false;
      let clsName;
      let showChange = true;

      if (this.state.mobileMode) {
        clsName = "wl-4col-table";
        if (this.state.showSku) showChange = false;
      } else {
        let numCols = 5;
        if (hasPages) numCols++;
        if (this.state.showSku) numCols++;
        clsName = "wl-" + numCols + "col-table";
      }

      let rows = [];
      const startIdx = this.state.pageIdx * this.state.maxPerPage;
      let endIdx = startIdx + this.state.maxPerPage;
      if (endIdx > phList.length) {
        endIdx = phList.length;
      }

      for (let i = startIdx; i < endIdx; i++) {
        const ph = phList[i];
        const color = ph.changePercent > 0 ? "green" : "red";
        const url = "/product/" + ph.productId + "/" + ph.sku;
        const msUrl =
          getMainSiteUrl() + "/product/" + ph.productId + "/" + ph.sku;
        const sortSymbol =
          ph.changePercent > 0 ? (
            <font color="green">
              <b>&#8593;</b>
            </font>
          ) : (
            <font color="red">
              <b>&#8595;</b>
            </font>
          );
        const clsName2 = i % 2 === 0 ? "wl-grid-item-ev" : "wl-grid-item-od";
        const dtStr = this.state.mobileMode ? ph.shortDtStr : ph.dtStr;
        let sku = ph.sku;
        if (this.state.mobileMode && this.state.showSku && sku.length > 10) {
          sku = sku.substring(0, 10) + "...";
        }
        const one = (
          <React.Fragment key={i}>
            {hasPages && !this.state.mobileMode && (
              <div className={clsName2}>{i + 1}.</div>
            )}
            <div className={clsName2}>{dtStr}</div>
            {this.state.showSku && (
              <div className={clsName2}>
                <Link to={url}>
                  <img
                    src={fullProductImageUrl(ph.imageUrl)}
                    height="30"
                    width="40"
                  />
                </Link>{" "}
                <a href={msUrl} target="MainSite">
                  {sku}
                </a>
              </div>
            )}
            <div className={clsName2}>{toAmountEx(ph.oldPrice)}</div>
            <div className={clsName2}>{toAmountEx(ph.newPrice)}</div>
            {showChange && (
              <div className={clsName2}>
                {sortSymbol}
                <font color={color}>{ph.changePercent.toFixed(2)}%</font>
              </div>
            )}
            {!this.state.mobileMode && (
              <div className={clsName2}>{ph.userId}</div>
            )}
          </React.Fragment>
        );
        rows.push(one);
      }
      return (
        <div className={clsName}>
          {this.header(hasPages, showChange)}
          {rows}
        </div>
      );
    }
  }
  pageNumClicked(idx) {
    this.setState({ pageIdx: idx });
  }
  showPageNumbers() {
    const maxPerPage = this.state.maxPerPage;
    const list = this.state.phList;
    if (list && list.length > maxPerPage) {
      let pages = Math.ceil(list.length / maxPerPage);
      if (list.length % maxPerPage) {
        if (pages * maxPerPage < list.length) {
          pages++;
        }
      }

      const pna = [];
      pna.push(<div key="PT">Pages:</div>);
      for (let i = 0; i < pages; i++) {
        let one;
        const clsName =
          i == this.state.pageIdx ? "left-5-bold" : "left-5-nodec";
        if (i == this.state.pageIdx) {
          one = (
            <div className={clsName} key={i}>
              {i + 1}
            </div>
          );
        } else {
          one = (
            <div className={clsName} key={i}>
              <a href="#" onClick={() => this.pageNumClicked(i)}>
                {i + 1}
              </a>
            </div>
          );
        }
        pna.push(one);
      }

      return <div className="generic-flex-top5">{pna}</div>;
    }
  }
  handleExpand() {
    this.setState({ expanded: !this.state.expanded });
  }
  showPriceHistory() {
    const phList = this.state.phList;
    if (phList && phList.length > 0) {
      let title;

      if (this.state.showSku) {
        title = (
          <b>
            <font size="4">Price History</font>
          </b>
        );
      } else {
        title = <i>{phList[0].sku} Price History</i>;
      }
      if (this.state.showExpCol) {
        const icon = this.state.expanded ? "minus.jpg" : "plus.jpg";
        title = (
          <div className="bottom-10">
            <a href="#NONE" onClick={this.handleExpand}>
              <img src={getIconBaseUrl() + icon} height="13"></img>
            </a>{" "}
            {title}
          </div>
        );
      }

      return (
        <div>
          {title}
          {this.showPageNumbers()}
          {this.showPHTable(phList)}
          {this.showPageNumbers()}
        </div>
      );
    }
    return "";
  }

  render() {
    return this.showPriceHistory();
  }
}
export default PHWidget;
