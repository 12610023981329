import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
} from "../Util";
import { checkView } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../waitlistStats.css";

class MarkComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      yesterdayList: null,
      otherList: null,
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      updBtnEnabled: false,
      showMore: false,
      isWorking: false,
      viewAllowed: checkView("Order"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.getOrderList = this.getOrderList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.markComplete = this.markComplete.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.processOrderResponse = this.processOrderResponse.bind(this);
    this.getTypeCount = this.getTypeCount.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.getOrderList("Yesterdays");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }
  processOrderResponse(orderType, res) {
    this.setState({ isWorking: false });
    if (res.status) {
      if (res.objList && res.objList.length > 0) {
        const yesterday = this.getYesterday();
        const orderList = res.objList.map((ord) => {
          const nOrd = {
            ...ord,
            completeDate: yesterday,
          };
          return nOrd;
        });

        if (orderType == "Yesterdays") {
          this.setState({ yesterdayList: orderList, isLoading: false });
        } else {
          this.setState({ otherList: orderList, isLoading: false });
        }
      }
      if (orderType == "Yesterdays") {
        this.getOrderList("Other");
      }
    }
  }
  getOrderList(orderType, showWorking) {
    const url = apiBaseUrl() + "Get" + orderType + "Candidates";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    if (showWorking) this.setState({ isWorking: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.processOrderResponse(orderType, res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get order list",
          isLoading: false,
        });
      });
  }
  markComplete() {
    const url = apiBaseUrl() + "MarkOrdersComplete";
    let objList = [];

    this.setState({
      updBtnEnabled: false,
      isWorking: true,
      errorMessage: null,
    });

    if (this.state.yesterdayList) {
      for (let i = 0; i < this.state.yesterdayList.length; i++) {
        const order = this.state.yesterdayList[i];
        if (order.selected) {
          objList.push(order);
        }
      }
    }
    if (this.state.otherList) {
      for (let i = 0; i < this.state.otherList.length; i++) {
        const order = this.state.otherList[i];
        if (order.selected) {
          objList.push(order);
        }
      }
    }

    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList,
    };
    axiosRetry(axios, { retries: 3 });

    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ isWorking: false });

        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            this.getOrderList("Yesterdays", true);
          } else {
            this.setState({
              updBtnEnabled: true,
              errorMessage: res.data.errorMessage,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get order list",
          isLoading: false,
          updBtnEnabled: true,
          isWorking: false,
        });
      });
  }

  handleChange(event) {
    let name = event.target.name;

    if (name.startsWith("CB_") || name.startsWith("OB_")) {
      const isYesterday = name.startsWith("CB_") ? true : false;
      const rowIdx = parseInt(name.substring(3));
      let updBtnEnabled = true;

      if (!event.target.checked) {
        let selCnt = 0;
        const yesterdayList = this.state.yesterdayList;
        const otherList = this.state.otherList;

        if (yesterdayList) {
          for (let i = 0; i < yesterdayList.length; i++) {
            if (yesterdayList[i].selected) selCnt++;
          }
        }
        if (otherList) {
          for (let i = 0; i < otherList.length; i++) {
            if (otherList[i].selected) selCnt++;
          }
        }
        if (selCnt < 2) updBtnEnabled = false;
      }

      if (isYesterday) {
        const yesterdayList = this.state.yesterdayList.map((r, idx) => {
          if (idx === rowIdx) {
            const nr = {
              ...r,
              selected: event.target.checked,
            };
            return nr;
          } else return r;
        });

        this.setState({ yesterdayList, updBtnEnabled });
      } else {
        const otherList = this.state.otherList.map((r, idx) => {
          if (idx === rowIdx) {
            const nr = {
              ...r,
              selected: event.target.checked,
            };
            return nr;
          } else return r;
        });

        this.setState({ otherList, updBtnEnabled });
      }
    } else if (name.startsWith("IDX_") || name.startsWith("ODX_")) {
      const isYesterday = name.startsWith("IDX_") ? true : false;

      name = name.substring(4);
      const pos = name.indexOf("_");
      const rowIdx = parseInt(name.substring(0, pos));

      name = name.substring(pos + 1);
      let orderList = isYesterday
        ? this.state.yesterdayList
        : this.state.otherList;
      let selected = orderList[rowIdx].selected;

      let updBtnEnabled = this.state.updBtnEnabled;
      if (name == "note" && event.target.value) {
        selected = true;
        updBtnEnabled = true;
      }
      orderList = orderList.map((ord, idx) => {
        if (idx == rowIdx) {
          const newOrd = {
            ...ord,
            [name]: event.target.value,
            selected,
          };
          return newOrd;
        } else return ord;
      });
      if (isYesterday) {
        this.setState({ yesterdayList: orderList, updBtnEnabled });
      } else {
        this.setState({ otherList: orderList, updBtnEnabled });
      }
    } else {
      if (name === "checkAll") {
        const checked = event.target.checked;
        let yesterdayList = this.state.yesterdayList;
        let otherList = this.state.otherList;

        if (yesterdayList) {
          yesterdayList = this.state.yesterdayList.map((ord) => {
            const newOrd = {
              ...ord,
              selected: checked,
            };
            return newOrd;
          });
        }
        if (otherList) {
          otherList = this.state.otherList.map((ord) => {
            const newOrd = {
              ...ord,
              selected: checked,
            };
            return newOrd;
          });
        }
        this.setState({
          yesterdayList,
          otherList,
          updBtnEnabled: checked,
        });
      } else {
        const val = event.target.value;
        const addBtnEnabled = val && val.trim().length > 0 ? true : false;
        this.setState({ [name]: val, addBtnEnabled });
      }
    }
  }

  oneRow(order, rowIdx, isOther) {
    const clsName = rowIdx % 2 === 0 ? "wl-grid-item-ev" : "wl-grid-item-od";
    const url = order.layaway
      ? "/layawayDetail/" + order.orderNumber
      : "/orderDetail/" + order.orderNumber;
    const cbName = isOther ? "OB_" + rowIdx : "CB_" + rowIdx;
    const prefix = isOther ? "ODX_" + rowIdx + "_" : "IDX_" + rowIdx + "_";

    return (
      <React.Fragment>
        <div className={clsName}>
          <input
            type="checkbox"
            name={cbName}
            checked={order.selected}
            onChange={this.handleChange}
            disabled={this.state.isWorking}
          />
        </div>
        <div className={clsName}>
          <a href={url}>{order.orderNumber}</a>
        </div>
        {!this.state.mobileMode && (
          <div className={clsName}>{order.shortDateStr}</div>
        )}
        <div className={clsName}>{order.deliveryDateStr}</div>
        <div className={clsName}>
          <input
            type="text"
            size="10"
            name={prefix + "completeDate"}
            value={order.completeDate || ""}
            onChange={this.handleChange}
            disabled={this.state.isWorking}
          />
        </div>
        <div className={clsName}>
          <input
            type="text"
            size="10"
            name={prefix + "note"}
            onChange={this.handleChange}
            value={order.note || ""}
            disabled={this.state.isWorking}
          />
        </div>
      </React.Fragment>
    );
  }

  header() {
    const clsName = "wl-grid-item-hd";
    const orderDateTitle = this.state.mobileMode ? "Date" : "Order Date";
    const completeTitle = this.state.mobileMode ? "Complete" : "Complete Date";

    return (
      <React.Fragment>
        <div className={clsName}>&nbsp;</div>
        <div className={clsName}>
          <b>Order</b>
        </div>
        {!this.state.mobileMode && (
          <div className={clsName}>
            <b>{orderDateTitle}</b>
          </div>
        )}
        <div className={clsName}>
          <b>Scheduled</b>
        </div>
        <div className={clsName}>
          <b>{completeTitle}</b>
        </div>
        <div className={clsName}>
          <b>Note</b>
        </div>
      </React.Fragment>
    );
  }
  checkAllBox() {
    if (this.getTypeCount() > 0) {
      const disableAll = this.state.isWorking;
      return (
        <div>
          <input
            type="checkbox"
            name="checkAll"
            onChange={this.handleChange}
            disabled={disableAll}
          ></input>
          ALL
        </div>
      );
    } else {
      return "";
    }
  }
  getYesterday() {
    let date = new Date();

    date.setDate(date.getDate() - 1);
    let yesterday = date.getFullYear() + "-";
    if (date.getMonth() < 9) yesterday = yesterday + "0";
    yesterday = yesterday + (date.getMonth() + 1) + "-";
    if (date.getDate() < 10) yesterday = yesterday + "0";
    yesterday = yesterday + date.getDate();
    return yesterday;
  }
  showOrders() {
    if (this.getTypeCount() > 0) {
      const clsName = !this.state.mobileMode
        ? "wl-6col-table"
        : "wl-5col-table";
      const showOther =
        this.state.showMore ||
        !this.state.yesterdayList ||
        this.state.yesterdayList.length == 0
          ? true
          : false;
      return (
        <div>
          {this.checkAllBox()}
          <div className={clsName}>
            {this.header()}
            {this.state.yesterdayList &&
              this.state.yesterdayList.map((order, rowIdx) => {
                return this.oneRow(order, rowIdx, false);
              })}
            {showOther &&
              this.state.otherList &&
              this.state.otherList.map((order, rowIdx) => {
                return this.oneRow(order, rowIdx, true);
              })}
          </div>
          {this.showMoreLess()}
        </div>
      );
    }
  }

  handleClick() {
    this.setState({ showMore: !this.state.showMore });
  }

  showMoreLess() {
    if (this.getTypeCount() > 1) {
      const icon = this.state.showMore ? "minus.jpg" : "plus.jpg";
      const title = this.state.showMore
        ? "Show less ......"
        : "Show more ......";
      return (
        <div className="top-10">
          <div>
            <img
              src={getIconBaseUrl() + icon}
              width="16"
              onClick={() => this.handleClick()}
              alt="PM"
            ></img>
            {title}
          </div>
        </div>
      );
    }
  }

  getTypeCount() {
    let typeCnt = 0;

    let list = this.state.yesterdayList;
    if (list && list.length > 0) {
      typeCnt++;
    }

    list = this.state.otherList;
    if (list && list.length > 0) {
      typeCnt++;
    }

    return typeCnt;
  }
  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    const title = "Mark Orders Complete";
    if (this.getTypeCount() < 1) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">{title}</font>
            <p />
            <font color="red">There is no orders.</font>
          </div>
        </div>
      );
    }
    const clsName = this.state.updBtnEnabled
      ? "narrow-btn"
      : "disabled-narrow-btn";

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div className="title-flex">
            <div>
              <font size="5">{title}</font> &nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <p />
          <label>
            For each order, click the checkbox, type the date each order was
            completed, then enter optional note. Click the "Mark Complete"
            button to mark the selected orders complete.
          </label>
          <p />
          {this.state.message && (
            <div>
              <font color="blue">{this.state.message}</font>
              <p />
            </div>
          )}
          {this.showOrders()}
          <br />
          <div className="threecol-table">
            <div>
              <button
                name="Update"
                className={clsName}
                onClick={this.markComplete}
                disabled={!this.state.updBtnEnabled}
              >
                Mark Complete
              </button>
            </div>

            <div>&nbsp;&nbsp;</div>
            {this.state.isWorking && (
              <div>
                <img src={getIconBaseUrl() + "working.gif"} width={35} />
              </div>
            )}
          </div>

          <p />
          <font color="red">{this.state.errorMessage}</font>
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MarkComplete);
