import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditStates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stateList: null,
      isLoading: true,
      updBtnEnabled: false,
      mobileMode: false,
      errorMessage: null,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  fetchData() {
    const url = apiBaseUrl() + "GetStates";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const objList = response.objList;
      this.setState({
        stateList: objList,
        isLoading: false,
      });
    }
  }
  handleChange(event) {
    const pos = event.target.name.indexOf("_");
    const id = parseInt(event.target.name.substring(pos + 1));
    const name = event.target.name.substring(0, pos);
    let stateList = [];
    let i;

    for (i = 0; i < this.state.stateList.length; i++) {
      if (id === this.state.stateList[i].id) {
        const state = {
          ...this.state.stateList[i],
          [name]: event.target.checked,
        };
        stateList.push(state);
      } else {
        stateList.push(this.state.stateList[i]);
      }
    }
    this.setState({ stateList, updBtnEnabled: true });
  }

  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleUpdate(event) {
    const url = apiBaseUrl() + "UpdateStates";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: this.state.stateList,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  theForm() {
    if (this.state.stateList && this.state.stateList.length > 0) {
      return (
        <div className="fourcol-wrapper">
          <div className="right-20">
            <b>State</b>
          </div>
          <div className="right-20">
            <b>Payment</b>
          </div>
          <div className="right-20">
            <b>Contact</b>
          </div>
          <div>
            <b>Delivery</b>
          </div>
          {this.state.stateList.map((state) => {
            const dvName = "delivery_" + state.id;
            const pmName = "payment_" + state.id;
            const ctName = "contact_" + state.id;
            return (
              <React.Fragment key={state.name}>
                <div>{state.name}</div>
                <div>
                  <input
                    type="checkbox"
                    name={pmName}
                    defaultChecked={state.payment}
                    disabled={!this.state.editAllowed}
                    onClick={this.handleChange}
                  ></input>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name={ctName}
                    defaultChecked={state.contact}
                    disabled={!this.state.editAllowed}
                    onClick={this.handleChange}
                  ></input>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name={dvName}
                    defaultChecked={state.delivery}
                    disabled={!this.state.editAllowed}
                    onClick={this.handleChange}
                  ></input>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const btnStyle = this.state.updBtnEnabled
      ? "btn-style"
      : "disabled-btn-style";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">States</font>
          <p />
          {this.theForm()}
          <p />
          <button
            name="Update"
            className={btnStyle}
            disabled={!this.state.updBtnEnabled}
            onClick={this.handleUpdate}
          >
            Update
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditStates);
