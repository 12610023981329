import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { apiBaseUrl } from "../Util";
import { setResetMsg } from "../actions/userActions";
import { AXIOS_HEADER } from "../config/constants";

import "../../App.css";
import "../../form.css";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      firstName: "",
      lastName: "",
      last4Phone: "",
      errorMessage: null,
      showDialog: false,
      message: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.processResetPassResponse = this.processResetPassResponse.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  processResetPassResponse(response) {
    if (response.success) {
      this.props.setResetMsg(response.message);
      this.props.history.push("/resetMessage");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleResetPassword(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "ResetPass";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, this.state, { headers: AXIOS_HEADER })
      .then(res => {
        this.processResetPassResponse(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  resetForm() {
    return (
      <ValidatorForm ref="form" onSubmit={this.handleResetPassword}>
        <div className="form-wrapper">
          <div className="form-item-label">User ID: </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="userId"
              size="15"
              value={this.state.userId}
              validators={["required"]}
              errorMessages={["User ID is required"]}
            />
          </div>
          <div className="form-item-label">First Name: </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="firstName"
              size="30"
              value={this.state.firstName}
              validators={["required"]}
              errorMessages={["First Name is required"]}
            />
          </div>
          <div className="form-item-label">Last Name: </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="lastName"
              size="30"
              value={this.state.lastName}
              validators={["required"]}
              errorMessages={["Last Name is required"]}
            />
          </div>
          <div className="form-item-label">Last 4 Digits of Phone: </div>
          <div className="form-item-value">
            <TextInput
              onChange={this.handleChange}
              name="last4Phone"
              size="5"
              value={this.state.last4Phone}
              validators={["isNumber", "minNumber:1000", "maxNumber:9999"]}
              errorMessages={[
                "Invalid number",
                "Must be 4 digits",
                "Must be 4 digits"
              ]}
            />
          </div>
        </div>
        <button type="submit" name="reset" className="btn-style">
          Reset Password
        </button>
        <p />
        <font color="red">{this.state.errorMesage}</font>
      </ValidatorForm>
    );
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Reset Password</font>
          <p />
          {this.resetForm()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setResetMsg: msg => {
      dispatch(setResetMsg(msg));
    }
  };
};

export default connect(null, mapDispatchToProps)(ResetPassword);
