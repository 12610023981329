import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, isUserLoggedIn } from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditAssembly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assemblyFees: [],
      isLoading: true,
      updateBtnDisabled: true,
      newName: null,
      newFee: null,
      newFeeMax: -1,
      errorMessage: null,
      editAllowed: checkUpdate("Fee"),
      viewAllowed: checkView("Fee"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.updateAssemblyFees = this.updateAssemblyFees.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    const url = apiBaseUrl() + "GetAssemblyList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ assemblyFees: res.data.objList, isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateAssemblyFees(event) {
    const url = apiBaseUrl() + "UpdateAssemblyFees";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: this.state.assemblyFees,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    const name = event.target.name;

    if (name.startsWith("max_")) {
      const afName = name.substring(4);
      const found = this.state.assemblyFees.find((af) => af.name === afName);
      if (found) {
        let assemblyFees = this.state.assemblyFees;
        found.feeMax = event.target.value;
        this.setState({ assemblyFees, updateBtnDisabled: false });
      }
    } else if (name.startsWith("new")) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      const found = this.state.assemblyFees.find(
        (af) => af.name === event.target.name
      );
      if (found) {
        let assemblyFees = this.state.assemblyFees;
        found.fee = event.target.value;
        this.setState({ assemblyFees, updateBtnDisabled: false });
      }
    }
  }

  handleDelete(event) {
    const name = event.target.name;
    const newList = this.state.assemblyFees.filter((af) => af.name !== name);

    this.setState({ assemblyFees: newList, updateBtnDisabled: false });
  }

  handleAdd(event) {
    event.preventDefault();

    if (!this.state.newName || !this.state.newFee) {
      this.setState({ errorMessage: "Name and fee are required!" });
    } else {
      let assemblyFees = this.state.assemblyFees;
      if (assemblyFees.find((af) => af.name === this.state.newName)) {
        this.setState({ errorMessage: "Duplicate name!" });
      } else {
        const af = {
          id: 0,
          name: this.state.newName,
          fee: this.state.newFee,
          feeMax: this.state.newFeeMax,
        };
        assemblyFees.push(af);
        this.setState({
          assemblyFees,
          updateBtnDisabled: false,
          newName: "",
          newFee: "",
          newFeeMax: -1,
        });
      }
    }
  }

  showList() {
    if (this.state.isLoading === false) {
      const editAllowed = this.state.editAllowed;
      return (
        <div className="fourcol-form">
          <div className="right-20">
            <b>Name</b>
          </div>
          <div className="right-20">
            <b>Fee</b>
          </div>
          <div className="right-20">
            <b>Max Fee</b>
          </div>
          <div>&nbsp;</div>
          {this.state.assemblyFees.map((af, idx) => {
            const maxName = "max_" + af.name;
            return (
              <React.Fragment key={idx}>
                <div className="right-20">{af.name}</div>
                <div className="right-20">
                  <input
                    type="text"
                    inputmode="decimal"
                    size="4"
                    name={af.name}
                    value={af.fee}
                    onChange={this.handleChange}
                    disabled={!editAllowed}
                  ></input>
                </div>
                <div className="right-20">
                  <input
                    type="text"
                    inputmode="decimal"
                    size="4"
                    name={maxName}
                    value={af.feeMax}
                    onChange={this.handleChange}
                    disabled={!editAllowed}
                  ></input>
                </div>
                <div>
                  {this.state.editAllowed && (
                    <button name={af.name} onClick={this.handleDelete}>
                      Delete
                    </button>
                  )}
                  &nbsp;
                </div>
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  }

  showAddBox() {
    if (this.state.editAllowed) {
      return (
        <div className="flex-wrapper">
          <div>
            <input
              type="text"
              name="newName"
              placeholder="[Name]"
              value={this.state.newName}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="left-10">
            <input
              type="text"
              inputmode="decimal"
              name="newFee"
              placeholder="[Fee]"
              size="8"
              value={this.state.newFee}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="left-10">
            <input
              type="text"
              inputmode="decimal"
              size="8"
              name="newFeeMax"
              value={this.state.newFeeMax}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="left-10">
            <button name="add" className="btn-style" onClick={this.handleAdd}>
              Add
            </button>
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Assembly Fees</font>
          <p />
          <button
            className={btnStyle}
            name="update"
            disabled={this.state.updateBtnDisabled}
            onClick={this.updateAssemblyFees}
          >
            Update
          </button>
          <p />
          <div className="flex-wrapper-full">
            {this.showList()}
            <div className="fill-right">&nbsp;</div>
          </div>
          <p />
          {this.showAddBox()}
          <p />
          <font color="red">{this.state.errorMessage}</font>
          <p />
          <a href="/editAsmFee">Add Product Assembly Fees</a>
          <p />
          <a href="/editAsmFee/assigned">View/Update Product Assembly Fees</a>
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAssembly);
