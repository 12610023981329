import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "./config/constants";
import {
  apiBaseUrl,
  getIconBaseUrl,
  isUserLoggedIn,
  fullProductImageUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "./Util";
import { checkView, checkUpdate } from "./SecManager";
import { setLoggedOut } from "./actions/userActions";
import Working from "./Working";
import "../App.css";
import "../generic.css";
import "../form.css";

class StatsDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statsDetail: null,
      mobileMode: false,
      isLoading: true,
      m2dExpanded: false,
      overallExpanded: false,
      errorMessage: null,
      editAllowed: checkUpdate("Statistics"),
      viewAllowed: checkView("Statistics"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetStatsDetail";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.props.match.params.statsDate,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ statsDetail: res.data.data, isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "Failed to get statistics",
          isLoading: false,
        });
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  statsHtml(psList, type) {
    const cntName = type === 0 ? "Count" : "View Count";
    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html +=
      '<table><tr><th style="background-color: #D0D0D0">Image</th><th style="background-color: #D0D0D0">' +
      cntName +
      "</th>";
    if (type === 1)
      html += '<th style="background-color: #D0D0D0">User Count</th>';
    html += '<th style="background-color: #D0D0D0">Product ID</th>';
    if (!this.state.mobileMode)
      html += '<th style="background-color: #D0D0D0">Price Range</th>';
    if (type === 0) html += '<th style="background-color: #D0D0D0">SKU</th>';
    else html += '<th style="background-color: #D0D0D0">Date Added</th>';
    html += "</tr>";

    if (psList) {
      for (let i = 0; i < psList.length; i++) {
        html +=
          '<tr><td><a href="/product/' +
          psList[i].productId +
          '"><img src="' +
          fullProductImageUrl(psList[i].imageUrl) +
          '" width="60" height="45"></img></a></td>';
        html += "<td>" + psList[i].count + "</td>";
        if (type === 1) html += "<td>" + psList[i].userCount + "</td>";
        html += "<td>" + psList[i].productId + "</td>";
        if (!this.state.mobileMode) {
          html += "<td>" + psList[i].price + "</td>";
        }
        if (type === 0) html += "<td>" + psList[i].sku + "</td>";
        else html += "<td>" + psList[i].dateAdded + "</td>";
        html += "</tr>";
      }
    }
    html += "</table>";
    return html;
  }

  handleClick(name) {
    if (name === "Overall") {
      const overallExpanded = !this.state.overallExpanded;
      this.setState({ overallExpanded });
    } else {
      const m2dExpanded = !this.state.m2dExpanded;
      this.setState({ m2dExpanded });
    }
  }
  MVPStats(name, mvpList, expanded) {
    if (mvpList && mvpList.length > 0) {
      const icon = expanded ? "minus.jpg" : "plus.jpg";
      return (
        <React.Fragment>
          <div className="generic-flex">
            <div>
              <img
                src={getIconBaseUrl() + icon}
                width="20"
                onClick={() => this.handleClick(name)}
                alt="PM"
              ></img>
              &nbsp; <b>{name} Most Viewed Products</b>
            </div>
          </div>
          {expanded && (
            <div
              className="form-item-value"
              dangerouslySetInnerHTML={{ __html: this.statsHtml(mvpList, 1) }}
            ></div>
          )}
        </React.Fragment>
      );
    }
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;
    if (!this.state.statsDetail)
      return (
        <div>
          <font color="red">No statistics details available</font>
        </div>
      );

    const stats = this.state.statsDetail.stats;
    const detail = this.state.statsDetail;
    const mvp = this.state.statsDetail.mostViewedProducts;
    const lvp = this.state.statsDetail.leastViewedProducts;
    const m2dMVP = this.state.statsDetail.month2DateMVPList;
    const overallMVP = this.state.statsDetail.overallMVPList;
    const mas = this.state.statsDetail.mostAbandonedSkus;
    let timestamp = stats.timestamp;
    if (this.state.mobileMode && timestamp && timestamp.length > 10) {
      timestamp = timestamp.substring(0, 10);
    }
    let orderStr = "" + stats.orderCount;
    if (detail.orderByStoreList != null && detail.orderByStoreList.length > 0) {
      orderStr += "<br/>";
      for (let i = 0; i < detail.orderByStoreList.length; i++) {
        const name = this.state.mobileMode
          ? detail.orderByStoreList[i].shortName
          : detail.orderByStoreList[i].name;
        orderStr +=
          "* " + name + ": " + detail.orderByStoreList[i].count + "<br/>";
      }
    }

    let layawayStr = "" + stats.layawayCount;
    if (
      detail.layawayByStoreList != null &&
      detail.layawayByStoreList.length > 0
    ) {
      layawayStr += "<br/>";
      for (let i = 0; i < detail.layawayByStoreList.length; i++) {
        const name = this.state.mobileMode
          ? detail.layawayByStoreList[i].shortName
          : detail.layawayByStoreList[i].name;
        layawayStr +=
          "* " + name + ": " + detail.layawayByStoreList[i].count + "<br/>";
      }
    }

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{stats.statsDate} Statistics</font>
          <p />
          <div className="form-wrapper">
            <div className="form-item-label">Stats created:</div>
            <div className="form-item-value">{timestamp}</div>
            <div className="form-item-label">Total orders:</div>
            <div
              className="form-item-value"
              dangerouslySetInnerHTML={{ __html: orderStr }}
            ></div>
            <div className="form-item-label">Total layaways:</div>
            <div
              className="form-item-value"
              dangerouslySetInnerHTML={{ __html: layawayStr }}
            ></div>
            <div className="form-item-label">Total payments:</div>
            <div className="form-item-value">{stats.paymentCount}</div>
            <div className="form-item-label">Total customers:</div>
            <div className="form-item-value">{stats.userCount}</div>
            <div className="form-item-label">Homepage customers:</div>
            <div className="form-item-value">{stats.hpUserCount}</div>
            <div className="form-item-label">Total searchs:</div>
            <div className="form-item-value">{stats.searchCount}</div>
            <div className="form-item-label">Total Product Views:</div>
            <div className="form-item-value">{stats.totalProdViews}</div>
            <div className="form-item-label">Abandoned orders:</div>
            <div className="form-item-value">{stats.abandonedOrders}</div>
            <div className="form-item-label">Abandoned shopping carts:</div>
            <div className="form-item-value">{stats.abandonedCartUsers}</div>
            <div className="form-item-label">Abandoned cart items:</div>
            <div className="form-item-value">{stats.abandonedCartItems}</div>
          </div>
          <p />
          {this.MVPStats("Month to Date", m2dMVP, this.state.m2dExpanded)}
          <br />
          {this.MVPStats("Overall", overallMVP, this.state.overallExpanded)}
          <p />
          <div>
            <b>Most viewed products:</b>
          </div>
          <div
            className="form-item-value"
            dangerouslySetInnerHTML={{ __html: this.statsHtml(mvp, 1) }}
          ></div>
          <p />
          <div>
            <b>Least viewed products:</b>
          </div>
          <div
            className="form-item-value"
            dangerouslySetInnerHTML={{ __html: this.statsHtml(lvp, 2) }}
          ></div>
          <p />
          <div>
            <b>Most abandoned SKUs (in abandoned shopping carts):</b>
          </div>
          <div
            className="form-item-value"
            dangerouslySetInnerHTML={{ __html: this.statsHtml(mas, 0) }}
          ></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StatsDetail);
