import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  previewImgBaseUrl,
  prodImgBaseUrl,
  getIconBaseUrl,
  fullProductImageUrl,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { uploadFiles } from "../FileUploader";
import ActionConfirmation from "../ActionConfirmation";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../storeprod.css";

class EditStoreProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storeProduct: null,
      locList: null,
      isLoading: true,
      updateBtnDisabled: true,
      descExpanded: false,
      mobileMode: false,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchStoreList = this.fetchStoreList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.getStoreName = this.getStoreName.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.deleteStoreProduct = this.deleteStoreProduct.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
    this.fetchStoreList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({ storeProduct: response.data, isLoading: false });
    }
  }
  fetchData() {
    const id = this.props.match.params.id ? this.props.match.params.id : 0;
    const url = apiBaseUrl() + "GetStoreProduct";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get store setting",
        });
        console.log(error);
      });
  }
  fetchStoreList() {
    const url = apiBaseUrl() + "StoreLocations";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ locList: res.data.objList });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get store locations",
        });
        console.log(error);
      });
  }
  processUploadResponse(response, fileName) {
    const storeProduct = {
      ...this.state.storeProduct,
      uploadedImageFile: fileName,
    };
    this.setState({ storeProduct });
  }
  handleChange(event) {
    let name = event.target.name;
    if (name === "imageUrl") {
      uploadFiles(event.target.files, this.processUploadResponse);
    } else if (name.startsWith("INV_")) {
      name = name.substring(4);
      let quantity = event.target.value;

      if (name.startsWith("CB_")) {
        if (event.target.checked) {
          quantity = 1;
        } else {
          quantity = 0;
        }
        name = name.substring(3);
      }

      const theIdx = parseInt(name);
      const inventoryList = this.state.storeProduct.inventoryList.map(
        (inv, idx) => {
          if (idx === theIdx) {
            return { ...inv, quantity };
          } else return inv;
        }
      );
      const storeProduct = {
        ...this.state.storeProduct,
        inventoryList,
      };
      this.setState({ storeProduct, updateBtnDisabled: false });
    } else {
      let val = event.target.value;
      if (name.startsWith("CB_")) {
        name = name.substring(3);
        val = event.target.checked;
      }
      const storeProduct = {
        ...this.state.storeProduct,
        [name]: val,
      };
      this.setState({ storeProduct, updateBtnDisabled: false });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateStoreProduct";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.storeProduct,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  deleteStoreProduct() {
    const url = apiBaseUrl() + "DeleteStoreProduct";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.storeProduct.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.props.history.push("/manageStoreProd");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processUpdateResponse(response) {
    if (response.status) {
      const url = "/manageStoreProd";
      this.props.history.push(url);
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  getStoreName(locId) {
    const locList = this.state.locList;
    let name = "Location: " + locId;
    if (locList) {
      const loc = locList.find((lr) => lr.locationInfo.id === locId);
      if (loc) {
        name = loc.locationInfo.shortName;
      }
    }
    return name;
  }
  handleClick() {
    const descExpanded = !this.state.descExpanded;
    this.setState({ descExpanded });
  }
  deleteConfirmation() {
    document.getElementById("hiddenConfirmDelBtn").click();
  }
  showQuantityOrEnable(sp) {
    const label = sp.typeId > 0 ? "Enabled" : "Quantity";
    return (
      <React.Fragment>
        <div className="form-item-label">{label}:</div>
        <div className="sp-2col-table">
          {sp.inventoryList.map((inv, idx) => {
            const name = sp.typeId > 0 ? "INV_CB_" + idx : "INV_" + idx;
            if (sp.typeId > 0) {
              return (
                <React.Fragment>
                  <div>
                    <input
                      name={name}
                      value={inv.quantity}
                      type="checkbox"
                      defaultChecked={inv.quantity > 0}
                      onChange={this.handleChange}
                      disabled={!this.state.editAllowed}
                    ></input>
                  </div>
                  <div>&nbsp;{this.getStoreName(inv.storeId)}</div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <div>{this.getStoreName(inv.storeId)}:</div>
                  <div className="left-10">
                    <input
                      name={name}
                      value={inv.quantity}
                      type="text"
                      inputmode="decimal"
                      size="6"
                      onChange={this.handleChange}
                      disabled={!this.state.editAllowed}
                    ></input>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      </React.Fragment>
    );
  }
  showType(sp) {
    let typeImageUrl = null;
    if (sp.typeId > 0 && sp.depositTypeList) {
      const found = sp.depositTypeList.find((dt) => dt.type == sp.typeId);
      if (found) typeImageUrl = found.imageUrl;
    }
    return (
      <div className="flex-wrapper">
        <div>
          <select name="typeId" value={sp.typeId} onChange={this.handleChange}>
            <option value={0}>Store Product</option>
            {sp.depositTypeList.map((dt) => {
              return (
                <option value={dt.type} key={dt.type}>
                  {dt.name}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          &nbsp;
          {typeImageUrl && (
            <img src={fullProductImageUrl(typeImageUrl)} height="20"></img>
          )}
        </div>
      </div>
    );
  }
  showAdvancedFields(sp) {
    if (this.state.descExpanded) {
      const editAllowed = this.state.editAllowed;
      const rows = this.state.mobileMode ? 6 : 8;
      const cols = this.state.mobileMode ? 40 : 60;
      const nameLabel = sp.typeId > 0 ? "Deposit Name" : "Product Name";
      const placeholder = "Same as " + nameLabel;
      return (
        <div>
          <i>Description:</i>
          <br />
          <textarea
            name="description"
            value={sp.description || ""}
            onChange={this.handleChange}
            disabled={!editAllowed}
            rows={rows}
            cols={cols}
          />
          <p />
          <i>DAT Field Name:</i>
          <br />
          <input
            type="text"
            size="30"
            name="datFieldName"
            value={sp.datFieldName}
            placeholder={placeholder}
            onChange={this.handleChange}
          ></input>
        </div>
      );
    } else {
      return <span>Advanced Fields</span>;
    }
  }
  theForm() {
    const sp = this.state.storeProduct;
    const editAllowed = this.state.editAllowed;
    const btnStyle =
      this.state.updateBtnDisabled || !editAllowed
        ? "disabled-btn-style"
        : "btn-style";
    const btnTitle = sp.id ? "Update" : "Add";
    const baseUrl = sp.uploadedImageFile
      ? previewImgBaseUrl()
      : prodImgBaseUrl();
    let imageUrl = sp.uploadedImageFile ? sp.uploadedImageFile : sp.imageUrl;
    let icon = this.state.descExpanded ? "minus.jpg" : "plus.jpg";
    const altText = this.state.descExpanded ? "Shrink" : "Expand";
    const sku = sp.id <= 0 && sp.typeId > 0 ? sp.depositSku : sp.sku;
    const nameLabel = sp.typeId > 0 ? "Deposit Name" : "Product Name";

    if (imageUrl) {
      imageUrl = baseUrl + imageUrl;
    }
    icon = getIconBaseUrl() + icon;

    return (
      <div>
        <ValidatorForm ref="form" onSubmit={this.handleUpdate}>
          <div className="form-wrapper">
            <div className="form-item-label">SKU: </div>
            <div className="form-item-value">{sku}</div>
            <div className="form-item-label">Type:</div>
            {this.showType(sp)}
            <div className="form-item-label">
              {nameLabel}
              <font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                size="30"
                name="name"
                value={sp.name || ""}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required"]}
                errorMessages={["Name is required"]}
              ></TextInput>
            </div>
            {sp.typeId > 0 && (
              <React.Fragment>
                <div className="form-item-label">&nbsp;</div>
                <div className="form-item-value">
                  <input
                    type="checkbox"
                    name="CB_layaway"
                    defaultChecked={sp.layaway}
                    onChange={this.handleChange}
                    disabled={!editAllowed}
                  ></input>{" "}
                  Is Layaway Deposit
                </div>
              </React.Fragment>
            )}
            {sp.typeId <= 0 && (
              <React.Fragment>
                <div className="form-item-label">
                  Price<font color="red">*</font>:
                </div>
                <div className="form-item-value">
                  <TextInput
                    size="6"
                    inputmode="decimal"
                    name="price"
                    value={sp.price || "0"}
                    onChange={this.handleChange}
                    disabled={!editAllowed || sp.typeId > 0}
                    validators={["required", "isNumber", "minNumber:1"]}
                    errorMessages={[
                      "Price is required",
                      "Must be a number",
                      "Price too low",
                    ]}
                  ></TextInput>
                </div>
              </React.Fragment>
            )}
            {this.showQuantityOrEnable(sp)}
            <div className="form-item-label">Picture:</div>
            <div className="sp-form-value">
              {imageUrl && (
                <div>
                  <img src={imageUrl} alt="Image" width="60" height="40"></img>
                  &nbsp;&nbsp;
                </div>
              )}
              <div>
                <input
                  type="file"
                  name="imageUrl"
                  multiple="false"
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <div className="form-item-label">
              Advanced:{" "}
              <img
                src={icon}
                alt={altText}
                width="16"
                onClick={this.handleClick}
              ></img>
            </div>
            <div className="form-item-value">{this.showAdvancedFields(sp)}</div>
          </div>
          <p />
          <button
            name="update"
            className={btnStyle}
            disabled={this.state.updateBtnDisabled || !editAllowed}
          >
            {btnTitle}
          </button>
        </ValidatorForm>
        <br />
        {sp.id > 0 && editAllowed && (
          <div>
            <button
              name="delete"
              className="red-btn-style"
              onClick={this.deleteConfirmation}
            >
              Delete
            </button>
            <ActionConfirmation
              hidden={true}
              btnId="hiddenConfirmDelBtn"
              actionHandler={this.deleteStoreProduct}
              buttonTitle="Hidden Delete"
              buttonClsName="red-btn-style"
              title="Delete Confirmation"
              message='Are you sure you want to delete this store product? If you click "Yes", it will be deleted for all stores.'
            />
          </div>
        )}
      </div>
    );
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const title =
      this.state.storeProduct.id > 0
        ? "Update Store Product"
        : "Add Store Product";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          {this.theForm()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditStoreProduct);
