import { createStore, combineReducers } from "redux";
import UserReducer from "../reducers/userReducer";
import SearchReducer from "../reducers/searchReducer";
import WebOrderReducer from "../reducers/webOrderReducer";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const rootReducer = combineReducers({
  user: UserReducer,
  search: SearchReducer,
  webOrder: WebOrderReducer,
});

const persistedState = loadFromLocalStorage();
export const store = createStore(rootReducer, persistedState);

store.subscribe(() => saveToLocalStorage(store.getState()));
