import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import OrderSearchWidget from "../order/OrderSearchWidget";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
  FormatCurrencyByAmt,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import OrderTableView from "./OrderTableView";
import StoreDatesSelector from "../StoreDatesSelector";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class ListOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: null,
      title: null,
      searching: false,
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      orderByDates: false,
      editAllowed: checkUpdate("Order"),
      viewAllowed: checkView("Order"),
    };

    this.fetchOrders = this.fetchOrders.bind(this);
    this.searchOrders = this.searchOrders.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchOrders({ storeId: -1 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  isLayaway() {
    const layaway =
      this.props.match.params.type && this.props.match.params.type == 2
        ? true
        : false;
    return layaway;
  }
  fetchOrders(storeDates) {
    const url = apiBaseUrl() + "SearchOrderExact";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      storeId: storeDates.storeId,
      startDate: storeDates.startDate,
      endDate: storeDates.endDate,
      type: 99,
      layaway: this.isLayaway(),
    };
    let title = null;
    if (storeDates.startDate && storeDates.endDate) {
      title = storeDates.startDate + " - " + storeDates.endDate;
    } else if (storeDates.startDate) {
      title = "Start Date: " + storeDates.startDate;
    } else if (storeDates.endDate) {
      title = "End Date: " + storeDates.endDate;
    }
    this.setState({
      title,
      searching: true,
      orderList: null,
    });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
          return;
        } else {
          this.setState({
            orderList: res.data.objList,
            isLoading: false,
            searching: false,
            orderByDates: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get orders",
          searching: false,
        });
      });
  }
  searchOrders(query, category, mostMatches) {
    const searchType = this.isLayaway() ? 2 : 1;
    const url = apiBaseUrl() + "SearchOrder";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      query,
      searchType,
      category,
      mostMatches,
      briefResult: true,
    };
    this.setState({
      title: category + ": " + query,
      searching: true,
      orderList: null,
    });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({
          orderList: res.data.briefList,
          searching: false,
          title: "Search Result",
          orderByDates: false,
        });
      })
      .catch((error) => {
        let errorMessage = "An error has occurred";
        if (checkUpdate("Setting")) {
          errorMessage += ": " + error;
        }
        console.log(error);
        this.setState({
          isLoading: false,
          errorMessage,
          searching: false,
        });
      });
  }
  displayPriceInfo() {
    if (
      this.state.orderByDates &&
      this.state.orderList &&
      this.state.orderList.length > 0
    ) {
      let grandTotal = 0;
      let cancelledTotal = 0;

      for (let i = 0; i < this.state.orderList.length; i++) {
        const order = this.state.orderList[i];
        grandTotal += order.totalPrice;
        if (
          (order.layaway && order.statusId == 7) ||
          (!order.layaway && order.statusId == 8)
        ) {
          cancelledTotal += order.totalPrice;
        }
      }
      return (
        <div className="tbl-2col">
          <div>
            <b>Grand Total: </b>
          </div>
          <div className="left-10">{FormatCurrencyByAmt(grandTotal)}</div>
          {cancelledTotal > 0 && (
            <React.Fragment>
              <div>
                <b>Cancelled Total: </b>
              </div>
              <div className="left-10">
                <font color="red">
                  {FormatCurrencyByAmt(0 - cancelledTotal)}
                </font>
              </div>
              <div>
                <b>Net Total: </b>
              </div>
              <div className="left-10">
                {FormatCurrencyByAmt(grandTotal - cancelledTotal)}
              </div>
            </React.Fragment>
          )}
        </div>
      );
    }
  }

  displayNonResult() {
    if (this.state.searching) {
      return <img src={getIconBaseUrl() + "working.gif"} alt="Searching" />;
    } else {
      return <label>No order was found: {this.state.title}</label>;
    }
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;
    const size = this.state.mobileMode ? 15 : 20;
    const count = this.state.orderList ? this.state.orderList.length : 0;
    const name = this.isLayaway() ? "Layaways" : "Orders";
    const searchType = this.isLayaway() ? 2 : 1;
    let title = this.state.title;
    if (!title) {
      if (this.isLayaway()) title = "Layaways in Last 30 Days (" + count + ")";
      else title = "Orders in Last 2 Days (" + count + ")";
    }
    const btnTitle = this.isLayaway() ? "List Layaways" : "List Orders";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div className="generic-flex">
            <div>
              <font size="5">{name}</font>
            </div>
            <OrderSearchWidget
              searchType={searchType}
              callback={this.searchOrders}
              size={size}
            />
          </div>

          <br />
          <StoreDatesSelector
            userId={this.props.userId}
            sessionKey={this.props.sessionKey}
            btnTitle={btnTitle}
            actionHandler={this.fetchOrders}
          />
          <br />
          <hr className="hr-300" align="left" />
          {this.state.orderList && this.state.orderList.length > 0 ? (
            <OrderTableView
              orderList={this.state.orderList}
              showInvLinks={true}
              title={title}
              isLayaway={this.isLayaway()}
            />
          ) : (
            this.displayNonResult()
          )}
          {this.displayPriceInfo()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListOrders);
