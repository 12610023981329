import {
  SEARCH_PRODUCTS,
  SEARCH_ORDERS,
  SET_CATEGORY,
  SET_SEARCH_RESULT,
  CLEAR_SEARCH,
  SET_EXACT_ORDER_RESULT,
} from "./actionTypes";

export const searchProducts = (query) => {
  return {
    type: SEARCH_PRODUCTS,
    query: query,
  };
};
export const searchOrders = (payload) => {
  return {
    type: SEARCH_ORDERS,
    payload,
  };
};
export const setCategory = (categoryId) => {
  return {
    type: SET_CATEGORY,
    categoryId: categoryId,
  };
};

export const setSearchResult = (result) => {
  return {
    type: SET_SEARCH_RESULT,
    result: result,
  };
};
export const setExactOrderResult = (result, crit) => {
  return {
    type: SET_EXACT_ORDER_RESULT,
    list: result,
    crit: crit,
  };
};
export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH,
  };
};
