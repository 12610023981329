import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import {
  apiBaseUrl,
  setTopContainerWrapperSettings,
  isUserLoggedIn,
  isMobileMode,
} from "../Util";
import "../../categoryBar.css";

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      mobileMode: false,
    };
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }

    const url = apiBaseUrl() + "Categories";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({
            items: res.data.objList,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  render() {
    if (this.state.items === null) return "";

    return (
      <div className="top-wrapper">
        <div className="cat-wrapper">
          <div className="cat-left-side">
            {this.state.items.map((item) => {
              const title = this.state.mobileMode
                ? item.category.name
                : item.category.name +
                  "(" +
                  item.forSaleCount +
                  "," +
                  item.notForSaleCount +
                  ")";
              const url = "/product-category/" + item.category.id;
              return (
                <div className="cat-link" key={item.category.id}>
                  <Link to={url}>{title}</Link>
                </div>
              );
            })}
            {!this.state.mobileMode && (
              <div className="cat-link">
                <Link to="/invisible" key="invisible">
                  Invisible
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userState: state.user,
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Category));
