import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import Slider from "react-slick";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  fullProductImageUrl,
  setTopContainerWrapperSettings,
  isUserLoggedIn,
  isMobileMode,
} from "../Util";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class HomeImagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeImages: [],
      isLoading: true,
      deletedIdList: [],
      uploadedList: [],
      updateBtnDisabled: true,
      imageWidth: 180,
      mobileMode: false,
    };
    this.adjustImageWidth = this.adjustImageWidth.bind(this);
    this.modifyHomeImages = this.modifyHomeImages.bind(this);
    this.confirmHomeImages = this.confirmHomeImages.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }

    const url = apiBaseUrl() + "GetHIForPreview";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    this.adjustImageWidth();
    window.addEventListener("resize", this.adjustImageWidth);
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ homeImages: res.data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustImageWidth);
  }

  adjustImageWidth() {
    const maxForScreen = window.innerWidth - 15 * 2;
    const imageWidth = 1170 > maxForScreen ? maxForScreen : 1170;

    this.setState({ imageWidth, mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }

  modifyHomeImages() {
    this.props.history.push("/editHomeImages");
  }
  confirmHomeImages() {
    const url = apiBaseUrl() + "ConfirmHomeImages";
    let userId = this.props.userId;

    this.adjustImageWidth();
    window.addEventListener("resize", this.adjustImageWidth);
    axiosRetry(axios, { retries: 3 });
    axios
      .post(
        url,
        { userId: userId, sessionKey: this.props.sessionKey },
        { headers: AXIOS_HEADER }
      )
      .then((res) => {
        this.props.history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleClick(prodId) {
    this.props.history.push("/product/" + prodId);
  }

  showHomeImages() {
    const imageList = this.state.homeImages;

    if (imageList && imageList.length > 0) {
      if (imageList.length === 1) {
        return (
          <img
            src={fullProductImageUrl(imageList[0].imageUrl)}
            alt="Home"
            width={this.state.imageWidth}
          ></img>
        );
      } else {
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: true,
        };

        return (
          <Slider {...settings} style={{ width: this.state.imageWidth }}>
            {imageList.map((homeImg, idx) => (
              <div
                onClick={() => this.handleClick(homeImg.productId)}
                key={idx}
              >
                <img
                  src={fullProductImageUrl(homeImg.imageUrl)}
                  width={this.state.imageWidth}
                  alt="Home"
                />
              </div>
            ))}
          </Slider>
        );
      }
    }
  }

  render() {
    return (
      <div className="top-container">
        <div className="top-wrapper">
          <font size="5">Home Images</font>
          <p />
          {this.showHomeImages()}
          <p />
          <div className="generic-flex">
            <div>
              <button
                name="modify"
                onClick={this.modifyHomeImages}
                className="btn-style"
              >
                Modify
              </button>
            </div>
            <div className="width-20"></div>
            <div>
              <button
                name="confirm"
                onClick={this.confirmHomeImages}
                className="green-btn-style"
              >
                Confirm
              </button>
            </div>
          </div>
          <div>
            <p />
            You must click the "Confirm" button to make the changes to take
            effect. <br />
            If you are not satisfied with your change, click the "Modify" button
            to make changes again, your previous changes will be discarded.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};

export default connect(mapStateToProps)(HomeImagePreview);
