import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { apiBaseUrl } from "../Util";

class Logout extends React.Component {
  componentDidMount() {
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    const url = apiBaseUrl() + "Logout";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.setLoggedOut();
    this.props.history.push("/login");
  }

  render() {
    return "";
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
