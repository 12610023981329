import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { Lightbox } from "react-modal-image";
import {
  apiBaseUrl,
  prodImgBaseUrl,
  previewImgBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
  getMainSiteUrl,
} from "../Util";
import { uploadFiles } from "../FileUploader";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

const TYPE_ORIGINAL = 1;
const TYPE_UPLOADED = 2;

class EditAdvertisement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adsList: [],
      isLoading: true,
      deletedIdList: [],
      uploadedList: [],
      modalImageUrl: null,
      imageWidth: 197,
      imageHeight: 120,
      mobileMode: false,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.changeStatus = this.changeStatus.bind(this);
    this.deleteAds = this.deleteAds.bind(this);
    this.preview = this.preview.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.addPicFile = this.addPicFile.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showModalImage = this.showModalImage.bind(this);
    this.closeModalImage = this.closeModalImage.bind(this);
    this.updateAdvertisement = this.updateAdvertisement.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);

    const url = apiBaseUrl() + "GetAdsList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          let adsList = res.data.objList;
          if (adsList) {
            for (let i = 0; i < adsList.length; i++) {
              adsList[i].origUrl = adsList[i].linkUrl;
            }
          }
          this.setState({ adsList, isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get list " + error,
        });
        console.log(error);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  updateAdvertisement(event) {
    const url = apiBaseUrl() + "UpdateAds";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      adsList: this.state.adsList,
      uploadedList: this.state.uploadedList,
      deletedIdList: this.state.deletedIdList,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({
            adsList: res.data.objList,
            deletedIdList: [],
            uploadedList: [],
            updateBtnDisabled: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteAds(idx, type) {
    let tbdId = 0;
    let list =
      type === TYPE_UPLOADED ? this.state.uploadedList : this.state.adsList;
    const newList = [];

    for (let i = 0; i < list.length; i++) {
      if (i !== idx) newList.push(list[i]);
      else tbdId = list[i].id;
    }

    if (type === TYPE_UPLOADED) {
      this.setState({
        uploadedList: newList,
        updateBtnDisabled: false,
      });
    } else {
      let idList = this.state.deletedIdList;
      idList.push(tbdId);
      this.setState({
        adsList: newList,
        deletedIdList: idList,
        updateBtnDisabled: false,
      });
    }
  }

  processUploadResponse(response, fileName) {
    if (response.status) {
      let list = this.state.uploadedList;
      list.push({ imageUrl: fileName });
      this.setState({
        uploadedList: list,
        updateBtnDisabled: false,
      });
    } else {
      console.log("Upload failed");
    }
  }
  addPicFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      uploadFiles(event.target.files, this.processUploadResponse);
    }
  }
  changeStatus(idx, type) {
    if (type === TYPE_ORIGINAL) {
      this.state.adsList[idx].disabled = !this.state.adsList[idx].disabled;
    } else {
      this.state.uploadedList[idx].disabled =
        !this.state.uploadedList[idx].disabled;
    }
    const state = {
      ...this.state,
      updateBtnDisabled: false,
    };
    this.setState(state);
  }
  preview(linkId) {
    document.getElementById(linkId).click();
  }
  showAdsList(list, type) {
    if (list && list.length > 0) {
      const baseUrl =
        type == TYPE_ORIGINAL ? prodImgBaseUrl() : previewImgBaseUrl();
      return (
        <React.Fragment>
          {list.map((homeImg, idx) => {
            const url = baseUrl + homeImg.imageUrl;
            const idStr = "T" + type + "_" + idx;
            const enLabel = homeImg.disabled ? (
              <font color="red">Enabled</font>
            ) : (
              <font color="green">Enabled</font>
            );
            const pDisabled =
              homeImg.linkUrl && homeImg.linkUrl.length > 2 ? false : true;
            const pBtnStyle = !pDisabled
              ? "blue-small-btn"
              : "disabled-blue-small-btn";
            const linkId = "link_" + type + "_" + idx;
            const linkUrl = getMainSiteUrl() + homeImg.linkUrl;
            const imgId = homeImg.disabled ? "disabledImage" : "normalImage";
            return (
              <div className="flex-wrapper" key={idx}>
                <div>
                  <img
                    id={imgId}
                    src={url}
                    width={this.state.imageWidth}
                    height={this.state.imageHeight}
                    alt="AdsImg"
                    onClick={() => this.showModalImage(homeImg.imageUrl)}
                  ></img>
                  &nbsp;&nbsp;
                </div>
                <div className="single-col">
                  <div>
                    <input
                      type="checkbox"
                      name="CB_disabled"
                      checked={!homeImg.disabled}
                      onChange={() => this.changeStatus(idx, type)}
                    />{" "}
                    {enLabel}
                  </div>
                  <div className="flex-wrapper">
                    <div>URL:&nbsp;</div>
                    <div>
                      <input
                        type="text"
                        onChange={this.handleChange}
                        name={idStr}
                        size="25"
                        value={homeImg.linkUrl || ""}
                        disabled={homeImg.disabled}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      name="preview"
                      className={pBtnStyle}
                      onClick={() => this.preview(linkId)}
                      disabled={pDisabled}
                    >
                      Preview
                    </button>
                    <a href={linkUrl} id={linkId} target="__Preview" />
                  </div>
                  <div>
                    <button
                      className="red-small-btn"
                      name="delImage"
                      onClick={() => this.deleteAds(idx, type)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      );
    }
  }
  showModalImage(imgUrl) {
    this.setState({ modalImageUrl: imgUrl });
  }
  closeModalImage() {
    this.setState({ modalImageUrl: null });
  }
  showAdvertisements() {
    const alt = this.state.mobileMode
      ? "Double tap to see larger picture"
      : "Double click to see larger picture";

    return (
      <div>
        {this.showAdsList(this.state.adsList, TYPE_ORIGINAL)}
        {this.showAdsList(this.state.uploadedList, TYPE_UPLOADED)}
        {this.state.modalImageUrl && (
          <Lightbox
            large={fullProductImageUrl(this.state.modalImageUrl)}
            alt={alt}
            hideDownload={true}
            hideZoom={true}
            onClose={this.closeModalImage}
            imageBackgroundColor="white"
          />
        )}
      </div>
    );
  }
  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("T")) {
      name = name.substring(1);
      const pos = name.indexOf("_");
      const type = parseInt(name.substring(0, pos));
      const idx = parseInt(name.substring(pos + 1));

      if (type == TYPE_ORIGINAL) {
        this.state.adsList[idx].linkUrl = event.target.value;
      } else {
        this.state.uploadedList[idx].linkUrl = event.target.value;
      }
      const state = {
        ...this.state,
        updateBtnDisabled: false,
      };
      this.setState(state);
    }
  }

  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    const editAllowed = this.state.editAllowed;
    const btnClsName = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div>
            <font size="5">Advertisement</font>
            <p />
            Images below are used in the slider show on the home page to do
            advertisement.
            {editAllowed && (
              <React.Fragment>
                &nbsp;&nbsp;You can delete existiing images or upload new onces.{" "}
                When you upload an image, make sure you assign the correct URL
                to it so it goes to the right advertisement page when users
                click the image. You may also update the URLs, enable/disable
                the images.
                <p />
                For best result, the width/height ratio should be 1200/660.
              </React.Fragment>
            )}
          </div>

          <p />
          <button
            className={btnClsName}
            name="update"
            disabled={this.state.updateBtnDisabled}
            onClick={this.updateAdvertisement}
          >
            Update Advertisement
          </button>
          <p />
          {this.showAdvertisements()}
          <p />
          {editAllowed && (
            <div>
              To add a new advertisement: first click the button below to add a
              new image, you will then be able to customize the URL:
              <p />
              <b>Add Image: </b>
              <input
                type="file"
                id="addPicFile"
                onChange={this.addPicFile}
              ></input>
              <p />
              <font color="red">{this.state.errorMessage}</font>
            </div>
          )}
          <p />
          <div>&nbsp;</div>
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAdvertisement);
