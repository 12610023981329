import React from "react";

import { fullProductImageUrl, getItemCount, toAmountEx } from "../Util";

import "../../App.css";
import "../../generic.css";
import "../../coi.css";

class CommonOrderDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commonOrderInfo: this.props.commonOrderInfo,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.commonOrderInfo != prevProps.commonOrderInfo) {
      this.setState({
        commonOrderInfo: this.props.commonOrderInfo,
      });
    }
  }
  showPayments(coi) {
    if (!coi.layaway || coi.order.storeId == 0) {
      if (coi.paymentList && coi.paymentList.length > 0) {
        return true;
      }
    }
    return false;
  }
  getLeaseApplFee() {
    const coi = this.state.commonOrderInfo;
    if (!coi.layaway && coi.order.leaseToOwn && coi.order.leaseId > 0) {
      return coi.order.leaseApplFee;
    }
    return 0;
  }
  progressiveInfo(colspan) {
    const coi = this.state.commonOrderInfo;
    if (!coi.layaway && coi.order.leaseId > 0) {
      const info = coi.leaseInfo;
      if (info) {
        const ps = coi.progressiveStatus;
        const status = ps && ps.funded ? "Funded" : "Pending";
        const color = ps && ps.funded ? "red" : "orange";
        return (
          <tr>
            <td colSpan={colspan} align="right" style={{ border: "none" }}>
              Progressive Leasing:
              <br />
              Lease ID: {info.leaseId}
            </td>
            <td align="right" style={{ border: "none" }}>
              {status}
              <br />
              <font color={color}>-{toAmountEx(info.cashPrice)}</font>
            </td>
          </tr>
        );
      }
    }
  }
  getProgressivePayment() {
    const coi = this.state.commonOrderInfo;
    if (!coi.layaway && coi.order.leaseToOwn && coi.order.leaseId > 0) {
      const info = coi.leaseInfo;
      if (info) {
        return info.cashPrice;
      }
    }
    return 0;
  }
  orderDetail() {
    const coi = this.state.commonOrderInfo;
    const order = coi.order;
    const orderItems = coi.orderItemList;
    const storeItems = coi.storeItemList;
    const storeCoupons = coi.storeCouponList;
    const payments = coi.paymentList;
    let balance = null;
    let paidInFull = false;
    const showPmts = this.showPayments(coi);
    const applFee = this.getLeaseApplFee();

    if (showPmts) {
      //For layaway, the payments are included in storeItems
      balance = order.totalPrice;
      for (let i = 0; i < payments.length; i++) {
        balance -= payments[i].amount;
        if (balance < 1.0) paidInFull = true;
      }
    }
    if (storeItems != null) {
      for (let i = 0; i < storeItems.length; i++) {
        //typeId=9 is Online payment which already included in the payments
        if (storeItems[i].typeId > 0 && storeItems[i].typeId != 9) {
          if (!balance) balance = order.totalPrice;
          balance -= storeItems[i].price;
          if (balance < 1.0) paidInFull = true;
        }
      }
    }
    const pp = this.getProgressivePayment();
    if (pp > 0) {
      if (!balance) balance = order.totalPrice;
      balance -= this.getProgressivePayment();
    }
    console.log("++++ Order ++++");
    console.log(coi);
    const clsName = "coi-table";
    const mobileMode = this.props.mobileMode;
    const colspan = mobileMode ? 3 : 4;
    const hdr = (
      <tr>
        <th>Product</th>
        {!mobileMode && <th>Description</th>}
        <th>Qty</th>
        <th>Unit Price</th>
        <th>Item Total</th>
      </tr>
    );
    return (
      <table className={clsName} cellSpacing="0" cellPadding="0">
        <tbody>
          {hdr}
          {orderItems &&
            orderItems.map((item, idx) => {
              const url = "/product/" + item.productId;
              const imgUrl = fullProductImageUrl(item.imageUrl);
              const total = item.item.unitPrice * item.item.quantity;
              return (
                <tr key={idx}>
                  <td>
                    <a href={url}>
                      <img
                        src={imgUrl}
                        alt={item.item.sku}
                        width="45"
                        height="30"
                      />
                    </a>
                    {item.item.sku}
                  </td>
                  {!mobileMode && <td>{item.productName}</td>}
                  <td align="center">{item.item.quantity}</td>
                  <td align="right">{toAmountEx(item.item.unitPrice)}</td>
                  <td align="right">{toAmountEx(total)}</td>
                </tr>
              );
            })}
          {storeItems &&
            storeItems.map((item, idx) => {
              if (item.typeId <= 0) {
                const imgUrl = fullProductImageUrl(item.imageUrl);
                const total = item.price * item.quantity;
                let name = item.name;

                if (name.startsWith("TYPED_")) name = name.substring(6);
                return (
                  <tr key={idx}>
                    <td>
                      <img
                        src={imgUrl}
                        alt={item.name}
                        width="45"
                        height="30"
                      />
                      {name}
                    </td>
                    {!mobileMode && <td>Store Item</td>}
                    <td align="center">{item.quantity}</td>
                    <td align="right">{toAmountEx(item.price)}</td>
                    <td align="right">{toAmountEx(total)}</td>
                  </tr>
                );
              }
            })}
          {order.discountName && order.discount > 0 && (
            <tr>
              <td align="center">Discount</td>
              {!mobileMode && <td>{order.discountName}</td>}
              <td>1</td>
              <td align="right">
                <font color="red">{toAmountEx(0 - order.discount)}</font>
              </td>
              <td align="right">
                <font color="red">{toAmountEx(0 - order.discount)}</font>
              </td>
            </tr>
          )}
          {order.promoDiscount > 0 &&
            !order.promoAfterTax &&
            !order.promoDeliveryFee && (
              <tr>
                <td align="center">Promotion</td>
                {!mobileMode && <td>{order.promoName}</td>}
                <td>1</td>
                <td align="right">
                  <font color="red">{toAmountEx(0 - order.promoDiscount)}</font>
                </td>
                <td align="right">
                  <font color="red">{toAmountEx(0 - order.promoDiscount)}</font>
                </td>
              </tr>
            )}
          <tr>
            <td colSpan={colspan} align="right" style={{ border: "none" }}>
              Subtotal:
            </td>
            <td align="right" style={{ border: "none" }}>
              {toAmountEx(order.subtotal)}
            </td>
          </tr>
          <tr>
            <td colSpan={colspan} align="right" style={{ border: "none" }}>
              Tax:
            </td>
            <td align="right" style={{ border: "none" }}>
              {toAmountEx(order.tax)}
            </td>
          </tr>
          {order.delivery && order.deliveryFee > 0 && (
            <tr>
              <td colSpan={colspan} align="right" style={{ border: "none" }}>
                Delivery Fee:
              </td>
              <td align="right" style={{ border: "none" }}>
                {toAmountEx(order.deliveryFee)}
              </td>
            </tr>
          )}
          {applFee > 0 && (
            <tr>
              <td colSpan={colspan} align="right" style={{ border: "none" }}>
                Application fee:
              </td>
              <td align="right" style={{ border: "none" }}>
                {toAmountEx(applFee)}
              </td>
            </tr>
          )}
          {order.promoDiscount > 0 &&
            order.promoAfterTax &&
            !order.promoDeliveryFee && (
              <tr>
                <td colSpan={colspan} align="right" style={{ border: "none" }}>
                  {order.promoName}:
                </td>
                <td align="right" style={{ border: "none" }}>
                  <font color="red">{toAmountEx(0 - order.promoDiscount)}</font>
                </td>
              </tr>
            )}
          {storeCoupons &&
            storeCoupons.length > 0 &&
            storeCoupons.map((coupon, idx) => {
              let name = storeCoupons[idx].name;
              if (name.startsWith("COUPON_")) name = name.substring(7);
              return (
                <tr key={idx}>
                  <td
                    colSpan={colspan}
                    align="right"
                    style={{ border: "none" }}
                  >
                    {name}:
                  </td>
                  <td align="right" style={{ border: "none" }}>
                    <font color="red">{toAmountEx(coupon.price)}</font>
                  </td>
                </tr>
              );
            })}
          <tr>
            <td colSpan={colspan} align="right" style={{ border: "none" }}>
              <b>Order Total</b>:
            </td>
            <td align="right" style={{ border: "none" }}>
              <b>{toAmountEx(order.totalPrice)}</b>
            </td>
          </tr>
          {this.progressiveInfo(colspan)}
          {showPmts &&
            payments.map((pmt, idx) => {
              let name = pmt.paymentName;
              if (name && !name.endsWith("Payment") && !name.endsWith("Paid")) {
                name += " Paid";
              }
              if (pmt.dateStr) name += " (" + pmt.dateStr + ")";

              let num = pmt.number;
              if (num && num.length > 4) {
                const extra = pmt.amount < 0 ? " Refund" : "";
                num = "**" + num.substring(num.length - 4);
                name = (
                  <span style={{ textDecoration: "none" }}>
                    {name + extra} (
                    <a href={"/paymentDetail/" + pmt.id}>{num}</a>)
                  </span>
                );
              }
              const color = pmt.amount > 0 ? "red" : "blue";
              return (
                <tr key={idx}>
                  <td
                    colSpan={colspan}
                    align="right"
                    style={{ border: "none" }}
                  >
                    {name}:
                  </td>
                  <td align="right" style={{ border: "none" }}>
                    <font color={color}>{toAmountEx(0 - pmt.amount)}</font>
                  </td>
                </tr>
              );
            })}
          {storeItems &&
            storeItems.map((item, idx) => {
              if (item.typeId > 0 && item.typeId != 9) {
                const amount = item.price > 0 ? item.price : 0 - item.price;
                const isReturn = item.price < 0;
                let name = item.name;

                if (name.startsWith("TYPED_")) name = name.substring(6);
                if (isReturn) name += " (Return)";
                if (item.item && item.item.dateStr) {
                  name += " (" + item.item.dateStr + ")";
                }
                return (
                  <tr key={idx}>
                    <td
                      colSpan={colspan}
                      align="right"
                      style={{ border: "none" }}
                    >
                      {name}
                    </td>
                    <td align="right" style={{ border: "none" }}>
                      <font color="red">{toAmountEx(0 - amount)}</font>
                    </td>
                  </tr>
                );
              }
            })}
          {paidInFull && (
            <tr>
              <td
                colSpan={colspan + 1}
                align="right"
                style={{ border: "none" }}
              >
                <i>
                  <font color="green">Paid in full</font>
                </i>
              </td>
            </tr>
          )}
          {balance != null && !paidInFull && (
            <tr>
              <td colSpan={colspan} align="right" style={{ border: "none" }}>
                <b>
                  <i>Balance</i>
                </b>
                :
              </td>
              <td align="right" style={{ border: "none" }}>
                <b>{toAmountEx(balance)}</b>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    if (getItemCount(this.state.commonOrderInfo) > 0) {
      return this.orderDetail();
    }
    return "";
  }
}

export default CommonOrderDetail;
