import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, isUserLoggedIn, centerAroundWarehouse } from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView } from "../SecManager";
import OrderInfoDialog from "./OrderInfoDialog";
import MyGoogleMap from "./MyGoogleMap";

import "../../orderStats.css";

class DeliveryMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: { lat: 33.755112, lng: -84.547043 },
      warehouse: { lat: 33.755112, lng: -84.547043 },
      deliveryList: null,
      theInfo: null,
      isLoading: true,
      errorMessage: null,
      viewAllowed: checkView("Order"),
    };
    this.getDeliveryLocations = this.getDeliveryLocations.bind(this);
    this.showMap = this.showMap.bind(this);
    this.showDeliveryDetail = this.showDeliveryDetail.bind(this);
  }

  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    this.getDeliveryLocations();
  }
  getDeliveryLocations() {
    const url = apiBaseUrl() + "GetDeliveryLocations";
    const value = this.props.match.params.date
      ? this.props.match.params.date
      : null;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data) {
          const deliveryList = res.data.deliveryList;
          const theInfo =
            deliveryList && deliveryList.length > 0 ? deliveryList[0] : null;
          const center = centerAroundWarehouse()
            ? { lat: res.data.warehouseLat, lng: res.data.warehouseLng }
            : { lat: res.data.centerLat, lng: res.data.centerLng };
          const warehouse = {
            lat: res.data.warehouseLat,
            lng: res.data.warehouseLng,
          };
          this.setState({
            deliveryList,
            center,
            warehouse,
            theInfo,
            errorMessage: null,
          });
        } else {
          this.setState({ errorMessage: "Failed to get location info" });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, errorMessage: "Error!" });
        console.log(error);
      });
  }
  showDeliveryDetail(orderInfo) {
    const btnId = "hiddenDialogBtn_";
    this.setState({ theInfo: orderInfo });
    document.getElementById(btnId).click();
  }
  showMap() {
    return (
      <React.Fragment>
        <MyGoogleMap
          center={this.state.center}
          warehouse={this.state.warehouse}
          deliveryList={this.state.deliveryList}
          markerClickedCallback={this.showDeliveryDetail}
        />
        <OrderInfoDialog
          btnId="hiddenDialogBtn_"
          deliveryInfo={this.state.theInfo}
        />
        ;
      </React.Fragment>
    );
  }
  render() {
    if (this.state.isLoading && !this.state.deliveryList) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            Please wait ....
            <p />
          </div>
        </div>
      );
    } else {
      if (this.state.deliveryList && this.state.deliveryList.length > 0) {
        return this.showMap();
      }
    }
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">An error has occurred</font>
          <p />
          {this.state.errorMessage}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryMap);
