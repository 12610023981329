import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { searchOrders } from "../actions/searchActions";
import { setTopContainerWrapperSettings, isMobileMode } from "../Util";

import "../../search.css";

class OrderSearchWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: null,
      mostMatches: false,
      category: this.props.searchCategory ? this.props.searchCategory : "Phone",
      mobileMode: false,
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.searchOrders = this.searchOrders.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }

  searchOrders(event) {
    if (event.key === "Enter") {
      if (this.props.callback) {
        const query = event.target.value;
        event.target.value = "";
        this.props.callback(query, this.state.category, this.state.mostMatches);
      } else {
        const payload = {
          query: event.target.value,
          mostMatches: this.state.mostMatches,
          searchType: this.props.searchType,
          category: this.state.category,
        };
        this.props.searchOrders(payload);
        event.target.value = "";
        this.props.history.push("/searchOrders");
      }
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  render() {
    const cat = this.state.category ? this.state.category : "Phone";
    const size = this.props.size ? this.props.size : 30;
    let placeholder = "Phone #";
    if (cat === "OrderNumber") placeholder = "Order #";
    else if (cat === "CreditCard") placeholder = "Credit Card";
    else if (cat === "All") {
      placeholder = "Search orders";
      if (this.props.searchType == 2) placeholder = "Search layaways";
      else if (this.props.searchType == 3)
        placeholder = "Search orders & layaways";
    }

    return (
      <div className="order-search-wrapper">
        <div className="order-search-box">
          <div>
            <input
              type="search"
              size={size}
              name="query"
              placeholder={placeholder}
              onKeyDown={this.searchOrders}
            />
          </div>
          <div className="generic-flex">
            <div>
              <input
                type="radio"
                name="category"
                defaultChecked={cat === "Phone"}
                value="Phone"
                onChange={this.handleChange}
              ></input>
              Phone&nbsp;
            </div>
            <div>
              <input
                type="radio"
                name="category"
                defaultChecked={cat === "OrderNumber"}
                value="OrderNumber"
                onChange={this.handleChange}
              ></input>
              Order&nbsp;
            </div>
            <div>
              <input
                type="radio"
                name="category"
                defaultChecked={cat === "CreditCard"}
                value="CreditCard"
                onChange={this.handleChange}
              ></input>
              CC&nbsp;
            </div>
            <div>
              <input
                type="radio"
                name="category"
                defaultChecked={cat === "All"}
                value="All"
                onChange={this.handleChange}
              ></input>
              All
            </div>
          </div>
        </div>
        {!this.state.mobileMode && (
          <div>
            (Name, phone number, order number, email, last 4 of credit card,
            etc.)
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userState: state.user,
    searchCategory: state.search.searchCategory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchOrders: (payload) => {
      dispatch(searchOrders(payload));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderSearchWidget));
