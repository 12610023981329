import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditHPSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      setting: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetHPSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
          return;
        }
        this.setState({ setting: res.data.data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;

    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
    }
    const setting = {
      ...this.state.setting,
      [name]: val,
    };
    this.setState({
      setting,
      updateBtnDisabled: false,
    });
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateHPSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.setting,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        const response = res.data;
        if (response.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
          return;
        }
        if (response.status) {
          this.props.history.push("/");
        } else {
          this.setState({ errorMessage: response.errorMessage });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  theForm() {
    const setting = this.state.setting;
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";

    return (
      <div className="generic-wrapper">
        <font size="5">Home Page Settings</font>
        <p />
        <div>
          <div>
            <input
              type="checkbox"
              name="CB_showNewInternetOrdersOnly"
              defaultChecked={setting.showNewInternetOrdersOnly}
              disabled={!editAllowed}
              onChange={this.handleChange}
            />{" "}
            Show Internet Orders Only
          </div>

          <div>
            <input
              type="checkbox"
              name="CB_showNewPayments"
              defaultChecked={setting.showNewPayments}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Show New Payments
          </div>

          <div>
            <input
              type="checkbox"
              name="CB_showNewProducts"
              defaultChecked={setting.showNewProducts}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Show New Products
          </div>

          <div>
            <input
              type="checkbox"
              name="CB_showNewNotifications"
              defaultChecked={setting.showNewNotifications}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Show Notifications
          </div>

          <div>
            <input
              type="checkbox"
              name="CB_showNewEvents"
              defaultChecked={setting.showNewEvents}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Show New Events
          </div>
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditHPSetting);
