import React from "react";
import { toDateStr } from "../Util";
import "../../generic.css";

class AddPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: this.props.balance,
      addPmtBtnDisabled: this.props.balance ? false : true,
      paymentMethod: 1,
      amount: this.props.balance,
      nameOnCard: null,
      last4: null,
      approvalCode: null,
      note: null,
      paymentDate: null,
      minDate: null,
      maxDate: null,
      addPmtExpanded: false,
      exceedBalWarning: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.addPayment = this.addPayment.bind(this);
  }
  componentDidMount() {
    this.setInitState();
  }
  componentDidUpdate(prevProps) {
    if (this.props.balance != prevProps.balance) {
      this.setInitState();
    }
  }
  setInitState() {
    const today = toDateStr(new Date());
    let minDate = null;

    if (this.props.dateOrdered) {
      minDate = toDateStr(new Date(this.props.dateOrdered));
    }
    this.setState({
      balance: this.props.balance,
      amount: this.props.balance,
      addPmtBtnDisabled: this.props.balance ? false : true,
      paymentDate: today,
      minDate,
      maxDate: today,
    });
  }
  handleChange(event) {
    const name = event.target.name;

    if (name.startsWith("CB_")) {
      this.setState({ addPmtExpanded: event.target.checked });
      return;
    }

    let val = event.target.value;
    let exceedBalWarning = this.state.exceedBalWarning;
    let addPmtBtnDisabled = this.state.addPmtBtnDisabled;
    if (name === "amount") {
      if (!val || val.trim().length === 0) {
        this.setState({ amount: null, addPmtBtnDisabled: true });
        return;
      }

      let amount = parseFloat(val);
      if (isNaN(amount)) {
        return;
      }
      addPmtBtnDisabled = false;
      if (amount > this.state.balance) {
        exceedBalWarning = "Exceed balance";
      } else {
        exceedBalWarning = null;
      }
    } else if (name === "paymentMethod") {
      val = parseInt(val);
    }
    this.setState({ [name]: val, exceedBalWarning, addPmtBtnDisabled });
  }
  addPayment(event) {
    this.setInitState();
    this.setState({ addPmtExpanded: false });

    const payment = {
      amount: this.state.amount,
      paymentDate: this.state.paymentDate,
      typeId: this.state.paymentMethod,
      nameOnCard: this.state.nameOnCard,
      last4: this.state.last4,
      approvalCode: this.state.approvalCode,
      note: this.state.note,
    };
    this.props.callback(payment);
  }
  addPaymentHeader() {
    return (
      <div className="flex-wrapper">
        <div>
          <input
            type="checkbox"
            name="CB_addPmtExpanded"
            checked={this.state.addPmtExpanded}
            onChange={this.handleChange}
          ></input>{" "}
        </div>
        <div>
          <b>
            <font color="darkgreen">Add Payment</font>
          </b>
        </div>
        {this.state.addPmtExpanded && (
          <React.Fragment>
            <div className="left-10">
              <input
                type="radio"
                name="paymentMethod"
                value={1}
                checked={this.state.paymentMethod === 1}
                onChange={this.handleChange}
              ></input>{" "}
              Cash/Check
            </div>
            <div className="left-10">
              <input
                type="radio"
                name="paymentMethod"
                value={2}
                checked={this.state.paymentMethod === 2}
                onChange={this.handleChange}
              ></input>
              Debit/Credit
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
  addPaymentSection() {
    if (this.state.addPmtExpanded) {
      const addBtnStyle = this.state.addPmtBtnDisabled
        ? "disabled-green-mbtn"
        : "green-mbtn";
      return (
        <div className="left-20">
          <div className="twocol-form">
            <div>
              Amount<font color="red">*</font>:
            </div>
            <div className="flex-wrapper">
              <div>
                <input
                  type="text"
                  size="6"
                  name="amount"
                  inputMode="decimal"
                  value={this.state.amount || ""}
                  onChange={this.handleChange}
                ></input>
              </div>

              <div className="left-10">
                <input
                  type="date"
                  name="paymentDate"
                  value={this.state.paymentDate}
                  min={this.state.minDate}
                  max={this.state.maxDate}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                &nbsp;
                {<font color="orange">{this.state.exceedBalWarning}</font>}
              </div>
            </div>
            {this.state.paymentMethod === 2 && (
              <React.Fragment>
                <div>Name on Card:</div>
                <div>
                  <input
                    type="text"
                    size="20"
                    name="nameOnCard"
                    value={this.state.nameOnCard || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div>Last4:</div>
                <div>
                  <input
                    type="text"
                    size="6"
                    name="last4"
                    value={this.state.last4 || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div>Approval Code:</div>
                <div>
                  <input
                    type="text"
                    size="20"
                    name="approvalCode"
                    value={this.state.approvalCode || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </React.Fragment>
            )}
            <div>Note:</div>
            <div>
              <input
                type="text"
                name="note"
                value={this.state.note || ""}
                onChange={this.handleChange}
              ></input>
            </div>
          </div>
          <div className="top10b15">
            <button
              className={addBtnStyle}
              name="addPmt"
              onClick={this.addPayment}
              disabled={this.state.addPmtBtnDisabled}
            >
              Add Payment
            </button>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.addPaymentHeader()}
        {this.addPaymentSection()}
      </div>
    );
  }
}
export default AddPayment;
