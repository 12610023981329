import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toAmount,
  toCurrentDTStr,
  loginRequiredForPayment,
  isMobileMode,
  setTopContainerWrapperSettings,
  allowPmtStatusChange,
} from "../Util";
import LoginUser from "../user/LoginUser";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class PaymentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: null,
      statusList: [],
      isLoading: true,
      isLoadingOS: true,
      errorMessage: null,
      paymentStatus: "Pending",
      statusTime: "",
      updateBtnDisabled: true,
      rtnPmtBtnDisabled: true,
      returnMessage: null,
      mobileMode: false,
      userId: this.props.userId,
      password: null,
      note: null,
      editAllowed: checkUpdate("Payment"),
      viewAllowed: checkView("Payment"),
      viewPPAllowed: checkView("PaymentProcessor"),
      returnAllowed: checkSecurity("ReturnPayment"),
    };
    this.fetchPayment = this.fetchPayment.bind(this);
    this.fetchStatusList = this.fetchStatusList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.handleRPChange = this.handleRPChange.bind(this);
    this.returnPayment = this.returnPayment.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchPayment();
    this.fetchStatusList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  fetchPayment() {
    const url = apiBaseUrl() + "GetPayment";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          const obj = res.data.data;
          this.setState({
            payment: obj,
            paymentStatus: obj.info.status,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  fetchStatusList() {
    const url = apiBaseUrl() + "GetPaymentStatusList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ statusList: res.data.objList, isLoadingOS: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange(event) {
    if (event.target.name === "statusTime") {
      this.setState({ statusTime: event.target.value });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        updateBtnDisabled: false,
        statusTime: toCurrentDTStr(),
      });
    }
  }
  updateStatus() {
    const url = apiBaseUrl() + "UpdatePaymentStatus";
    const found = this.state.statusList.find(
      (status) => status.status === this.state.paymentStatus
    );
    const statusId = found ? found.id : 0;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objectId: this.state.payment.info.id,
      statusId: statusId,
      statusTime: this.state.statusTime,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            window.location.reload();
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              returnMessage: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showPaymentStatus() {
    if (this.state.isLoadingOS || !this.state.statusList) return "";

    if (!allowPmtStatusChange()) {
      const url = "/paymentDetail/" + this.state.payment.returnPaymentId;
      return (
        <div className="flex-wrapper">
          <div>Payment Status: </div>
          <div className="left-10">
            {this.state.payment.returnPaymentId > 0 ? (
              <a href={url}>
                <b>Returned</b>
              </a>
            ) : (
              this.state.paymentStatus
            )}
          </div>
        </div>
      );
    } else {
      if (this.state.editAllowed) {
        const btnStyle = this.state.updateBtnDisabled
          ? "disabled-btn-style"
          : "btn-style";
        const url = "/paymentDetail/" + this.state.payment.returnPaymentId;
        return (
          <div className="flex-wrapper">
            <div>Payment Status: </div>
            <div className="left-10">
              {this.state.payment.returnPaymentId > 0 ? (
                <a href={url}>
                  <b>Returned</b>
                </a>
              ) : (
                <select
                  name="paymentStatus"
                  value={this.state.paymentStatus}
                  onChange={this.handleChange}
                >
                  {this.state.statusList.map((status) => {
                    return (
                      <option value={status.status} key={status.status}>
                        {status.status}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            {this.state.updateBtnDisabled === false && (
              <div className="left-10">
                <input
                  name="statusTime"
                  value={this.state.statusTime}
                  onChange={this.handleChange}
                ></input>
              </div>
            )}
            {!this.state.payment.returnPaymentId > 0 && (
              <div className="left-10">
                <button
                  name="updateStatus"
                  className={btnStyle}
                  onClick={this.updateStatus}
                  disabled={this.state.updateBtnDisabled}
                >
                  Update Status
                </button>
              </div>
            )}
          </div>
        );
      } else {
        return <div>Payment Status: {this.state.paymentStatus}</div>;
      }
    }
  }

  showPaymentInfo() {
    if (!this.state.payment) return "";

    const pmt = this.state.payment.info;
    const timestamp = this.state.mobileMode ? pmt.mobileDateStr : pmt.dateStr;
    const url = this.state.payment.layawayPayment
      ? "/layawayDetail/" + pmt.orderNumber
      : "/orderDetail/" + pmt.orderNumber;
    return (
      <div>
        <p />
        <b>Payment Information:</b>
        <br />
        <div className="form-wrapper">
          <div className="form-item-label">Payment Type: </div>
          <div className="form-item-value">{pmt.paymentName}</div>
          <div className="form-item-label">Payment Submitted: </div>
          <div className="form-item-value">{timestamp}</div>
          <div className="form-item-label">Order Number: </div>
          <div className="form-item-value">
            <Link to={url}>{pmt.orderNumber}</Link>
          </div>
          <div className="form-item-label">Payment Amount: </div>
          <div className="form-item-value">{toAmount(pmt.amount)}</div>
          <div className="form-item-label">Name on Card: </div>
          <div className="form-item-value">{pmt.name}</div>
          <div className="form-item-label">Card Number: </div>
          <div className="form-item-value">{pmt.number}</div>
          {pmt.automatic && (
            <React.Fragment>
              <div className="form-item-label">Payment Order ID: </div>
              <div className="form-item-value">{pmt.paymentOrderId}</div>
              <div className="form-item-label">Transaction Type: </div>
              <div className="form-item-value">{pmt.transactionType}</div>
              <div className="form-item-label">Approval Code: </div>
              <div className="form-item-value">{pmt.approvalCode}</div>
              <div className="form-item-label">Authorized Amount: </div>
              <div className="form-item-value">
                {toAmount(pmt.authorizedAmount)}
              </div>
              <div className="form-item-label">Reference Code: </div>
              <div className="form-item-value">{pmt.transRefCode}</div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
  showPaymentAddress() {
    if (this.state.payment && this.state.payment.address) {
      const addr = this.state.payment.address;
      let html = "<b>Payment Address:</b><br/>";
      html += addr.firstName + " " + addr.lastName + "<br/>";
      html += addr.addressLine1 + "<br/>";
      if (addr.addressLine2) html += addr.addressLine2 + "<br/>";
      html += addr.city + ", " + addr.state + " " + addr.zipCode + "<br/>";
      if (addr.phone) html += "Phone: " + addr.phone + "<br/>";
      if (addr.mobilePhone) html += "Phone: " + addr.mobilePhone + "<br/>";
      if (addr.email) html += "Email: " + addr.email + "<br/>";

      return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }
  }
  handleRPChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      rtnPmtBtnDisabled: false,
    });
  }
  returnPayment(event) {
    event.preventDefault();

    this.setState({ rtnPmtBtnDisabled: true });
    const url = apiBaseUrl() + "ReturnPayment";
    const req = {
      userId: this.state.userId,
      password: this.state.password,
      note: this.state.note,
      paymentId: this.state.payment.info.id,
      orderNumber: this.state.payment.info.orderNumber,
      layawayPayment: this.state.payment.layawayPayment,
      amount: this.state.amount,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            const obj = res.data.data;
            this.setState({
              payment: obj,
              paymentStatus: obj.info.status,
              isLoading: false,
              returnMessage: "Payment successfully returned.",
              errorMessage: null,
            });
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              returnMessage: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showReturnPayment() {
    const pmt = this.state.payment;
    if (
      this.state.returnAllowed &&
      pmt &&
      pmt.orderStatus === "Cancelled" &&
      pmt.returnPaymentId <= 0 &&
      pmt.info.amount > 0
    ) {
      const btnStyle = this.state.rtnPmtBtnDisabled
        ? "disabled-btn-style"
        : "btn-style";
      const btnTitle = pmt.layawayPayment ? "Refund" : "Return Payment";
      return (
        <div>
          <ValidatorForm ref="form" onSubmit={this.returnPayment}>
            <div className="form-wrapper">
              <div className="form-item-label">User ID:</div>
              <div className="form-item-value">
                <TextInput
                  onChange={this.handleRPChange}
                  name="userId"
                  size="20"
                  value={this.state.userId}
                  disabled={true}
                  validators={["required"]}
                  errorMessages={["User ID is required"]}
                />
              </div>
              <div className="form-item-label">Password:</div>
              <div className="form-item-value">
                <TextInput
                  onChange={this.handleRPChange}
                  type="password"
                  name="password"
                  size="20"
                  value={this.state.password}
                  validators={["required"]}
                  errorMessages={["Password is required"]}
                />
              </div>
              {pmt.layawayPayment && (
                <React.Fragment>
                  <div className="form-item-label">Amount:</div>
                  <div className="form-item-value">
                    <input
                      type="text"
                      onChange={this.handleRPChange}
                      name="amount"
                      size="8"
                      value={this.state.amount}
                    />
                  </div>
                </React.Fragment>
              )}
              <div className="form-item-label">Reason:</div>
              <div className="form-item-value">
                <input
                  type="text"
                  onChange={this.handleRPChange}
                  name="note"
                  size="30"
                  value={this.state.note}
                  placeholder="Reason for refund"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className={btnStyle}
                disabled={this.state.rtnPmtBtnDisabled}
              >
                {btnTitle}
              </button>
            </div>
          </ValidatorForm>
        </div>
      );
    }
  }

  showPaymentProcInfo() {
    if (this.state.viewPPAllowed) {
      if (this.state.payment && this.state.payment.info.response) {
        const info = this.state.payment.info;
        const answer = info.approved ? "Yes" : "No";
        const colCnt = this.state.mobileMode ? 55 : 90;
        const rowCnt = this.state.mobileMode ? 15 : 10;
        return (
          <div>
            <p />
            <b>Payment Processing Info:</b>
            <br />
            Approved: {answer} <br />
            {info.approved === true && (
              <React.Fragment>
                Approval Code: {info.approvalCode} <br />
                Authorized Amount: ${info.authorizedAmount}
                <br />
              </React.Fragment>
            )}
            Response:
            <br />
            <textarea
              rows={rowCnt}
              cols={colCnt}
              disabled={true}
              value={info.response}
            ></textarea>
          </div>
        );
      }
    }
  }
  needLogin() {
    if (loginRequiredForPayment()) {
      const timeElapsed =
        (new Date().getTime() - parseInt(this.props.userState.loginTime)) /
        1000;

      if (timeElapsed > 300) {
        return true;
      }
    }
    return false;
  }

  render() {
    if (this.needLogin()) {
      const returnUrl = "/paymentDetail/" + this.props.match.params.id;
      return <LoginUser returnUrl={returnUrl} />;
    }

    if (this.state.isLoading) return <Working size={80} />;
    if (!this.state.payment) {
      return (
        <div>
          <font color="red">Payment not found</font>
        </div>
      );
    }

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div>
            <font size="5">Payment Detail</font>
            <p />
          </div>
          {this.state.errorMessage && (
            <React.Fragment>
              <font color="red">{this.state.errorMessage}</font>
              <p />
            </React.Fragment>
          )}
          {this.state.returnMessage && (
            <React.Fragment>
              <font color="green">
                <b>{this.state.returnMessage}</b>
              </font>
              <p />
            </React.Fragment>
          )}
          {this.showPaymentStatus()}
          {this.showPaymentInfo()}
          {this.showReturnPayment()}
          {this.showPaymentAddress()}
          {this.showPaymentProcInfo()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);
