import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import ActionConfirmation from "../ActionConfirmation";
import {
  getIconBaseUrl,
  fullProductImageUrl,
  getMainSiteUrl,
  apiBaseUrl,
} from "../Util";
import "../../App.css";
import "../../waitlistStats.css";

class ImageMapItemWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapItem: this.props.mapItem,
      mobileMode: this.props.mobileMode,
      editAllowed: this.props.editAllowed,
      pvInfoList: this.props.pvInfoList,
      btnTitle: this.props.mapItem.isAdd ? "Add" : "Update",
      updBtnDisabled: true,
      delBtnId: "delItemBtnId_" + Math.random(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.addUpdateMapItem = this.addUpdateMapItem.bind(this);
    this.deleteMapItem = this.deleteMapItem.bind(this);
    this.processDeleteResponse = this.processDeleteResponse.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.mapItem.mapID !== prevProps.mapItem.mapID ||
      this.props.mapItem.id !== prevProps.mapItem.id
    ) {
      this.setState({
        mapItem: this.props.mapItem,
        mobileMode: this.props.mobileMode,
        editAllowed: this.props.editAllowed,
        pvInfoList: this.props.pvInfoList,
        btnTitle: this.props.mapItem.isAdd ? "Add" : "Update",
        updBtnDisabled: true,
      });
    }
  }
  handleAddUpdate(event) {
    const isUpdate = this.state.mapItem.isAdd ? false : true;
    this.props.updateCallback(
      this.state.mapItem,
      this.props.passBack,
      isUpdate
    );
  }
  addUpdateMapItem(event) {
    const mapItem = this.state.mapItem;
    const url = mapItem.isAdd
      ? apiBaseUrl() + "AddMapItem"
      : apiBaseUrl() + "UpdateMapItem";

    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: mapItem,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        const dialogTitle = this.state.mapItem.isAdd
          ? "Add " + error.name
          : "Update " + error.name;
        const dialogMessage = this.state.mapItem.isAdd
          ? "Add failed :" + error.message
          : "Update failed: " + error.message;

        this.props.errorCallback(dialogTitle, dialogMessage);
        console.log(error);
      });
  }
  deleteMapItem(event) {
    const url = apiBaseUrl() + "DeleteMapItem";

    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.mapItem,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processDeleteResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to delete image maps item",
        });
        console.log(error);
      });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.data) {
        this.setState({ mapItem: response.data });
        this.props.updateCallback(response.data, this.props.passBack);
      } else {
        const dialogTitle = this.state.mapItem.isAdd
          ? "Add Map Item Failed"
          : "Update Map Item Failed";
        const dialogMessage = response.errorMessage;

        this.props.errorCallback(dialogTitle, dialogMessage);
      }
    }
  }
  processDeleteResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        this.setState({ mapItem: {} });
        this.props.deleteCallback(this.state.mapItem, this.props.passBack);
      }
    }
  }
  handleChange(event) {
    const name = event.target.name;

    let mapItem = {
      ...this.state.mapItem,
      [name]: event.target.value,
    };
    if (
      (name == "productSKU" || name == "productSKU2") &&
      this.state.pvInfoList
    ) {
      let pv = this.state.pvInfoList.find(
        (item) => item.sku == event.target.value
      );
      if (pv) {
        if (name == "productSKU") {
          mapItem = {
            ...mapItem,
            productURL: pv.productUrl,
            adPrice: pv.price,
            pvImageUrl: pv.imageUrl,
            skuChanged: true,
          };
        } else {
          mapItem = {
            ...mapItem,
            productURL2: pv.productUrl,
            adPrice2: pv.price,
            pvImageUrl2: pv.imageUrl,
            sku2Changed: true,
          };
        }
      }
    }
    this.setState({ mapItem, updBtnDisabled: false });
  }

  confirmDelete() {
    document.getElementById(this.state.delBtnId).click();
  }
  getNewSkuAnchor(item, isSku2) {
    let base = getMainSiteUrl();

    if (base.endsWith("/")) base = base.substring(0, base.length - 1);

    const img = isSku2
      ? fullProductImageUrl(item.pvImageUrl2)
      : fullProductImageUrl(item.pvImageUrl);
    const url = isSku2 ? base + item.productURL2 : base + item.productURL;

    return (
      <a href={url} target="MainSite">
        <img src={img} height="80" />
      </a>
    );
  }
  theForm() {
    const item = this.state.mapItem;

    return (
      <div>
        <ValidatorForm ref="form" onSubmit={this.addUpdateMapItem}>
          <div className="form-wrapper">
            <div className="form-item-label">
              Upper Left<font color="red">*</font>:
            </div>
            <div className="generic-flex">
              <TextInput
                onChange={this.handleChange}
                name="upperLeftX"
                size="4"
                value={item.upperLeftX || 0}
                disabled={!this.state.editAllowed}
                validators={["required"]}
                errorMessages={["Upper left X coordinator is required"]}
              />
              x &nbsp;&nbsp;&nbsp;
              <TextInput
                onChange={this.handleChange}
                name="upperLeftY"
                size="4"
                value={item.upperLeftY || 0}
                disabled={!this.state.editAllowed}
                validators={["required"]}
                errorMessages={["Upper left Y coordinator is required"]}
              />
              y
            </div>

            <div className="form-item-label">
              Lower Right<font color="red">*</font>:
            </div>
            <div className="generic-flex">
              <TextInput
                onChange={this.handleChange}
                name="lowerRightX"
                size="4"
                value={item.lowerRightX || 0}
                disabled={!this.state.editAllowed}
                validators={["required"]}
                errorMessages={["Lower right X coordinator is required"]}
              />
              x &nbsp;&nbsp;&nbsp;
              <TextInput
                onChange={this.handleChange}
                name="lowerRightY"
                size="4"
                value={item.lowerRightY || 0}
                disabled={!this.state.editAllowed}
                validators={["required"]}
                errorMessages={["Lower right Y coordinator is required"]}
              />
              y
            </div>
            <div className="form-item-label">SKU:</div>
            <div className="form-item-value">
              {this.state.pvInfoList ? (
                <select
                  name="productSKU"
                  value={item.productSKU}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                >
                  <option value=""></option>
                  {this.state.pvInfoList.map((pv) => {
                    const cat = pv.category ? pv.category.substring(0, 1) : "";
                    return (
                      <option value={pv.sku} key={pv.sku}>
                        {cat}: {pv.sku} ${pv.price}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="productSKU"
                  size="30"
                  value={item.productSKU || ""}
                  disabled={!this.state.editAllowed}
                />
              )}
            </div>
            {item.skuChanged && (
              <React.Fragment>
                <div className="form-item-label"></div>
                <div className="form-item-value">
                  {this.getNewSkuAnchor(item, false)}
                </div>
              </React.Fragment>
            )}
            <div className="form-item-label">
              URL<font color="red">*</font>:
            </div>
            <div className="form-item-value">
              <TextInput
                onChange={this.handleChange}
                name="productURL"
                size="45"
                value={item.productURL || ""}
                disabled={!this.state.editAllowed}
                validators={["required"]}
                errorMessages={["URL is required"]}
              />
            </div>
            <div className="form-item-label">Ad Price:</div>
            <div className="form-item-value">
              <input
                type="text"
                onChange={this.handleChange}
                name="adPrice"
                size="8"
                value={item.adPrice || 0}
                disabled={!this.state.editAllowed}
              />
            </div>
            <div className="form-item-label">SKU2:</div>
            <div className="form-item-value">
              {this.state.pvInfoList ? (
                <select
                  name="productSKU2"
                  value={item.productSKU2}
                  onChange={this.handleChange}
                  disabled={!this.state.editAllowed}
                >
                  <option value=""></option>
                  {this.state.pvInfoList.map((pv) => {
                    const cat = pv.category ? pv.category.substring(0, 1) : "";
                    return (
                      <option value={pv.sku} key={pv.sku}>
                        {cat}: {pv.sku} ${pv.price}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="productSKU2"
                  size="30"
                  value={item.productSKU2 || ""}
                  disabled={!this.state.editAllowed}
                />
              )}
            </div>
            {item.sku2Changed && (
              <React.Fragment>
                <div className="form-item-label"></div>
                <div className="form-item-value">
                  {this.getNewSkuAnchor(item, true)}
                </div>
              </React.Fragment>
            )}
            <div className="form-item-label">Ad Price2:</div>
            <div className="form-item-value">
              <input
                type="text"
                onChange={this.handleChange}
                name="adPrice2"
                size="8"
                value={item.adPrice2 || 0}
                disabled={!this.state.editAllowed}
              />
            </div>
          </div>
          {this.showUpdateButton()}
        </ValidatorForm>
        <ActionConfirmation
          title="Delete Confirmation"
          message={
            "Are you sure you want to delete this map item?  SKU: " +
            item.productSKU
          }
          buttonTitle="Delete"
          buttonClsName="red-btn-style"
          actionHandler={this.deleteMapItem}
          hidden={true}
          btnId={this.state.delBtnId}
        />
      </div>
    );
  }
  showUpdateButton() {
    if (this.state.editAllowed) {
      const btnStyle = this.state.updBtnDisabled
        ? "disabled-small-btn"
        : "small-btn";
      return (
        <div className="top-10">
          <div className="generic-flex">
            <button
              className={btnStyle}
              type="submit"
              disabled={!this.state.editAllowed || this.state.updBtnDisabled}
            >
              {this.state.btnTitle}
            </button>
            {!this.state.mapItem.isAdd && this.state.editAllowed && (
              <div className="left-20">
                <img
                  src={getIconBaseUrl() + "delete_button.jpg"}
                  height="26"
                  onClick={this.confirmDelete}
                />
              </div>
            )}
          </div>

          <p />
        </div>
      );
    }
  }
  render() {
    return this.theForm();
  }
}
export default ImageMapItemWidget;
