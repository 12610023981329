//User action types
export const SET_USERINFO = "SET_USERINFO";
export const SET_LOGGEDIN = "SET_LOGGEDIN";
export const SET_LOGGEDOUT = "SET_LOGGEDOUT";
export const SET_USERID = "SET_USERID";
export const SET_RESETMSG = "SET_RESETMSG";
export const SET_VERSION = "SET_VERSION";

//Search action types
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

export const SEARCH_ORDERS = "SEARCH_ORDERS";
export const SET_EXACT_ORDER_RESULT = "SET_EXACT_ORDER_RESULT";

export const REGISTER_WEBORDER = "REGISTER_WEBORDER";
