import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import { fullProductImageUrl, thumbnailImgWidth, apiBaseUrl } from "../Util";
import { setLoggedOut } from "../actions/userActions";
import "../../App.css";
import "../../generic.css";
import "../../prodByCat.css";

class InvisibleThumbnail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
    this.processResponse = this.processResponse.bind(this);
    this.markVisible = this.markVisible.bind(this);
  }

  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        window.location.reload();
      } else {
        this.setState({ errorMessage: response.errorMessage });
      }
    }
  }

  markVisible(event) {
    let objList = [];

    if (this.props.prod && this.props.prod.pvList) {
      const pvList = this.props.prod.pvList;
      for (let i = 0; i < pvList.length; i++) {
        objList.push(pvList[i].sku);
      }
    }
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList,
    };

    const url = apiBaseUrl() + "MarkVisible";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({ errorMessage: "Failed to mark visible" });
        console.log(error);
      });
  }
  render() {
    if (!this.props.prod) {
      return "";
    }

    let imgUrl = fullProductImageUrl(this.props.prod.imageUrl);

    if (this.props.prod.pvList && this.props.prod.pvList.length == 1) {
      const img = this.props.prod.pvList[0].imageUrl;
      if (img && img.length > 0) {
        imgUrl = fullProductImageUrl(img);
      }
    }
    return (
      <div>
        <div className="generic-flex">
          <div className="generic-item">
            <img src={imgUrl} width={thumbnailImgWidth()} alt="ProdImg"></img>
          </div>
          <div className="generic-item">
            <div>Product ID: {this.props.prod.id}</div>
            <div>{this.props.prod.name}</div>

            {this.props.prod.pvList.map((pv) => {
              const clsName = pv.forSale ? "greenCircle" : "redCircle";

              return (
                <div className="flex-wrapper" key={pv.sku}>
                  <div className={clsName}></div>
                  {pv.sku}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <button
            className="btn-style"
            name="MarkVisible"
            onClick={this.markVisible}
          >
            Mark Visible
          </button>
          <p />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvisibleThumbnail));
