import React from "react";
import {
  toAmount,
  setTopContainerWrapperSettings,
  isMobileMode,
} from "../Util";
import "../../App.css";
import "../../generic.css";
import "../../ordtbl.css";

class LeaseOrderTableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMode: false,
      orderList: this.props.orderList,
      sortOrder: 0,
      sortColumn: 2,
      orderFldsList: null,
      title: this.props.title,
      maxPerPage: this.props.maxPerPage ? this.props.maxPerPage : 500,
      showFilters: this.props.showFilters,
      pageIdx: 0,
      includeFunded: false,
      includeCancelled: false,
      includeId0: false, //leaseID=0
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.setFieldLists = this.setFieldLists.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pageNumClicked = this.pageNumClicked.bind(this);
  }
  componentDidMount() {
    if (this.props.orderList) {
      this.setFieldLists(this.props.orderList);
    }

    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }
  componentDidUpdate(prevProps) {
    if (this.props.orderList !== prevProps.orderList) {
      this.setState({
        orderList: this.props.orderList,
        showFilters: this.props.showFilters,
        title: this.props.title,
        orderFilter: this.props.showFilters ? 0 : 3,
      });

      if (this.props.orderList) {
        this.setFieldLists(this.props.orderList);
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  getName(order) {
    let name;
    if (this.state.mobileMode) {
      name = "";
      if (order.firstName && order.firstName.length > 0) {
        name += order.firstName.substring(0, 1);
      }
      if (order.lastName && order.lastName.length > 0) {
        name += order.lastName.substring(0, 1);
      }
    } else {
      name = order.firstName + " " + order.lastName;
      if (name.length > 12) name = name.substring(0, 12);
    }
    return name;
  }
  setFieldLists(orderList) {
    let orderFldsList = [];
    if (orderList.length > 0) {
      for (let i = 0; i < orderList.length; i++) {
        const order = orderList[i];
        let statusId = order.statusId;
        let deliveryDateStr = order.deliveryDateStr;
        if (order.delivery && !order.deliveryDateStr && order.statusId < 5) {
          statusId = -1;
          deliveryDateStr = "";
        }
        if (!order.delivery) deliveryDateStr = "ZZ";
        let name = order.firstName + " " + order.lastName;
        name = name.toLowerCase();

        const fields = [
          statusId,
          order.orderNumber,
          order.timestamp,
          deliveryDateStr,
          name ? name.toUpperCase() : name,
          order.leaseId,
          order.leaseStatus,
          order,
        ];
        orderFldsList.push(fields);
      }
    }
    this.setState({ orderFldsList });
  }
  pageNumClicked(idx) {
    this.setState({ pageIdx: idx });
  }
  handleClick(colIdx) {
    let fieldsList = this.state.orderFldsList;

    if (fieldsList && fieldsList.length > 1) {
      const sortOrder = this.state.sortOrder === 0 ? 1 : 0;
      let fieldsList = this.state.orderFldsList;
      if (fieldsList && fieldsList.length > 1) {
        fieldsList = fieldsList.sort((a, b) => {
          if (colIdx === 0 || colIdx === 2) {
            let val = a[colIdx] - b[colIdx];

            if (val === 0) {
              //sub-sort by dateOrdered
              let t1 = a[7].timestamp;
              let t2 = b[7].timestamp;
              return t1 - t2;
            }

            if (sortOrder === 1) val = 0 - val;
            return val;
          } else {
            let val = 0;
            if (a[colIdx] > b[colIdx]) val = 1;
            else if (a[colIdx] < b[colIdx]) val = -1;

            if (val === 0) {
              //sub-sort by dateOrdered
              let t1 = a[7].timestamp;
              let t2 = b[7].timestamp;
              return t1 - t2;
            }
            if (sortOrder === 1) val = 0 - val;
            return val;
          }
        });
      }

      this.setState({
        orderFldsList: fieldsList,
        sortOrder,
        sortColumn: colIdx,
      });
    }
  }
  tableHeader() {
    const desktop = this.state.mobileMode ? false : true;
    const nLabel = desktop ? "Name" : "NM";
    const sortCol = this.state.sortColumn;
    const sortSymbol =
      this.state.sortOrder == 1 ? (
        <font color="green">
          <b>&#8593;</b>
        </font>
      ) : (
        <font color="red">
          <b>&#8595;</b>
        </font>
      );
    const hd = "otv-hd";
    const hdl = "otv-hdlink";
    return (
      <React.Fragment>
        <div className={sortCol == 0 ? hdl : hd}>
          {sortCol == 0 && sortSymbol}
          <a href="#" onClick={() => this.handleClick(0)}>
            <b>S</b>
          </a>
        </div>
        <div className={sortCol == 1 ? hdl : hd}>
          {sortCol == 1 && sortSymbol}
          <a href="#" onClick={() => this.handleClick(1)}>
            Order#
          </a>
        </div>
        <div className={sortCol == 2 ? hdl : hd}>
          {sortCol == 2 && sortSymbol}
          <a href="#" onClick={() => this.handleClick(2)}>
            Date
          </a>
        </div>
        {desktop && (
          <div className={sortCol == 3 ? hdl : hd}>
            {sortCol == 3 && sortSymbol}
            <a href="#" onClick={() => this.handleClick(3)}>
              Delivery
            </a>
          </div>
        )}
        <div className={sortCol == 4 ? hdl : hd}>
          {sortCol == 4 && sortSymbol}
          <a href="#" onClick={() => this.handleClick(4)}>
            {nLabel}
          </a>
        </div>
        <div className={hd}>Phone</div>
        <div className={hd}>Amount</div>
        {desktop && (
          <div className={sortCol == 5 ? hdl : hd}>
            {sortCol == 5 && sortSymbol}
            <a href="#" onClick={() => this.handleClick(5)}>
              Lease ID
            </a>
          </div>
        )}
        {desktop && <div className={hd}>Status</div>}
      </React.Fragment>
    );
  }
  oneTableRow(order, idx) {
    const desktop = this.state.mobileMode ? false : true;
    const clsName = idx % 2 === 0 ? "otv-item-ev" : "otv-item-od";
    const rClsName = idx % 2 === 0 ? "otv-item-evr" : "otv-item-odr";
    const url = "/orderDetail/" + order.orderNumber;
    const timestamp = order.shortDtStr;
    let phone = order.phone;
    if (this.state.mobileMode && order.shortPhone) {
      phone = order.shortPhone;
    }
    let dd = "N/A";
    if (order.delivery) {
      if (order.deliveryDateStr) dd = order.deliveryDateStr;
      else if (order.storeId > 0) {
        dd = "Will call";
      }
    }
    const color = order.statusColor;
    const orderNumber = color ? (
      <font color={color}>{order.orderNumber}</font>
    ) : (
      order.orderNumber
    );
    const tsStr = color ? <font color={color}>{timestamp}</font> : timestamp;
    const nameStr = color ? (
      <font color={color}>{this.getName(order)}</font>
    ) : (
      this.getName(order)
    );
    const phStr = color ? <font color={color}>{phone}</font> : phone;
    const amtStr = color ? (
      <font color={color}>{toAmount(order.totalPrice)}</font>
    ) : (
      toAmount(order.totalPrice)
    );
    const idStr = color ? (
      <font color={color}>{order.leaseId}</font>
    ) : (
      order.leaseId
    );
    const statusStr = color ? (
      <font color={color}>{order.leaseStatus}</font>
    ) : (
      order.leaseStatus
    );
    const ddStr = color ? <font color={color}>{dd}</font> : dd;
    const idxStr = "" + (idx + 1) + ". ";
    return (
      <React.Fragment key={idx}>
        <div className={clsName}>
          <div className="flex-wrapper">
            <div>{idxStr}</div>
          </div>
        </div>
        <div className={clsName}>
          <a href={url}>{orderNumber}</a>
        </div>
        <div className={clsName}>{tsStr}</div>
        {desktop && <div className={clsName}>{ddStr}</div>}
        <div className={clsName}>{nameStr}</div>
        <div className={clsName}>{phStr}</div>
        <div className={rClsName}>{amtStr}</div>
        {desktop && <div className={clsName}>{idStr}</div>}
        {desktop && <div className={clsName}>{statusStr}</div>}
      </React.Fragment>
    );
  }
  getRows() {
    let rows = [];
    const startIdx = this.state.pageIdx * this.state.maxPerPage;
    let endIdx = startIdx + this.state.maxPerPage;
    if (endIdx > this.state.orderFldsList.length) {
      endIdx = this.state.orderFldsList.length;
    }

    let idx = startIdx;
    let actualIdx = startIdx;
    for (;;) {
      const flds = this.state.orderFldsList[idx];

      if (!flds) break;

      const order = flds[7];
      let show = true;
      if (this.state.showFilters) {
        if (order.leaseStatus == "Funded") {
          show = this.state.includeFunded;
        } else if (order.leaseStatus == "Cancelled") {
          show = this.state.includeCancelled;
        } else if (order.leaseId == 0) {
          show = this.state.includeId0;
        }
      }

      idx++;
      if (show) {
        rows.push(this.oneTableRow(order, actualIdx));
        actualIdx++;
        if (actualIdx >= endIdx) break;
      } else if (idx >= endIdx) break;
    }
    return rows;
  }
  showPageNumbers() {
    if (
      this.state.orderFldsList &&
      this.state.orderFldsList.length > this.state.maxPerPage
    ) {
      let pages = Math.ceil(
        this.state.orderFldsList.length / this.state.maxPerPage
      );
      if (this.state.orderFldsList.length % this.state.maxPerPage) {
        if (pages * this.state.maxPerPage < this.state.orderFldsList.length) {
          pages++;
        }
      }
      const pna = [];
      pna.push(<div key="PT">Pages:</div>);
      for (let i = 0; i < pages; i++) {
        let one;
        const clsName =
          i == this.state.pageIdx ? "left-5-bold" : "left-5-nodec";
        if (i == this.state.pageIdx) {
          one = (
            <div className={clsName} key={i}>
              {i + 1}
            </div>
          );
        } else {
          one = (
            <div className={clsName} key={i}>
              <a href="#" onClick={() => this.pageNumClicked(i)}>
                {i + 1}
              </a>
            </div>
          );
        }
        pna.push(one);
      }

      return <div className="generic-flex-top5">{pna}</div>;
    }
  }
  showFilterCBs() {
    if (this.state.showFilters) {
      const labelFunded = this.state.mobileMode ? "Funded" : "Show funded";
      const labelCan = this.state.mobileMode ? "Cancelled" : "Show cancelled";
      const label0 = this.state.mobileMode ? "ID=0" : "Show ID=0";
      return (
        <div className="flex-wrapper-t10">
          <div>
            <input
              type="checkbox"
              name="includeFunded"
              checked={this.state.includeFunded}
              onChange={this.handleChange}
            />
            &nbsp;{labelFunded}
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="includeCancelled"
              checked={this.state.includeCancelled}
              onChange={this.handleChange}
            />
            &nbsp;{labelCan}
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="includeId0"
              checked={this.state.includeId0}
              onChange={this.handleChange}
            />
            &nbsp;{label0}
          </div>
        </div>
      );
    }
  }
  showOrderTable() {
    if (this.state.orderFldsList) {
      let clsName = this.state.mobileMode ? "otv-table-c6" : "otv-table-c9";
      return (
        <div>
          {this.showFilterCBs()}
          {this.showPageNumbers()}
          <div className={clsName}>
            {this.tableHeader()}
            {this.getRows()}
          </div>
        </div>
      );
    }
  }
  render() {
    if (this.state.orderList && this.state.orderList.length > 0) {
      return (
        <div>
          {this.props.title && (
            <div>
              <font size="4">
                <b>{this.props.title}</b>
              </font>
            </div>
          )}
          {this.showOrderTable()}
          {this.showPageNumbers()}
        </div>
      );
    }
    return "";
  }
}
export default LeaseOrderTableView;
