import React from "react";

import "./App.css";
import Category from "./components/product/Category";
import Products from "./components/product/ProductsByCat";
import Home from "./components/Home";
import MenuBar from "./components/MenuBar";
import EditProduct from "./components/product/EditProduct";
import EditVariant from "./components/product/EditVariant";
import ProductPreview from "./components/product/ProductPreview";
import ManageImgMan from "./components/product/ManageImgMan";
import Search from "./components/product/Search";
import EditSpecials from "./components/setting/EditSpecials";
import EditHomepageProd from "./components/setting/EditHomepageProd";
import EditHomeImages from "./components/setting/EditHomeImages";
import HomeImagePreview from "./components/setting/HomeImagePreview";
import EditDeliveryFee from "./components/setting/EditDeliveryFee";
import EditAssembly from "./components/setting/EditAssembly";
import EditStoreLoc from "./components/store/EditStoreLoc";
import EditSpecialMenu from "./components/setting/EditSpecialMenu";
import EditLogos from "./components/setting/EditLogos";
import EditOtherSettings from "./components/setting/EditOtherSettings";
import EditDatSetting from "./components/setting/EditDatSetting";
import EditPaymentSetting from "./components/setting/EditPaymentSetting";
import LoginUser from "./components/user/LoginUser";
import MyAccount from "./components/user/MyAccount";
import ResetPassword from "./components/user/ResetPassword";
import ResetMessage from "./components/user/ResetMessage";
import ManageUsers from "./components/user/ManageUsers";
import ManageSessions from "./components/user/ManageSessions";
import UpdateUser from "./components/user/UpdateUser";
import Logout from "./components/user/Logout";
import EditCategory from "./components/product/EditCategory";
import EditSupplier from "./components/product/EditSupplier";
import ManageOrders from "./components/order/ManageOrders";
import ListOrders from "./components/order/ListOrders";
import OrderDetail from "./components/order/OrderDetail";
import ManagePayments from "./components/order/ManagePayments";
import PaymentDetail from "./components/order/PaymentDetail";
import NotificationDetail from "./components/order/NotificationDetail";
import Statistics from "./components/Statistics";
import StatsDetail from "./components/StatsDetail";
import EditPVSequence from "./components/product/EditPVSequence";
import EditImageSetting from "./components/product/EditImageSetting";
import AddSymLinkVariant from "./components/product/AddSymLinkVariant";
import InvisibleProducts from "./components/product/InvisibleProducts";
import EditBT from "./components/product/EditBT";
import EditCombo from "./components/product/EditCombo";
import ComboPreview from "./components/product/ComboPreview";
import ManageBT from "./components/product/ManageBT";
import EditProdAsmFee from "./components/product/EditProdAsmFee";
import EditStates from "./components/setting/EditStates";
import EditStoreSetting from "./components/store/EditStoreSetting";
import ScheduleSetting from "./components/setting/ScheduleSetting";
import EditFAQ from "./components/site/EditFAQ";
import PreviewFAQ from "./components/site/PreviewFAQ";
import OrderSearch from "./components/order/OrderSearch";
import OrderStats from "./components/order/OrderStats";
import OrderListView from "./components/order/OrderListView";
import WaitlistStats from "./components/product/WaitlistStats";
import ManageSalesperson from "./components/store/ManageSaleperson";
import EditSalesperson from "./components/store/EditSalesperson";
import ManageInventory from "./components/store/ManageInventory";
import ManageStoreProduct from "./components/store/ManageStoreProduct";
import EditStoreProduct from "./components/store/EditStoreProduct";
import EditVariantDisplay from "./components/product/EditVariantDisplay";
import EditProductSetting from "./components/setting/EditProductSetting";
import ScheduledOrders from "./components/order/ScheduledOrders";
import SEOSetting from "./components/setting/SEOSetting";
import BatchAdmin from "./components/site/BatchAdmin";
import EditBrowserIdSetting from "./components/setting/EditBrowserIdSetting";
import ManageLayaways from "./components/store/ManageLayaways";
import LayawayDetail from "./components/store/LayawayDetail";
import EditLayawaySetting from "./components/store/EditLayawaySetting";
import EditFtpSetting from "./components/setting/EditFtpSetting";
import MakePayment from "./components/store/MakePayment";
import DeliveryMap from "./components/order/DeliveryMap";
import LoadedOrder from "./components/store/LoadedOrder";
import EditLoadedSetting from "./components/setting/EditLoadedSetting";
import EditOrderPdfSetting from "./components/setting/EditOrderPdfSetting";
import EditHPSetting from "./components/setting/EditHPSetting";
import ReturnPayment from "./components/store/ReturnPayment";
import ReturnItems from "./components/store/ReturnItems";
import EditOrderSettings from "./components/setting/EditOrderSettings";
import PriceHistory from "./components/product/PriceHistory";
import SalesStats from "./components/store/SalesStats";
import EditProgressiveSetting from "./components/progressive/EditProgressiveSetting";
import LeasingOrders from "./components/progressive/LeasingOrders";
import EditAdvertisement from "./components/setting/EditAdvertisement";
import LeaseLookup from "./components/progressive/LeaseLookup";
import ManageFlyers from "./components/site/ManageFlyers";
import ImageMapPreview from "./components/site/ImageMapPreview";
import ManagePromotion from "./components/site/ManagePromotion";
import ErrorBoundary from "./components/ErrorBoundary";
import MarkComplete from "./components/store/MarkComplete";

import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="top-container">
        <ErrorBoundary>
          <MenuBar />
          <Category />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/product/:id/:sku?" component={ProductPreview} />
            <Route path="/category/:id" component={Products} />
            <Route path="/product-category/:id/:page?" component={Products} />
            <Route path="/editProduct/:id" component={EditProduct} />
            <Route path="/editPvSeq/:id" component={EditPVSequence} />
            <Route path="/manageImgMan/:id/:key?" component={ManageImgMan} />
            <Route path="/editSpecials" component={EditSpecials} />
            <Route path="/editHomepageProd" component={EditHomepageProd} />
            <Route path="/editHomeImages" component={EditHomeImages} />
            <Route path="/editAds" component={EditAdvertisement} />
            <Route path="/homeImgPreview" component={HomeImagePreview} />
            <Route path="/editDeliveryFee" component={EditDeliveryFee} />
            <Route path="/editAssembly" component={EditAssembly} />
            <Route path="/login" component={LoginUser} />
            <Route path="/logout" component={Logout} />
            <Route path="/myAccount/:id?" component={MyAccount} />
            <Route path="/search" component={Search} />
            <Route path="/resetPass" component={ResetPassword} />
            <Route path="/resetMessage" component={ResetMessage} />
            <Route path="/manageUsers" component={ManageUsers} />
            <Route path="/user/:userId?" component={UpdateUser} />
            <Route path="/editLocations/:id?" component={EditStoreLoc} />
            <Route path="/editSettings" component={EditOtherSettings} />
            <Route path="/editCategory" component={EditCategory} />
            <Route path="/editSupplier" component={EditSupplier} />
            <Route path="/manageOrders" component={ManageOrders} />
            <Route path="/orderDetail/:orderNo" component={OrderDetail} />
            <Route path="/managePayments" component={ManagePayments} />
            <Route path="/paymentDetail/:id" component={PaymentDetail} />
            <Route path="/notiDetail/:id" component={NotificationDetail} />
            <Route path="/specialMenu" component={EditSpecialMenu} />
            <Route path="/editLogos" component={EditLogos} />
            <Route path="/statistics" component={Statistics} />
            <Route path="/statsDetail/:statsDate" component={StatsDetail} />
            <Route path="/editStates" component={EditStates} />
            <Route path="/imageSetting/:id" component={EditImageSetting} />
            <Route path="/addSymLink/:id" component={AddSymLinkVariant} />
            <Route path="/invisible" component={InvisibleProducts} />
            <Route path="/editVariant/:id/:prodId?" component={EditVariant} />
            <Route path="/schedule" component={ScheduleSetting} />
            <Route path="/editBT/:key" component={EditBT} />
            <Route path="/editCombo/:comboId/:prodId?" component={EditCombo} />
            <Route path="/combo/:comboId" component={ComboPreview} />
            <Route path="/editAsmFee/:type?" component={EditProdAsmFee} />
            <Route path="/editFaq" component={EditFAQ} />
            <Route path="/previewFaq" component={PreviewFAQ} />
            <Route path="/btItems" component={ManageBT} />
            <Route path="/searchOrders" component={OrderSearch} />
            <Route path="/orderStats" component={OrderStats} />
            <Route path="/orderListView" component={OrderListView} />
            <Route path="/waitlistStats" component={WaitlistStats} />
            <Route path="/manageSess" component={ManageSessions} />
            <Route path="/storeSetting/:id" component={EditStoreSetting} />
            <Route path="/manageSales" component={ManageSalesperson} />
            <Route path="/manageInventory" component={ManageInventory} />
            <Route path="/editSales/:id?" component={EditSalesperson} />
            <Route path="/editVD/:id?" component={EditVariantDisplay} />
            <Route path="/manageStoreProd" component={ManageStoreProduct} />
            <Route path="/editStoreProd/:id?" component={EditStoreProduct} />
            <Route path="/editPmtSetting" component={EditPaymentSetting} />
            <Route path="/editProdSetting" component={EditProductSetting} />
            <Route path="/scheduled/:date?" component={ScheduledOrders} />
            <Route path="/batch" component={BatchAdmin} />
            <Route path="/seo" component={SEOSetting} />
            <Route path="/browserIdent" component={EditBrowserIdSetting} />
            <Route path="/manageLayaways" component={ManageLayaways} />
            <Route path="/layawaySetting" component={EditLayawaySetting} />
            <Route path="/layawayDetail/:orderNo" component={LayawayDetail} />
            <Route path="/editFtpSetting" component={EditFtpSetting} />
            <Route path="/makePayment" component={MakePayment} />
            <Route path="/deliveryMap/:date?" component={DeliveryMap} />
            <Route path="/datSetting" component={EditDatSetting} />
            <Route path="/loaded/:date?" component={LoadedOrder} />
            <Route path="/markComplete" component={MarkComplete} />
            <Route path="/loadedSetting" component={EditLoadedSetting} />
            <Route path="/listOrders/:type?" component={ListOrders} />
            <Route path="/pdfSetting" component={EditOrderPdfSetting} />
            <Route path="/hpSetting" component={EditHPSetting} />
            <Route path="/returnPayment" component={ReturnPayment} />
            <Route path="/orderSetting" component={EditOrderSettings} />
            <Route path="/priceHistory" component={PriceHistory} />
            <Route path="/salesStats" component={SalesStats} />
            <Route path="/leasingOrders" component={LeasingOrders} />
            <Route path="/manageFlyers" component={ManageFlyers} />
            <Route path="/flyer/:mapId?" component={ImageMapPreview} />
            <Route path="/managePromo" component={ManagePromotion} />
            <Route
              path="/progressiveSetting"
              component={EditProgressiveSetting}
            />
            <Route path="/leaseLookup" component={LeaseLookup} />
            <Route
              path="/returnItems/:orderNumber/:type?"
              component={ReturnItems}
            />
          </Switch>
        </ErrorBoundary>
      </div>
    </Router>
  );
}

export default App;
