import React from "react";
import {
  FormatCurrency,
  fullProductImageUrl,
  setTopContainerWrapperSettings,
  isMobileMode,
} from "../Util";

import "../../App.css";
import "../../generic.css";
import "../../prodPreview.css";

const MODE_DROPDOWN = 1;
const MODE_THUMBNAIL = 2;
const MODE_NAME_IN_BOX = 3;
const MODE_NAME_WITH_PRICE = 4;
const MODE_NAMECOLOR_IN_BOX = 5;
const MODE_NAMECOLOR_WITH_PRICE = 6;

class VariantDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      skuIdx: 0,
      product: this.props.product,
      vdMode: this.props.vdMode
        ? this.props.vdMode
        : this.props.product.variantDisplay,
      mobileMode: false,
    };
    this.selectVariant = this.selectVariant.bind(this);
    this.adjustScreenSize = this.adjustScreenSize.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.adjustScreenSize();
    window.addEventListener("resize", this.adjustScreenSize);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.product !== prevProps.product ||
      this.props.vdMode !== prevProps.vdMode
    ) {
      this.setState({
        skuIdx: 0,
        product: this.props.product,
        vdMode: this.props.vdMode
          ? this.props.vdMode
          : this.props.product.variantDisplay,
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustScreenSize);
  }

  adjustScreenSize() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  selectVariant(idx) {
    this.setState({ skuIdx: idx });
    if (this.props.callbackFunc) {
      this.props.callbackFunc(idx);
    }
  }
  handleChange(event) {
    const idx = parseInt(event.target.value);
    this.selectVariant(idx);
  }
  showThumbnails(prod) {
    const ratio = prod.thumbnailHWRatio > 0 ? prod.thumbnailHWRatio : 0.75;
    const thumbnailWidth = 85;
    const height = thumbnailWidth * ratio;
    return (
      <div className="prod-thumbnail-wrapper">
        {prod.pvList.map((pv, idx) => {
          const imageUrl = pv.imageUrl ? pv.imageUrl : prod.imageUrl;
          let forSale = pv.forSale;

          if (prod.useQuantity && pv.unitsInStock <= 0) {
            forSale = false;
          }
          if (forSale) {
            const clsName =
              idx === this.state.skuIdx ? "brightness-selected" : "brightness";
            return (
              <div className={clsName} key={idx}>
                <img
                  src={fullProductImageUrl(imageUrl)}
                  width={thumbnailWidth}
                  height={height}
                  onClick={() => this.selectVariant(idx)}
                ></img>
              </div>
            );
          } else {
            const clsName = idx === this.state.skuIdx ? "selected" : "none";
            return (
              <div className={clsName} key={idx}>
                <img
                  key={pv.sku}
                  id="soldOutImage"
                  src={fullProductImageUrl(imageUrl)}
                  width={thumbnailWidth}
                  height={height}
                  onClick={() => this.selectVariant(idx)}
                  alt={pv.sku}
                ></img>
              </div>
            );
          }
        })}
      </div>
    );
  }
  showDropdown(prod) {
    return (
      <div>
        <b>{prod.variantName}: </b>&nbsp;&nbsp;
        <select
          onChange={this.handleChange}
          onSelect={this.handleChange}
          value={this.state.skuIdx}
        >
          {prod.pvList.map((pv, idx) => {
            const price = prod.pvList[idx].finalPrice;
            let vv = pv.variantValue;
            if (vv.length > 35 && this.state.mobileMode)
              vv = vv.substring(0, 35);
            return (
              <option key={pv.sku} value={idx} key={idx}>
                {vv} ${price}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
  showNameInBox(prod, withPrice) {
    return (
      <div>
        <b>{prod.variantName}</b>
        <br />
        <div className="prod-thumbnail-wrapper">
          {prod.pvList.map((pv, idx) => {
            const val = pv.shortName ? pv.shortName : pv.variantValue;
            let forSale = pv.forSale;

            if (prod.useQuantity && pv.unitsInStock <= 0) {
              forSale = false;
            }

            const pColor = forSale ? "red" : "gray";
            let clsName = "varname-box";

            if (!forSale) clsName += "-nfs";
            if (idx === this.state.skuIdx) clsName += "-selected";
            return (
              <div
                className={clsName}
                key={idx}
                onClick={() => this.selectVariant(idx)}
              >
                {val}
                {withPrice && (
                  <React.Fragment>
                    <br />
                    <font color={pColor}>
                      <FormatCurrency amount={pv.finalPrice} />
                    </font>
                  </React.Fragment>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  showNameColorInBox(prod, withPrice) {
    return (
      <div>
        <b>{prod.variantName}</b>
        <br />
        <div className="prod-thumbnail-wrapper">
          {prod.pvList.map((pv, idx) => {
            const color = pv.color;
            const val = pv.shortName ? pv.shortName : pv.variantValue;
            let forSale = pv.forSale;

            if (prod.useQuantity && pv.unitsInStock <= 0) {
              forSale = false;
            }

            const pColor = forSale ? "red" : "gray";
            let clsName = "varnamef-box";

            if (!forSale) clsName += "-nfs";
            if (idx === this.state.skuIdx) clsName += "-selected";

            return (
              <div
                className={clsName}
                key={idx}
                onClick={() => this.selectVariant(idx)}
              >
                {color && (
                  <div
                    style={{
                      backgroundColor: color,
                      border: "1px solid black",
                      height: "15px",
                      width: "15px",
                      marginRight: "5px",
                    }}
                  >
                    &nbsp;&nbsp;
                  </div>
                )}
                <div>
                  {val}
                  {withPrice && (
                    <React.Fragment>
                      <br />
                      <font color={pColor}>
                        <FormatCurrency amount={pv.finalPrice} />
                      </font>
                    </React.Fragment>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  render() {
    if (this.state.product.pvList && this.state.product.pvList.length > 1) {
      const prod = this.state.product;
      switch (this.state.vdMode) {
        case MODE_THUMBNAIL:
          return this.showThumbnails(prod);
        case MODE_NAME_IN_BOX:
          return this.showNameInBox(prod, false);
        case MODE_NAME_WITH_PRICE:
          return this.showNameInBox(prod, true);
        case MODE_NAMECOLOR_IN_BOX:
          return this.showNameColorInBox(prod, false);
        case MODE_NAMECOLOR_WITH_PRICE:
          return this.showNameColorInBox(prod, true);
        default:
          return this.showDropdown(prod);
      }
    }
    return "";
  }
}

export default VariantDisplay;
