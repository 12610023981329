import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import {
  apiBaseUrl,
  fullProductImageUrl,
  FormatCurrency,
  priceColor,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import "../../generic.css";
import "../../App.css";
import "../../prodPreview.css";

class RelatedProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      relProdIdList: null,
      allProducts: null,
      isLoading: true,
      tbaProdId: 0,
      mobileMode: false,
      delayRefresh: false,
      errorMessage: null,
      editAllowed: checkUpdate("RelatedProd"),
      viewAllowed: checkView("RelatedProd"),
    };
    this.fetchData = this.fetchData.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.handleProdChange = this.handleProdChange.bind(this);
    this.addRelatedProd = this.addRelatedProd.bind(this);
    this.removeRelatedProd = this.removeRelatedProd.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
  }
  componentDidUpdate(preProps) {
    if (this.props.prodId !== preProps.prodId) {
      this.fetchData();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({
        relProdIdList: response.relProdIdList,
        allProducts: response.allProducts,
        isLoading: false,
      });

      const availList = this.getAvailableList(
        response.allProducts,
        response.relProdIdList
      );
      let tbaProdId = 0;

      if (availList && availList.length > 0) {
        tbaProdId = availList[0].id;
      }
      this.setState({ tbaProdId });
    }
  }

  inRelList(prodId, relProdIdList) {
    const relIdList = relProdIdList ? relProdIdList : [];
    return relIdList.find((id) => id == prodId);
  }
  getAvailableList(allProducts, relProdIdList) {
    if (allProducts) {
      const newList = allProducts.filter(
        (item) =>
          item.id != this.props.prodId &&
          !this.inRelList(item.id, relProdIdList)
      );
      return newList;
    }
    return null;
  }

  fetchData() {
    if (!this.state.viewAllowed) return;

    const url = apiBaseUrl() + "GetRelatedProducts";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.prodId,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.status) {
        this.fetchData();
      } else {
        this.setState({ errorMessage: response.errorMessage });
      }
    }
  }

  addRelatedProd(event) {
    let relProdIdList = this.state.relProdIdList
      ? this.state.relProdIdList
      : [];

    relProdIdList.push(this.state.tbaProdId);
    this.handleAddDelete(relProdIdList);
  }
  removeRelatedProd(prodId) {
    const currentList = this.state.relProdIdList
      ? this.state.relProdIdList
      : [];
    const newList = currentList.filter((id) => id != prodId);
    this.handleAddDelete(newList);
  }

  handleAddDelete(relProdIdList) {
    const url = apiBaseUrl() + "UpdateRelatedProducts";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      mainId: this.props.prodId,
      idList: relProdIdList,
      delayMainRefresh: this.state.delayRefresh,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleProdChange(event) {
    this.setState({ tbaProdId: parseInt(event.target.value) });
  }

  priceRange(pv) {
    return (
      <font color={priceColor()}>
        <FormatCurrency amount={pv.salePrice} />
      </font>
    );
  }

  showProductList() {
    const availList = this.getAvailableList(
      this.state.allProducts,
      this.state.relProdIdList
    );

    if (!availList) return "";

    return (
      <select
        name="prodId"
        value={this.state.tbaProdId}
        onChange={this.handleProdChange}
      >
        {availList.map((prod) => {
          const pv = prod.pvList[0];
          return (
            <option value={prod.id} key={prod.id}>
              {prod.id}: {pv.sku} ${pv.salePrice}
            </option>
          );
        })}
      </select>
    );
  }
  productThumbnail(pid, canRemove) {
    if (!this.state.allProducts) return "";

    const prod = this.state.allProducts.find((item) => item.id === pid);

    if (!prod) return "";

    const imgUrl = fullProductImageUrl(prod.imageUrl);
    const pvUrl = "/product/" + prod.id;

    return (
      <div className="generic-flex" key={pid}>
        <div className="generic-item">
          <Link to={pvUrl}>
            <img src={imgUrl} width="150" height="100" alt="ProdImg"></img>
          </Link>
          <br />
          {canRemove && (
            <button name="remove" onClick={() => this.removeRelatedProd(pid)}>
              Remove
            </button>
          )}
        </div>

        <div className="generic-item">
          <div>{this.priceRange(prod.pvList[0])}</div>
          <div>
            {prod.pvList.map((pv) => {
              let sku = pv.sku;
              if (sku.length > 20 && this.state.mobileMode)
                sku = sku.substring(0, 20) + "...";
              return (
                <React.Fragment key={pv.sku}>
                  {sku}
                  <br />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  handleChange(event) {
    if (event.target.name === "delayRefresh") {
      this.setState({ delayRefresh: event.target.checked });
    }
  }
  displayRelatedProducts() {
    const delMsg = this.state.relProdIdList
      ? "Below are current related products. You can remove them by clicking the Remove button below each picture."
      : "";

    return (
      <div>
        <hr width="350" align="left"></hr>
        <div className="generic-flex-b8">
          <div>
            <b>
              <font size="4">Related Products</font>
            </b>
          </div>
          <div className="left-10">
            <input
              type="checkbox"
              name="delayRefresh"
              onChange={this.handleChange}
              defaultChecked={this.state.delayRefresh}
            ></input>{" "}
            Delay Refresh
          </div>
        </div>
        {this.state.editAllowed && delMsg}
        <p />
        {this.state.relProdIdList && (
          <React.Fragment>
            <div className="grid-wrapper-top">
              {this.state.relProdIdList.map((pid) => {
                return this.productThumbnail(pid, this.state.editAllowed);
              })}
            </div>
            <p />
          </React.Fragment>
        )}
        {this.state.editAllowed && (
          <React.Fragment>
            To add a new related product, select from the dropdown list and then
            click the "Add" button. <br />
            <p />
            <div>
              {this.showProductList()}
              <button
                name="manage"
                className="btn-style"
                onClick={this.addRelatedProd}
              >
                Add
              </button>
              &nbsp;&nbsp;
              {this.productThumbnail(this.state.tbaProdId, false)}
            </div>
            <p />
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    if (!this.state.viewAllowed) return "";
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">{this.displayRelatedProducts()}</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RelatedProducts));
