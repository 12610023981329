import React from "react";
import { getIconBaseUrl } from "./Util";
import "../App.css";
import "../generic.css";
import "../form.css";

class Working extends React.Component {
  render() {
    const size = this.props.size ? this.props.size : 120;
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <img src={getIconBaseUrl() + "working.gif"} width={size} />
        </div>
      </div>
    );
  }
}
export default Working;
