import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditBrowserIdSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      browserIdList: null,
      isLoading: true,
      updateBtnDisabled: true,
      addBtnDisabled: true,
      testBtnDisabled: true,
      identStr: "",
      shortName: "",
      sequence: 0,
      wholeAgent: false,
      userAgent: "",
      extractedShortName: null,
      errorMessage: null,
      viewAllowed: checkSecurity("RootFeatures"),
    };

    this.adjustMode = this.adjustMode.bind(this);
    this.fetchList = this.fetchList.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.updateList = this.updateList.bind(this);
    this.testBrowserIdents = this.testBrowserIdents.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchList();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  fetchList() {
    const url = apiBaseUrl() + "GetBrowserIdents";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  testBrowserIdents() {
    const url = apiBaseUrl() + "TestBrowserIdents";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.state.userAgent,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data) {
          this.setState({ extractedShortName: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const objList = response.objList;
      const sequence = this.getNextSequence(objList);

      this.setState({ browserIdList: objList, sequence, isLoading: false });
    }
  }
  getNextSequence(bidList) {
    let sequence = 0;
    if (bidList != null && bidList.length > 0) {
      for (let i = 0; i < bidList.length; i++) {
        if (sequence < bidList[i].sequence) sequence = bidList[i].sequence;
      }
      sequence = sequence + 1;
    }
    return sequence;
  }
  processUpdateResponse(response) {
    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  updateList(event) {
    const url = apiBaseUrl() + "UpdateBrowserIdents";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: this.state.browserIdList,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;

    if (name.startsWith("NEW_")) {
      name = name.substring(4);
      if (name.startsWith("CB_")) {
        name = name.substring(3);
        val = event.target.checked;
      }
      let addBtnDisabled = true;
      if (name === "identStr") {
        if (val && val.trim().length > 0) {
          if (this.state.shortName && this.state.shortName.trim().length > 0) {
            addBtnDisabled = false;
          }
        }
      } else if (name === "shortName") {
        if (val && val.trim().length > 0) {
          if (this.state.identStr && this.state.identStr.trim().length > 0) {
            addBtnDisabled = false;
          }
        }
      }
      this.setState({ [name]: val, addBtnDisabled });
    } else if (name === "userAgent") {
      const testBtnDisabled = val && val.trim().length > 0 ? false : true;
      this.setState({
        userAgent: val,
        testBtnDisabled,
        extractedShortName: null,
      });
    } else {
      const pos = name.indexOf("_");
      const theIdx = parseInt(name.substring(0, pos));

      name = name.substring(pos + 1);
      if (name.startsWith("CB_")) {
        name = name.substring(3);
        val = event.target.checked;
      }

      const browserIdList = this.state.browserIdList.map((bid, idx) => {
        if (idx === theIdx) {
          const newBid = {
            ...bid,
            [name]: val,
          };
          return newBid;
        } else return bid;
      });
      this.setState({ browserIdList, updateBtnDisabled: false });
    }
  }
  handleDelete(theIdx) {
    const browserIdList = this.state.browserIdList.filter(
      (bid, idx) => idx !== theIdx
    );
    this.setState({ browserIdList, updateBtnDisabled: false });
  }
  handleAdd(event) {
    const bid = {
      id: 0,
      identStr: this.state.identStr,
      shortName: this.state.shortName,
      sequence: this.state.sequence,
      wholeAgent: this.state.wholeAgent,
    };
    let browserIdList = this.state.browserIdList
      ? this.state.browserIdList
      : [];
    browserIdList.push(bid);

    const sequence = this.getNextSequence(browserIdList);
    this.setState({
      browserIdList,
      sequence,
      shortName: "",
      identStr: "",
      wholeAgent: false,
      updateBtnDisabled: false,
    });
  }
  showOneRow(bid, idx) {
    const clsName = "left-10";
    const prefix = bid ? "" + idx : "NEW";
    const idName = prefix + "_identStr";
    const flagName = prefix + "_CB_wholeAgent";
    const snName = prefix + "_shortName";
    const seqName = prefix + "_sequence";
    const btnName = "Del" + idx;
    const obj = bid ? bid : this.state;
    const btnClsName = this.state.addBtnDisabled
      ? "disabled-small-btn"
      : "small-btn";
    return (
      <React.Fragment>
        <div>
          <input
            type="text"
            size="15"
            name={idName}
            onChange={this.handleChange}
            value={obj.identStr}
            placeholder="Ident Str"
          ></input>
          <br />
          <input
            type="checkbox"
            name={flagName}
            onChange={this.handleChange}
            defaultChecked={obj.wholeAgent}
          ></input>{" "}
          Entire Agent
        </div>
        <div className={clsName}>
          <input
            type="text"
            size="15"
            name={snName}
            onChange={this.handleChange}
            value={obj.shortName}
            placeholder="Short Name"
          ></input>
        </div>
        <div className={clsName}>
          <input
            type="text"
            size="4"
            name={seqName}
            onChange={this.handleChange}
            value={obj.sequence}
            placeholder="Seq"
          ></input>
        </div>
        <div className={clsName}>
          {bid ? (
            <button name={btnName} onClick={() => this.handleDelete(idx)}>
              Delete
            </button>
          ) : (
            <button
              name="addNew"
              className={btnClsName}
              onClick={this.handleAdd}
              disabled={this.state.addBtnDisabled}
            >
              Add New
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
  showBrowserIdents() {
    if (!this.state.browserIdList || this.state.browserIdList.length === 0)
      return;

    return (
      <div className="fourcol-wrapper-g10">
        {this.state.browserIdList.map((bid, idx) => {
          return this.showOneRow(bid, idx);
        })}
      </div>
    );
  }
  showAdd() {
    return <div className="generic-flex">{this.showOneRow()}</div>;
  }
  showTest() {
    const clsName = this.state.testBtnDisabled
      ? "disabled-small-btn"
      : "small-btn";
    const shortName = this.state.extractedShortName;
    return (
      <div>
        Enter browser's "UserAgent" info below then click the "Test" button.
        <p />
        <textarea
          rows="5"
          cols="60"
          name="userAgent"
          value={this.state.userAgent}
          onChange={this.handleChange}
        ></textarea>
        <br />
        {shortName && (
          <p>
            Short Name: <b>{shortName}</b>
          </p>
        )}
        <button
          name="test"
          className={clsName}
          disabled={this.state.testBtnDisabled}
          onClick={this.testBrowserIdents}
        >
          Test Browser Idents
        </button>
      </div>
    );
  }
  render() {
    if (this.state.isLoading === true) return <Working size={80} />;

    const btnClsName = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Browser Idents</font>
          <p />
          {this.showBrowserIdents()}
          <p />
          {this.showAdd()}
          <p />
          <button
            className={btnClsName}
            name="update"
            disabled={this.state.updateBtnDisabled}
            onClick={this.updateList}
          >
            Update
          </button>
          <p />
          {this.showTest()}
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBrowserIdSetting);
