import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  toAmountEx,
  fullProductImageUrl,
  getMainSiteUrl,
  getIconBaseUrl,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkSecurity, checkView } from "../SecManager";
import Working from "../Working";
import StoreDatesSelector from "../StoreDatesSelector";
import "../../App.css";
import "../../generic.css";
import "../../waitlistStats.css";

class SalesStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statsList: null,
      isLoading: true,
      mobileMode: false,
      title: null,
      statsIdx: -1,
      errorMessage: null,
      viewAllowed: checkView("Order"),
    };

    this.fetchStats = this.fetchStats.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchStats({ storeId: -1, category: -1 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  fetchStats(storeDates) {
    const storeId = storeDates.storeId;
    const startDate = storeDates.startDate;
    const endDate = storeDates.endDate;
    const storeName = storeDates.storeName;
    const url = apiBaseUrl() + "GetTopSalesItems";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      storeId,
      startDate,
      endDate,
      category: storeDates.category,
    };

    let title = null;
    if (storeId >= 0 || startDate || endDate) {
      if (storeId >= 0 && storeName) {
        title = storeName;
      } else title = "All Stores";
      if (startDate || endDate) {
        const sd = startDate ? startDate : "2020-05-08";
        const ed = endDate ? endDate : "Today";
        title += " " + sd + " - " + ed;
      } else {
        title += " All Time";
      }
    }
    this.setState({ title, isLoading: true, statsIdx: -1 });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
          return;
        } else {
          this.setState({
            statsList: res.data.objList,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get orders",
        });
      });
  }
  handleClick(idx) {
    this.setState({ statsIdx: idx });
  }
  displayNonResult() {
    if (this.state.searching) {
      return <Working />;
    } else {
      return <label>No order was found: {this.state.title}</label>;
    }
  }
  oneStatsRow(stats, idx) {
    const clsName = idx % 2 === 0 ? "wl-grid-item-ev" : "wl-grid-item-od";
    const img = stats.imageUrl ? (
      <img src={fullProductImageUrl(stats.imageUrl)} width="40" height="30" />
    ) : (
      ""
    );

    const url = "/product/" + stats.productId + "/" + stats.sku;
    const msUrl =
      getMainSiteUrl() + "/product/" + stats.productId + "/" + stats.sku;
    const ind =
      stats.phComplete && checkSecurity("RootFeatures") ? (
        <div className="greenCircle"></div>
      ) : (
        ""
      );
    let sku = stats.name && stats.name.length > 0 ? stats.name : stats.sku;
    if (this.state.mobileMode && sku.length > 10) {
      sku = sku.substring(0, 10) + "...";
    }
    return (
      <React.Fragment key={idx}>
        <div className={clsName}>
          <div className="flex-center">
            {stats.ranking}
            {ind}
          </div>
        </div>
        {stats.name ? (
          <div className={clsName}>
            <div className="flex-center">
              <div>{img}</div>
              <div>{sku}</div>
            </div>
          </div>
        ) : (
          <div className={clsName}>
            <div className="flex-center">
              <div>
                <a href={url} target="AdminSite">
                  {img}
                </a>
              </div>
              <div>
                <a href={msUrl} target="MainSite">
                  {sku}
                </a>
              </div>
            </div>
          </div>
        )}

        <div className={clsName}>
          <a href="#" onClick={() => this.handleClick(idx)}>
            {stats.totalCount}
          </a>
        </div>
        <div className={clsName}>{toAmountEx(stats.averagePrice)}</div>
        {!this.state.mobileMode && (
          <div className={clsName}>{toAmountEx(stats.lowestPrice)}</div>
        )}
        {!this.state.mobileMode && (
          <div className={clsName}>{toAmountEx(stats.highestPrice)}</div>
        )}
      </React.Fragment>
    );
  }
  showStats() {
    const statsList = this.state.statsList;
    if (statsList && statsList.length > 0) {
      let clsName = this.state.mobileMode ? "wl-4col-table" : "wl-6col-table";
      const hdClsName = "wl-grid-item-hd";

      return (
        <div>
          {this.state.title && (
            <div>
              <font size="4">{this.state.title}</font>
            </div>
          )}
          <div className={clsName}>
            <div className={hdClsName}></div>
            <div className={hdClsName}>
              <b>SKU</b>
            </div>
            <div className={hdClsName}>
              <b>Qty</b>
            </div>
            <div className={hdClsName}>
              <b>Avg Price</b>
            </div>
            {!this.state.mobileMode && (
              <div className={hdClsName}>
                <b>Low</b>
              </div>
            )}
            {!this.state.mobileMode && (
              <div className={hdClsName}>
                <b>High</b>
              </div>
            )}
            {statsList.map((stats, idx) => {
              return this.oneStatsRow(stats, idx);
            })}
          </div>
        </div>
      );
    }
  }
  oneOrderRow(order, idx) {
    const clsName = idx % 2 === 0 ? "wl-grid-item-ev" : "wl-grid-item-od";
    const dp = order.delivery ? "D" : "P";
    const url = order.layaway
      ? "/layawayDetail/" + order.orderNumber
      : "/orderDetail/" + order.orderNumber;
    return (
      <React.Fragment key={idx}>
        <div className={clsName}>{idx + 1}</div>
        <div className={clsName}>{order.dtStr}</div>
        <div className={clsName}>
          <a href={url} target="AdminSite">
            {order.orderNumber}
          </a>
        </div>
        <div className={clsName}>{order.quantity}</div>
        <div className={clsName}>{toAmountEx(order.price)}</div>
        {!this.state.mobileMode && (
          <div className={clsName}>{order.storeName}</div>
        )}
        {!this.state.mobileMode && <div className={clsName}>{dp}</div>}
      </React.Fragment>
    );
  }
  showOrderList() {
    const orderList = this.state.statsList[this.state.statsIdx].orderList;
    if (orderList && orderList.length > 0) {
      let clsName = this.state.mobileMode ? "wl-5col-table" : "wl-7col-table";
      const hdClsName = "wl-grid-item-hd";

      return (
        <div>
          <div className={clsName}></div>
          <div className="flex-center">
            <div>
              <a href="#" onClick={() => this.handleClick(-1)}>
                <img src={getIconBaseUrl() + "back.jpg"} height="25" />
              </a>
            </div>
            <div className="left-10">
              <font size="4">
                {this.state.statsList[this.state.statsIdx].sku}
              </font>
            </div>
          </div>
          <div className={clsName}>
            <div className={hdClsName}></div>
            <div className={hdClsName}>
              <b>Date</b>
            </div>
            <div className={hdClsName}>
              <b>Order#</b>
            </div>
            <div className={hdClsName}>
              <b>Qty</b>
            </div>
            <div className={hdClsName}>
              <b>Price</b>
            </div>
            {!this.state.mobileMode && (
              <div className={hdClsName}>
                <b>Store</b>
              </div>
            )}
            {!this.state.mobileMode && (
              <div className={hdClsName}>
                <b>D/P</b>
              </div>
            )}
            {orderList.map((order, idx) => {
              return this.oneOrderRow(order, idx);
            })}
          </div>
        </div>
      );
    }
  }
  showStatsOrDetail() {
    if (this.state.statsIdx >= 0) return this.showOrderList();
    else return this.showStats();
  }
  render() {
    if (this.state.isLoading)
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <Working />
          </div>
        </div>
      );

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div>
            <font size="5">Product Sales Statistics</font>
          </div>
          <br />
          <StoreDatesSelector
            userId={this.props.userId}
            sessionKey={this.props.sessionKey}
            btnTitle="Get Stats"
            useCategory={true}
            actionHandler={this.fetchStats}
          />
          <br />
          <hr className="hr-300" align="left" />
          {this.showStatsOrDetail()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesStats);
