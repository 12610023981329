import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { compose, withProps } from "recompose";

class MyGoogleMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: this.props.center,
      warehouse: this.props.warehouse,
      deliveryList: this.props.deliveryList,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.deliveryInfo !== prevProps.deliveryInfo) {
      this.setState({
        deliveryInfo: this.props.deliveryInfo,
        center: this.props.center,
        warehouse: this.props.warehouse,
      });
    }
  }
  shouldComponentUpdate(nextProps) {
    // If shouldComponentUpdate returns false,
    // then render() will be completely skipped until the next state change.
    // In addition, componentWillUpdate and componentDidUpdate will not be called.
    return false;
  }
  googleMap() {
    const delivered = {
      path:
        "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: "blue",
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new window.google.maps.Point(12, 20),
    };
    const delivered2 = {
      path:
        "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
      fillColor: "blue",
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new window.google.maps.Point(12, 20),
    };
    const MyMapComponent = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyBRwOEmf1pnDeWq0yaOgButnGaUwvYk_5w&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `1200px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap defaultZoom={11} defaultCenter={this.state.center}>
        <Marker
          position={this.state.warehouse}
          label="FWL"
          title="FWL Warehouse"
          labelClass={{ color: "white" }}
          icon="https://www.furniturewayless.com/icons/fwl-map.png"
        />
        {this.state.deliveryList.map((info) => {
          const icon = info.order.statusId == 6 ? delivered : null;
          const label = {
            fontWeight: "bold",
            text: "" + info.index,
            color: "white",
          };
          const title =
            "" +
            info.index +
            ". " +
            info.order.orderNumber +
            "\r\n" +
            "$" +
            info.order.totalPrice +
            " ($" +
            info.order.deliveryFee +
            ")" +
            "\r\n" +
            "Distance: " +
            info.address.distance +
            "\r\n" +
            info.address.firstName +
            " " +
            info.address.lastName +
            "\r\n" +
            info.address.addressLine1 +
            "\r\n" +
            info.address.city +
            ", " +
            info.address.state +
            " " +
            info.address.zipCode +
            "\r\n" +
            info.address.phone;
          return (
            <Marker
              position={{
                lat: info.address.latitude,
                lng: info.address.longitude,
              }}
              label={label}
              title={title}
              icon={icon}
              onClick={() => this.props.markerClickedCallback(info)}
            />
          );
        })}
      </GoogleMap>
    ));

    return <MyMapComponent />;
  }
  render() {
    return this.googleMap();
  }
}

export default MyGoogleMap;
