import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditLayawaySettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetLayawaySetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({ settings: response.data, isLoading: false });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;

    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
    }

    if (name.startsWith("INTERNET_")) {
      name = name.substring(9);
      const internet = {
        ...this.state.settings.internet,
        [name]: val,
      };
      const settings = {
        ...this.state.settings,
        internet,
      };
      this.setState({ settings, updateBtnDisabled: false });
    } else {
      const settings = {
        ...this.state.settings,
        [name]: val,
      };
      this.setState({ settings, updateBtnDisabled: false });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateLayawaySetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.settings,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  theForm() {
    const settings = this.state.settings;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const hrClsName = this.state.mobileMode ? "hr-240" : "hr-300";
    const dLabel = this.state.mobileMode ? "Deposit" : "Deposit Amount";
    const bLabel = this.state.mobileMode ? "Before" : "Before Payment";
    const aLabel = this.state.mobileMode ? "After" : "After Payment";
    const pLabel = this.state.mobileMode
      ? "Payment Overdue after"
      : "Payment Overdue Notification after";
    const uLabel = this.state.mobileMode
      ? "Update when Layaway Changed"
      : "Update DAT when Layaway Changed";
    const rowCnt = this.state.mobileMode ? 15 : 8;
    const colCnt = this.state.mobileMode ? 43 : 80;
    const iLabel = this.state.mobileMode ? "Instru" : "Instruction";
    return (
      <div className="generic-wrapper">
        <font size="5">Layaway Settings</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">DAT: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_updateDat"
              defaultChecked={settings.updateDat}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            {uLabel}
          </div>
          <div className="form-item-label">Invoice: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_showInvoiceLinks"
              defaultChecked={settings.showInvoiceLinks}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Show Invoice Links
          </div>
          <div className="form-item-label">
            Customer
            <br />
            Notification:{" "}
          </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_pmtReminderEnabled"
              defaultChecked={settings.pmtReminderEnabled}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Payment Reminder within&nbsp;
            <input
              type="text"
              name="pmtReminderDays"
              size="3"
              value={settings.pmtReminderDays}
              disabled={!this.state.editAllowed || !settings.pmtReminderEnabled}
              onChange={this.handleChange}
            ></input>{" "}
            Days
            <br />
            <input
              type="checkbox"
              name="CB_pmtOverdueEnabled"
              defaultChecked={settings.pmtOverdueEnabled}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            {pLabel}&nbsp;
            <input
              type="text"
              name="pmtOverdueDays"
              size="3"
              value={settings.pmtOverdueDays}
              disabled={!this.state.editAllowed || !settings.pmtOverdueEnabled}
              onChange={this.handleChange}
            ></input>{" "}
            Days
            <br />
            <input
              type="checkbox"
              name="CB_changeNotificationEnabled"
              defaultChecked={settings.changeNotificationEnabled}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Layaway Change Notification
          </div>
          <div className="form-item-label">Invoice: </div>
          <div className="form-item-value">
            <input
              type="text"
              name="invoicePrefix"
              size="8"
              value={settings.invoicePrefix}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            (Prefix)
            <br />
            <input
              type="text"
              name="invoiceStartNum"
              size="8"
              value={settings.invoiceStartNum}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            (Start Number)
          </div>
          <div className="form-item-label">Payment: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_userPaymentAllowed"
              defaultChecked={settings.userPaymentAllowed}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Allow User Online Payments
          </div>
          <div className="form-item-label">
            <b>INTERNET</b>
          </div>
          <div className="form-item-value">
            <hr className={hrClsName} />
          </div>
          <div className="form-item-label">Layaway: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_INTERNET_enabled"
              defaultChecked={settings.internet.enabled}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed}
            ></input>{" "}
            Enabled
          </div>
          <div className="form-item-label">Prefix: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="INTERNET_layawayPrefix"
              value={settings.internet.layawayPrefix}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
            ></input>
          </div>
          <div className="form-item-label">Start Num: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="INTERNET_layawayStartNum"
              value={settings.internet.layawayStartNum}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
            ></input>
          </div>
          <div className="form-item-label">{dLabel}:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_INTERNET_showDepositAsZero"
              defaultChecked={settings.internet.showDepositAsZero}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
            ></input>{" "}
            Show Zero Amount in Invoice
            <br />
            <input
              type="checkbox"
              name="CB_INTERNET_pcnt10Enabled"
              defaultChecked={settings.internet.pcnt10Enabled}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
            ></input>{" "}
            Allow 10% Down
            <br />
            <input
              type="checkbox"
              name="CB_INTERNET_pcnt20Enabled"
              defaultChecked={settings.internet.pcnt20Enabled}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
            ></input>{" "}
            Allow 20% Down
            <br />
            <input
              type="text"
              size="5"
              name="INTERNET_maxDownpayment"
              value={settings.internet.maxDownpayment}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
            ></input>{" "}
            Max Downpayment
            <br />
          </div>
          <div className="form-item-label">
            {bLabel}
            <br />
            (SC):
          </div>
          <div className="form-item-value">
            <textarea
              name="INTERNET_scBeforePmtMsg"
              value={settings.internet.scBeforePmtMsg || ""}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
              rows={rowCnt}
              cols={colCnt}
            ></textarea>
            <p />
            <b>Mobile:</b>
            <br />
            <textarea
              name="INTERNET_scBeforePmtMsgMobile"
              value={settings.internet.scBeforePmtMsgMobile || ""}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
              rows={rowCnt}
              cols={colCnt}
            ></textarea>
          </div>
          <div className="form-item-label">
            {bLabel}
            <br />
            (MC):
          </div>
          <div className="form-item-value">
            <textarea
              name="INTERNET_mcBeforePmtMsg"
              value={settings.internet.mcBeforePmtMsg || ""}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
              rows={rowCnt}
              cols={colCnt}
            ></textarea>
            <p />
            <b>Mobile:</b>
            <br />
            <textarea
              name="INTERNET_mcBeforePmtMsgMobile"
              value={settings.internet.mcBeforePmtMsgMobile || ""}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
              rows={rowCnt}
              cols={colCnt}
            ></textarea>
          </div>
          <div className="form-item-label">{aLabel}:</div>
          <div className="form-item-value">
            <textarea
              name="INTERNET_afterPaymentMessage"
              value={settings.internet.afterPaymentMessage || ""}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
              rows={rowCnt}
              cols={colCnt}
            ></textarea>
          </div>
          <div className="form-item-label">
            Payment
            <br />
            {iLabel}:
          </div>
          <div className="form-item-value">
            <textarea
              name="INTERNET_pmtInstrTmpl"
              value={settings.internet.pmtInstrTmpl || ""}
              onChange={this.handleChange}
              disabled={!this.state.editAllowed || !settings.internet.enabled}
              rows={rowCnt}
              cols={colCnt}
            ></textarea>
          </div>
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled || !this.state.editAllowed}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLayawaySettings);
