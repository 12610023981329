import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import {
  FormatCurrency,
  apiBaseUrl,
  priceColor,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
  isUserLoggedIn,
  getSoldoutIcon,
  isMobileMode,
  manualBaseUrl,
  getIconBaseUrl,
} from "../Util";
import { checkUpdate, checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import RelatedProducts from "./RelatedProducts";
import BuyTogether from "./BuyTogether";
import VariantDisplay from "./VariantDisplay";
import ProductImage from "./ProductImage";
import PHWidget from "./PHWidget";
import Working from "../Working";
import "../../App.css";
import "../../form.css";
import "../../generic.css";
import "../../product.css";
import "../../prodPreview.css";

class ProductPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sku: this.props.match.params.sku,
      product: null,
      skuIdx: 0,
      displayName: null,
      imageWidth: 0,
      maxImageWidth: 600,
      pickupAvail: "Same day",
      deliveryAvail: "Next day",
      forSale: true,
      zeroQuantity: false,
      quantity: 0,
      qtyBtnDisabled: true,
      mobileMode: false,
      addVariantDisabled: false,
      editAllowed: checkUpdate("Product"),
      advAllowed: checkSecurity("AdvancedProduct"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.showDetail = this.showDetail.bind(this);
    this.updateImageWidth = this.updateImageWidth.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.setAvailability = this.setAvailability.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.addSymLink = this.addSymLink.bind(this);
    this.addVariant = this.addVariant.bind(this);
    this.selectVariant = this.selectVariant.bind(this);
    this.adjustScreenSize = this.adjustScreenSize.bind(this);
    this.editCombo = this.editCombo.bind(this);
    this.viewCombo = this.viewCombo.bind(this);
    this.addManuals = this.addManuals.bind(this);
    this.updateSku = this.updateSku.bind(this);
    this.updateQuantity = this.updateQuantity.bind(this);
  }

  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const product = response.data;
      const sku = this.props.match.params.sku;
      let idx = -1;
      if (sku && product.pvList.length > 1) {
        idx = 0;
        for (const [i, pv] of product.pvList.entries()) {
          if (pv.sku === sku) {
            idx = i;
            break;
          }
        }
      }

      const optionName =
        product.pvList && product.pvList.length > 1 ? product.optionName : null;
      let addVariantDisabled = true;

      if (product.variantName) {
        if (product.pvList.length > 1 || product.pvList[0].variantValue) {
          addVariantDisabled = false;
        }
      }

      if (idx < 0) idx = 0;

      let forSale = product.pvList[idx].forSale;
      let zeroQuantity = false;
      const pv = product.pvList[idx];

      if (product.useQuantity && pv.unitsInStock <= 0) {
        forSale = false;
        zeroQuantity = true;
      }
      this.setState({
        product,
        sku: pv.sku,
        skuIdx: idx,
        optionName,
        forSale,
        zeroQuantity,
        addVariantDisabled,
        displayName: this.getDisplayName(product, idx),
      });
      this.setAvailability(idx);
    }
  }

  setAvailability(pvIdx) {
    let pickupAvail = "Same day";
    let deliveryAvail = "Next day";
    const pv = this.state.product.pvList[pvIdx];
    let forSale = pv.forSale;
    let zeroQuantity = false;

    if (this.state.product.useQuantity && pv.unitsInStock <= 0) {
      forSale = false;
      zeroQuantity = true;
    }
    if (pv.inStockTime && pv.inStockTime > 0) {
      if (pv.inStockTime === 1) {
        pickupAvail = "Next day";
        deliveryAvail = "In two days";
      } else {
        pickupAvail = "In " + pv.inStockTime + " days";
        deliveryAvail = "In " + (pv.inStockTime + 1) + " days";
      }
    }
    this.setState({
      pickupAvail: pickupAvail,
      deliveryAvail: deliveryAvail,
      forSale,
      zeroQuantity,
    });
  }

  fetchData() {
    const url = apiBaseUrl() + "GetProduct";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }

    this.adjustScreenSize();
    window.addEventListener("resize", this.adjustScreenSize);
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.match.params.id !== prevProps.match.params.id ||
      this.props.match.params.sku !== prevProps.match.params.sku
    ) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustScreenSize);
  }

  adjustScreenSize() {
    this.updateImageWidth(null);
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }

  updateImageWidth(imageInfo) {
    let imageWidth =
      this.state.mobileMode || isMobileMode()
        ? getGroupWrapperWidth()
        : (getGroupWrapperWidth() * 60) / 100;

    if (imageInfo && this.state.product.imageSetting === 0) {
      const nativeWidth = imageInfo.width;
      if (nativeWidth > 0 && imageWidth > nativeWidth) {
        imageWidth = nativeWidth;
      }
    }
    this.setState({ imageWidth });
  }

  handleChange(event) {
    if (event.target.name === "quantity") {
      const quantity = event.target.value;
      const qtyBtnDisabled = quantity && quantity.length > 0 ? false : true;
      this.setState({ quantity, qtyBtnDisabled });
    } else {
      const idx = event.target.value;
      this.selectVariant(idx);
    }
  }
  getDisplayName(product, skuIdx) {
    const pv = product.pvList.length > 0 ? product.pvList[skuIdx] : null;
    let displayName = product.name;

    if (pv) {
      if (pv.symLink) displayName = pv.variantValue;
      else {
        if (product.pvList.length > 1) {
          if (pv.fullName && pv.fullName.length > 0) {
            displayName = pv.fullName;
          } else {
            displayName += " - " + pv.variantValue;
          }
        }
      }
    }
    return displayName;
  }
  selectVariant(idx) {
    const pv = this.state.product.pvList[idx];
    this.setState({
      sku: pv.sku,
      skuIdx: idx,
      displayName: this.getDisplayName(this.state.product, idx),
    });
    this.updateImageWidth(pv.imageInfo);
    this.setAvailability(idx);
  }

  handleClick() {
    const url = apiBaseUrl() + "MarkProdVariant";
    const pv = this.state.product.pvList[this.state.skuIdx];
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      sku: pv.sku,
      forSale: !pv.forSale,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updateQuantity(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdatePVQuantity";
    const pv = this.state.product.pvList[this.state.skuIdx];
    const newPv = {
      ...pv,
      unitsInStock: this.state.quantity,
    };
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: newPv,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  priceRange() {
    if (this.state.product.lowPriceVar === this.state.product.highPriceVar)
      return "";
    if (this.state.product.variantDisplay === 4) return "";
    return (
      <div>
        Price Range:
        <font color={priceColor()}>
          <FormatCurrency amount={this.state.product.lowPriceVar} /> -
          <FormatCurrency amount={this.state.product.highPriceVar} />
        </font>
        <p />
      </div>
    );
  }

  variantInfo() {
    return (
      <VariantDisplay
        product={this.state.product}
        vdMode={this.state.product.variantDisplay}
        callbackFunc={this.selectVariant}
      />
    );
  }

  pickupDeliveryInfo() {
    if (this.state.forSale) {
      return (
        <div className="prod-tbl-container2">
          <div className="prod-tbl-item-left">Available for Pickup:</div>
          <div className="prod-tbl-item-left">
            <font color="green">{this.state.pickupAvail}</font>
          </div>
          <div className="prod-tbl-item-left">Available for Delivery:</div>
          <div className="prod-tbl-item-left">
            <font color="green">{this.state.deliveryAvail}</font>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <img src={getSoldoutIcon()} height="70" alt="Soldout"></img>
          <br />
          This product is temporarily sold out, please check back later.
        </div>
      );
    }
  }
  showImage() {
    let imageList;
    const pv = this.state.product.pvList[this.state.skuIdx];
    let iiList = pv.imageInfoList;

    if (!iiList && !pv.imageUrl) {
      iiList = this.state.product.imageInfoList;
    }

    if (iiList && iiList.length > 1) {
      imageList = iiList.map((info) => info.url);
    } else {
      const imgUrl = pv.imageUrl ? pv.imageUrl : this.state.product.imageUrl;
      imageList = [imgUrl];
    }
    return (
      <ProductImage
        imageList={imageList}
        imageWidth={this.state.imageWidth}
        displayName={this.state.displayName}
        showModalImage={true}
      />
    );
  }
  editCombo() {
    const url =
      "/editCombo/" + this.state.product.comboId + "/" + this.state.product.id;
    this.props.history.push(url);
  }
  viewCombo() {
    const url = "/combo/" + this.state.product.comboId;
    this.props.history.push(url);
  }
  showBTorCombo(uniqueKey) {
    if (this.state.product.comboId && this.state.product.comboId.length > 0) {
      const btnTitle = this.state.product.comboExists
        ? "Update Combo"
        : "Configure Combo";

      return (
        <div className="generic-flex">
          <div>
            <button
              name="update"
              onClick={this.editCombo}
              className="btn-style"
            >
              {btnTitle}
            </button>
          </div>
          {this.state.product.comboExists && (
            <div className="left-10">
              <button
                name="view"
                onClick={this.viewCombo}
                className="btn-style"
              >
                View Combo
              </button>
            </div>
          )}
        </div>
      );
    } else {
      return <BuyTogether uniqueKey={uniqueKey} />;
    }
  }
  addManuals(event) {
    const pvList = this.state.product.pvList;
    let url = "/manageImgMan/M_" + this.state.product.id;
    if (pvList && pvList.length > 1) {
      //Multi-variant
      url += "/" + pvList[this.state.skuIdx].uniqueKey;
    }
    this.props.history.push(url);
  }
  showAssemblyManual() {
    let manualList =
      this.state.product.pvList[this.state.skuIdx].installManualList;

    if (!manualList || manualList.length === 0)
      manualList = this.state.product.installManualList;
    if (manualList && manualList.length > 0) {
      return (
        <div className="generic-flex">
          <div>
            <b>Assembly Manual:&nbsp;&nbsp;</b>
          </div>
          {manualList.map((item, idx) => {
            const manual = item.url;
            const manualUrl = manualBaseUrl() + manual;
            const pos = manual.lastIndexOf(".");
            let iconUrl = null;
            if (pos > 0) {
              const ext = manual.substring(pos);
              if (ext.toUpperCase() === ".PDF") {
                iconUrl = getIconBaseUrl() + "pdf.jpg";
              }
            }
            if (!iconUrl) iconUrl = manualUrl;

            return (
              <div className="left-10" key={idx}>
                <a href={manualUrl} target="Manual">
                  <img src={iconUrl} height="20"></img>
                </a>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <button
            className="green-btn-style"
            name="addMan"
            onClick={this.addManuals}
          >
            Add Assembly Manuals
          </button>
        </div>
      );
    }
  }
  updateSku() {
    const pv = this.state.product.pvList[this.state.skuIdx];
    if (pv.origProductId > 0) {
      this.props.history.push("/editProduct/" + pv.origProductId);
    } else {
      this.props.history.push("/editVariant/" + pv.id);
    }
  }
  editQuantForm() {
    const btnStyle = this.state.qtyBtnDisabled
      ? "small-btn2-disabled"
      : "small-btn2";
    return (
      <div>
        <input
          type="text"
          size="5"
          name="quantity"
          onChange={this.handleChange}
        ></input>
        &nbsp;&nbsp;
        <button
          className={btnStyle}
          name="updateQuant"
          onClick={this.updateQuantity}
        >
          Update Quantity
        </button>
        &nbsp;(Current: 0)
      </div>
    );
  }
  showDetail() {
    const prod = this.state.product;
    let btnTitle = null;
    let btnStyle = null;

    if (this.state.editAllowed) {
      if (this.state.forSale) {
        btnTitle = "Mark Sold Out";
        btnStyle = "red-btn-style";
      } else {
        if (!this.state.zeroQuantity) {
          btnTitle = "Mark For Sale";
          btnStyle = "btn-style";
        }
      }
    }

    const pv = prod.pvList[this.state.skuIdx];
    const uniqueKey = pv.uniqueKey;
    const desc =
      pv.useDesc && pv.description && pv.description.length > 0
        ? pv.description
        : prod.description;
    const showTariff = pv.finalPrice - pv.salePrice > 1.0 ? true : false;
    return (
      <div className="prod-wrapper">
        <div className="prod-detail-container">
          <div>
            <div className="prod-detail-image">{this.showImage()}</div>
            {!this.state.mobileMode && this.showBTorCombo(uniqueKey)}
          </div>

          <div className="prod-detail-info" align="left">
            <b>{this.state.displayName} </b>
            <p />
            Sales Price:&nbsp;&nbsp;
            <b>
              <font color={priceColor()}>
                <FormatCurrency amount={pv.finalPrice} />
              </font>
            </b>
            <br />
            {showTariff && (
              <label>
                (Before Tariff: <FormatCurrency amount={pv.salePrice} />)
              </label>
            )}
            <p />
            <div
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            ></div>
            <p />
            {pv.dimension && (
              <span>
                Dimension: {pv.dimension}
                <p />
              </span>
            )}
            <b>SKU: </b> {pv.sku}{" "}
            {prod.pvList.length > 1 && (
              <button
                className="small-btn2"
                name="update"
                onClick={this.updateSku}
              >
                Update
              </button>
            )}
            <br />
            {this.priceRange()}
            <br />
            {this.variantInfo()}
            <p />
            {btnTitle && (
              <button
                key="markProd"
                onClick={this.handleClick}
                className={btnStyle}
              >
                {btnTitle}
              </button>
            )}
            {!btnTitle && this.state.zeroQuantity && this.editQuantForm()}
            <p />
            {this.pickupDeliveryInfo()}
            {this.showAssemblyManual()}
            <p />
            <hr width="200" align="left"></hr>
            <div className="form-wrapper">
              <div className="form-item-label">Product ID: </div>
              <div className="form-item-value">{prod.id}</div>
              <div className="form-item-label">Quantity: </div>
              <div className="form-item-value">{pv.unitsInStock}</div>
            </div>
            {pv.priceHistory && pv.priceHistory.length > 0 && (
              <PHWidget
                phList={pv.priceHistory}
                showSku={false}
                mobileMode={this.state.mobileMode}
                showExpCol={true}
              />
            )}
            {this.state.mobileMode && (
              <React.Fragment>
                <p />
                {this.showBTorCombo(uniqueKey)}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  updateProduct() {
    const url = "/editProduct/" + this.state.product.id;
    this.props.history.push(url);
  }
  addVariant() {
    const url = "/editVariant/+/" + this.state.product.id;
    this.props.history.push(url);
  }

  addSymLink() {
    const url = "/addSymLink/" + this.state.product.id;
    this.props.history.push(url);
  }

  render() {
    if (this.state.product === null) return <Working size={80} />;

    const btnStyle =
      this.state.addVariantDisabled === true
        ? "disabled-btn-style"
        : "btn-style";
    return (
      <div className="top-container">
        <div className="top-wrapper">
          <div>
            <font size="5" color="darkgreen">
              Preview
            </font>
          </div>
          {this.showDetail()}
          {this.state.editAllowed && (
            <div className="generic-flex">
              <div>
                <button
                  name="update"
                  onClick={this.updateProduct}
                  className="btn-style"
                >
                  Modify
                </button>
              </div>
              <div className="left-5">
                <button
                  name="addVariant"
                  onClick={this.addVariant}
                  className={btnStyle}
                  disabled={this.state.addVariantDisabled}
                >
                  Add Variant
                </button>
              </div>
              {!this.state.addVariantDisabled && (
                <div className="left-5">
                  <button
                    name="continue"
                    onClick={this.addSymLink}
                    className="btn-style"
                  >
                    Add SymLink
                  </button>
                </div>
              )}
            </div>
          )}

          {this.state.addVariantDisabled === true && (
            <div>
              <p />
              If you would like to add a variant to this product, you need to do
              the following: <br />
              <ul>
                <li>Click the "Modify" button</li>
                <li>Enter values in the "Variant Name" and "Variant" fields</li>
                <li>Click "Update" button</li>
                <li>Then, you will be back to this page to add variant</li>
              </ul>
            </div>
          )}
          <p />
          <RelatedProducts prodId={this.state.product.id} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    categoryId: state.search.categoryId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductPreview);
