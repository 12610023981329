import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toAmount,
  getIconBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
  isEmptyStr,
  toDateStr,
  getOrderBalance,
  getItemCount,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import InvoiceLinkWidget from "../store/InvoiceLinkWidget";
import OrderUpdator from "../store/OrderUpdator";
import AddrUpdateForm from "../store/AddrUpdateForm";
import ShippingUpdator from "../store/ShippingUpdator";
import OrderNotes from "../store/OrderNotes";
import OrderActivityLog from "../store/OrderActivityLog";
import PrintOrder from "../store/PrintOrder";
import MarkPaid from "../store/MarkPaid";
import AddPayment from "../store/AddPayment";
import CommonOrderDetail from "../store/CommonOrderDetail";
import ReturnedInfo from "../store/ReturnedInfo";
import ActionConfirmation from "../ActionConfirmation";
import Working from "../Working";
import LeaseActionWidget from "../progressive/LeaseActionWidget";
import DatWidget from "../store/DatWidget";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

const RETURNED_STATUS = 9;
const CANCELLED_STATUS = 8;

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      isLoading: true,
      errorMessage: null,
      orderStatus: 0,
      updateBtnDisabled: true,
      scheduledDate: null,
      note: "",
      updateOrderMode: false,
      mobileMode: false,
      editAddrMode: false,
      statusDate: null,
      maxStatusDate: null,
      leaseCancelled: false,
      editAllowed: checkUpdate("Order"),
      viewAllowed: checkView("Order"),
    };
    this.fetchOrder = this.fetchOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.ackOrder = this.ackOrder.bind(this);
    this.editButtonClicked = this.editButtonClicked.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.noteAdded = this.noteAdded.bind(this);
    this.updateDatePaid = this.updateDatePaid.bind(this);
    this.addPayment = this.addPayment.bind(this);
    this.changeToReturned = this.changeToReturned.bind(this);
    this.gotoReturnItems = this.gotoReturnItems.bind(this);
    this.leaseCancelled = this.leaseCancelled.bind(this);
    this.deliveryConfirmSubmitted = this.deliveryConfirmSubmitted.bind(this);
  }
  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    const today = toDateStr(new Date());
    this.setState({
      statusDate: today,
      maxStatusDate: today,
      leaseDeliveryDate: today,
    });
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchOrder();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response, afterUpdate) {
    if (response && response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const order = response.data;
      if (order) {
        const shipping = order.summary.delivery ? 2 : 1;
        let scheduledDate = null;
        let statusDate = this.state.statusDate;

        if (order.summary.delivery) {
          if (order.summary.schDateStr) {
            const date = new Date(order.summary.schDateStr);
            const now = new Date();
            const delta = (date.getTime() - now.getTime()) / 3600000;

            if (delta > -24) {
              scheduledDate = date.toISOString().split("T")[0];
            } else {
              scheduledDate = order.summary.schDateStr;
            }
            try {
              statusDate = toDateStr(date);
            } catch (error) {}
          }
        }
        if (afterUpdate && order.summary.leaseId > 0) {
          if (order.commonOrderInfo && !order.commonOrderInfo.leaseInfo) {
            if (
              this.state.order &&
              this.state.order.commonOrderInfo &&
              this.state.order.commonOrderInfo.leaseInfo
            ) {
              const commonOrderInfo = {
                ...order.commonOrderInfo,
                leaseInfo: this.state.order.commonOrderInfo.leaseInfo,
              };
              order = {
                ...order,
                commonOrderInfo,
              };
            }
          }
        }
        this.setState({
          order,
          scheduledDate,
          statusDate,
          shipping,
          origShipping: shipping,
          isLoading: false,
        });
      } else {
        if (!afterUpdate) {
          this.setState({
            errorMessage: "Order not found!!",
            isLoading: false,
          });
        }
      }
    }
  }
  fetchOrder() {
    const url = apiBaseUrl() + "GetOrder";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.props.match.params.orderNo,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  editButtonClicked(name) {
    this.setState({ [name]: true });
  }
  updateOrder(order) {
    const url = apiBaseUrl() + "UpdateOrder";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: order,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ order: res.data.data, updateOrderMode: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  shippingInfo() {
    const ordsum = this.state.order.summary;
    if (ordsum.statusId < 20) {
      let isContactAddr = false;
      let address = ordsum.delivery
        ? this.state.order.deliveryAddress
        : this.state.order.contactAddress;
      let pickupLoc = null;

      if (ordsum.delivery) {
        if (!this.state.order.deliveryAddress || !ordsum.schDateStr) {
          address = this.state.order.contactAddress;
          isContactAddr = true;
        }
      } else {
        isContactAddr = true;
        if (this.state.order.pickupLocation) {
          const locInfo = this.state.order.pickupLocation.locationInfo;
          if (locInfo.isWarehouse || locInfo.warehouse) pickupLoc = "Warehouse";
          else pickupLoc = locInfo.shortName;
        }
      }
      const scheduledDate = ordsum.scheduledDate
        ? toDateStr(new Date(ordsum.scheduledDate))
        : null;
      const info = {
        orderNumber: ordsum.orderNumber,
        delivery: ordsum.delivery,
        deliveryInstr: ordsum.deliveryInstr,
        orderSubtotal: ordsum.subtotal,
        scheduledDate,
        address,
        isContactAddr,
        pickupLoc,
      };
      return <ShippingUpdator info={info} callback={this.processResponse} />;
    } else {
      if (ordsum.delivery) {
        return (
          <div>
            <b>Shipping: </b> Delivery ({ordsum.schDateStr})
            <p />
          </div>
        );
      } else {
        return (
          <div>
            <b>Shipping: </b> Pickup
            <p />
          </div>
        );
      }
    }
  }
  showAddress(addr, showEmail) {
    let html = addr.firstName + " " + addr.lastName + "<br/>";
    html += addr.addressLine1 + "<br/>";
    if (addr.addressLine2) html += addr.addressLine2 + "<br/>";

    if (
      (!isEmptyStr(addr.city) || !isEmptyStr(addr.zipCode)) &&
      addr.city != "Unknown"
    ) {
      html += addr.city + ", " + addr.state + " " + addr.zipCode + "<br/>";
    }

    if (addr.phone) {
      html += addr.phone;
      if (addr.mobilePhone) {
        html += "/" + addr.mobilePhone;
      }
      html += "<br/>";
    }
    if (addr.email && showEmail) html += addr.email + "<br/>";

    return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
  }

  isEmptyAddress(addr) {
    if (addr) {
      if (
        !isEmptyStr(addr.firstName) ||
        !isEmptyStr(addr.lastName) ||
        !isEmptyStr(addr.addressLine1)
      ) {
        return false;
      }
    }
    return true;
  }
  showPickupLoc() {
    if (this.state.order.pickupLocation) {
      const info = this.state.order.pickupLocation.locationInfo;
      return (
        <div>
          {info.description}
          <br />
          {info.addressLine1}
          <br />
          {info.city}, {info.state} {info.zipCode}
          <br />
          {info.phone}
        </div>
      );
    }
  }

  showNotifications() {
    if (this.state.order.notifications) {
      const clsName = this.state.mobileMode
        ? "threecol-wrapper"
        : "fourcol-wrapper";
      return (
        <div>
          <p />
          <b>Notifications:</b>
          <br />
          <div className={clsName}>
            {this.state.order.notifications.map((noti, idx) => {
              const recType = noti.recipientType === 1 ? "Customer" : "Store";
              let img = noti.sent
                ? getIconBaseUrl() + "email.jpg"
                : getIconBaseUrl() + "cross.png";
              const url = "/notiDetail/" + noti.id;
              if (noti.sent && noti.typeName === "DeliverySMS")
                img = getIconBaseUrl() + "sms.jpg";
              return (
                <React.Fragment key={idx}>
                  <div>
                    <img src={img} alt="Status" width="15"></img>
                  </div>
                  <div className="left-10">
                    {noti.typeName} ({recType})
                  </div>
                  {!this.state.mobileMode && (
                    <div className="left-10">{noti.dateStr}</div>
                  )}
                  <div className="left-10">
                    <Link to={url}>{noti.toEmail}</Link>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    }
  }
  showDeliveryOrPickupInfo() {
    if (this.state.order.summary.delivery) {
      return (
        <div>
          <p />
          <b>Delivery Information:</b>
          <br />
          {this.showAddress(
            this.state.order.deliveryAddress,
            !this.state.mobileMode
          )}
        </div>
      );
    } else {
      return (
        <div>
          <p />
          <b>Pickup Location:</b>
          <br />
          {this.showPickupLoc()}
        </div>
      );
    }
  }
  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      this.setState({ [name]: event.target.checked });
    } else if (name === "shipping") {
      const shipping = parseInt(event.target.value);
      this.setState({ shipping });
    } else {
      let updateBtnDisabled = false;
      if (this.state.order) {
        if (this.state.order.summary.statusId == event.target.value) {
          updateBtnDisabled = true;
        } else if (event.target.value == RETURNED_STATUS) {
          const order = this.state.order.summary;
          if (order.storeId <= 0 && order.statusId < CANCELLED_STATUS) {
            document.getElementById("hiddenConfirmRtnBtn").click();
          }
          return;
        }
      }
      this.setState({
        [name]: event.target.value,
        updateBtnDisabled,
      });
    }
  }

  updateStatus() {
    const url = apiBaseUrl() + "UpdateOrderStatus";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objectId: this.state.order.summary.id,
      statusId: this.state.orderStatus,
      statusDate: this.state.statusDate,
      note: this.state.note,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            window.location.reload();
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              message: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  leaseCancelled() {
    this.setState({ leaseCancelled: true });
  }
  deliveryConfirmSubmitted(progressiveStatus) {
    const commonOrderInfo = {
      ...this.state.order.commonOrderInfo,
      progressiveStatus,
    };
    const order = {
      ...this.state.order,
      commonOrderInfo,
    };
    this.setState({ order });
  }

  updateAddress(address, doUpdate) {
    if (doUpdate) {
      const url = apiBaseUrl() + "UpdateOrderAddress";
      const data = {
        ...address,
        orderNumber: this.state.order.summary.orderNumber,
      };
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        data,
      };
      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          if (res.data && res.data.invalidSession) {
            this.props.setLoggedOut();
            this.props.history.push("/login");
          } else {
            let order = res.data.data;
            if (!order) {
              order = {
                ...this.state.order,
                contactAddress: address,
              };
            }
            this.setState({ order, editAddrMode: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ editAddrMode: false });
    }
  }
  ackOrder() {
    const url = apiBaseUrl() + "AckOrder";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.state.order.summary.orderNumber,
      flag: false,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            this.props.history.push("/");
          } else {
            this.setState({
              errorMessage: "Failed to mark acknowledged",
              message: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  addPayment(payment) {
    const data = {
      ...payment,
      transactionType: payment.typeId,
      number: payment.last4,
      name: payment.nameOnCard,
      orderNumber: this.state.order.summary.orderNumber,
      userId: this.props.userId,
    };
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };
    const url = apiBaseUrl() + "AddPayment";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ order: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showOrderStatus() {
    const order = this.state.order;

    if (!order || !order.orderStatus) return "";

    const delvPic =
      this.state.orderStatus == 5 || this.state.orderStatus == 6 ? true : false;
    const placeholder = delvPic ? "Note" : "Reason for status change";

    if (
      order.orderStatus &&
      (order.orderStatus.final || order.orderStatus.isFinal)
    ) {
      return (
        <div className="flex-wrapper">
          <div>Order Status: </div>
          <div className="left-10">
            <b>{order.orderStatus.status}</b>
          </div>
        </div>
      );
    } else {
      if (this.state.editAllowed) {
        const btnStyle = this.state.updateBtnDisabled
          ? "disabled-small-btn"
          : "small-btn";
        return (
          <div>
            <div className="flex-wrapper">
              <div>Order Status: </div>
              <div className="left-10">
                <select
                  name="orderStatus"
                  value={this.state.orderStatus}
                  onChange={this.handleChange}
                >
                  <option value={order.orderStatus.id}>
                    {order.orderStatus.status}
                  </option>
                  {order.allowedList &&
                    order.allowedList.map((status) => {
                      return (
                        <option value={status.id} key={status.id}>
                          {status.status}
                        </option>
                      );
                    })}
                </select>
              </div>
              {this.state.updateBtnDisabled === false && delvPic && (
                <div className="left-10">
                  <input
                    type="date"
                    name="statusDate"
                    value={this.state.statusDate}
                    max={this.state.maxStatusDate}
                    onChange={this.handleChange}
                  />
                </div>
              )}
              {this.state.updateBtnDisabled === false &&
                !this.state.mobileMode && (
                  <div className="left-10">
                    <input
                      name="note"
                      size="20"
                      value={this.state.note}
                      placeholder={placeholder}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                )}
              <div className="left-10">
                <button
                  name="updateStatus"
                  className={btnStyle}
                  onClick={this.updateStatus}
                  disabled={this.state.updateBtnDisabled}
                >
                  Update Status
                </button>
              </div>
            </div>
            {this.state.updateBtnDisabled === false &&
              this.state.mobileMode && (
                <div>
                  <input
                    name="note"
                    size="20"
                    value={this.state.note}
                    placeholder="Reason for status change"
                    onChange={this.handleChange}
                  ></input>
                </div>
              )}
          </div>
        );
      } else {
        const statusStr = order.orderStatus
          ? order.orderStatus.status
          : "Unknown";
        return <div>Order Status: {statusStr}</div>;
      }
    }
  }

  showOrderActivities() {
    return (
      <OrderActivityLog
        activityList={this.state.order.orderActivities}
        isLayaway={false}
        mobileMode={this.state.mobileMode}
      />
    );
  }
  getPaymentIcon(status) {
    let url = getIconBaseUrl();
    if (status == "Processed") {
      url += "pmtAccepted.png";
    } else if (status === "Rejected") url += "pmtRejected.jpg";
    else if (status === "Cancelled") url += "cancelled.jpg";
    else url += "creditCard.png";
    return url;
  }
  showPayments() {
    if (this.state.order.payments && this.state.order.payments.length > 0) {
      return (
        <div>
          <p />
          <b>Payments</b>
          <br />
          {this.state.order.payments.map((pmt) => {
            const url = "/paymentDetail/" + pmt.id;
            let last4 = pmt.typeId == 1 ? "Cash" : "Credit";

            if (pmt.number && pmt.number.length >= 4) {
              const len = pmt.number.length;
              last4 = "***" + pmt.number.substring(len - 4);
            }

            return (
              <div className="flex-wrapper" key={pmt.id}>
                <div>
                  <img
                    src={this.getPaymentIcon(pmt.status)}
                    alt="Status"
                    width="24"
                  ></img>
                </div>
                <div className="left-10">{pmt.dateStr}</div>
                <div className="left-10">{pmt.paymentName}</div>
                {pmt.amount < 0 ? (
                  <div className="left-10">
                    <font color="red">{toAmount(pmt.amount)}</font>
                  </div>
                ) : (
                  <div className="left-10">{toAmount(pmt.amount)}</div>
                )}

                <div className="left-10">
                  <Link to={url}>{last4}</Link>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p />
          <b>Payments</b>
          <br />
          No online payment information found.
        </div>
      );
    }
  }
  orderSummary() {
    const order = this.state.order.summary;
    const progressiveStatus =
      this.state.order.commonOrderInfo.progressiveStatus;
    let salesperson = null;
    if (order.storeId > 0 && order.storeName) {
      if (order.kiosk) salesperson = "KIOSK";
      else salesperson = order.salesperson;
    }
    const shipping = order.delivery ? "Delivery" : "Pickup";
    let deliveryDate = null;
    let color = null;
    if (order.delivery) {
      deliveryDate = order.schDateStr ? order.schDateStr : "Will call";
      if (!order.schDateStr) color = "red";
    }
    let dateDelivered = null;
    if (order.dateDelivered) {
      try {
        dateDelivered = toDateStr(new Date(order.dateDelivered));
      } catch (error) {}
    }

    return (
      <div className="otv-2col-form">
        <div>Date:</div>
        <div>{order.dateStr}</div>

        {salesperson && (
          <React.Fragment>
            <div>Store Name:</div>
            <div>{order.storeName}</div>
            <div>Salesperson:</div>
            <div>
              <b>{salesperson}</b>
            </div>
          </React.Fragment>
        )}
        <div>Order Total:</div>
        <div>{toAmount(order.totalPrice)}</div>
        <div>Shipping:</div>
        <div>{shipping}</div>
        {deliveryDate && (
          <React.Fragment>
            <div>Scheduled:</div>
            {color ? (
              <div>
                <font color={color}>{deliveryDate}</font>
              </div>
            ) : (
              <div>{deliveryDate}</div>
            )}
          </React.Fragment>
        )}
        {dateDelivered && (
          <React.Fragment>
            <div>
              {order.delivery ? (
                <label>Delivered:</label>
              ) : (
                <label>Picked Up:</label>
              )}
            </div>
            <div>
              <font color="green">{dateDelivered}</font>
            </div>
          </React.Fragment>
        )}
        {order.paidDateStr && (
          <React.Fragment>
            <div>Date Paid:</div>
            <div>
              <font color="green">{order.paidDateStr}</font>
            </div>
          </React.Fragment>
        )}
        {order.leaseToOwn && this.showProgressiveInfo(progressiveStatus)}
      </div>
    );
  }

  showProgressiveInfo(info) {
    if (info) {
      let status = info.funded ? (
        <font color="green">FUNDED</font>
      ) : (
        info.status
      );
      const okToDeliver = info.okToDeliver ? (
        <font color="blue">Yes</font>
      ) : (
        "No"
      );
      if (this.state.leaseCancelled) {
        status = <font color="red">Cancelled</font>;
      }
      return (
        <React.Fragment>
          <div>Lease To Own:</div>
          <div>Progressive</div>
          <div>Lease ID:</div>
          <div>{info.leaseId}</div>
          <div>Status:</div>
          <div>{status}</div>
          {!info.funded && !this.state.leaseCancelled && (
            <React.Fragment>
              <div>OkToDeliver:</div>
              <div>{okToDeliver}</div>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div>Lease To Own:</div>
          <div>Progressive</div>
        </React.Fragment>
      );
    }
  }
  updateDatePaid(datePaid) {
    try {
      const summary = {
        ...this.state.order.summary,
        datePaid,
        paidDateStr: toDateStr(new Date(datePaid)),
      };
      const order = {
        ...this.state.order,
        summary,
      };
      this.setState({ order });
    } catch (error) {}
  }
  editAddrForm() {
    const clsName = this.state.mobileMode ? "top-10" : "left-30";
    return (
      <div className={clsName}>
        <AddrUpdateForm
          address={this.state.order.contactAddress}
          addrRequired={this.state.order.summary.delivery}
          callback={this.updateAddress}
        />
      </div>
    );
  }

  header() {
    const ordWrp = this.state.order;
    const title = "Order: " + ordWrp.summary.orderNumber;
    const coi = this.state.order ? this.state.order.commonOrderInfo : null;
    const pending = false;
    const tblCls = this.state.mobileMode ? "none" : "generic-flex";
    const col2Cls = this.state.mobileMode ? "top-10" : "left-30";
    const showActWidget =
      ordWrp.orderStatus.status === "Cancelled" &&
      !checkSecurity("RootFeatures")
        ? false
        : true;

    return (
      <div>
        <div className="generic-flex">
          <div>
            <font size="5">{title}</font>
          </div>
          {pending && ordWrp.summary.storeId <= 0 && (
            <div className="left-20">
              <button className="btn-style" name="Ack" onClick={this.ackOrder}>
                Acknowledge
              </button>
            </div>
          )}
        </div>

        <br />
        <div className={tblCls}>
          {this.orderSummary()}
          {this.state.editAddrMode ? (
            this.editAddrForm()
          ) : (
            <div className={col2Cls}>
              <i>Customer Information:</i>
              <br />
              {this.showAddress(ordWrp.contactAddress, true)}
              <button
                className="small-btn3"
                name="editAddress"
                onClick={() => this.editButtonClicked("editAddrMode")}
              >
                Edit
              </button>
            </div>
          )}
        </div>
        {showActWidget && (
          <LeaseActionWidget
            mobileMode={this.state.mobileMode}
            coi={coi}
            cancelledHandler={this.leaseCancelled}
            deliveryConfirmedHandler={this.deliveryConfirmSubmitted}
          />
        )}
        <hr className="hr-300" align="left"></hr>
      </div>
    );
  }
  showReturnButton() {
    if (
      !this.state.updateOrderMode &&
      this.state.order.commonOrderInfo.returnAllowed
    )
      return true;
    else return false;
  }
  gotoReturnItems() {
    const url = "/returnItems/" + this.state.order.summary.orderNumber;
    this.props.history.push(url);
  }
  orderDetailSect() {
    const itemCnt = getItemCount(this.state.order.commonOrderInfo);

    if (itemCnt > 0) {
      const coi = this.state.order.commonOrderInfo;
      const plAllowed =
        !coi.progressiveSetting || coi.progressiveSetting.modifyOrderAllowed
          ? true
          : false;
      const order = this.state.order.summary;
      const showReturn = this.showReturnButton();
      const clsName = showReturn ? "flex-wrapper-b10" : "flex-wrapper";
      let updModeDisabled = coi.editAllowed && plAllowed ? false : true;
      return (
        <React.Fragment>
          {order.statusId < 20 && (
            <div className={clsName}>
              <div>
                <input
                  type="checkbox"
                  name="CB_updateOrderMode"
                  checked={this.state.updateOrderMode}
                  disabled={updModeDisabled}
                  onChange={this.handleChange}
                ></input>{" "}
                Update mode
              </div>
              {showReturn && (
                <div className="left-10">
                  <button
                    className="red-small-btn"
                    name="returnBtn"
                    onClick={this.gotoReturnItems}
                  >
                    Return Items
                  </button>
                </div>
              )}
            </div>
          )}
          {this.state.updateOrderMode ? (
            <OrderUpdator
              order={this.state.order}
              callback={this.updateOrder}
              allowStoreProd={true}
              showSummary={true}
            />
          ) : (
            <div>
              <CommonOrderDetail
                commonOrderInfo={this.state.order.commonOrderInfo}
                mobileMode={this.state.mobileMode}
              />
              <p />
              <ReturnedInfo
                info={this.state.order.returnedInfo}
                coi={this.state.order.commonOrderInfo}
                mobileMode={this.state.mobileMode}
              />
            </div>
          )}
        </React.Fragment>
      );
    }
    return (
      <ReturnedInfo
        info={this.state.order.returnedInfo}
        mobileMode={this.state.mobileMode}
      />
    );
  }
  noteAdded(orderNote) {
    let noteList = this.state.order.noteList;

    if (!noteList) noteList = [];
    noteList.push(orderNote);

    const order = {
      ...this.state.order,
      noteList,
    };
    this.setState({ order });
  }
  addPaymentSect() {
    const order = this.state.order.summary;
    const coi = this.state.order.commonOrderInfo;
    if (order.statusId >= 20 || order.paidDateStr) {
      //Closed or already paid
      return "";
    }
    const balance = getOrderBalance(coi);
    if (balance < 1.0) return ""; //zero balance
    return (
      <AddPayment
        balance={balance}
        dateOrdered={order.dateOrdered}
        callback={this.addPayment}
      />
    );
  }
  showOrderPdfSect() {
    const ordWrp = this.state.order;
    const sendPdfEnabled = this.state.viewAllowed;

    if (ordWrp.commonOrderInfo) {
      return (
        <PrintOrder
          order={ordWrp.commonOrderInfo}
          sendPdfEnabled={sendPdfEnabled}
        />
      );
    } else {
      return (
        <PrintOrder
          orderNumber={ordWrp.summary.orderNumber}
          sendPdfEnabled={sendPdfEnabled}
        />
      );
    }
  }
  showDatSection() {
    if (
      this.state.order &&
      this.state.order.summary &&
      this.state.order.summary.leaseId > 0
    ) {
      const order = this.state.order.summary;
      const orderDatFile = order.invoiceNumber ? null : order.orderDatFile;
      return (
        <DatWidget
          orderNumber={order.orderNumber}
          orderDatFile={orderDatFile}
          invoiceDatFile={order.invoiceDatFile}
        />
      );
    }
  }
  changeToReturned() {
    this.setState({
      orderStatus: RETURNED_STATUS,
      updateBtnDisabled: false,
    });
  }

  rtnConfirmSect() {
    const order = this.state.order.summary;
    if (order) {
      let msg = "Are you sure you want to change status to Returned? ";
      msg += order.delivery
        ? "Delivery fee will not be refunded."
        : "Deposit will not be refunded.";
      return (
        <ActionConfirmation
          hidden={true}
          btnId="hiddenConfirmRtnBtn"
          actionHandler={this.changeToReturned}
          buttonTitle="Hidden Delete"
          buttonClsName="red-btn-style"
          title="Change Status Confirmation"
          message={msg}
        />
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Working />;
    }

    if (!this.state.order)
      return (
        <div>
          <font color="red">Order not found!</font>
        </div>
      );

    const ordWrp = this.state.order;
    const closed = ordWrp && ordWrp.summary.statusId >= 20 ? true : false;
    const editUrl = closed ? null : ordWrp.editDatUrl;
    const showLegacy = this.state.order.commonOrderInfo.showLegacyLinks;
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div>
            {this.header()}
            {this.showOrderStatus()}
            {!ordWrp.summary.paidDateStr && ordWrp.summary.statusId < 7 && (
              <MarkPaid
                order={ordWrp.summary}
                isLayaway={false}
                callback={this.updateDatePaid}
              />
            )}
            <font color="red">
              {this.state.errorMessage}
              <br />
            </font>
            {this.showOrderPdfSect()}
          </div>
          {this.showDatSection()}
          {this.shippingInfo()}
          {this.orderDetailSect()}
          {this.addPaymentSect()}
          <OrderNotes
            orderNumber={ordWrp.summary.orderNumber}
            noteList={ordWrp.noteList}
            layaway={false}
            editDisabled={closed}
            callback={this.noteAdded}
          />
          {this.showOrderActivities()}
          {showLegacy && (
            <InvoiceLinkWidget
              invoicePrintUrl={ordWrp.printDatUrl}
              invoicePdfUrl={ordWrp.pdfDatUrl}
              invoiceEditUrl={editUrl}
              showTitle={true}
            />
          )}
          {this.showNotifications()}
          {this.showPayments()}
          {this.rtnConfirmSect()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
