import React from "react";
import {
  toAmount,
  setTopContainerWrapperSettings,
  isMobileMode,
  getIconBaseUrl,
} from "../Util";
import "../../App.css";
import "../../search.css";
import "../../generic.css";
import "../../ordtbl.css";

class OrderTableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMode: false,
      orderList: this.props.orderList,
      sortOrder: 0,
      sortColumn: 2,
      orderFldsList: null,
      title: this.props.title,
      maxPerPage: this.props.maxPerPage ? this.props.maxPerPage : 500,
      pageIdx: 0,
      orderFilter: 0, //0 -- no filter, 1 -- paid only, 2 -- unpaid only
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.setFieldLists = this.setFieldLists.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pageNumClicked = this.pageNumClicked.bind(this);
  }
  componentDidMount() {
    this.setFieldLists(this.props.orderList);
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }
  componentDidUpdate(prevProps) {
    if (this.props.orderList !== prevProps.orderList) {
      this.setState({ orderList: this.props.orderList });
      this.setFieldLists(this.props.orderList);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  getName(order) {
    let name;
    if (this.state.mobileMode) {
      name = "";
      if (order.firstName && order.firstName.length > 0) {
        name += order.firstName.substring(0, 1);
      }
      if (order.lastName && order.lastName.length > 0) {
        name += order.lastName.substring(0, 1);
      }
    } else {
      name = order.firstName + " " + order.lastName;
      if (name.length > 12) name = name.substring(0, 12);
    }
    return name;
  }
  setFieldLists(orderList) {
    let orderFldsList = [];
    if (orderList.length > 0) {
      for (let i = 0; i < orderList.length; i++) {
        const order = orderList[i];
        let statusId = order.statusId;
        let deliveryDateStr = order.deliveryDateStr;
        if (order.delivery && !order.deliveryDateStr && order.statusId < 5) {
          statusId = -1;
          deliveryDateStr = "";
        }
        if (!order.delivery) deliveryDateStr = "ZZ";
        let name = order.firstName + " " + order.lastName;
        name = name.toLowerCase();

        const fields = [
          statusId,
          order.orderNumber,
          order.timestamp,
          deliveryDateStr,
          name ? name.toUpperCase() : name,
          order.salesperson
            ? order.salesperson.toUpperCase()
            : order.salesperson,
          order.totalDeposit,
          order.depositPercent,
          order.nextDueDate,
          order,
        ];
        orderFldsList.push(fields);
      }
    }
    this.setState({ orderFldsList });
  }
  pageNumClicked(idx) {
    this.setState({ pageIdx: idx });
  }
  handleClick(colIdx) {
    let fieldsList = this.state.orderFldsList;

    if (fieldsList && fieldsList.length > 1) {
      const sortOrder = this.state.sortOrder === 0 ? 1 : 0;
      let fieldsList = this.state.orderFldsList;
      if (fieldsList && fieldsList.length > 1) {
        fieldsList = fieldsList.sort((a, b) => {
          if (colIdx === 0 || colIdx === 2) {
            let val = a[colIdx] - b[colIdx];

            if (val === 0) {
              //sub-sort by dateOrdered
              let t1 = a[9].timestamp;
              let t2 = b[9].timestamp;
              return t1 - t2;
            }

            if (sortOrder === 1) val = 0 - val;
            return val;
          } else {
            let val = 0;
            if (a[colIdx] > b[colIdx]) val = 1;
            else if (a[colIdx] < b[colIdx]) val = -1;

            if (val === 0) {
              //sub-sort by dateOrdered
              let t1 = a[9].timestamp;
              let t2 = b[9].timestamp;
              return t1 - t2;
            }
            if (sortOrder === 1) val = 0 - val;
            return val;
          }
        });
      }

      this.setState({
        orderFldsList: fieldsList,
        sortOrder,
        sortColumn: colIdx,
      });
    }
  }
  tableHeader() {
    const desktop = this.state.mobileMode ? false : true;
    const nLabel = desktop ? "Name" : "NM";
    const sortCol = this.state.sortColumn;
    const isLayaway = this.props.isLayaway;
    const sortSymbol =
      this.state.sortOrder == 1 ? (
        <font color="green">
          <b>&#8593;</b>
        </font>
      ) : (
        <font color="red">
          <b>&#8595;</b>
        </font>
      );
    const hd = "otv-hd";
    const hdl = "otv-hdlink";
    return (
      <React.Fragment>
        <div className={sortCol == 0 ? hdl : hd}>
          {sortCol == 0 && sortSymbol}
          <a href="#" onClick={() => this.handleClick(0)}>
            <b>S</b>
          </a>
        </div>
        <div className={sortCol == 1 ? hdl : hd}>
          {sortCol == 1 && sortSymbol}
          <a href="#" onClick={() => this.handleClick(1)}>
            Order#
          </a>
        </div>
        <div className={sortCol == 2 ? hdl : hd}>
          {sortCol == 2 && sortSymbol}
          <a href="#" onClick={() => this.handleClick(2)}>
            Date
          </a>
        </div>
        {desktop && !isLayaway && (
          <div className={sortCol == 3 ? hdl : hd}>
            {sortCol == 3 && sortSymbol}
            <a href="#" onClick={() => this.handleClick(3)}>
              Delivery
            </a>
          </div>
        )}
        <div className={sortCol == 4 ? hdl : hd}>
          {sortCol == 4 && sortSymbol}
          <a href="#" onClick={() => this.handleClick(4)}>
            {nLabel}
          </a>
        </div>
        <div className={hd}>Phone</div>
        {desktop && (
          <div className={sortCol == 5 ? hdl : hd}>
            {sortCol == 5 && sortSymbol}
            <a href="#" onClick={() => this.handleClick(5)}>
              Sales
            </a>
          </div>
        )}
        <div className={hd}>Amount</div>
        {isLayaway && desktop && (
          <React.Fragment>
            <div className={sortCol == 6 ? hdl : hd}>
              {sortCol == 6 && sortSymbol}
              <a href="#" onClick={() => this.handleClick(6)}>
                Deposit
              </a>
            </div>
            <div className={sortCol == 7 ? hdl : hd}>
              {sortCol == 7 && sortSymbol}
              <a href="#" onClick={() => this.handleClick(7)}>
                Percent
              </a>
            </div>
            <div className={sortCol == 8 ? hdl : hd}>
              {sortCol == 8 && sortSymbol}
              <a href="#" onClick={() => this.handleClick(8)}>
                Next Due
              </a>
            </div>
          </React.Fragment>
        )}
        {desktop && <div className={hd}>Lagacy</div>}
      </React.Fragment>
    );
  }
  oneTableRow(order, idx) {
    const isLayaway = this.props.isLayaway;
    const desktop = this.state.mobileMode ? false : true;
    const clsName = idx % 2 === 0 ? "otv-item-ev" : "otv-item-od";
    const rClsName = idx % 2 === 0 ? "otv-item-evr" : "otv-item-odr";
    const url = isLayaway
      ? "/layawayDetail/" + order.orderNumber
      : "/orderDetail/" + order.orderNumber;
    const timestamp = order.shortDtStr;
    let imgUrl = getIconBaseUrl() + order.statusIcon;
    let phone = order.phone;
    if (this.state.mobileMode && order.shortPhone) {
      phone = order.shortPhone;
    }
    let dd = "N/A";
    if (order.delivery) {
      if (order.deliveryDateStr) dd = order.deliveryDateStr;
      else if (order.storeId > 0) {
        dd = "Will call";
        imgUrl = getIconBaseUrl() + "willcall.jpg";
      }
    }
    const color = order.statusColor;
    const orderNumber = color ? (
      <font color={color}>{order.orderNumber}</font>
    ) : (
      order.orderNumber
    );
    const tsStr = color ? <font color={color}>{timestamp}</font> : timestamp;
    const nameStr = color ? (
      <font color={color}>{this.getName(order)}</font>
    ) : (
      this.getName(order)
    );
    const phStr = color ? <font color={color}>{phone}</font> : phone;
    const sp = color ? (
      <font color={color}>{order.salesperson}</font>
    ) : (
      order.salesperson
    );
    const amtStr = color ? (
      <font color={color}>{toAmount(order.totalPrice)}</font>
    ) : (
      toAmount(order.totalPrice)
    );
    const pcnt = "" + order.depositPercent.toFixed(1) + "%";
    const pcntStr = color ? <font color={color}>{pcnt}</font> : pcnt;
    const ndStr = color ? (
      <font color={color}>{order.nextDueDate}</font>
    ) : (
      order.nextDueDate
    );
    const depStr = color ? (
      <font color={color}>{toAmount(order.totalDeposit)}</font>
    ) : (
      toAmount(order.totalDeposit)
    );
    let indicatorCls = null;

    if (order.datePaidStr) {
      indicatorCls = "greenCircle";
    } else if (isLayaway && order.statusColor) {
      const color = order.statusColor;
      if (
        color === "red" ||
        color === "green" ||
        color === "blue" ||
        color === "orange"
      ) {
        indicatorCls = order.statusColor + "Circle";
      }
    }
    const idxStr = "" + (idx + 1) + ". ";
    return (
      <React.Fragment key={idx}>
        <div className={clsName}>
          <div className="flex-wrapper">
            <div>
              {idxStr}
              <img src={imgUrl} width={20} alt="Status"></img>
            </div>
            {indicatorCls && <div className={indicatorCls}></div>}
          </div>
        </div>
        <div className={clsName}>
          <a href={url}>{orderNumber}</a>
        </div>
        <div className={clsName}>{tsStr}</div>
        {desktop && !isLayaway && <div className={clsName}>{dd}</div>}
        <div className={clsName}>{nameStr}</div>
        <div className={clsName}>{phStr}</div>
        {desktop && <div className={clsName}>{sp}</div>}
        <div className={rClsName}>{amtStr}</div>
        {isLayaway && desktop && (
          <React.Fragment>
            <div className={rClsName}>{depStr}</div>
            <div className={rClsName}>{pcntStr}</div>
            <div className={clsName}>{ndStr}</div>
          </React.Fragment>
        )}
        {desktop && (
          <div className={clsName}>
            {order.invEditUrl && (
              <a href={order.invEditUrl} target="_Invoice">
                <img src={getIconBaseUrl() + "inv_edit.gif"} alt="Edit" />
                &nbsp;&nbsp;
              </a>
            )}
            {order.invPdfUrl && (
              <a href={order.invPdfUrl} target="_Invoice">
                <img src={getIconBaseUrl() + "inv_pdf.gif"} alt="PDF" />
                &nbsp;&nbsp;
              </a>
            )}
            {order.invPrintUrl && (
              <a href={order.invPrintUrl} target="_Invoice">
                <img src={getIconBaseUrl() + "inv_print.gif"} alt="Print" />
              </a>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
  getRows() {
    let rows = [];
    const startIdx = this.state.pageIdx * this.state.maxPerPage;
    let endIdx = startIdx + this.state.maxPerPage;
    if (endIdx > this.state.orderFldsList.length) {
      endIdx = this.state.orderFldsList.length;
    }

    let idx = startIdx;
    let actualIdx = startIdx;
    for (;;) {
      const flds = this.state.orderFldsList[idx];
      const flt = this.state.orderFilter;
      const order = flds[9];
      const show =
        flt == 0 ||
        (flt == 1 && order.datePaid) ||
        (flt == 2 && !order.datePaid)
          ? true
          : false;
      idx++;
      if (show) {
        rows.push(this.oneTableRow(order, actualIdx));
        actualIdx++;
        if (actualIdx >= endIdx) break;
      } else if (idx >= endIdx) break;
    }
    return rows;
  }
  showPageNumbers() {
    if (
      this.state.orderFldsList &&
      this.state.orderFldsList.length > this.state.maxPerPage
    ) {
      let pages = Math.ceil(
        this.state.orderFldsList.length / this.state.maxPerPage
      );
      if (this.state.orderFldsList.length % this.state.maxPerPage) {
        if (pages * this.state.maxPerPage < this.state.orderFldsList.length) {
          pages++;
        }
      }
      const pna = [];
      pna.push(<div key="PT">Pages:</div>);
      for (let i = 0; i < pages; i++) {
        let one;
        const clsName =
          i == this.state.pageIdx ? "left-5-bold" : "left-5-nodec";
        if (i == this.state.pageIdx) {
          one = (
            <div className={clsName} key={i}>
              {i + 1}
            </div>
          );
        } else {
          one = (
            <div className={clsName} key={i}>
              <a href="#" onClick={() => this.pageNumClicked(i)}>
                {i + 1}
              </a>
            </div>
          );
        }
        pna.push(one);
      }

      return <div className="generic-flex-top5">{pna}</div>;
    }
  }
  showOrderTable() {
    if (this.state.orderFldsList) {
      let clsName = "otv-table-c6";
      if (!this.state.mobileMode) {
        if (this.props.isLayaway) {
          clsName = "otv-table-c11";
        } else {
          clsName = "otv-table-c9";
        }
      }
      return (
        <div>
          <div className="flex-wrapper-t10">
            <div>
              <input
                type="radio"
                name="orderFilter"
                value={0}
                checked={this.state.orderFilter == 0}
                onChange={this.handleChange}
              />
              &nbsp;All
            </div>
            <div className="left-10">
              <input
                type="radio"
                name="orderFilter"
                value={1}
                checked={this.state.orderFilter == 1}
                onChange={this.handleChange}
              />
              &nbsp;Paid Only
            </div>
            <div className="left-10">
              <input
                type="radio"
                name="orderFilter"
                value={2}
                checked={this.state.orderFilter == 2}
                onChange={this.handleChange}
              />
              &nbsp;Unpaid Only
            </div>
          </div>
          {this.showPageNumbers()}
          <div className={clsName}>
            {this.tableHeader()}
            {this.getRows()}
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        {this.props.title && (
          <div>
            <font size="4">{this.props.title}</font>
          </div>
        )}
        {this.showOrderTable()}
        {this.showPageNumbers()}
      </div>
    );
  }
}
export default OrderTableView;
