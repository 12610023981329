import React from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../form.css";

class InvDelButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      showDeleteDialog: false,
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
  }

  handleInvisible() {
    document.getElementById("showInvDialogBtn").click();
  }
  showDeleteWarning() {
    document.getElementById("showDeleteDialogBtn").click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
  }
  openDeleteDialog(event) {
    event.preventDefault();
    this.setState({ showDeleteDialog: true });
  }
  closeDeleteDialog() {
    this.setState({ showDeleteDialog: false });
  }

  hiddenInvButton() {
    return (
      <button
        id="showInvDialogBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  showInvisibleConfirmation() {
    const uName = this.props.uName ? this.props.uName : "Variant";
    const name = this.props.name ? this.props.name : "variant";
    return (
      <div>
        {this.hiddenInvButton()}
        <Dialog
          isOpen={this.state.showDialog}
          onDismiss={this.closeDialog}
          aria-label="1"
        >
          <b>Hide {uName} Confirmation</b>
          <p>Are you sure you want to make this {name} invisible?</p>
          <p />
          <button onClick={this.props.invAction} className="btn-style">
            Yes
          </button>
          &nbsp;&nbsp;
          <button onClick={this.closeDialog} className="btn-style">
            Cancel
          </button>
        </Dialog>
      </div>
    );
  }
  hiddenDeleteButton() {
    return (
      <button
        id="showDeleteDialogBtn"
        onClick={this.openDeleteDialog}
        style={{ display: "none" }}
      ></button>
    );
  }

  showDeleteConfirmation() {
    const name = this.props.name ? this.props.name : "variant";
    return (
      <div>
        {this.hiddenDeleteButton()}
        <Dialog
          isOpen={this.state.showDeleteDialog}
          onDismiss={this.closeDeleteDialog}
          aria-label="2"
        >
          <div>
            <b>Delete Confirmation</b>
            <p>Are you sure you want to permanently delete this {name}?</p>
            <p />
            If you click "Yes", this variant can't be recovered anymore.
            <p />
            <button onClick={this.props.delAction} className="btn-style">
              Yes
            </button>
            &nbsp;&nbsp;
            <button onClick={this.closeDeleteDialog} className="btn-style">
              Cancel
            </button>
          </div>
        </Dialog>
      </div>
    );
  }
  render() {
    return (
      <div className="top-10">
        <button className="orange-btn-style" onClick={this.handleInvisible}>
          Mark Invisible
        </button>
        &nbsp;&nbsp;
        <button className="red-btn-style" onClick={this.showDeleteWarning}>
          Delete
        </button>
        {this.showInvisibleConfirmation()}
        {this.showDeleteConfirmation()}
      </div>
    );
  }
}
export default InvDelButtons;
