import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../category.css";

class EditCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objList: [],
      catTBU: this.newCategory(),
      isLoading: true,
      mobileMode: false,
      updateBtnDisabled: true,
      deleteBtnDisabled: true,
      message: null,
      errorMessage: null,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
      isRoot: checkSecurity("RootFeatures"),
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.addButtonClicked = this.addButtonClicked.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.fetchObjList = this.fetchObjList.bind(this);
    this.performAction = this.performAction.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  newCategory() {
    const newCat = {
      id: 0,
      name: "",
      description: "",
      assemblyRequired: false,
      showInMenu: true,
      deliveryFee: 0,
      deliveryFeePerItem: false,
    };
    return newCat;
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }

    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchObjList();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  handleChange(event) {
    if (event.target.name === "index") {
      const idx = parseInt(event.target.value);
      const catTBU = this.state.objList[idx].category;
      const deleteBtnDisabled = this.state.objList[idx].count > 0;

      this.setState({
        catTBU,
        deleteBtnDisabled,
        updateBtnDisabled: true,
      });
    } else {
      let name = event.target.name;
      let value = event.target.value;
      let imageDir = this.state.catTBU.imageDir;

      if (name.startsWith("CB_")) {
        name = name.substring(3);
        value = event.target.checked;
      } else if (name == "deliveryFee") {
        if (value && value.length > 0) {
          value = parseInt(value);
        } else value = 0;
      } else {
        if (name == "name" && this.state.catTBU.id <= 0) {
          imageDir = value.toLowerCase();
        }
      }

      let catTBU = {
        ...this.state.catTBU,
        [name]: value,
        imageDir,
      };
      this.setState({ catTBU, updateBtnDisabled: false });
    }
  }

  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.setState({
        catTBU: this.newCategory(),
        isLoading: false,
        objList: response.objList,
        updateBtnDisabled: true,
      });
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  fetchObjList() {
    const url = apiBaseUrl() + "Categories";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addButtonClicked(event) {
    this.setState({
      catTBU: this.newCategory(),
    });
  }

  handleUpdate(event) {
    event.preventDefault();

    const action = this.state.catTBU.id <= 0 ? "Add" : "Update";
    this.performAction(action);
  }

  handleDelete(event) {
    event.preventDefault();
    this.performAction("Delete");
  }

  performAction(action) {
    const url = apiBaseUrl() + action + "Category";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.catTBU,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showList() {
    return (
      <div>
        <select name="index" size="10" onChange={this.handleChange}>
          {this.state.objList.map((obj, idx) => {
            const cat = obj.category;
            return (
              <option value={idx} key={cat.id}>
                &nbsp;&nbsp;{cat.name} ({obj.count}) &nbsp;&nbsp;
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  showButtons() {
    if (this.state.editAllowed) {
      return (
        <div className="left-10" valign="top">
          <button
            name="addObj"
            className="btn-style"
            onClick={this.addButtonClicked}
          >
            Add New
          </button>
        </div>
      );
    }
  }

  theForm() {
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const delBtnStyle = this.state.deleteBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const updBtnTitle = this.state.catTBU.id > 0 ? "Update" : "Add Category";
    const clsName = this.state.mobileMode ? "none" : "left-40";
    const title =
      this.state.catTBU.id > 0
        ? "Category: " + this.state.catTBU.name
        : "New Category";
    const dfpiVisible =
      this.state.isRoot && this.state.catTBU.deliveryFee > 0 ? true : false;
    return (
      <div className={clsName}>
        <font size="4">{title}</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">Name: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name="name"
              value={this.state.catTBU.name}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Description: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="40"
              name="description"
              value={this.state.catTBU.description}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          {this.state.isRoot && (
            <React.Fragment>
              <div className="form-item-label">Delivery Fee: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  size="5"
                  name="deliveryFee"
                  value={this.state.catTBU.deliveryFee}
                  onChange={this.handleChange}
                  disabled={!editAllowed}
                ></input>
              </div>
              <div className="form-item-label">Image Dir: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  size="35"
                  name="imageDir"
                  value={this.state.catTBU.imageDir}
                  onChange={this.handleChange}
                  disabled={!editAllowed}
                ></input>
              </div>
            </React.Fragment>
          )}

          <div className="form-item-label">Settings: </div>
          <div className="form-item-value">
            {dfpiVisible && (
              <React.Fragment>
                <input
                  type="checkbox"
                  name="CB_deliveryFeePerItem"
                  checked={this.state.catTBU.deliveryFeePerItem}
                  onChange={this.handleChange}
                  disabled={!editAllowed}
                ></input>{" "}
                Delivery Fee Per Item
                <br />
              </React.Fragment>
            )}
            <input
              type="checkbox"
              name="CB_assemblyRequired"
              checked={this.state.catTBU.assemblyRequired}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Assembly Required
            <br />
            <input
              type="checkbox"
              name="CB_showInMenu"
              checked={this.state.catTBU.showInMenu}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Show In Menu
            <br />
          </div>
          {this.props.hasInStockTime && (
            <React.Fragment>
              <div className="form-item-label">In Stock Time: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  inputmode="decimal"
                  size="8"
                  name="inStockTime"
                  value={this.state.inStockTime}
                  onChange={this.handleChange}
                  disabled={!editAllowed}
                ></input>
              </div>
            </React.Fragment>
          )}
        </div>
        <p />
        <div className="flex-wrapper">
          <div>
            <button
              name="update"
              onClick={this.handleUpdate}
              className={btnStyle}
              disabled={this.state.updateBtnDisabled}
            >
              {updBtnTitle}
            </button>
          </div>
          {this.state.catTBU.id > 0 && editAllowed && (
            <div className="left-10">
              <button
                name="delObj"
                className={delBtnStyle}
                disabled={this.state.deleteBtnDisabled}
                onClick={this.handleDelete}
              >
                Delete
              </button>
            </div>
          )}
        </div>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.mobileMode) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">{this.props.title}</font>
            <div className="flex-wrapper" align="left">
              {this.showList()}
              {this.showButtons()}
            </div>
            <div>&nbsp;</div>
            {this.theForm()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">{this.props.title}</font>
            <div className="edit-cat-wrapper" align="left">
              {this.showList()}
              {this.showButtons()}
              {this.theForm()}
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditCategory));
