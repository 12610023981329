import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toTimeStr,
  previewImgBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class NotificationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: null,
      isLoading: true,
      message: null,
      errorMessage: null,
      recipient: null,
      mobileMode: false,
      editAllowed: checkUpdate("Order"),
      viewAllowed: checkView("Order"),
    };
    this.fetchNotification = this.fetchNotification.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resendNotification = this.resendNotification.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchNotification();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }

  fetchNotification() {
    const url = apiBaseUrl() + "GetNotification";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          const noti = res.data.data;
          this.setState({
            notification: noti,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  resendNotification(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "ResendNotification";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
      recipient: this.state.recipient,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            const recipient = this.state.recipient
              ? this.state.recipient
              : this.state.notification.toEmail;
            const message = "Notification succesfully resent to: " + recipient;
            this.setState({ message, errorMessage: null });
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              mesage: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showAttachment() {
    if (this.state.notification.attachment) {
      const url = previewImgBaseUrl() + this.state.notification.attachment;

      return (
        <div>
          <p />
          <b>Product Picture:</b>
          <p />
          <a href={url} target="Attachment">
            <img src={url} alt="Attachment" width="500"></img>
          </a>
        </div>
      );
    }
  }

  showNotification() {
    const noti = this.state.notification;
    const body = noti.mime ? noti.body : "<pre>" + noti.body + "</pre>";
    const placeholder = noti.sms
      ? this.state.notification.toEmail
      : "[" + this.state.notification.toEmail + "]";
    const et = noti.sms ? "To Phone" : "To Email";
    const ft = noti.sms ? "From" : "From Email";
    return (
      <div className="generic-wrapper">
        <font size="5">Notification Details</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">Sent: </div>
          <div className="form-item-value">{toTimeStr(noti.sentTime)}</div>
          <div className="form-item-label">{et}: </div>
          <div className="form-item-value">{noti.toEmail}</div>
          <div className="form-item-label">{ft}: </div>
          <div className="form-item-value">{noti.fromEmail}</div>
          <div className="form-item-label">Subject: </div>
          <div className="form-item-value">{noti.subject}</div>
        </div>
        <p />
        <div className="flex-wrapper">
          <div>
            {noti.sms ? (
              <input
                type="number"
                size="12"
                name="recipient"
                placeholder={placeholder}
                value={this.state.recipient}
                onChange={this.handleChange}
              ></input>
            ) : (
              <input
                type="text"
                size="30"
                name="recipient"
                placeholder={placeholder}
                value={this.state.recipient}
                onChange={this.handleChange}
              ></input>
            )}
          </div>
          <div className="left-10">
            <button
              name="resend"
              onClick={this.resendNotification}
              className="btn-style"
            >
              Resend
            </button>
          </div>
        </div>
        <font color="green">{this.state.message}</font>
        <br />
        <font color="red">{this.state.errorMessage}</font>
        <br />
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
        {this.showAttachment()}
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;
    if (!this.state.notification) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">Notification not found.</div>
        </div>
      );
    }

    return <div className="top-wrapper">{this.showNotification()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetail);
