import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toAmount,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
  toDateStr,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class ScheduledOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeliveryStatus: false,
      orderList: null,
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      editAllowed: checkUpdate("Order"),
      viewAllowed: checkView("Order"),
    };
    this.fetchOrders = this.fetchOrders.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.getDistance = this.getDistance.bind(this);
  }

  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchOrders();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.setState({
      orderList: response.orderList,
      showDeliveryStatus: response.showDeliveryStatus,
      isLoading: false,
    });
  }
  fetchOrders() {
    const url = apiBaseUrl() + "GetScheduledOrders";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.props.match.params.date,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getDistance(orderNumber, idx, layaway) {
    const url = apiBaseUrl() + "CalculateDistance";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      orderNumber,
      layaway,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data) {
          const distance = res.data.data ? res.data.data : 0;
          const list = layaway ? this.state.layawayList : this.state.orderList;
          const newList = list.map((order, i) => {
            if (i === idx) {
              const newOrder = {
                ...order,
                distance,
              };
              return newOrder;
            } else return order;
          });
          if (layaway) {
            this.setState({ layawayList: newList });
          } else {
            this.setState({ orderList: newList });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  oneHtmlRow(order, idx, layaway) {
    if (order.distance < 0) {
      this.getDistance(order.orderNumber, idx, layaway);
    }

    let idx2 = idx + 1;
    if (layaway && this.state.orderList) {
      idx2 += this.state.orderList.length;
    }

    const customer = layaway ? order.customerName : order.customer;
    const c2 = this.props.match.params.date ? customer : order.schDateStr;
    const url = layaway
      ? "/layawayDetail/" + order.orderNumber
      : "/orderDetail/" + order.orderNumber;
    const orderNum = order.orderNumber;
    let html =
      "<tr><td>" + idx2 + '. <a href="' + url + '">' + orderNum + "</a></td>";
    html += "<td>" + c2 + "</td>";
    html += "<td>" + order.ordDateStr + "</td>";
    html += '<td align="right">' + toAmount(order.totalPrice) + "</td>";
    html += "<td>" + order.distance + "</td>";
    if (!this.state.mobileMode) {
      html += "<td>" + order.city + "</td>";
      html += "<td>" + order.zipCode + "</td>";
      if (this.state.showDeliveryStatus) {
        if (order.statusId == 6) {
          html +=
            '<td><img src="' +
            getIconBaseUrl() +
            'checkmark.png" height="15" alt="Delivered"/></td>';
        } else {
          html += "<td></td>";
        }
      }
    }
    html += "</tr>";
    return html;
  }
  showOrders() {
    const orderList = this.state.orderList;

    if (!orderList || orderList.length === 0) {
      return <div>No orders scheduled</div>;
    } else {
      const c2 = this.props.match.params.date ? "Customer" : "Scheduled";
      let html =
        "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
      html +=
        '<table><th style="background-color: #D0D0D0" align="left">Order#</th>';
      html +=
        '<th style="background-color: #D0D0D0" align="left">' + c2 + "</th>";
      html += '<th style="background-color: #D0D0D0" align="left">Ordered</th>';
      html += '<th style="background-color: #D0D0D0" align="right">Amount</th>';
      html += '<th style="background-color: #D0D0D0">Distance</th>';
      if (!this.state.mobileMode) {
        html += '<th style="background-color: #D0D0D0" align="left">City</th>';
        html += '<th style="background-color: #D0D0D0">ZIP</th>';
        if (this.state.showDeliveryStatus) {
          html += '<th style="background-color: #D0D0D0">D</th>';
        }
      }
      html += "</tr>";

      for (let i = 0; i < orderList.length; i++) {
        html += this.oneHtmlRow(orderList[i], i, false);
      }

      const layawayList = this.state.layawayList;
      if (layawayList && layawayList.length > 0) {
        for (let i = 0; i < layawayList.length; i++) {
          html += this.oneHtmlRow(layawayList[i], i, true);
        }
      }
      html += "</table>";

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
      );
    }
  }
  showMapLink() {
    const url = this.props.match.params.date
      ? "/deliveryMap/" + this.props.match.params.date
      : "/deliveryMap";
    return (
      <div className="div-nodec">
        <a href={url} target={"MAP_" + this.props.match.params.date}>
          DELIVERY MAP
        </a>
        <p />
      </div>
    );
  }
  gotoDate(dateStr) {
    const url = "/scheduled/" + dateStr;
    window.location.replace(url);
  }
  dateNav() {
    const today = toDateStr(new Date());
    let pre = null;

    if (this.props.match.params.date > today) {
      let preDate = new Date(this.props.match.params.date);
      preDate.setTime(preDate.getTime() - 24 * 60 * 60 * 1000);
      pre = toDateStr(preDate);
    }

    let date = new Date(this.props.match.params.date);
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    const next = toDateStr(date);

    return (
      <React.Fragment>
        {pre && (
          <div>
            &nbsp;&nbsp;
            <img
              src={getIconBaseUrl() + "previous.jpg"}
              height="23"
              onClick={() => this.gotoDate(pre)}
            />
          </div>
        )}
        <div>
          <font size="5">&nbsp;{this.props.match.params.date}&nbsp;</font>
        </div>
        <div>
          <img
            src={getIconBaseUrl() + "next.jpg"}
            height="23"
            onClick={() => this.gotoDate(next)}
          />
        </div>
      </React.Fragment>
    );
  }
  showTitle() {
    if (this.props.match.params.date) {
      try {
        if (this.state.mobileMode) {
          return (
            <div>
              <div>
                <font size="5">Scheduled Orders </font>
              </div>
              <div className="bottom-flex">{this.dateNav()}</div>
            </div>
          );
        } else {
          return (
            <div className="bottom-flex">
              <div>
                <font size="5">Scheduled Orders for </font>
              </div>
              {this.dateNav()}
            </div>
          );
        }
      } catch (error) {
        //ignore
      }
      return (
        <div>
          <font size="5">
            Scheduled Orders for {this.props.match.params.date}
          </font>
        </div>
      );
    } else {
      return <font size="5">Scheduled Orders</font>;
    }
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          {this.showTitle()}
          <p />
          {this.showMapLink()}
          {this.showOrders()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ScheduledOrders);
