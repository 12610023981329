import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../setting.css";

class ScheduleSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leadDays: 1,
      numOfDays: 20,
      maxScheduledOrders: 30,
      cutoffTime: "18:00",
      notifyReschedule: false,
      updateDatReschedule: false,
      centerAroundWH: false,
      removeDelivered: false,
      monthDateList: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Product"),
      viewAllowed: checkView("Product"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetScheduleSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    const data = response.data;
    this.setState({
      leadDays: data.leadDays,
      numOfDays: data.numOfDays,
      cutoffTime: data.cutoffTime,
      monthDateList: data.monthDateList,
      maxScheduledOrders: data.maxScheduledOrders,
      notifyReschedule: data.notifyReschedule,
      updateDatReschedule: data.updateDatReschedule,
      centerAroundWH: data.centerAroundWH,
      removeDelivered: data.removeDelivered,
      isLoading: false,
    });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("CB_")) {
      const idx = parseInt(name.substring(3));
      let i;
      let monthDateList = [];

      for (i = 0; i < this.state.monthDateList.length; i++) {
        if (i === idx) {
          const one = {
            ...this.state.monthDateList[i],
            available: event.target.checked,
          };
          monthDateList.push(one);
        } else {
          monthDateList.push(this.state.monthDateList[i]);
        }
      }
      this.setState({ monthDateList, updateBtnDisabled: false });
    } else if (name.startsWith("RCHCB_") || name.startsWith("MAPCB_")) {
      name = name.substring(6);
      this.setState({ [name]: event.target.checked, updateBtnDisabled: false });
    } else {
      this.setState({ [name]: event.target.value, updateBtnDisabled: false });
    }
  }

  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateScheduleSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showOrderCount(day) {
    if (day.orderCount <= 0) return "";
    else {
      const url = "/scheduled/" + day.date2;
      return (
        <label>
          (<Link to={url}>{day.orderCount}</Link>)
        </label>
      );
    }
  }
  monthDays() {
    if (this.state.monthDateList) {
      const clsName = this.state.mobileMode
        ? "twocol-wrapper"
        : "threecol-wrapper";
      return (
        <div className="top-10">
          <div className="nodec-div">
            <b>Availability</b>&nbsp;&nbsp;&nbsp;
            <Link to="/scheduled">All scheduled</Link>
          </div>
          <div className={clsName}>
            {this.state.monthDateList.map((day, idx) => {
              const name = "CB_" + idx;
              return (
                <div className="top7r20" key={idx}>
                  <input
                    type="checkbox"
                    name={name}
                    onChange={this.handleChange}
                    defaultChecked={day.available && day.canChange}
                    disabled={!day.canChange}
                  ></input>{" "}
                  &nbsp;{day.date} {this.showOrderCount(day)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  theForm() {
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const timeType = this.state.mobileMode ? "time" : "text";
    const notifyLabel = this.state.mobileMode
      ? "Notify customer"
      : "Notify customer after reschedule";
    const updateLabel = this.state.mobileMode
      ? "Update DAT file"
      : "Update DAT after reschedule";
    return (
      <div className="generic-wrapper">
        <font size="5">Delivery Schedule Settings</font>
        <p />
        <ValidatorForm ref="form" onSubmit={this.handleUpdate}>
          <div className="form-wrapper">
            <div className="form-item-label">Lead Days: </div>
            <div className="form-item-value">
              <TextInput
                inputmode="decimal"
                size="8"
                name="leadDays"
                value={this.state.leadDays}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required", "isNumber"]}
                errorMessages={["Lead Days is required", "Must be a number"]}
              ></TextInput>
            </div>
            <div className="form-item-label">Cutoff Time: </div>
            <div className="form-item-value">
              <TextInput
                type={timeType}
                size="8"
                name="cutoffTime"
                value={this.state.cutoffTime}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required"]}
                errorMessages={["Cutoff Time is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">Days to Select: </div>
            <div className="form-item-value">
              <TextInput
                inputmode="decimal"
                size="8"
                name="numOfDays"
                value={this.state.numOfDays}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required", "isNumber"]}
                errorMessages={[
                  "Number of Days is required",
                  "Must be a number",
                ]}
              ></TextInput>
            </div>
            <div className="form-item-label">Max Delivery/Day: </div>
            <div className="form-item-value">
              <TextInput
                inputmode="decimal"
                size="8"
                name="maxScheduledOrders"
                value={this.state.maxScheduledOrders}
                onChange={this.handleChange}
                disabled={!editAllowed}
                validators={["required", "isNumber"]}
                errorMessages={[
                  "Number of Days is required",
                  "Must be a number",
                ]}
              ></TextInput>
            </div>
            <div className="form-item-label">Reschedule: </div>
            <div className="form-item-value">
              <input
                type="checkbox"
                name="RCHCB_notifyReschedule"
                defaultChecked={this.state.notifyReschedule}
                disabled={!editAllowed}
                onChange={this.handleChange}
              ></input>{" "}
              {notifyLabel}
              <br />
              <input
                type="checkbox"
                name="RCHCB_updateDatReschedule"
                defaultChecked={this.state.updateDatReschedule}
                disabled={!editAllowed}
                onChange={this.handleChange}
              ></input>{" "}
              {updateLabel}
            </div>
            <div className="form-item-label">Delivery Map: </div>
            <div className="form-item-value">
              <input
                type="checkbox"
                name="MAPCB_centerAroundWH"
                defaultChecked={this.state.centerAroundWH}
                disabled={!editAllowed}
                onChange={this.handleChange}
              ></input>{" "}
              Center Around Warehouse
              <br />
              <input
                type="checkbox"
                name="MAPCB_removeDelivered"
                defaultChecked={this.state.removeDelivered}
                disabled={!editAllowed}
                onChange={this.handleChange}
              ></input>{" "}
              Remove Delivered
            </div>
          </div>
          <p />
          {this.monthDays()}
          <p />
          <button
            name="update"
            type="submit"
            className={btnStyle}
            disabled={this.state.updateBtnDisabled}
          >
            Update
          </button>
        </ValidatorForm>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSettings);
