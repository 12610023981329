import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "./config/constants";
import { apiBaseUrl, toDateStr } from "./Util";
import "../App.css";
import "../generic.css";

class StoreDatesSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeList: null,
      storeId: this.props.defaultStoreId > -1 ? this.props.defaultStoreId : -1,
      startDate: null,
      endDate: null,
      includeLayaway: true,
      minStart: "2020-05-08",
      maxStart: null,
      minEnd: "2020-05-08",
      maxEnd: null,
      catList: null,
      category: -1,
      btnTitle: this.props.btnTitle,
      btnEnabled: false,
    };

    this.getStoreList = this.getStoreList.bind(this);
    this.setDateRanges = this.setDateRanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  componentDidMount() {
    this.getCategories();
    this.getStoreList();
    this.setDateRanges();
  }
  componentDidUpdate(prevProps) {}
  getStoreList() {
    if (this.props.storeList && this.props.storeList.length > 0) {
      this.setState({ storeList: this.props.storeList });
    } else {
      const url = apiBaseUrl() + "GetAllWebStores";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
      };

      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.setState({ storeList: res.data.objList });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorMessage: "Failed to get store list",
          });
        });
    }
  }
  getCategories() {
    if (this.props.useCategory) {
      const url = apiBaseUrl() + "Categories";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
      };
      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.setState({
            catList: res.data.objList,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  handleClick() {
    let storeName = null;
    if (this.state.storeId >= 0) {
      const store = this.state.storeList.find(
        (s) => s.storeId == this.state.storeId
      );
      if (store) storeName = store.storeName;
    }

    const storeDates = {
      storeId: this.state.storeId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      storeName,
      includeLayaway: this.state.includeLayaway,
      category: this.state.category,
    };
    this.props.actionHandler(storeDates);
    this.setState({
      storeId: -1,
      startDate: "",
      endDate: "",
    });
  }
  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      this.setState({ [name]: event.target.checked, btnEnabled: true });
    } else if (name == "category") {
      this.setState({
        category: parseInt(event.target.value),
        btnEnabled: true,
      });
    } else {
      const val = event.target.value;
      let maxStart = this.state.maxStart;
      let minEnd = this.state.minEnd;

      if (name === "startDate") {
        minEnd = val;
      } else {
        maxStart = val;
      }
      this.setState({ [name]: val, maxStart, minEnd, btnEnabled: true });
    }
  }
  setDateRanges() {
    const date = new Date();
    const max = toDateStr(date);

    this.setState({ maxStart: max, maxEnd: max });
  }
  showCategories() {
    const catList = this.state.catList;
    if (catList && catList.length > 0) {
      return (
        <div className="generic-flex">
          <div>Category:</div>
          <div className="left-10">
            <select
              name="category"
              defaultValue={-1}
              onChange={this.handleChange}
            >
              <option value={-1}>[ALL]</option>
              {catList.map((cat, idx) => {
                return (
                  <option value={cat.category.id} key={idx}>
                    {cat.category.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      );
    }
  }
  selectBox() {
    const clsName = this.state.btnEnabled ? "small-btn" : "disabled-small-btn";
    return (
      <div>
        <div className="generic-flex">
          <select
            name="storeId"
            value={this.state.storeId}
            onChange={this.handleChange}
          >
            <option value={-1}>[ALL]</option>
            {this.state.storeList &&
              this.state.storeList.map((ss, idx) => {
                const checked =
                  ss.storeId == this.props.defaultStoreId ? true : false;
                return (
                  <option
                    key={ss.storeId}
                    value={ss.storeId}
                    key={idx}
                    defaultChecked={checked}
                  >
                    {ss.storeName}
                  </option>
                );
              })}
          </select>
          &nbsp;&nbsp;
          <input
            type="date"
            size="10"
            min={this.state.minStart}
            max={this.state.maxStart}
            name="startDate"
            placeholder="Start"
            value={this.state.startDate || ""}
            onChange={this.handleChange}
          ></input>
          -
          <input
            type="date"
            size="10"
            min={this.state.minEnd}
            max={this.state.maxEnd}
            name="endDate"
            placeholder="End"
            value={this.state.endDate || ""}
            onChange={this.handleChange}
          ></input>
          &nbsp;
          <button
            name="search"
            className={clsName}
            disabled={!this.state.btnEnabled}
            onClick={this.handleClick}
          >
            <b>{this.state.btnTitle}</b>
          </button>
        </div>
        {this.showCategories()}
        {this.props.showLayawayCB && (
          <div>
            <input
              type="checkbox"
              name="CB_includeLayaway"
              defaultChecked={this.state.includeLayaway}
              onChange={this.handleChange}
            />
            Include Layaway
          </div>
        )}
      </div>
    );
  }
  render() {
    return this.selectBox();
  }
}
export default StoreDatesSelector;
