import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class SEOSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keywordsList: null,
      newKeywords: null,
      isLoading: true,
      updBtnEnabled: false,
      addBtnEnabled: false,
      mobileMode: false,
      errorMessage: null,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.addEntry = this.addEntry.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  fetchData() {
    const url = apiBaseUrl() + "GetInterKeywords";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const objList = response.objList;
      console.log(objList);
      this.setState({
        keywordsList: objList,
        isLoading: false,
      });
    }
  }
  handleChange(event) {
    if (event.target.name === "newKeywords") {
      const addBtnEnabled =
        event.target.value &&
        event.target.value.trim().length > 0 &&
        event.target.value.indexOf(",") > 0;
      this.setState({ newKeywords: event.target.value, addBtnEnabled });
    } else {
      const idx = parseInt(event.target.name);
      let keywordsList = [];
      for (let i = 0; i < this.state.keywordsList.length; i++) {
        if (i === idx) keywordsList.push(event.target.value);
        else keywordsList.push(this.state.keywordsList[i]);
      }
      this.setState({ keywordsList, updBtnEnabled: true });
    }
  }
  addEntry() {
    let keywordsList = this.state.keywordsList;
    keywordsList.push(this.state.newKeywords);
    this.setState({ keywordsList, newKeywords: null, updBtnEnabled: true });
  }
  deleteEntry(idx) {
    let keywordsList = [];
    for (let i = 0; i < this.state.keywordsList.length; i++) {
      if (i !== idx) {
        keywordsList.push(this.state.keywordsList[i]);
      }
    }
    this.setState({ keywordsList, updBtnEnabled: true });
  }
  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleUpdate(event) {
    const url = apiBaseUrl() + "UpdateInterKeywords";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      objList: this.state.keywordsList,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  theForm() {
    const kwList = this.state.keywordsList;
    if (kwList && kwList.length > 0) {
      const addBtn = this.state.addBtnEnabled
        ? "small-btn"
        : "disabled-small-btn";
      return (
        <div>
          {kwList.map((item, idx) => {
            const name = "" + idx;
            return (
              <div className="flex-b10">
                <div>
                  <input
                    name={name}
                    type="text"
                    size="45"
                    value={item}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="left-10">
                  <button
                    className="small-btn"
                    name={"Btn" + idx}
                    onClick={() => this.deleteEntry(idx)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
          <div className="generic-flex">
            <div>
              <input
                name="newKeywords"
                type="text"
                size="45"
                value={this.state.newKeywords || ""}
                onChange={this.handleChange}
              />
            </div>
            <div className="left-10">
              <button
                name="Add"
                className={addBtn}
                onClick={this.addEntry}
                disabled={!this.state.addBtnEnabled}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const btnStyle = this.state.updBtnEnabled
      ? "btn-style"
      : "disabled-btn-style";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Interchangeable Keywords</font>
          <p />
          {this.theForm()}
          <p />
          <button
            name="Update"
            className={btnStyle}
            disabled={!this.state.updBtnEnabled}
            onClick={this.handleUpdate}
          >
            Update
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SEOSetting);
