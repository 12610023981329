import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import {
  mainSiteApiBaseUrl,
  setTopContainerWrapperSettings,
  parseAddress,
  getIconBaseUrl,
} from "../Util";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../../contact.css";

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "GA",
      zipCode: "",
      phone: "",
      email: "",
      deliveryInstr: "",
      deliveryFee: 65,
      updBtnEnabled: true,
      working: false,
      overrideDeliveryFee: null,
      errorMessage: "",
      outOfAreaMessage: null,
      dialogTitle: null,
      showStoreDialog: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.updateDeliveryInfo = this.updateDeliveryInfo.bind(this);
    this.autocomplete = null;

    this.validateAddress = this.validateAddress.bind(this);
    this.checkAddressResponse = this.checkAddressResponse.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.setContact = this.setContact.bind(this);
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);

    this.setContact(this.props.contact);
    this.setState({ updBtnEnabled: !this.props.initDisabled });

    const radius = 60 * 1600; //miles --> meters
    const circle = new window.google.maps.Circle({
      center: new window.google.maps.LatLng(33.755112, -84.547043),
      radius: radius,
    });

    this.autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { bounds: circle.getBounds(), strictbounds: true }
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  }
  componentDidUpdate(prevProps) {
    if (this.props.initDisabled !== prevProps.initDisabled) {
      if (!this.props.initDisabled && !this.state.updBtnEnabled) {
        this.setState({ updBtnEnabled: true });
      }
    }
    if (this.props.deliveryInstr !== prevProps.deliveryInstr) {
      this.setState({ deliveryInstr: this.props.deliveryInstr });
    }
    if (this.props.contact !== prevProps.contact) {
      this.setContact(this.props.contact);
    }
  }
  setContact(address) {
    if (address) {
      this.setState({
        firstName: address.firstName,
        lastName: address.lastName,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        phone: address.phone,
        email: address.email,
        deliveryInstr: this.props.deliveryInstr,
      });
    }
  }
  handlePlaceSelect() {
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    const addrObj = parseAddress(address);

    try {
      this.setState({
        addressLine1: addrObj.addressLine1,
        city: addrObj.city,
        state: addrObj.state,
        zipCode: addrObj.zipCode,
        googleMapLink: addressObject.url,
      });
      document.getElementById("autocomplete").value = addrObj.addressLine1;
    } catch (error) {
      console.log(error);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }
  adjustSettings() {
    setTopContainerWrapperSettings();
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: null,
      updBtnEnabled: true,
    });
  }
  checkAddressResponse(res) {
    if (res.status === true) {
      const contact = {
        ...this.state,
        deliveryFee: res.deliveryFee,
      };
      this.props.actionHandler(contact);
    } else {
      const errorMessage = res.outOfAreaMessage
        ? "Please either choose Pickup or specify the Delivery Fee"
        : res.errorMessage;
      this.setState({
        outOfAreaMessage: res.outOfAreaMessage,
        errorMessage,
        dialogTitle: res.dialogTitle,
      });
      document.getElementById("cityWarnBtn").click();
    }
  }
  validateAddress() {
    const url = mainSiteApiBaseUrl() + "CheckDeliveryCity";
    const address = {
      ...this.state,
      userId: this.props.userId,
    };
    const req = {
      address,
      orderSubtotal: this.props.orderSubtotal,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.checkAddressResponse(res.data);
      })
      .catch((error) => {
        this.setState({ updBtnEnabled: true, working: false });
        console.log(error);
      });
  }
  updateDeliveryInfo() {
    this.setState({ updBtnEnabled: false, working: true });
    let errorMessage = null;
    if (!this.state.firstName) errorMessage = "First Name is required";
    else if (!this.state.lastName) errorMessage = "Last Name is required";
    else if (!this.state.phone) errorMessage = "Phone is required";
    else if (!this.state.addressLine1) errorMessage = "Address is required";
    else if (!this.state.city) errorMessage = "City is required";
    else if (!this.state.zipCode) errorMessage = "ZIP code is required";

    if (errorMessage) {
      this.setState({ errorMessage });
      this.setState({ updBtnEnabled: true, working: false });
      return;
    } else {
      if (this.state.overrideDeliveryFee) {
        let fee = -1;
        try {
          fee = parseFloat(this.state.overrideDeliveryFee);
        } catch (error) {}
        if (!isNaN(fee) && fee > 0) {
          const contact = {
            ...this.state,
            deliveryFee: fee,
          };
          this.props.actionHandler(contact);
          this.setState({ updBtnEnabled: true, working: false });
          return;
        }
      }
      this.validateAddress();
    }
  }
  openDialog() {
    this.setState({ showStoreDialog: true });
  }
  closeDialog() {
    this.setState({ showStoreDialog: false });
  }

  hiddenButton() {
    return (
      <button
        id="cityWarnBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  showDialogMessage() {
    const title = this.state.dialogTitle
      ? this.state.dialogTitle
      : "Out Of Delivery Area";
    return (
      <div>
        {this.hiddenButton()}
        <Dialog
          isOpen={this.state.showStoreDialog}
          onDismiss={this.closeDialog}
        >
          <b>{title}</b>
          <p>{this.state.outOfAreaMessage}</p>
          You can either change to "Pickup" or specify the <b>Delivery Fee</b>
          &nbsp; below and click "{this.props.buttonName}" again.
          <p />
          <button onClick={this.closeDialog} className="btn-style">
            Okay
          </button>
        </Dialog>
      </div>
    );
  }
  addressForm() {
    const txtLen = 30;
    return (
      <React.Fragment>
        <div className="cttbl-item-left">
          Address<font color="red">*</font>:
        </div>
        <div className="cttbl-item-left">
          <input
            id="autocomplete"
            ref="input"
            type="text"
            name="addressLine1"
            size="45"
            value={this.state.addressLine1}
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">Address2:</div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="addressLine2"
            size={txtLen}
            placeholder="(Apartment number, etc.)"
            value={this.state.addressLine2}
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">
          City<font color="red">*</font>:{" "}
        </div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="city"
            value={this.state.city}
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">
          State<font color="red">*</font>:{" "}
        </div>
        <div className="cttbl-item-left">Georgia</div>
        <div className="cttbl-item-left">
          ZIP code<font color="red">*</font>:{" "}
        </div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="zipCode"
            size="10"
            value={this.state.zipCode}
            onChange={this.handleChange}
          />
        </div>
        {this.state.outOfAreaMessage && (
          <React.Fragment>
            <div className="cttbl-item-left">
              <b>Delivery Fee:</b>{" "}
            </div>
            <div className="cttbl-item-left">
              <input
                type="text"
                name="overrideDeliveryFee"
                size="8"
                value={this.state.overrideDeliveryFee || ""}
                onChange={this.handleChange}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  deliveryForm() {
    const txtLen = 30;
    const colCnt = 50;
    const rowCnt = 5;
    const btnStyle = this.state.updBtnEnabled
      ? "btn-style"
      : "disabled-btn-style";

    return (
      <div className="ct-wrapper">
        {this.state.errorMessage && (
          <div>
            <font color="red">{this.state.errorMessage}</font>
          </div>
        )}
        <div className="cttbl-container">
          <div className="cttbl-item-left">
            First Name<font color="red">*</font>:{" "}
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">
            Last Name<font color="red">*</font>:{" "}
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">
            Phone<font color="red">*</font>:{" "}
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="phone"
              size="15"
              value={this.state.phone}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">Email: </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="email"
              size={txtLen}
              value={this.state.email}
              onChange={this.handleChange}
            />
          </div>
          {this.addressForm()}
        </div>
        <div align="left">
          <div>Add Delivery Instruction:</div>
          <textarea
            name="deliveryInstr"
            rows={rowCnt}
            cols={colCnt}
            value={this.state.deliveryInstr || ""}
            onChange={this.handleChange}
          ></textarea>
        </div>
        <div className="cttbl-item-left">
          <div className="tbl-2col">
            <div>
              <button
                className={btnStyle}
                disabled={!this.state.updBtnEnabled}
                onClick={this.updateDeliveryInfo}
              >
                {this.props.buttonName}
              </button>
            </div>
            {this.state.working && (
              <div className="left-20">
                <img src={getIconBaseUrl() + "working.gif"} width={30} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.deliveryForm()}
        {this.showDialogMessage()}
      </div>
    );
  }
}
export default CustomerInfo;
