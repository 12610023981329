import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditLoadedSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedSetting: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetLoadedNotiSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        const response = res.data;
        if (response.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
          return;
        }
        this.setState({ loadedSetting: response.data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("CB_")) {
      name = name.substring(3);

      let loadedSetting = {
        ...this.state.loadedSetting,
        [name]: event.target.checked,
      };
      this.setState({
        loadedSetting,
        updateBtnDisabled: false,
      });
    } else {
      const loadedSetting = {
        ...this.state.loadedSetting,
        [name]: event.target.value,
      };
      this.setState({
        loadedSetting,
        updateBtnDisabled: false,
      });
    }
  }
  handleUpdate(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateLoadedNotiSetting";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state.loadedSetting,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        const response = res.data;
        if (response.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
          return;
        }
        if (response.status) {
          this.props.history.push("/");
        } else {
          this.setState({ errorMessage: response.errorMessage });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  theForm() {
    const settings = this.state.loadedSetting;
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const rows = this.state.mobileMode ? 12 : 8;
    const cols = this.state.mobileMode ? 50 : 80;
    return (
      <div className="generic-wrapper">
        <font size="5">Loaded Order Notification Settings</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">SMS:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_sendSMS"
              defaultChecked={settings.sendSMS}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Enable SMS Notification
          </div>
          <div className="form-item-label"></div>
          <div className="form-item-value">
            <i>SMS Template:</i>
            <br />
            <textarea
              name="smsTemplate"
              value={settings.smsTemplate}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.sendSMS}
              cols={cols}
              rows={rows}
            ></textarea>
          </div>
          <div className="form-item-label"></div>
          <div className="form-item-value">
            <i>SMS AutoReply:</i>
            <br />
            <textarea
              name="smsAutoReply"
              value={settings.smsAutoReply}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.sendSMS}
              cols={cols}
              rows={3}
            ></textarea>
          </div>
          <div className="form-item-label">Email:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_sendEmail"
              defaultChecked={settings.sendEmail}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
            Enable Email Notification
          </div>
          <div className="form-item-label"></div>
          <div className="form-item-value">
            <i>Email Template:</i>
            <br />
            <textarea
              name="emailTemplate"
              value={settings.emailTemplate}
              onChange={this.handleChange}
              disabled={!editAllowed || !settings.sendEmail}
              cols={cols}
              rows={rows}
            ></textarea>
          </div>
          <div className="form-item-label">All:</div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_checkAllEnabled"
              defaultChecked={settings.checkAllEnabled}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />
            Enable Check All
          </div>
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <p />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditLoadedSetting);
