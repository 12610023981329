import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import SPThumbnail from "./SPThumbnail";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../storeprod.css";

class ManageStoreProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storeProdList: null,
      storeLocList: null,
      selStoreId: 0,
      isLoading: true,
      mobileMode: false,
      errorMessage: null,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };

    this.fetchStoreProducts = this.fetchStoreProducts.bind(this);
    this.fetchStoreList = this.fetchStoreList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchStoreProducts();
    this.fetchStoreList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      this.setState({ storeProdList: response.objList, isLoading: false });
    }
  }
  fetchStoreProducts() {
    const url = apiBaseUrl() + "AllStoreProducts";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.props.match.params.id,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get store products",
        });
        console.log(error);
      });
  }
  fetchStoreList() {
    const url = apiBaseUrl() + "StoreLocations";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ storeLocList: res.data.objList });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get store locations",
        });
        console.log(error);
      });
  }
  handleChange(event) {
    const storeId = parseInt(event.target.value);
    this.setState({ selStoreId: storeId });
  }

  storeLocations() {
    return (
      <select
        name="storeId"
        value={this.state.selStoreId}
        onChange={this.handleChange}
      >
        <option value={0}>[ALL LOCATIONS]</option>
        {this.state.storeLocList &&
          this.state.storeLocList.map((locWrapper) => {
            const loc = locWrapper.locationInfo;
            return <option value={loc.id}>{loc.shortName}</option>;
          })}
      </select>
    );
  }

  showProducts(spList) {
    const clsName = this.state.mobileMode ? "none" : "sp-2col-wrapper";
    return (
      <div className={clsName}>
        {spList.map((sp) => (
          <SPThumbnail
            storeProd={sp}
            locId={this.state.selStoreId}
            locList={this.state.storeLocList}
          />
        ))}
      </div>
    );
  }
  storeProducts() {
    let list = this.state.storeProdList;
    if (!list || list.length === 0) return null;

    if (this.state.selStoreId > 0) {
      const sList = list.filter(
        (sp) =>
          sp.inventoryList &&
          sp.inventoryList.find((inv) => inv.storeId === this.state.selStoreId)
      );
      list = sList;
    }

    return (
      <div>
        <div>Select Store:&nbsp;{this.storeLocations()}</div>
        <p />
        {list && list.length > 0 && this.showProducts(list)}
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const title = "Store Products";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          <div className="sp-div">
            <a href="/editStoreProd/0">
              <font color="red" size="4">
                +Add Store Product
              </font>
            </a>
          </div>
          <br />
          {this.storeProducts()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageStoreProduct);
