import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../category.css";

class EditCatSup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objList: [],
      id: 0,
      name: "",
      inStockTime: 0,
      isLoading: true,
      mobileMode: false,
      updateBtnDisabled: true,
      deleteBtnDisabled: true,
      message: null,
      errorMessage: null,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.addButtonClicked = this.addButtonClicked.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.fetchObjList = this.fetchObjList.bind(this);
    this.performAction = this.performAction.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }

    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchObjList();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    const flag = isMobileMode() ? true : false;

    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
  }

  handleChange(event) {
    if (event.target.name === "id") {
      const found = this.state.objList.find(
        (item) => item.id == event.target.value
      );
      if (found) {
        const deleteBtnDisabled = found.count > 0;
        this.setState({
          id: found.id,
          name: found.name,
          inStockTime: found.inStockTime,
          deleteBtnDisabled,
          updateBtnDisabled: true,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        updateBtnDisabled: false,
      });
    }
  }
  processUpdateResponse(response, action) {
    if (response.status) {
      this.setState({
        id: 0,
        name: "",
        inStockTime: 0,
        updateBtnDisabled: true,
      });
      this.fetchObjList();
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  fetchObjList() {
    const url = apiBaseUrl() + "Get" + this.props.typeName + "List";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ objList: res.data.objList, isLoading: false });

          if (res.data && res.data.objList && res.data.objList.length > 0) {
            this.setState({
              id: res.data.objList[0].id,
              name: res.data.objList[0].name,
              inStockTime: res.data.objList[0].inStockTime,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addButtonClicked(event) {
    this.setState({
      id: 0,
      name: "",
      inStockTime: 0,
    });
  }

  handleUpdate(event) {
    event.preventDefault();

    const action = this.state.id > 0 ? "Update" : "Add";
    this.performAction(action);
  }

  handleDelete(event) {
    event.preventDefault();
    this.performAction("Delete");
  }

  performAction(action) {
    const url = apiBaseUrl() + action + this.props.typeName;
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      id: this.state.id,
      name: this.state.name,
      inStockTime: this.state.inStockTime,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.processUpdateResponse(res.data, action);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showList() {
    return (
      <div>
        <select
          name="id"
          size="10"
          value={this.state.id}
          onChange={this.handleChange}
        >
          {this.state.objList.map((obj) => {
            return (
              <option value={obj.id} key={obj.id}>
                &nbsp;&nbsp;{obj.name} ({obj.count}) &nbsp;&nbsp;
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  showButtons() {
    if (this.state.editAllowed) {
      return (
        <div className="left-10" valign="top">
          <button
            name="addObj"
            className="btn-style"
            onClick={this.addButtonClicked}
          >
            Add New
          </button>
        </div>
      );
    }
  }

  theForm() {
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const delBtnStyle = this.state.deleteBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const updBtnTitle =
      this.state.id > 0 ? "Update" : "Add " + this.props.typeName;
    const clsName = this.state.mobileMode ? "none" : "left-40";
    return (
      <div className={clsName}>
        <font size="4">{this.props.typeName}</font>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">Name: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          {this.props.hasInStockTime && (
            <React.Fragment>
              <div className="form-item-label">In Stock Time: </div>
              <div className="form-item-value">
                <input
                  type="text"
                  inputmode="decimal"
                  size="8"
                  name="inStockTime"
                  value={this.state.inStockTime}
                  onChange={this.handleChange}
                  disabled={!editAllowed}
                ></input>
              </div>
            </React.Fragment>
          )}
        </div>
        <p />
        <div className="flex-wrapper">
          <div>
            <button
              name="update"
              onClick={this.handleUpdate}
              className={btnStyle}
              disabled={this.state.updateBtnDisabled}
            >
              {updBtnTitle}
            </button>
          </div>
          {this.state.id > 0 && editAllowed && (
            <div className="left-10">
              <button
                name="delObj"
                className={delBtnStyle}
                disabled={this.state.deleteBtnDisabled}
                onClick={this.handleDelete}
              >
                Delete
              </button>
            </div>
          )}
        </div>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.mobileMode) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">{this.props.title}</font>
            <div className="flex-wrapper" align="left">
              {this.showList()}
              {this.showButtons()}
            </div>
            <div>&nbsp;</div>
            {this.theForm()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">{this.props.title}</font>
            <div className="edit-cat-wrapper" align="left">
              {this.showList()}
              {this.showButtons()}
              {this.theForm()}
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditCatSup));
