import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  previewImgBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
} from "../Util";
import { uploadFiles } from "../FileUploader";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";

import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditSpecialMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      link: null,
      iconUrl: null,
      text: null,
      color: null,
      weight: null,
      uploadedPic: null,
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      updateBtnDisabled: true,
      deleteBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.addPicFile = this.addPicFile.bind(this);
    this.doUpdate = this.doUpdate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetSpecialMenu";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get special menu",
        });
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();

    const infoWidth = getGroupWrapperWidth() - 10;
    if (infoWidth < 750) {
      document.documentElement.style.setProperty(
        "--info-wrapper-width",
        "" + infoWidth + "px"
      );
    }
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    const data = response.data;
    if (data) {
      this.setState({
        id: data.id,
        link: data.link,
        text: data.text,
        color: data.color,
        weight: data.weight,
        iconUrl: data.iconUrl,
        deleteBtnDisabled: false,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      updateBtnDisabled: false,
    });
  }

  handleDelete(event) {
    const data = {
      id: this.state.id,
      link: null,
      text: this.state.text,
      iconUrl: this.state.iconUrl,
    };
    this.doUpdate(data);
  }

  handleUpdate(event) {
    if (event) event.preventDefault();
    this.doUpdate(this.state);
  }
  doUpdate(data) {
    const url = apiBaseUrl() + "UpdateSpecialMenu";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: data,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  processUploadResponse(response) {
    if (response.status) {
      this.setState({
        uploadedPic: response.data,
        updateBtnDisabled: false,
      });
    } else {
      console.log("Upload failed");
    }
  }
  addPicFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      uploadFiles(event.target.files, this.processUploadResponse);
    }
  }
  handleCancel(event) {
    event.preventDefault();
    this.props.history.push("/");
  }
  theForm() {
    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const delBtnStyle = this.state.deleteBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";

    let imgUrl = null;
    if (this.state.uploadedPic)
      imgUrl = previewImgBaseUrl() + this.state.uploadedPic;
    else if (this.state.iconUrl) imgUrl = this.state.iconUrl;

    const btnTitle = this.state.deleteBtnDisabled ? "Add" : "Update";
    const label = imgUrl ? "Replace" : "Add";
    const cwDisabled =
      !editAllowed || this.state.iconUrl || this.state.uploadedPic;
    const text = this.state.text ? this.state.text : "";
    return (
      <div className="generic-wrapper">
        <font size="5">Special Menu</font>
        <p />
        <div className="info-wrapper">
          Special Menu is an optional menu next to the home icon in the desktop
          version, it is not used by the mobile version. "Menu Text" is for
          informatonal purpuse only, it doesn't have an effect. The "Menu URL"
          points to the page you like when users click on this menu. The "Menu
          Icon" is used as an image for this special menu, just like the home
          logo. This icon has the same height as the home icon in the menu bar.
        </div>
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">Menu Text: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name="text"
              value={text}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Text Color: </div>
          <div className="form-item-value">
            <div className="generic-flex">
              <div>
                <input
                  type="text"
                  size="15"
                  name="color"
                  value={this.state.color}
                  onChange={this.handleChange}
                  disabled={cwDisabled}
                ></input>
              </div>
              <div>(black, red, green, ...)</div>
            </div>
          </div>
          <div className="form-item-label">Text Weight: </div>
          <div className="form-item-value">
            <div className="generic-flex">
              <div>
                <input
                  type="text"
                  size="15"
                  name="weight"
                  value={this.state.weight}
                  onChange={this.handleChange}
                  disabled={cwDisabled}
                ></input>
              </div>
              <div>(normal,bold)</div>
            </div>
          </div>
          <div className="form-item-label">Menu URL: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name="link"
              value={this.state.link}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
          </div>
          <div className="form-item-label">Menu Icon: </div>
          <div className="form-item-value">
            <div className="generic-flex">
              <div>{imgUrl && <img src={imgUrl} height="45"></img>}</div>
              <div>
                <b>&nbsp;&nbsp;{label}:&nbsp;&nbsp;</b>
              </div>
              <div>
                <input
                  type="file"
                  id="iconUrl"
                  onChange={this.addPicFile}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <p />
        <div className="generic-flex">
          <button
            name="update"
            onClick={this.handleUpdate}
            className={btnStyle}
            disabled={this.state.updateBtnDisabled}
          >
            {btnTitle}
          </button>
          {this.state.deleteBtnDisabled === false && (
            <button
              name="delete"
              onClick={this.handleDelete}
              className={delBtnStyle}
              disabled={this.state.deleteBtnDisabled}
            >
              Delete
            </button>
          )}
          <button
            name="cancel"
            onClick={this.handleCancel}
            className="grey-btn-style"
          >
            Cancel
          </button>
        </div>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSpecialMenu);
