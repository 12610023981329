import React from "react";
import EditCatSup from "./EditCatSup";

class EditSupplier extends React.Component {
  render() {
    return (
      <EditCatSup typeName="Supplier" hasInStockTime={true} title="Suppliers" />
    );
  }
}

export default EditSupplier;
