import React from "react";
import CurrencyFormat from "react-currency-format";

export function FormatCurrency(props) {
  return (
    <CurrencyFormat
      value={props.amount}
      decimalScale={2}
      fixedDecimalScale={true}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
}
export function FormatCurrencyByAmt(amt) {
  return (
    <CurrencyFormat
      value={amt}
      decimalScale={2}
      fixedDecimalScale={true}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
}
export function apiBaseUrl() {
  const { apiUrl } = window["runConfig"];

  return apiUrl;
}
export function mainSiteApiBaseUrl() {
  const { mainSiteApiUrl } = window["runConfig"];

  return mainSiteApiUrl;
}
export function getMainSiteUrl() {
  const { mainSiteUrl } = window["runConfig"];

  return mainSiteUrl;
}
export function fullProductImageUrl(url) {
  if (url === undefined || url === null) return "";

  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    const { prodImgBaseUrl } = window["runConfig"];

    if (
      prodImgBaseUrl === undefined ||
      prodImgBaseUrl === null ||
      prodImgBaseUrl === ""
    ) {
      return url;
    } else {
      return prodImgBaseUrl + url;
    }
  }
}

export function previewImgBaseUrl() {
  const { previewImgBaseUrl } = window["runConfig"];
  return previewImgBaseUrl;
}
export function manualBaseUrl() {
  const { manualBaseUrl } = window["runConfig"];
  return manualBaseUrl;
}
export function faqImgBaseUrl() {
  const { faqImgBaseUrl } = window["runConfig"];

  return faqImgBaseUrl;
}
export function prodImgBaseUrl() {
  const { prodImgBaseUrl } = window["runConfig"];
  return prodImgBaseUrl;
}
export function useProductFilter() {
  const { useProductFilter } = window["runConfig"];
  return useProductFilter;
}
export function getIconBaseUrl() {
  const { iconBaseUrl } = window["runConfig"];

  if (iconBaseUrl !== undefined && iconBaseUrl !== null) {
    return iconBaseUrl;
  } else {
    return "/icons/";
  }
}
export function priceColor() {
  const { priceColor } = window["runConfig"];

  return priceColor;
}
export function maxWidth() {
  const { maxWidth } = window["runConfig"];

  return maxWidth;
}
export function loginRequiredForPayment() {
  const { loginRequiredForPayment } = window["runConfig"];

  return loginRequiredForPayment;
}
export function allowPmtStatusChange() {
  const { allowPmtStatusChange } = window["runConfig"];
  return allowPmtStatusChange;
}
export function thumbnailImgWidth() {
  const { thumbnailImgWidth } = window["runConfig"];

  return thumbnailImgWidth;
}
export function getLeftMargin() {
  const { desktopLeftMargin } = window["runConfig"];

  return desktopLeftMargin;
}
export function rateToPercent(rate) {
  return "" + (rate * 100.0).toFixed(1) + "%";
}

export function getGroupWrapperWidth() {
  const { maxWidth } = window["runConfig"];
  const topContainerWidth =
    window.innerWidth > maxWidth ? maxWidth : window.innerWidth;
  const grpWrapperWidth = topContainerWidth - getLeftMargin() * 2;

  return grpWrapperWidth;
}

export function setTopContainerWrapperSettings() {
  const { maxWidth, sliderPreColor, sliderNextColor } = window["runConfig"];
  const topContainerWidth =
    window.innerWidth > maxWidth ? maxWidth : window.innerWidth;
  const grpWrapperWidth = topContainerWidth - getLeftMargin() * 2;

  document.documentElement.style.setProperty(
    "--top-container-width",
    "" + topContainerWidth + "px"
  );

  document.documentElement.style.setProperty(
    "--group-wrapper-width",
    "" + grpWrapperWidth + "px"
  );

  document.documentElement.style.setProperty(
    "--slider-pre-color",
    sliderPreColor
  );
  document.documentElement.style.setProperty(
    "--slider-next-color",
    sliderNextColor
  );
  document.documentElement.style.setProperty(
    "--faq-answer-width",
    "" + (grpWrapperWidth - 30) + "px"
  );
}
export function isUserLoggedIn(userState) {
  if (userState && userState.isLoggedIn === true && userState.userId) {
    return true;
  }
  return false;
}
export function getSoldoutIcon() {
  const { soldout_icon } = window["runConfig"];

  return soldout_icon;
}
function toD2Str(num) {
  let str = "";
  if (num < 10) str = str + "0";
  str = str + num;
  return str;
}

function toDTStr(date) {
  const test = "" + date.getFullYear();

  if (test === "NaN") {
    return date.toString();
  } else {
    let str =
      "" +
      date.getFullYear() +
      "-" +
      toD2Str(date.getMonth() + 1) +
      "-" +
      toD2Str(date.getDate()) +
      " " +
      toD2Str(date.getHours()) +
      ":" +
      toD2Str(date.getMinutes()) +
      ":" +
      toD2Str(date.getSeconds());
    return str;
  }
}

export function toTimeStr(dtStr) {
  let str = "";
  if (dtStr) {
    const date = new Date(dtStr);
    //str = toDTStr(date);
    str = date.toLocaleDateString();
    if (str == "Invalid Date") {
      str = dtStr;
      if (str.length > 16) {
        str = dtStr.substring(0, 10) + " " + dtStr.substring(11, 16);
      }
    } else {
      str += " " + date.toLocaleTimeString();
    }
  }

  return str;
}

export function toCurrentDTStr() {
  const date = new Date();
  return toDTStr(date);
}

export function toDateStr(date) {
  try {
    return date.toISOString().split("T")[0];
  } catch (error) {
    //ignore
  }
  let dateStr = "" + date.getFullYear() + "-";
  if (date.getMonth() < 9) dateStr += "0";
  dateStr += "" + (1 + date.getMonth()) + "-";
  if (date.getDate() < 10) dateStr += "0";
  dateStr += "" + date.getDate();
  return dateStr;
}

export function toDateStrEx(date) {
  let dtStr = "";
  if (date && date.length > 0) {
    if (date instanceof Date) {
      dtStr = date.toISOString().split("T")[0];
    } else {
      dtStr = new Date(date).toISOString().split("T")[0];
    }
  }
  return dtStr;
}

export function toAmount(x) {
  const amount = x < 0 ? 0 - x : x;
  let str = "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const len = str.length;
  const pos = str.indexOf(".");

  if (pos > 0) {
    if (pos + 3 < len) {
      str = str.substring(0, pos + 3);
    } else if (pos + 2 === len) {
      str = str + "0";
    }
  } else str = str + ".00";
  if (x < 0) str = "-" + str;
  return str;
}
export function toAmountEx(x) {
  let amt = x;
  let sign = "";

  if (amt < 0) {
    amt = 0 - amt;
    sign = "-";
  }
  const comma = amt >= 1000 ? true : false;
  let amtStr = "" + amt.toFixed(2);
  if (comma) {
    const pos = amtStr.length - 6;
    amtStr = amtStr.substring(0, pos) + "," + amtStr.substring(pos);
  }
  let str = sign + "$" + amtStr;
  if (str == "-$0.00") str = "$0.00";
  return str;
}

export function isMobileMode() {
  const { mobileWidthThreshold } = window["runConfig"];

  if (window.innerWidth <= mobileWidthThreshold) return true;
  else return false;
}
export function getCompressSetting() {
  const { compressMaxSize, compressQuality, compressMaxWidth } =
    window["runConfig"];

  return {
    maxSize: compressMaxSize,
    quality: compressQuality,
    maxWidth: compressMaxWidth,
  };
}

export function getFileSizeThreshold() {
  const { fileSizeThreshold } = window["runConfig"];
  return fileSizeThreshold;
}
export function isEmptyStr(str) {
  if (str && str.trim().length > 0) return false;
  return true;
}

export function parseAddress(address) {
  let numIdx = -1;
  let routeIdx = 1;
  let cityIdx = 2;
  let stateIdx = 4;
  let zipIdx = 6;

  if (address.length > 6 && address[6].types[0] === "country") {
    cityIdx++;
    stateIdx++;
    zipIdx++;
  }
  try {
    for (let i = 0; i < address.length; i++) {
      if (address[i].types) {
        if (address[i].types.includes("locality")) cityIdx = i;
        else if (address[i].types.includes("administrative_area_level_1"))
          stateIdx = i;
        else if (address[i].types.includes("postal_code")) zipIdx = i;
        else if (address[i].types.includes("street_number")) numIdx = i;
        else if (address[i].types.includes("route")) routeIdx = i;
      }
    }
  } catch (error2) {
    numIdx = 0;
    console.log(error2);
  }
  let addressLine1 = "";
  if (numIdx >= 0) addressLine1 = address[numIdx].short_name + " ";
  addressLine1 += address[routeIdx].long_name;

  const addrObj = {
    addressLine1,
    city: address[cityIdx].short_name,
    state: address[stateIdx].short_name,
    zipCode: address[zipIdx].short_name,
  };
  return addrObj;
}

export function formatCurrency(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      "$" +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
    if (amount < 0) return "-$" + (0 - amount);
    else return "$" + amount;
  }
}
export function centerAroundWarehouse() {
  const { centerAroundWH } = window["runConfig"];

  return centerAroundWH;
}

export function getOrderBalance(coi) {
  const order = coi.order;
  const storeItems = coi.storeItemList;
  const payments = coi.paymentList;
  let balance = order.totalPrice;

  if (!coi.layaway || order.storeId == 0) {
    //For store layaway, the payments are included in storeItems
    if (payments && payments.length > 0) {
      for (let i = 0; i < payments.length; i++) {
        balance -= payments[i].amount;
      }
    }
  }
  if (storeItems != null) {
    for (let i = 0; i < storeItems.length; i++) {
      //typeId=9 is online payment which already included in payments
      if (storeItems[i].typeId > 0 && storeItems[i].typeId != 9) {
        balance -= storeItems[i].price;
      }
    }
  }
  return balance;
}

export function getItemCount(coi) {
  let count = 0;
  if (coi) {
    const orderItems = coi.orderItemList;
    if (orderItems) {
      for (let i = 0; i < orderItems.length; i++) {
        count += orderItems[i].item.quantity;
      }
    }
    const storeItems = coi.storeItemList;
    if (storeItems) {
      for (let i = 0; i < storeItems.length; i++) {
        if (storeItems[i].quantity > 0 && storeItems[i].typeId < 1) {
          count += storeItems[i].quantity;
        }
      }
    }
  }
  return count;
}
