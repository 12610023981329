import React from "react";
import { connect } from "react-redux";
import { isUserLoggedIn } from "../Util";
import EditHSProductList from "./EditHSProductList";

class EditHomepageProd extends React.Component {
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
  }

  render() {
    return (
      <EditHSProductList name="HomepageProd" displayName="Homepage Products" />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userState: state.user,
  };
};

export default connect(mapStateToProps)(EditHomepageProd);
