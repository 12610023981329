import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  useProductFilter,
} from "../Util";
import { checkUpdate } from "../SecManager";
import { setCategory } from "../actions/searchActions";
import { setLoggedOut } from "../actions/userActions";
import Thumbnail from "./Thumbnail";
import Working from "../Working";
import "../../generic.css";
import "../../prodByCat.css";

class ProductsByCat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      origItems: null,
      sortedItems: null,
      isLoading: true,
      filterList: null,
      isLoadingFL: true,
      sort: false,
      backendApi: "ProdByCat",
      mobileMode: false,
      editAllowed: checkUpdate("Product"),
    };
    this.fetchItems = this.fetchItems.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchItems(this.state.backendApi);

    if (useProductFilter()) {
      const url = apiBaseUrl() + "GetFilterList";
      const req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
      };

      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.setState({
            filterList: res.data,
            isLoadingFL: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ isLoadingFL: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  componentDidUpdate(preProps) {
    if (this.props.match.params.id !== preProps.match.params.id) {
      this.setState({ isLoading: true, items: null });
      this.fetchItems(this.state.backendApi);
    }
  }

  handleChange(event) {
    if (event.target.checked) {
      if (this.state.sortedItems) {
        this.setState({ items: this.state.sortedItems, sort: true });
      } else {
        let sortedItems = [...this.state.origItems];
        sortedItems.sort(
          (a, b) =>
            b.rankingInfo.finalRankingScore - a.rankingInfo.finalRankingScore
        );
        this.setState({
          items: sortedItems,
          sortedItems,
          sort: true,
        });
      }
    } else {
      this.setState({ items: this.state.origItems, sort: false });
    }
  }

  fetchItems(backendApi) {
    const url = apiBaseUrl() + backendApi;
    this.props.setCategory(this.props.match.params.id);
    const req = {
      id: parseInt(this.props.match.params.id, 10),
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          const objList = res.data.objList;
          this.setState({
            items: objList,
            origItems: objList,
            sortedItems: null,
            sort: false,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  filterChanged(event) {
    this.setState({ backendApi: event.target.value });
    this.fetchItems(event.target.value);
  }

  showBody() {
    if (!this.state.items) {
      return <div>No products found.</div>;
    } else {
      return (
        <div className="prodbycat-wrapper">
          {this.state.items.map((item, idx) => {
            return (
              <Thumbnail prod={item} key={idx} showRanking={this.state.sort} />
            );
          })}
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading === true || this.state.isLoadingFL === true)
      return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div className="flex-wrapper-top">
            {this.state.editAllowed && (
              <div className="db-title">
                <Link to="/editProduct/+">
                  <label className="blue-title">+Add New Product</label>
                </Link>
              </div>
            )}
            <div className="width-20"></div>
            {useProductFilter() && (
              <React.Fragment>
                <div>Filter:&nbsp;&nbsp;</div>
                <div>
                  <select
                    name="filter"
                    value={this.state.backendApi}
                    onChange={this.filterChanged}
                  >
                    {this.state.filterList.map((filter) => {
                      return (
                        <option
                          value={filter.backendApi}
                          key={filter.backendApi}
                        >
                          {filter.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </React.Fragment>
            )}
            <div>
              <input
                type="checkbox"
                name="sort"
                defaultChecked={this.state.sort}
                onChange={this.handleChange}
              ></input>
              Sort By Ranking Scores
            </div>
          </div>
          {this.showBody()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
    setCategory: (categoryId) => {
      dispatch(setCategory(categoryId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsByCat);
