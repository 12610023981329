import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  fullProductImageUrl,
  thumbnailImgWidth,
  getIconBaseUrl,
} from "../Util";
import RankingInfoDialog from "./RankingInfoDialog";
import "../../App.css";
import "../../generic.css";

class Thumbnail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: this.props.prod,
      showRanking: this.props.showRanking,
    };
    this.showRankingDetail = this.showRankingDetail.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.prod !== prevProps.prod ||
      this.props.showRanking !== prevProps.showRanking
    ) {
      this.setState({
        item: this.props.prod,
        showRanking: this.props.showRanking,
      });
    }
  }
  showRankingDetail(event) {
    event.preventDefault();

    const btnId = "hiddenDialogBtn" + this.state.item.id;
    document.getElementById(btnId).click();
  }
  render() {
    const item = this.state.item;
    const imgUrl = fullProductImageUrl(item.imageUrl);
    const pvUrl = "/product/" + item.id;
    const editUrl = "/editProduct/" + item.id;
    const editComboUrl = item.comboExists
      ? "/editCombo/" + item.comboId + "/" + item.id
      : null;
    const comboImg = getIconBaseUrl() + "combo.jpg";
    const btnId = "hiddenDialogBtn" + item.id;
    const actBtnId = "riDialogBtn" + item.id;
    return (
      <div className="generic-flex" key={item.id}>
        <div className="generic-item">
          <Link to={pvUrl}>
            <img src={imgUrl} width={thumbnailImgWidth()} alt="ProdImg"></img>
          </Link>
        </div>
        <div className="generic-item">
          <div>
            {item.grade && (
              <img
                src={getIconBaseUrl() + item.grade + ".jpg"}
                height={14}
              ></img>
            )}
            Product ID : <Link to={editUrl}>{item.id}</Link>
            {this.state.showRanking && (
              <span className="span-nodec">
                &nbsp;(
                <a href="#" onClick={this.showRankingDetail}>
                  <b>{item.rankingInfo.finalRankingScore}</b>
                </a>
                )
              </span>
            )}
          </div>
          <div>{item.name}</div>

          {item.pvList.map((pv) => {
            let forSale = pv.forSale;

            if (item.useQuantity && pv.unitsInStock <= 0) {
              forSale = false;
            }

            const clsName = forSale ? "greenCircle" : "redCircle";
            let editSkuUrl = null;
            let editBtUrl = null;
            const btImg = getIconBaseUrl() + "buyTogether2.jpg";

            if (pv.origPvId <= 0 && pv.btExists) {
              editBtUrl = "/editBt/" + pv.uniqueKey;
            }

            if (item.pvList.length > 1) {
              if (pv.origPvId > 0) {
                if (pv.origMvProduct) {
                  editSkuUrl = "/editVariant/" + pv.origPvId;
                } else {
                  //Single variant
                  editSkuUrl = "/editProduct/" + pv.origProductId;
                }
              } else {
                editSkuUrl = "/editVariant/" + pv.id;
              }
            }
            return (
              <div className="flex-wrapper" key={pv.sku}>
                <div className={clsName}></div>
                {editSkuUrl ? <Link to={editSkuUrl}>{pv.sku}</Link> : pv.sku}
                &nbsp;
                {pv.origPvId > 0 && (
                  <img src="/icons/SymLink.jpg" height="15"></img>
                )}
                {editBtUrl && (
                  <a href={editBtUrl}>
                    <img src={btImg} height="15"></img>
                  </a>
                )}
                {editComboUrl && (
                  <a href={editComboUrl}>
                    <img src={comboImg} height="15"></img>
                  </a>
                )}
              </div>
            );
          })}
        </div>
        <RankingInfoDialog
          btnId={btnId}
          actBtnId={actBtnId}
          rankingInfo={this.state.item.rankingInfo}
        />
      </div>
    );
  }
}

export default withRouter(Thumbnail);
