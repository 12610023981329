import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import {
  apiBaseUrl,
  isUserLoggedIn,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
  isMobileMode,
  faqImgBaseUrl,
  previewImgBaseUrl,
} from "../Util";
import { uploadFiles } from "../FileUploader";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../session.css";
import "../../faq.css";

class EditFAQ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      faqList: null,
      catList: null,
      updBtnEnabled: false,
      mobileMode: false,
      isLoading: true,
      isLoadingCat: true,
      errorMessage: null,
      viewAllowed: checkView("Product"),
      editAllowed: checkUpdate("Product"),
    };
    this.processResponse = this.processResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.updateFaq = this.updateFaq.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.previewFaq = this.previewFaq.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchList();
    this.getCategories();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    setTopContainerWrapperSettings();
    this.setState({ mobileMode: isMobileMode() });
  }
  fetchList() {
    const url = apiBaseUrl() + "GetFaqList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCategories() {
    const url = apiBaseUrl() + "Categories";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          const objList = res.data.objList;
          if (objList) {
            this.setState({
              catList: objList,
              isLoadingCat: false,
            });
          } else {
            this.setState({
              catList: [{ id: 1, name: "InStock" }],
              isLoadingCat: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      let faqList = [];
      let maxSeq = 0;
      if (response.objList) {
        for (const [idx, faq] of response.objList.entries()) {
          if (faq.sequence > maxSeq) maxSeq = faq.sequence;

          let answerList = faq.answerList;
          let maxSeq2 = 0;
          if (!answerList || answerList.length === 0) answerList = [];
          else {
            maxSeq2 = answerList[answerList.length - 1].sequence;
          }
          const na = {
            id: 0,
            faqId: faq.id,
            sequence: maxSeq2 + 1,
          };
          answerList.push(na);
          const nf = {
            ...faq,
            answerList,
          };
          faqList.push(nf);
        }
      }
      const newItem = {
        id: 0,
        categoryId: 0,
        question: null,
        sequence: maxSeq + 1,
      };
      faqList.push(newItem);
      this.setState({ faqList, isLoading: false });
    }
  }
  processUploadResponse(response, fileName, faqIdx, answerIdx) {
    if (response.status) {
      let faqList = [];
      for (const [i, faq] of this.state.faqList.entries()) {
        if (i === faqIdx) {
          let answerList = [];
          for (const [k, answer] of faq.answerList.entries()) {
            if (k === answerIdx) {
              const updatedAnswer = {
                ...answer,
                imageUrl: fileName,
                updated: true,
                imageUpdated: true,
              };
              answerList.push(updatedAnswer);
            } else {
              answerList.push(answer);
            }
          }
          const updatedFaq = {
            ...faq,
            answerList,
          };
          faqList.push(updatedFaq);
        } else {
          faqList.push(faq);
        }
      }
      this.setState({ faqList });
    } else {
      console.log("Upload failed");
    }
  }
  updateFaq(idx, action) {
    const faq = this.state.faqList[idx];
    const url = apiBaseUrl() + action + "Faq";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: faq,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateAnswer(faqIdx, answerIdx, action) {
    const answer = this.state.faqList[faqIdx].answerList[answerIdx];
    const url = apiBaseUrl() + action + "Answer";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: answer,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleClick(faqIdx, answerIdx) {
    if (answerIdx < 0) {
      let faqList = [];
      for (const [i, faq] of this.state.faqList.entries()) {
        if (i === faqIdx) {
          const item = {
            ...faq,
            expanded: !faq.expanded,
          };
          faqList.push(item);
        } else faqList.push(faq);
      }
      this.setState({ faqList });
    } else {
      let faqList = [];
      for (const [i, faq] of this.state.faqList.entries()) {
        if (i === faqIdx) {
          let answerList = [];
          for (const [k, answer] of faq.answerList.entries()) {
            if (k === answerIdx) {
              const newA = {
                ...answer,
                expanded: !answer.expanded,
              };
              answerList.push(newA);
            } else answerList.push(answer);
          }
          const item = {
            ...faq,
            answerList,
          };
          faqList.push(item);
        } else faqList.push(faq);
      }
      this.setState({ faqList });
    }
  }

  expandedAnswer(faqIdx, answerIdx) {
    const faq = this.state.faqList[faqIdx];
    const answer = faq.answerList[answerIdx];
    const taName = "Answer_" + faqIdx + "_" + answerIdx + "_answer";
    const imgName = "Answer_" + faqIdx + "_" + answerIdx + "_imageUrl";
    const widthName = "Answer_" + faqIdx + "_" + answerIdx + "_widthSpec";
    const seqName = "Answer_" + faqIdx + "_" + answerIdx + "_sequence";
    const titleName = "Answer_" + faqIdx + "_" + answerIdx + "_title";
    const btnTitle = answer.id > 0 ? "Update" : "Add Answer";
    const btnStyle = answer.updated ? "blue-btn" : "disabled-btn";
    let imageUrl = answer.imageUrl;

    if (imageUrl) {
      if (answer.imageUpdated) imageUrl = previewImgBaseUrl() + imageUrl;
      else imageUrl = faqImgBaseUrl() + imageUrl;
    }
    const mm = this.state.mobileMode;
    const clsName = mm ? "none" : "twocol-table";
    const cols = mm ? 50 : 80;
    const rows = mm ? 15 : 10;
    const imgLabel = mm ? "" : "Image: ";
    return (
      <div>
        <div className={clsName}>
          {!mm && <div>Sequence:</div>}
          <div className="left-10">
            <input
              size="4"
              name={seqName}
              onChange={this.handleChange}
              value={answer.sequence}
            ></input>
          </div>
          {!mm && <div>Title:</div>}
          <div className="left-10">
            <input
              size="25"
              name={titleName}
              placeholder="Title"
              onChange={this.handleChange}
              value={answer.title || ""}
            ></input>
          </div>
          {!mm && (
            <div>
              Answer <font color="red">*</font>:
            </div>
          )}
          <div className="left-10">
            <textarea
              cols={cols}
              rows={rows}
              name={taName}
              onChange={this.handleChange}
              value={answer.answer || ""}
            ></textarea>
          </div>
          {imageUrl && (
            <React.Fragment>
              {!mm && <div>Image Width:</div>}
              <div className="left-10">
                <input
                  size="5"
                  name={widthName}
                  placeholder="Width"
                  onChange={this.handleChange}
                  value={answer.widthSpec || ""}
                ></input>{" "}
                (Number or percent)
              </div>
            </React.Fragment>
          )}
          <div>
            {imageUrl ? (
              <img
                src={imageUrl}
                width="80"
                height="60"
                alt="AnswerImage"
              ></img>
            ) : (
              imgLabel
            )}
          </div>
          <div className="left-10">
            <input
              type="file"
              name={imgName}
              onChange={this.handleChange}
            ></input>
            &nbsp;
            <button
              className={btnStyle}
              name="update"
              disabled={!answer.updated}
              onClick={() => this.updateAnswer(faqIdx, answerIdx, "Update")}
            >
              {btnTitle}
            </button>
            &nbsp;&nbsp;
            {answer.id > 0 && (
              <button
                className="red-btn"
                name="delete"
                disabled={!this.state.viewAllowed}
                onClick={() => this.updateAnswer(faqIdx, answerIdx, "Delete")}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  showOneAnswer(faqIdx, answerIdx) {
    const faq = this.state.faqList[faqIdx];
    const answer = faq.answerList[answerIdx];
    const icon = answer.expanded ? "minus.jpg" : "plus.jpg";
    const key = "" + faqIdx + "." + answerIdx;
    let text;
    if (!answer.answer) text = "[Add An Answer]";
    else {
      if (answer.title) text = answer.title;
      else text = "Answer " + (answerIdx + 1);
    }
    return (
      <div className="generic-flex" key={key}>
        <div>
          <img
            src={getIconBaseUrl() + icon}
            width="20"
            onClick={() => this.handleClick(faqIdx, answerIdx)}
            alt="PM"
          ></img>
        </div>
        {answer.expanded ? (
          this.expandedAnswer(faqIdx, answerIdx)
        ) : (
          <div className="left-10">
            <font size="4">{text}</font>
          </div>
        )}
      </div>
    );
  }
  expandedFaq(faqIdx) {
    const faq = this.state.faqList[faqIdx];
    const list = faq.answerList;
    const name = "FAQ_" + faqIdx + "_question";
    const seqName = "FAQ_" + faqIdx + "_sequence";
    const catName = "FAQ_" + faqIdx + "_categoryId";
    const btnTitle = faq.id > 0 ? "Update" : "Add FAQ";
    const btnStyle = faq.updated ? "blue-btn" : "disabled-btn";
    const canDelete = faq.id > 0 && list.length < 2;
    return (
      <div className="left-10">
        <div className="twocol-wrapper">
          <div className="form-item-label">
            Question <font color="red">*</font>:
          </div>
          <div className="form-item-value">
            <input
              type="text"
              size="30"
              name={name}
              value={faq.question || ""}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="form-item-label">Category:</div>
          <div className="form-item-value">
            <select
              name={catName}
              value={faq.categoryId}
              onChange={this.handleChange}
            >
              <option value="">[Category]</option>
              {this.state.catList.map((cat) => {
                return (
                  <option value={cat.category.id} key={cat.category.id}>
                    {cat.category.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-item-label">Sequence:</div>
          <div className="form-item-value">
            <input
              type="text"
              size="4"
              name={seqName}
              value={faq.sequence}
              onChange={this.handleChange}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              name="update"
              className={btnStyle}
              disabled={!faq.updated || !this.state.editAllowed}
              onClick={() => this.updateFaq(faqIdx, "Update")}
            >
              {btnTitle}
            </button>
            &nbsp;&nbsp;
            {canDelete && (
              <button
                name="delete"
                className="red-btn"
                disabled={!this.state.editAllowed}
                onClick={() => this.updateFaq(faqIdx, "Delete")}
              >
                Delete
              </button>
            )}
          </div>
        </div>
        {list &&
          list.map((answer, answerIdx) =>
            this.showOneAnswer(faqIdx, answerIdx)
          )}
      </div>
    );
  }

  showOneFaq(idx) {
    const icon = this.state.faqList[idx].expanded ? "minus.jpg" : "plus.jpg";
    const faq = this.state.faqList[idx];
    const cnt = faq.answerList ? faq.answerList.length - 1 : 0;
    const question = faq.question
      ? faq.question + " (" + cnt + ")"
      : "[Add New FAQ]";
    return (
      <div className="top-10" key={idx}>
        <div className="generic-flex">
          <div>
            <img
              src={getIconBaseUrl() + icon}
              width="20"
              onClick={() => this.handleClick(idx, -1)}
              alt="PM"
            ></img>
          </div>
          {this.state.faqList[idx].expanded ? (
            this.expandedFaq(idx)
          ) : (
            <div className="left-10">
              <font size="4">{question}</font>
            </div>
          )}
        </div>
      </div>
    );
  }
  handleChange(event) {
    let name = event.target.name;
    if (name.startsWith("FAQ_")) {
      name = name.substring(4);
      const pos = name.indexOf("_");
      const idx = parseInt(name.substring(0, pos));
      let faqList = [];

      name = name.substring(pos + 1);
      for (const [i, faq] of this.state.faqList.entries()) {
        if (i === idx) {
          const item = {
            ...faq,
            [name]: event.target.value,
            updated: true,
          };
          faqList.push(item);
        } else {
          faqList.push(faq);
        }
      }
      this.setState({ faqList });
    } else if (name.startsWith("Answer_")) {
      name = name.substring(7);
      let pos = name.indexOf("_");
      const faqIdx = parseInt(name.substring(0, pos));
      name = name.substring(pos + 1);
      pos = name.indexOf("_");
      const aIdx = parseInt(name.substring(0, pos));
      name = name.substring(pos + 1);

      if (name === "imageUrl") {
        uploadFiles(
          event.target.files,
          this.processUploadResponse,
          faqIdx,
          aIdx
        );
      } else {
        let faqList = [];

        for (const [i, faq] of this.state.faqList.entries()) {
          if (i === faqIdx) {
            let answerList = [];
            for (const [k, answer] of faq.answerList.entries()) {
              if (k === aIdx) {
                const item = {
                  ...answer,
                  [name]: event.target.value,
                  updated: true,
                };
                answerList.push(item);
              } else answerList.push(answer);
            }
            const updatedFaq = {
              ...faq,
              answerList,
            };
            faqList.push(updatedFaq);
          } else {
            faqList.push(faq);
          }
        }
        this.setState({ faqList });
      }
    }
  }

  theForm() {
    return (
      <div className="form-container">
        <div>
          {this.state.faqList.map((faq, idx) => {
            return this.showOneFaq(idx);
          })}
        </div>
      </div>
    );
  }
  previewFaq() {
    const url = "/previewFaq";
    this.props.history.push(url);
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-container">
        <div className="top-wrapper">
          <font size="5">FAQ</font>
          <p />
          {this.theForm()}
          <p />
          {this.state.faqList && this.state.faqList.length > 1 && (
            <button
              className="btn-style"
              name="preview"
              onClick={this.previewFaq}
            >
              Preview
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    categoryId: state.search.categoryId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditFAQ);
