import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import OrderSearchWidget from "./OrderSearchWidget";
import {
  apiBaseUrl,
  isUserLoggedIn,
  toAmount,
  getIconBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView, checkUpdate } from "../SecManager";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class ManageOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyList: [],
      expanded: [],
      orderStatusList: null,
      isLoading: true,
      errorMessage: null,
      mobileMode: false,
      editAllowed: checkUpdate("Order"),
      viewAllowed: checkView("Order"),
    };
    this.fetchOrders = this.fetchOrders.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.getOrderStatusList = this.getOrderStatusList.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (
      isUserLoggedIn(this.props.userState) === false ||
      !this.state.viewAllowed
    ) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.getOrderStatusList();
    this.fetchOrders();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    let expanded = [];
    if (response.objList && response.objList.length > 0) {
      let i;
      for (i = 0; i < response.objList.length; i++) {
        if (i === 0) expanded.push(true);
        else expanded.push(false);
      }
    }
    this.setState({
      monthlyList: response.objList,
      expanded,
      isLoading: false,
    });
  }
  fetchOrders() {
    const url = apiBaseUrl() + "GetOrders";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getOrderStatusList() {
    const url = apiBaseUrl() + "GetOrderStatusList";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          this.setState({ orderStatusList: res.data.objList });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getName(order) {
    if (order.contactAddress) {
      if (this.state.mobileMode) {
        return (
          order.contactAddress.firstName.substring(0, 1) +
          order.contactAddress.lastName.substring(0, 1)
        );
      } else {
        return (
          order.contactAddress.firstName + " " + order.contactAddress.lastName
        );
      }
    }
  }
  getIcon(statusId) {
    const id = statusId > 0 ? statusId : 1;
    if (this.state.orderStatusList) {
      const orderStatus = this.state.orderStatusList.find(
        (status) => id === status.id
      );
      if (orderStatus) {
        const url = getIconBaseUrl() + orderStatus.icon;
        return url;
      }
    }
    return null;
  }
  handleClick(idx) {
    let expanded = [];
    let i;
    for (i = 0; i < this.state.expanded.length; i++) {
      if (i === idx) expanded.push(!this.state.expanded[i]);
      else expanded.push(this.state.expanded[i]);
    }
    this.setState({ expanded });
  }
  oneHtmlRow(order) {
    const url = "/orderDetail/" + order.summary.orderNumber;
    const timestamp = this.state.mobileMode
      ? order.summary.mobileDateStr
      : order.summary.dateStr;
    const imgUrl = this.getIcon(order.summary.statusId);
    let orderNum = order.summary.orderNumber;
    if (this.state.mobileMode && orderNum.length > 10) {
      orderNum = orderNum.substring(7);
    }
    let html =
      '<tr><td><img src="' + imgUrl + '" alt="Status" width="24"></img></td>';
    html += '<td><a href="' + url + '">' + orderNum + "</a></td>";
    html += "<td>" + timestamp + "</td>";
    html += "<td>" + this.getName(order) + "</td>";
    html += '<td align="right">' + toAmount(order.summary.totalPrice) + "</td>";
    html += "</tr>";
    return html;
  }
  showTable(monthOrders) {
    let i;
    const orderList = monthOrders.list;
    const nLabel = this.state.mobileMode ? "NM" : "Name";
    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html += '<table><tr><th style="background-color: #D0D0D0">&nbsp;</th>';
    html += '<th style="background-color: #D0D0D0" align="left">Order#</th>';
    html += '<th style="background-color: #D0D0D0" align="left">Time</th>';
    html +=
      '<th style="background-color: #D0D0D0" align="left">' + nLabel + "</th>";
    html += '<th style="background-color: #D0D0D0" align="right">Amount</th>';
    html += "</tr>";

    for (i = 0; i < orderList.length; i++) {
      html += this.oneHtmlRow(orderList[i]);
    }
    html += "</table>";

    return (
      <div
        className="left-8"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    );
  }
  showHeader(monthOrders) {
    return (
      <div>
        <b>
          &nbsp;{monthOrders.name} {monthOrders.list.length}
        </b>
      </div>
    );
  }
  showOrders() {
    if (!this.state.monthlyList || this.state.monthlyList.length === 0)
      return <div>No orders found</div>;

    return (
      <React.Fragment>
        {this.state.monthlyList.map((monthOrders, idx) => {
          const icon = this.state.expanded[idx] ? "minus.jpg" : "plus.jpg";
          return (
            <div className="bottom-15" key={idx}>
              <div className="generic-flex">
                <div>
                  <img
                    src={getIconBaseUrl() + icon}
                    width="20"
                    onClick={() => this.handleClick(idx)}
                    alt="PM"
                  ></img>
                </div>
                <div>
                  {this.state.expanded[idx]
                    ? this.showTable(monthOrders)
                    : this.showHeader(monthOrders)}
                </div>
                <p />
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Orders</font>
          <p />
          <OrderSearchWidget searchType={1} />
          <br />
          {this.showOrders()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageOrders);
