import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
  getIconBaseUrl,
  getMainSiteUrl,
} from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import ImageMapItemWidget from "./ImageMapItemWidget";
import ImageMapWidget from "./ImageMapWidget";
import DialogBox from "../DialogBox";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../inventory.css";

class ManageFlyers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      flyerList: null,
      pvInfoList: null,
      largeImgWidth: 400,
      addNewExpanded: false,
      refreshBtnEnabled: false,
      hdnDialogBtnId: "hdnDialogBtnId_" + Math.random(),
      dialogTitle: "Action Perform Failed",
      dialogMessage: "An error has occurred!",
      viewAllowed: checkView("Setting"),
      updateAllowed: checkUpdate("Setting"),
    };

    this.adjustMode = this.adjustMode.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getPVInfoList = this.getPVInfoList.bind(this);
    this.imageMapUpdated = this.imageMapUpdated.bind(this);
    this.imageMapDeleted = this.imageMapDeleted.bind(this);
    this.imageMapItemUpdated = this.imageMapItemUpdated.bind(this);
    this.imageMapItemDeleted = this.imageMapItemDeleted.bind(this);
    this.checkRefreshStatus = this.checkRefreshStatus.bind(this);
    this.refreshMapForMain = this.refreshMapForMain.bind(this);
    this.showErrorDialog = this.showErrorDialog.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }
    this.getImageMaps();
    this.getPVInfoList();
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.checkRefreshStatus();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  showErrorDialog(dialogTitle, dialogMessage) {
    this.setState({ dialogTitle, dialogMessage });
    document.getElementById(this.state.hdnDialogBtnId).click();
  }
  handleClick(idx1, idx2) {
    if (idx1 < 0) {
      const addNewExpanded = this.state.addNewExpanded ? false : true;
      if (addNewExpanded) {
        let flyerList = this.state.flyerList;
        for (let i = 0; i < flyerList.length; i++) {
          flyerList[i].expanded = false;
        }
        this.setState({ addNewExpanded, flyerList });
      } else {
        this.setState({ addNewExpanded });
      }
    } else {
      let flyerList = this.state.flyerList;
      let flyer = flyerList[idx1];

      if (idx2 < -98) {
        flyer.newExpanded = flyer.newExpanded ? false : true;
      } else if (idx2 < 0) {
        flyer.expanded = flyer.expanded ? false : true;
      } else {
        flyer.itemList[idx2].expanded = flyer.itemList[idx2].expanded
          ? false
          : true;
      }
      this.setState({ flyerList });
    }
  }
  imageMapUpdated(map, idx) {
    let flyerList = [];

    if (idx < 0) {
      map = {
        ...map,
        expanded: true,
      };
      flyerList.push(map);
    }
    for (let i = 0; i < this.state.flyerList.length; i++) {
      let flyer;
      if (idx == i) flyer = map;
      else {
        flyer = this.state.flyerList[i];
        if (idx < 0) {
          flyer = {
            ...flyer,
            expanded: false,
          };
        }
      }
      flyerList.push(flyer);
    }
    const addNewExpanded = idx < 0 ? false : this.state.addNewExpanded;
    this.setState({ flyerList, addNewExpanded, refreshBtnEnabled: true });
  }
  imageMapDeleted(map, idx) {
    let flyerList = [];
    for (let i = 0; i < this.state.flyerList.length; i++) {
      if (idx != i) flyerList.push(this.state.flyerList[i]);
    }
    this.setState({ flyerList, refreshBtnEnabled: true });
  }
  imageMapItemUpdated(mapItem, passBack) {
    const idx1 = passBack[0];
    const idx2 = passBack[1];

    let flyerList = this.state.flyerList;

    if (idx2 >= 0) {
      flyerList[idx1].itemList[idx2] = mapItem;
    } else {
      if (!flyerList[idx1].itemList) {
        flyerList[idx1].itemList = [];
      }
      flyerList[idx1].itemList.push(mapItem);
      flyerList[idx1].newExpanded = false;
    }
    this.setState({ flyerList, refreshBtnEnabled: true });
  }
  imageMapItemDeleted(mapItem, passBack) {
    const idx1 = passBack[0];
    const idx2 = passBack[1];

    let flyerList = [];
    for (let i = 0; i < this.state.flyerList.length; i++) {
      let flyer = this.state.flyerList[i];
      if (idx1 == i) {
        let itemList = [];
        for (let j = 0; j < flyer.itemList.length; j++) {
          if (j != idx2) itemList.push(flyer.itemList[j]);
        }
        flyer.itemList = itemList;
      }
      flyerList.push(flyer);
    }
    this.setState({ flyerList, refreshBtnEnabled: true });
  }

  getImageMaps() {
    const url = apiBaseUrl() + "GetAllImageMaps";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get image maps",
        });
        console.log(error);
      });
  }
  checkRefreshStatus() {
    const url = apiBaseUrl() + "CheckRefreshMapStatus";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ refreshBtnEnabled: res.data.status });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  refreshMapForMain() {
    const url = apiBaseUrl() + "RefreshMapForMain";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.status) {
          this.setState({ refreshBtnEnabled: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getPVInfoList() {
    const url = apiBaseUrl() + "GetAllPVs";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data) {
          this.setState({ pvInfoList: res.data.objList });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get PV info list",
        });
        console.log(error);
      });
  }

  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      if (response.objList && response.objList.length > 0) {
        response.objList[0].expanded = true;
      }
      this.setState({ isLoading: false, flyerList: response.objList });
    }
  }

  showFlyerList() {
    const list = this.state.flyerList;
    if (list) {
      return (
        <div>
          {list.map((map, idx) => {
            const icon = map.expanded ? "minus.jpg" : "plus.jpg";
            return (
              <div className="bottom-15" key={idx}>
                <div className="generic-flex">
                  <div>
                    <img
                      src={getIconBaseUrl() + icon}
                      width="20"
                      onClick={() => this.handleClick(idx, -1)}
                      alt="PM"
                    ></img>
                  </div>
                  <div>
                    {map.expanded ? (
                      this.showFlyer(map, idx)
                    ) : (
                      <div className="generic-flex">
                        <div>
                          <img
                            src={fullProductImageUrl(map.imageURL)}
                            width={45}
                          />
                        </div>
                        <div className="left-5">
                          <b>
                            <font size="4">{map.mapName}</font>
                          </b>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
  addNewMapForm() {
    const icon = this.state.addNewExpanded ? "minus.jpg" : "plus.jpg";
    const flyer = {
      id: 0,
      mapID: "",
    };
    return (
      <div>
        {this.state.updateAllowed && (
          <div className="generic-flex">
            <div>
              <img
                src={getIconBaseUrl() + icon}
                width="20"
                onClick={() => this.handleClick(-1, -1)}
                alt="PM"
              ></img>
            </div>
            <div>
              <b>
                <font color="blue" size="4">
                  Add New Flyer
                </font>
              </b>
            </div>
          </div>
        )}
        <div>
          {this.state.addNewExpanded && (
            <div className="left-20">
              <div className="top-10"></div>
              <ImageMapWidget
                map={flyer}
                updateCallback={this.imageMapUpdated}
                deleteCallback={this.imageMapDeleted}
                errorCallback={this.showErrorDialog}
                passBack={-1}
                editAllowed={this.state.updateAllowed}
                userId={this.props.userId}
                sessionKey={this.props.sessionKey}
                setLoggedOut={this.props.setLoggedOut}
              />
              <div>
                <hr />
              </div>
            </div>
          )}
        </div>
        <div className="top-20"></div>
      </div>
    );
  }

  mapItemForm(idx1, idx2) {
    const item = idx2 >= 0 ? this.state.flyerList[idx1].itemList[idx2] : {};
    const passBack = [idx1, idx2];
    return (
      <div className="left-20">
        <ImageMapItemWidget
          mapItem={item}
          mobileMode={false}
          passBack={passBack}
          pvInfoList={this.state.pvInfoList}
          updateCallback={this.imageMapItemUpdated}
          deleteCallback={this.imageMapItemDeleted}
          errorCallback={this.showErrorDialog}
          editAllowed={this.state.updateAllowed}
          userId={this.props.userId}
          sessionKey={this.props.sessionKey}
          setLoggedOut={this.props.setLoggedOut}
        />
      </div>
    );
  }

  showFlyer(flyer, idx) {
    if (flyer) {
      const newIcon = flyer.newExpanded ? "minus.jpg" : "plus.jpg";
      const newItem = { mapID: flyer.mapID, isAdd: true };
      const key = flyer.mapID + "-" + idx;
      return (
        <div>
          <b>
            <font size="4">{flyer.mapName}</font>
          </b>
          <div className="twocol-wrapper">
            <div>
              <div>
                <a href={"/flyer/" + flyer.mapID} target="_blank">
                  <img
                    src={fullProductImageUrl(flyer.imageURL)}
                    width={this.state.largeImgWidth}
                  />
                </a>
              </div>
              <div className="top-20"></div>
              <ImageMapWidget
                map={flyer}
                updateCallback={this.imageMapUpdated}
                deleteCallback={this.imageMapDeleted}
                errorCallback={this.showErrorDialog}
                passBack={idx}
                editAllowed={this.state.updateAllowed}
                userId={this.props.userId}
                sessionKey={this.props.sessionKey}
                setLoggedOut={this.props.setLoggedOut}
              />
            </div>
            <div className="left-20" key={key}>
              {flyer.itemList &&
                flyer.itemList.map((item, idx2) => {
                  const icon = item.expanded ? "minus.jpg" : "plus.jpg";
                  let urlBase = getMainSiteUrl();

                  if (urlBase.endsWith("/")) {
                    urlBase = urlBase.substring(0, urlBase.length - 1);
                  }

                  let pvUrl = item.pvURL ? urlBase + item.pvURL : null;
                  let urlIcon =
                    item.productURL == item.pvURL
                      ? "green_url.jpg"
                      : "red_url.jpg";
                  let priceIcon =
                    item.adPrice - item.pvPrice > -2 &&
                    item.adPrice - item.pvPrice < 2
                      ? "green_dollar.jpg"
                      : "red_dollar.jpg";

                  return (
                    <div key={flyer.mapID + "." + idx2}>
                      <div className="generic-flex">
                        <div>
                          <img
                            src={getIconBaseUrl() + icon}
                            width="20"
                            onClick={() => this.handleClick(idx, idx2)}
                            alt="PM"
                          ></img>
                        </div>
                        <div>
                          <a href={urlBase + item.productURL} target="MainSite">
                            <img
                              src={fullProductImageUrl(item.adImageUrl)}
                              width="70"
                            />
                          </a>
                        </div>

                        <div>{item.productSKU}</div>
                        <div>
                          {pvUrl ? (
                            <a href={pvUrl} target="MainSite">
                              <img
                                src={fullProductImageUrl(item.pvImageUrl)}
                                width="70"
                              />
                            </a>
                          ) : (
                            <img
                              src={fullProductImageUrl(item.pvImageUrl)}
                              width="70"
                            />
                          )}
                        </div>
                        {item.productSKU && item.productSKU != "N/A" && (
                          <React.Fragment>
                            <div className="left-10">
                              <img
                                src={getIconBaseUrl() + urlIcon}
                                height="15"
                              />
                            </div>
                            <div className="left-5">
                              <img
                                src={getIconBaseUrl() + priceIcon}
                                height="15"
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                      {item.expanded && this.mapItemForm(idx, idx2)}
                    </div>
                  );
                })}
              {this.state.updateAllowed && (
                <React.Fragment>
                  <div className="generic-flex">
                    <div>
                      <img
                        src={getIconBaseUrl() + newIcon}
                        width="20"
                        onClick={() => this.handleClick(idx, -99)}
                        alt="PM"
                      ></img>
                    </div>
                    <div>
                      <font color="blue">
                        <b>Add New Item</b>
                      </font>
                    </div>
                  </div>
                  {flyer.newExpanded && (
                    <div className="left-20">
                      <ImageMapItemWidget
                        mapItem={newItem}
                        mobileMode={false}
                        passBack={[idx, -1]}
                        pvInfoList={this.state.pvInfoList}
                        updateCallback={this.imageMapItemUpdated}
                        deleteCallback={this.imageMapItemDeleted}
                        errorCallback={this.showErrorDialog}
                        editAllowed={this.state.updateAllowed}
                        userId={this.props.userId}
                        sessionKey={this.props.sessionKey}
                        setLoggedOut={this.props.setLoggedOut}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          <div>
            <hr />
          </div>
        </div>
      );
    }
  }
  render() {
    if (this.state.isLoading) return <Working />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div className="generic-flex">
            <div>
              <font size="5">Flyers</font>
            </div>
            {this.state.refreshBtnEnabled && (
              <div className="left-40">
                <button
                  name="refresh"
                  className="green-btn-style"
                  onClick={this.refreshMapForMain}
                >
                  Refresh Main Site
                </button>
              </div>
            )}
          </div>

          <p />
          {this.addNewMapForm()}
          {this.showFlyerList()}
        </div>
        <DialogBox
          hidden={true}
          btnId={this.state.hdnDialogBtnId}
          buttonTitle="Hidden"
          buttonClsName="btn-style"
          title={this.state.dialogTitle}
          message={this.state.dialogMessage}
          html={true}
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageFlyers);
