import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedOut } from "../actions/userActions";
import { apiBaseUrl, isMobileMode, isUserLoggedIn } from "../Util";
import { checkSecurity } from "../SecManager";

import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import "../../form.css";
import "../../generic.css";
import "../../App.css";

class MakePayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      number: "",
      cvv: "",
      amount: null,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "GA",
      zipCode: "",
      orderNumber: "",
      stateList: null,
      expMonth: null,
      expYear: null,
      submitBtnEnabled: true,
      errorMessage: null,
      allowed: checkSecurity("RootFeatures"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.makePayment = this.makePayment.bind(this);
    this.handleExpChange = this.handleExpChange.bind(this);
    this.setCurrentDateAsExpDate = this.setCurrentDateAsExpDate.bind(this);
    this.processResponse = this.processResponse.bind(this);
  }

  componentDidMount() {
    if (!isUserLoggedIn(this.props.userState)) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.allowed) {
      this.props.history.push("/");
      return;
    }

    this.setCurrentDateAsExpDate();

    const url = apiBaseUrl() + "StatesForPayment";
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ stateList: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getThisMonAsString() {
    const nm = new Date().getMonth() + 1;
    const str = nm < 10 ? "0" + nm : "" + nm;
    return str;
  }

  setCurrentDateAsExpDate() {
    const year = new Date().getFullYear();
    const mon = this.getThisMonAsString();

    this.setState({ expMonth: mon, expYear: year });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: null,
    });
  }
  processResponse(response) {
    if (response && response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      //TODO
    }
  }
  makePayment(event) {
    const url = apiBaseUrl() + "RemitPayment";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showStateList() {
    if (this.state.stateList === null) return "Georgia";
    else {
      return (
        <select
          name="state"
          value={this.state.state}
          onChange={this.handleChange}
        >
          {this.state.stateList.map((pair, idx) => {
            return (
              <option value={pair.value} key={idx}>
                {pair.key}
              </option>
            );
          })}
        </select>
      );
    }
  }

  handleExpChange(event) {
    let thisYear = new Date().getFullYear();
    const thisMon = this.getThisMonAsString();

    if (event.target.name === "year") {
      this.setState({ expYear: event.target.value });

      if (event.target.value === thisYear) {
        const selMon = document.getElementById("month").value;

        if (selMon < thisMon) {
          this.setState({ expMonth: thisMon });
        }
      }
    } else {
      this.setState({ expMonth: event.target.value });

      if (thisMon > event.target.value) {
        const selYear = document.getElementById("year").value;
        if (selYear === thisYear) {
          this.setState({ expYear: thisYear + 1 });
        }
      }
    }
  }

  expDate() {
    const thisYear = new Date().getFullYear();
    let years = [],
      i;
    for (i = 0; i < 15; i++) {
      years.push(thisYear + i);
    }

    return (
      <div className="generic-flex">
        <div>
          <select
            id="month"
            name="month"
            value={this.state.expMonth || ""}
            onChange={this.handleExpChange}
          >
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
        <div>&nbsp;/&nbsp;</div>
        <div>
          <select
            id="year"
            name="year"
            value={this.state.expYear || ""}
            onChange={this.handleExpChange}
          >
            {years.map((yr) => {
              return (
                <option value={yr} key={yr}>
                  {yr}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }

  billingAddressForm() {
    return (
      <React.Fragment>
        <div className="form-item-label">
          <hr className="hr-100" align="left"></hr>
        </div>
        <div className="form-item-value"></div>
        <div className="form-item-label">Address line:</div>
        <div className="form-item-value">
          <input
            type="text"
            name="addressLine1"
            size="30"
            value={this.state.addressLine1}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-item-label">Address line2:</div>
        <div className="form-item-value">
          <input
            type="text"
            name="addressLine2"
            size="30"
            value={this.state.addressLine2}
            onChange={this.handleChange}
            placeholder="Apartment #, etc"
          />
        </div>
        <div className="form-item-label">City:</div>
        <div className="form-item-value">
          <input
            type="text"
            name="city"
            value={this.state.city}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-item-label">State: </div>
        <div className="form-item-value">{this.showStateList()}</div>
        <div className="form-item-label">ZIP code:</div>
        <div className="form-item-value">
          <input
            type="number"
            name="zipCode"
            size="10"
            value={this.state.zipCode}
            onChange={this.handleChange}
          />
        </div>
      </React.Fragment>
    );
  }
  render() {
    const btnStyle = this.state.submitBtnEnabled
      ? "btn-style"
      : "disabled-btn-style";
    const errMsg =
      '<font color="red">' + this.state.errorMessage + "</font><p/>";
    return (
      <div className="top-wrapper">
        {this.state.errorMessage && (
          <div
            dangerouslySetInnerHTML={{
              __html: errMsg,
            }}
          ></div>
        )}
        <font size="4">Make Credit Card Payment</font> &nbsp;&nbsp;
        <p />
        <ValidatorForm ref="form" onSubmit={this.makePayment}>
          <div className="form-wrapper">
            <div className="form-item-label">
              Name on card<font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["Name is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">
              Card number<font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                type="number"
                name="number"
                value={this.state.number || ""}
                onChange={this.handleChange}
                placeholder="Card number, no space"
                validators={[
                  "required",
                  "isNumber",
                  "minStringLength: 13",
                  "maxStringLength: 16",
                ]}
                errorMessages={[
                  "Card number is required",
                  "Must be a number, no space",
                  "Credit card number too short",
                  "Credit card number too long",
                ]}
              ></TextInput>
            </div>
            <div className="form-item-label">
              Expiration date<font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">{this.expDate()}</div>
            <div className="form-item-label">
              CVV<font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                size="4"
                name="cvv"
                value={this.state.cvv || ""}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["CVV is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">
              Amount<font color="red">*</font>:{" "}
            </div>
            <div className="form-item-value">
              <TextInput
                size="10"
                name="amount"
                value={this.state.amount}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["Amount is required"]}
              ></TextInput>
            </div>
            <div className="form-item-label">Order Number:</div>
            <div className="form-item-value">
              <input
                type="text"
                size="10"
                name="orderNumber"
                value={this.state.orderNumber || ""}
                onChange={this.handleChange}
              ></input>
            </div>
            {this.billingAddressForm()}
          </div>
          <p />
          <div>
            <button
              id="submitBtn"
              className={btnStyle}
              type="submit"
              disabled={!this.state.submitBtnEnabled}
            >
              Make Payment
            </button>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MakePayment));
