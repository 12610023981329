import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  toAmount,
  setTopContainerWrapperSettings,
  isMobileMode,
  getIconBaseUrl,
  isUserLoggedIn,
} from "../Util";
import { setLoggedOut } from "../actions/userActions";
import { checkView } from "../SecManager";
import "../../App.css";
import "../../search.css";
import "../../generic.css";

class OrderListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMode: false,
      orderList: this.props.exactOrderList,
      viewAllowed: checkView("Order"),
    };
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }
  componentDidUpdate(prevProps) {
    if (this.props.exactOrderList !== prevProps.exactOrderList) {
      this.setState({ orderList: this.props.exactOrderList });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  showOrders() {
    const orderList = this.state.orderList;
    const clsName = "res-fivecol-wrapper";

    return (
      <div className="order-result-wrapper">
        <div className={clsName}>
          {orderList.map((ord, idx) => {
            const name = this.state.mobileMode
              ? ord.firstName.substring(0, 1) + ord.lastName.substring(0, 1)
              : ord.firstName + " " + ord.lastName;
            const url = "/orderDetail/" + ord.orderNumber;
            const img = getIconBaseUrl() + ord.statusIcon;
            const dateStr = this.state.mobileMode
              ? ord.shortDateStr
              : ord.dateStr;
            return (
              <React.Fragment key={idx}>
                <div>
                  <img src={img} width="12" alt="Order"></img>
                </div>
                <div>
                  <Link to={url}>{ord.orderNumber}</Link>
                </div>
                <div>{dateStr}</div>
                <div>{name}</div>
                <div align="right">{toAmount(ord.totalPrice)}</div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
  render() {
    const title = "Orders: " + this.props.exactCrit;
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          {this.showOrders()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
    exactOrderList: state.search.exactOrderList,
    exactCrit: state.search.exactCrit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderListView);
