import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, isUserLoggedIn, previewImgBaseUrl } from "../Util";
import { checkView, checkUpdate } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import { uploadFiles } from "../FileUploader";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditLogos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: null,
      mobileLogo: null,
      mobileLogoOffset: 20,
      uploadedPic: null,
      uploadedMobilePic: null,
      isLoading: true,
      errorMessage: null,
      updateBtnDisabled: true,
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.addPicFile = this.addPicFile.bind(this);
    this.addPicFileForMobile = this.addPicFileForMobile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewAllowed) {
      this.props.history.push("/");
      return;
    }

    const url = apiBaseUrl() + "GetLogos";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get logos",
        });
        console.log(error);
      });
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    const data = response.data;
    if (data) {
      this.setState({
        logo: data.logo,
        mobileLogo: data.mobileLogo,
        mobileLogoOffset: data.mobileLogoOffset,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  }
  handleChange(event) {
    if (!event.target.value || event.target.value.length === 0) {
      this.setState({
        mobileLogoOffset: null,
        errorMessage: "Offset is required",
        updateBtnDisabled: true,
      });
    } else {
      const mobileLogoOffset = parseInt(event.target.value);
      this.setState({
        mobileLogoOffset,
        updateBtnDisabled: false,
        errorMessage: null,
      });
    }
  }
  handleUpdate(event) {
    if (event) event.preventDefault();

    const url = apiBaseUrl() + "UpdateLogos";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data: this.state,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  processUpdateResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
      return;
    }

    if (response.status) {
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  processUploadResponse(response, fileName, isMobile) {
    if (response.status) {
      if (isMobile) {
        this.setState({
          uploadedMobilePic: fileName,
          updateBtnDisabled: false,
        });
      } else {
        this.setState({
          uploadedPic: fileName,
          updateBtnDisabled: false,
        });
      }
    } else {
      console.log("Upload failed");
    }
  }
  addPicFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      uploadFiles(event.target.files, this.processUploadResponse, false);
    }
  }
  addPicFileForMobile(event) {
    if (event.target.files && event.target.files.length > 0) {
      uploadFiles(event.target.files, this.processUploadResponse, true);
    }
  }
  theForm() {
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    let imgUrl = null;
    if (this.state.uploadedPic)
      imgUrl = previewImgBaseUrl() + this.state.uploadedPic;
    else imgUrl = this.state.logo;
    let mobileImgUrl = null;
    if (this.state.uploadedMobilePic)
      mobileImgUrl = previewImgBaseUrl() + this.state.uploadedMobilePic;
    else mobileImgUrl = this.state.mobileLogo;

    return (
      <div className="generic-wrapper">
        <font size="5">Logos</font>
        <p />
        <p />
        <div className="form-wrapper">
          <div className="form-item-label">Desktop: </div>
          <div className="form-item-value">
            <div className="generic-flex">
              <div>
                <img src={imgUrl} height="45" alt="Snap"></img>
              </div>
              <div>
                <b>&nbsp;&nbsp;Replace:&nbsp;&nbsp;</b>
              </div>
              <div>
                <input type="file" id="logo" onChange={this.addPicFile}></input>
              </div>
            </div>
          </div>

          <div className="form-item-label">Mobile: </div>
          <div className="form-item-value">
            <div className="generic-flex">
              <div>
                <img src={mobileImgUrl} height="45" alt="Img"></img>
              </div>
              <div>
                <b>&nbsp;&nbsp;Replace:&nbsp;&nbsp;</b>
              </div>
              <div>
                <input
                  type="file"
                  id="mobileLogo"
                  onChange={this.addPicFileForMobile}
                ></input>
              </div>
            </div>
          </div>
          <div className="form-item-label">Offset: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="5"
              name="mobileLogoOffset"
              value={this.state.mobileLogoOffset}
              onChange={this.handleChange}
            ></input>{" "}
            (For mobile logo)
          </div>
        </div>
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled}
        >
          Update
        </button>
        <p />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working size={80} />;

    return <div className="top-wrapper">{this.theForm()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditLogos);
