import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { setLoggedIn, setLoggedOut } from "../actions/userActions";
import { isUserLoggedIn, apiBaseUrl } from "../Util";
import { AXIOS_HEADER } from "../config/constants";

class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      newPassword: "",
      newPassword2: "",
      errorMessage: null,
      changePassOnly: this.props.match.params.id == 1,
      passwordRequirements: this.props.passwordRequirements,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.processChangePassResponse = this.processChangePassResponse.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id != 1) {
      if (isUserLoggedIn(this.props.userState) === false) {
        this.props.history.push("/login");
        return;
      }
    }
  }
  handleLogout() {
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };

    const url = apiBaseUrl() + "Logout";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.setLoggedOut();
    this.props.history.push("/login");
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  processChangePassResponse(response) {
    if (response.success) {
      this.props.setLoggedIn(response);
      window.location.replace("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChangePassword(event) {
    event.preventDefault();

    const pw = this.state.password;
    const newPw = this.state.newPassword;
    const newPw2 = this.state.newPassword2;

    if (!pw || !newPw || !newPw2) {
      this.setState({ errorMessage: "Password is required." });
      return;
    }

    if (newPw !== newPw2) {
      this.setState({ errorMessage: "New passwords don't match" });
      return;
    }

    const url = apiBaseUrl() + "ChangePass";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      password: pw,
      newPassword: newPw,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processChangePassResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          {this.state.changePassOnly === false && (
            <React.Fragment>
              <font size="5">My Account&nbsp;&nbsp;&nbsp;&nbsp;</font>

              <button
                name="logout"
                className="btn-style"
                onClick={this.handleLogout}
              >
                Logout
              </button>
              <p />
              <hr width="300" align="left"></hr>
            </React.Fragment>
          )}
          <b>CHANGE PASSWORD:</b>
          <p />
          {this.state.changePassOnly === true && (
            <div>
              You are required to change your password. <p />
              <p />
            </div>
          )}
          <div>
            The new password must meet the following requirements: <p />
            {this.props.passwordReq}
            <p />
          </div>
          <div className="form-wrapper">
            <div className="form-item-label">Current Password: </div>
            <div className="form-item-value">
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="form-item-label">New Password: </div>
            <div className="form-item-value">
              <input
                type="password"
                name="newPassword"
                value={this.state.newPassword}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="form-item-label">Confirm Password:</div>
            <div className="form-item-value">
              <input
                type="password"
                name="newPassword2"
                value={this.state.newPassword2}
                onChange={this.handleChange}
              ></input>
            </div>
          </div>
          <p />
          <button
            className="btn-style"
            name="changePass"
            onClick={this.handleChangePassword}
          >
            Change Password
          </button>
          <p />
          <font color="red">{this.state.errorMessage}</font>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userState: state.user,
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    passwordReq: state.user.passwordReq,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
    setLoggedIn: (payload) => {
      dispatch(setLoggedIn(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
