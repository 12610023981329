import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isUserLoggedIn,
  isMobileMode,
  setTopContainerWrapperSettings,
} from "../Util";
import { checkView, checkUpdate, checkSecurity } from "../SecManager";
import { setLoggedOut } from "../actions/userActions";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class EditStoreLoc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storeLocations: [],
      locIdx: 0,
      isLoading: true,
      updateBtnDisabled: true,
      mobileMode: false,
      message: null,
      errorMessage: null,
      viewProdAllowed: checkView("Product"),
      editAllowed: checkUpdate("Setting"),
      viewAllowed: checkView("Setting"),
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLocChange = this.handleLocChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.processUpdateResponse = this.processUpdateResponse.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn(this.props.userState) === false) {
      this.props.history.push("/login");
      return;
    }
    if (!this.state.viewProdAllowed) {
      this.props.history.push("/");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchData();
    }
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    if (response.invalidSession) {
      this.props.setLoggedOut();
      this.props.history.push("/login");
    } else {
      const storeLocations = response.objList;
      let locIdx = 0;
      if (this.props.match.params.id && storeLocations) {
        for (let i = 0; i < storeLocations.length; i++) {
          if (storeLocations[i].locationInfo.id == this.props.match.params.id) {
            locIdx = i;
            break;
          }
        }
      }
      this.setState({
        storeLocations,
        locIdx,
        isLoading: false,
      });
    }
  }
  fetchData() {
    const url = apiBaseUrl() + "StoreLocations";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get store list",
        });
        console.log(error);
      });
  }
  handleLocChange(event) {
    const locIdx = parseInt(event.target.value);
    this.setState({ locIdx });
  }
  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    const locIdx = this.state.locIdx;
    let hrIdx = -1;

    if (name.startsWith("HR_")) {
      //name format: HR_[idx]_[name] or HR_[idx]_CB_[name]
      name = name.substring(3);
      hrIdx = parseInt(name.substring(0, 1));
      name = name.substring(2);
    }
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      value = event.target.checked;
    }

    let storeLocations;
    if (hrIdx >= 0) {
      const hourList = this.state.storeLocations[locIdx].hourList.map(
        (hr, idx) => {
          if (idx === hrIdx) {
            const newHr = {
              ...hr,
              [name]: value,
            };
            return newHr;
          } else return hr;
        }
      );
      storeLocations = this.state.storeLocations.map((loc, idx) => {
        if (idx === locIdx) {
          const newLoc = {
            ...loc,
            hourList,
          };
          return newLoc;
        } else return loc;
      });
    } else {
      storeLocations = this.state.storeLocations.map((loc, idx) => {
        if (idx === locIdx) {
          const locationInfo = {
            ...this.state.storeLocations[locIdx].locationInfo,
            [name]: value,
          };
          const newLoc = {
            ...loc,
            locationInfo,
          };
          return newLoc;
        } else return loc;
      });
    }
    this.setState({ storeLocations, updateBtnDisabled: false });
  }
  handleUpdate(event) {
    event.preventDefault();
    const url = apiBaseUrl() + "UpdateLocation";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      location: this.state.storeLocations[this.state.locIdx],
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processUpdateResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  processUpdateResponse(response) {
    if (response.status) {
      this.setState({
        message:
          "Successfully updated location: " +
          this.state.storeLocations[this.state.locIdx].locationInfo.name,
        errorMessage: null,
        updateBtnDisabled: true,
      });
    } else {
      this.setState({ errorMessage: response.errorMessage, message: null });
    }
  }
  storeList() {
    if (!this.state.storeLocations || this.state.storeLocations.length === 0)
      return "";

    return (
      <div>
        <select
          name="id"
          size="10"
          value={this.state.locIdx}
          onChange={this.handleLocChange}
        >
          {this.state.storeLocations.map((storeLoc, idx) => {
            const info = storeLoc.locationInfo;
            return (
              <option value={idx} key={info.id}>
                &nbsp;&nbsp;{info.name}&nbsp;&nbsp;
              </option>
            );
          })}
        </select>
      </div>
    );
  }
  storeHours(editAllowed) {
    const hourList = this.state.storeLocations[this.state.locIdx].hourList;
    if (!hourList || hourList.length === 0) return "";

    const timeType = this.state.mobileMode ? "time" : "text";

    return (
      <div>
        <b>Store Hours</b>
        <br />
        <div className="form-wrapper">
          {hourList.map((info, idx) => {
            const startName = "HR_" + info.dayOfWeek + "_startTime";
            const endName = "HR_" + info.dayOfWeek + "_endTime";
            const flagName = "HR_" + info.dayOfWeek + "_CB_open";
            return (
              <React.Fragment key={idx}>
                <div className="form-item-label">
                  <input
                    type="checkbox"
                    name={flagName}
                    checked={info.open}
                    onChange={this.handleChange}
                    disabled={!editAllowed}
                  ></input>
                  {info.dowName}:
                </div>
                {info.open ? (
                  <div className="form-item-value">
                    <input
                      type={timeType}
                      size="8"
                      name={startName}
                      value={info.startTime}
                      onChange={this.handleChange}
                      disabled={!editAllowed}
                    ></input>
                    &nbsp;&nbsp;-&nbsp;&nbsp;
                    <input
                      type={timeType}
                      size="10"
                      name={endName}
                      value={info.endTime}
                      onChange={this.handleChange}
                      disabled={!editAllowed}
                    ></input>
                  </div>
                ) : (
                  <div className="form-item-value">CLOSED</div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  geoInfo(info) {
    if (checkSecurity("RootFeatures")) {
      const numType = this.state.mobileMode ? "number" : "text";
      const editAllowed = this.state.editAllowed;
      return (
        <React.Fragment>
          <div className="form-item-label">Latitude: </div>
          <div className="form-item-value">
            <input
              type={numType}
              size="12"
              name="latitude"
              value={info.latitude}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Longitude: </div>
          <div className="form-item-value">
            <input
              type={numType}
              size="12"
              name="longitude"
              value={info.longitude}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Delivery Radius: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              size="5"
              name="deliveryRadius"
              value={info.deliveryRadius}
              onChange={this.handleChange}
              disabled={!editAllowed || !info.warehouse}
            ></input>{" "}
            (miles from warehouse)
          </div>
        </React.Fragment>
      );
    }
  }
  theForm() {
    if (!this.state.storeLocations) return "";

    const editAllowed = this.state.editAllowed;
    const btnStyle = this.state.updateBtnDisabled
      ? "disabled-btn-style"
      : "btn-style";
    const colCnt = this.state.mobileMode ? 42 : 80;
    const rowCnt = this.state.mobileMode ? 9 : 5;
    const loc = this.state.storeLocations[this.state.locIdx];
    const storeSetting = loc.storeSetting;
    const info = loc.locationInfo;
    let webOrder = null;
    if (storeSetting && storeSetting.id > 0) {
      if (this.state.editAllowed) {
        webOrder = "Update/Register Web Order";
      } else {
        webOrder = "Register Web Order";
      }
    } else {
      if (this.state.editAllowed) {
        webOrder = "Setup Web Order";
      }
    }
    const ssUrl = "/storeSetting/" + info.id;
    const telType = this.state.mobileMode ? "tel" : "text";
    return (
      <div>
        {webOrder && (
          <div className="db-title">
            <a href={ssUrl}>
              <b>{webOrder}</b>
            </a>
          </div>
        )}
        <div className="form-wrapper">
          <div className="form-item-label">Name: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="40"
              name="name"
              value={info.name}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Short Name: </div>
          <div className="form-item-value">
            <input
              type="text"
              name="shortName"
              value={info.shortName}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Description: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="40"
              name="description"
              value={info.description}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Phone: </div>
          <div className="form-item-value">
            <input
              type={telType}
              size="15"
              name="phone"
              value={info.phone}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">
            Pickup
            <br />
            Instruction:{" "}
          </div>
          <div className="form-item-value">
            <textarea
              name="pickupInstr"
              cols={colCnt}
              rows={rowCnt}
              value={info.pickupInstr}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></textarea>
          </div>
          <div className="form-item-label">Lead Time: </div>
          <div className="form-item-value">
            <input
              type="text"
              size="15"
              name="pickupLeadTime"
              value={info.pickupLeadTime}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>{" "}
          </div>
          <div className="form-item-label">Deposit: </div>
          <div className="form-item-value">
            <input
              type="text"
              inputmode="decimal"
              name="securityDeposit"
              size="8"
              value={info.securityDeposit}
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></input>
          </div>
          <div className="form-item-label">Settings: </div>
          <div className="form-item-value">
            <input
              type="checkbox"
              name="CB_warehouse"
              checked={info.warehouse}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Is Warehouse <br />
            <input
              type="checkbox"
              name="CB_pickup"
              checked={info.pickup}
              onChange={this.handleChange}
              disabled={!editAllowed}
            />{" "}
            Is Pickup Location <br />
          </div>
          {this.geoInfo(info)}
          <div className="form-item-label">Special Info: </div>
          <div className="form-item-value">
            <textarea
              name="specialInfo"
              cols={colCnt}
              rows={rowCnt}
              value={info.specialInfo}
              placeholder="Information here will be used as store status (Open/Close)"
              onChange={this.handleChange}
              disabled={!editAllowed}
            ></textarea>
          </div>
        </div>
        <p />
        {this.storeHours(editAllowed)}
        <p />
        <button
          name="update"
          onClick={this.handleUpdate}
          className={btnStyle}
          disabled={this.state.updateBtnDisabled || !editAllowed}
        >
          Update
        </button>
        <p />
        <font color="green">{this.state.message}</font>
        <br />
        <font color="red">{this.state.errorMessage}</font>
        <br />
      </div>
    );
  }
  render() {
    if (this.state.isLoading) return <Working size={80} />;

    const clsName = this.state.mobileMode ? "none" : "flex-wrapper-top";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Store Locations</font>
          <p />
          <div className={clsName}>
            {this.storeList()}
            <div className="left-25">&nbsp;</div>
            {this.theForm()}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => {
      dispatch(setLoggedOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditStoreLoc);
