import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, getIconBaseUrl } from "../Util";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../layaway.css";

class OrderNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNumber: this.props.orderNumber,
      noteList: this.props.noteList,
      isLayaway: this.props.isLayaway,
      addBtnEnabled: false,
      orderNote: null,
      showAllNotes: false,
      publicNote: false,
      isLoading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.addNote = this.addNote.bind(this);
  }
  componentDidMount() {}
  componentDidUpdate(prepProps) {
    if (prepProps.noteList != this.props.noteList) {
      this.setState({ noteList: this.props.noteList });
    }
  }
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;
    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;

      if (name === "showAllNotes") {
        this.setState({ showAllNotes: val });
        return;
      }
    }
    this.setState({ [name]: val, addBtnEnabled: true });
  }
  addNote(event) {
    const url = apiBaseUrl() + "AddOrderNote";
    const data = {
      orderNumber: this.state.orderNumber,
      note: this.state.orderNote,
      publicNote: this.state.publicNote,
      layaway: this.state.isLayaway,
    };
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      data,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.data) {
            if (this.props.callback) {
              this.props.callback(data);
            }
            this.setState({
              noteList: res.data.data,
              orderNote: null,
              publicNote: false,
              addBtnEnabled: false,
              showAllNotes: true,
            });
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              message: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getUserId(userId) {
    if (userId.length > 15 && userId.indexOf("-") > 0) return "Internet";
    else return userId;
  }
  addNewForm() {
    const addBtnStyle = this.state.addBtnEnabled
      ? "btn-style"
      : "disabled-btn-style";
    return (
      <React.Fragment>
        <div className="top-10">
          <textarea
            name="orderNote"
            rows={5}
            cols={50}
            value={this.state.orderNote || ""}
            onChange={this.handleChange}
          ></textarea>
        </div>
        <div className="generic-flex">
          <div>
            <button
              name="addNote"
              className={addBtnStyle}
              onClick={this.addNote}
              disabled={!this.state.addBtnEnabled}
            >
              Add New Note
            </button>
          </div>
          <div className="left-20">
            <input
              type="checkbox"
              name="CB_publicNote"
              checked={this.state.publicNote}
              onChange={this.handleChange}
              disabled={!this.state.addBtnEnabled}
            ></input>{" "}
            Public Note
          </div>
        </div>
      </React.Fragment>
    );
  }

  showNoteList(theNoteList) {
    if (theNoteList && theNoteList.length > 0) {
      let noteList = this.state.showAllNotes
        ? theNoteList
        : theNoteList.filter((note) => note.publicNote);

      return (
        <div>
          <div>
            <input
              type="checkbox"
              name="CB_showAllNotes"
              defaultChecked={this.state.showAllNotes}
              onChange={this.handleChange}
            />
            &nbsp;Show all notes
          </div>
          {noteList && noteList.length > 0 && (
            <div className="c4-table-left5">
              <div className="la-act-hd">&nbsp;</div>
              <div className="la-act-hd">Date</div>
              <div className="la-act-hd">Clerk</div>
              <div className="la-act-hd">Note</div>
              {noteList.map((note, idx) => {
                const clsName =
                  idx % 2 === 0 ? "la-item-ev-300" : "la-item-od-300";
                let ts = "C";
                if (note.type === 1) ts = "P";
                else if (note.type === 2) ts = "D";
                return (
                  <React.Fragment key={idx}>
                    <div className={clsName}>{ts}</div>
                    <div className={clsName}>{note.dateStr}</div>
                    <div className={clsName}>{this.getUserId(note.userId)}</div>
                    <div className={clsName}>{note.note}</div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      );
    }
  }
  orderNotes(noteList) {
    return (
      <div>
        <hr className="hr-300" align="left" />
        <div className="la-sect-header">
          <div>
            <img src={getIconBaseUrl() + "notes-y.jpg"} height="22"></img>
          </div>
          <div className="la-sect-title">
            <font size="4">Notes</font>
          </div>
        </div>
        {this.showNoteList(noteList)}
        {!this.props.editDisabled && this.addNewForm()}
      </div>
    );
  }
  render() {
    const noteList = this.state.noteList;

    if (!this.props.editDisabled || (noteList && noteList.length > 0)) {
      return this.orderNotes(noteList);
    } else {
      return "";
    }
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};

export default connect(mapStateToProps)(OrderNotes);
