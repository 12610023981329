import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { checkView, checkUpdate } from "../SecManager";
import {
  apiBaseUrl,
  fullProductImageUrl,
  toAmountEx,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
  isEmptyStr,
  getItemCount,
} from "../Util";
import ReturnedInfo from "./ReturnedInfo";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../layaway.css";

class ReturnItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coi: null,
      isLoading: true,
      mobileMode: false,
      returnItemList: null,
      returnBtnDisabled: true,
      returnResponse: null,
      removeDiscount: false,
      processing: false,
      refundTotal: 0,
      editAllowed: checkUpdate("Order"),
      viewAllowed: checkView("Order"),
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchOrder = this.fetchOrder.bind(this);
    this.addReturnItem = this.addReturnItem.bind(this);
    this.returnItems = this.returnItems.bind(this);
    this.gotoOrderDetail = this.gotoOrderDetail.bind(this);
    this.deleteRtnItem = this.deleteRtnItem.bind(this);
  }
  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchOrder();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  fetchOrder() {
    const url = apiBaseUrl() + "GetOrderForPrint";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      value: this.props.match.params.orderNumber,
      flag: this.props.match.params.type == 2,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.status) {
          this.setState({ coi: res.data.data, isLoading: false });
        } else {
          this.setState({
            errorMessage: res.data.errorMessage,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Failed to get order/layaway",
          isLoading: false,
        });
        console.log(error);
      });
  }
  returnItems() {
    const url = apiBaseUrl() + "ReturnOrderItems";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      flag: this.state.removeDiscount,
      objList: this.state.returnItemList,
    };

    this.setState({ processing: true, returnBtnDisabled: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ returnResponse: res.data, processing: false });
      })
      .catch((error) => {
        const returnResponse = {
          status: false,
          errorMessage: "Failed to return items: " + error,
        };
        this.setState({
          returnResponse,
          processing: false,
          returnBtnDisabled: false,
        });
        console.log(error);
      });
  }
  gotoOrderDetail() {
    let url =
      this.props.match.params.type == 2 ? "/layawayDetail/" : "/orderDetail/";
    url += this.state.coi.order.orderNumber;

    this.props.history.push(url);
  }
  handleChange(event) {
    const name = event.target.name;
    if (name == "removeDiscount") {
      let delta =
        this.state.coi.order.discount * (1 + this.state.coi.order.taxRate);
      if (event.target.checked) {
        delta = 0 - delta;
      }
      const refundTotal = this.state.refundTotal + delta;
      this.setState({ removeDiscount: event.target.checked, refundTotal });
      return;
    }

    const val = event.target.value;
    let idxStr = null;
    let isSP = false;
    let isQty = false;

    if (name.startsWith("SPQTY_")) {
      idxStr = name.substring(6);
      isQty = true;
      isSP = true;
    } else if (name.startsWith("QTY_")) {
      idxStr = name.substring(4);
      isQty = true;
      isSP = false;
    } else if (name.startsWith("SPFEE_")) {
      idxStr = name.substring(6);
      isQty = false;
      isSP = true;
    } else if (name.startsWith("FEE_")) {
      idxStr = name.substring(4);
      isQty = false;
      isSP = false;
    }
    if (idxStr) {
      const idx = parseInt(idxStr);
      const oldList = isSP
        ? this.state.coi.storeItemList
        : this.state.coi.orderItemList;
      let newList = [];

      for (let i = 0; i < oldList.length; i++) {
        if (i == idx) {
          let item;
          if (isQty) {
            item = {
              ...oldList[i],
              tbrQty: parseInt(val),
            };
          } else {
            item = {
              ...oldList[i],
              tbrFee: parseInt(val),
            };
          }
          newList.push(item);
        } else newList.push(oldList[i]);
      }

      let coi;
      if (isSP) {
        coi = {
          ...this.state.coi,
          storeItemList: newList,
        };
      } else {
        coi = {
          ...this.state.coi,
          orderItemList: newList,
        };
      }
      this.setState({ coi });
    }
  }
  orderSummary() {
    const coi = this.state.coi;
    if (coi) {
      const order = coi.order;
      let salesperson = null;
      if (order.storeId > 0 && order.storeName) {
        if (order.kiosk) salesperson = "KIOSK";
        else salesperson = order.salesperson;
      }
      const shipping = order.delivery ? "Delivery" : "Pickup";
      let deliveryDate = null;
      let color = null;
      if (order.schDateStr) {
        deliveryDate = order.schDateStr;
      }
      const name = order.layaway ? "Layaway Number" : "Order Number";
      return (
        <div className="otv-2col-form">
          <div>{name}:</div>
          <div>{order.orderNumber}</div>
          <div>Date Ordered:</div>
          <div>{order.dateStr}</div>

          {salesperson && (
            <React.Fragment>
              <div>Store Name:</div>
              <div>{order.storeName}</div>
              <div>Salesperson:</div>
              <div>
                <b>{salesperson}</b>
              </div>
            </React.Fragment>
          )}
          <div>Order Total:</div>
          <div>{toAmountEx(order.totalPrice)}</div>
          <div>Shipping:</div>
          <div>{shipping}</div>
          {deliveryDate && (
            <React.Fragment>
              <div>Scheduled:</div>
              {color ? (
                <div>
                  <font color={color}>{deliveryDate}</font>
                </div>
              ) : (
                <div>{deliveryDate}</div>
              )}
            </React.Fragment>
          )}
          {order.leaseToOwn && (
            <React.Fragment>
              <div>Lease To Own:</div>
              <div>Progressive</div>
            </React.Fragment>
          )}
        </div>
      );
    }
  }
  customerInfo() {
    const addr = this.state.coi.contactAddress;
    if (addr) {
      let html = addr.firstName + " " + addr.lastName + "<br/>";
      html += addr.addressLine1 + "<br/>";
      if (addr.addressLine2) html += addr.addressLine2 + "<br/>";

      if (
        (!isEmptyStr(addr.city) || !isEmptyStr(addr.zipCode)) &&
        addr.city != "Unknown"
      ) {
        html += addr.city + ", " + addr.state + " " + addr.zipCode + "<br/>";
      }

      if (addr.phone) {
        html += addr.phone;
        if (addr.mobilePhone) {
          html += "/" + addr.mobilePhone;
        }
        html += "<br/>";
      }
      if (addr.email) html += addr.email + "<br/>";

      return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
    }
  }
  qtySelection(idx, qty, isSP) {
    const name = isSP ? "SPQTY_" + idx : "QTY_" + idx;
    let options = [];
    for (let i = 1; i <= qty; i++) {
      const op = <option value={i}>{i}</option>;
      options.push(op);
    }

    return (
      <select name={name} onChange={this.handleChange}>
        {options};
      </select>
    );
  }
  feeSelection(idx, isSP) {
    const name = isSP ? "SPFEE_" + idx : "FEE_" + idx;
    const cList = this.state.coi.restockingFeeChoices;

    if (cList && cList.length > 0) {
      return (
        <select name={name} onChange={this.handleChange}>
          {cList.map((c, idx) => {
            return (
              <option key={idx} value={c}>
                {c}%
              </option>
            );
          })}
        </select>
      );
    } else {
      return (
        <select name={name} onChange={this.handleChange}>
          <option value={0}>0%</option>
          <option value={15}>15%</option>
        </select>
      );
    }
  }
  getRefundTotal(rtnList) {
    let refundTotal = 0;
    if (rtnList && rtnList.length > 0) {
      for (let i = 0; i < rtnList.length; i++) {
        const ri = rtnList[i];
        const refund =
          ri.quantity * ((ri.unitPrice * (100 - ri.restockingPercent)) / 100);
        refundTotal += refund * (1 + this.state.coi.order.taxRate);
      }
    }
    return refundTotal;
  }
  addReturnItem(idx, isSP) {
    const layaway = this.props.match.params.type == 2 ? true : false;
    let rtnItem;
    let coi;
    const rsfc = this.state.coi.restockingFeeChoices;
    const defFee = rsfc && rsfc.length > 0 ? rsfc[0] : 0;

    if (isSP) {
      let storeItemList = this.state.coi.storeItemList;
      let sItem = storeItemList[idx];
      const quantity = sItem.tbrQty ? sItem.tbrQty : 1;
      const restockingPercent = sItem.tbrFee ? sItem.tbrFee : defFee;
      let sku = sItem.sku;

      if (sku.startsWith("TYPED_")) sku = sku.substring(6);

      rtnItem = {
        ...sItem.orderItem,
        quantity,
        restockingPercent,
        layaway,
        imageUrl: sItem.imageUrl,
        productName: sItem.name,
        spIdx: idx,
        rgIdx: -1,
      };
      sItem.quantity -= quantity;
      coi = {
        ...this.state.coi,
        storeItemList,
      };
    } else {
      let orderItemList = this.state.coi.orderItemList;
      let oItem = orderItemList[idx];
      const quantity = oItem.tbrQty ? oItem.tbrQty : 1;
      let restockingPercent;

      if (oItem.tbrFee == 0) restockingPercent = 0;
      else restockingPercent = oItem.tbrFee ? oItem.tbrFee : defFee;

      rtnItem = {
        ...oItem.item,
        quantity,
        restockingPercent,
        layaway,
        imageUrl: oItem.imageUrl,
        productName: oItem.productName,
        spIdx: -1,
        rgIdx: idx,
      };
      oItem.item.quantity -= quantity;
      coi = {
        ...this.state.coi,
        orderItemList,
      };
    }

    let returnItemList = this.state.returnItemList
      ? this.state.returnItemList
      : [];
    returnItemList.push(rtnItem);

    const refundTotal = this.getRefundTotal(returnItemList);
    this.setState({
      coi,
      returnItemList,
      refundTotal,
      returnBtnDisabled: false,
    });
  }
  orderItems() {
    const coi = this.state.coi;
    if (
      coi &&
      !(this.state.returnResponse && this.state.returnResponse.status)
    ) {
      if (coi.returnAllowed) {
        if (getItemCount(coi) > 0) {
          const orderItems = coi.orderItemList;
          const storeItems = coi.storeItemList;
          const tblClsName = this.state.mobileMode
            ? "la-table-c4"
            : "la-table-c6";
          return (
            <div>
              <div className="bottom-10">
                <i>Order Items</i>
              </div>
              <div className={tblClsName}>
                <div className="la-ord-hd">Product</div>
                {!this.state.mobileMode && (
                  <div className="la-ord-hd">Name</div>
                )}
                <div className="la-ord-hd">Qty</div>
                {!this.state.mobileMode && (
                  <div className="la-ord-hd">Price</div>
                )}
                <div className="la-ord-hd">Fee</div>
                <div className="la-ord-hd">&nbsp;</div>
                {orderItems.map((item, idx) => {
                  if (item.item.quantity > 0) {
                    const imgUrl = fullProductImageUrl(item.imageUrl);
                    const clsName = idx % 2 === 0 ? "la-item-ev" : "la-item-od";
                    const clsName2 =
                      idx % 2 === 0 ? "la-item-ev-250" : "la-item-od-250";
                    const btnName = "return" + idx;
                    let sku = item.item.sku;

                    if (this.state.mobileMode && sku.length > 12) {
                      sku = sku.substring(0, 11) + "...";
                    }

                    const col1 = this.state.mobileMode ? (
                      <span>
                        {sku}
                        <br />
                        {toAmountEx(item.item.unitPrice)}
                      </span>
                    ) : (
                      sku
                    );
                    return (
                      <React.Fragment key={idx}>
                        <div className={clsName}>
                          <img src={imgUrl} width="45" height="30"></img>
                          &nbsp;{col1}
                        </div>
                        {!this.state.mobileMode && (
                          <div className={clsName2}>{item.productName}</div>
                        )}
                        <div className={clsName}>
                          {item.item.quantity > 1
                            ? this.qtySelection(idx, item.item.quantity, false)
                            : item.item.quantity}
                        </div>
                        {!this.state.mobileMode && (
                          <div className={clsName}>
                            {toAmountEx(item.item.unitPrice)}
                          </div>
                        )}
                        <div className={clsName}>
                          {this.feeSelection(idx, false)}
                        </div>
                        <div className={clsName}>
                          <button
                            name={btnName}
                            onClick={() => this.addReturnItem(idx, false)}
                            className="small-btn"
                          >
                            Select
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                })}
                {storeItems &&
                  storeItems.map((item, idx) => {
                    if (item.quantity > 0 && item.typeId < 1) {
                      const btnName = "returnSp" + idx;
                      const imgUrl = fullProductImageUrl(item.imageUrl);
                      const clsName =
                        idx % 2 === 0 ? "la-item-ev" : "la-item-od";
                      const clsName2 =
                        idx % 2 === 0 ? "la-item-ev-250" : "la-item-od-250";
                      let sku = item.sku;
                      if (sku.startsWith("TYPED_")) sku = sku.substring(6);

                      if (this.state.mobileMode && sku.length > 12) {
                        sku = sku.substring(0, 11) + "...";
                      }
                      const col1 = this.state.mobileMode ? (
                        <span>
                          {sku}
                          <br />
                          {toAmountEx(item.price)}
                        </span>
                      ) : (
                        sku
                      );
                      return (
                        <React.Fragment key={idx}>
                          <div className={clsName}>
                            <img src={imgUrl} width="45" height="30"></img>
                            &nbsp;{col1}
                          </div>
                          {!this.state.mobileMode && (
                            <div className={clsName2}>{item.name}</div>
                          )}
                          <div className={clsName}>
                            {item.quantity > 1
                              ? this.qtySelection(idx, item.quantity, true)
                              : item.quantity}
                          </div>
                          {!this.state.mobileMode && (
                            <div className={clsName}>
                              {toAmountEx(item.price)}
                            </div>
                          )}
                          <div className={clsName}>
                            {this.feeSelection(idx, false)}
                          </div>
                          <div className={clsName}>
                            <button
                              name={btnName}
                              onClick={() => this.addReturnItem(idx, true)}
                              className="small-btn"
                            >
                              Return
                            </button>
                          </div>
                        </React.Fragment>
                      );
                    }
                  })}
              </div>
            </div>
          );
        }
      } else {
        return (
          <div>
            Return is not allowed in the current status. Please change it to
            Delivered/Picked Up first.
          </div>
        );
      }
    }
    return "";
  }

  returnSection() {
    const rtnRes = this.state.returnResponse;

    if (rtnRes && rtnRes.status) {
      return (
        <div>
          <p />
          <div>
            <font color="blue">
              Return successfully processed, below is the summary:
            </font>
          </div>
          <br />
          <ReturnedInfo
            info={rtnRes.data}
            coi={this.state.coi}
            mobileMode={this.state.mobileMode}
            hideUser={true}
          />
        </div>
      );
    } else {
      return this.toBeReturnedItems();
    }
  }
  deleteRtnItem(idx) {
    let returnItemList = [];
    let coi = this.state.coi;
    let refundTotal = this.state.refundTotal;

    for (let i = 0; i < this.state.returnItemList.length; i++) {
      const rtnItem = this.state.returnItemList[i];
      if (i != idx) {
        returnItemList.push(rtnItem);
      } else {
        const refund =
          rtnItem.quantity *
          rtnItem.unitPrice *
          ((100 - rtnItem.restockingPercent) / 100) *
          (1 + coi.order.taxRate);
        refundTotal -= refund;
        if (rtnItem.spIdx >= 0) {
          coi.storeItemList[rtnItem.spIdx].quantity += rtnItem.quantity;
        } else {
          coi.orderItemList[rtnItem.rgIdx].item.quantity += rtnItem.quantity;
        }
      }
    }
    const returnBtnDisabled = returnItemList.length === 0;
    this.setState({ returnItemList, coi, refundTotal, returnBtnDisabled });
  }

  toBeReturnedItems() {
    const returnItemList = this.state.returnItemList;

    if (returnItemList && returnItemList.length > 0) {
      const title = "Items To Be Returned";
      let removeInfo = null;
      let discount = this.state.coi.order.discount;

      if (discount > 1) {
        removeInfo = (
          <span>
            Remove {this.state.coi.order.discountName}{" "}
            <font color="red">-{toAmountEx(discount)}</font>
          </span>
        );
      }

      const tblClsName = this.state.mobileMode ? "la-table-c4" : "la-table-c6";
      return (
        <div>
          <div className="bottom-10">
            <b>{title}</b>
          </div>
          <div className={tblClsName}>
            <div className="la-ord-hd-red">Product</div>
            {!this.state.mobileMode && (
              <div className="la-ord-hd-red">Name</div>
            )}
            <div className="la-ord-hd-red">Qty</div>
            {!this.state.mobileMode && (
              <div className="la-ord-hd-red">Price</div>
            )}
            <div className="la-ord-hd-red">Fee</div>
            <div className="la-ord-hd-red"></div>
            {returnItemList.map((item, idx) => {
              const imgUrl = fullProductImageUrl(item.imageUrl);
              const clsName = idx % 2 === 0 ? "la-item-ev" : "la-item-od";
              const clsName2 =
                idx % 2 === 0 ? "la-item-ev-250" : "la-item-od-250";
              const col1 = this.state.mobileMode ? (
                <span>
                  {item.sku}
                  <br />
                  {toAmountEx(item.unitPrice)}
                </span>
              ) : (
                item.sku
              );
              return (
                <React.Fragment key={idx}>
                  <div className={clsName}>
                    <img src={imgUrl} width="45" height="30"></img>
                    &nbsp;{col1}
                  </div>
                  {!this.state.mobileMode && (
                    <div className={clsName2}>{item.productName}</div>
                  )}
                  <div className={clsName}>{item.quantity}</div>
                  {!this.state.mobileMode && (
                    <div className={clsName}>{toAmountEx(item.unitPrice)}</div>
                  )}
                  <div className={clsName}>{item.restockingPercent}%</div>
                  <div className={clsName}>
                    <img
                      src={getIconBaseUrl() + "cross.png"}
                      height="14"
                      onClick={() => this.deleteRtnItem(idx)}
                      alt="Delete"
                    ></img>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          {removeInfo && (
            <div>
              <input
                type="checkbox"
                name="removeDiscount"
                defaultChecked={this.state.removeDiscount}
                onChange={this.handleChange}
              ></input>{" "}
              {removeInfo}
            </div>
          )}
          <div className="top-10">
            Estimated Refund: {toAmountEx(this.state.refundTotal)}
          </div>
        </div>
      );
    }
    return "";
  }
  showBtnAndMsg() {
    if (
      (this.state.returnResponse && this.state.returnResponse.status) ||
      !this.state.coi.returnAllowed
    ) {
      return (
        <div className="top-10">
          <p />
          <button
            className="btn-style"
            name="ok"
            onClick={this.gotoOrderDetail}
          >
            OK
          </button>
        </div>
      );
    } else {
      const btnStyle = this.state.returnBtnDisabled
        ? "disabled-small-btn"
        : "red-small-btn";
      const errorMessage = this.state.returnResponse
        ? this.state.returnResponse.errorMessage
        : null;
      return (
        <div>
          <div className="top-10">
            <button
              className={btnStyle}
              name="returnItems"
              disabled={this.state.returnBtnDisabled}
              onClick={this.returnItems}
            >
              Return Items
            </button>
          </div>
          {errorMessage && (
            <label>
              <p />
              <font color="red">{errorMessage}</font>
            </label>
          )}
        </div>
      );
    }
  }
  render() {
    if (this.state.isLoading) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <img src={getIconBaseUrl() + "working.gif"} width="120" />
          </div>
        </div>
      );
    }

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div>
            <font size="5">Return Items</font>
          </div>
          <br />
          <div className="flex-wrapper-top">
            {this.orderSummary()}
            <div className="width-40"></div>
            {this.customerInfo()}
          </div>

          <p />
          {this.orderItems()}
          <div className="top-10">&nbsp;</div>
          <p />
          {this.returnSection()}
          {this.showBtnAndMsg()}
          <p />
          {this.state.processing && <Working />}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
export default connect(mapStateToProps)(ReturnItems);
