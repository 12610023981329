import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, toDateStr } from "../Util";

import "../../App.css";
import "../../generic.css";
import "../../form.css";

class MarkPaid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order,
      isLayaway: this.props.isLayaway,
      datePaid: null,
      minDatePaid: null,
      maxDatePaid: null,
      note: null,
      markChecked: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.markAsPaid = this.markAsPaid.bind(this);
    this.setInitState = this.setInitState.bind(this);
  }

  componentDidMount() {
    this.setInitState();
  }
  componentDidUpdate(prevPros) {
    if (this.props.order != prevPros.order) {
      this.setInitState();
    }
  }
  setInitState() {
    this.setState({
      order: this.props.order,
      isLayaway: this.props.isLayaway,
      datePaid: toDateStr(new Date()),
      minDatePaid: toDateStr(new Date(this.props.order.dateOrdered)),
      maxDatePaid: toDateStr(new Date()),
      note: null,
      markChecked: false,
    });
  }
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;

    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
    }
    this.setState({ [name]: val });
  }

  markAsPaid() {
    const url = apiBaseUrl() + "MarkOrderPaid";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.sessionKey,
      orderNumber: this.state.order.orderNumber,
      datePaid: this.state.datePaid,
      note: this.state.note,
      layaway: this.state.isLayaway,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.invalidSession) {
          this.props.setLoggedOut();
          this.props.history.push("/login");
        } else {
          if (res.data.status) {
            if (this.props.callback) {
              this.props.callback(this.state.datePaid);
            } else {
              window.location.reload();
            }
          } else {
            this.setState({
              errorMessage: res.data.errorMessage,
              message: null,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  theForm() {
    const clsName = this.state.markChecked
      ? "green-small-btn"
      : "disabled-green-small-btn";
    return (
      <div className="flex-wrapper-tb10">
        <div>
          <input
            type="checkbox"
            name="CB_markChecked"
            defaultChecked={this.state.markChecked}
            onChange={this.handleChange}
          />{" "}
          Mark Paid
        </div>
        {this.state.markChecked && (
          <React.Fragment>
            <div className="left-10">
              <input
                type="date"
                name="datePaid"
                value={this.state.datePaid}
                max={this.state.maxDatePaid}
                min={this.state.minDatePaid}
                onChange={this.handleChange}
              />
            </div>
            <div className="left-10">
              <input
                type="text"
                size="20"
                name="note"
                placeholder="Note"
                value={this.state.note}
                onChange={this.handleChange}
              />
            </div>
          </React.Fragment>
        )}
        <div className="left-10"></div>
        <div
          className={clsName}
          name="update"
          disabled={!this.state.markChecked}
          onClick={this.markAsPaid}
        >
          Update
        </div>
      </div>
    );
  }
  render() {
    const order = this.state.order;
    if (!order || order.datePaid || order.paidDateStr) {
      return "";
    }
    return this.theForm();
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};

export default connect(mapStateToProps)(MarkPaid);
