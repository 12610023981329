import React from "react";
import { apiBaseUrl, getIconBaseUrl } from "../Util";
import CustomerInfo from "./CustomerInfo";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";

import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../layaway.css";

class ShippingUpdator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryInfo: this.props.info.address,
      shipping: this.props.info.delivery ? 2 : 1,
      origShipping: this.props.info.delivery ? 2 : 1,
      deliveryInstr: this.props.info.deliveryInstr,
      layaway: this.props.info.layaway,
      shippingExpanded: false,
      selectedDate: this.props.info.scheduledDate,
      scheduledDate: this.props.info.scheduledDate,
      pickupLoc: this.props.info.pickupLoc,
      updPicBtnDisabled: true,
      addNoteBtnDisabled: true,
      deliUpdBtnDisabled:
        this.props.info.scheduledDate && !this.props.info.isContactAddr,
      pickupNote: this.props.info.deliveryInstr,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.updateShippingInfo = this.updateShippingInfo.bind(this);
  }
  handleChange(event) {
    const name = event.target.name;
    if (name === "shipping") {
      const shipping = parseInt(event.target.value);
      this.setState({ shipping });
    } else {
      let deliUpdBtnDisabled = this.state.deliUpdBtnDisabled;
      if (name === "selectedDate") {
        deliUpdBtnDisabled = false;
      }
      this.setState({ [name]: event.target.value, deliUpdBtnDisabled });
    }
  }
  handleClick() {
    this.setState({ shippingExpanded: !this.state.shippingExpanded });
  }
  titleAndDateSelector() {
    const now = new Date();
    const minDate = now.toISOString().split("T")[0];
    return (
      <div className="la-date-title">
        <div>
          <b>
            <font size="4">Delivery Info</font>
          </b>
        </div>
        <div className="left-20">Date: &nbsp;</div>
        <div>
          <input
            type="date"
            name="selectedDate"
            value={this.state.selectedDate}
            min={minDate}
            onChange={this.handleChange}
          ></input>
        </div>
      </div>
    );
  }
  updateShippingInfo(contact) {
    const type = this.state.layaway ? "Layaway" : "Order";
    const url = apiBaseUrl() + "Update" + type + "Shipping";
    let req;

    if (contact) {
      req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        orderNumber: this.props.info.orderNumber,
        delivery: this.state.shipping == 2,
        deliveryFee: contact.deliveryFee,
        deliveryDate: this.state.selectedDate,
        address: contact,
        note: contact.deliveryInstr,
      };
      this.setState({
        deliveryInfo: contact,
        deliveryInstr: contact.deliveryInstr,
        deliUpdBtnDisabled: true,
        origShipping: 2,
      });
    } else {
      req = {
        userId: this.props.userId,
        sessionKey: this.props.sessionKey,
        orderNumber: this.props.info.orderNumber,
        delivery: false,
        note: this.state.pickupNote,
      };
      this.setState({ deliUpdBtnDisabled: true, origShipping: 1 });
    }

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            shippingExpanded: false,
            updPicBtnDisabled: true,
            scheduledDate: this.state.selectedDate,
          });
        }
        this.props.callback(res.data, true);
      })
      .catch((error) => {
        console.log(error);
        this.props.callback(false, "Failed to update shipping");
      });
  }
  shippingInfo() {
    const icon = this.state.shippingExpanded ? "minus.jpg" : "plus.jpg";
    const btnTitle =
      this.state.origShipping == 2 ? "Change to Pickup" : "Update Note";
    const updPicBtnDisabled =
      this.state.origShipping == 2 ? false : this.state.updPicBtnDisabled;
    const btnStyle = updPicBtnDisabled ? "disabled-btn-style" : "btn-style";
    let toggleTitle1 = null;
    let toggleTitle2 = null;

    if (this.state.shipping == this.state.origShipping) {
      if (this.state.origShipping == 2) {
        if (this.state.scheduledDate) {
          toggleTitle2 = <label>View/Update</label>;
        } else {
          toggleTitle2 = (
            <b>
              <font color="red">Schedule Now</font>
            </b>
          );
        }
      } else {
        if (this.state.deliveryInstr) toggleTitle1 = "View/Update Note";
        else toggleTitle1 = "Add Note";
      }
    }

    let showAddrForm = !toggleTitle2 && this.state.shipping == 2;
    let showNoteForm = !toggleTitle1 && this.state.shipping == 1;
    if (this.state.shippingExpanded) {
      if (this.state.shipping == 2) showAddrForm = true;
      else showNoteForm = true;
    }

    let dTitle = "Delivery";
    let pTitle = "Pickup";
    if (this.props.info.delivery) {
      if (this.state.scheduledDate) {
        dTitle += " (" + this.state.scheduledDate + ")";
      } else {
        dTitle += " (Will call)";
      }
    } else {
      if (this.state.pickupLoc) {
        pTitle += " (" + this.state.pickupLoc + ")";
      }
    }

    return (
      <div className="top-10">
        <div className="la-sect-header">
          <div>
            <img src={getIconBaseUrl() + "shipping.jpg"} height="22"></img>
          </div>
          <div className="la-sect-title">
            <font size="4">Shipping</font>
          </div>
        </div>
        <div>
          <div className="flex-wrapper">
            <div>
              <input
                type="radio"
                name="shipping"
                value={1}
                checked={this.state.shipping == 1}
                onChange={this.handleChange}
              ></input>{" "}
              {pTitle}
            </div>

            {toggleTitle1 && (
              <div className="la-view-upd">
                <img
                  src={getIconBaseUrl() + icon}
                  width="15"
                  onClick={this.handleClick}
                  alt="PM"
                ></img>
                &nbsp;{toggleTitle1}
              </div>
            )}
          </div>
          <div className="flex-wrapper">
            <div>
              <input
                type="radio"
                name="shipping"
                value={2}
                checked={this.state.shipping == 2}
                onChange={this.handleChange}
              ></input>{" "}
              {dTitle}
            </div>
            {toggleTitle2 && (
              <div className="la-view-upd">
                <img
                  src={getIconBaseUrl() + icon}
                  width="15"
                  onClick={this.handleClick}
                  alt="PM"
                ></img>
                &nbsp;{toggleTitle2}
              </div>
            )}
          </div>
        </div>
        {showNoteForm && (
          <div className="top-20">
            <div>Pickup Note:</div>
            <textarea
              name="pickupNote"
              rows={5}
              cols={50}
              value={this.state.pickupNote || ""}
              onChange={this.handleChange}
            ></textarea>
            <p />
            <button
              name="updPickup"
              className={btnStyle}
              onClick={() => this.updateShippingInfo(null)}
              disabled={updPicBtnDisabled}
            >
              {btnTitle}
            </button>
          </div>
        )}
        {showAddrForm && (
          <div>
            {this.titleAndDateSelector()}
            <CustomerInfo
              userId={this.props.userId}
              contact={this.state.deliveryInfo}
              isContactAddr={this.props.info.isContactAddr}
              deliveryInstr={this.state.deliveryInstr}
              orderSubtotal={this.props.orderSubtotal}
              actionHandler={this.updateShippingInfo}
              initDisabled={this.state.deliUpdBtnDisabled}
              buttonName={
                this.state.origShipping == 2
                  ? "Update Delivery Info"
                  : "Change to Delivery"
              }
            />
          </div>
        )}
        <div>&nbsp;</div>
        <hr className="hr-300" align="left" />
      </div>
    );
  }

  render() {
    return this.shippingInfo();
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    sessionKey: state.user.sessionKey,
  };
};
export default connect(mapStateToProps)(ShippingUpdator);
